// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const styles = (theme) => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#6E6E6E',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightSemibold,
  },
  body: {
    color: '#737373',
    fontSize: 12,
  },
  deleted: {
    color: 'rgba(0, 0, 0, 0.26)',
    fontSize: 12,
  },
});

function CustomTableCell(props) {
  const { deleted, classes, ...rest } = props;
  return (
    <TableCell
      classes={{
        head: classes.head,
        body: deleted ? classes.deleted : classes.body,
      }}
      {...rest}
    />
  );
}

export default withStyles(styles)(CustomTableCell);
