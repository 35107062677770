// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import axios from 'axios';
import React, { Component } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { API_URL_WITH_V2 } from '../../config';
import { AuthHeaders } from '../../modules/shared/HeaderToken';
import Table from '../admin/shared/table/Table';
import TableCell from '../admin/shared/table/TableCell';
import TableRow from '../admin/shared/table/TableRow';

class AccountsDashboard extends Component {
  static billingTransitions() {
    const ACCOUNT = {
      CLOSED: [],
      DEACTIVATED: ['ACTIVE'],
      ACTIVE: ['DEACTIVATED'],
    };
    const PAID_REWARD = {
      INACTIVE: [],
      DENIED_BY_ADMIN: ['VERIFIED_BY_ADMIN', 'SOFTVERIFIED_BY_ADMIN'],
      UNVERIFIED: ['SOFTVERIFIED_BY_ADMIN', 'DENIED_BY_ADMIN'],
      UNVERIFIED_BY_ADMIN: ['SOFTVERIFIED_BY_ADMIN', 'DENIED_BY_ADMIN'],
      SOFTVERIFIED: [
        'VERIFIED_BY_ADMIN',
        'UNVERIFIED_BY_ADMIN',
        'DENIED_BY_ADMIN',
      ],
      SOFTVERIFIED_BY_ADMIN: [
        'VERIFIED_BY_ADMIN',
        'UNVERIFIED_BY_ADMIN',
        'DENIED_BY_ADMIN',
      ],
      VERIFIED_BY_ADMIN: ['SOFTVERIFIED_BY_ADMIN', 'DENIED_BY_ADMIN'],
    };
    return {
      ACCOUNT,
      PAID_REWARD,
    };
  }

  static accountTransitions() {
    return {
      CLOSED: [],
      DEACTIVATED: ['ACTIVE'],
      ACTIVE: ['DEACTIVATED'],
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      status: 'IDLE',
      page: 1,
      limit: 100,
      employers: [],
      employerProcessingStatuses: {
        ACCOUNT: {},
        PAID_REWARD: {},
      },
    };
  }

  componentDidMount = async () => {
    await this.loadDashboard();
  };

  billingStatus({ type, employer }) {
    const employerProcessingStatus =
      this.state.employerProcessingStatuses[type][employer.employerId] ||
      'IDLE';
    let displayBillingStatus =
      employer.billingStatus[_.camelCase(`${type}_status`)];
    displayBillingStatus = _.startCase(_.toLower(displayBillingStatus));
    if (employerProcessingStatus === 'IDLE') {
      return <TableCell>{displayBillingStatus}</TableCell>;
    } else if (employerProcessingStatus === 'PROCESSING') {
      return (
        <TableCell>
          <div className="alert alert-warning">Processing</div>
        </TableCell>
      );
    } else if (employerProcessingStatus === 'ERROR') {
      return (
        <TableCell>
          {displayBillingStatus}
          <div className="alert alert-danger">Please try again</div>
        </TableCell>
      );
    } else if (employerProcessingStatus === 'SUCCESS') {
      return (
        <TableCell>
          <div className="alert alert-success">{displayBillingStatus}</div>
        </TableCell>
      );
    }
    return;
  }

  updateBillingStatus = async (event) => {
    const employerIdNewStatus = _.get(event, 'target.value', null);
    if (_.isEmpty(employerIdNewStatus)) {
      return;
    }

    const [type, employerId, status] = employerIdNewStatus.split('-');
    if (
      window.confirm(
        `Are you sure you want to change the accountStatus to ${status}`,
      ) === false
    ) {
      return;
    }
    const { state } = this;
    state.employerProcessingStatuses[type][employerId] = 'PROCESSING';
    this.setState(state);
    try {
      const url = `${API_URL_WITH_V2}/superadmin/accounts/update/${_.toLower(
        type,
      )}/status`;
      const response = await axios({
        url,
        method: 'POST',
        ...AuthHeaders(),
        data: {
          employerId,
          status,
        },
      });
      state.employerProcessingStatuses[type][employerId] =
        response.data.data.status;
      const employer = _.find(state.employers, {
        employerId,
      });
      if (response.data.data.status === 'SUCCESS') {
        employer.billingStatus = _.get(response, 'data.data.billingStatus');
      }
    } catch (err) {
      state.employerProcessingStatuses[type][employerId] = 'ERROR';
    }
    this.setState(state);
  };

  billingAction({ type, employer }) {
    const billingStatus = _.get(
      employer,
      `billingStatus.${_.camelCase(`${type}_STATUS`)}`,
    );
    const availableTransistions =
      AccountsDashboard.billingTransitions()[type][billingStatus] || [];
    if (availableTransistions.length === 0) {
      return 'No action here';
    }
    return (
      <FormControl id={employer.employerId} className="form-control">
        <InputLabel>Change</InputLabel>
        <Select value="" onChange={this.updateBillingStatus}>
          {availableTransistions.map((transition) => {
            return (
              <MenuItem
                key={`${type}-${employer.employerId}-${transition}`}
                value={`${type}-${employer.employerId}-${transition}`}
              >
                <em>{transition}</em>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  async loadDashboard() {
    this.setState({
      status: 'LOADING',
    });
    try {
      const url = `${API_URL_WITH_V2}/superadmin/accounts/dashboard?page=${this.state.page}&limit=${this.state.limit}`;
      const response = await axios({
        url,
        method: 'GET',
        ...AuthHeaders(),
      });
      const status = _.get(response, 'data.data.status', 'ERROR');
      const employers = _.get(response, 'data.data.results.employers', []);
      await this.setState({
        status,
        employers,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        status: 'ERROR',
      });
    }
  }

  renderError() {
    return (
      <div style={{ 'margin-top': '100px' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3>Accounts Dashboard {this.state.status}</h3>
              <div className="alert alert-danger">
                Something went wrong while loading dashboard. Please try again.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.status === 'ERROR') {
      return this.renderError();
    }
    return (
      <div
        style={{
          'padding-top': '100px',
          'background-color': 'white',
          'min-height': '1000px',
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3>Accounts Dashboard</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Joined</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Active Employees</TableCell>
                    <TableCell>Paid Reward Status</TableCell>
                    <TableCell>Paid Reward Action</TableCell>
                    <TableCell>Account Status</TableCell>
                    <TableCell>Account Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.employers.map((employer) => (
                    <TableRow
                      key={employer.employerId}
                      id={employer.employerId}
                    >
                      <TableCell component="th" scope="row">
                        {employer.name}
                      </TableCell>
                      <TableCell>{employer.joinedOn}</TableCell>
                      <TableCell>{employer.ownerEmail}</TableCell>
                      <TableCell>{employer.employeeCount}</TableCell>
                      {this.billingStatus({ employer, type: 'PAID_REWARD' })}
                      <TableCell>
                        <div style={{ minWidth: '200px' }}>
                          {this.billingAction({
                            employer,
                            type: 'PAID_REWARD',
                          })}
                        </div>
                      </TableCell>
                      {this.billingStatus({ employer, type: 'ACCOUNT' })}
                      <TableCell>
                        <div style={{ minWidth: '200px' }}>
                          {this.billingAction({ employer, type: 'ACCOUNT' })}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountsDashboard;
