// @ts-nocheck TODO: type issues need to be fixed in this file
import { useState } from 'react';
import { FormikErrors } from 'formik';

import { STRENGTH_NONE } from '../../../constants/progressValidator';
import {
  validatePassword,
  checkPasswordStrength,
  passwordValidators,
  calculateValidationTexts,
  calculateStrength,
} from '../../../Utils/validators/password';

interface PasswordInitialValue {
  password: string;
}

const { newValidationTexts: passwordValidationText } = checkPasswordStrength();

const usePasswordWithValidator = () => {
  const [validationTexts, setValidationText] = useState(passwordValidationText);
  const [strength, setStrength] = useState<string>(STRENGTH_NONE);

  const validateFormikForm = async (values: PasswordInitialValue) => {
    const { password } = values;
    const resultErrors: FormikErrors<PasswordInitialValue> = {};

    const { isValid: isPasswordValid, errors } = validatePassword(
      password,
      passwordValidators,
    );
    const newValidationTexts = calculateValidationTexts(
      password,
      errors,
      passwordValidators,
    );
    setValidationText(newValidationTexts);
    const newStrength = calculateStrength(password, newValidationTexts);
    setStrength(newStrength);
    if (!isPasswordValid && errors.length) {
      [resultErrors.password] = errors;
    }
    return resultErrors;
  };

  return {
    models: {
      validationTexts,
      strength,
    },
    operations: {
      validateFormikForm,
    },
  };
};

export default usePasswordWithValidator;
