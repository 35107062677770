export const device = {
  mobile: '(min-width: 320px) and (max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  laptop: '(min-width: 1024px)',
  tabletV2: '(min-width: 768px)',
  laptop2: '(min-width: 1025px)',
};

export const PrimaryNavWidth = 96;
export const SecondaryNavWidth = 336;
export const PrimaryNavWidthMobile = 72;
export const AsideWidthWhenClosed = 24;
export const AsideWidthWhenOpen = 320;
export const AsideToggleIconSize = 40;

export const MainHeaderHeight = '152px';
export const MainHeaderWithTabsHeight = '181px';

export const RewardsFixedHeaderSpacing =
  PrimaryNavWidth + SecondaryNavWidth + AsideWidthWhenClosed * 2;

export const RewardsFixedHeaderMobileSpacing = AsideWidthWhenClosed * 2;
