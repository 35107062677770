import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';
import Body from '../../../atomic/atoms/Body';
import SSOInfoCard from '../../../atomic/pages/SSOInfoCard';
import Layout from '../../../aV2/Layout/onboarding';

import useSAMLAuthLogic from './useSAMLAuthLogic';
import {
  getSAMLErrorData,
  SAMLAuthErrorCodes,
} from '../../../Utils/data/SAML/error';
import RoutesList from '../../../aV2/routes/routesList';
import { RETURN_TO_SIGN_IN } from '../../../languages/en/signIn';
import {
  ButtonVariants,
  ButtonStatuses,
  ButtonColors,
} from '../../../atomic/atoms/Button/interfaces';

const SAMLAuthController = () => {
  const { loadingText, errorCode } = useSAMLAuthLogic();
  const { push } = useHistory();
  if (errorCode) {
    const { headerText, bodyText, buttonText, buttonIcon, urlToPush } =
      getSAMLErrorData(errorCode);
    let buttonProps: {
      variant?: ButtonVariants;
      status?: ButtonStatuses;
      colour?: ButtonColors;
    } = {};
    let handleButtonClick = () => {
      push(urlToPush);
    };
    if (errorCode === SAMLAuthErrorCodes.ADMIN_APPROVAL_REQUESTED) {
      buttonProps = {
        variant: 'text',
        status: 'success',
        colour: 'secondary',
      };
      handleButtonClick = () => {};
    }
    const subText = {
      text: '',
      linkText: RETURN_TO_SIGN_IN,
      linkPath: RoutesList.SIGN_IN,
    };
    return (
      <Layout>
        <SSOInfoCard
          headingText={headerText}
          bodyText={bodyText}
          buttonOnClick={handleButtonClick}
          buttonText={buttonText}
          buttonIcon={buttonIcon || undefined}
          subText={subText}
          buttonProps={buttonProps || undefined}
        />
      </Layout>
    );
  }
  return (
    <Grid container justify="center" style={{ marginTop: 90 }}>
      <Body variant="body2Bold">{loadingText}</Body>
      <LoadingPulse isLoading />
    </Grid>
  );
};

export default SAMLAuthController;
