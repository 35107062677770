import { checkIfProduction } from '../admin';

export const fireConversionForSignup = (userId: string) => {
  if (checkIfProduction()) {
    const script = document.createElement('script');
    const scriptText = document.createTextNode(`gtag('event', 'conversion', {
            'send_to': 'AW-979429994/Zp4nCNu03aQBEOrUg9MD',
            'value': 40.0,
            'currency': 'USD',
            'transaction_id': '${userId}',
          });`);
    script.appendChild(scriptText);
    document.body.appendChild(script);
  }
};

export const fireConversionForEmployerOnboard = () => {
  if (checkIfProduction()) {
    const script = document.createElement('script');
    const scriptText = document.createTextNode(`gtag('event', 'conversion', {
            'send_to': 'AW-979429994/IyZ_CJ2YpI4DEOrUg9MD'
          });`);
    script.appendChild(scriptText);
    document.body.appendChild(script);
  }
};
