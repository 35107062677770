// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import Button from '../../../componentsV2/core/Button';
import { verifyCode, resendCode } from '../../modules/onboarding';

import { getEmailRegistered } from '../../modules/onboarding/selectors';
import themeV2 from '../../../componentsV2/theme';
import TextField from '../../../componentsV2/core/TextField';
import Existing from '../shared/ExistingAssemblyDiv';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    padding: '40px 55px 25px 55px',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  otpContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  body: {
    flex: 1,
  },
  bodyHead: {
    marginBottom: '24px',
  },
  bodyTitle: {
    marginTop: '20px',
  },
  bodyText: {
    marginBottom: '32px',
  },
  textCenter: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '500',
  },
  label: {
    display: 'block',
    fontSize: '12px',
    width: 'calc(100% - 24px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    background: '#F9F9F9',
    borderRadius: '3px',
    padding: '12px',
    marginTop: '8px',
    marginBottom: '24px',
    fontWeight: '600',
  },
  button: {
    display: 'block',
    marginTop: '8px',
    width: '100%',
  },
  secondaryDivStyle: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  progresStyle: {
    color: themeV2.palette.greenLight,
  },
  successDivStyle: {
    color: themeV2.palette.greenLight,
  },
  errorDivStyle: {
    color: themeV2.palette.red2,
  },
  emailWrapper: {},
  emailLabel: {
    fontSize: '12px',
  },
  emailText: {
    fontSize: '14px',
    paddingLeft: '15px',
    marginTop: '14px',
    marginBottom: '24px',
  },
});

class EmailSignin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: 'jayasurya@joinasssembly.com',
      password: '',
      emailErrors: null,
      passwordErrors: null,
      emailTouched: false,
      passwordTouched: false,
    };
  }

  componentDidMount() {}

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      emailErrors: null,
      passwordErrors: null,
      [`${name}Touched`]: true,
    });
  };

  render() {
    const { classes, signUp } = this.props;
    const {
      email,
      password,
      passwordErrors,
      emailErrors,
      passwordTouched,
      emailTouched,
    } = this.state;

    let isButtonDisabled =
      !(emailTouched && passwordTouched) || emailErrors || passwordErrors;
    if (
      email.length !== 0 &&
      password.length !== 0 &&
      !emailErrors &&
      !passwordErrors
    ) {
      isButtonDisabled = false;
    }

    return (
      <Layout>
        {
          <>
            <Card className={classes.card}>
              <div className={classes.wrapper}>
                <div className={classes.body}>
                  <div className={classes.bodyHead}>
                    <Typography variant="h5" weight="bold">
                      {signUp
                        ? 'Welcome! You’re required to use email to sign in.'
                        : 'Welcome back! Sorry, you’re required to use email.'}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.bodyTitle}
                      gutterBottom
                    >
                      To get signed in to your team’s Assembly, you’ll first
                      need to create a password to continue. You will use these
                      credentials form here on to access Assembly.
                    </Typography>
                  </div>
                  <div>
                    <div className={classes.emailWrapper}>
                      <div className={classes.emailLabel}>
                        <b>Your email address from Slack</b>
                      </div>
                      <div className={classes.emailText}>
                        jayasurya@joinassembly.com
                      </div>
                    </div>
                    <TextField
                      id="password"
                      label={
                        signUp ? 'Create your password' : 'Enter your password'
                      }
                      placeholder="Enter password"
                      type="password"
                      value={password}
                      onChange={this.handleChange('password')}
                      error={passwordErrors}
                      rootStyle={{ width: '100%', marginTop: 10 }}
                    />
                    <Button
                      style={{ width: '100%', marginTop: 20 }}
                      disabled={isButtonDisabled}
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      Sign In
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
            <Existing
              text="Are you a Slack admin?"
              linkText="Turn on Slack sign in"
              to="https://slack.com/apps/manage/settings"
            />
          </>
        }
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  email: getEmailRegistered(state),
});

export default connect(mapStateToProps, { verifyCode, resendCode })(
  withStyles(styles)(EmailSignin),
);
