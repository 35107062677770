import RoutesList from '../../../aV2/routes/routesList';
import { SSOOptions, SSOContent, SSOTypes } from './common';

export enum SSOErrorCodes {
  ASSEMBLY_NOT_FOUND = 'ASSEMBLY_NOT_FOUND',
  ASSEMBLY_NOT_ACTIVE = 'ASSEMBLY_NOT_ACTIVE',
  ASSEMBLY_ALREADY_EXISTS = 'ASSEMBLY_ALREADY_EXISTS',
  SIGNIN_VIA_SLACK = 'SIGNIN_VIA_SLACK',
  SIGNIN_VIA_ADP = 'SIGNIN_VIA_ADP',
  SIGNIN_VIA_OFFICE365 = 'SIGNIN_VIA_OFFICE365',
  SIGNIN_VIA_GOOGLE = 'SIGNIN_VIA_GSUITE',
  SIGNIN_WITH_EMAIL = 'SIGNIN_WITH_EMAIL',
  SIGNIN_VIA_SAML = 'SIGNIN_VIA_SAML',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  SLACK_USER_NOT_IN_ASSEMBLY = 'SLACK_USER_NOT_IN_ASSEMBLY',
  SSO_USER_NOT_IN_ASSEMBLY = 'SSO_USER_NOT_IN_ASSEMBLY',
  OFFICE365_USER_NOT_IN_ASSEMBLY = 'OFFICE365_USER_NOT_IN_ASSEMBLY',
  ADMIN_APPROVAL_REQUESTED = 'ADMIN_APPROVAL_REQUESTED',
  SLACK_TEAM_MISMATCH = 'SLACK_TEAM_MISMATCH',
  OFFICE365_TENANT_MISMATCH = 'OFFICE365_TENANT_MISMATCH',
  SLACK_SIGN_IN_DISABLED = 'SLACK_SIGN_IN_DISABLED',
  SSO_SIGN_IN_DISABLED = 'SSO_SIGN_IN_DISABLED',
  OFFICE365_SIGNIN_DISABLED = 'OFFICE365_SIGNIN_DISABLED',
  SLACK_SIGN_UP_DISABLED = 'SLACK_SIGN_UP_DISABLED',
  SSO_SIGN_UP_DISABLED = 'SSO_SIGN_UP_DISABLED',
  OFFICE365_SIGN_UP_DISABLED = 'OFFICE365_SIGN_UP_DISABLED',
  CONSENT_REQUIRED = 'consent_required',
  ACCESS_DENIED = 'access_denied', // Common for Slack and Google
  USER_CANCELLED = 'CancelledByUser',
  SERVER_ERROR = 'SERVER_ERROR',
  // Google SSO Errors https://github.com/anthonyjgrove/react-google-login#onfailure-callback
  POPUP_CLOSED_BY_USER = 'popup_closed_by_user',
  COOKIES_NOT_ALLOWED = 'idpiframe_initialization_failed',
  IMMEDIATE_FAILED = 'immediate_failed',
  INTERNAL_ERROR = 'internal_error',
  INTERNAL_ERROR_LIFION = 'internal_error_lifion',
  ADP_SIGN_IN_DISABLED = 'ADP_SIGN_IN_DISABLED',
  ADP_USER_NOT_IN_ASSEMBLY = 'ADP_USER_NOT_IN_ASSEMBLY',
  NEW_ADP_USER_ERROR = 'NEW_ADP_USER_ERROR',
  LIFION_SIGNIN_BLOCKED = 'LIFION_SIGNIN_BLOCKED',
}

export const ErrorData = {
  [SSOErrorCodes.ASSEMBLY_NOT_ACTIVE]: {
    primaryText: '	This account is closed',
    secondaryText: 'Sorry, your Assembly account has been closed',
    buttonText: 'Setup new Assembly',
    urlToPush: RoutesList.ENTER_EMAIL,
    buttonIcon: null,
  },
  [SSOErrorCodes.ASSEMBLY_NOT_FOUND]: {
    primaryText: 'Assembly not found',
    secondaryText: 'Sorry, your Assembly account has not been set up yet',
    buttonText: 'Setup new Assembly',
    urlToPush: RoutesList.ENTER_EMAIL,
    buttonIcon: null,
  },
  [SSOErrorCodes.ASSEMBLY_ALREADY_EXISTS]: {
    primaryText: 'Assembly account exists',
    secondaryText: 'Sorry, Assembly already exists for this :sso account',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.SIGNIN_VIA_SLACK]: {
    primaryText: 'Please use your SSO',
    secondaryText:
      'Your account is authorized with Slack – please click the “Sign in with Slack” button to log in to your Assembly',
    buttonText: 'Sign in with Slack',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.SLACK].ssoParam,
    )}?auto=true`,
    buttonIcon: 'slack-mini-logo-white',
  },
  [SSOErrorCodes.SIGNIN_VIA_ADP]: {
    primaryText: 'Please use your SSO',
    secondaryText:
      'Your account is authorized with ADP – please click the “Sign in with ADP” button to log in to your Assembly',
    buttonText: 'Sign in with ADP',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.ADP].ssoParam,
    )}?auto=true`,
    buttonIcon: 'adp-mini-logo-white',
  },
  [SSOErrorCodes.SIGNIN_VIA_OFFICE365]: {
    primaryText: 'Please use your SSO',
    secondaryText:
      // eslint-disable-next-line max-len
      'Your account is authorized with Office 365 – please click the “Sign in with Office 365” button to log in to your Assembly',
    buttonText: 'Sign in with Office 365',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.OFFICE365].ssoParam,
    )}?auto=true`,
    buttonIcon: 'office-365-mini-logo',
  },
  [SSOErrorCodes.SIGNIN_VIA_GOOGLE]: {
    primaryText: 'Please use your SSO',
    secondaryText:
      // eslint-disable-next-line max-len
      'Your account is authorized with Google – please click the “Sign in with Google" button to log in to your Assembly',
    buttonText: 'Sign in with Google',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.GOOGLE].ssoParam,
    )}?auto=true`,
    buttonIcon: SSOContent[SSOOptions.GOOGLE].logo,
  },
  [SSOErrorCodes.SIGNIN_VIA_SAML]: {
    primaryText: 'Please sign in with :samlName',
    secondaryText:
      // eslint-disable-next-line max-len
      'Your account requires authorization with :samlName - please click the “Sign in with :samlName” button to log into your Assembly. If you don’t have :samlName access, please talk to your Assembly admin.',
    buttonText: 'Sign in with :samlName',
    urlToPush: `/auth/saml?assemblyId=:id`,
    buttonIcon: 'key',
  },
  [SSOErrorCodes.SIGNIN_WITH_EMAIL]: {
    primaryText: 'Please use email and password',
    secondaryText:
      // eslint-disable-next-line max-len
      'Your account is authorized with email and password – please click the “Sign in with Email” button to log in to your Assembly',
    buttonText: 'Sign in with Email',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.USER_NOT_FOUND]: {
    primaryText: 'Account not found',
    secondaryText:
      'Sorry, we’re not able to find your account. Please talk to your admin or try creating a new Assembly',
    buttonText: 'Setup new Assembly',
    urlToPush: RoutesList.ENTER_EMAIL,
    buttonIcon: null,
  },
  [SSOErrorCodes.SLACK_USER_NOT_IN_ASSEMBLY]: {
    primaryText: 'This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.SSO_USER_NOT_IN_ASSEMBLY]: {
    primaryText: 'This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.OFFICE365_USER_NOT_IN_ASSEMBLY]: {
    primaryText: 'This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.ADMIN_APPROVAL_REQUESTED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText:
      // eslint-disable-next-line max-len
      'A request has been sent to you account’s admin – If your Assembly admin approves your access, we will notify you',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.SLACK_TEAM_MISMATCH]: {
    primaryText: 'Slack is already connected',
    secondaryText:
      'Sorry, each Slack account can only be associated with a single Assembly account',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.OFFICE365_TENANT_MISMATCH]: {
    primaryText: 'Office 365 is already connected',
    secondaryText:
      'Sorry, each Office 365 account can only be associated with a single Assembly account',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.SLACK_SIGN_IN_DISABLED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.SSO_SIGN_IN_DISABLED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.OFFICE365_SIGNIN_DISABLED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.SLACK_SIGN_UP_DISABLED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.SSO_SIGN_UP_DISABLED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.OFFICE365_SIGN_UP_DISABLED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.CONSENT_REQUIRED]: {
    primaryText: 'Authentication denied',
    secondaryText:
      'Whoops, you declined Assembly’s permission to connect with Office 365',
    buttonText: 'Sign in with Office 365',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.OFFICE365].ssoParam,
    )}?auto=true`,
    buttonIcon: 'office-365-mini-logo',
  },
  [SSOErrorCodes.ACCESS_DENIED]: {
    primaryText: 'Authentication denied',
    secondaryText:
      'Whoops, you declined Assembly’s permission to connect with :sso',
    buttonText: 'Sign in with :sso',
    urlToPush: '',
    buttonIcon: null,
  },
  [SSOErrorCodes.USER_CANCELLED]: {
    primaryText: 'Authentication denied',
    // eslint-disable-next-line max-len
    secondaryText: `Whoops, you declined Assembly’s permission to connect with :sso. If you didn't, then this might be an issue with your Teams app. Please update your Microsoft Teams application and try again.`,
    buttonText: 'Sign in with :sso',
    urlToPush: '',
    buttonIcon: 'slack-mini-logo-white',
  },
  [SSOErrorCodes.SERVER_ERROR]: {
    primaryText: 'Whoops, some wires got crossed',
    secondaryText: 'Sorry for the error, please try again now or try later',
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.POPUP_CLOSED_BY_USER]: {
    primaryText: 'Authentication denied',
    secondaryText: 'Incomplete login. Please try again.',
    buttonText: 'Sign in with :sso',
    urlToPush: '',
    buttonIcon: 'google-light',
  },
  [SSOErrorCodes.IMMEDIATE_FAILED]: {
    primaryText: 'Authentication denied',
    secondaryText: 'Whoops! Some wires got crossed. Please try again.',
    buttonText: 'Sign in with Google',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.GOOGLE].ssoParam,
    )}?auto=true`,
    buttonIcon: 'google-light',
  },
  [SSOErrorCodes.COOKIES_NOT_ALLOWED]: {
    primaryText: 'Authentication denied',
    secondaryText: 'Please enable third party cookies to allow Google sign-in',
    buttonText: 'Sign in with Google',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.GOOGLE].ssoParam,
    )}?auto=true`,
    buttonIcon: 'google-light',
  },
  [SSOErrorCodes.INTERNAL_ERROR]: {
    primaryText: 'Authentication denied',
    secondaryText: `We could not sign you in with ADP. Please contact your ADP administrator to make sure that
      you have Assembly for workforce now installed`,
    buttonText: 'Sign in with ADP',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.ADP].ssoParam,
    )}?auto=true`,
    buttonIcon: SSOContent[SSOOptions.ADP].logo,
  },
  [SSOErrorCodes.INTERNAL_ERROR_LIFION]: {
    primaryText: 'Authentication denied',
    secondaryText: `We could not sign you in with Lifion. Please contact your Lifion administrator to make sure that
      you have Assembly installed`,
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SSOErrorCodes.ADP_SIGN_IN_DISABLED]: {
    primaryText: 'Authentication denied',
    secondaryText:
      'Whoops! It looks like ADP sign-in is not available for this Assembly. Please contact your admin.',
    buttonText: 'Sign in with ADP',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.ADP].ssoParam,
    )}?auto=true`,
    buttonIcon: SSOContent[SSOOptions.ADP].logo,
  },
  [SSOErrorCodes.ADP_USER_NOT_IN_ASSEMBLY]: {
    primaryText: 'Authentication denied',
    secondaryText: `Whoops! We couldn’t find an Assembly account for this user.
      Please ensure you are using the correct email or contact your admin.`,
    buttonText: 'Sign in with ADP',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.ADP].ssoParam,
    )}?auto=true`,
    buttonIcon: SSOContent[SSOOptions.ADP].logo,
  },
  [SSOErrorCodes.NEW_ADP_USER_ERROR]: {
    primaryText: 'Authentication denied',
    secondaryText: `Whoops! Looks like you haven’t been invited to this Assembly yet. Please contact your admin.`,
    buttonText: 'Sign in with ADP',
    urlToPush: `/${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
      SSOContent[SSOOptions.ADP].ssoParam,
    )}?auto=true`,
    buttonIcon: SSOContent[SSOOptions.ADP].logo,
  },
  [SSOErrorCodes.LIFION_SIGNIN_BLOCKED]: {
    primaryText: 'Sorry! This Assembly is invite only',
    secondaryText: `Whoops! Looks like you haven’t been invited to this Assembly yet. Please contact your admin.`,
    buttonText: 'Thanks, take me back',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
};

const ssoToBeReplacedErrorCodes = [
  SSOErrorCodes.USER_CANCELLED,
  SSOErrorCodes.POPUP_CLOSED_BY_USER,
  SSOErrorCodes.ACCESS_DENIED,
];

export const getSSOErrorData = (
  errorCode: SSOErrorCodes,
  ssoType: SSOTypes,
  sso: SSOOptions,
) => {
  if (!Object.values(SSOErrorCodes).includes(errorCode)) {
    const [subErrorCode, samlName, assemblyId] = errorCode.split('-');
    if (
      subErrorCode &&
      samlName &&
      assemblyId &&
      subErrorCode === SSOErrorCodes.SIGNIN_VIA_SAML
    ) {
      return {
        headerText: ErrorData[
          SSOErrorCodes.SIGNIN_VIA_SAML
        ].primaryText.replace(':samlName', samlName),
        bodyText: ErrorData[
          SSOErrorCodes.SIGNIN_VIA_SAML
        ].secondaryText.replaceAll(':samlName', samlName),
        buttonText: ErrorData[SSOErrorCodes.SIGNIN_VIA_SAML].buttonText.replace(
          ':samlName',
          samlName,
        ),
        urlToPush: ErrorData[SSOErrorCodes.SIGNIN_VIA_SAML].urlToPush.replace(
          ':id',
          assemblyId,
        ),
        buttonIcon: ErrorData[SSOErrorCodes.SIGNIN_VIA_SAML].buttonIcon,
      };
    }
    return {
      headerText: ErrorData[SSOErrorCodes.SERVER_ERROR].primaryText,
      bodyText: ErrorData[SSOErrorCodes.SERVER_ERROR].secondaryText,
      buttonText: ErrorData[SSOErrorCodes.SERVER_ERROR].buttonText,
      urlToPush: ErrorData[SSOErrorCodes.SERVER_ERROR].urlToPush,
      buttonIcon: ErrorData[SSOErrorCodes.SERVER_ERROR].buttonIcon,
    };
  }

  if (errorCode === SSOErrorCodes.INTERNAL_ERROR && sso === SSOOptions.LIFION) {
    return {
      headerText: ErrorData[SSOErrorCodes.INTERNAL_ERROR_LIFION].primaryText,
      bodyText: ErrorData[SSOErrorCodes.INTERNAL_ERROR_LIFION].secondaryText,
      buttonText: ErrorData[SSOErrorCodes.INTERNAL_ERROR_LIFION].buttonText,
      urlToPush: ErrorData[SSOErrorCodes.INTERNAL_ERROR_LIFION].urlToPush,
      buttonIcon: ErrorData[SSOErrorCodes.INTERNAL_ERROR_LIFION].buttonIcon,
    };
  }

  if (errorCode === SSOErrorCodes.ASSEMBLY_ALREADY_EXISTS) {
    let message = ErrorData[errorCode].secondaryText;
    message = message.replace(/:sso/g, SSOContent[sso].displayName);

    return {
      headerText: ErrorData[errorCode].primaryText,
      bodyText: message,
      buttonText: ErrorData[errorCode].buttonText,
      urlToPush: ErrorData[errorCode].urlToPush,
      buttonIcon: ErrorData[errorCode].buttonIcon,
    };
  }

  const headerText = ErrorData[errorCode].primaryText;
  let bodyText = ErrorData[errorCode].secondaryText;
  let { buttonIcon } = ErrorData[errorCode];
  let { buttonText, urlToPush } = ErrorData[errorCode];
  if (ssoType === SSOTypes.SIGN_UP) {
    if (errorCode === SSOErrorCodes.CONSENT_REQUIRED) {
      buttonText = 'Sign up with Office 365';
      urlToPush = `/${RoutesList.SSO_SIGNUP_AUTHORIZE_FN(
        SSOContent[SSOOptions.OFFICE365].ssoParam,
      )}?auto=true`;
    }
    if (errorCode === SSOErrorCodes.ACCESS_DENIED) {
      buttonText = `Sign up with ${SSOContent[sso].displayName}`;
      urlToPush = `/${RoutesList.SSO_SIGNUP_AUTHORIZE_FN(
        SSOContent[sso].ssoParam,
      )}?auto=true`;
    }
  }

  if (ssoToBeReplacedErrorCodes.indexOf(errorCode) !== -1) {
    bodyText = bodyText.replace(':sso', SSOContent[sso].displayName);
    buttonText = buttonText.replace(':sso', SSOContent[sso].displayName);
    urlToPush = `/${RoutesList.SSO_AUTHORIZE_FN(
      SSOContent[sso].ssoParam,
      ssoType,
    )}?auto=true`;
    buttonIcon = SSOContent[sso].logo;
  }
  return {
    headerText,
    bodyText,
    buttonText,
    urlToPush,
    buttonIcon,
  };
};
