import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';

export const Container = styled.div`
  width: fit-content;
  & div:nth-child(2) {
    z-index: 2;
  }
`;

export const PopoverElement = styled.div<{
  padding: string;
}>`
  background: ${ThemeV2.palette.gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: ${({ padding }) => padding};
`;

export const ReferenceContentContainer = styled.div<{ padding: string }>`
  padding: ${({ padding }) => padding};
`;

export const ClickHolder = styled.div`
  &:focus {
    outline: 0;
    border: 0;
  }
`;
