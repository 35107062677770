import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import LinkV2 from '../../atoms/Link_V2';
import { Flex } from '../../../Utils/styles/display';

const StyledLink = styled(LinkV2)`
  text-decoration: none;
  margin: 0 2px;
`;

export interface SigninSubTextProps {
  text: string;
  linkOneText: string;
  linkOnePath: string;
  linkTwoText?: string;
  linkTwoPath?: string;
  className?: string;
}

const SigninSubText = (props: SigninSubTextProps) => {
  const {
    text,
    linkOneText,
    linkOnePath,
    linkTwoText,
    linkTwoPath,
    className,
  } = props;
  return (
    <Flex alignItems="center" className={className}>
      <Body variant="body2" color="gray8">
        {text}
      </Body>
      <StyledLink to={linkOnePath}>{linkOneText}</StyledLink>
      {linkTwoText && linkTwoPath && (
        <>
          <Body variant="body2" color="gray8">
            or
          </Body>
          <StyledLink to={linkTwoPath}>{linkTwoText}</StyledLink>
        </>
      )}
    </Flex>
  );
};

export default SigninSubText;
