import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import useFetchIMConfig from '../../../interactionLayer/useFetchIMConfig/useFetchIMConfig';
import useVerifyIMCode from '../../../interactionLayer/useVerifyIMCode/useVerifyIMCode';

import SSOOnboardingTemplate from '../../templates/SSOOnboardingTemplate/SSOOnboardingTemplate';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import LoadingPulse from '../../atoms/LoadingPulse';
import IntegrationButton from '../../molecules/IntegrationButton';
import {
  IdentitySSO,
  getSSOIDByParam,
} from '../../../Utils/identity/allowedSSOs';

interface IdentityManagerAuthorizeParams {
  sso: string;
}

const IdentityManagerAuthorize = () => {
  const { sso } = useParams<IdentityManagerAuthorizeParams>();
  const { isVerifyingCode } = useVerifyIMCode(sso);
  const { models, operations } = useFetchIMConfig(sso);
  const ssoId = getSSOIDByParam(sso);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const { logo, displayName } = IdentitySSO[ssoId];

  const isConnectingOrVerifying = models.isConnectingIM || isVerifyingCode;
  const connectionBody = models.isConnectingIM
    ? `Connecting to ${displayName}`
    : `Verifying ${displayName}`;
  const welcomeHeading = `Welcome! Sign in with ${displayName} to access your Assembly.`;
  const welcomeBody = `Give recognition, redeem your earned points, peep the leader boards, and
  more. Click the ${displayName} button to sign in to Assembly.`;

  // Starting of Presentation Component
  // TODO: Split Dumb & Smart Components
  if (isConnectingOrVerifying) {
    return (
      <Grid container justify="center" style={{ marginTop: 90 }}>
        <Body variant="body2Bold">{connectionBody}</Body>
        <LoadingPulse isLoading />
      </Grid>
    );
  }

  return (
    <SSOOnboardingTemplate logoURL="/">
      <Heading variant="h5">{welcomeHeading}</Heading>
      <Body variant="body2" style={{ margin: '16px 0px' }}>
        {welcomeBody}
      </Body>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IntegrationButton
          disabled={!models.imConfigData}
          onClick={operations.onAuthorizeClick}
          logo={logo}
        />
      </div>
    </SSOOnboardingTemplate>
  );
};

export default IdentityManagerAuthorize;
