import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

export const CardRoot = styled.div`
  background: ${ThemeV2.palette.gray1};
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

export interface CardProps {
  children: ReactNode;
}

const Card = (props: CardProps) => {
  const { children, ...rest } = props;
  return <CardRoot {...rest}>{children}</CardRoot>;
};

export default Card;
