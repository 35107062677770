import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { BaseEmoji, CustomEmoji, EmojiData } from 'emoji-mart';

import Heading from '../../atoms/Heading';
import EmoticonPickerPopover from '../EmoticonPickerPopover';
import { PopoverChildrenProps } from '../../../hooks/usePopover/interface';
import { TextFieldWithEmoticonPickerProps } from './interface';
import { StyledTextField, StyledSVGIcon } from './styles';
import ThemeV2 from '../../../componentsV2/theme';

const renderEmoji = (emoji: BaseEmoji | CustomEmoji) => {
  const customEmoji = { ...emoji } as CustomEmoji;
  if (customEmoji.imageUrl) {
    return customEmoji.imageUrl;
  }
  const baseEmoji = { ...emoji } as BaseEmoji;
  return String.fromCodePoint(parseInt(baseEmoji.unified, 16));
};

const TextFieldWithEmoticonPicker = (
  props: TextFieldWithEmoticonPickerProps,
) => {
  const { value, label, name, placeholder, onChange } = props;

  const [textFieldValue, setTextFieldValue] = useState(value.textField);
  const [emoticonValue, setEmoticonValue] = useState<EmojiData | undefined>(
    value.emoticon,
  );
  const handleTextFieldValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTextFieldValue(e.target.value);
    onChange({ textField: e.target.value, emoticon: emoticonValue });
  };
  const handleEmoticonSelect = (emoji: EmojiData) => {
    setEmoticonValue(emoji);
    onChange({ textField: textFieldValue, emoticon: emoji });
  };

  const startAdornment = {
    startAdornment: (
      <EmoticonPickerPopover onEmoticonSelect={handleEmoticonSelect}>
        {({ togglePopover }: PopoverChildrenProps) => (
          <InputAdornment
            position="start"
            onClick={() => {
              togglePopover();
            }}
            data-testid="emoticonPickerButton"
          >
            <>
              <Heading
                variant="h6"
                color={ThemeV2.palette.gray6}
                weight="regular"
              >
                {emoticonValue !== undefined ? renderEmoji(emoticonValue) : '+'}
              </Heading>
              <StyledSVGIcon icon="caret-rounded" size="16px" />
            </>
          </InputAdornment>
        )}
      </EmoticonPickerPopover>
    ),
  };

  return (
    <div>
      <StyledTextField
        label={label}
        name={name}
        placeholder={placeholder}
        onChange={handleTextFieldValueChange}
        value={textFieldValue}
        InputProps={startAdornment}
      />
    </div>
  );
};

export default TextFieldWithEmoticonPicker;
