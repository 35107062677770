// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import {
  arrayOf,
  func,
  number,
  oneOfType,
  shape,
  string,
  bool,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dropdown from '../../atoms/Dropdown';

const useStyles = makeStyles({
  root: ({ optionsList }) => ({
    '&:first-child': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: optionsList.length === 1 ? '2px' : '2px 0px 0px 2px',
      },
    },
    '&:nth-child(2)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: optionsList.length === 3 ? '0px' : '0px 2px 2px 0px',
      },
    },
    '&:nth-child(3)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0px 2px 2px 0px',
      },
    },
  }),
});

const DropdownGroup = (props) => {
  const {
    optionsList,
    onChangeList,
    selectedOptions,
    isFilter,
    className: parentClassName,
  } = props;
  const classes = useStyles(props);
  const className = `${classes.root} ${parentClassName}`;

  return (
    <>
      {optionsList.map((items, index) => {
        const { menuItems, placeholder, icon, width, filterPlaceholder } =
          items;
        return (
          <Dropdown
            key={`${placeholder}-${width}-${icon}`}
            menuItems={menuItems}
            onChange={onChangeList[index]}
            selectedMenuItemId={selectedOptions[index]}
            width={width}
            isFilter={isFilter}
            placeholder={placeholder}
            filterPlaceholder={filterPlaceholder}
            icon={icon}
            className={className}
          />
        );
      })}
    </>
  );
};

export const optionsListPropType = arrayOf(
  shape({
    placeholder: string,
    icon: string,
    menuItems: arrayOf(
      shape({
        id: oneOfType([string, number]).isRequired,
        value: string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
);

DropdownGroup.propTypes = {
  optionsList: optionsListPropType.isRequired,
  onChangeList: arrayOf(func).isRequired,
  selectedOptions: arrayOf(oneOfType([string, number])),
  isFilter: bool,
  className: string,
};

DropdownGroup.defaultProps = {
  selectedOptions: [],
  isFilter: false,
  className: '',
};

export default DropdownGroup;
