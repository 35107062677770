import React, { useCallback } from 'react';
import styled from 'styled-components';

import SVGIcon from '../../atoms/SVGIcon';
import Avatar from '../../atoms/Avatar';
import ThemeV2 from '../../../componentsV2/theme';
import { AvatarProps } from '../../atoms/Avatar/interfaces';

interface ChipWrapperProps {
  role: string;
  clickable?: boolean;
  disabled?: boolean;
  isValid?: boolean;
}

interface ChipAvatarProps {
  img?: string;
  color?: string;
}

interface ChipLabelProps {
  disabled?: boolean;
}

interface ChipDeleteIconProps {
  disabled?: boolean;
  isValid?: boolean;
}

const ChipWrapper = styled.div<ChipWrapperProps>`
  align-items: center;
  background: ${(props) => {
    if (props.disabled) {
      return ThemeV2.palette.gray4;
    }
    if (!props.isValid) {
      return ThemeV2.palette.dustRed2;
    }
    return ThemeV2.palette.gray3;
  }};
  border-radius: 16px;
  box-sizing: border-box;
  display: inline-flex;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  height: 32px;
  justify-content: center;
  margin: 2px 4px;
  padding: 4px;
  border: none;
  outline: 0;
  font-size: 14px;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
  vertical-align: middle;
  text-decoration: none;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};

  &:hover {
    background: ${(props) => {
      if (!props.isValid) {
        return ThemeV2.palette.dustRed4;
      }
      if (!props.disabled) {
        return ThemeV2.palette.gray4;
      }
      return false;
    }};
  }
  &:focus {
    background: ${(props) => {
      if (!props.isValid) {
        return ThemeV2.palette.dustRed4;
      }
      if (!props.disabled) {
        return ThemeV2.palette.gray5;
      }
      return false;
    }};
  }
  &:active {
    background: ${(props) => {
      if (!props.isValid) {
        return ThemeV2.palette.dustRed4;
      }
      if (!props.disabled) {
        return ThemeV2.palette.gray5;
      }
      return false;
    }};
    box-shadow: none;
  }

  .avatarCircle {
    margin-right: -4px;
    color: ${ThemeV2.palette.gray8};
    width: 24px;
    height: 24px;
    font-size: 12px;
    margin-left: 4px;
  }
`;

ChipWrapper.displayName = 'ChipWrapper';

const ChipLabel = styled.span<ChipLabelProps>`
  color: ${(props) =>
    props.disabled ? ThemeV2.palette.gray6 : ThemeV2.palette.gray9};
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
`;
ChipLabel.displayName = 'ChipLabel';

const ChipDeleteIcon = styled.div<ChipDeleteIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.26);
  width: 22px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  height: 22px;
  margin: 0 0 0 -6px;
`;
ChipDeleteIcon.displayName = 'ChipDeleteIcon';

export const ChipAvatar = styled(Avatar)<ChipAvatarProps>`
  margin-right: -4px;
  color: ${({ iconColor }) => (iconColor ? iconColor : ThemeV2.palette.gray8)};
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 0;

  span {
    background-color: ${(props) => props.backgroundColor};

    svg {
      fill: initial;
      path {
        ${({ iconColor }) => {
          if (iconColor) {
            return {
              fill: iconColor,
            };
          }
        }}
      }
    }
  }
`;
ChipAvatar.displayName = 'ChipAvatar';

export interface ChipProps {
  avatar?: AvatarProps;
  label: string;
  validation?: (value: string) => boolean;
  onDelete?: () => void;
  onClick?: () => void;
  clickable?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string | number;
}

const Chip = (props: ChipProps) => {
  const {
    avatar,
    label,
    validation,
    onDelete,
    onClick,
    clickable,
    className,
    disabled,
  } = props;

  const { silver } = ThemeV2.palette;

  const onDeleteHandler = useCallback(() => {
    if (!disabled && onDelete) {
      onDelete();
    }
  }, [disabled, onDelete]);

  let isValid = true;

  if (validation) {
    isValid = validation(label);
  }

  return (
    <ChipWrapper
      className={className}
      clickable={clickable}
      role="button"
      disabled={disabled}
      onClick={onClick}
      isValid={isValid}
    >
      {avatar && <ChipAvatar {...avatar} />}
      <ChipLabel disabled={disabled}>{label}</ChipLabel>
      {onDelete && (
        <ChipDeleteIcon
          disabled={disabled}
          isValid={isValid}
          onClick={onDeleteHandler}
          data-testid="chip delete icon"
        >
          <SVGIcon
            icon="close-circle"
            color={isValid ? silver : ThemeV2.palette.dustRed6}
          />
        </ChipDeleteIcon>
      )}
    </ChipWrapper>
  );
};

Chip.defaultProps = {
  className: '',
};

export default Chip;
