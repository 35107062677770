import React from 'react';
import useAdminInsightsModel from './useAdminInsightsModel';
import useAdminInsightsLogic from './useAdminInsightsLogic';
import AdminAnalyticsPage from '../../../../atomic/pages/AdminAnalytics';

interface AdminInsightsPageProps {
  isSuperAdmin?: boolean;
  assemblyId?: string;
  assemblyName?: string;
}

const AdminInsightsPageController = ({
  isSuperAdmin,
  assemblyId,
  assemblyName,
}: AdminInsightsPageProps) => {
  const {
    actions,
    data: {
      peopleSummaryData,
      recognitionSummaryData,
      profileInfoData,
      peopleGraphData,
      recognitionGraphData,
      coreValuesGraphData,
      badgesGraphData,
      adminAnalyticsAccess,
      currentCompanyName,
      assemblyCreatedAt,
      overallEngagementStatus,
    },
    metadata: {
      badgesDataError,
      coreValuesDataError,
      recognitionDataError,
      peopleDataError,
      overallEngagementError,
      overallEngagementRequestLoading,
    },
  } = useAdminInsightsModel();
  const {
    models: {
      selectedValueId,
      selectedValueTimelineId,
      selectedPeopleTimelineId,
      selectedRecognitionTimelineId,
      isUpgradeModalOpen,
      selectedChartData,
      selectedChartError,
      selectedPeopleViewId,
      selectedRecognitionViewId,
      selectedValueViewId,
      monthlyTimelineItems,
      weeklyTimelineItems,
      valueDropdownItems,
      viewDropdownItems,
      showBookCallModal,
      showTipsModal,
    },
    operations: {
      onValueDropdownChange,
      toggleUpgradeModalOpen,
      onViewDropdownChange,
      onTimelineDropdownChange,
      toggleBookCallModal,
      toggleTipsModal,
    },
  } = useAdminInsightsLogic(
    actions,
    adminAnalyticsAccess,
    coreValuesGraphData,
    badgesGraphData,
    coreValuesDataError,
    badgesDataError,
    assemblyId,
    isSuperAdmin,
    assemblyCreatedAt,
  );
  return (
    <AdminAnalyticsPage
      adminAnalyticsAccess={adminAnalyticsAccess}
      peopleDataError={peopleDataError}
      peopleSummaryData={peopleSummaryData}
      peopleGraphData={peopleGraphData}
      selectedPeopleTimelineId={selectedPeopleTimelineId}
      profileInfoData={profileInfoData}
      onValueDropdownChange={onValueDropdownChange}
      selectedValueTimelineId={selectedValueTimelineId}
      selectedChartData={selectedChartData}
      selectedChartError={selectedChartError}
      selectedRecognitionTimelineId={selectedRecognitionTimelineId}
      selectedValueId={selectedValueId}
      recognitionSummaryData={recognitionSummaryData}
      recognitionGraphData={recognitionGraphData}
      recognitionDataError={recognitionDataError}
      toggleUpgradeModalOpen={toggleUpgradeModalOpen}
      isUpgradeModalOpen={isUpgradeModalOpen}
      isSuperAdmin={isSuperAdmin}
      onViewDropdownChange={onViewDropdownChange}
      selectedPeopleViewId={selectedPeopleViewId}
      selectedRecognitionViewId={selectedRecognitionViewId}
      selectedValueViewId={selectedValueViewId}
      assemblyName={assemblyName || currentCompanyName}
      onTimelineDropdownChange={onTimelineDropdownChange}
      monthlyTimelineDropdownItems={monthlyTimelineItems}
      weeklyTimelineDropdownItems={weeklyTimelineItems}
      valueDropdownItems={valueDropdownItems}
      viewDropdownItems={viewDropdownItems}
      overallEngagementStatus={overallEngagementStatus}
      overallEngagementError={overallEngagementError}
      overallEngagementRequestLoading={overallEngagementRequestLoading}
      showBookCallModal={showBookCallModal}
      toggleBookCallModal={toggleBookCallModal}
      showTipsModal={showTipsModal}
      toggleTipsModal={toggleTipsModal}
    />
  );
};

export default AdminInsightsPageController;
