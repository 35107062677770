import { SSOComponentProps } from '../../../pages/signin/interfaces';
import { SigninSubTextProps } from '../../molecules/SigninSubText';

import { CreateYourPasswordProps } from '../CreateYourPassword/interface';

export enum SigninOptionsType {
  SAML_ONLY = 'SAML_ONLY',
  SAML_WITH_SSO = 'SAML_WITH_SSO',
  SAML_WITH_PASSWORD = 'SAML_WITH_PASSWORD',
  NO_PREFERRED_SSO = 'NO_PREFERRED_SSO',
  SAML_WITH_NO_PREFERRED_SSO = 'SAML_WITH_NO_PREFERRED_SSO',
  SSO_ENFORCED = 'SSO_ENFORCED',
  CREATE_PASSWORD = 'CREATE_PASSWORD',
  CREATE_PASSWORD_WITH_NO_PREFERRED_SSO = 'CREATE_PASSWORD_WITH_NO_PREFERRED_SSO',
  SAML_WITH_CREATE_PASSWORD = 'SAML_WITH_CREATE_PASSWORD',
}

export interface HeaderProps {
  headingText: string;
  bodyText?: string;
}

export interface SAMLProps extends HeaderProps {
  samlButtonText: string;
  samlIcon: string;
  samlURL: string;
}

export interface PasswordInitialValue {
  password: string;
}

export interface PasswordProps {
  initialValues: PasswordInitialValue;
  onSubmit: (values: PasswordInitialValue) => void;
  loading?: boolean;
  errors?: string;
}

export interface CreatePasswordProps {
  onClick: () => void;
  isLoading?: boolean;
}

interface SAMLOnly {
  signinOptionsType: SigninOptionsType.SAML_ONLY;
  samlProps: SAMLProps;
  subTextProps: SigninSubTextProps;
  className?: string;
}

interface SAMLWithSSO {
  signinOptionsType: SigninOptionsType.SAML_WITH_SSO;
  samlProps: SAMLProps;
  ssoComponentProps: SSOComponentProps;
  subTextProps: SigninSubTextProps;
  className?: string;
}

interface SAMLWithPassword {
  signinOptionsType: SigninOptionsType.SAML_WITH_PASSWORD;
  samlProps: SAMLProps;
  passwordProps: PasswordProps;
  subTextProps: SigninSubTextProps;
  className?: string;
}

interface NoPreferredSSO extends HeaderProps {
  signinOptionsType: SigninOptionsType.NO_PREFERRED_SSO;
  passwordProps: PasswordProps;
  subTextProps: SigninSubTextProps;
  ssoComponentProps: SSOComponentProps;
  className?: string;
}
interface SAMLWithNoPreferredSSO {
  signinOptionsType: SigninOptionsType.SAML_WITH_NO_PREFERRED_SSO;
  ssoComponentProps: SSOComponentProps;
  samlProps: SAMLProps;
  subTextProps: SigninSubTextProps;
  className?: string;
}

interface CreatePassword extends HeaderProps {
  signinOptionsType: SigninOptionsType.CREATE_PASSWORD;
  createPasswordProps: CreatePasswordProps;
  subTextProps: SigninSubTextProps;
  ssoComponentProps: SSOComponentProps;
  className?: string;
}

interface SSOEnforced extends HeaderProps {
  signinOptionsType: SigninOptionsType.SSO_ENFORCED;
  subTextProps: SigninSubTextProps;
  ssoComponentProps: SSOComponentProps;
  className?: string;
}

interface CreatePasswordWithNoPreferredSSO extends HeaderProps {
  signinOptionsType: SigninOptionsType.CREATE_PASSWORD_WITH_NO_PREFERRED_SSO;
  ssoComponentProps: SSOComponentProps;
  createPasswordProps: CreateYourPasswordProps;
  subTextProps: SigninSubTextProps;
  className?: string;
}

interface SAMLWithCreatePassword {
  signinOptionsType: SigninOptionsType.SAML_WITH_CREATE_PASSWORD;
  samlProps: SAMLProps;
  createPasswordProps: CreateYourPasswordProps;
  subTextProps: SigninSubTextProps;
  className?: string;
}

/* Interface required for SigninOptions */
export type SigninOptionsProps =
  | SAMLOnly
  | SAMLWithSSO
  | SAMLWithPassword
  | NoPreferredSSO
  | SAMLWithNoPreferredSSO
  | CreatePassword
  | SSOEnforced
  | CreatePasswordWithNoPreferredSSO
  | SAMLWithCreatePassword;

/* Interface required for SigninOptionsLayout starts here */
export enum SigninOptionsLayoutType {
  SAML = 'SAML',
  NO_PREFERRED_SSO = 'NO_PREFERRED_SSO',
  SAML_WITH_NO_PREFERRED_SSO = 'SAML_WITH_NO_PREFERRED_SSO',
  SSO_ENFORCED = 'SSO_ENFORCED',
}
interface SigninOptionsLayoutSAMLProps {
  templateType: SigninOptionsLayoutType.SAML;
  samlProps: SAMLProps;
  subTextProps: SigninSubTextProps;
  children?: React.ReactNode;
  className?: string;
}

interface SigninOptionsLayoutTypeNoPreferredSSOProps {
  templateType: SigninOptionsLayoutType.NO_PREFERRED_SSO;
  headingText: string;
  bodyText?: string;
  children: React.ReactNode;
  subTextProps: SigninSubTextProps;
  ssoComponentProps: SSOComponentProps;
  className?: string;
}
interface SigninOptionsLayoutTypeSAMLWithNoPreferredSSOProps {
  templateType: SigninOptionsLayoutType.SAML_WITH_NO_PREFERRED_SSO;
  samlProps: SAMLProps;
  subTextProps: SigninSubTextProps;
  ssoComponentProps: SSOComponentProps;
  className?: string;
}

interface SigninOptionsLayoutTypeSSOEnforcedProps {
  templateType: SigninOptionsLayoutType.SSO_ENFORCED;
  headingText: string;
  bodyText?: string;
  subTextProps: SigninSubTextProps;
  ssoComponentProps: SSOComponentProps;
  className?: string;
}

export type SigninOptionsLayoutProps =
  | SigninOptionsLayoutSAMLProps
  | SigninOptionsLayoutTypeNoPreferredSSOProps
  | SigninOptionsLayoutTypeSAMLWithNoPreferredSSOProps
  | SigninOptionsLayoutTypeSSOEnforcedProps;
/* Interface required for SigninOptionsLayout ends here */
