// @ts-nocheck TODO: type issues need to be fixed in this file
import { requestsReducer, success } from 'redux-saga-requests';
import _ from 'lodash';
import { AuthHeadersV2 } from '../../../../modules/shared/HeaderToken';
import { API_URL_WITH_V2 } from '../../../../config';

const apiEndpoints = {
  EMPLOYER_SLACK_DETAILS: `${API_URL_WITH_V2}/integrations/slack/employer/details`,
  EMPLOYER_SLACK_MEMBERS_ANNOUNCEMENT: `${API_URL_WITH_V2}/integrations/slack/employer/sendWelcomeMessageToUsers`,
};

export const EMPLOYER_SLACK_DETAILS = '@admin/slack/EMPLOYER_SLACK_DETAILS';
export const EMPLOYER_SLACK_MEMBERS_ANNOUNCEMENT =
  '@admin/slack/EMPLOYER_SLACK_MEMBERS_ANNOUNCEMENT';

export const getEmployerSlackDetails = () => ({
  type: EMPLOYER_SLACK_DETAILS,
  request: {
    url: apiEndpoints.EMPLOYER_SLACK_DETAILS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const postSlackAnnouncementToUsers = ({ userIds }) => ({
  type: EMPLOYER_SLACK_MEMBERS_ANNOUNCEMENT,
  request: {
    url: apiEndpoints.EMPLOYER_SLACK_MEMBERS_ANNOUNCEMENT,
    data: {
      userIds,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export default requestsReducer({
  actionType: EMPLOYER_SLACK_DETAILS,
  getData: (state, action, config) => {
    switch (action.type) {
      case success(EMPLOYER_SLACK_DETAILS): {
        const { details } = _.get(action, ['data', 'data'], {});
        return { ...state.data, ...details };
      }
      default:
        return state;
    }
  },
});
