import { useQuery } from 'react-query';

import { GET_MULTIPLE_JOBS_STATUS } from '../../constants/endpoints';
import { ResponseError } from '../../interfaces/ResponseError';
import { makeAPICallWithDataReturn } from '../utils';

type JobStatus = 'QUEUED' | 'PROCESSING' | 'ERROR' | 'SUCCESS';
export type JobsPollingResponse = {
  id: string;
  status: JobStatus;
  name: string;
};

export interface JobsStatusResponse {
  data: JobsPollingResponse[];
}

export const useMultipleJobsStatusQuery = (jobIds?: string[]) => {
  return useQuery<JobsStatusResponse, ResponseError>(
    [GET_MULTIPLE_JOBS_STATUS, jobIds],
    () =>
      makeAPICallWithDataReturn(
        GET_MULTIPLE_JOBS_STATUS,
        undefined,
        undefined,
        {
          jobIds: jobIds?.join(',') || '',
        },
      ),
    {
      staleTime: Infinity,
      refetchInterval: 3000,
      enabled: jobIds !== undefined,
    },
  );
};
