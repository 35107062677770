// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';

const Authorization = (allowedRoles) => (WrappedComponent) =>
  class WithAuthorization extends Component {
    constructor(props) {
      super(props);
      const me = JSON.parse(localStorage.getItem('user'));
      let hasRole = false;
      if (me) {
        const { role } = me;

        hasRole = role.find((rol) => {
          if (allowedRoles.includes(rol)) {
            return true;
          }
          return null;
        });
      }

      if (!hasRole) {
        props.history.push('/404');
      }
      // if (!allowedRoles.includes(role)) {
      //   props.history.push('/404');
      // }
    }

    render() {
      const me = JSON.parse(localStorage.getItem('user'));
      let hasRole = false;
      if (me) {
        const { role } = me;
        hasRole = role.find((rol) => {
          if (allowedRoles.includes(rol)) {
            return true;
          }
          return null;
        });
      }

      if (hasRole) {
        return <WrappedComponent {...this.props} />;
      }
      return null;
    }
  };

export default Authorization;
