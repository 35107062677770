import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import { get } from 'lodash';
import { parse } from 'qs';
import { APP_URL } from '../../../config';
import RoutesList from '../../../aV2/routes/routesList';
import useRequest from '../../../hooks/useRequest/useRequest';
import {
  GET_SSO_SIGNIN_CONFIG,
  GET_SSO_SIGNUP_CONFIG,
} from '../../../constants/endpoints';
import { SSOOptions, SSOTypes } from '../../../Utils/data/ssoManager/common';
import { isSourceMSTeams, MS_TEAMS } from '../../../Utils/msteams';

const useFetchSSOConfig = (
  sso: string,
  ssoType: SSOTypes,
  authInModal: boolean,
) => {
  const [isConnectingSSO, setIsConnectingSSO] = useState(false);
  const [ssoAuthorizeURI, setSSOAuthorizeURI] = useState('');
  const { push } = useHistory();
  const { search } = useLocation();
  const isFromMSTeams = isSourceMSTeams();

  const redirectToIM = useCallback((authorizeUri: string) => {
    window.location.href = authorizeUri;
  }, []);

  const ssoURL = useMemo(
    () =>
      `/${RoutesList.SSO_AUTHORIZE_MODAL_FN(
        sso,
        ssoType,
      )}?auto=true&source=${MS_TEAMS}`,
    [sso, ssoType],
  );
  const msTeamsOAuthStart = useCallback(() => {
    microsoftTeams.initialize();
    /* To notify app loaded to hide loading indicator */
    microsoftTeams.appInitialization.notifyAppLoaded();
    microsoftTeams.appInitialization.notifySuccess();
    microsoftTeams.authentication.authenticate({
      url: ssoURL,
      width: 600,
      height: 535,
      successCallback(result) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { code } = result;
        push(
          `/${RoutesList.SSO_AUTHORIZE_FN(sso, ssoType)}?code=${code}&from=tab`,
        );
      },
      failureCallback(reason) {
        push(`/${RoutesList.SSO_ERRORS_FN(sso, ssoType, reason)}`);
      },
    });
  }, [push, sso, ssoType, ssoURL]);

  const onGetSSOConfigSuccess = useCallback(
    (data) => {
      const authorizeURI = get(data, ['data', 'authorizeUri'], null);
      setSSOAuthorizeURI(authorizeURI);
      const parsedParams = parse(search, {
        ignoreQueryPrefix: true,
      });
      const { auto, code } = parsedParams;
      if (auto === 'true' && !code) {
        setIsConnectingSSO(true);
        if (!isFromMSTeams || authInModal) {
          redirectToIM(authorizeURI);
        }
      }
    },
    [authInModal, isFromMSTeams, redirectToIM, search],
  );

  const handleError = useCallback(() => {
    // dispatch(showErrorMessage(message));
  }, []);
  const { fireRequest: signinSSORequest, isLoading: signinRequestLoading } =
    useRequest(GET_SSO_SIGNIN_CONFIG, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      onSuccess: onGetSSOConfigSuccess,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      onError: handleError,
    });

  const { fireRequest: signupSSORequest, isLoading: signupRequestLoading } =
    useRequest(GET_SSO_SIGNUP_CONFIG, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      onSuccess: onGetSSOConfigSuccess,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      onError: handleError,
    });

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const { auto, code } = parsedParams;
    if (auto === 'true' && !code) {
      setIsConnectingSSO(true);
    }

    if (sso !== SSOOptions.GOOGLE && sso !== SSOOptions.LIFION) {
      if (auto === 'true' && isFromMSTeams && !authInModal) {
        msTeamsOAuthStart();
      } else {
        if (ssoType === SSOTypes.SIGN_IN) {
          let REDIRECT_URI;
          if (sso === SSOOptions.ADP) {
            // ADP uses single URL for Redirection, so reusing IM URL
            REDIRECT_URI = `${APP_URL}${RoutesList.IM_AUTHORIZE_SSO(sso)}`;
          } else if (authInModal) {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_AUTHORIZE_MODAL_FN(
              sso,
              ssoType,
            )}`;
          } else {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
              sso,
            )}`;
          }
          signinSSORequest({
            params: { redirectUri: REDIRECT_URI },
            substitutions: { sso },
          });
        }
        // Skipping for ADP as we redirect user to ADP Marketplace on Sign Up
        if (ssoType === SSOTypes.SIGN_UP && sso !== SSOOptions.ADP) {
          let REDIRECT_URI;
          if (authInModal) {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_AUTHORIZE_MODAL_FN(
              sso,
              ssoType,
            )}`;
          } else {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_SIGNUP_AUTHORIZE_FN(
              sso,
            )}`;
          }
          signupSSORequest({
            params: { redirectUri: REDIRECT_URI },
            substitutions: { sso },
          });
        }
      }
    }
  }, [
    sso,
    signinSSORequest,
    ssoType,
    signupSSORequest,
    search,
    isFromMSTeams,
    msTeamsOAuthStart,
    authInModal,
  ]);

  return {
    models: {
      ssoAuthorizeURI,
      isLoading:
        signupRequestLoading || signinRequestLoading || isConnectingSSO,
    },
    operations: {
      msTeamsOAuthStart,
    },
  };
};

export default useFetchSSOConfig;
