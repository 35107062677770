import styled from 'styled-components';
import { StyledComponentProps } from './interface';
import SVGIcon from '../../atoms/SVGIcon';
import IconButton from '../../atoms/IconButton_V2';
import ThemeV2 from '../../../componentsV2/theme';

export const sizeVariant = {
  sm: '32px',
  md: '40px',
  lg: '48px',
};

const labelYPosition = {
  sm: '8px',
  md: '12px',
  lg: '16px',
};

export const textFieldInputHeight = {
  sm: '22px',
  md: '30px',
  lg: '38px',
};

export const renderStatusColor = (
  defaultColor: string,
  { isFocused, hasError, disabled, isActivated, icon }: StyledComponentProps,
) => {
  let color = defaultColor;
  if (!disabled) {
    if (!hasError && isActivated) {
      color = ThemeV2.palette.gray9;
    } else if (!hasError && isFocused && !isActivated) {
      color = ThemeV2.palette.geekBlue6;
    } else if (hasError) {
      color = ThemeV2.palette.red6;
    }
  } else if (icon !== '') {
    color = ThemeV2.palette.gray6;
  } else {
    color = ThemeV2.palette.gray3;
  }

  return color;
};

export const renderInputPlaceholder = ({
  isFocused,
  label,
  hasError,
  disabled,
}: StyledComponentProps) => {
  let value = 'hidden';
  if (label !== '') {
    if (isFocused || hasError) {
      if (!disabled) {
        value = 'visible';
      }
    }
  } else {
    value = 'visible';
  }
  return value;
};

export const TextInputFieldWrapper = styled.div`
  position: relative;
`;
TextInputFieldWrapper.displayName = 'TextInputFieldWrapper';

export const FormLabel = styled.label<StyledComponentProps>`
  position: absolute;
  z-index: 2;
  transform: translate(14px, 20px) scale(1);
  transform: ${(props) =>
    props.isFocused
      ? 'translate(0px, -8px) scale(0.71)'
      : `translate(8px, ${labelYPosition[props.inputSize || 'md']}) scale(1)`};
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: 14px;
  font-weight: ${ThemeV2.typography.fontWeightMedium};
  font-style: normal;
  padding: 0 4px;
  background: ${(props) =>
    props.disabled ? ThemeV2.palette.gray3 : ThemeV2.palette.gray1};
  color: ${(props) => renderStatusColor(ThemeV2.palette.gray5, props)};
  transition: 0.15s ease;
  user-select: ${(props) => props.disabled && 'none'};
  cursor: inherit;
  line-height: 1.2;
`;
FormLabel.displayName = 'FormLabel';

export const TextFieldRoot = styled.div<StyledComponentProps>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: ${(props) => props.inputSize && sizeVariant[props.inputSize]};
  line-height: 1;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.disabled
        ? ThemeV2.palette.gray3
        : renderStatusColor(ThemeV2.palette.gray6, props)};
  transition: 0.3s ease;
  background: ${(props) =>
    props.disabled ? ThemeV2.palette.gray3 : ThemeV2.palette.gray1};
  padding-right: 45px;
  z-index: ${(props) => props.isFocused && '1'};
  &:hover {
    box-shadow: ${(props) =>
      props.disabled
        ? 'none'
        : '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12)'};
  }
`;
TextFieldRoot.displayName = 'TextFieldRoot';

export const InputIcon = styled(SVGIcon)<StyledComponentProps>`
  display: flex;
  box-sizing: content-box;
  padding: 4px 0 4px 8px;
  & svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    & path {
      fill: ${(props) =>
        renderStatusColor(props.color || ThemeV2.palette.gray9, props)};
    }
  }
`;
InputIcon.displayName = 'InputIcon';

export const InputDropdownButton = styled(IconButton)<StyledComponentProps>`
  position: absolute;
  right: 10px;
  & span {
    min-width: auto;
    & svg path {
      fill: ${(props) =>
        props.isFocused ? ThemeV2.palette.gray9 : ThemeV2.palette.gray6};
    }
  }
`;
InputDropdownButton.displayName = 'InputDropdownButton';

export const InputClearButton = styled(
  InputDropdownButton,
)<StyledComponentProps>`
  right: ${(props) => (props.dropdownItems ? '40px' : '10px')};
`;

export const TextFieldInput = styled.input<StyledComponentProps>`
  height: ${(props) =>
    props.inputSize && textFieldInputHeight[props.inputSize]};
  padding: ${(props) =>
    `3px 0px 3px ${props.icon || props.hasPrefix ? '0px' : '4px'}`};
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
  flex: 1;
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: 14px;
  line-height: 1;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-style: normal;
  text-align: ${(props) => (props.hasSuffix ? 'right' : 'left')};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) =>
    !props.isFocused && !props.disabled
      ? 'transparent'
      : ThemeV2.palette.gray9};
  & ::placeholder,
  ::-webkit-input-placeholder {
    visibility: ${(props) => renderInputPlaceholder(props)};
    color: ${ThemeV2.palette.gray6};
  }
  &:focus {
    &::placeholder,
    ::-webkit-input-placeholder {
      visibility: visible;
    }
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;
TextFieldInput.displayName = 'TextFieldInput';

export const AppendText = styled.p<StyledComponentProps>`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: 14px;
  line-height: 22px;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-style: normal;
  color: ${(props) =>
    props.isFocused ? ThemeV2.palette.gray7 : ThemeV2.palette.gray6};
  margin-top: 0;
  margin-bottom: 0;
`;
AppendText.displayName = 'AppendText';

export const PrefixText = styled(AppendText)<StyledComponentProps>`
  margin-right: 4px;
  display: ${(props) => (props.isFocused ? 'inline' : 'none')};
  margin-left: ${(props) => (props.icon ? '0x' : '4px')};
`;
PrefixText.displayName = 'PrefixText';

export const HelperText = styled.p<StyledComponentProps>`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: 10px;
  line-height: 20px;
  font-weight: ${ThemeV2.typography.fontWeightMedium};
  padding: 4px 0 0 12px;
  margin: 0;
  color: ${(props) =>
    props.hasError ? ThemeV2.palette.red6 : ThemeV2.palette.gray7};
  visibility: ${(props) => props.disabled && 'hidden'};
  user-select: ${(props) => props.disabled && 'none'};
`;
HelperText.displayName = 'HelperText';

export const ChipMoreItem = styled.span`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: ${ThemeV2.typography.fontWeightRegular};
`;

ChipMoreItem.displayName = 'ChipMoreItem';

export const NoOption = styled.div`
  padding: 4px 8px;
  color: #595959;
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-size: 14px;
`;

export const TextFieldRootWrapper = styled.div`
  width: 100%;
  border: none;
  outline: 0;
  background: transparent;
  padding: 0;
`;
