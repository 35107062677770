import ThemeV2 from '../../componentsV2/theme';

export const colors = [
  { backgroundColor: ThemeV2.palette.blue1, color: ThemeV2.palette.blue7 },
  { backgroundColor: ThemeV2.palette.red1, color: ThemeV2.palette.dustRed7 },
  { backgroundColor: ThemeV2.palette.gold1, color: ThemeV2.palette.gold7 },
  { backgroundColor: ThemeV2.palette.green1, color: ThemeV2.palette.green7 },
  { backgroundColor: ThemeV2.palette.cyan1, color: ThemeV2.palette.cyan7 },
  { backgroundColor: ThemeV2.palette.purple1, color: ThemeV2.palette.purple7 },
  { backgroundColor: ThemeV2.palette.blue1, color: ThemeV2.palette.blue4 },
  { backgroundColor: ThemeV2.palette.red1, color: ThemeV2.palette.dustRed4 },
  { backgroundColor: ThemeV2.palette.gold1, color: ThemeV2.palette.gold4 },
  { backgroundColor: ThemeV2.palette.green1, color: ThemeV2.palette.green4 },
  { backgroundColor: ThemeV2.palette.cyan1, color: ThemeV2.palette.cyan4 },
  { backgroundColor: ThemeV2.palette.purple1, color: ThemeV2.palette.purple4 },
  { backgroundColor: ThemeV2.palette.blue1, color: ThemeV2.palette.blue9 },
  { backgroundColor: ThemeV2.palette.red1, color: ThemeV2.palette.dustRed9 },
  { backgroundColor: ThemeV2.palette.gold1, color: ThemeV2.palette.gold9 },
  { backgroundColor: ThemeV2.palette.green1, color: ThemeV2.palette.green9 },
  { backgroundColor: ThemeV2.palette.cyan1, color: ThemeV2.palette.cyan9 },
  { backgroundColor: ThemeV2.palette.purple1, color: ThemeV2.palette.purple9 },
];
