import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

export const DropdownItemContainer = styled.div<{
  isPrefixIconRequired?: boolean;
  disabled?: boolean;
  isSelected?: boolean;
  deleteItem?: boolean;
  disableHoverActions?: boolean;
}>`
  white-space: nowrap;
  min-width: 158px;
  padding: 4px 8px;
  border-radius: ${({ isSelected }) => !isSelected && '4px'};
  background: ${ThemeV2.palette.gray1};
  display: flex;
  align-items: center;
  text-align: left;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ isSelected, disabled, deleteItem }) => {
    if (disabled) {
      return ThemeV2.palette.gray6;
    }
    if (deleteItem) {
      return ThemeV2.palette.dustRed6;
    }
    if (isSelected) {
      return ThemeV2.palette.geekBlue6;
    }
    return ThemeV2.palette.gray8;
  }};
  & > span {
    & > div:last-of-type {
      color: ${ThemeV2.palette.gray1};
    }
  }
  div:last-of-type {
    flex-grow: 1;
    color: currentColor;
  }

  ${({ disableHoverActions, disabled, deleteItem }) =>
    !disableHoverActions &&
    `
    &:hover {
      background: ${
        disabled ? ThemeV2.palette.gray1 : ThemeV2.palette.geekBlue1
      };
      color: ${
        // eslint-disable-next-line no-nested-ternary
        disabled
          ? ThemeV2.palette.gray6
          : deleteItem
          ? ThemeV2.palette.dustRed6
          : ThemeV2.palette.geekBlue6
      }
    }
  `};
`;

export const PrefixContainer = styled.span<{ prefixIcon?: boolean }>`
  margin-right: 8px;
  height: ${({ prefixIcon }) => (prefixIcon ? '15px' : 'initial')};
  span {
    color: currentColor;
    & > svg {
      path {
        fill: currentColor;
      }
    }
  }
`;
