// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import { verifyCode, resendCode } from '../../modules/onboarding';

import Link from '../../../componentsV2/core/Link';
import { handleErrorAction } from '../../utils/ErrorHandler';
import {
  getEmailRegistered,
  getVerifyRequestLoadingStatus,
  getResendCodeRequestLoadingStatus,
} from '../../modules/onboarding/selectors';
import RoutesList from '../../routes/routesList';
import themeV2 from '../../../componentsV2/theme';
import OtpInput from '../../../componentsV2/core/OTPInput/BaseOtpInput';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    padding: '40px 55px 25px 55px',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  otpContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  body: {
    flex: 1,
  },
  bodyHead: {
    marginBottom: '24px',
  },
  bodyTitle: {
    marginTop: '20px',
  },
  bodyText: {
    marginBottom: '32px',
  },
  textCenter: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '500',
  },
  label: {
    display: 'block',
    fontSize: '12px',
    width: 'calc(100% - 24px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    background: '#F9F9F9',
    borderRadius: '3px',
    padding: '12px',
    marginTop: '8px',
    marginBottom: '24px',
    fontWeight: '600',
  },
  button: {
    display: 'block',
    marginTop: '8px',
    width: '100%',
  },
  secondaryDivStyle: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  progresStyle: {
    color: themeV2.palette.greenLight,
  },
  successDivStyle: {
    color: themeV2.palette.greenLight,
  },
  errorDivStyle: {
    color: themeV2.palette.red2,
  },
});

class SignupSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      codeErrors: null,
      codeVerified: false,
      buttonDisabled: true,
      resendCodeSuccess: false,
    };
  }

  componentDidMount() {
    this.activateLink();
  }

  activateLink = () => {
    setTimeout(() => {
      this.setState({
        buttonDisabled: false,
      });
    }, 30000);
  };

  handleOtpChange = (code) => {
    this.setState(
      () => ({ code }),
      () => {
        if (code.length === 6) {
          this.verifyCode();
        }
      },
    );
  };

  resendCode = (e) => {
    e.preventDefault();
    const { match } = this.props;
    const { userId } = match.params;

    this.props
      .resendCode({ userId })
      .then((successAction) => {
        this.setState({ resendCodeSuccess: true });
        this.setState({ buttonDisabled: true });
        this.activateLink();
      })
      .catch((errorOrAbortAction) => {});
  };

  verifyCode = () => {
    let { code } = this.state;
    const { match } = this.props;
    const { userId } = match.params;
    code = code.trim();

    this.setState({ codeErrors: null });
    if (userId) {
      // TODOV2: Remove this
      if (code === '000000') {
        code = '0000';
      }
      this.props
        .verifyCode({ userId, codeToVerify: code })
        .then((successAction) => {
          this.setState({ codeVerified: true });
          const { history } = this.props;
          const newUserId = _.get(successAction, ['data', 'data', '_id'], null);
          history.push(`${RoutesList.ENTER_COMPANY_NAME}/${newUserId}`);
        })
        .catch((errorOrAbortAction) => {
          handleErrorAction(errorOrAbortAction);
          this.setState({
            codeErrors:
              'Whoops, this code doesn’t match what we sent, check your email and try again.',
          });
        });
    }
  };

  render() {
    const { classes, isRequestLoading, isResendRequestLoading, email } =
      this.props;
    const { code, codeErrors, codeVerified, resendCodeSuccess } = this.state;
    if (!email) {
      return <Redirect to="/create-account" />;
    }

    return (
      <Layout>
        <>
          <Card className={classes.card}>
            <div className={classes.wrapper}>
              <div className={classes.body}>
                <div className={classes.bodyHead}>
                  <Typography variant="h5" weight="bold">
                    {/* 🎉 Congrats, you signed up! */}
                    Check your email
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.bodyTitle}
                    gutterBottom
                  >
                    {/* We take security seriously, for you. We sent you an email
                      to verify your identity.
                      <b>
                        Please go to the email we sent and click the “Verify My
                        E-mail Address” button.
                      </b> */}
                    You will receive a temporary 6 digit confirmation code at{' '}
                    <b>{email}</b>, enter it quickly.
                  </Typography>
                </div>
                <div className={classes.bodyText}>
                  {/* <Typography>
                      <div className={(classes.wrapper, classes.textCenter)}>
                        We sent an email to:
                      </div>
                      <div className={classes.label}>{email}</div>
                    </Typography>
                    <Typography variant="body2">
                      <b>Didn’t get an email?</b> Check your spam folder before
                      resending. It can take a few moments.
                    </Typography> */}
                  <div className={classes.otpContainerStyle}>
                    <OtpInput
                      numInputs={6}
                      isDisabled={isRequestLoading}
                      hasErrored={!!codeErrors}
                      errorStyle="error"
                      onChange={this.handleOtpChange}
                      isInputNu
                      shouldAutoFocus
                      value={code}
                    />
                  </div>
                  {codeVerified && (
                    <div className={classes.secondaryDivStyle}>
                      <CheckIcon className={classes.successDivStyle} />
                      &nbsp;&nbsp;
                      <span className={classes.successDivStyle}>
                        {' '}
                        Code verified.
                      </span>
                    </div>
                  )}
                  {isRequestLoading && (
                    <div className={classes.secondaryDivStyle}>
                      <CircularProgress
                        size={24}
                        className={classes.progresStyle}
                      />
                      &nbsp;&nbsp;
                      <span className={classes.successDivStyle}>
                        {' '}
                        Verifying code.
                      </span>
                    </div>
                  )}
                  {codeErrors && !isRequestLoading && (
                    <div className={classes.secondaryDivStyle}>
                      <div className={classes.errorDivStyle}>{codeErrors}</div>
                    </div>
                  )}
                </div>
                {/* <div>
                    <Typography variant="body2">
                      You can resend: 30 seconds
                    </Typography>
                    <Button
                      disabled={buttonDisabled}
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={this.resendCode}
                    >
                      Resend Verification Email
                    </Button>
                  </div> */}
                <div>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ marginTop: 5 }}
                  >
                    Keep this window open while checking your email. If you
                    can’t find your code, check your spam or junk folder or{' '}
                    <Link to="" onClick={this.resendCode}>
                      resend code
                    </Link>
                    .
                  </Typography>
                </div>
                <div>
                  {isResendRequestLoading && (
                    <div className={classes.secondaryDivStyle}>
                      <CircularProgress
                        size={24}
                        className={classes.progresStyle}
                      />
                      &nbsp;&nbsp;
                      <span className={classes.successDivStyle}>
                        {' '}
                        Resending code.
                      </span>
                    </div>
                  )}
                  {resendCodeSuccess && !isResendRequestLoading && (
                    <div className={classes.secondaryDivStyle}>
                      <CheckIcon className={classes.successDivStyle} />
                      &nbsp;&nbsp;
                      <span className={classes.successDivStyle}>
                        {' '}
                        Code resent.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  email: getEmailRegistered(state),
  isRequestLoading: getVerifyRequestLoadingStatus(state),
  isResendRequestLoading: getResendCodeRequestLoadingStatus(state),
});

export default connect(mapStateToProps, { verifyCode, resendCode })(
  withStyles(styles)(SignupSuccess),
);
