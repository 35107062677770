const accountManagementTypes = {
  ALL_USERS: 'ALL_USERS',
  BUSINESS_UNIT: 'BUSINESS_UNIT',
  DEPARTMENT: 'DEPARTMENT',
  WORK_LOCATION: 'WORK_LOCATION',
  SELECTED_USERS: 'SELECTED_USERS',
  GROUPS: 'GROUPS',
  COMPANIES: 'COMPANIES',
  CHANNELS: 'CHANNELS',
  ORGANIZATIONAL_UNIT: 'ORGUNITS',
};

export default accountManagementTypes;
