import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import HeaderNavbar from '../../organism/HeaderNavbar';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: ThemeV2.palette.geekBlue2,
  },
}));

interface SSOOnboardingTemplateProps {
  logoURL: string;
  children: React.ReactNode;
}

const SSOOnboardingTemplate = ({
  logoURL,
  children,
}: SSOOnboardingTemplateProps) => {
  const classes = useStyles();
  const { push } = useHistory();
  const onLogoClick = useCallback(() => {
    push(logoURL);
  }, [push, logoURL]);

  return (
    <div className={classes.root}>
      <HeaderNavbar logoOnClick={onLogoClick} />
      {children}
    </div>
  );
};

SSOOnboardingTemplate.defaultProps = {
  logoURL: '/',
};

export default SSOOnboardingTemplate;
