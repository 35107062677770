import pluralize from 'pluralize';
import { A, AN, FOR } from '../../../languages/en/singleWords';
import accountManagementTypes from '../../identity/accountManagementTypes';

export const accountManagementTypeLanguageMap = {
  [accountManagementTypes.ALL_USERS]: 'Everyone',
  [accountManagementTypes.BUSINESS_UNIT]: 'Business Unit',
  [accountManagementTypes.DEPARTMENT]: 'Department',
  [accountManagementTypes.SELECTED_USERS]: 'People',
  [accountManagementTypes.CHANNELS]: 'Channel',
  [accountManagementTypes.GROUPS]: 'Group',
  [accountManagementTypes.COMPANIES]: 'Company',
  [accountManagementTypes.ORGANIZATIONAL_UNIT]: 'Organizational Unit',
  [accountManagementTypes.WORK_LOCATION]: 'Work Location',
};

export const identityManagerSteps = {
  WHAT_IS_THIS: 'WHAT_IS_THIS',
  SETUP_INFO: 'SETUP_INFO',
  AUTHORIZE_ADP: 'AUTHORIZE_ADP',
  SELECT_PEOPLE: 'SELECT_PEOPLE',
  CONFIGURE_INVITES: 'CONFIGURE_INVITES',
  CREATE_ACCOUNTS: 'CREATE_ACCOUNTS',
};

export const buttonText = {
  STEP_WHAT_IS_THIS_NEXT: 'Continue',
  STEP_WHAT_IS_THIS_CANCEL: 'Cancel',
  STEP_SETUP_UP_INFO_NEXT: 'Connect :IMDisplayName',
  STEP_AUTHORIZE_ADP_NEXT: 'Authorize',
  STEP_SELECT_PEOPLE_NEXT_DEFAULT: 'Continue',
  STEP_SELECT_PEOPLE_NEXT_EDIT: 'Continue with Updates',
  STEP_SELECT_PEOPLE_NEXT_ALL_USERS: `Continue with ${
    accountManagementTypeLanguageMap[accountManagementTypes.ALL_USERS]
  }`,
  STEP_SELECT_PEOPLE_NEXT_SELECTED_USERS: `Continue with :count ${
    accountManagementTypeLanguageMap[accountManagementTypes.SELECTED_USERS]
  }`,
  STEP_CONFIGURE_INVITES_NEXT: 'Create Assembly Accounts',
  STEP_CONFIGURE_INVITES_BACK: 'Back',
  STEP_CREATE_ACCOUNTS_NEXT_DEFAULT: 'View People',
  STEP_CREATE_ACCOUNTS_NEXT_QUEUED_INVITES: 'View Queued Invites',
  STEP_CREATE_ACCOUNTS_BACK: 'Disconnect :IMDisplayName',
};

export const getSelectUsersNextButtonText = (
  accountManagementType: string,
  count: number,
) => {
  return `Continue with ${count} ${
    count > 1
      ? pluralize(accountManagementTypeLanguageMap[accountManagementType])
      : accountManagementTypeLanguageMap[accountManagementType]
  }`;
};

export const selectPeopleEntityTableHeadings = {
  [accountManagementTypes.ALL_USERS]: {
    allList: '',
    newList: '',
    removeList: '',
  },
  [accountManagementTypes.BUSINESS_UNIT]: {
    allList: `All ${
      accountManagementTypeLanguageMap[accountManagementTypes.BUSINESS_UNIT]
    }s :operation (:count):`,
    newList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.BUSINESS_UNIT]
    }s being added (:count members):`,
    removeList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.BUSINESS_UNIT]
    }s being removed (:count members):`,
  },
  [accountManagementTypes.DEPARTMENT]: {
    allList: `All ${
      accountManagementTypeLanguageMap[accountManagementTypes.DEPARTMENT]
    }s :operation (:count):`,
    newList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.DEPARTMENT]
    }s being added (:count members):`,
    removeList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.DEPARTMENT]
    }s being removed (:count members):`,
  },
  [accountManagementTypes.CHANNELS]: {
    allList: `All ${
      accountManagementTypeLanguageMap[accountManagementTypes.CHANNELS]
    }s :operation (:count):`,
    newList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.CHANNELS]
    }s being added (:count members):`,
    removeList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.CHANNELS]
    }s being removed (:count members):`,
  },
  [accountManagementTypes.GROUPS]: {
    allList: `All ${
      accountManagementTypeLanguageMap[accountManagementTypes.GROUPS]
    }s :operation (:count):`,
    newList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.GROUPS]
    }s being added (:count members):`,
    removeList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.GROUPS]
    }s being removed (:count members):`,
  },
  [accountManagementTypes.COMPANIES]: {
    allList: `All ${
      accountManagementTypeLanguageMap[accountManagementTypes.COMPANIES]
    }s :operation (:count):`,
    newList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.COMPANIES]
    }s being added (:count members):`,
    removeList: `${
      accountManagementTypeLanguageMap[accountManagementTypes.COMPANIES]
    }s being removed (:count members):`,
  },
  [accountManagementTypes.ORGANIZATIONAL_UNIT]: {
    allList: `All ${
      accountManagementTypeLanguageMap[
        accountManagementTypes.ORGANIZATIONAL_UNIT
      ]
    }s :operation (:count):`,
    newList: `${
      accountManagementTypeLanguageMap[
        accountManagementTypes.ORGANIZATIONAL_UNIT
      ]
    }s being added (:count members):`,
    removeList: `${
      accountManagementTypeLanguageMap[
        accountManagementTypes.ORGANIZATIONAL_UNIT
      ]
    }s being removed (:count members):`,
  },
  [accountManagementTypes.SELECTED_USERS]: {
    allList: 'All members :operation (:count):',
    newList: 'Members being added (:count):',
    removeList: 'Members being removed (:count):',
  },
};

export const getEntityTableSearchBoxPlaceHolder = (entity: string) => {
  const placeholder = () => {
    if (
      entity ===
      accountManagementTypeLanguageMap[
        accountManagementTypes.ORGANIZATIONAL_UNIT
      ]
    ) {
      return ` ${FOR} ${AN} `;
    }
    if (
      entity ===
      accountManagementTypeLanguageMap[accountManagementTypes.SELECTED_USERS]
    ) {
      return ' ';
    }
    return ` ${FOR} ${A} `;
  };
  const placeholderText = placeholder();
  return `Search${placeholderText}${entity}`;
};

export const userSelectSelectedUsersIconInfo = {
  heading: 'Friendly heads up!',
  subHeading:
    'This will require you and admins to manually add or remove members',
};

export const modalText = {
  DISCONNECT: 'Are you sure you want to disconnect :displayName?',
  CANCEL: 'Are you sure you want to cancel :displayName setup? ',
  NEVER_MIND: 'Nevermind',
  SURE: `I’m sure`,
};

export const BotManageContent = {
  HEADING: 'Install the Assembly :displayName bot?',
  SUB_HEADING:
    'Want to use Assembly without leaving :displayName? Easy, install our :displayName app and assign a channel!',
  INSTALL_BUTTON_TEXT: 'Install :displayName App',
  INSTALLED_TEXT: 'Installed',
  DISCONNECT_BUTTON_TEXT: 'Disconnect :displayName',
};

export const ChannelSelectionContent = {
  HEADING: 'Select a default channel to keep all recognition in one channel',
  SUB_HEADING:
    // eslint-disable-next-line max-len
    'If you already have a public recognition channel, we recommend selecting it. If not, we recommend creating a new public channel.',
  TAB1_LABEL: 'Select Channel',
  TAB2_LABEL: 'Create Channel',
  HELPER_TEXT: 'We will invite all existing members for you to this channel',
  BACK_BUTTON_TEXT: `Back`,
  NEXT_BUTTON_TEXT: 'Next',
};

export const CustomCurrencyContent = {
  HEADING: 'Customize your company’s own recognition currency.',
  SUB_HEADING:
    // eslint-disable-next-line max-len
    'Make your currency something fun and relevant to your company. It will encourage team members to give more shoutouts. Trophy is our default.',
  ICON_PICKER_DEFAULT_ICON: '🏆',
  ICON_PICKER_PLACEHOLDER_TEXT: 'Eg: Trophies',
  ICON_PICKER_HELPER_TEXT: '👉 Less than 22 characters and singular',
  HELPER_TEXT:
    '👉 Please confirm if the plural version is correct, or change it',
  INPUT_VALUE: 'Trophies',
  INVALID_ERROR: 'Invalid Error',
  CHECK_IF_SINGULAR: '🚨 Whoops, that looks like a plural, keep it singular',
};

export const BotSuccessContent = {
  HEADING: 'Congrats! Your Assembly is setup and ready to go',
  SUB_HEADING:
    // eslint-disable-next-line max-len
    'Your team can start giving customCurrency’s with their recognition right now! Head to your feed and find a team member you want to recognize or appreciate!',
  BUTTON_FEED_TEXT: 'Head to my feed ',
  BUTTON_INVITE_TEXT: 'Invite members now ',
  LINK_TEXT: 'Not now, head to my feed',
};

export const AutoApproveMembersContent = {
  PRIMARY_BUTTON_TEXT: 'Next >',
  SECONDARY_BUTTON_TEXT: 'Nevermind',
  HEADING: 'Auto Approve Members',
  PLACEHOLDER_TEXT: 'Search members',
  DESCRIPTION_TEXT:
    // eslint-disable-next-line max-len
    'These members have requested to join your Assembly. When you switch to auto approval, these requests will be approved. If you’d like to reject anyone, please deselect them here.',
};

export const TwoColumnEntitySelectorContent = {
  PENDING_TEXT: 'Pending Requests',
  SELECT_TEXT: 'Select All',
  DESELECT_TEXT: 'Deselect All',
  REJECT_TEXT: 'Reject',
  NO_MATCH_FOUND: 'No match found',
};

export const SelectPeopleLoadingStateContent = {
  HEADING: 'We’re connecting to your HRIS provider',
  // eslint-disable-next-line max-len
  BODY: 'This may take a 2-10 minute depending on your company size. You can leave this page and continue with other tasks. Once we’re done connecting with your HRIS, you can continue to configure invites.',
  LOADING_GIF: 'https://media.giphy.com/media/QBd2kLB5qDmysEXre9/giphy.gif',
};
