import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { parse } from 'qs';

import useFetch from '../../hooks/useFetch';
import RoutesList from '../../aV2/routes/routesList';
import { GET_SSO_OAUTH_CONFIG } from '../../hooks/useFetch/endpoints';
import { APP_URL } from '../../config';

const useFetchIMConfig = (sso: string) => {
  const REDIRECT_URI = `${APP_URL}${RoutesList.IM_AUTHORIZE_SSO(sso)}`;

  const [imConfig, setIMConfig] = useState(null);
  const [isConnectingIM, setIsConnectingIM] = useState(false);
  const [isAutoLogin, setIsAutoLogin] = useState(false);

  const { search } = useLocation();

  const redirectToIM = (authorizeUri: string) => {
    window.location.href = authorizeUri;
  };

  const onGetSSOConfigSuccess = useCallback(
    (data) => {
      if (isAutoLogin) {
        setIsConnectingIM(true);
        const authorizeUri = _.get(data, ['data', 'authorizeUri'], '');
        redirectToIM(authorizeUri);
      } else {
        setIMConfig(data);
      }
    },
    [isAutoLogin],
  );

  const handleError = () => {
    // dispatch(showErrorMessage(message));
    //  TODO: Handle errors
  };

  const { makeRequest } = useFetch({
    apiEndpoint: GET_SSO_OAUTH_CONFIG,
    onSuccess: onGetSSOConfigSuccess,
    onFailure: handleError,
  });

  useEffect(() => {
    makeRequest({
      params: { redirectUri: REDIRECT_URI },
      substitutions: { sso },
    });
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const { auto, code } = parsedParams;
    if (auto === 'true' && !code) {
      setIsConnectingIM(true);
      setIsAutoLogin(true);
    }
  }, [makeRequest, search, sso, REDIRECT_URI]);

  const onClick = useCallback(() => {
    setIsConnectingIM(true);
    const authorizeUri = _.get(imConfig, ['data', 'authorizeUri'], '');
    redirectToIM(authorizeUri);
  }, [imConfig]);

  return {
    models: {
      isConnectingIM,
      imConfigData: imConfig,
    },
    operations: {
      onAuthorizeClick: onClick,
    },
  };
};

export default useFetchIMConfig;
