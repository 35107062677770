import styled from 'styled-components';
import { Popper } from '@material-ui/core';
import { StyledComponentProps } from './interface';
import ThemeV2 from '../../../componentsV2/theme';

export const DropdownCardWrapper = styled.div<StyledComponentProps>`
  width: ${(props) => props.isFullWidth && '100%'};
  z-index: 3;
`;
DropdownCardWrapper.displayName = 'DropdownCardWrapper';

export const DropdownCardBackground = styled.div<StyledComponentProps>`
  max-height: 300px;
  overflow-y: auto;
  background: ${ThemeV2.palette.gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
  margin: 4px 0;
  width: ${(props) => !props.isFullWidth && 'fit-content'};
`;
DropdownCardBackground.displayName = 'DropdownCardBackground';

export const StyledPopper = styled(Popper)<{
  width: string | undefined;
}>`
  z-index: 9999;
  width: ${(props) => props.width && props.width};
`;
