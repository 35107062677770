import mixpanel from 'mixpanel-browser';
import { isSourceMSTeams } from '../msteams';
import {
  AnalyticsEvents,
  ANALYTICS_EVENTS,
  FEED_EVENTS,
  LEFT_DRAWER_EVENT_NAME,
  RIGHT_DRAWER_EVENT_NAME,
} from './constants';
import { GET_PLATFORM, MIXPANEL_KEY } from '../../config';

import { getUserAnniversary, getUserBirthday } from '../admin';

import {
  EventPropsType,
  FeedAnalyticsEventProps,
  FeedEventNameType,
  LeftDrawerEventNameType,
  LeftDrawerEventProps,
  ParticipationAnalyticsEventProps,
  ParticipationEventNameType,
  PostAnalyticsEventProps,
  PostEventNameType,
  RightDrawerEventNameType,
  RightDrawerEventProps,
  SuperProps,
  TrackLeftDrawerEventProps,
  UserProps,
} from './interfaces';

import { CurrentUser } from '../../interfaces/currentUser';
import { isUserAdmin, isUserManager } from '../user';
import { getFlowPerms } from '../flows/feeds';

const getUserID = () => {
  const user = localStorage.getItem('user');
  if (user) {
    const me = JSON.parse(user);
    return me._id;
  }
  return null;
};

const initAnalytics = () => {
  mixpanel.init(MIXPANEL_KEY);
};

const initDataDogRum = () => {
  // datadogRum.init({
  //   applicationId: DATA_DOG.APPLICATION_ID,
  //   clientToken: DATA_DOG.CLIENT_TOKEN,
  //   site: 'datadoghq.com',
  //   service: 'assembly',
  //   env: DATA_DOG.ENV,
  //   version: '1.0.0',
  //   sampleRate: 100,
  //   trackInteractions: false,
  // });
  //
  // datadogRum.startSessionReplayRecording();
};

const identifyUserForAnalytics = (
  userId: string,
  {
    email,
    name,
    isAdmin,
    assemblyId,
  }: { email: string; name: string; isAdmin: boolean; assemblyId: string },
) => {
  mixpanel.identify(userId);
  mixpanel.register({
    Email: email,
    Name: name,
    isAdmin,
    Platform: GET_PLATFORM(isSourceMSTeams()),
    assemblyId,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // DD_RUM.onReady(function () {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   DD_RUM.setUser({
  //     id: userId,
  //     name,
  //     email,
  //   });
  // });
  // mixpanel.track('UserOnline');
};

const signoutEvent = () => {
  mixpanel.reset();
};

const optOutFromTracking = () => {
  mixpanel.opt_out_tracking();
};

// const registerPageView = (pageName: string) => {
//   let pageURL = '';
//   let referrer = '';
//   if (window) {
//     pageURL = window.location.href;
//     referrer = document.referrer;
//   }
//   mixpanel.register({
//     'Page Name': pageName,
//     Platform: GET_PLATFORM(isSourceMSTeams()),
//     'Page URL': pageURL,
//     'Referrer URL': referrer,
//   });
//   mixpanel.track(ANALYTICS_EVENTS.PAGE_VIEW);
// };

const trackPageView = (pageName: string) => {
  let pageURL = '';
  let referrer = '';
  if (window) {
    pageURL = window.location.href;
    referrer = document.referrer;
  }
  const eventProps = {
    'Page Name': pageName,
    Platform: GET_PLATFORM(isSourceMSTeams()),
    'Page URL': pageURL,
    'Referrer URL': referrer,
  };
  mixpanel.track(ANALYTICS_EVENTS.PAGE_VIEW, eventProps);
};

const registerSuperProps = (superProps: Partial<SuperProps>) => {
  mixpanel.register(superProps);
};

const registerUserProps = (userProps: Partial<UserProps>) => {
  const userID = getUserID() || userProps?.$distinct_id;
  if (userID) {
    mixpanel.identify(userID);
    mixpanel.people.set(userProps);
  }
};

const registerPropsFromUser = (user: CurrentUser) => {
  const isAdmin = isUserAdmin(user);
  const isManager = isUserManager(user);
  const Department = user.profile.department || '';
  const allowanceType = user.allowance.type;
  const allowanceValue = user.allowance.points;
  const userStatus = user.status;
  const totalPointsGiven = user.totalPointsGiven;
  const birthday = getUserBirthday(user);
  const hiredday = getUserAnniversary(user);
  const userProps: Partial<UserProps> = {
    Platform: GET_PLATFORM(isSourceMSTeams()),
    isAdmin,
    isManager,
    Department,
    'Allowance Type': allowanceType,
    'Total Allowance': allowanceValue,
    'User Status': userStatus,
    'Total Points Given': totalPointsGiven,
    Birthday: birthday,
    Anniversary: hiredday,
  };
  const superProps: Partial<SuperProps> = {
    Platform: GET_PLATFORM(isSourceMSTeams()),
    isAdmin,
    isManager,
    Department,
    'Allowance Type': allowanceType,
    'Total Allowance': allowanceValue,
    'User Status': userStatus,
    'Total Points Given': totalPointsGiven,
  };

  registerUserProps(userProps);
  registerSuperProps(superProps);
};

const trackEvent = (
  eventName: string,
  userProps?: Partial<UserProps>,
  eventProps?: EventPropsType,
  superProps?: Partial<SuperProps>,
) => {
  if (superProps) {
    registerSuperProps(superProps);
  }
  if (userProps) {
    registerUserProps(userProps);
  }
  mixpanel.track(eventName, eventProps);
};

const trackFeedEvent = (
  action: FeedEventNameType,
  eventProps: FeedAnalyticsEventProps,
) => {
  trackEvent(FEED_EVENTS, undefined, {
    ...eventProps,
    action: action,
    event: AnalyticsEvents.ACTION,
  });
};

const trackPostEvent = (
  eventName: PostEventNameType,
  eventProps: PostAnalyticsEventProps,
) => {
  trackEvent(eventName, undefined, eventProps);
};

const trackRightDrawerEvent = (
  action: RightDrawerEventNameType,
  eventProps: RightDrawerEventProps,
) => {
  trackEvent(RIGHT_DRAWER_EVENT_NAME, undefined, {
    ...eventProps,
    action: action,
    event: AnalyticsEvents.ACTION,
  });
};

const trackParticipationFlowEvents = (
  participationEventName: ParticipationEventNameType,
  participationEventProps: ParticipationAnalyticsEventProps,
) => {
  trackEvent(participationEventName, undefined, participationEventProps);
};

const trackMainFeedActionBarEvent = (
  eventName: string,
  eventProps: EventPropsType,
) => {
  trackEvent(eventName, undefined, eventProps);
};

const trackLeftDrawerEvent = (
  action: LeftDrawerEventNameType,
  args?: TrackLeftDrawerEventProps,
) => {
  const eventProps: LeftDrawerEventProps = {
    notificationTypeClicked: args?.notificationTypeClicked,
    flowId: args?.flowId || args?.id,
    flowName: args?.label,
    flowPerms: getFlowPerms({
      isFlowOwner: args?.isFlowOwner || false,
      isFlowViewer: args?.isFlowViewer || false,
      isFlowParticipant: args?.isFlowParticipant || false,
    }),
    flowIsDue: !!args?.occurrence?.activeOccurrence,
    flowOccurrenceRunning: !!args?.occurrence?.activeOccurrence,
    isArchived: args?.isArchived || false,
    postId: args?.postId,
    responseId: args?.responseId,
  };

  trackEvent(LEFT_DRAWER_EVENT_NAME, undefined, {
    ...eventProps,
    action: action,
    event: AnalyticsEvents.ACTION,
  });
};

export {
  initAnalytics,
  initDataDogRum,
  optOutFromTracking,
  identifyUserForAnalytics,
  trackPageView,
  trackEvent,
  trackFeedEvent,
  trackPostEvent,
  trackRightDrawerEvent,
  trackParticipationFlowEvents,
  trackLeftDrawerEvent,
  signoutEvent,
  registerPropsFromUser,
  registerSuperProps,
  registerUserProps,
  trackMainFeedActionBarEvent,
};
