import styled from 'styled-components';

import { Flex } from '../../../Utils/styles/display';
import IconInfo from '../IconInfo';

export const StyledIconInfo = styled(IconInfo)`
  margin-top: 8px;
`;

export const StyledFlexContainer = styled(Flex)`
  &:last-of-type > .MuiFormControlLabel-root {
    padding-bottom: 0px;
  }
`;

export const ExtraContent = styled.div`
  margin-top: -8px;
  margin-bottom: 16px;
  margin-left: 24px;
`;
