export enum ReactionType {
  REGULAR = 'REGULAR',
  CUSTOM = 'CUSTOM',
}

export interface ReactionMember {
  name: string;
  memberID: string;
}

export type PostReaction = {
  name: string;
  type: ReactionType;
  value: string;
  displayName?: string;
  members: ReactionMember[];
};
