import { useQuery } from 'react-query';

import { GET_JOB_STATUS } from '../../constants/endpoints';
import { ResponseError } from '../../interfaces/ResponseError';
import { makeAPICallWithDataReturn } from '../utils';

interface JobStatusResponse {
  data: { id: string; status: string };
}

export const useJobStatusQuery = (jobId?: string) => {
  return useQuery<JobStatusResponse, ResponseError>(
    [GET_JOB_STATUS, jobId],
    () =>
      makeAPICallWithDataReturn(GET_JOB_STATUS, undefined, undefined, {
        jobId: jobId || '',
      }),
    {
      staleTime: Infinity,
      refetchInterval: 3000,
      enabled: jobId !== undefined,
    },
  );
};

export interface MakeAPICallInterface {
  apiName: string;
  payload?: any;
  params?: Record<string, string | number>;
  substitutions?: Record<string, string>;
}

export const useGenericAPIQuery = (
  api?: MakeAPICallInterface,
  enabled?: boolean,
) => {
  return useQuery<any, ResponseError>(
    api?.apiName || '',
    () =>
      makeAPICallWithDataReturn(
        api?.apiName || '',
        api?.payload,
        api?.params,
        api?.substitutions,
      ),
    {
      staleTime: Infinity,
      enabled,
    },
  );
};
