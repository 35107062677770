import React, { useCallback, useState } from 'react';
import { Token } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  CardNumberElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import { STRIPE_PUBLISHABLE } from '../../config';
import {
  requiredName,
  genericAddCardError,
  requiredPromoCode,
} from '../../Utils/data/planUpgradeModal/errorMessages';
import { applyPromoCodeAction } from '../../modules/Checkout';
import PlanUpgradeAddPaymentModal from '../../atomic/organism/PlanUpgradeAddPaymentModal';

interface PlanUpgradeAddPaymentModalContainerProps {
  heading: string;
  onNextClick({
    token,
    name,
    promoCode,
  }: {
    token: Token;
    name: string;
    promoCode?: string;
  }): void;
  onNevermindClick(): void;
  onModalClose(): void;
}

const Container = (props: PlanUpgradeAddPaymentModalContainerProps) => {
  const {
    heading: templateHeading,
    onNextClick,
    onNevermindClick,
    onModalClose,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { promoCodeError, promoCodeSuccess } = useSelector((state) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    promoCodeError: state.checkout.promoCodeError,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    promoCodeSuccess: state.checkout.promoCodeSuccess,
  }));
  const [name, setName] = useState<string>('');
  const [promoCode, setPromoCode] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isAddPaymentLoading, setAddPaymentLoading] = useState<boolean>(false);
  const getStripeToken = useCallback(
    async (callback: (token: Token) => void) => {
      if (stripe && elements) {
        const cardElement = elements.getElement(CardNumberElement);
        if (cardElement) {
          setAddPaymentLoading(true);
          const { error: createTokenError, token } = await stripe.createToken(
            cardElement,
          );
          setAddPaymentLoading(false);
          if (createTokenError) {
            const { message } = createTokenError;
            setError(message || genericAddCardError);
          } else if (token) {
            callback(token);
          }
        }
      }
    },
    [elements, stripe],
  );

  const handleApplyClick = useCallback(() => {
    setError(null);
    if (promoCode !== '') {
      dispatch(applyPromoCodeAction(promoCode));
    } else {
      setError(requiredPromoCode);
    }
  }, [dispatch, promoCode]);

  const handleNextClick = useCallback(async () => {
    setError(null);
    if (!name) {
      setError(requiredName);
      return;
    }
    if (promoCodeSuccess) {
      getStripeToken((token) => {
        onNextClick({ token, name, promoCode });
      });
    } else {
      getStripeToken((token) => {
        onNextClick({ token, name });
      });
    }
  }, [name, promoCodeSuccess, getStripeToken, onNextClick, promoCode]);

  return (
    <PlanUpgradeAddPaymentModal
      heading={templateHeading}
      onApplyClick={handleApplyClick}
      onNextClick={handleNextClick}
      onNevermindClick={onNevermindClick}
      name={name}
      setName={setName}
      promoCode={promoCode}
      setPromoCode={setPromoCode}
      promoCodeError={!!promoCodeError}
      promoCodeHelperText={promoCodeError || promoCodeSuccess}
      error={error}
      setError={setError}
      onModalClose={onModalClose}
      isButtonsLoading={isAddPaymentLoading}
    />
  );
};

const PlanUpgradeAddPaymentModalContainer = (
  props: PlanUpgradeAddPaymentModalContainerProps,
) => {
  const [stripePromise] = useState(loadStripe(STRIPE_PUBLISHABLE));
  return (
    <Elements stripe={stripePromise}>
      <Container {...props} />
    </Elements>
  );
};

export default PlanUpgradeAddPaymentModalContainer;
