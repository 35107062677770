import React, { memo } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {
  IMAGE_CROPPER_MODAL_CANCEL_TEXT,
  IMAGE_CROPPER_MODAL_HEADING,
  IMAGE_CROPPER_MODAL_SUBMIT_TEXT,
} from '../../../languages/en/rewards';
import { ImageCropperModalProps } from './interface';
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  StyledButton,
  StyledHeading,
  StyledIconButton,
  StyledModal,
} from './styles';

const ImageCropperModal = (props: ImageCropperModalProps) => {
  const { isModalOpen, handleModalClose, onSubmitClick, children, disabled } =
    props;
  return (
    <StyledModal isOpen={isModalOpen} handleClose={handleModalClose}>
      <ModalHeader>
        <StyledHeading variant="h5" weight="bold">
          {IMAGE_CROPPER_MODAL_HEADING}
        </StyledHeading>
        <StyledIconButton icon="round-close" onClick={handleModalClose} />
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
      <ModalFooter>
        <StyledButton
          variant="text"
          color="secondary"
          onClick={handleModalClose}
        >
          {IMAGE_CROPPER_MODAL_CANCEL_TEXT}
        </StyledButton>
        <StyledButton onClick={onSubmitClick} disabled={disabled}>
          {IMAGE_CROPPER_MODAL_SUBMIT_TEXT}
        </StyledButton>
      </ModalFooter>
    </StyledModal>
  );
};

const MemoizedImageCropperModal = memo(ImageCropperModal);
MemoizedImageCropperModal.displayName = 'ImageCropperModal';
export default MemoizedImageCropperModal;
