// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import Button from '../../../componentsV2/core/Button';
import TextField from '../../../componentsV2/core/TextField';
import {
  BirthDayMonthPicker,
  DayMonthYearPicker,
} from '../../../componentsV2/custom/BirthDayMonthPicker';

import {
  setDetailsForEmployee,
  setSkipDetailsForEmployee,
} from '../../modules/employeeSignup';
import { getSetDetailsRequestLoadingStatus } from '../../modules/employeeSignup/selectors';
import { actions as employeeSignupActions } from '../../../modules/onboarding/EmployeeSignup';
import { meSelector } from '../../../modules/me/selector';
import RoutesList from '../../routes/routesList';
import themeV2 from '../../../componentsV2/theme';
import Modal from '../../../componentsV2/core/Modal';
import SetupSuccess from '../shared/onboarding/SetupSuccess';
import { USER_ACCOUNT_SETUP } from '../../../constants/routes';

import {
  getHireYearOptions,
  validateMonthDayAndYear,
  getMonthFromDateObject,
} from '../../../Utils/date';
import { validateDisplayName } from '../../../Utils/validation';
import { handleErrorAction } from '../../utils/ErrorHandler';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    height: 593,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
  textField: {
    marginBottom: 24,
    width: '100%',
  },
  bottomDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  skipButtonLabel: {
    fontSize: 14,
    color: themeV2.palette.darkGray3,
    fontWeight: themeV2.typography.fontWeightMedium,
  },
});

class EnterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthDay: null,
      birthMonth: null,
      hireDay: null,
      hireMonth: null,
      hireYear: null,
      displayName: '',
      displayNameErrors: null,
      displayNameTouched: false,
      birthdateError: null,
      birthdateTouched: false,
      hiredateError: null,
      hireateTouched: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setupInitialData(user);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user && this.props.user) {
      this.setupInitialData(this.props.user);
    }
  }

  setupInitialData = (user) => {
    let birthDay = null;
    let birthMonth = null;
    let hireDay = null;
    let hireMonth = null;
    let hireYear = null;
    let displayName = '';
    let { birthdateTouched, hireateTouched, displayNameTouched } = this.state;
    if (user.profile) {
      if (user.profile.birthday) {
        birthDay = user.profile.birthday.date;
        birthMonth = getMonthFromDateObject(user.profile.birthday);
        birthdateTouched = true;
      } else if (user.profile.birthDate) {
        const birthDate = moment.utc(user.profile.birthDate);
        if (birthDate.isValid()) {
          birthDay = birthDate.date();
          birthMonth = birthDate.format('MMMM');
          birthdateTouched = true;
        }
      }
      if (user.profile.hiredday) {
        hireDay = user.profile.hiredday.date;
        hireMonth = getMonthFromDateObject(user.profile.hiredday);
        hireYear = user.profile.hiredday.year;
        hireateTouched = true;
      } else if (user.profile.hiredDate) {
        const hiredDate = moment.utc(user.profile.hiredDate);
        if (hiredDate.isValid()) {
          hireDay = hiredDate.date();
          hireMonth = hiredDate.format('MMMM');
          hireYear = hiredDate.year();
          hireateTouched = true;
        }
      }
      if (user.profile.username) {
        displayName = user.profile.username;
        displayNameTouched = true;
      }
    }

    this.setState({
      birthDay,
      birthMonth,
      hireDay,
      hireMonth,
      hireYear,
      birthdateTouched,
      hireateTouched,
      displayName,
      displayNameTouched,
    });
  };

  onSkipClicked = () => {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    const firstLogin = params.get('firstLogin');
    this.props.setSkipDetailsForEmployee();
    if (firstLogin === 'true') {
      history.push(USER_ACCOUNT_SETUP);
    } else {
      history.push(RoutesList.HOME);
    }
  };

  onNextClick = (e) => {
    e.preventDefault();
    const {
      hireDay,
      hireMonth,
      hireYear,
      birthDay,
      birthMonth,
      displayName,
      displayNameTouched,
    } = this.state;

    let goThrough = true;

    let displayNameParsed = '';
    if (displayNameTouched && displayName.length > 0) {
      const displayNameErrors = validateDisplayName(displayName);
      if (displayNameErrors) {
        this.setState({ displayNameErrors });
        goThrough = false;
      }
      displayNameParsed = displayName.toLowerCase();
    }

    let hiredDate = null;
    let hiredday = null;
    if (hireMonth || hireDay || hireYear) {
      const isValidHiredate = validateMonthDayAndYear(
        hireMonth,
        hireDay,
        hireYear,
      );
      if (!isValidHiredate) {
        this.setState({ hiredateError: 'Hire date is not valid' });
        goThrough = false;
      } else {
        hiredDate = moment.utc(
          `${hireMonth}-${hireDay}-${hireYear}`,
          'MMMM-DD-YYYY',
        );
        hiredday = {
          date: hireDay,
          monthNumber: Number(moment().month(hireMonth).format('M')),
          month: hireMonth,
          year: hireYear,
        };
      }
    }

    let birthday = null;
    if (birthMonth || birthDay) {
      const isValidBirthday = validateMonthDayAndYear(birthMonth, birthDay);
      if (!isValidBirthday) {
        this.setState({ birthdateError: 'Birth month or day is invalid' });
        goThrough = false;
      } else if (birthMonth && birthDay) {
        birthday = {
          date: birthDay,
          month: birthMonth,
          monthNumber: Number(moment().month(birthMonth).format('M')),
        };
      }
    }

    if (goThrough) {
      this.props
        .setDetailsForEmployee({
          birthday,
          hiredDate,
          hiredday,
          username: displayNameParsed,
        })
        .then((successAction) => {
          const { history, location } = this.props;
          const params = new URLSearchParams(location.search);
          const firstLogin = params.get('firstLogin');
          this.props.removeInviteUserData();
          history.push(
            `${history.location.pathname}?setProfile=success${
              firstLogin === 'true' ? '&firstLogin=true' : ''
            }`,
          );
        })
        .catch((errorOrAbortAction) => {
          const error = handleErrorAction(errorOrAbortAction);
          const usernameError = _.get(
            error,
            ['values', 'username', 'error'],
            null,
          );
          if (usernameError) {
            this.setState({
              displayNameErrors:
                'Display name not available. Try a different name.',
            });
          }
        });
    }
  };

  handleDisplayNameChange = (event) => {
    this.setState({
      displayName: event.target.value,
      displayNameErrors: null,
      displayNameTouched: true,
    });
  };

  handleBirthMonthChange = (event) => {
    this.setState({
      birthMonth: event.target.value,
      birthdateError: null,
      birthdateTouched: true,
    });
  };

  handleBirthDayChange = (event) => {
    this.setState({
      birthDay: event.target.value,
      birthdateError: null,
      birthdateTouched: true,
    });
  };

  handleHireMonthChange = (event) => {
    this.setState({
      hireMonth: event.target.value,
      hiredateError: null,
      hireateTouched: true,
    });
  };

  handleHireDayChange = (event) => {
    this.setState({
      hireDay: event.target.value,
      hiredateError: null,
      hireateTouched: true,
    });
  };

  handleHireYearChange = (event) => {
    this.setState({
      hireYear: event.target.value,
      hiredateError: null,
      hireateTouched: true,
    });
  };

  render() {
    const { classes, loading, location } = this.props;
    const {
      birthDay,
      birthMonth,
      hireDay,
      hireMonth,
      hireYear,
      displayName,
      displayNameTouched,
      displayNameErrors,
      birthdateError,
      hiredateError,
      birthdateTouched,
      hireateTouched,
    } = this.state;

    const isButtonDisabled =
      !(birthdateTouched || hireateTouched || displayNameTouched) ||
      !!hiredateError ||
      !!displayNameErrors ||
      !!birthdateError;

    const params = new URLSearchParams(location.search);
    const setProfile = params.get('setProfile');
    const firstLogin = params.get('firstLogin');

    return (
      <Layout>
        <Card className={classes.card}>
          <div className={classes.wrapper}>
            <div className={classes.body}>
              <form onSubmit={this.onNextClick}>
                <Typography variant="h5" weight="bold" gutterBottom>
                  Make your profile you
                </Typography>
                <TextField
                  id="displayName"
                  label="Display name (optional)"
                  placeholder="Ex. marthawayne"
                  value={displayName}
                  onChange={this.handleDisplayNameChange}
                  rootStyle={{ width: '100%' }}
                  error={displayNameErrors}
                  helperText="No spaces and only “-“ or “.” can be used — we will use your full name by default but you can personalize it if you you’d like"
                  className={classes.textField}
                  autoComplete="off"
                  name="displayName"
                />
                <BirthDayMonthPicker
                  label="Your birth day and month (optional)"
                  helperText="Only for others to wish you happy birthday on your big day!"
                  className={classes.textField}
                  monthValue={birthMonth}
                  onMonthChange={this.handleBirthMonthChange}
                  dayValue={birthDay}
                  onDayChange={this.handleBirthDayChange}
                  error={birthdateError}
                />
                <DayMonthYearPicker
                  label="Your workaversary (optional)"
                  helperText="Only for others to wish you happy anniversary on your big day!"
                  className={classes.textField}
                  monthValue={hireMonth}
                  onMonthChange={this.handleHireMonthChange}
                  dayValue={hireDay}
                  onDayChange={this.handleHireDayChange}
                  yearValue={hireYear}
                  onYearChange={this.handleHireYearChange}
                  yearOptions={getHireYearOptions()}
                  error={hiredateError}
                />
                <div className={classes.bottomDiv}>
                  <Typography variant="subtitle2" weight="semiBold">
                    Step 2 of 2
                  </Typography>
                  <div className={classes.actionDiv}>
                    <Button
                      classes={{
                        label: classes.skipButtonLabel,
                      }}
                      style={{ marginRight: 16 }}
                      onClick={this.onSkipClicked}
                    >
                      Skip
                    </Button>
                    <Button
                      style={{ width: 93 }}
                      disabled={isButtonDisabled || loading}
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      {loading ? 'Saving...' : 'Done'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Card>
        <Modal
          open={!!setProfile && setProfile === 'success'}
          handleClose={this.closeEnterProfileModal}
        >
          <SetupSuccess
            firstLogin={firstLogin === 'true'}
            history={this.props.history}
            header="Your account is setup and you’re ready to join your team!"
            SubTextComponent={
              <Typography variant="subtitle1" component="span">
                Congrats and welcome to Assembly — poke around and get
                comfortable! Once you’re ready,{' '}
                <Typography variant="subtitle1" weight="bold">
                  send the first recognition post.
                </Typography>
              </Typography>
            }
          />
        </Modal>
      </Layout>
    );
  }
}

EnterDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: meSelector(state),
  loading: getSetDetailsRequestLoadingStatus(state),
});

export default connect(mapStateToProps, {
  setDetailsForEmployee,
  setSkipDetailsForEmployee,
  removeInviteUserData: employeeSignupActions.removeInviteUserData,
})(withStyles(styles)(EnterDetails));
