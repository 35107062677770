import { DatumValue } from '@nivo/line';
import moment from 'moment';

export const generateAxisLeftValues = (
  chartData:
    | {
        id: string;
        color: string;
        data: {
          x: string;
          y: number;
        }[];
      }[]
    | null,
) => {
  const allYValues: Array<number> = [];
  chartData?.forEach((point) => {
    point.data.forEach((yValues) => {
      allYValues.push(yValues.y);
    });
  });

  const highestValue = Math.max(...allYValues);
  const yAxisValues: Array<number> = [];
  const maxValueLimit = Math.ceil(highestValue / 10) * 10;
  for (let i = 0; i <= 10; i += 1) {
    yAxisValues.push((maxValueLimit / 10) * i);
  }

  return yAxisValues;
};

export const getDateFormattedString = (
  time: DatumValue,
  timeDuration?: string,
) => {
  const date = moment.utc(
    (typeof time === 'string' && parseInt(time, 10)) || time,
  );
  if (timeDuration === 'monthly') {
    return date.format('MMM YYYY');
  }
  const startMonth = date.format('MMM');
  const startDate = date.format('DD');
  date.add(6, 'days');
  const endMonth = date.format('MMM');
  const endDate = date.format('DD');
  return `${startMonth} ${startDate}-${
    startMonth === endMonth ? endDate : `${endMonth} ${endDate}`
  }`;
};
