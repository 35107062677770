import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '../../../componentsV2/core/Typography';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';
import Body from '../../../atomic/atoms/Body';
import Layout from '../../../aV2/Layout/onboarding';
import SigninSubText from '../../../atomic/molecules/SigninSubText';
import SignInOptions from '../../../atomic/organism/SigninOptions';

import useCreatePassword from './useCreatePassword';
import {
  CREATE_PASSWORD_BODY_TEXT,
  IS_THIS_CORRECT_TEXT,
  RETURN_TO_SIGN_IN_TEXT,
  SAML_WITH_NO_PREFERRED_SSO_BODY_TEXT,
  SAML_WITH_NO_PREFERRED_SSO_TEXT,
  SIGN_IN_WITH_SAML_TEXT,
} from '../../../languages/en/signIn';
import { DEFAULT_PATH } from '../../../constants/routes';
import { SigninOptionsType } from '../../../atomic/organism/SigninOptions/interface';
import { SSOComponentProps } from '../../../pages/signin/interfaces';
import { SSOTypes } from '../../../Utils/data/ssoManager/common';

export const StyledSigninSubText = styled(SigninSubText)`
  margin-top: 12px;
  justify-content: center;
`;

export const StyledInvalidDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const subTextProps = {
  text: IS_THIS_CORRECT_TEXT,
  linkOneText: RETURN_TO_SIGN_IN_TEXT,
  linkOnePath: DEFAULT_PATH,
};

const CreatePasswordController = () => {
  const {
    isLoading,
    tokenInvalid,
    createPasswordType,
    SAMLLoginOptions,
    createYourPasswordProps,
  } = useCreatePassword();

  const [ssoType, setSSOType] = useState<string | undefined>(undefined);
  const [ssoLoading, setSSOLoading] = useState<boolean>(false);
  const handleSSOClick = useCallback((ssoTypeVal: string) => {
    setSSOType(ssoTypeVal);
    setSSOLoading(true);
  }, []);

  if (ssoLoading) {
    return (
      <Layout>
        <Grid container justify="center" style={{ marginTop: 90 }}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore  */}
          <Typography variant="body2" weight="bold" gutterBottom>
            {ssoLoading ? `Connecting to ${ssoType}` : 'Verifying Slack'}
          </Typography>
          <LoadingPulse isLoading />
        </Grid>
      </Layout>
    );
  }

  if (
    createPasswordType === SigninOptionsType.SAML_WITH_CREATE_PASSWORD &&
    SAMLLoginOptions
  ) {
    const samlName = SAMLLoginOptions.name || 'SAML';
    const samlProps = {
      samlButtonText: SIGN_IN_WITH_SAML_TEXT(samlName),
      samlIcon: 'key',
      samlURL: SAMLLoginOptions.uri,
      headingText: SAML_WITH_NO_PREFERRED_SSO_TEXT(samlName),
      bodyText: SAML_WITH_NO_PREFERRED_SSO_BODY_TEXT(samlName),
    };
    return (
      <Layout>
        <SignInOptions
          signinOptionsType={createPasswordType}
          samlProps={samlProps}
          subTextProps={subTextProps}
          createPasswordProps={createYourPasswordProps}
        />
      </Layout>
    );
  }
  if (
    createPasswordType ===
    SigninOptionsType.CREATE_PASSWORD_WITH_NO_PREFERRED_SSO
  ) {
    const ssoComponentProps: SSOComponentProps = {
      onSSOClick: handleSSOClick,
      ssoType: SSOTypes.SIGN_IN,
    };
    return (
      <Layout>
        <SignInOptions
          signinOptionsType={createPasswordType}
          subTextProps={subTextProps}
          createPasswordProps={createYourPasswordProps}
          ssoComponentProps={ssoComponentProps}
          headingText={CREATE_PASSWORD_BODY_TEXT}
        />
      </Layout>
    );
  }

  return (
    <Grid container justify="center" style={{ marginTop: 90 }}>
      <Body variant="body2Bold">{isLoading && `Verifying Token`}</Body>
      {tokenInvalid && (
        <>
          <StyledInvalidDiv>
            <Body variant="body2Bold">
              Your token is invalid. Please try again.
            </Body>
          </StyledInvalidDiv>
          <div>
            <StyledSigninSubText {...subTextProps} />
          </div>
        </>
      )}
      {isLoading && <LoadingPulse isLoading />}
    </Grid>
  );
};

export default CreatePasswordController;
