// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link, { AnchorLink } from '../../../componentsV2/core/Link';
import Typography from '../../../componentsV2/core/Typography';
import themeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  existing: {
    color: themeV2.palette.darkText,
    alignitems: 'center',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 500,
    marginTop: 10,
  },
  link: {
    fontSize: 14,
    color: `${themeV2.palette.blue} !important`,
  },
}));

export default function Existing(props) {
  const classes = useStyles();
  const { text, linkText, to, link } = props;
  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.existing} gutterBottom>
        {text}&nbsp;
        {link ? (
          <AnchorLink className={classes.link} to={link}>
            {linkText}
          </AnchorLink>
        ) : (
          <Link className={classes.link} to={to}>
            {linkText}
          </Link>
        )}
      </Typography>
    </div>
  );
}
