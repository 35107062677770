// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Sidebar from './navbar/Sidebar';
import HeaderWithMenu from '../../header/HeaderWithMenu';

const styles = () => ({
  drawerPaper: {
    width: 250,
    background: '#fafafa', //  this.props.colorSidebar.color,
    marginTop: '60px',
    zIndex: '1000',
    paddingLeft: '20px',
    boxShadow: '',
    borderRight: 'none',
  },
  fullList: {
    width: 'auto',
  },
});

class SidebarMenuRouters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: true,
      shouldShowMenuToggle: false,
      menuLeft: '0px',
    };
    this.menuCollapseWithResize = this.menuCollapseWithResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  // Sidebar collapse when tablet
  componentDidMount() {
    window.addEventListener('resize', this.menuCollapseWithResize);
    // this.props.dispatch(uiActions.resizeWindow(window.innerWidth));
    if (window.innerWidth < 991) {
      this.setMenuFalse();
    }
  }

  // Sidebar collapse when tablet
  componentWillUnmount() {
    window.removeEventListener('resize', this.menuCollapseWithResize);
  }

  // TOGGLE HANDLE FROM PARENT TO CHILD ----------- START
  setMenuFalse() {
    this.setState(
      { menuOpen: false, shouldShowMenuToggle: true, menuLeft: '0px' },
      () => {
        this.alertParent();
      },
    );
  }

  setMenuTrue() {
    this.setState(
      { menuOpen: true, shouldShowMenuToggle: false, menuLeft: '0px' },
      () => {
        this.alertParent();
      },
    );
  }

  // TOGGLE HANDLE FROM PARENT TO CHILD ----------- START
  alertParent = () => {
    // this.props.onChangeMenu(this.state.menuOpen);
  };

  // Sidebar toggle
  toggleMenu() {
    this.setState(
      (prevState) => ({
        menuOpen: !prevState.menuOpen,
      }),
      () => {
        this.alertParent();
      },
    );
  }

  // TOGGLE HANDLE FROM PARENT TO CHILD ----------- END

  // menu collapse when on mobile function
  menuCollapseWithResize() {
    // this.props.dispatch(uiActions.resizeWindow(window.innerWidth));
    if (window.innerWidth < 991) {
      this.setMenuFalse();
    }
    if (window.innerWidth > 991) {
      this.setMenuTrue();
    }
  }

  render() {
    const { classes } = this.props;

    // Sidebar class based on bg color
    // const sidebarClass = classNames({
    //   'menu-drawer': true,
    //   'has-bg': true,
    // });

    return (
      <div>
        <HeaderWithMenu
          shouldShowMenuToggle={this.state.shouldShowMenuToggle}
          toggleMenu={this.toggleMenu}
          path=""
        />
        <Drawer
          open={this.state.menuOpen}
          variant="persistent"
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div role="presentation">
            <Sidebar employer={this.props.employer} />
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  colorHeader: state.headerActiveStyle,
  colorSidebar: state.sidebarActiveStyle,
  colorHeaderBanner: state.headerBAnnerActiveStyle,
  employer: state.admin.employer,
});

export default connect(mapStateToProps)(withStyles(styles)(SidebarMenuRouters));
