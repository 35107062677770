// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../core/Button';
import ThemeV2 from '../../theme';

const styles = () => ({
  root: {
    position: 'relative',
    padding: '6px 3px 6px 10px',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  svg: {
    display: 'inline-block',
    height: '1em',
    width: '1em',
    fill: ThemeV2.palette.darkText,
    transform: 'rotate(0deg) translateX(0)',
    fontSize: '1.5rem',
    flexShrink: 0,
    userSelect: 'none',
    marginLeft: 'auto',
    transition: '0.25s ease all',
    '&.open': {
      transform: 'rotate(180deg) translateX(0)',
    },
  },
});

class ButtonWithArrow extends Component {
  render() {
    const { classes, isOpen, children, onClick, ...rest } = this.props;
    return (
      <Button
        className={classes.root}
        aria-label="Add Emoji"
        onClick={onClick}
        {...rest}
      >
        {children}
        <svg
          className={classnames(classes.svg, isOpen ? 'open' : '')}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M7 10l5 5 5-5z"></path>
        </svg>
      </Button>
    );
  }
}

export default withStyles(styles)(ButtonWithArrow);
