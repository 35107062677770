// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import titlelize from 'titleize';
import { createSelector } from 'reselect';
import { MEMBER_TYPES } from '../../utils/constants/slackOnboarding';
import {
  UPDATE_DEFAULT_SLACK_CHANNEL,
  UPDATE_ACTIVE_SLACK_CHANNELS,
  UPDATE_SLACK_MEMBERS,
  UPDATE_SPECIFIC_SLACK_MEMBERS,
  CREATE_DEFAULT_SLACK_CHANNEL,
  GET_SLACK_MEMBERS,
  INVITE_SLACK_MEMBERS,
  GET_SLACK_HRIS_SETTINGS,
  ONBOARD_SLACK_MEMBERS,
  UPDATE_SLACK_HRIS_SETTINGS,
} from '.';

export const getUpdateDefaultChannelsLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', UPDATE_DEFAULT_SLACK_CHANNEL, 'pending'],
    false,
  );

export const getCreateDefaultChannelsLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', CREATE_DEFAULT_SLACK_CHANNEL, 'pending'],
    false,
  );

export const getUpdateActiveChannelsLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', UPDATE_ACTIVE_SLACK_CHANNELS, 'pending'],
    false,
  );

export const getSlackUsersLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', GET_SLACK_MEMBERS, 'pending'],
    false,
  );

export const updateSlackUsersLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', INVITE_SLACK_MEMBERS, 'pending'],
    0,
  );

export const getSlackHRISLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', GET_SLACK_HRIS_SETTINGS, 'pending'],
    0,
  );

export const getSlackMembersOnboardLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', ONBOARD_SLACK_MEMBERS, 'pending'],
    0,
  );

export const getSlackHRISUpdateLoadingStatus = (state) =>
  _.get(
    state,
    ['v2SlackOnboard', 'mutations', UPDATE_SLACK_HRIS_SETTINGS, 'pending'],
    0,
  );

export const getSelectUsersLoadingStatus = (state) => {
  const isUsersRequesting = _.get(
    state,
    ['v2SlackOnboard', 'mutations', UPDATE_SLACK_MEMBERS, 'pending'],
    0,
  );

  const isSpecificUsersRequesting = _.get(
    state,
    ['v2SlackOnboard', 'mutations', UPDATE_SPECIFIC_SLACK_MEMBERS, 'pending'],
    0,
  );
  return isUsersRequesting > 0 || isSpecificUsersRequesting > 0;
};

export const getSlackConfigInfo = (state) =>
  _.get(state, ['v2SlackOnboard', 'data', 'SLACK_APP'], null);

export const getSlackChannelsInfo = (state) =>
  _.get(state, ['v2SlackOnboard', 'data', 'slackChannels'], []);

export const getActiveSlackChannelsInfo = (state) =>
  _.get(state, ['v2SlackOnboard', 'data', 'activeChannels'], []);

export const getDefaultSlackChannelInfo = (state) =>
  _.get(state, ['v2SlackOnboard', 'data', 'defaultChannel'], null);

export const slackMembersSelector = (state) =>
  _.get(state, ['v2SlackOnboard', 'data', 'slackMembers'], []);

export const slackCustomSelector = (state) =>
  _.get(state, ['v2SlackOnboard', 'data', 'customEmojis'], []);

const serializeSlackMembers = (slackMembers) => {
  return slackMembers
    .filter((member) => !member.deleted)
    .map((member) => {
      const user = { id: member.id };
      user.username = member.name;
      user.fullName = titlelize(member.profile.real_name);
      user.thumbImage = member.profile.image_48;
      user.image = member.profile.image_512;
      return user;
    });
};
export const getSlackMembersInfo = createSelector(
  slackMembersSelector,
  (slackMembers) => serializeSlackMembers(slackMembers),
);

export const invitedSlackMembersSelector = (state) =>
  _.get(state, ['v2SlackOnboard', 'data', 'slackMembersAdded'], []);

export const getSlackMembersSerialzed = createSelector(
  slackMembersSelector,
  (slackMembers) =>
    slackMembers.map((slackMember) => {
      let memberTypeString;
      let memberType;
      switch (slackMember.slackMemberType) {
        case 'ADMIN':
        case 'OWNER':
        case 'REGULAR':
          memberTypeString = 'Full Member';
          memberType = MEMBER_TYPES.FULL_MEMBERS.id;
          break;
        case 'SINGLE_CHANNEL_GUEST':
          memberTypeString = 'Single Channel Guest';
          memberType = MEMBER_TYPES.SINGLE_CHANNEL_GUEST.id;
          break;
        case 'MULTI_CHANNEL_GUEST':
          memberTypeString = 'Multi Channel Guest';
          memberType = MEMBER_TYPES.MULTI_CHANNEL_GUEST.id;
          break;
        case 'STRANGER':
          memberTypeString = 'Outside Guests';
          memberType = MEMBER_TYPES.OUTSIDE_GUESTS.id;
          break;
        default:
          break;
      }
      return {
        ...slackMember,
        name: titlelize(slackMember.name),
        memberTypeString,
        memberType,
      };
    }),
);

export const getAssemblySlackMembersSerialzed = createSelector(
  getSlackMembersSerialzed,
  (slackMembers) => slackMembers.filter((member) => member.isAssemblyUser),
);
