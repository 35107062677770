import { AutocompleteDropdownItem } from '../../../../atomic/organism/Autocomplete/interfaces';
import { SelectedCustomRecurrenceTypes } from '../../../../atomic/organism/CustomRecurrence/types';
import {
  ErrorEntry,
  TriggerBuilderBlockData,
} from '../../../../interfaces/Flow/Builder';

export type ScheduleDateTimeControllerTypes = {
  templateId: string;
  isInEditMode: boolean;
  endTimeInMinutes: number;
  updateEndTimeInMinutes: (updatedEndTimeInMinutes: number) => void;
  shortcut: boolean;
  onShortcutToggle: (updatedShortcut: boolean) => void;
  setIsNextButtonDisabled: (val: boolean) => void;
  triggerBlockData: TriggerBuilderBlockData;
  hasActiveOccurrence?: boolean;
  updateErrorData: (error: ErrorEntry | null) => void;
  updateTriggerBlockData: (
    updatedContentBlock: TriggerBuilderBlockData,
  ) => void;
};

type TimeZoneType = {
  id: string;
  title: string;
  value: string;
};

export type GetRRuleStringProps = {
  flowStartDate: Date | undefined;
  flowEndDate: Date | undefined;
  flowFrequency: AutocompleteDropdownItem<string, undefined> | null;
  flowFrequencyTime: AutocompleteDropdownItem<number, undefined> | null;
  flowEndDateTime: AutocompleteDropdownItem<number, undefined> | null;
  selectedTimezone: TimeZoneType;
  selectedCustomRecurrenceTypes: SelectedCustomRecurrenceTypes | undefined;
};

export enum FlowFrequencyOptions {
  DAILY = 'daily',
  ONCE = 'once',
  EVERY_WEEKDAY = 'everyWeekday',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biWeekly',
  MONTHLY = 'monthly',
  MONTHLY_LAST = 'monthlyLast',
  QUARTERLY = 'quarterly',
  QUARTERLY_LAST = 'quarterlyLast',
  ANNUALLY = 'annually',
  CUSTOM = 'custom',
  REPEAT_DAYS = 'repeatDays',
  REPEAT_ALL_DAYS = 'repeatAllDays',
  REPEAT_WEEK_DAYS = 'repeatWeekDays',
  REPEAT_WEEKS = 'repeatWeeks',
  REPEAT_YEARS = 'repeatYears',
  REPEAT_MONTHS = 'repeatMonths',
}
