export const EACH = 'each';
export const LEFT = 'left';
export const GIVE = 'give';
export const ME = 'Me';
export const OF = 'of';
export const MY = 'my';
export const SHOWING = 'Showing';
export const EDIT = 'Edit';
export const CLOSE = 'Close';
export const CLEAR = 'Clear';
export const AVATAR = 'Avatar';
export const NAME = 'Name';
export const HANDLE = 'Handle';
export const EMAIL = 'Email';
export const DEPARTMENT = 'Department';
export const TIME_ZONE = 'Time Zone';
export const MANAGER = 'Direct Manager';
export const DIRECT = 'Direct';
export const REPORTS = 'Reports';
export const POST = 'Post';
export const BIRTHDAY = 'Birthday';
export const START = 'Start';
export const DATE = 'Date';
export const PROFILE = 'Profile';
export const INFORMATION = 'Information';
export const IN = 'In';
export const APP = 'App';
export const CELEBRATED = 'celebrated';
export const OK = 'OK';
export const CANCEL = 'Cancel';
export const AND_LOWER = 'and';
export const OTHERS_LOWER = 'others';
export const YOUR = 'your';
export const YOU = 'You';
export const ADMIN_LOWER = 'admin';
export const UPLOADED = 'uploaded';
export const LOADING = 'Loading';
export const NO = 'No';
export const UPGRADE = 'Upgrade';
export const OR = 'OR';
export const FOR = 'for';
export const A = 'a';
export const AN = 'an';
export const LEARN = 'Learn';
export const MORE = 'more';
export const REQUIRED = 'Required';
export const WITH = 'with';
export const SHORTCUT = 'Shortcut';
export const VIEW = 'View';
export const FEED = 'Feed';
export const REMIND = 'Remind';
export const RUN = 'Run';
export const NOW = 'now';
export const END = 'End';
export const OCCURRENCE = 'occurrence';
export const DONE = 'Done';
export const ANSWER = 'Answer';
export const FLOW = 'flow';
export const RECOMMENDED = 'Recommended';
export const TEMPLATES = 'Templates';
export const CONFIRM = 'Confirm';
export const PREVIEW = 'Preview';
export const CATEGORIES = 'Categories';
export const ANSWERED = 'answered';
export const POSTED = 'posted';
export const ANONYMOUS = 'Anonymous';
export const ANONYMOUSLY = 'Anonymously';
export const EVERYONE = 'Everyone';
export const PRIVATELY = 'privately';
export const ONLY = 'only';
export const OWNER = 'owner';
export const PARTICIPANTS = 'participants';
export const CUSTOM = 'custom';
export const DATES = 'Dates';
export const START_DATE = 'Start Date';
export const END_DATE = 'End Date';
export const AT = 'at';
export const DUE = 'Due';
export const CLOSES = 'Closes';
export const ARCHIVE = 'Archive';
export const UNARCHIVE = 'Unarchive';
export const THIS = 'this';
export const ADDITIONAL = 'Additional';
export const OPTIONS = 'options';
export const SETTINGS = 'Settings';
export const VERSION = 'Version';
export const BLOCK = 'Block';
export const MENTIONED = 'Mentioned';
export const DESCRIPTION = 'Description';
export const WHEN = 'When';
export const ABOUT = 'About';
export const FILTER = 'Filter';
export const PEOPLE = 'People';
export const PERSON = 'Person';
export const MANAGE = 'Manage';
export const INSIGHTS = 'Insights';
export const EDITOR = 'Editor';
export const NEXT = 'Next';
export const TO = 'to';
export const DELETE = 'Delete';
export const CREATE = 'Create';
export const OPTION = 'option';
export const UNLIMITED = 'Unlimited';
export const EMOJIS = 'Emojis';
export const GIFS = 'GIFs';
export const ATTACHMENTS = 'Attachments';
export const ROUTINE = 'Routine';
export const ADD = 'Add';
export const ANOTHER = 'another';
export const OTHER = 'Other';
export const CHOOSE = 'Choose';
export const CONTAINS = 'Contains';
export const BETWEEN = 'between';
export const AND = 'and';
export const EXACTLY = 'exactly';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const DAYS = 'days';
export const WEEKS = 'weeks';
export const MONTHS = 'months';
export const YEARS = 'years';
export const TRY = 'Try';
export const AGAIN = 'again';
export const ON = 'on';
export const OFF = 'off';
export const SELECTABLE = 'selectable';
export const BY = 'by';
export const CREATED = 'created';
export const BLOCKS = 'blocks';
export const FORM = 'form';
export const HIDE = 'hide';
export const ANYTIME = 'Anytime';
export const SCHEDULE = 'Schedule';
export const RUNS = 'Runs';
export const SAVE = 'Save';
export const REFRESH = 'Refresh';
export const LIST = 'List';
export const SENT = 'sent';
export const MESSAGE = 'message';
export const PARTICIPANT = 'participant';
export const RECURS = 'Recurs';
export const ACCEPT = 'Accept';
export const USE = 'Use';
export const TEMPLATE = 'template';
export const ENTER = 'Enter';
export const THE = 'the';
export const REFRESHES = 'Refreshes';
export const ITEM = 'item';
export const TASK = 'task';
export const PENDING = 'pending';
export const GOOGLE = 'google';
export const OUTLOOK = 'outlook';
export const UNASSIGNED = 'unassigned';
export const ASSIGNEE = 'assignee';
export const NOT = 'not';
export const ERROR = 'error';
export const REASSIGN = 'reassign';
export const UNASSIGN = 'unassign';
export const SHARE = 'Share';
export const BACK = 'Back';
export const UNARCHIVED = 'Unarchived';
export const INCOMPLETE = 'Incomplete';
export const CHANGED_TO = 'Changed to';
export const LOADING_LABEL = 'Loading...';
export const SOMEONE = 'someone';
