// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import Button from '../../../componentsV2/core/Button';
import TextField from '../../../componentsV2/core/TextField';

import { setCompanyName } from '../../modules/onboarding';
import { getEmailRegistered } from '../../modules/onboarding/selectors';
import RoutesList from '../../routes/routesList';
import EnterProfile from './EnterProfile';
import Modal from '../../../componentsV2/core/Modal';
import { fireConversionForSignup } from '../../../Utils/analytics/gtag';
import { INVALID_COMPANY_NAME_ERROR } from '../../../Utils/identity/languages';
import { httpRegExp } from '../../../Utils/text';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    height: 593,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
});

class EnterCompanyName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      companyNameErrors: null,
      companyNameTouched: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { userId } = match.params;
    if (userId) {
      fireConversionForSignup(userId);
    }
  }

  onCreateCompanyClick = (e) => {
    e.preventDefault();
    const { companyName } = this.state;
    this.props.setCompanyName({ companyName });
    // this.setState({ isEnterProfileOpen: true });
    const { history, match, location } = this.props;
    const { userId } = match.params;
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    history.push(
      `${RoutesList.ENTER_COMPANY_NAME}/${userId}?setProfile=setName&code=${code}`,
    );
  };

  handleChange = (event) => {
    const { value } = event.target;

    this.setState({
      companyName: value,
      companyNameErrors: value.match(httpRegExp)
        ? INVALID_COMPANY_NAME_ERROR
        : null,
      companyNameTouched: true,
    });
  };

  closeEnterProfileModal = () => {
    // this.setState({
    //   isEnterProfileOpen: false,
    // });
  };

  render() {
    const { classes, match, location } = this.props;
    const { companyName, companyNameErrors } = this.state;

    const { userId } = match.params;
    if (!userId) {
      return <Redirect to={`${RoutesList.ENTER_EMAIL}`} />;
    }

    const params = new URLSearchParams(location.search);
    const setProfile = params.get('setProfile');

    return (
      <Layout>
        <Card className={classes.card}>
          <div className={classes.wrapper}>
            <div className={classes.body}>
              <form onSubmit={this.onCreateCompanyClick}>
                <Typography variant="h5" weight="bold" gutterBottom>
                  What’s the name of your company or team?
                </Typography>
                <TextField
                  id="companyName"
                  placeholder="Ex. Wayne Enterprises or WE Engineering"
                  value={companyName}
                  onChange={this.handleChange}
                  rootStyle={{ width: '100%' }}
                  error={companyNameErrors}
                  autoComplete="off"
                />
                <Button
                  style={{ width: '100%', marginTop: 20 }}
                  disabled={
                    companyName.trim().length === 0 || companyNameErrors
                  }
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Create My Assembly
                </Button>
              </form>
            </div>
          </div>
        </Card>
        <Modal open={!!setProfile} handleClose={this.closeEnterProfileModal}>
          {setProfile === 'setName' && (
            <EnterProfile history={this.props.history} />
          )}
        </Modal>
      </Layout>
    );
  }
}

EnterCompanyName.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  email: getEmailRegistered(state),
});

export default connect(mapStateToProps, { setCompanyName })(
  withStyles(styles)(EnterCompanyName),
);
