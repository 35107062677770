import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import HighlightAnalyticsNumber from '../../molecules/HightlightAnalyticsNumber';
import PercantageIndicator from '../../molecules/PercentageIndicator';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import Heading from '../../atoms/Heading';
import { BenchmarkStatus } from '../../../interfaces/analytics/benchmarking/interfaces';

interface PanelProps {
  isPercentageIndicators?: boolean;
  data: {
    headerText: string;
    dataNumber: number;
    percentChange: string;
    subText: string;
    subTextLink?: string;
    comparisonString: string;
    isLoading: boolean;
    error: boolean;
    headerTooltip?: string;
    engagementRank: BenchmarkStatus;
  }[];
  header?: string;
  statusUpdate: string;
  subHeader: string;
  numberOfItems?: number;
  onShowTipsClick?: () => void;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  headerContainer: {
    marginBottom: '16px',
  },
  header: {
    margin: 0,
    marginBottom: '16px',
  },
  panelContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    '& > div:not(:first-of-type)': {
      borderLeft: `1px solid ${ThemeV2.palette.gray4}`,
    },
  },
});

const AnalyticsInfoPanel = ({
  isPercentageIndicators,
  data,
  header,
  statusUpdate,
  subHeader,
  numberOfItems = 4,
  onShowTipsClick,
}: PanelProps) => {
  const classes = useStyles();
  const panelData = useMemo(
    () => data || new Array(numberOfItems).fill({}),
    [data, numberOfItems],
  );
  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        {header && (
          <Heading variant="h1" className={classes.header}>
            {header}
          </Heading>
        )}
        <Body variant="subHead2Bold">{subHeader}</Body>
        <Body variant="body2" color="gray7">
          {statusUpdate}
        </Body>
      </div>
      <div className={classes.panelContainer}>
        {isPercentageIndicators
          ? panelData.map(
              ({
                dataNumber,
                error,
                isLoading,
                headerText,
                engagementRank,
              }) => (
                <PercantageIndicator
                  infoData={{
                    dataNumber,
                    error,
                    isLoading,
                    headerText,
                    engagementRank,
                  }}
                  key={headerText || Math.random()}
                />
              ),
            )
          : panelData.map((dataItem) => (
              <HighlightAnalyticsNumber
                infoData={dataItem}
                key={dataItem.headerText || Math.random()}
                onShowTipsClick={onShowTipsClick}
              />
            ))}
      </div>
    </div>
  );
};

export default AnalyticsInfoPanel;
