// @ts-nocheck TODO: type issues need to be fixed in this file
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../atomic/atoms/Button';
import TextField from '../../../atomic/molecules/NewTextfield';
import SignInOptions from '../../../atomic/organism/SigninOptions';
import { SigninOptionsType } from '../../../atomic/organism/SigninOptions/interface';
import { StyledDivider } from '../../../atomic/organism/SigninOptions/styles';
import ValidateYourEmail from '../../../atomic/organism/ValidateYourEmail';
import Card from '../../../componentsV2/core/Card';
import Link from '../../../componentsV2/core/Link';
import Typography from '../../../componentsV2/core/Typography';
import themeV2 from '../../../componentsV2/theme';
import { DEFAULT_PATH, RESET_PASSWORD } from '../../../constants/routes';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import {
  CREATE_PASSWORD_BODY_TEXT,
  CREATE_PASSWORD_HEADER_TEXT,
  ENTER_EMAIL_TO_CREATE_PASSWORD,
  IS_THIS_CORRECT_TEXT,
  LIFION_ENFORCED_BODY_TEXT,
  LIFION_ENFORCED_HEADER_TEXT,
  NO_PREFERRED_SSO_BODY_TEXT,
  NO_PREFERRED_SSO_HEADER_TEXT,
  RESET_PASSWORD_TEXT,
  RETURN_TO_SIGN_IN_TEXT,
  SAML_DISABLED_MESSAGE,
  SAML_ONLY_BODY_TEXT,
  SAML_ONLY_HEADER_TEXT,
  SAML_WITH_NO_PREFERRED_SSO_BODY_TEXT,
  SAML_WITH_NO_PREFERRED_SSO_TEXT,
  SAML_WITH_PASSWORD_TEXT,
  SAML_WITH_SSO_TEXT,
  SIGNIN_TO_ENTER,
  SIGN_IN_WITH_SAML_TEXT,
  SSO_ENFORCED_BODY_TEXT,
  SSO_ENFORCED_HEADER_TEXT,
  WELCOME_BACK,
} from '../../../languages/en/signIn';
import {
  handleUserSignin,
  userSessionCreatedAction,
} from '../../../modules/Session';
import SSOComponent from '../../../pages/signin/SSOComponent';
import LoadingPulse from '../../../screens/shared/LoadingPulse';
import { SAMLAuthErrorCodes } from '../../../Utils/data/SAML/error';
import { SSOContent, SSOOptions } from '../../../Utils/data/ssoManager/common';
import { isValidEmail } from '../../../Utils/validation';
import Layout from '../../Layout/onboarding';
import {
  loginOptions,
  loginWithPassword,
  triggerSetPassword,
} from '../../modules/login';
import {
  getRequestLoadingStatus,
  getSigninOptionsRequestLoadingStatus,
  getSigninWithPasswordRequestLoadingStatus,
  getTriggerResetPasswordRequestLoadingStatus,
} from '../../modules/login/selectors';
import { authorizeSignInWithSlack } from '../../modules/slackOnboard';
import { getSlackConfigInfo } from '../../modules/slackOnboard/selectors';
import RoutesList from '../../routes/routesList';
import Existing from '../shared/ExistingAssemblyDiv';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 500,
    padding: '43px 53px 25px 53px',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
  footer: {},
  link: {
    fontSize: 12,
  },
  terms: {
    color: themeV2.palette.lightGray1,
  },
});

const StyledButton = styled(Button)`
  margin-top: 16px;
  margin-bottom: 24px;
`;

const StyledWrapper = styled.div`
  max-width: 486px;
  margin: 0 auto;
`;

const SIGNIN_STATE = {
  EMAIL: 'EMAIL',
  VERIFY: 'VERIFY',
  PASSWORD_RESET: 'PASSWORD_RESET',
};

const SSOSignInOptions = {
  NO_PREFERRED_SSO_FOUND: 'NO_PREFERRED_SSO_FOUND',
};

const SigninWithPasswordOptions = {
  ENABLED: 'ENABLED',
  EMPTY: 'EMPTY',
};

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signinState: SIGNIN_STATE.EMAIL,
      email: '',
      emailErrors: null,
      emailTouched: false,
      ssoLoading: false,
      ssoType: null,
      verifyData: null,
    };
  }

  onSubmitPassword = (values, { setFieldError }) => {
    let { password } = values;
    password = password.trim();

    let { email } = this.state;
    email = email.trim();

    let goThrough = true;

    if (!password) {
      setFieldError('password', 'Password is required');
      goThrough = false;
    }
    if (goThrough) {
      this.props
        .loginWithPassword({ email, password })
        .then((successAction) => {
          const data = _.get(successAction, ['data']);
          const { user, jwtToken } = data;
          this.props.userSessionCreatedAction(user, jwtToken);
          const { history } = this.props;
          const toPath =
            _.get(history, ['location', 'state', 'origin'], null) ||
            RoutesList.HOME;
          if (toPath.includes('signin') || toPath.includes('signout')) {
            handleUserSignin(user);
          } else {
            handleUserSignin(user, toPath);
          }
        })
        .catch((errorOrAbortAction) => {
          const error = _.get(errorOrAbortAction, [
            'error',
            'response',
            'data',
            'message',
          ]);
          if (
            error === 'INVALID_PASSWORD' ||
            error === 'INVALID_PARAMETER' ||
            error === 'MISSING_REQUIRED_PARAMETERS'
          ) {
            setFieldError('password', 'Invalid password');
          }
        });
    }
  };

  onForgotPassword = () => {
    let { email } = this.state;
    email = email.trim();

    if (email) {
      this.props
        .triggerSetPassword({ email })
        .then((successAction) => {
          this.setState({
            signinState: SIGNIN_STATE.PASSWORD_RESET,
          });
        })
        .catch((errorOrAbortAction) => {});
    }
  };

  onNextClick = (e) => {
    e.preventDefault();
    let { email } = this.state;
    email = email.trim();

    let goThrough = true;
    if (!email) {
      this.setState({ emailErrors: 'Email is required' });
      goThrough = false;
    }

    if (!isValidEmail(email)) {
      this.setState({ emailErrors: 'Enter a valid email' });
      goThrough = false;
    }

    if (goThrough) {
      const encodedEmail = encodeURIComponent(email);
      this.props
        .loginOptions({ email: encodedEmail })
        .then((successAction) => {
          const data = _.get(successAction, ['data']);

          const samlData = _.get(successAction, ['data', 'saml'], null);
          const passwordData = _.get(successAction, ['data', 'password'], null);

          if (!samlData && passwordData === SigninWithPasswordOptions.EMPTY) {
            if (this.hasSAMLDisconnectedError()) {
              this.onForgotPassword();
            } else {
              this.setState({
                signinState: SIGNIN_STATE.VERIFY,
                verifyData: data,
              });
            }
          } else {
            this.setState({
              signinState: SIGNIN_STATE.VERIFY,
              verifyData: data,
            });
          }
        })
        .catch((errorOrAbortAction) => {
          const error = _.get(errorOrAbortAction, [
            'error',
            'response',
            'data',
            'message',
          ]);
          this.setState({
            emailErrors: 'Email not found',
          });
          if (error === 'USER_NOT_FOUND' || error === 'ASSEMBLY_NOT_FOUND') {
            this.setState({
              emailErrors: 'Invalid email',
            });
          }
          if (
            error === 'MISSING_REQUIRED_PARAMETERS' ||
            error === 'INVALID_PARAMETER'
          ) {
            this.setState({
              emailErrors: 'Invalid email',
            });
          }
        });
    }
  };

  handleSSOClick = (ssoOption) => {
    this.setState({ ssoLoading: true, ssoType: ssoOption });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      emailErrors: null,
      [`${name}Touched`]: true,
    });
  };

  getSSOOptionsForSSO = (sso) => {
    switch (sso) {
      case 'SLACK':
        return SSOOptions.SLACK;
      case 'GSUITE':
        return SSOOptions.GOOGLE;
      case 'OFFICE365':
        return SSOOptions.OFFICE365;
      case 'ADP':
        return SSOOptions.ADP;
      case 'LIFION':
        return SSOOptions.LIFION;
      default:
        return SSOOptions.SLACK;
    }
  };

  getSAMLName = (samlData) => {
    return samlData.name || 'SAML';
  };

  getSubTextProps = (hasTwoLinks = false) => {
    const subTextProps = {
      text: IS_THIS_CORRECT_TEXT,
      linkOneText: RETURN_TO_SIGN_IN_TEXT,
      linkOnePath: DEFAULT_PATH,
    };
    if (hasTwoLinks) {
      subTextProps.linkTwoText = RESET_PASSWORD_TEXT;
      subTextProps.linkTwoPath = RESET_PASSWORD;
    }
    return subTextProps;
  };

  hasSAMLDisconnectedError = () => {
    const { location } = this.props;
    const parsedParams = parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const errorCodeFromURL = parsedParams && parsedParams.error;
    return (
      errorCodeFromURL &&
      errorCodeFromURL === SAMLAuthErrorCodes.SAML_NOT_ENABLED
    );
  };

  renderVerifyState = () => {
    const { verifyData } = this.state;
    const samlData = _.get(verifyData, ['saml'], null);
    const passwordData = _.get(verifyData, ['password'], null);
    const ssoData = _.get(verifyData, ['sso'], null);

    let signinOptionsType = SigninOptionsType.NO_PREFERRED_SSO;
    if (samlData) {
      if (ssoData && ssoData !== SSOSignInOptions.NO_PREFERRED_SSO_FOUND) {
        signinOptionsType = SigninOptionsType.SAML_WITH_SSO;
      }
      if (ssoData && ssoData === SSOSignInOptions.NO_PREFERRED_SSO_FOUND) {
        signinOptionsType = SigninOptionsType.SAML_WITH_NO_PREFERRED_SSO;
      }
      if (!ssoData && !passwordData) {
        signinOptionsType = SigninOptionsType.SAML_ONLY;
      }
      if (passwordData && passwordData === SigninWithPasswordOptions.ENABLED) {
        signinOptionsType = SigninOptionsType.SAML_WITH_PASSWORD;
      }
    } else if (ssoData && ssoData !== SSOSignInOptions.NO_PREFERRED_SSO_FOUND) {
      signinOptionsType = SigninOptionsType.SSO_ENFORCED;
    } else if (
      passwordData &&
      passwordData === SigninWithPasswordOptions.EMPTY
    ) {
      signinOptionsType = SigninOptionsType.CREATE_PASSWORD;
    } else {
      signinOptionsType = SigninOptionsType.NO_PREFERRED_SSO;
    }
    switch (signinOptionsType) {
      case SigninOptionsType.SAML_WITH_SSO: {
        const samlName = this.getSAMLName(samlData);
        const ssoOption = this.getSSOOptionsForSSO(ssoData);
        const ssoName = SSOContent[ssoOption].displayName;
        const samlProps = {
          samlButtonText: SIGN_IN_WITH_SAML_TEXT(samlName),
          samlIcon: 'key',
          samlURL: samlData.uri,
          headingText: `${SIGN_IN_WITH_SAML_TEXT(samlName)}?`,
          bodyText: SAML_WITH_SSO_TEXT(samlName, ssoName),
        };
        const subTextProps = this.getSubTextProps();

        const ssoComponentProps = {
          showPreferred: ssoOption,
          onSSOClick: this.handleSSOClick,
        };
        return (
          <Layout>
            <SignInOptions
              signinOptionsType={signinOptionsType}
              samlProps={samlProps}
              subTextProps={subTextProps}
              ssoComponentProps={ssoComponentProps}
            />
          </Layout>
        );
      }
      case SigninOptionsType.SAML_WITH_PASSWORD: {
        const samlName = this.getSAMLName(samlData);
        const samlProps = {
          samlButtonText: SIGN_IN_WITH_SAML_TEXT(samlName),
          samlIcon: 'key',
          samlURL: samlData.uri,
          headingText: `${SIGN_IN_WITH_SAML_TEXT(samlName)}?`,
          bodyText: SAML_WITH_PASSWORD_TEXT(samlName),
        };
        const subTextProps = this.getSubTextProps(true);
        const { loadingSigninWithPassword } = this.props;
        const passwordProps = {
          initialValues: {
            password: '',
          },
          onSubmit: this.onSubmitPassword,
          loading: loadingSigninWithPassword,
        };
        return (
          <Layout>
            <SignInOptions
              signinOptionsType={signinOptionsType}
              samlProps={samlProps}
              subTextProps={subTextProps}
              passwordProps={passwordProps}
            />
          </Layout>
        );
      }
      case SigninOptionsType.SAML_ONLY: {
        const samlName = this.getSAMLName(samlData);
        const samlProps = {
          samlButtonText: SIGN_IN_WITH_SAML_TEXT(samlName),
          samlIcon: 'key',
          samlURL: samlData.uri,
          headingText: SAML_ONLY_HEADER_TEXT(samlName),
          bodyText: SAML_ONLY_BODY_TEXT(samlName),
        };
        const subTextProps = this.getSubTextProps();
        return (
          <Layout>
            <SignInOptions
              signinOptionsType={signinOptionsType}
              samlProps={samlProps}
              subTextProps={subTextProps}
            />
          </Layout>
        );
      }
      case SigninOptionsType.SAML_WITH_NO_PREFERRED_SSO: {
        const samlName = this.getSAMLName(samlData);
        const samlProps = {
          samlButtonText: SIGN_IN_WITH_SAML_TEXT(samlName),
          samlIcon: 'key',
          samlURL: samlData.uri,
          headingText: SAML_WITH_NO_PREFERRED_SSO_TEXT(samlName),
          bodyText: SAML_WITH_NO_PREFERRED_SSO_BODY_TEXT(samlName),
        };
        const subTextProps = this.getSubTextProps();
        return (
          <Layout>
            <SignInOptions
              signinOptionsType={signinOptionsType}
              samlProps={samlProps}
              subTextProps={subTextProps}
            />
          </Layout>
        );
      }
      case SigninOptionsType.SSO_ENFORCED: {
        const ssoOption = this.getSSOOptionsForSSO(ssoData);
        const ssoName = SSOContent[ssoOption].displayName;
        const headingText =
          ssoOption === SSOOptions.LIFION
            ? LIFION_ENFORCED_HEADER_TEXT
            : SSO_ENFORCED_HEADER_TEXT;
        const bodyText =
          ssoOption === SSOOptions.LIFION
            ? LIFION_ENFORCED_BODY_TEXT
            : SSO_ENFORCED_BODY_TEXT(ssoName);
        const subTextProps = this.getSubTextProps();

        const onSSOClick = () => {
          const lifionURI = _.get(verifyData, ['authorizeUri'], null);
          if (ssoOption === SSOOptions.LIFION && lifionURI) {
            window.location.href = lifionURI;
          }
          this.handleSSOClick(ssoName);
        };

        const ssoComponentProps = {
          showPreferred: ssoOption,
          onSSOClick:
            ssoOption === SSOOptions.LIFION ? onSSOClick : this.handleSSOClick,
        };
        return (
          <Layout>
            <SignInOptions
              signinOptionsType={signinOptionsType}
              headingText={headingText}
              bodyText={bodyText}
              subTextProps={subTextProps}
              ssoComponentProps={ssoComponentProps}
            />
          </Layout>
        );
      }
      case SigninOptionsType.NO_PREFERRED_SSO: {
        const headingText = NO_PREFERRED_SSO_HEADER_TEXT;
        const bodyText = NO_PREFERRED_SSO_BODY_TEXT;
        const subTextProps = this.getSubTextProps(true);
        const ssoComponentProps = {
          onSSOClick: this.handleSSOClick,
        };
        const { loadingSigninWithPassword } = this.props;
        const passwordProps = {
          initialValues: {
            password: '',
          },
          onSubmit: this.onSubmitPassword,
          loading: loadingSigninWithPassword,
        };
        return (
          <Layout>
            <SignInOptions
              signinOptionsType={signinOptionsType}
              headingText={headingText}
              bodyText={bodyText}
              subTextProps={subTextProps}
              ssoComponentProps={ssoComponentProps}
              passwordProps={passwordProps}
            />
          </Layout>
        );
      }
      case SigninOptionsType.CREATE_PASSWORD: {
        const headingText = CREATE_PASSWORD_HEADER_TEXT;
        const bodyText = CREATE_PASSWORD_BODY_TEXT;
        const subTextProps = this.getSubTextProps();

        const { loading } = this.props;
        const createPasswordProps = {
          onClick: this.onForgotPassword,
          isLoading: loading,
        };
        return (
          <Layout>
            <SignInOptions
              signinOptionsType={signinOptionsType}
              headingText={headingText}
              bodyText={bodyText}
              subTextProps={subTextProps}
              createPasswordProps={createPasswordProps}
            />
          </Layout>
        );
      }

      default:
        return null;
    }
  };

  render() {
    const { classes, loading } = this.props;
    const {
      email,
      emailErrors,
      emailTouched,
      ssoLoading,
      ssoType,
      signinState,
    } = this.state;

    let isButtonDisabled = !emailTouched || emailErrors;
    if (email.length !== 0 && !emailErrors) {
      isButtonDisabled = false;
    }

    if (ssoLoading) {
      return (
        <Layout>
          <Grid container justify="center" style={{ marginTop: 90 }}>
            <Typography variant="body2" weight="bold" gutterBottom>
              {ssoLoading ? `Connecting to ${ssoType}` : 'Verifying Slack'}
            </Typography>
            <LoadingPulse isLoading />
          </Grid>
        </Layout>
      );
    }

    if (signinState === SIGNIN_STATE.VERIFY) {
      return this.renderVerifyState();
    }

    if (signinState === SIGNIN_STATE.PASSWORD_RESET) {
      return (
        <Layout>
          <StyledWrapper>
            <ValidateYourEmail />
          </StyledWrapper>
        </Layout>
      );
    }

    let headerText = WELCOME_BACK;
    let bodyText = SIGNIN_TO_ENTER;
    if (this.hasSAMLDisconnectedError()) {
      headerText = SAML_DISABLED_MESSAGE;
      bodyText = ENTER_EMAIL_TO_CREATE_PASSWORD;
    }

    return (
      <Layout>
        <Card className={classes.card}>
          <div className={classes.wrapper}>
            <div className={classes.body}>
              <form onSubmit={this.onNextClick}>
                <Typography variant="h5" weight="bold" gutterBottom>
                  {headerText}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {bodyText}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <SSOComponent
                    ssoType="signin"
                    onSSOClick={this.handleSSOClick}
                  />
                </div>
                <StyledDivider />
                <TextField
                  id="email"
                  type="text"
                  label="Your email address"
                  placeholder="name@workemail.com"
                  value={email}
                  onChange={this.handleChange('email')}
                  hasError={!!emailErrors}
                  helperText={emailErrors}
                  autoFocus
                  isFullWidth
                />

                <StyledButton
                  disabled={isButtonDisabled || loading || ssoLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  isFullWidth
                  isLoading={loading}
                >
                  Next
                </StyledButton>
                <SplitTreatments
                  names={[SplitNames.REMOVE_PASSWORD_BASED_AUTH]}
                >
                  {({ treatments: { REMOVE_PASSWORD_BASED_AUTH } }) =>
                    REMOVE_PASSWORD_BASED_AUTH.treatment !==
                      TreatmentTypes.ON && (
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ marginTop: 5 }}
                      >
                        Forgot your password?&nbsp;
                        <Link to={RoutesList.FORGOT_PASSWORD}>
                          Reset password
                        </Link>
                      </Typography>
                    )
                  }
                </SplitTreatments>
              </form>
            </div>
          </div>
        </Card>
        <Existing
          text="Don’t have an Assembly yet?"
          linkText="Get started"
          classes
          to={RoutesList.ENTER_EMAIL}
        />
      </Layout>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading:
    getRequestLoadingStatus(state) ||
    getSigninOptionsRequestLoadingStatus(state) ||
    getTriggerResetPasswordRequestLoadingStatus(state),
  loadingSigninWithPassword: getSigninWithPasswordRequestLoadingStatus(state),

  slackConfigInfo: getSlackConfigInfo(state),
});

export default connect(mapStateToProps, {
  loginOptions,
  loginWithPassword,
  userSessionCreatedAction,
  authorizeSignInWithSlack,
  triggerSetPassword,
})(withStyles(styles)(SignIn));
