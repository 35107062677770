import { Placement } from '@popperjs/core';

export interface PopoverChildrenProps {
  isPopoverOpen: boolean;
  togglePopover: () => void;
}

export enum PositionStrategies {
  Fixed = 'fixed',
  Absolute = 'absolute',
}

export interface UsePopoverProps {
  xAxisOffset?: number;
  yAxisOffset?: number;
  popoverPosition?: Placement;
  children: React.FunctionComponent<PopoverChildrenProps>;
  strategy?: PositionStrategies;
}
