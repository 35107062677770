// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import Layout from '../../../Layout/onboarding';
import VerticallyCenteredLayout from '../../../Layout/verticallyCentered';
import Typography from '../../../../componentsV2/core/Typography';
import Button from '../../../../componentsV2/core/Button';
import RoutesList from '../../../routes/routesList';
import themeV2 from '../../../../componentsV2/theme';

const styles = (theme) => ({
  emailStyle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonStyle: {
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
    marginTop: 30,
    '&:hover': {
      color: `${themeV2.palette.white} !important`,
    },
    '&:visited': {
      color: `${themeV2.palette.white} !important`,
    },
  },
});

const SignInLink = React.forwardRef((props, ref) => (
  <Link to={RoutesList.SIGN_IN} {...props} ref={ref} />
));

function EmailSent(props) {
  const { classes } = props;
  return (
    <Layout>
      <VerticallyCenteredLayout offsetHeight={200}>
        <div>
          <Typography weight="bold" variant="h4" gutterBottom>
            Your password reset request has been sent!
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Please check your inbox to reset your password
          </Typography>
        </div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            component={SignInLink}
            className={classes.buttonStyle}
          >
            Go back to Sign In
          </Button>
        </div>
      </VerticallyCenteredLayout>
    </Layout>
  );
}

EmailSent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(EmailSent));
