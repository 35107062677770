import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useRequest from '../../../../hooks/useRequest/useRequest';
import {
  ANALYTICS_PEOPLE_SUMMARY,
  ANALYTICS_RECOGNITION_SUMMARY,
  ANALYTICS_PROFILE_INFORMATION,
  ANALYTICS_PEOPLE_GRAPH_DATA,
  ANALYTICS_RECOGNITION_GRAPH_DATA,
  ANALYTICS_CORE_VALUES_GRAPH_DATA,
  ANALYTICS_BADGES_GRAPH_DATA,
  ANALYTICS_OVERALL_ENGAGEMENT,
} from '../../../../constants/endpoints';
import {
  PEOPLE_SUMMARY_TEMPLATE,
  RECOGNITION_SUMMARY_TEMPLATE,
  PROFILE_INFORMATION_TEMPLATE,
  serializeGraphData,
  calculateValues,
} from '../../../../Utils/data/analytics/common';
import { checkAdminAnalyticsAccess } from '../../../../modules/admin/billing/account/selector';
import { employerSelector } from '../../../../modules/admin/selector';

const useAdminInsightsModel = () => {
  const adminAnalyticsAccess = useSelector(checkAdminAnalyticsAccess);
  const employer = useSelector(employerSelector);
  const [currentCompanyName, assemblyCreatedAt] = useMemo(
    () => [employer?.name, employer?.createdAt],
    [employer],
  );
  const {
    fireRequest: makeOverallEngagementRequest,
    response: overallEngagementResponse,
    isLoading: overallEngagementRequestLoading,
    error: overallEngagementError,
  } = useRequest(ANALYTICS_OVERALL_ENGAGEMENT, {
    initialLoadingState: true,
  });
  const {
    fireRequest: makePeopleSummaryRequest,
    response: peopleSummaryResponse,
    isLoading: peopleSummaryRequestLoading,
    error: peopleSummaryError,
  } = useRequest(ANALYTICS_PEOPLE_SUMMARY, {
    initialLoadingState: true,
  });
  const {
    fireRequest: makeRecognitionSummaryRequest,
    response: recognitionSummaryResponse,
    isLoading: recognitionSummaryRequestLoading,
    error: recognitionSummaryError,
  } = useRequest(ANALYTICS_RECOGNITION_SUMMARY, {
    initialLoadingState: true,
  });
  const {
    fireRequest: makeProfileInfoRequest,
    response: profileInfoResponse,
    isLoading: profileInfoRequestLoading,
    error: profileInfoError,
  } = useRequest(ANALYTICS_PROFILE_INFORMATION, {
    initialLoadingState: true,
  });
  const {
    fireRequest: makePeopleDataRequest,
    response: peopleDataResponse,
    error: peopleDataError,
  } = useRequest(ANALYTICS_PEOPLE_GRAPH_DATA, {
    initialLoadingState: true,
    resetResponseOnRequest: true,
  });
  const {
    fireRequest: makeRecognitionDataRequest,
    response: recognitionDataResponse,
    error: recognitionDataError,
  } = useRequest(ANALYTICS_RECOGNITION_GRAPH_DATA, {
    initialLoadingState: true,
    resetResponseOnRequest: true,
  });
  const {
    fireRequest: makeCoreValuesDataRequest,
    response: coreValuesDataResponse,
    error: coreValuesDataError,
  } = useRequest(ANALYTICS_CORE_VALUES_GRAPH_DATA, {
    resetResponseOnRequest: true,
  });
  const {
    fireRequest: makeBadgesDataRequest,
    response: badgesDataResponse,
    error: badgesDataError,
  } = useRequest(ANALYTICS_BADGES_GRAPH_DATA, {
    resetResponseOnRequest: true,
  });

  const badgesGraphData = useMemo(
    () => serializeGraphData(badgesDataResponse),
    [badgesDataResponse],
  );
  const coreValuesGraphData = useMemo(
    () => serializeGraphData(coreValuesDataResponse),
    [coreValuesDataResponse],
  );
  const recognitionGraphData = useMemo(
    () => serializeGraphData(recognitionDataResponse),
    [recognitionDataResponse],
  );
  const peopleGraphData = useMemo(
    () => serializeGraphData(peopleDataResponse),
    [peopleDataResponse],
  );
  const profileInfoData = useMemo(() => {
    return PROFILE_INFORMATION_TEMPLATE.map((item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data } = profileInfoResponse || {};
      const responseItem = data?.[item.key];
      const error = Boolean(profileInfoError);
      return {
        isLoading: profileInfoRequestLoading,
        error,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...calculateValues(responseItem, item, error, true),
      };
    });
  }, [profileInfoError, profileInfoRequestLoading, profileInfoResponse]);
  const recognitionSummaryData = useMemo(() => {
    return RECOGNITION_SUMMARY_TEMPLATE.map((item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data } = recognitionSummaryResponse || {};
      const responseItem = data?.[item.key];
      const error = Boolean(recognitionSummaryError);
      return {
        isLoading: recognitionSummaryRequestLoading,
        error,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...calculateValues(responseItem, item, error),
      };
    });
  }, [
    recognitionSummaryError,
    recognitionSummaryRequestLoading,
    recognitionSummaryResponse,
  ]);
  const peopleSummaryData = useMemo(() => {
    return PEOPLE_SUMMARY_TEMPLATE.map((item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data } = peopleSummaryResponse || {};
      const responseItem = data?.[item.key];
      const error = Boolean(peopleSummaryError);
      return {
        isLoading: peopleSummaryRequestLoading,
        error,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...calculateValues(responseItem, item, error),
      };
    });
  }, [peopleSummaryError, peopleSummaryRequestLoading, peopleSummaryResponse]);

  const overallEngagementStatus = useMemo(() => {
    if (!overallEngagementResponse) {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return overallEngagementResponse?.data?.engagementRank;
  }, [overallEngagementResponse]);

  const returnValues = useMemo(
    () => ({
      actions: {
        makePeopleSummaryRequest,
        makeRecognitionSummaryRequest,
        makeProfileInfoRequest,
        makePeopleDataRequest,
        makeRecognitionDataRequest,
        makeCoreValuesDataRequest,
        makeBadgesDataRequest,
        makeOverallEngagementRequest,
      },
      data: {
        peopleSummaryData,
        recognitionSummaryData,
        profileInfoData,
        peopleGraphData,
        recognitionGraphData,
        coreValuesGraphData,
        badgesGraphData,
        adminAnalyticsAccess,
        currentCompanyName,
        assemblyCreatedAt,
        overallEngagementStatus,
      },
      metadata: {
        peopleDataError,
        recognitionDataError,
        coreValuesDataError,
        badgesDataError,
        overallEngagementRequestLoading,
        overallEngagementError,
      },
    }),
    [
      makePeopleSummaryRequest,
      makeRecognitionSummaryRequest,
      makeProfileInfoRequest,
      makePeopleDataRequest,
      makeRecognitionDataRequest,
      makeCoreValuesDataRequest,
      makeBadgesDataRequest,
      makeOverallEngagementRequest,
      peopleSummaryData,
      recognitionSummaryData,
      profileInfoData,
      peopleGraphData,
      recognitionGraphData,
      coreValuesGraphData,
      badgesGraphData,
      adminAnalyticsAccess,
      currentCompanyName,
      assemblyCreatedAt,
      overallEngagementStatus,
      peopleDataError,
      recognitionDataError,
      coreValuesDataError,
      badgesDataError,
      overallEngagementRequestLoading,
      overallEngagementError,
    ],
  );
  return returnValues;
};

export default useAdminInsightsModel;
