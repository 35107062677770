// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../core/Button';
import AddToSlackLogoNonRetina from '../../../img/Slack_buttons/add_to_slack@1x.png';
import AddToSlackLogoRetina from '../../../img/Slack_buttons/add_to_slack@2x.png';
import SignInWithSlackLogoNonRetina from '../../../img/Slack_buttons/sign_in_with_slack@1x.png';
import SignInWithSlackLogoRetina from '../../../img/Slack_buttons/sign_in_with_slack@2x.png';

const useStyles = makeStyles((theme) => ({
  SlackButton: {
    marginTop: 20,
    borderRadius: 6,
    height: 40,
    opacity: 1,
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    transition: '0.3s ease all',
    cursor: 'pointer',
    padding: 0,
    '& img': {
      maxHeight: 40,
    },
    '&:hover': {
      boxShadow: '0px 2px 6px rgba(44, 44, 44, 0.24)',
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },
}));

export default function SlackButton(props) {
  const classes = useStyles();
  const { onClick, addToSlack, signIn, ...rest } = props;
  if (addToSlack) {
    return (
      <Button className={classes.SlackButton} onClick={onClick} {...rest}>
        <img
          src={AddToSlackLogoNonRetina}
          srcSet={AddToSlackLogoRetina}
          alt="add to Slack"
        />
      </Button>
    );
  }
  return (
    <Button className={classes.SlackButton} onClick={onClick} {...rest}>
      <img
        src={SignInWithSlackLogoNonRetina}
        srcSet={SignInWithSlackLogoRetina}
        alt="sign-in with Slack"
      />
    </Button>
  );
}
