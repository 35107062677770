// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import { createSelector } from 'reselect';
import convertToArray from '../../../Utils/objectUtils/convertToArray';

export const getAccountsObjectSelector = (state) => {
  return _.get(state, ['superAdmin', 'data', 'accounts'], {});
};

export const getAccountsSelector = createSelector(
  getAccountsObjectSelector,
  (accounts) => {
    return convertToArray(accounts);
  },
);

export const getAccountCustomIconSelector = (state, employer) => {
  let icon = null;
  const customIcon = _.get(
    employer,
    ['customCurrency', 'currencyIconURL'],
    null,
  );
  const emoji = _.get(employer, ['customCurrency', 'currencyEmoji'], null);
  if (customIcon && customIcon.length > 0) {
    icon = {};
    icon.type = 'icon';
    icon.value = customIcon;
  } else if (emoji) {
    icon = {};
    icon.type = 'emoji';
    icon.value = emoji;
  }
  return icon;
};
