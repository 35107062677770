import { css } from 'styled-components';

export const ellipsisStyle = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FullWidthStyle = css`
  width: 100%;
`;

export const shadowElevation2Style = css`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12);
`;
