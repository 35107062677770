import React, { useState } from 'react';

import {
  StyledIconInfo,
  StyledWrapper,
  StyledHeading,
  StyledNewPasswordTextFieldWrapper,
  StyledReEnterPasswordTextFieldWrapper,
} from './styles';

import FormikField from '../../molecules/FormikField';
import Button from '../../atoms/Button';
import Body from '../../atoms/Body';
import NewTextfield from '../../molecules/NewTextfield';
import {
  ERROR,
  CREATE_YOUR_PASSWORD,
  CREATE_YOUR_PASSWORD_AND_SIGN_IN,
  CONFIRM_YOUR_PASSWORD,
} from '../../../languages/en/signIn';

import { CreateYourPasswordProps } from './interface';

const CreateYourPassword = (props: CreateYourPasswordProps) => {
  const {
    formik,
    showError = false,
    validationTexts,
    strength,
    isExpandOnFocusEnabled = false,
    isLoading = false,
  } = props;
  const [isFocused, setIsFocused] = useState(!isExpandOnFocusEnabled);
  const onFocusHandler = () => {
    setIsFocused(true);
  };
  const { dirty, isValid, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <StyledWrapper isExpandOnFocusEnabled={isExpandOnFocusEnabled}>
        {!isExpandOnFocusEnabled && (
          <StyledHeading variant="h6">{CREATE_YOUR_PASSWORD}</StyledHeading>
        )}
        {showError && (
          <StyledIconInfo
            icon="warning-red"
            iconColor="red6"
            backgroundColor="white"
          >
            <Body variant="body2" color="red6">
              {ERROR}
            </Body>
          </StyledIconInfo>
        )}
        {!isFocused && (
          <NewTextfield
            type="text"
            value=""
            onChange={() => {}}
            name="dummyTextField"
            label={CREATE_YOUR_PASSWORD}
            onInputFocus={onFocusHandler}
          />
        )}
        {isFocused && (
          <>
            <StyledNewPasswordTextFieldWrapper>
              <FormikField
                formik={formik}
                type="textV3_password"
                name="password"
                label={CREATE_YOUR_PASSWORD}
                strength={strength}
                validationTexts={validationTexts}
                autoFocus
              />
            </StyledNewPasswordTextFieldWrapper>
            <StyledReEnterPasswordTextFieldWrapper>
              <FormikField
                formik={formik}
                type="textV3_password"
                name="confirmPassword"
                label={CONFIRM_YOUR_PASSWORD}
              />
            </StyledReEnterPasswordTextFieldWrapper>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              status="default"
              disabled={!(dirty && isValid)}
              isFullWidth
              isLoading={isLoading}
            >
              {CREATE_YOUR_PASSWORD_AND_SIGN_IN}
            </Button>
          </>
        )}
      </StyledWrapper>
    </form>
  );
};

export default CreateYourPassword;
