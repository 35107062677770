const billingTransitions = () => {
  const ACCOUNT = {
    CLOSED: [],
    DEACTIVATED: ['ACTIVE'],
    ACTIVE: ['DEACTIVATED'],
  };
  const PAID_REWARD = {
    INACTIVE: [],
    DENIED_BY_ADMIN: ['VERIFIED_BY_ADMIN', 'SOFTVERIFIED_BY_ADMIN'],
    UNVERIFIED: ['SOFTVERIFIED_BY_ADMIN', 'DENIED_BY_ADMIN'],
    UNVERIFIED_BY_ADMIN: ['SOFTVERIFIED_BY_ADMIN', 'DENIED_BY_ADMIN'],
    SOFTVERIFIED: [
      'VERIFIED_BY_ADMIN',
      'UNVERIFIED_BY_ADMIN',
      'DENIED_BY_ADMIN',
    ],
    SOFTVERIFIED_BY_ADMIN: [
      'VERIFIED_BY_ADMIN',
      'UNVERIFIED_BY_ADMIN',
      'DENIED_BY_ADMIN',
    ],
    VERIFIED_BY_ADMIN: ['SOFTVERIFIED_BY_ADMIN', 'DENIED_BY_ADMIN'],
  };
  return {
    ACCOUNT,
    PAID_REWARD,
  };
};

export default billingTransitions();
