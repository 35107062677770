export const FLOWS_PREFERENCE_SETTINGS = {
  PREFERENCE_HEADING: 'Set who has permission to create new flows',
  PREFERENCE_SUB_TITLE: 'People who can create new flows',
  PREFERENCE_SELECT_HELPER_TEXT:
    'Workspace Owners & Admins always have access.',
  UPGRADE_TEXT: 'Upgrade to edit',
  GIF_FORMAT_HEADING: 'Set GIF MMPA ratings',
  GIF_FORMAT_SUB_TITLE:
    'GIFs from Giphy are rated according to appropriateness, similar to movies – we recommend PG-13 or lower',
  GIF_FORMAT_SELECT_LABEL: 'GIF’s MPAA Rating',
};

export const FLOWS_PERMISSIONS_TEXTS = {
  FLOW_CREATION_PERMISSIONS_TITLE: 'Flow Creation Permissions',
  FLOW_CREATION_PERMISSIONS_SUBTITLE:
    'Configure access to certain features while creating flows.',
  LEARN_MORE_TEXT: 'Learn more',
  LEARN_MORE_URL: '',
  UPGRADE_TEXT: 'Upgrade to edit',
  FLOW_PERMISSIONS_HELPER_TEXT: 'Workspace Owners & Admins always have access.',
  CREATE_FLOW_PERMISSIONS_TITLE: 'Set who has permission to create new flows',
  CREATE_FLOW_PERMISSIONS_SUBTITLE: 'People who can create new flows',
  ADD_EVERYONE_PERMISSION_TITLE:
    'Set who has permission to add entire organization as a participant in a flow',
  ADD_EVERYONE_PERMISSION_SUBTITLE:
    'Anyone who has this permission can select “Entire Organization” as a criteria for participation.',
  ADD_GIVE_POINTS_PERMISSION_TITLE:
    'Set who has permission to add “Give Points” blocks to a flow',
  ADD_GIVE_POINTS_PERMISSION_SUBTITLE:
    'Anyone with this permission can create a flow where participants are prompted to give points to others.',
  GIF_FORMAT_HEADING: 'Set GIF MMPA ratings',
  GIF_FORMAT_SUB_TITLE:
    'GIFs from Giphy are rated according to appropriateness, similar to movies – we recommend PG-13 or lower',
  GIF_FORMAT_SELECT_LABEL: 'GIF’s MPAA Rating',
  PERMISSION_CHANGED_SUCCESSFULLY: 'Permissions updated!',
  PERMISSION_CHANGE_ERROR: 'Permissions not updated. Please try again.',
};

export const CURRENCY_SETTINGS_TEXTS = {
  EMOJI_LABEL: 'Emoji',
  LABEL: 'Currency name, singular',
  HELPER_TEXT: 'Name should be singular and less than 22 characters',
};
