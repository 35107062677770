import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useProfileInfoFetchQuery } from '../queries/Profile';

const useDataDog = (isAuthenticated: boolean) => {
  const { data } = useProfileInfoFetchQuery(isAuthenticated);

  useEffect(() => {
    if (data) {
      datadogRum.setUser({
        id: data.member.memberId,
        email: data.member.email,
        name: `${data.member.profile.firstName} ${data.member.profile.lastName}`,
        assemblyId: data.assembly.assemblyId,
      });
    }
  }, [data]);
};

export default useDataDog;
