// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const styles = {
  card: {
    minWidth: 275,
    borderRadius: 10,
    border: 'none',
    backgroundColor: '#ffffff',
    padding: '43px 53px',
    boxShadow: '0px 10px 25px rgba(202,202,202,0.75);',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
};

function SimpleCard(props) {
  const { classes, children, style, className } = props;
  // const bull = <span className={classes.bullet}>•</span>;
  return (
    <Card className={classNames(classes.card, className)} style={style}>
      {children}
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(SimpleCard);
