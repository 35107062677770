export const CREATE_YOUR_PASSWORD = 'Create your password';
export const ERROR = 'Something went wrong. Please try again.';
export const CURRENT_PASSWORD_ERROR =
  'Incorrect password. Forgot your password? Click below.';
export const REQUIRED_CONFIRM_PASSWORD = 'Confirm your password is required';
export const PASSWORD_DO_NOT_MATCH_ERROR = `Password did not match`;
export const NEW_PASSWORD = 'New Password';
export const CONFIRM_YOUR_PASSWORD = 'Confirm your password';
export const CREATE_YOUR_PASSWORD_AND_SIGN_IN =
  'Create your password and sign in';
export const IS_THIS_CORRECT = 'Is this incorrect?';
export const RETURN_TO_SIGN_IN = 'Return to sign in';

export const VALIDATE_YOUR_EMAIL = {
  HEADING: 'Validate your email',
  BODY: `We sent you an email to verify your identity. Click the link in that
  email to come back and set a password.`,
};
export const signInWithText = {
  SLACK: 'Sign in with Slack',
  OFFICE365: 'Sign in with Office365',
  ADP: 'Sign in with ADP',
  GOOGLE: 'Sign in with Google',
  LIFION: 'Sign in with Lifion',
};

export const WELCOME_BACK = 'Welcome back!';
export const SIGNIN_TO_ENTER = 'Please sign in below to enter your account';
export const SIGN_IN_BUTTON_TEXT = 'Sign in';
export const SIGN_IN_LOADING_BUTTON_TEXT = 'Signing in...';
export const CREATE_PASSWORD = 'Create a password';

export const RETURN_TO_SIGN_IN_TEXT = 'Return to sign in';
export const RESET_PASSWORD_TEXT = 'Reset your password';
export const IS_THIS_CORRECT_TEXT = 'Is this correct?';

export const VERIFYING_SAML = 'Verifying SAML';
export const CONNECTING_TO = 'Connecting to';

export const SAML_DISABLED_MESSAGE =
  'Your admin has disabled SAML authentication.';
export const ENTER_EMAIL_TO_CREATE_PASSWORD =
  'Choose an SSO or enter your email to create a password for future logins.';

export const SIGN_IN_WITH_SAML_TEXT = (samlName: string) =>
  `${SIGN_IN_BUTTON_TEXT} with ${samlName}`;

export const SAML_WITH_SSO_TEXT = (samlName: string, ssoName: string) =>
  `Your account allows authorization with ${samlName}. Click the “Sign in with ${samlName}” button to log in or sign in with ${ssoName}.`;

export const SAML_WITH_NO_PREFERRED_SSO_TEXT = (samlName: string) =>
  `Please sign in with ${samlName}.`;

export const SAML_WITH_NO_PREFERRED_SSO_BODY_TEXT = (samlName: string) =>
  `Your account allows authorization with ${samlName}. Click the “Sign in with ${samlName}” button to log in or choose an SSO.`;

export const SAML_WITH_PASSWORD_TEXT = (samlName: string) =>
  `Your account allows authorization with ${samlName}. Click the “Sign in with ${samlName}” button to log in or enter your password below`;

export const SAML_ONLY_HEADER_TEXT = (samlName: string) =>
  `Please sign in with ${samlName}`;

export const SAML_ONLY_BODY_TEXT = (samlName: string) =>
  `Your account requires authorization with ${samlName} - please click the “Sign in with ${samlName}” button to log into your Assembly. If you don’t have ${samlName} access, please talk to your Assembly admin.`;

export const SSO_ENFORCED_HEADER_TEXT = 'Please use your SSO';

export const LIFION_ENFORCED_HEADER_TEXT = 'You’re registered with Lifion';

export const LIFION_ENFORCED_BODY_TEXT =
  'Lifion has been used to authorize your account. Please log into your company’s Lifion account in order to log into your Assembly.';

export const SSO_ENFORCED_BODY_TEXT = (ssoName: string) =>
  `Your account is authorized with ${ssoName} - please click the “Sign in with ${ssoName}” button to log into your Assembly.`;

export const NO_PREFERRED_SSO_HEADER_TEXT = 'Use SSO or enter your password';
export const NO_PREFERRED_SSO_BODY_TEXT =
  'Choose an SSO to authenticate or enter your password.';

export const CREATE_PASSWORD_HEADER_TEXT = `Your account doesn't have a password`;
export const CREATE_PASSWORD_BODY_TEXT =
  'Choose an SSO to authenticate or create a password.';
