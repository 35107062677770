// @ts-nocheck TODO: type issues need to be fixed in this file
import { requestsReducer } from 'redux-saga-requests';
import {
  HeadersWithoutAuthV2,
  AuthHeadersV2,
} from '../../../modules/shared/HeaderToken';
import { API_URL_WITH_V2, API_URL_WITH_V3 } from '../../../config';

const apiEndpoints = {
  REGISTER_EMPLOYEE_WITH_EMAIL: `${API_URL_WITH_V2}/auth/onboard/employee/register`,
  REGISTER_EMPLOYEE_WITH_DOMAIN: `${API_URL_WITH_V2}/auth/onboard/employee/register/domain`,
  GET_EMPLOYER_DETAILS: `${API_URL_WITH_V3}/assembly/`,
  VERIFY_MAGIC_LINK: `${API_URL_WITH_V2}/auth/onboard/employee/verify/magiclink/`,
  VERIFY_INVITE_TOKEN: `${API_URL_WITH_V2}/auth/onboard/token/`,
  SET_PROFILE_FOR_INVITE_TOKEN: `${API_URL_WITH_V2}/auth/onboard/employee/inviteToken/completeProfile`,
  SET_PROFILE_FOR_EMPLOYEE: `${API_URL_WITH_V2}/auth/onboard/employee/magiclink/completeProfile`,
  SET_DETAILS_FOR_USER: `${API_URL_WITH_V2}/auth/onboard/employee/completeProfile`,
  SET_SKIP_DETAILS_FOR_USER: `${API_URL_WITH_V3}/assembly/members/setOnboardingStatus`,
};

const REGISTER_EMPLOYEE_WITH_EMAIL =
  '@sessions/V2/REGISTER_EMPLOYEE_WITH_EMAIL';
export const REGISTER_EMPLOYEE_WITH_DOMAIN =
  '@sessions/V2/REGISTER_EMPLOYEE_WITH_DOMAIN';
const GET_EMPLOYER_DETAILS = '@sessions/V2/GET_EMPLOYER_DETAILS';
const VERIFY_MAGIC_LINK = '@sessions/V2/VERIFY_MAGIC_LINK';
const VERIFY_INVITE_TOKEN = '@sessions/V2/VERIFY_INVITE_TOKEN';
export const SET_PROFILE_FOR_INVITE_TOKEN =
  '@sessions/V2/SET_PROFILE_FOR_INVITE_TOKEN';
export const SET_PROFILE_FOR_EMPLOYEE = '@sessions/V2/SET_PROFILE_FOR_EMPLOYEE';
export const SET_DETAILS_FOR_USER = '@sessions/V2/SET_DETAILS_FOR_USER';
export const SET_SKIP_DETAILS_FOR_USER =
  '@sessions/V2/SET_SKIP_DETAILS_FOR_USER';

export const registerEmployeeWithEmail = ({ email, employerId }) => ({
  type: REGISTER_EMPLOYEE_WITH_EMAIL,
  request: {
    url: apiEndpoints.REGISTER_EMPLOYEE_WITH_EMAIL,
    data: {
      email,
      employerId,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
});

export const registerEmployeeWithDomain = ({ email }) => ({
  type: REGISTER_EMPLOYEE_WITH_DOMAIN,
  request: {
    url: apiEndpoints.REGISTER_EMPLOYEE_WITH_DOMAIN,
    data: {
      email,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
});

export const getEmployerName = ({ employerId }) => ({
  type: GET_EMPLOYER_DETAILS,
  request: {
    url: `${apiEndpoints.GET_EMPLOYER_DETAILS}${employerId}/name`,
    method: 'get',
    ...HeadersWithoutAuthV2(),
  },
});

export const getMagicLinkVerified = ({ magicLink }) => ({
  type: VERIFY_MAGIC_LINK,
  request: {
    url: `${apiEndpoints.VERIFY_MAGIC_LINK}${magicLink}`,
    method: 'get',
    ...HeadersWithoutAuthV2(),
  },
});

export const getInviteTokenVerified = ({ inviteToken }) => ({
  type: VERIFY_INVITE_TOKEN,
  request: {
    url: `${apiEndpoints.VERIFY_INVITE_TOKEN}${inviteToken}`,
    method: 'get',
    ...HeadersWithoutAuthV2(),
  },
});

export const setProfileForInviteToken = ({
  inviteToken,
  password,
  fullName,
}) => ({
  type: SET_PROFILE_FOR_INVITE_TOKEN,
  request: {
    url: apiEndpoints.SET_PROFILE_FOR_INVITE_TOKEN,
    data: {
      password,
      fullName,
      inviteToken,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
});

export const setProfileForEmployee = ({ userId, password, fullName }) => ({
  type: SET_PROFILE_FOR_EMPLOYEE,
  request: {
    url: apiEndpoints.SET_PROFILE_FOR_EMPLOYEE,
    data: {
      password,
      fullName,
      userId,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
});

export const setDetailsForEmployee = ({
  username,
  birthday,
  hiredDate,
  hiredday,
}) => ({
  type: SET_DETAILS_FOR_USER,
  request: {
    url: apiEndpoints.SET_DETAILS_FOR_USER,
    data: {
      username,
      birthday,
      hiredDate,
      hiredday,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const setSkipDetailsForEmployee = (isOnboardingComplete = true) => ({
  type: SET_SKIP_DETAILS_FOR_USER,
  request: {
    url: apiEndpoints.SET_SKIP_DETAILS_FOR_USER,
    data: {
      isOnboardingComplete,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export default requestsReducer({
  actionType: REGISTER_EMPLOYEE_WITH_EMAIL,
  mutations: {
    [REGISTER_EMPLOYEE_WITH_DOMAIN]: { updateData: false },
    [SET_PROFILE_FOR_INVITE_TOKEN]: { updateData: false },
    [SET_PROFILE_FOR_EMPLOYEE]: { updateData: false },
    [SET_DETAILS_FOR_USER]: { updateData: false },
    [SET_SKIP_DETAILS_FOR_USER]: { updateData: false },
  },
});
