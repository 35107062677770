import React from 'react';
import { PulseLoader } from 'react-spinners';
import Grid from '@material-ui/core/Grid';

import themeV2 from '../../../componentsV2/theme';

export interface LoadingPulseProps {
  size?: number;
  hasPadding?: boolean;
  isLoading?: boolean;
}

// Adapted from LoadingPulse from shared components folder
const LoadingPulse = ({
  hasPadding,
  isLoading = true,
  size = 16,
}: LoadingPulseProps) => (
  <Grid
    container
    justifyContent="center"
    style={hasPadding ? { padding: '1rem' } : { margin: '1rem 0' }}
    data-testid="loadingPulse"
  >
    <PulseLoader
      size={size}
      color={themeV2.palette.primary.main}
      loading={isLoading}
    />
  </Grid>
);

export default LoadingPulse;
