import React from 'react';

import SVGIcon from '../../atoms/SVGIcon';
import IntegrationButtonBase from '../../atoms/IntegrationButtonBase';

export interface IntegrationButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  text?: string;
  logo?: string;
  iconSize?: string;
  showUpgradeInfo?: boolean;
  upgradeText?: string;
  linkText?: string;
}

const IntegrationButton = ({
  disabled = false,
  onClick,
  logo,
  text,
  showUpgradeInfo = false,
  iconSize = '16px',
  linkText,
  upgradeText,
}: IntegrationButtonProps) => (
  <IntegrationButtonBase
    disabled={disabled}
    onClick={onClick}
    text={text}
    linkText={linkText}
    showUpgradeInfo={showUpgradeInfo}
    upgradeText={upgradeText}
  >
    {logo && <SVGIcon icon={logo} size={iconSize} />}
  </IntegrationButtonBase>
);

export default IntegrationButton;
