// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  string,
  oneOf,
  bool,
  func,
  shape,
  arrayOf,
  oneOfType,
  number,
  node,
} from 'prop-types';

import TextField from '../../atoms/TextField';
import TextField_V2 from '../TextField_V2';
import TextField_V3 from '../NewTextfield';
import Label from '../../atoms/Label';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import TextArea from '../../atoms/TextArea';
import ProgressValidator from '../ProgressValidator';
import ValidationText from '../ValidationText';
import DropdownGroup, { optionsListPropType } from '../DropdownGroup';
import ThemeV2 from '../../../componentsV2/theme';
import DropdownWithMultiSelect from '../DropdownWithMultiSelect';
import TextFieldWithChips from '../TextfieldWithChips';
import CalendarInput from '../CalendarInput';
import RadioGroup from '../RadioGroup';
import Checkbox from '../../atoms/Checkbox';
import TextFieldWithEmoticonPicker from '../TextFieldWithEmoticonPicker';
import FlowCheckbox from '../FlowCheckbox';
import { SingleSelectAutoComplete } from '../../organism/Autocomplete';

const useStyles = makeStyles({
  component: {
    marginBottom: '4px',
  },
  progressValidator: {
    marginBottom: 4,
  },
  validationTexts: {
    marginBottom: 4,
  },
  helperText: (props) => ({
    color: props.error ? ThemeV2.palette.red6 : ThemeV2.palette.gray7,
  }),
});

const Input = (props) => {
  let Component;
  switch (props.type) {
    case 'text':
    case 'password':
    case 'number':
      Component = TextField;
      break;
    case 'button':
    case 'submit':
      Component = Button;
      break;
    case 'dropdown':
      Component = DropdownGroup;
      break;
    case 'dropdownWithMultiSelect':
      Component = DropdownWithMultiSelect;
      break;
    case 'textWithChips':
      Component = TextFieldWithChips;
      break;
    case 'date':
      Component = CalendarInput;
      break;
    case 'textV2_text':
    case 'textV2_password':
    case 'textV2_number':
      Component = TextField_V2;
      break;
    case 'textArea':
      Component = TextArea;
      break;
    case 'textV3_text':
    case 'textV3_password':
    case 'textV3_number':
      Component = TextField_V3;
      break;
    case 'radio':
      Component = RadioGroup;
      break;
    case 'checkbox':
      Component = Checkbox;
      break;
    case 'textV3_text_emoticon_picker':
      Component = TextFieldWithEmoticonPicker;
      break;
    case 'flowCheckbox':
      Component = FlowCheckbox;
      break;
    case 'singleSelectAutoComplete':
      Component = SingleSelectAutoComplete;
      break;
    default:
      Component = TextField;
  }
  const {
    type,
    value,
    placeholder,
    icon,
    iconSize,
    iconHasBackground,
    iconBackgroundColor,
    domain,
    error,
    autoFocus,
    autoComplete,
    isValid,
    onChange,
    onKeyPress,
    onBlur,
    className: parentClassName,
    onClick,
    disabled,
    isFullWidth,
    strength,
    validationTexts,
    optionsList,
    width,
    selectedOptions,
    onChangeList,
    helperText,
    label,
    name,
    options,
    rows,
    multiline,
    onInputChange,
    onInputFocus,
    isLoading,
    noOptionsText,
    disabledDays,
    helperTextVariant,
    enableChip,
    dropdownItems,
    selectedItems,
    limitTags,
    hasDropdown,
    isEditable,
    disableLimit,
    isMultiSelectEnable,
    radioOptions,
    size,
    img,
    disableClearButton,
    removeElevation,
  } = props;

  const componentType = useMemo(() => {
    if (
      type === 'textV2_text' ||
      type === 'textV3_text' ||
      type === 'textV3_text_emoticon_picker'
    )
      return 'text';
    if (type === 'textV2_password' || type === 'textV3_password')
      return 'password';
    if (type === 'textV2_number' || type === 'textV3_number') return 'number';
    return type;
  }, [type]);

  const dropDownProps = useMemo(() => {
    if (hasDropdown) {
      return {
        enableChip,
        dropdownItems,
        selectedItems,
        limitTags,
        isEditable,
      };
    }
    return null;
  }, [
    dropdownItems,
    enableChip,
    isEditable,
    limitTags,
    selectedItems,
    hasDropdown,
  ]);

  const inputProps = {
    name,
    type: componentType,
    value,
    placeholder,
    icon,
    iconSize,
    iconHasBackground,
    iconBackgroundColor,
    domain,
    error,
    autoFocus,
    isValid,
    onChange,
    onKeyPress,
    onBlur,
    onClick,
    disabled,
    isFullWidth,
    optionsList,
    width,
    selectedOptions,
    onChangeList,
    options,
    onInputChange,
    onInputFocus,
    isLoading,
    noOptionsText,
    disabledDays,
    label,
    helperText,
    disableLimit,
    isMultiSelectEnable,
    /* This property is for TextField_V2 */
    hasError: error,
    radioOptions,
    size,
    disableClearButton,
    rows,
    multiline,
    removeElevation,
    autoComplete,
    ...dropDownProps,
    img,
  };

  const isTextFieldV2orV3 = useMemo(() => {
    if (
      type === 'textV2_text' ||
      type === 'textV3_text' ||
      type === 'textV3_text_emoticon_picker'
    )
      return true;
    if (type === 'textV2_number' || type === 'textV3_number') return true;
    if (type === 'textV2_password' || type === 'textV3_password') return true;
    if (type === 'textArea') return true;
    if (type === 'singleSelectAutoComplete') return true;
    return false;
  }, [type]);
  const isCheckbox = useMemo(() => {
    return type === 'checkbox' || type === 'flowCheckbox';
  }, [type]);
  const classes = useStyles(props);
  const className = `${classes.component} ${parentClassName}`;

  return (
    <>
      {label && !isTextFieldV2orV3 && !isCheckbox && (
        <Label id={name}>{label}</Label>
      )}
      <Component
        {...inputProps}
        className={className}
        showClearIcon={!disableClearButton}
      />
      {helperText && !isTextFieldV2orV3 && (
        <Body variant={helperTextVariant} className={classes.helperText}>
          {helperText}
        </Body>
      )}
      {strength && (
        <ProgressValidator
          strength={strength}
          className={classes.progressValidator}
        />
      )}
      {validationTexts.length > 0 && (
        <div className={classes.validationTexts}>
          {validationTexts.map((item) => (
            <ValidationText key={item.text} checked={item.checked}>
              {item.text}
            </ValidationText>
          ))}
        </div>
      )}
    </>
  );
};

export const inputTypePropType = oneOf([
  'text',
  'textV2_text',
  'textV2_number',
  'textV2_password',
  'textV3_text',
  'textV3_number',
  'textV3_password',
  'textArea',
  'textV2_textArea',
  'number',
  'password',
  'button',
  'submit',
  'dropdown',
  'dropdownWithMultiSelect',
  'textWithChips',
  'date',
  'radio',
  'checkbox',
  'textV3_text_emoticon_picker',
  'flowCheckbox',
  'singleSelectAutoComplete',
]);

export const AssemblyCurrencyTypes = shape({
  name: string.isRequired,
  pluralName: string.isRequired,
  type: oneOf(['REGULAR', 'CUSTOM']).isRequired,
  value: string,
});

export const individualItemTypes = arrayOf(
  shape({
    value: string.isRequired,
    id: (string || number).isRequired,
    isSelected: bool,
    prefixIcon: string,
    prefixImg: string,
    disabled: bool,
    deleteItem: bool,
    assemblyCurrency: AssemblyCurrencyTypes,
    emoji: string,
  }),
);

export const dropdownItemsTypes = arrayOf(
  shape({
    id: (string || number || null || undefined).isRequired,
    value: string,
    prefixIcon: string || undefined,
    prefixImg: string || undefined,
    disabled: bool || undefined,
    deleteItem: bool || undefined,
    category: string,
    items: individualItemTypes,
  }),
);

export const SelectedItemTypes = arrayOf(
  shape({
    id: (string || number).isRequired,
    label: string.isRequired,
    avatar: string,
    clickable: bool,
  }),
);

Input.propTypes = {
  type: inputTypePropType,
  onChange: func,
  onBlur: func,
  onKeyPress: func,
  value: oneOfType([
    string,
    bool,
    shape(),
    arrayOf(shape()),
    number,
    arrayOf(string),
  ]),
  placeholder: string,
  icon: string,
  iconSize: string,
  iconHasBackground: bool,
  iconBackgroundColor: oneOf(Object.keys(ThemeV2.palette)),
  domain: string,
  error: bool,
  autoFocus: bool,
  name: string,
  label: node,
  disabled: bool,
  helperText: string,
  strength: string,
  validationTexts: arrayOf(
    shape({
      text: string.isRequired,
      checked: bool,
    }),
  ),
  onClick: func,
  isValid: bool,
  rows: number,
  multiline: bool,
  isFullWidth: bool,
  className: string,
  optionsList: optionsListPropType,
  width: string,
  selectedOptions: arrayOf(string) || arrayOf(number),
  onChangeList: arrayOf(func),
  options: oneOfType([arrayOf(string), arrayOf(shape())]),
  onInputChange: func,
  onInputFocus: func,
  isLoading: bool,
  noOptionsText: string,
  disabledDays: func,
  helperTextVariant: string,
  enableChip: bool,
  dropdownItems: dropdownItemsTypes,
  selectedItems: SelectedItemTypes,
  limitTags: number,
  hasDropdown: bool,
  isEditable: bool,
  disableLimit: bool,
  isMultiSelectEnable: bool,
  disableClearButton: bool,
  removeElevation: bool,
  radioOptions: arrayOf(
    shape({
      value: string.isRequired,
      label: string,
    }),
  ),
  size: string,
  img: string,
  autoComplete: string,
  showClearIcon: bool,
};

Input.defaultProps = {
  type: 'text',
  error: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  onKeyPress: () => {},
  placeholder: '',
  strength: null,
  validationTexts: [],
  value: '',
  icon: null,
  iconSize: '18px',
  iconHasBackground: false,
  iconBackgroundColor: null,
  domain: null,
  autoFocus: false,
  rows: null,
  multiline: false,
  removeElevation: true,
  name: '',
  label: '',
  isValid: false,
  helperText: null,
  onClick: () => {},
  disableClearButton: false,
  isFullWidth: false,
  className: '',
  optionsList: [],
  width: 'auto',
  selectedOptions: [],
  onChangeList: [],
  options: [],
  onInputChange: () => {},
  onInputFocus: () => {},
  isLoading: false,
  noOptionsText: undefined,
  disabledDays: () => {},
  helperTextVariant: 'body3',
  enableChip: false,
  dropdownItems: [],
  selectedItems: [],
  limitTags: -1,
  hasDropdown: false,
  isEditable: false,
  disableLimit: false,
  isMultiSelectEnable: true,
  radioOptions: undefined,
  size: undefined,
  img: '',
  autoComplete: '',
  showClearIcon: false,
};

const MemoizedInput = React.memo(Input);
MemoizedInput.displayName = 'Input';

export default MemoizedInput;
