// @ts-nocheck TODO: type issues need to be fixed in this file
// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_USER_PROFILE = 'show_user_profile';
export const HIDE_USER_PROFILE = 'hide_user_profile';
export const TOGGLE_NOTIFICATIONS = 'toggle_notifications';

export const RESIZE_WINDOW = 'resize_window';

export const SHOW_INCOMPLETE_ONBOARDING = 'show_incomplete_onboarding';
export const HIDE_INCOMPLETE_ONBOARDING = 'hide_incomplete_onboarding';

export const UPDATE_GIVE_CARROTS_UI = 'update_give_carrots_ui';
export const ADD_EMOJI_TO_MESSAGE = 'add_emoji_to_message';
export const EMPLOYEES_SET_UP_UPDATES = 'employee_set_up_updates';

export const GIVE_CARROTS_UI_STATUSES = {
  BLANK: 'blank',
  RESET: 'reset',
  NO_RESET: 'no_reset',
};
export const ADD_EMOJI_STATUS = {
  RESET: 'reset',
  SET: 'set',
};
export const EMPLOYEES_SETUP_STATUSES = {
  UN_KNOWN: 'un_known',
  HAS_EMPLOYEES: 'has_employees',
  NO_EMPLOYEES: 'no_employees',
};

// ------------------------------------
// Actions
// ------------------------------------

const showUserProfile = (user) => ({
  type: SHOW_USER_PROFILE,
  user,
});

const hideUserProfile = () => ({
  type: HIDE_USER_PROFILE,
  user: null,
});
const toggleNotifications = () => ({
  type: TOGGLE_NOTIFICATIONS,
});

const showIncompleteOnboarding = () => ({
  type: SHOW_INCOMPLETE_ONBOARDING,
});
const hideIncompleteOnboarding = () => ({
  type: HIDE_INCOMPLETE_ONBOARDING,
});

const resizeWindow = (windowSize) => ({
  type: RESIZE_WINDOW,
  windowSize,
});
const updateGiveCarrotsUI = (status = GIVE_CARROTS_UI_STATUSES.BLANK) => ({
  type: UPDATE_GIVE_CARROTS_UI,
  status,
});
const updateEmployeesSetupStatus = (
  status = EMPLOYEES_SETUP_STATUSES.UN_KNOWN,
) => ({
  type: EMPLOYEES_SET_UP_UPDATES,
  status,
});
const addEmojiToMessage = (status = ADD_EMOJI_STATUS.RESET, emoji) => ({
  type: ADD_EMOJI_TO_MESSAGE,
  status,
  emoji,
});

export const uiActions = {
  showUserProfile,
  hideUserProfile,
  toggleNotifications,
  showIncompleteOnboarding,
  hideIncompleteOnboarding,
  resizeWindow,
  updateGiveCarrotsUI,
  updateEmployeesSetupStatus,
  addEmojiToMessage,
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case SHOW_USER_PROFILE:
      return { ...state, userProfileToShow: action.user };
    case HIDE_USER_PROFILE:
      return { ...state, userProfileToShow: null };
    case TOGGLE_NOTIFICATIONS:
      return { ...state, showNotification: !state.showNotification };
    case SHOW_INCOMPLETE_ONBOARDING:
      return { ...state, showIncompleteOnboarding: true };
    case HIDE_INCOMPLETE_ONBOARDING:
      return { ...state, showIncompleteOnboarding: false };
    case RESIZE_WINDOW:
      return { ...state, windowSize: action.windowSize };
    case UPDATE_GIVE_CARROTS_UI:
      return { ...state, giveCarrotUIStatus: action.status };
    case EMPLOYEES_SET_UP_UPDATES:
      return { ...state, employeeSetupStatus: action.status };
    case ADD_EMOJI_TO_MESSAGE:
      return {
        ...state,
        emojiToAdd: {
          emoji: action.emoji,
          status: action.status,
        },
      };
    default:
      return state; // , showNotification : false
  }
};
