// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
};

function VerticallyCenteredLayout(props) {
  const { children, classes } = props;
  const { offsetHeight } = props;

  return (
    <div
      className={classes.root}
      style={{ minHeight: `calc(100vh - ${offsetHeight}px)` }}
    >
      {children}
    </div>
  );
}

VerticallyCenteredLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  offsetHeight: PropTypes.number,
};

VerticallyCenteredLayout.defaultProps = {
  offsetHeight: 90,
};

export default withStyles(styles)(VerticallyCenteredLayout);
