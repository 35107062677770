import ThemeV2 from '../../componentsV2/theme';

export const mapHexCodeToEmoticon = (value: string) => {
  if (!value) {
    throw new Error('invalid arguments');
  }

  if (value.indexOf('-') > 0) {
    return String.fromCodePoint(
      ...value.split('-').map((item) => +`0x${item}`),
    );
  }

  return String.fromCodePoint(+`0x${value}`);
};

export const mapColorToPalette = (value: string) => {
  if (!value) {
    throw new Error('invalid arguments');
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore  Theme object structure is not consistent
  const color = ThemeV2.palette[value];
  return color || ThemeV2.palette.blue2;
};
