import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as microsoftTeams from '@microsoft/teams-js';

import RoutesList from '../../../aV2/routes/routesList';
import Button from '../../atoms/Button';
import { StyledRoot, StyledHeading, StyledBody } from '../../pages/SSOInfoCard';
import SSOComponent from '../../../pages/signin/SSOComponent';
import Field from '../../molecules/Field';
import CreateYourPassword from '../CreateYourPassword';

import {
  StyledDivider,
  StyledSigninSubText,
  StyledSubmitButton,
} from './styles';
import {
  SigninOptionsProps,
  SigninOptionsType,
  SigninOptionsLayoutType,
  PasswordProps,
  HeaderProps,
  SigninOptionsLayoutProps,
} from './interface';
import { AUTH_SAML } from '../../../constants/routes';
import {
  SIGN_IN_BUTTON_TEXT,
  CREATE_PASSWORD,
} from '../../../languages/en/signIn';
import { SSOTypes } from '../../../Utils/data/ssoManager/common';
import { isSourceMSTeams, MS_TEAMS } from '../../../Utils/msteams';

const PasswordComponent = (props: PasswordProps) => {
  const { initialValues, onSubmit, loading } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty, isValid, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*  @ts-ignore  */}
          <Field
            type="textV3_password"
            label="Enter your password"
            name="password"
            autoFocus
          />
          <StyledSubmitButton
            type="submit"
            color="primary"
            variant="contained"
            status="default"
            disabled={!(dirty && isValid)}
            size="medium"
            isFullWidth
            isLoading={loading}
          >
            {SIGN_IN_BUTTON_TEXT}
          </StyledSubmitButton>
        </form>
      )}
    </Formik>
  );
};

const SigninOptionsLayoutHeader = (props: HeaderProps) => {
  const { headingText, bodyText } = props;
  return (
    <>
      <StyledHeading variant="h5">{headingText}</StyledHeading>
      {bodyText && (
        <StyledBody variant="body2" color="gray9">
          {bodyText}
        </StyledBody>
      )}
    </>
  );
};

const SigninOptionsLayout = (props: SigninOptionsLayoutProps) => {
  const [samlButtonLoading, setSAMLButtonLoading] = useState(false);
  let samlURL = '';
  if (
    props.templateType === SigninOptionsLayoutType.SAML ||
    props.templateType === SigninOptionsLayoutType.SAML_WITH_NO_PREFERRED_SSO
  ) {
    samlURL = props.samlProps.samlURL;
  }
  const isFromMSTeams = isSourceMSTeams();
  const { push } = useHistory();
  const onSAMLClick = useCallback(() => {
    if (samlURL) {
      setSAMLButtonLoading(true);

      if (isFromMSTeams) {
        const encodedSAMLUrl = encodeURIComponent(samlURL);
        const modalURL = `${RoutesList.SAML_AUTHORIZE_MODAL}?source=${MS_TEAMS}&samlURL=${encodedSAMLUrl}`;

        microsoftTeams.initialize();
        /* To notify app loaded to hide loading indicator */
        microsoftTeams.appInitialization.notifyAppLoaded();
        microsoftTeams.appInitialization.notifySuccess();

        microsoftTeams.authentication.authenticate({
          url: modalURL,
          width: 600,
          height: 535,
          successCallback(result) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { code } = result;
            push(`${AUTH_SAML}?token=${code}&from=tab`);
          },
          failureCallback(reason) {
            push(`${AUTH_SAML}/error=${reason}`);
          },
        });
      } else {
        window.location.href = samlURL;
      }
    }
  }, [isFromMSTeams, push, samlURL]);
  switch (props.templateType) {
    case SigninOptionsLayoutType.SAML: {
      const { samlProps, children, subTextProps, className } = props;
      const { headingText, bodyText } = samlProps;
      const headerProps = { headingText, bodyText };
      return (
        <StyledRoot className={className}>
          <SigninOptionsLayoutHeader {...headerProps} />
          <Button
            onClick={onSAMLClick}
            isFullWidth
            color="primary"
            icon={samlProps.samlIcon}
            isLoading={samlButtonLoading}
          >
            {samlProps.samlButtonText}
          </Button>
          {children && <StyledDivider />}
          {children && children}
          <StyledSigninSubText {...subTextProps} />
        </StyledRoot>
      );
    }
    case SigninOptionsLayoutType.NO_PREFERRED_SSO: {
      const {
        headingText,
        bodyText,
        subTextProps,
        ssoComponentProps,
        children,
        className,
      } = props;
      const headerProps = { headingText, bodyText };
      return (
        <StyledRoot className={className}>
          <SigninOptionsLayoutHeader {...headerProps} />
          <SSOComponent {...ssoComponentProps} ssoType={SSOTypes.SIGN_IN} />
          <StyledDivider />
          {children}
          <StyledSigninSubText {...subTextProps} />
        </StyledRoot>
      );
    }
    case SigninOptionsLayoutType.SAML_WITH_NO_PREFERRED_SSO: {
      const { samlProps, subTextProps, ssoComponentProps, className } = props;
      const { headingText, bodyText } = samlProps;
      const headerProps = { headingText, bodyText };
      return (
        <StyledRoot className={className}>
          <SigninOptionsLayoutHeader {...headerProps} />
          <Button
            onClick={onSAMLClick}
            isFullWidth
            color="primary"
            icon={samlProps.samlIcon}
            isLoading={samlButtonLoading}
          >
            {samlProps.samlButtonText}
          </Button>
          <StyledDivider />
          <SSOComponent {...ssoComponentProps} ssoType={SSOTypes.SIGN_IN} />
          <StyledSigninSubText {...subTextProps} />
        </StyledRoot>
      );
    }
    default: {
      const {
        headingText,
        bodyText,
        subTextProps,
        ssoComponentProps,
        className,
      } = props;
      const headerProps = { headingText, bodyText };
      return (
        <StyledRoot className={className}>
          <SigninOptionsLayoutHeader {...headerProps} />
          <SSOComponent
            {...ssoComponentProps}
            showAsPrimary
            ssoType={SSOTypes.SIGN_IN}
          />
          <StyledSigninSubText {...subTextProps} />
        </StyledRoot>
      );
    }
  }
};

const SigninOptions = (props: SigninOptionsProps) => {
  switch (props.signinOptionsType) {
    case SigninOptionsType.SAML_ONLY: {
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.SAML}
          {...props}
        />
      );
    }
    case SigninOptionsType.SAML_WITH_SSO: {
      const { ssoComponentProps, ...rest } = props;
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.SAML}
          {...rest}
        >
          <SSOComponent {...ssoComponentProps} ssoType={SSOTypes.SIGN_IN} />
        </SigninOptionsLayout>
      );
    }
    case SigninOptionsType.SAML_WITH_PASSWORD: {
      const { passwordProps, ...rest } = props;
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.SAML}
          {...rest}
        >
          <PasswordComponent {...passwordProps} />
        </SigninOptionsLayout>
      );
    }
    case SigninOptionsType.NO_PREFERRED_SSO: {
      const { passwordProps, ...rest } = props;
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.NO_PREFERRED_SSO}
          {...rest}
        >
          <PasswordComponent {...passwordProps} />
        </SigninOptionsLayout>
      );
    }
    case SigninOptionsType.CREATE_PASSWORD: {
      const { createPasswordProps, ...rest } = props;
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.NO_PREFERRED_SSO}
          {...rest}
        >
          <Button
            color="secondary"
            size="medium"
            status="default"
            isFullWidth
            {...createPasswordProps}
          >
            {CREATE_PASSWORD}
          </Button>
        </SigninOptionsLayout>
      );
    }
    case SigninOptionsType.SAML_WITH_NO_PREFERRED_SSO: {
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.SAML_WITH_NO_PREFERRED_SSO}
          {...props}
        />
      );
    }
    case SigninOptionsType.CREATE_PASSWORD_WITH_NO_PREFERRED_SSO: {
      const { createPasswordProps, ...rest } = props;
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.NO_PREFERRED_SSO}
          {...rest}
        >
          <CreateYourPassword {...createPasswordProps} />
        </SigninOptionsLayout>
      );
    }
    case SigninOptionsType.SAML_WITH_CREATE_PASSWORD: {
      const { createPasswordProps, ...rest } = props;
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.SAML}
          {...rest}
        >
          <CreateYourPassword {...createPasswordProps} />
        </SigninOptionsLayout>
      );
    }
    default: {
      return (
        <SigninOptionsLayout
          templateType={SigninOptionsLayoutType.SSO_ENFORCED}
          {...props}
        />
      );
    }
  }
};

export default SigninOptions;
