import { LeftDrawerCollapsibleNavItemProps } from '../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { PageType } from '../../interfaces/Feed';
import { MemberRole } from '../../interfaces/member';
import { TaskCategories, MiscTaskCategories } from '../../interfaces/notebook';
import {
  ACTION_EVENTS,
  FEED_ANALYTICS_EVENTS,
  FLOW_BUILDER_EVENTS,
  LEFT_DRAWER_ANALYTICS_EVENTS as LEFT_DRAWER_EVENTS,
  MAIN_FEED_ACTION_BAR_EVENTS,
  NOTEBOOK_EVENTS,
  NOTEBOOK_TASK_EVENTS,
  PARTICIPATION_ANALYTICS_EVENTS as PARTICIPATION_EVENTS,
  POST_ANALYTICS_EVENTS,
  RIGHT_DRAWER_EVENTS,
} from './constants';

export enum RegistrationMethods {
  Google = 'Google',
  ADP = 'ADP',
  Slack = 'Slack',
  Office365 = 'Office365',
  Email = 'Email',
}

export type EventPropsType = {
  [key: string]:
    | null
    | Date
    | string
    | number
    | boolean
    | undefined
    | Array<string>
    | Record<string, unknown>;
};

export type BaseEventProps = {
  show?: string;
  error?: string;
  action?: string;
  event?: typeof ACTION_EVENTS[keyof typeof ACTION_EVENTS];
};

export interface UserProps {
  $name: string;
  $email: string;
  $distinct_id: string;
  'Registration Date': string;
  'Registration Method': RegistrationMethods;
  'Company Plan Type': string;
  'Allowance Points Available': number;
  isAdmin: boolean;
  isManager: boolean;
  Department: string;
  'Allowance Type': string;
  'User Status': string;
  'Total Allowance': number;
  'Total Points Given': number;
  canSendPrivate: boolean;
  Platform: string;
  Birthday: string;
  Anniversary: string;
  isPaid: boolean;
  planType: string;
}

export interface SuperProps {
  Name: string;
  Email: string;
  'Company Plan Type': string;
  'Allowance Points Available': number;
  canSendPrivate: boolean;
  Platform: string;
  isAdmin: boolean;
  isManager: boolean;
  Department: string;
  'Allowance Type': string;
  'User Status': string;
  'Total Allowance': number;
  'Total Points Given': number;
  active: number;
  completed: number;
  archived: number;
}

export type LeftDrawerEventProps = BaseEventProps & {
  notificationTypeClicked?: undefined | string;
  flowId?: string;
  flowName?: string;
  flowPerms?: FlowPermissions[];
  flowIsDue?: boolean;
  flowOccurrenceRunning?: boolean;
  isArchived?: boolean;
  postId?: string;
  responseId?: string;
};

export type PostAnalyticsEventProps = BaseEventProps & {
  flowId: string;
  flowName?: string;
  isArchived?: boolean;
  feedViewed: PageType;
  postId: string | null;
  numPostComments: number;
  numPostReactions: number;
  responseId: string | null;
  flowPerms?: 'owner' | 'participant' | 'viewer';
  commentMediaAttached?: 'mention' | 'gif' | 'emoji';
};

export type FlowBuilderEventProps = BaseEventProps & {
  dueDate?: number;
  shortcut?: boolean;
  flowTitle?: string;
  numBlocks?: number;
  flowEmoji?: string;
  lastEditDate?: Date;
  templateId?: string;
  templateName?: string;
  flowOwnerId?: string;
  flowEdited?: boolean;
  actionSelected?: string;
  numOccurrences?: number;
  flowOwnerEmail?: string;
  flowDescription?: string;
  lastOccurrenceDate?: Date;
  triggerRecurrence?: string;
  firstOccurrenceDate?: Date;
  numBlocksRequired?: number;
  contentBlockType?: string[];
  triggerTypeSelected?: string;
  flowSectionsEdited?: string[];
  flowOwnerPermissions?: string[];
  viewerCriteriaSelected?: string[];
  triggerSettingsConfigured?: string;
  participantCriteriaSelected?: string[];
  scheduleStartDateSelected?: string;
  scheduleStartTimeSelected?: string;
  scheduleEndDateSelected?: string;
  scheduleEndTimeSelected?: string;
  recurrenceSelected?: string;
  dueDateSelected?: string;
};

export type OnboardingEventProps = BaseEventProps & {
  path?: string;
  templateId?: string;
  calendarType?: string;
  meetingTitle?: string;
  meetingRecurs?: boolean;
  meetingParticipants?: string[];
};

export type FlowInsightsEventProps = BaseEventProps & {
  tab?: string;
  blockType?: string;
  specificFilter?: string[];
  filter?: Record<string, string[]>;
};

export type NotebookAnalyticsProps = BaseEventProps &
  NotebookSectionalAnalyticsProps &
  NotebookTaskAnalyticsProps;

export type DrawerTabType = 'filter' | 'about' | 'people' | 'leaderboards';

export type RightDrawerEventProps = BaseEventProps & {
  feedViewed: PageType;
  drawerTabViewed: DrawerTabType;
  filterApplied?: Array<string> | string;
  flowId?: string;
  flowPerms?: Array<string> | string;
  flowName?: string;
};
export type FlowPermissions = 'owner' | 'participant' | 'viewer';

export type FeedAnalyticsEventProps = BaseEventProps & {
  feedSort?: string;
  flowPerms?: FlowPermissions[] | null;
  flowName?: string;
};
export type ParticipationAnalyticsEventProps = {
  flowId: string;
  flowName?: string;
  templateId?: string;
  event: Array<string> | string;
  flowOwnerId: string;
  flowOwnerEmail: string;
  flowOwnerPermissions: Array<string>;
  numBlocks: number;
  numBlocksRequired: number;
  blockTypes: Array<string> | null;
  numOccurrences: number | null;
  shortcut: boolean | null;
  trigger: string;
  numVersions: number;
  firstOccurrenceDate: string;
  lastOccurrenceDate: string;
  publishedDate: string;
  flowPerms: FlowPermissions[] | null;
};

export type LeftDrawerEventNameType =
  typeof LEFT_DRAWER_EVENTS[keyof typeof LEFT_DRAWER_EVENTS];
export type PostEventNameType =
  typeof POST_ANALYTICS_EVENTS[keyof typeof POST_ANALYTICS_EVENTS];
export type FlowBuilderEventNameType =
  typeof FLOW_BUILDER_EVENTS[keyof typeof FLOW_BUILDER_EVENTS];
export type FeedEventNameType =
  typeof FEED_ANALYTICS_EVENTS[keyof typeof FEED_ANALYTICS_EVENTS];
export type ParticipationEventNameType =
  typeof PARTICIPATION_EVENTS[keyof typeof PARTICIPATION_EVENTS];
export type RightDrawerEventNameType =
  typeof RIGHT_DRAWER_EVENTS[keyof typeof RIGHT_DRAWER_EVENTS];
// eslint-disable-next-line max-len
export type MainFeedActionBarEventNameType =
  typeof MAIN_FEED_ACTION_BAR_EVENTS[keyof typeof MAIN_FEED_ACTION_BAR_EVENTS];

export type NotebookEventNameType =
  typeof NOTEBOOK_EVENTS[keyof typeof NOTEBOOK_EVENTS];
export type NotebookTaskEventNameType =
  typeof NOTEBOOK_TASK_EVENTS[keyof typeof NOTEBOOK_TASK_EVENTS];

export type NotebookSectionalAnalyticsProps = {
  section?: TaskCategories | MiscTaskCategories;
  todayTasks?: string[];
  overdueTasks?: string[];
  upcomingTasks?: string[];
  unscheduledTasks?: string[];
  completedTasks?: string[];
  archivedTasks?: string[];
  lastSection?: TaskCategories | MiscTaskCategories;
  type?: string;
  action?: string;
  show?: string;
  error?: string;
  source?: 'notebook';
  subSource?: string;
  errorIds?: string[];
};

export type NotebookTaskAnalyticsBaseProps = {
  taskId?: string;
  creatorId?: string;
  assigneeId?: string;
  dueDate?: string;
  creationDate?: string;
  source?: 'notebook';
  subSource?: TaskCategories;
  agent?: string;
  type?: string;
  title?: string;
  status?: string;
  lastTitle?: string;
  lastDueDate?: string;
  lastStatus?: string;
  lastAssigneeId?: string;
  titleLinkURL?: string[];
  tab?: string;
};

export type NotebookTaskAnalyticsProps = NotebookTaskAnalyticsBaseProps & {
  action?: NotebookTaskEventNameType;
  show?: NotebookTaskEventNameType;
  error?: NotebookTaskEventNameType;
};

export type InviteMembersEventProps = BaseEventProps & {
  inviterId?: string;
  inviteesEmails?: string[];
  inviterRole?: MemberRole[];
  source?: string;
  subSource?: string | string[];
};

export type TemplateGalleryEventsProps = BaseEventProps & {
  templateId?: string;
  templateName?: string;
  searchQuery?: string;
  filterApplied?: string;
  source?: string;
};

export type TrackLeftDrawerEventProps = LeftDrawerEventProps &
  Partial<LeftDrawerCollapsibleNavItemProps>;

export type ShareSheetEventProps = BaseEventProps & {
  shareFlowStartSource?: string;
  modalVariant?: string;
  participantCriteriaSelected?: any;
  viewerCriteriaSelected?: any;
  newLinkSettings?: any;
};
