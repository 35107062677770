export const orderSortFilterMenuItems = [
  {
    width: '132px',
    filterPlaceholder: 'Filter by',
    menuItems: [
      {
        id: 1,
        value: 'All messages',
      },
      {
        id: 2,
        value: 'Relevant',
      },
    ],
  },
  {
    width: '96px',
    filterPlaceholder: 'Order by',
    menuItems: [
      {
        id: 1,
        value: 'Newest',
      },
      {
        id: 2,
        value: 'Oldest',
      },
    ],
  },
];
