// @ts-nocheck TODO: type issues need to be fixed in this file
import moment from 'moment';
import _ from 'lodash';

import { serializeUser } from '../../Utils/user';

function deserializeDate(date) {
  return date ? moment(date) : null;
}

const checkAndReplaceDeletedUserInArray = (userArray) => {
  const userArr = _.isNil(userArray) ? [] : userArray;
  return userArr.map((u) => {
    if (!u) {
      return { isDeleted: true };
    }
    return u;
  });
};

function deserializeComment(comment) {
  let { from, taggedUsers } = comment;
  if (!from) {
    from = { isDeleted: true };
  }
  if (!from) {
    from = { isDeleted: true };
  }
  taggedUsers = checkAndReplaceDeletedUserInArray(taggedUsers);
  return {
    ...comment,
    from,
    taggedUsers,
  };
}

function deserializePost(post) {
  let { to, from, taggedUsers, likes } = post;
  if (!from) {
    from = { isDeleted: true };
  } else {
    from = serializeUser(from);
  }
  const toUsers = checkAndReplaceDeletedUserInArray(to.users).map((user) =>
    serializeUser(user),
  );
  taggedUsers = checkAndReplaceDeletedUserInArray(taggedUsers).map((user) =>
    serializeUser(user),
  );
  likes = checkAndReplaceDeletedUserInArray(likes).map((like) =>
    serializeUser(like),
  );
  return {
    ...post,
    to: { ...to, users: toUsers },
    from,
    taggedUsers,
    likes,
    createdAt: deserializeDate(post.createdAt),
    updatedAt: deserializeDate(post.updatedAt),
  };
}

function deserializeNotification(notification) {
  let { from, user } = notification;
  const serializedNotification = { ...notification };
  if (!from) {
    from = { isDeleted: true };
    serializedNotification.from = from;
  }
  if (!user) {
    user = { isDeleted: true };
    serializedNotification.user = user;
  }

  if (
    notification.type === 'post' ||
    notification.type === 'tagged_in_post' ||
    notification.type === 'comment' ||
    notification.type === 'tagged_in_comment'
  ) {
    if (notification.post) {
      serializedNotification.post = deserializePost(notification.post);
    }

    if (
      notification.type === 'comment' ||
      notification.type === 'tagged_in_comment'
    ) {
      if (notification.comment) {
        serializedNotification.comment = deserializeComment(
          notification.comment,
        );
      }
    }
  }
  return serializedNotification;
}

export {
  deserializeDate,
  deserializePost,
  deserializeComment,
  deserializeNotification,
};
