import React from 'react';
import styled from 'styled-components';

export interface SVGIconProps {
  icon: string;
  size?: string;
  color?: string;
  flip?: boolean;
  rotate?: number;
  stroke?: string;
  hoverColor?: string;
  className?: string;
}

const getTransform = ({ rotate, flip }: Partial<SVGIconProps>) =>
  `rotate(${rotate}deg)${flip ? ' scaleX(-1) scaleY(-1)' : ''}`;

const StyledIcon = styled.span<Partial<SVGIconProps>>`
  display: inline-flex;
  color: ${(props) => props.color};
  // sizes relative to set font-size
  vertical-align: top;
  min-width: ${(props) => props.size};
  height: ${(props) => props.size};
  text-align: center;
  transform: ${(props) => getTransform(props)};
  // transition: transform 0.1s ease-out;
  & > svg {
    align-self: center;
    height: ${(props) => props.size};
    min-width: ${(props) => props.size};
    display: block;
    fill: ${(props) => props.color};
    & > path:last-of-type {
      fill: ${(props) => props.color};
      stroke: ${(props) => props.stroke};
    }
    & > circle {
      fill: ${(props) => props.color};
    }
  }
  &:hover {
    & > svg {
      & > path:last-of-type {
        fill: ${(props) => props.hoverColor};
      }
    }
  }
`;

// How to use SVGIcon :
// 1. Export svg in SVG format from Figma and add it inside icons folder of this Component
// 2. Replace the path's fill with 'currentColor' value
// 3. Pass file's name as 'icon' prop to this Component to render the SVG
// 4. Its better to have viewBox="0 0 24 24" and remove height and width of the svg
const SVGIcon = ({ icon, className, ...rest }: SVGIconProps) => {
  let svg;
  try {
    // eslint-disable-next-line global-require, import/no-dynamic-require,@typescript-eslint/no-var-requires
    svg = require(`!raw-loader!./icons/${icon}.svg`).default;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Icon not found:', `${icon}.svg`, e);
    svg = '<span>x</span>';
  }
  return (
    <StyledIcon
      className={className}
      data-testid={`SVGIcon-${icon}`}
      {...rest}
      data-cy={icon}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

SVGIcon.defaultProps = {
  flip: false,
  rotate: 0,
  size: '24px',
  color: '',
  stroke: '',
  hoverColor: undefined,
  className: '',
};

export default SVGIcon;
