import React, { useCallback, useMemo, useRef, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { Modifier } from 'react-day-picker';
import moment from 'moment';
import Calendar from '../CalendarComponent';
import TextField from '../../atoms/TextField';
import isEmpty from 'lodash/isEmpty';
import { anchorOrigin, transformOrigin, Header, Footer } from './styles';
import Divider from '../../atoms/Divider';
import Button from '../../atoms/Button';
import TriggerButton from './TriggerButton';
import ThemeV2 from '../../../componentsV2/theme';
import { getCurrentDateForTimeZone } from '../../../Utils/date';

export type CalendarInputProps = {
  name: string;
  value?: Date;
  onChange: (value?: Date) => void;
  onBlur?: () => void;
  placeholder: string;
  error?: boolean;
  disabledDays?: Modifier | Modifier[];
  showDropdownIcon?: boolean;
  hideCalendarIcon?: boolean;
  className?: string;
  disabled?: boolean;
  clearTextEnabled?: boolean;
  stopEventPropagation?: boolean;
  triggerComponent?: {
    onPrimaryTrigger?: () => void;
    onSecondaryTrigger?: () => void;
    primaryTriggerComponent?: React.ReactNode;
    secondaryTriggerComponent?: React.ReactNode;
  };
  heading?: string;
  footerProps?: {
    disabled: boolean;
    footerLabel: string;
    onFooterClick: () => void;
  };
  timezone?: string;
};

const CalendarInput = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  disabledDays,
  showDropdownIcon = false,
  hideCalendarIcon = false,
  disabled = false,
  clearTextEnabled = true,
  className,
  triggerComponent,
  heading,
  footerProps,
  stopEventPropagation,
  timezone,
}: CalendarInputProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const textFieldRef = useRef<HTMLElement>(null);
  const customButtonRef = useRef<HTMLDivElement>(null);

  const openPopover = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open]);

  const stopPropagation = useCallback(
    (e: React.MouseEvent) => {
      if (stopEventPropagation) {
        e.stopPropagation();
      }
    },
    [stopEventPropagation],
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e);
      setOpen(false);
    },
    [stopPropagation],
  );

  const id = open ? 'calendar-input' : undefined;

  const inputValue = useMemo(
    () => (value ? moment(value).format('MMMM D, YYYY') : ''),
    [value],
  );

  const handleChange = useCallback(
    (date: Date, modifiers = {}) => {
      // Disable Click on Disabled dates
      if (modifiers.disabled) {
        return;
      }
      setOpen(false);
      onChange(date);
    },
    [onChange],
  );

  const handleClearClick = useCallback(() => {
    if (value) {
      onChange(undefined);
    }
  }, [onChange, value]);

  const [month, setMonth] = useState<Date>(
    value || (timezone ? getCurrentDateForTimeZone(timezone) : new Date()),
  );

  const icon = useMemo(() => {
    if (hideCalendarIcon) {
      return '';
    }
    if (showDropdownIcon) {
      return 'calendar';
    }
    return '';
  }, [hideCalendarIcon, showDropdownIcon]);

  const renderTriggerComponent = () => {
    if (triggerComponent) {
      const {
        primaryTriggerComponent,
        secondaryTriggerComponent,
        onPrimaryTrigger,
        onSecondaryTrigger,
      } = triggerComponent;
      return (
        <TriggerButton disabled={disabled}>
          <TriggerButton.PrimaryTriggerButton
            ref={customButtonRef}
            disabled={disabled}
            onPrimaryButtonClick={(e) => {
              openPopover();
              stopPropagation(e);
              if (onPrimaryTrigger) {
                onPrimaryTrigger();
              }
            }}
          >
            {primaryTriggerComponent}
          </TriggerButton.PrimaryTriggerButton>
          <TriggerButton.SecondaryTriggerButton
            onSecondaryButtonClick={(e) => {
              stopPropagation(e);
              if (onSecondaryTrigger) {
                onSecondaryTrigger();
              }
            }}
          >
            {secondaryTriggerComponent}
          </TriggerButton.SecondaryTriggerButton>
        </TriggerButton>
      );
    }
    return (
      <TextField
        name={name}
        aria-describedby={id}
        placeholder={placeholder}
        onClick={openPopover}
        onBlur={onBlur}
        value={inputValue}
        icon={icon}
        endIcon={showDropdownIcon ? 'caret-rounded' : 'calendar'}
        innerRef={textFieldRef}
        error={error}
        clearTextEnabled={!!value && clearTextEnabled}
        onClearClick={handleClearClick}
        disabled={disabled}
        className={open ? `${className} 'Mui-focused'` : `${className}`}
      />
    );
  };

  return (
    <>
      {renderTriggerComponent()}
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={
            isEmpty(triggerComponent)
              ? textFieldRef.current
              : customButtonRef.current
          }
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {heading && (
            <>
              <Header variant="body2Medium">{heading}</Header>
              <Divider color={ThemeV2.palette.gray4} isFullWidth />
            </>
          )}
          <Calendar
            activeDate={month}
            handleDayClick={handleChange}
            numberOfYearsAllowedFromCurrentYear={2}
            handleDateChange={setMonth}
            selectedDays={value ? [value] : undefined}
            disabledDays={disabledDays}
            modifiers={{
              today: timezone
                ? getCurrentDateForTimeZone(timezone)
                : new Date(),
            }}
          />
          {footerProps && (
            <>
              <Divider color={ThemeV2.palette.gray4} isFullWidth />
              <Footer>
                <Button
                  onClick={() => {
                    setOpen(false);
                    if (footerProps?.onFooterClick)
                      footerProps?.onFooterClick();
                  }}
                  color="secondary"
                  variant="text"
                  status="warning"
                  disabled={footerProps?.disabled}
                >
                  {footerProps?.footerLabel}
                </Button>
              </Footer>
            </>
          )}
        </Popover>
      )}
    </>
  );
};

export default CalendarInput;
