// @ts-nocheck TODO: type issues need to be fixed in this file
import getSymbolFromCurrency from 'currency-symbol-map';

const countriesData = require('i18n-iso-countries');
countriesData.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const GIFT_CARD_TYPES = {
  FIXED: 'FIXED_VALUE',
  VARIABLE: 'VARIABLE_VALUE',
};

export const checkGiftCardItemType = (giftCard) => {
  const firstItem = giftCard.items[0];
  return firstItem.valueType;
};

// Current available currencies
//  "USD", "EUR", "INR"

const DONATION_DENOMINATIONS_US = [5, 10, 15, 20, 25, 50, 75, 100];

const GIFT_CARD_DENOMINATIONS_US = [5, 10, 25, 50, 100];

const GIFT_CARD_DENOMINATIONS_EUR = [5, 10, 25, 50, 100];

const GIFT_CARD_DENOMINATIONS_INR = [500, 1000, 2000];

export const getDenomsForGiftcards = (currencyCode, minVal, maxVal) => {
  switch (currencyCode) {
    case 'USD':
    case 'CAD':
    case 'AUD':
      return GIFT_CARD_DENOMINATIONS_US;
    case 'EUR':
    case 'GDP':
    case 'GBP':
    case 'SGD':
    case 'SEK':
      return GIFT_CARD_DENOMINATIONS_EUR;
    case 'INR':
      return GIFT_CARD_DENOMINATIONS_INR;
    default: {
      const denoms = [];
      let valueToAdd = 0;
      const incrementBy = Math.floor(maxVal / 4);
      [1, 2, 3, 4, 5].forEach((i) => {
        valueToAdd += incrementBy;
        denoms.push(valueToAdd);
      });
      return denoms;
    }
  }
};

export const getDenomsForDonations = (currencyCode) => {
  switch (currencyCode) {
    case 'USD':
      return DONATION_DENOMINATIONS_US;
    default:
      return DONATION_DENOMINATIONS_US;
  }
};

export const getDisplayAmount = (faceValue, currencyCode) => {
  let name;
  const countrySymbol = getSymbolFromCurrency(currencyCode);
  if (countrySymbol) {
    name = `${countrySymbol}${faceValue}`;
  } else {
    name = `${faceValue} ${currencyCode}`;
  }
  return name;
};

export const getCountryName = (code) => {
  if (code === 'US') {
    return 'United States';
  }
  return countriesData.getName(code, 'en');
};

export const getCountryCode = (countryName) => {
  if (countryName === 'United States') {
    return countriesData.getAlpha2Code('United States of America', 'en');
  }
  return countriesData.getAlpha2Code(countryName, 'en');
};

export const sortByNumber = (array, fieldName) =>
  array.sort((a, b) => {
    if (a[fieldName] > b[fieldName]) {
      return 1;
    }
    if (a[fieldName] < b[fieldName]) {
      return -1;
    }
    return 0;
  });
