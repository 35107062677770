// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import { signOutAction } from './Session';
import { API_URL_WITH_V2 } from '../config';
import processErrorResponse, {
  ErrorMessages,
} from '../containers/helpers/ErrorHelper';
import { AuthHeaders } from './shared/HeaderToken';
import Logger from '../Utils/Logger';
import { deserializeNotification } from './utils/serialize';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  NOTIFICATION: `${API_URL_WITH_V2}/user/notification`,
  READ_NOTIFICATIONS: `${API_URL_WITH_V2}/user/notification/markread`,
};

const processError = (error = {}, dispatch) => {
  Logger('processError');
  Logger(error.response);
  Logger(error);
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(notificationActions.requestFailed(ErrorMessages.serverError));
      break;
    case 401: // Not authorised
      // (Log out user)
      dispatch(signOutAction());
      break;
    case 403: // Forbidden
      // redirect to home
      break;
    case 404: // Page not found
      dispatch(notificationActions.requestFailed(ErrorMessages.serverError));
      break;
    // mostly my error
    case 422:
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        dispatch(notificationActions.requestFailed(msg));
      }
      break;
    default:
      dispatch(notificationActions.requestFailed(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// Constants
// ------------------------------------
export const GET_NOTIFICATIONS_SUCCESS = 'get_notifications_success';
export const UPDATE_NOTIFICATIONS_COUNT = 'update_notifications_count';
export const REQUEST_FAILED = 'notifications_request_failed';

// ------------------------------------
// Actions
// ------------------------------------

const getNotificationsSuccess = (notifications) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  notifications,
});
const updateNotificationsCount = (count) => ({
  type: UPDATE_NOTIFICATIONS_COUNT,
  count,
});

const requestFailed = (errorMessage) => ({
  type: REQUEST_FAILED,
  error: errorMessage,
});

export const notificationActions = {
  getNotificationsSuccess,
  updateNotificationsCount,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------
export const getNotificationsAction = (props) => async (dispatch) => {
  try {
    const res = await axios.get(apiEndpoints.NOTIFICATION, AuthHeaders());
    const result = res.data;
    Logger(result, true);
    if (result.success) {
      dispatch(
        notificationActions.getNotificationsSuccess(result.data.notifications),
      );
      dispatch(
        notificationActions.updateNotificationsCount(
          result.data.notificationCount,
        ),
      );
    } else {
      // dispatch(actions.emailExists());
      // history.push('/employer-onboarding');
    }
  } catch (error) {
    processError(error, dispatch);
  }
};
export const updateNotificationsReadAction = (props) => async (dispatch) => {
  try {
    const res = await axios.get(apiEndpoints.READ_NOTIFICATIONS, AuthHeaders());
    const result = res.data;
    Logger(result, true);
    if (result.success) {
      dispatch(notificationActions.updateNotificationsCount(0));
    } else {
      // dispatch(actions.emailExists());
      // history.push('/employer-onboarding');
    }
  } catch (error) {
    processError(error, dispatch);
  }
};

export default (state = {}, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS: {
      let notifications = action.notifications || [];
      notifications = notifications.map((notification) =>
        deserializeNotification(notification),
      );
      return { ...state, notifications, error: null };
    }
    case UPDATE_NOTIFICATIONS_COUNT:
      return { ...state, count: action.count };
    case REQUEST_FAILED:
      return { ...state, error: action.errorMessage };
    default:
      return state;
  }
};
