// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import _ from 'lodash';
import { put } from 'redux-saga/effects';
import history from '../history';
import { API_URL_WITH_V2 } from '../config';
import { signOutAction } from './Session';
import processErrorResponse, {
  ErrorMessages,
} from '../containers/helpers/ErrorHelper';
import { AuthHeaders } from './shared/HeaderToken';
import Logger from '../Utils/Logger';
import { serializeUser, setCurrentUserFirstLogin } from '../Utils/user';
import { adminActions } from './Admin';
import { bootIntercom } from '../Utils/Intercom';
import {
  showSuccessMessage,
  showErrorMessage,
  showInfoMessage,
} from '../Utils/flashHandler';
import {
  checkAdmin,
  getAllowanceTypeLiteralForUser,
  getUserStatusTypeLiteralForUser,
} from '../Utils/admin';
import * as Analytics from '../Utils/analytics';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  GET_USER_INFO: `${API_URL_WITH_V2}/auth/user/info`,
  UPDATE_USER_ACCOUNT_SETTINGS: `${API_URL_WITH_V2}/user/settings/updateaccount`,
  GET_MY_REWARDS_HISTORY: `${API_URL_WITH_V2}/user/settings/history`,
};

export const IMAGE_UPLOAD_STATUS = {
  BLANK: 'BLANK',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const processError = (error = {}, dispatch) => {
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(meActions.requestFailed(ErrorMessages.serverError));
      break;
    case 403: // Forbidden
      //  redirect to home
      history.push('/404');
      break;
    case 404: // Page not found
      dispatch(meActions.requestFailed(ErrorMessages.serverError));
      break;
    //  mostly my error
    case 401: // Not authorised
      dispatch(signOutAction());
      break;
    case 422:
    /* falls through */
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        dispatch(meActions.requestFailed(msg));
      }
      break;
    default:
      dispatch(meActions.requestFailed(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// Constants
// ------------------------------------
export const GET_MY_USER_INFO = 'GET_MY_USER_INFO';
export const GOT_MY_POINTS_TO_REDEEM = 'got_my_points_to_redeem';
export const GOT_MY_POINTS_TO_GIVE = 'got_my_points_to_give';
export const GOT_MY_DETAILS = 'got_my_details';
export const GOT_MY_IP_DETAILS = 'GOT_MY_IP_DETAILS';
export const GOT_MY_REWARDS_HISTORY = 'got_my_rewards_history';
export const PROFILE_IMAGE_UPLOAD_SUCCESS = 'PROFILE_IMAGE_UPLOAD_SUCCESS';
export const PROFILE_IMAGE_UPLOAD_RESET = 'PROFILE_IMAGE_UPLOAD_RESET';
export const PROFILE_IMAGE_UPLOAD_FAILURE = 'PROFILE_IMAGE_UPLOAD_FAILURE';
export const REQUEST_FAILED = 'me_request_failed';
// ------------------------------------
// Actions
// ------------------------------------

const gotMyPointsToRedeem = (pointsEarned) => ({
  type: GOT_MY_POINTS_TO_REDEEM,
  pointsEarned,
});

const gotMyPointsToGive = (pointsToGive) => ({
  type: GOT_MY_POINTS_TO_GIVE,
  pointsToGive,
});

const gotMyDetails = (me) => ({
  me,
  type: GOT_MY_DETAILS,
});

const gotMyIPDetails = (details) => ({
  type: GOT_MY_IP_DETAILS,
  details,
});

const gotMyRewardsHistory = (myRewardsHistory) => ({
  myRewardsHistory,
  type: GOT_MY_REWARDS_HISTORY,
});

const profileImageUploadedSuccessfully = () => ({
  type: PROFILE_IMAGE_UPLOAD_SUCCESS,
});
const profileImageUploadFailed = () => ({
  type: PROFILE_IMAGE_UPLOAD_FAILURE,
});

const resetImageUpload = () => ({
  type: PROFILE_IMAGE_UPLOAD_RESET,
});

const requestFailed = (errorMessage) => ({
  type: REQUEST_FAILED,
  error: errorMessage,
});

export const meActions = {
  gotMyPointsToRedeem,
  gotMyPointsToGive,
  requestFailed,
  gotMyDetails,
  gotMyRewardsHistory,
  resetImageUpload,
  profileImageUploadedSuccessfully,
  profileImageUploadFailed,
  gotMyIPDetails,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getUserInfoAction =
  () =>
  // Logger("getUserInfoAction", true);
  async (dispatch) => {
    try {
      const me = JSON.parse(localStorage.getItem('user'));
      updateMyDetails(me, dispatch);

      const res = await axios.get(apiEndpoints.GET_USER_INFO, AuthHeaders());
      const result = res.data;
      Logger(result, true);
      Logger(AuthHeaders(), false);
      if (result.success) {
        const { user, employer, currency } = result.data;
        // user = {...user, userRoles : result.data.userRoles};
        dispatch(meActions.gotMyPointsToRedeem(user.pointsEarned));
        updateMyDetails(user, dispatch);
        dispatch(adminActions.gotMyEmployer(employer));
        dispatch(adminActions.gotCompanyDetails({ currency }));
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };

export const getUpdatedCurrencyAction =
  () =>
  // Logger("getUserInfoAction", true);
  async (dispatch) => {
    try {
      const res = await axios.get(apiEndpoints.GET_USER_INFO, AuthHeaders());
      const result = res.data;
      Logger(result, true);
      Logger(AuthHeaders(), false);
      if (result.success) {
        const { currency } = result.data;
        dispatch(adminActions.gotCompanyDetails({ currency }));
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };

export function* userInfoSaga() {
  const res = yield axios.get(apiEndpoints.GET_USER_INFO, AuthHeaders());
  const result = res.data;
  Logger(result, true);
  if (result.success) {
    const { user, employer, currency } = result.data;
    yield put(meActions.gotMyPointsToRedeem(user.pointsEarned));
    updateMyDetails(user);
    yield put(meActions.gotMyDetails(user));
    yield put(adminActions.gotMyEmployer(employer));
    yield put(adminActions.gotCompanyDetails({ currency }));
  }
}

export const updateMySettingsAction =
  (props) =>
  //  Request
  //  firstName
  //  lastname
  //  department
  //  file (profileImage)
  //  Notifications (ALL BOOLEAN)
  //       carrotNotif(Notify me when I receive new Carrots)
  //       allowanceNotif(Remind me if I forget to use my allowance)
  //       anniversaryNotif(Inform me of my Workaversary and Birthday)
  //       commentsNotif(Notify me of comments on posts I’m apart of)
  //       activityNotif(Notify me of other fun and/or interesting activity)

  async (dispatch) => {
    dispatch(showInfoMessage('Saving your changes...'));
    try {
      const res = await axios.post(
        apiEndpoints.UPDATE_USER_ACCOUNT_SETTINGS,
        props,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        dispatch(showSuccessMessage('Your changes has been saved.'));
        const { user } = result.data;
        // user = {...user, userRoles : result.data.userRoles};
        updateMyDetails(user, dispatch);
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
export const updateMyProfileImageAction =
  (props) =>
  //  Request
  //  firstName
  //  lastname
  //  department
  //  file (profileImage)
  //  Notifications (ALL BOOLEAN)
  //       carrotNotif(Notify me when I receive new Carrots)
  //       allowanceNotif(Remind me if I forget to use my allowance)
  //       anniversaryNotif(Inform me of my Workaversary and Birthday)
  //       commentsNotif(Notify me of comments on posts I’m apart of)
  //       activityNotif(Notify me of other fun and/or interesting activity)

  async (dispatch) => {
    dispatch(showInfoMessage('Uploading your profile pic...'));
    try {
      const res = await axios.post(
        apiEndpoints.UPDATE_USER_ACCOUNT_SETTINGS,
        props,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        dispatch(showSuccessMessage('Profile pic updated successfully'));
        const { user } = result.data;
        // user = {...user, userRoles : result.data.userRoles};
        updateMyDetails(user, dispatch);
        dispatch(meActions.profileImageUploadedSuccessfully());
      }
    } catch (error) {
      processError(error, dispatch);
      dispatch(meActions.profileImageUploadFailed());
      dispatch(
        showErrorMessage('Whoops, Image upload failed. Please try again.'),
      );
    }
  };
export const getMyRewardsAction =
  () =>
  //  Response
  //  My life time carrots earned and money cashed out
  // ALL Rewards claimes

  async (dispatch) => {
    try {
      const res = await axios.get(
        apiEndpoints.GET_MY_REWARDS_HISTORY,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        dispatch(
          meActions.gotMyRewardsHistory(
            result.data ? result.data.history : null,
          ),
        );
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };

const postAuthActions = (user) => {
  const firstName = _.get(user, ['profile', 'firstName'], '');
  const lastName = _.get(user, ['profile', 'lastName'], '');
  const name = `${firstName} ${lastName}`;
  const assemblyId = _.get(user, ['employerId'], '');
  bootIntercom({
    email: user.email,
    user_id: user._id,
    name,
    firstName,
    lastName,
    isAdmin: checkAdmin(user.role),
    externalId: user._id,
    userShoutoutStatus: getUserStatusTypeLiteralForUser(user),
    allowanceType: getAllowanceTypeLiteralForUser(user),
  });
  Analytics.identifyUserForAnalytics(user._id, {
    email: user.email,
    name,
    isAdmin: checkAdmin(user.role),
    assemblyId,
  });
};

export const updateMyDetails = (me, dispatch) => {
  localStorage.setItem('user', JSON.stringify(me));
  if (me && me.isFirstLogin !== undefined && me.isFirstLogin !== null) {
    setCurrentUserFirstLogin(me.isFirstLogin);
  }
  postAuthActions(me);
  if (window.raven) {
    window.Raven.setUserContext({
      email: me.email,
      id: me._id,
    });
  }
  if (dispatch) {
    dispatch(meActions.gotMyDetails(me));
  }
};

export const getMyIPDetails = () => async (dispatch) => {
  try {
    const ipStackURL = 'https://ipapi.co/json/';
    // `http://api.ipstack.com/check?access_key=${IP_STACK_ACCESS_TOKEN}`
    const res = await axios.get(ipStackURL);
    const result = res.data;
    Logger(result, true);

    if (result.country) {
      //  country_code
      dispatch(meActions.gotMyIPDetails({ countryCode: result.country }));
    }
  } catch (error) {
    processError(error, dispatch);
  }
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case GOT_MY_POINTS_TO_REDEEM:
      return { ...state, pointsEarned: action.pointsEarned };
    case GOT_MY_POINTS_TO_GIVE:
      return { ...state, pointsToGive: action.pointsToGive };
    case GOT_MY_DETAILS: {
      const me = serializeUser(action.me);
      return { ...state, me };
    }
    case GOT_MY_IP_DETAILS:
      return {
        ...state,
        ipDetails: { countryCode: action.details.countryCode },
      };
    case GOT_MY_REWARDS_HISTORY:
      return { ...state, myRewardsHistory: action.myRewardsHistory };
    case PROFILE_IMAGE_UPLOAD_SUCCESS:
      return { ...state, imageUploadStatus: IMAGE_UPLOAD_STATUS.SUCCESS };
    case PROFILE_IMAGE_UPLOAD_FAILURE:
      return { ...state, imageUploadStatus: IMAGE_UPLOAD_STATUS.FAILED };
    case PROFILE_IMAGE_UPLOAD_RESET:
      return { ...state, imageUploadStatus: IMAGE_UPLOAD_STATUS.BLANK };
    case REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
