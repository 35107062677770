// @ts-nocheck TODO: type issues need to be fixed in this file
import qs from 'qs';
import RoutesList from '../routes/routesList';

export const parseURL = (search) => {
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  return { code: parsed.code, state: parsed.state };
};

export const errorCodes = {
  NO_SLACK_TEAM_IN_ASSEMBLY: 'NO_SLACK_TEAM_IN_ASSEMBLY',
  SLACK_SIGN_IN_DISABLED: 'SLACK_SIGN_IN_DISABLED',
  SLACK_TEAM_MISMATCH: 'SLACK_TEAM_MISMATCH',
  ONLY_ADMIN_OWNER_ALLOWED_TO_ADD_TO_SLACK:
    'ONLY_ADMIN_OWNER_ALLOWED_TO_ADD_TO_SLACK',
  SLACK_ONBOARDING_INCOMPLETE: 'SLACK_ONBOARDING_INCOMPLETE',
  NON_WORK_EMAIL_NOT_ALLOWED: 'NON_WORK_EMAIL_NOT_ALLOWED',
  SLACK_USER_NOT_IN_ASSEMBLY: 'SLACK_USER_NOT_IN_ASSEMBLY',
  ADMIN_APPROVAL_REQUESTED: 'ADMIN_APPROVAL_REQUESTED',
};

export const SLACK_ERROR_MESSAGES = {
  ACCESS_DENIED:
    'Sorry, your Slack permissions prevent you from adding Assembly. Please talk to your Slack workspace admin and try again.',
};

export const SLACK_ERROR_TYPES = {
  ACCESS_DENIED: 'access_denied',
};

export const ONBOARDING_FLOW = {
  SIGN_IN_WITH_SLACK: 'SIGN_IN_WITH_SLACK',
  ADD_TO_SLACK: 'ADD_TO_SLACK',
};

export const MEMBER_GROUPS = {
  ALL: 'all-members',
  FULL: 'only-full-slack',
  SPECIFIC: 'specific-members',
};

export const SLACK_ONBOARDING_STEPS = {
  PENDING_INTEGRATION: 'PENDING_INTEGRATION',
  DEFAULT_CHANNEL_SELECTED: 'DEFAULT_CHANNEL_SELECTED',
  MEMBERS_INVITED: 'MEMBERS_INVITED',
  MEMBERS_ONBOARDED: 'MEMBERS_ONBOARDED',
  SET_CUSTOM_CURRENCY: 'SET_CUSTOM_CURRENCY',
  WELCOME_MESSAGE_SENT: 'WELCOME_MESSAGE_SENT',
};

export const getPathToSlackComplete = (onboardingStep) => {
  switch (onboardingStep) {
    case SLACK_ONBOARDING_STEPS.PENDING_INTEGRATION:
      return RoutesList.SLACK_SELECT_DEFAULT_CHANNEL;
    case SLACK_ONBOARDING_STEPS.DEFAULT_CHANNEL_SELECTED:
      return RoutesList.SLACK_SPECIFIC_MEMBERS;
    case SLACK_ONBOARDING_STEPS.MEMBERS_INVITED:
      return RoutesList.SLACK_SPECIFIC_MEMBERS;
    case SLACK_ONBOARDING_STEPS.MEMBERS_ONBOARDED:
      return RoutesList.SLACK_SELECT_DEFAULT_CURRENCY;
    case SLACK_ONBOARDING_STEPS.SET_CUSTOM_CURRENCY:
      return RoutesList.SLACK_ASSEMBLY_ANNOUNCEMENT;
    default:
      return RoutesList.SLACK_SIGN_IN;
  }
};

export const shoulShowSlackOnboardingAlert = (onboardingStep) => {
  if (
    !onboardingStep ||
    onboardingStep === SLACK_ONBOARDING_STEPS.WELCOME_MESSAGE_SENT
  ) {
    return false;
  }
  return true;
};
