import styled from 'styled-components';
import { makeStyles, Paper } from '@material-ui/core';
import Chip from '../../molecules/Chip';
import ThemeV2 from '../../../componentsV2/theme';
import { device } from '../../../constants/layout';

export const useStyles = makeStyles({
  root: ({ inputBaseRightPadding }: { inputBaseRightPadding?: string }) => ({
    [`@media ${device.mobile}`]: {
      maxWidth: '80vw',
    },
    fontFamily: ThemeV2.typography.adminFontFamily,
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingRight: inputBaseRightPadding || '0',
      '& .MuiAutocomplete-input:first-child': {
        paddingLeft: '14px',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      top: '14px',
    },
    '& .MuiAutocomplete-popupIndicator': {
      '& .MuiSvgIcon-root': {
        width: '16px',
        height: '16px',
        fill: ThemeV2.palette.gray9,
      },
    },
  }),
});

export const StyledPaper = styled(Paper)`
  ul {
    margin: 4px;
    padding: 4px 0;
    max-height: 30vh;
    & > li {
      padding: 0;
      padding-left: 0;
      .MuiListSubheader-root.MuiAutocomplete-groupLabel {
        padding: 4px 12px;
        font-size: 12px;
        font-weight: ${ThemeV2.typography.fontWeightRegular};
        line-height: 20px;
        color: ${ThemeV2.palette.gray7};
      }
      &.MuiAutocomplete-option {
        padding-left: 0;
        min-height: fit-content;
        > div {
          padding: 4px 12px;
          width: 100%;
        }
      }
      &.MuiAutocomplete-option[data-focus='true'] {
        background-color: transparent;
        & > div {
          border-radius: 4px;
          background: ${ThemeV2.palette.geekBlue1};
          color: ${ThemeV2.palette.geekBlue6};
        }
      }
    }
  }
`;

export const StyledDynamicPaper = styled(StyledPaper)`
  width: fit-content;
  ul {
    overflow-x: hidden;
  }
`;

export const StyledLoader = styled.div`
  margin-right: 28px;
`;

export const StyledChip = styled(Chip)`
  margin-top: 4px;
  margin-right: 0px;
  margin-left: 12px;
  max-width: 60%;
`;
