import { inIframe } from '../window';

const WEB_APP_SOURCE = 'WEB_APP_SOURCE';
export const MS_TEAMS = 'MS_TEAMS';

export const setAppSource = (source: string) => {
  localStorage.setItem(WEB_APP_SOURCE, source);
};

export const getAppSource = () => {
  return localStorage.getItem(WEB_APP_SOURCE);
};

export const setMSTeamsAsSource = () => {
  setAppSource(MS_TEAMS);
};

export const isSourceMSTeams = () =>
  localStorage.getItem(WEB_APP_SOURCE) === MS_TEAMS && inIframe();
