import React, {
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import ThemeV2 from '../../../componentsV2/theme';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import SVGIcon from '../../atoms/SVGIcon';
import { SelectedItemProps, TextFieldProps, TextFieldType } from './interface';

import Chip from '../Chip';
import { getFilteredItems, getItemsToAdd } from './utils';

import {
  AppendText,
  ChipMoreItem,
  FormLabel,
  HelperText,
  InputClearButton,
  InputDropdownButton,
  InputIcon,
  NoOption,
  PrefixText,
  TextFieldInput,
  TextFieldRoot,
  TextFieldRootWrapper,
  TextInputFieldWrapper,
} from './styles';

import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import {
  DropdownCategoryLabel,
  StyledDropdownListItem,
} from '../Dropdown_V2/styles';

import DropdownCard from '../../atoms/DropdownCard_V2';
import { DropdownCardWrapper } from '../../atoms/DropdownCard_V2/styles';
import { DropdownPlacement, MenuItemProps } from '../Dropdown_V2/interfaces';

const TextField = (props: TextFieldProps) => {
  const {
    name: textFieldId,
    type,
    value,
    placeholder,
    label,
    helperText,
    hasError,
    onFocus,
    onBlur,
    onChange,
    onClearClick,
    disableClearButton,
    icon,
    disabled,
    size,
    suffix,
    prefix,
    selectedItems,
    enableChip,
    dropdownItems,
    limitTags,
    isEditable,
    inputIconColor = ThemeV2.palette.gray6,
    className,
    dataTestId,
    onSelectItemChange,
    hasDropdownFilter,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterByValue, setFilterByValue] = useState('');
  const [filteredDropdownItems, setFilteredDropdownItems] =
    useState<MenuItemProps[]>();

  const [currentSelectedItems, setCurrentSelectedItems] = useState<
    SelectedItemProps[]
  >(selectedItems || []);

  const [renderChipItems, setRenderChipItems] = useState<SelectedItemProps[]>(
    [],
  );

  const hasValue = value !== '' || currentSelectedItems.length > 0;
  const suffixEnabled =
    suffix !== '' &&
    (type === TextFieldType.Text || type === TextFieldType.Email);
  const prefixEnabled = prefix !== '';
  const canDropdownOpen = !disabled && isDropdownOpen;

  useEffect(() => {
    if (
      !isFocused &&
      limitTags &&
      limitTags > -1 &&
      Array.isArray(currentSelectedItems)
    ) {
      const more = currentSelectedItems.length - limitTags;
      if (more > 0) {
        setRenderChipItems(currentSelectedItems.slice(0, limitTags));
      } else {
        setRenderChipItems(currentSelectedItems);
      }
    } else {
      setRenderChipItems(currentSelectedItems);
    }
    if (onSelectItemChange) {
      onSelectItemChange(currentSelectedItems);
    }
  }, [
    currentSelectedItems,
    isFocused,
    isDropdownOpen,
    limitTags,
    onSelectItemChange,
  ]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    setFilterByValue('');
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleDropdownOpen = useCallback(
    (event) => {
      if (hasDropdownFilter) {
        setIsDropdownOpen(true);
      } else {
        setIsDropdownOpen(!isDropdownOpen);
      }

      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl, hasDropdownFilter, isDropdownOpen],
  );

  const handleDropdownClose = useCallback(() => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  }, []);

  const handleClearClick = useCallback(() => {
    if (onClearClick) {
      onClearClick();
    }
    handleDropdownClose();
    onChange('');
    setIsFocused(true);
    setCurrentSelectedItems([]);
    setFilterByValue('');
  }, [handleDropdownClose, onChange, onClearClick]);

  const handleOnChange = useCallback(
    (e) => {
      onChange(e.target.value);
      setFilterByValue(e.target.value);
    },
    [onChange],
  );
  const hasFocus =
    (!disabled && hasValue) ||
    (!disabled && isFocused) ||
    (!disabled && hasError);

  const addChipItem = useCallback(
    (chipLabel: string, id?: string | number) => {
      // To avoid duplicate entry
      if (currentSelectedItems.every((item) => item.id !== id)) {
        const chipItem = {
          label: chipLabel,
          clickable: false,
          id,
        };
        setCurrentSelectedItems((state) => [...state, chipItem]);
      } else {
        onChange('');
      }
      setIsFocused(false);
    },
    [currentSelectedItems, onChange],
  );

  const deleteChipItem = useCallback(
    (chipLabel: string) => {
      const chipItems = currentSelectedItems.filter(
        (item) => item.label !== chipLabel,
      );

      setCurrentSelectedItems(chipItems);
    },
    [currentSelectedItems],
  );

  const deleteLastChipItem = useCallback(() => {
    const chipItems = currentSelectedItems.slice(
      0,
      currentSelectedItems.length - 1,
    );
    setCurrentSelectedItems(chipItems);
    setIsFocused(true);
  }, [currentSelectedItems]);

  // Handle Keyboard events
  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const { target, key } = e;
      if (enableChip && !dropdownItems) {
        if (key === 'Enter') {
          const chipLabel = (target as HTMLInputElement).value;
          const chipId = chipLabel.toLowerCase().replace(' ', '-');
          if (chipLabel) {
            // Handle duplicate
            addChipItem(chipLabel, chipId);
            onChange('');
          }
        }
      }
      if (key === 'Backspace' || key === 'Delete') {
        if (!value && currentSelectedItems.length > 0) {
          deleteLastChipItem();
        }
      }
    },
    [
      addChipItem,
      currentSelectedItems.length,
      deleteLastChipItem,
      dropdownItems,
      enableChip,
      onChange,
      value,
    ],
  );

  const handleMenuItemClick = useCallback(
    (id: string | number) => {
      const dropdownItem = dropdownItems?.map((menuItem) => {
        return menuItem.items.find(
          (chip: { id: string | number }) => chip.id === id,
        );
      })[0];

      const itemValue = dropdownItem?.value;
      if (itemValue) {
        // Handle duplicate
        if (enableChip) {
          addChipItem(itemValue, id);
          onChange('');
          handleDropdownClose();
          setIsFocused(true);
        } else {
          onChange(itemValue, id);
          handleDropdownClose();
        }
      }
    },
    [addChipItem, dropdownItems, enableChip, handleDropdownClose, onChange],
  );

  const onOutsideClickHandler = useCallback(() => {
    handleDropdownClose();
    setIsFocused(false);
  }, [handleDropdownClose]);

  useOnClickOutside(containerRef, onOutsideClickHandler);

  const chipsMoreCount = currentSelectedItems.length - renderChipItems.length;

  useEffect(() => {
    if (dropdownItems && isEditable) {
      const filteredItems = getFilteredItems(dropdownItems, filterByValue);
      const resultItems = getItemsToAdd(
        filteredItems,
        currentSelectedItems.map((item) => item.id),
      );

      setFilteredDropdownItems(resultItems);
    } else {
      setFilteredDropdownItems(dropdownItems);
    }
  }, [currentSelectedItems, dropdownItems, filterByValue, isEditable]);

  const renderDropdownItems = useCallback(() => {
    return (
      filteredDropdownItems &&
      filteredDropdownItems.map((dropdownItem) => (
        <div key={dropdownItem.id}>
          {dropdownItem.category && dropdownItem.items.length > 0 && (
            <DropdownCategoryLabel variant="body3">
              {dropdownItem.category}
            </DropdownCategoryLabel>
          )}
          {dropdownItem.items.map((item) => (
            <StyledDropdownListItem
              key={item.id}
              value={item.value}
              id={item.id}
              prefixIcon={item.prefixIcon}
              prefixImg={item.prefixImg}
              disabled={item.disabled}
              deleteItem={item.deleteItem}
              onItemClick={handleMenuItemClick}
            />
          ))}
          {dropdownItem.items.length === 0 && <NoOption>No options</NoOption>}
        </div>
      ))
    );
  }, [filteredDropdownItems, handleMenuItemClick]);

  const id = isDropdownOpen ? 'spring-popper' : undefined;

  const onButtonClick = (event: { currentTarget: React.ReactNode }) => {
    handleDropdownOpen(event);
  };

  return (
    <TextInputFieldWrapper
      ref={containerRef}
      className={className}
      data-testid={dataTestId}
      aria-describedby={id}
    >
      {label && (
        <FormLabel
          isFocused={hasFocus || currentSelectedItems.length > 0}
          hasError={hasError}
          isActivated={hasValue}
          htmlFor={textFieldId}
          disabled={disabled}
          icon={icon}
          inputSize={size}
        >
          {label}
        </FormLabel>
      )}
      <TextFieldRootWrapper
        onClick={onButtonClick}
        data-testid="textFieldRootWrapper"
      >
        <TextFieldRoot
          isFocused={hasFocus}
          hasError={hasError}
          disabled={disabled}
          isActivated={hasValue}
          inputSize={size}
        >
          {icon && (
            <InputIcon icon={icon} disabled={disabled} color={inputIconColor} />
          )}
          {prefixEnabled && (
            <PrefixText isFocused={hasFocus} icon={icon}>
              {prefix}
            </PrefixText>
          )}
          {renderChipItems.map((item) => (
            <Chip
              key={item.label}
              label={item.label}
              clickable={item.clickable}
              onDelete={() => deleteChipItem(item.label)}
              disabled={disabled}
            />
          ))}
          {chipsMoreCount > 0 && <ChipMoreItem>+{chipsMoreCount}</ChipMoreItem>}
          <TextFieldInput
            id={textFieldId}
            name={textFieldId}
            type={type}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={onBlur}
            hasError={hasError}
            value={value}
            onChange={handleOnChange}
            isFocused={!disabled && (hasValue || isFocused)}
            disabled={disabled}
            icon={icon}
            label={label?.toString()}
            aria-label={textFieldId}
            hasSuffix={suffixEnabled}
            hasPrefix={prefixEnabled}
            onKeyDown={onKeyPress}
            autoComplete="off"
            inputSize={size}
            readOnly={!isEditable}
            role="textbox"
          />
          {suffixEnabled && (
            <AppendText isFocused={hasFocus}>&nbsp;{suffix}</AppendText>
          )}
          {hasValue && !disabled && !disableClearButton && (
            <InputClearButton
              size={ButtonSize.Normal}
              onClick={handleClearClick}
              isFocused={(!disabled && hasValue) || (!disabled && isFocused)}
              title="search clear button"
              dropdownItems={dropdownItems}
              rounded
              dataTestId="clear button"
            >
              <SVGIcon size="24px" icon="round-close" />
            </InputClearButton>
          )}
          {dropdownItems && (
            <InputDropdownButton
              size={ButtonSize.Normal}
              onClick={handleDropdownOpen}
              isFocused={(!disabled && hasValue) || (!disabled && isFocused)}
              title="open dropdown button"
              rounded
              disabled={disabled}
            >
              <SVGIcon
                size="24px"
                icon="caret-rounded"
                flip={canDropdownOpen}
              />
            </InputDropdownButton>
          )}
        </TextFieldRoot>
      </TextFieldRootWrapper>
      {helperText && (
        <HelperText hasError={hasError} disabled={disabled}>
          {helperText}
        </HelperText>
      )}
      {dropdownItems && (
        <DropdownCardWrapper isFullWidth>
          {canDropdownOpen && (
            <DropdownCard
              id={id}
              isOpen={isDropdownOpen}
              anchorEl={anchorEl}
              dropdownPlacement={DropdownPlacement.BottomStart}
              isFullWidth
            >
              {renderDropdownItems()}
            </DropdownCard>
          )}
        </DropdownCardWrapper>
      )}
    </TextInputFieldWrapper>
  );
};

TextField.defaultProps = {
  placeholder: '',
  label: '',
  helperText: '',
  type: 'text',
  hasError: false,
  disabled: false,
  icon: false,
  suffix: '',
  prefix: '',
  size: 'md',
  enableChip: false,
  onChange: () => {},
  onFocus: () => {},
  onClearClick: () => {},
  disableClearButton: false,
  onBlur: undefined,
  dropdownItems: null,
  limitTags: -1,
  isEditable: true,
  className: '',
  dataTestId: '',
};

const MemoizedTextField = memo(TextField);
MemoizedTextField.displayName = 'TextField';

export default MemoizedTextField;
