import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';

import useFetchBotConfig from '../../../interactionLayer/useFetchBotConfig/useFetchBotConfig';
import useVerifyBotCode from '../../../interactionLayer/useVerifyBotCode/useVerifyBotCode';

import Body from '../../atoms/Body';
import LoadingPulse from '../../atoms/LoadingPulse';
import {
  BotTypeOptions,
  BotTypeManager,
  BotManager,
} from '../../../Utils/data/botManager/common';

const BotManagerAuthorize = () => {
  const { botType } = useParams<{ botType: BotTypeOptions }>();
  const { isVerifyingCode } = useVerifyBotCode(botType);
  const { models } = useFetchBotConfig(botType);

  if (models.isConnectingBot || isVerifyingCode) {
    const { displayName } = BotManager[BotTypeManager[botType]];
    return (
      <Grid container justify="center" style={{ marginTop: 90 }}>
        <Body variant="body2Bold">
          {models.isConnectingBot
            ? `Connecting to ${displayName}`
            : `Verifying ${displayName}`}
        </Body>
        <LoadingPulse isLoading />
      </Grid>
    );
  }
  return null;
};

export default BotManagerAuthorize;
