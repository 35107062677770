// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { parsePhoneNumber } from 'react-phone-number-input';
import Typography from '../../../componentsV2/core/Typography';
import TextField from '../../../componentsV2/core/TextField';
import PhoneField from '../../../atomic/molecules/PhoneField';
import Button from '../../../componentsV2/core/Button';

import { userSessionCreatedAction } from '../../../modules/Session';
import { checkIfProduction } from '../../../Utils/admin';
import { handleErrorAction } from '../../utils/ErrorHandler';
import { completeProfile } from '../../modules/onboarding';
import {
  getCompleteProfileRequestLoadingStatus,
  getCompanyName,
  getEmailRegistered,
} from '../../modules/onboarding/selectors';
import RoutesList from '../../routes/routesList';
import {
  validatePassword,
  validateFullName,
  validatePhoneNumber,
} from '../../../Utils/validation';
import { trackEvent, EventType } from '../../../Utils/analytics/Metrics';
import ProgressValidator from '../../../atomic/molecules/ProgressValidator';
import ValidationText from '../../../atomic/molecules/ValidationText';
import {
  passwordValidators,
  checkPasswordMaxLength,
  checkForNameAndMailId,
  checkPasswordStrength,
  PASSWORD_LANGUAGE,
} from '../../../Utils/validators/password';
import {
  STRENGTH_NONE,
  STRENGTH_STRONG,
} from '../../../constants/progressValidator';
import { ONBOARDING_SETUP } from '../../../constants/routes';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  marginBottom24: {
    marginBottom: '24px',
  },
  marginBottom12: {
    marginBottom: '12px',
  },
  body: {
    flex: 1,
  },
  bottomDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

class EnterProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      fullNameErrors: null,
      fullNameTouched: false,
      password: '',
      passwordErrors: null,
      passwordTouched: false,
      phoneNumber: '+1',
      phoneNumberValue: '',
      phoneNumberErrors: null,
      validationTexts: passwordValidators,
      strength: STRENGTH_NONE,
    };
  }

  trackCriteo = (userId, email) => {
    if (!userId || !email) {
      return;
    }
    window.criteo_q = window.criteo_q || [];
    const deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent,
        )
      ? 'm'
      : 'd';
    window.criteo_q.push(
      { event: 'setAccount', account: 65430 },
      { event: 'setSiteType', type: deviceType },
      { event: 'setEmail', email },
      {
        event: 'trackTransaction',
        id: userId,
        item: [{ id: '1', price: '0', quantity: 1 }],
      },
    );
  };

  onSigninClick = (e) => {
    e.preventDefault();
    let { fullName, password, phoneNumber, phoneNumberValue } = this.state;
    const { companyName, match, mailId, location } = this.props;
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const { userId } = match.params;
    fullName = fullName.trim();
    password = password.trim();

    let goThrough = true;
    const fullNameErrors = validateFullName(fullName);
    if (fullNameErrors) {
      this.setState({ fullNameErrors });
      goThrough = false;
    }

    const PHONE_NUMBER_INVALID_ERROR = 'Phone number is invalid';
    let phoneNumberErrors = validatePhoneNumber(phoneNumber);

    if (!phoneNumber && phoneNumberValue && phoneNumberValue.length > 0) {
      phoneNumberErrors = PHONE_NUMBER_INVALID_ERROR;
    }

    if (phoneNumberErrors) {
      this.setState({ phoneNumberErrors });
      goThrough = false;
    }

    const passwordErrors = validatePassword(password);
    if (passwordErrors) {
      this.setState({ passwordErrors });
      goThrough = false;
    }

    const isPasswordReachedMaxLength = checkPasswordMaxLength(password);
    if (isPasswordReachedMaxLength) {
      this.setState({ passwordErrors: PASSWORD_LANGUAGE.charactersLimit });
      goThrough = false;
    }

    const hasNameAndMailId = checkForNameAndMailId(password, {
      mailId,
      fullName,
    });
    if (hasNameAndMailId.length > 0) {
      goThrough = false;
      this.setState({
        passwordErrors: PASSWORD_LANGUAGE.emailUsernameSimilar,
      });
    }

    if (goThrough) {
      const phone = this.mapPhoneNumberToPhone(phoneNumber);
      this.props
        .completeProfile({
          companyName,
          userId,
          fullName,
          password,
          phone,
          code,
        })
        .then((successAction) => {
          const data = _.get(successAction, ['data', 'data']);
          const { user, token, employer } = data;
          trackEvent(EventType.PROFILE_COMPLETE, {
            userId: user._id,
            employerId: employer._id,
          });
          this.trackCriteo(user._id, user.email);
          this.props.userSessionCreatedAction(user, token);
          const { history } = this.props;
          history.push(ONBOARDING_SETUP);
        })
        .catch((errorOrAbortAction) => {
          handleErrorAction(errorOrAbortAction);
        });
    }
  };

  mapPhoneNumberToPhone = (phoneNumber) => {
    if (!phoneNumber || !parsePhoneNumber(phoneNumber)) {
      return null;
    }

    const phone = parsePhoneNumber(phoneNumber.toString());
    return {
      countryCode: `+${phone.countryCallingCode}`,
      number: +phone.nationalNumber,
    };
  };

  handleNameChange = (event) => {
    this.setState({
      fullName: event.target.value,
      fullNameErrors: null,
      fullNameTouched: true,
    });
  };

  handlePhoneNumberInput = (event) => {
    this.setState({ phoneNumberValue: event, phoneNumberErrors: null });
  };

  handlePhoneNumberChange = (event) => {
    this.setState({
      phoneNumber: event,
      phoneNumberErrors: null,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    const { newValidationTexts, newStrength } = checkPasswordStrength(password);

    this.setState({
      password: event.target.value,
      passwordErrors: null,
      passwordTouched: true,

      validationTexts: newValidationTexts,
      strength: newStrength,
    });
  };

  render() {
    const { classes, loading, companyName, match } = this.props;
    const {
      password,
      passwordErrors,
      passwordTouched,
      fullName,
      phoneNumber,
      fullNameTouched,
      fullNameErrors,
      phoneNumberErrors,
      validationTexts,
      strength,
    } = this.state;

    const isPasswordStrengthStrong = strength !== STRENGTH_STRONG;
    const isButtonDisabled =
      !passwordTouched ||
      !!passwordErrors ||
      !!phoneNumberErrors ||
      !fullNameTouched ||
      !!fullNameErrors;

    const { userId } = match.params;
    if (!userId) {
      return <Redirect to={`${RoutesList.ENTER_EMAIL}`} />;
    }
    if (!companyName) {
      return <Redirect to={`${RoutesList.ENTER_COMPANY_NAME}/${userId}`} />;
    }

    return (
      <div className={classes.wrapper}>
        <Helmet>
          {checkIfProduction() && (
            <script
              script
              type="text/javascript"
              src="//static.criteo.net/js/ld/ld.js"
              async="true"
            />
          )}
        </Helmet>
        <div className={classes.body}>
          <form onSubmit={this.onSigninClick}>
            <Typography variant="h5" weight="bold" gutterBottom>
              Set your name and password
            </Typography>
            <TextField
              id="full-name"
              name="name"
              label="Your full name"
              placeholder="Ex. Martha Wayne"
              value={fullName}
              onChange={this.handleNameChange}
              rootStyle={{ width: '100%' }}
              error={fullNameErrors}
              helperText="Your name will appear on all posts and messages you create"
              className={classes.marginBottom24}
              autoComplete="off"
            />
            <PhoneField
              id="phone-number"
              name="phone-number"
              label="Your phone number (optional)"
              placeholder="Ex. +1 123 456 7890"
              value={phoneNumber}
              error={phoneNumberErrors}
              onInput={this.handlePhoneNumberInput}
              onChange={this.handlePhoneNumberChange}
              autoComplete="off"
              helperText="Your number will only be used to contact you about your account"
              className={classes.marginBottom24}
              rootStyle={{ width: '100%' }}
            />
            <TextField
              id="new-password"
              type="password"
              label="Your password"
              name="pass"
              placeholder="Create a strong password"
              value={password}
              onChange={this.handlePasswordChange}
              rootStyle={{ width: '100%' }}
              error={passwordErrors}
              className={classes.marginBottom12}
              autoComplete="new-password"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
            {strength && (
              <ProgressValidator
                strength={strength}
                className={classes.progressValidator}
              />
            )}
            {validationTexts && (
              <div
                className={`${classes.validationTexts} ${classes.marginBottom24}`}
              >
                {validationTexts.map((item) => (
                  <ValidationText key={item.text} checked={item.checked}>
                    {item.text}
                  </ValidationText>
                ))}
              </div>
            )}
            <div className={classes.bottomDiv}>
              <Typography variant="subtitle2" weight="semiBold">
                Step 1 of 2
              </Typography>
              <Button
                style={{ width: 93 }}
                disabled={
                  isButtonDisabled || loading || isPasswordStrengthStrong
                }
                type="submit"
                variant="contained"
                color="secondary"
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

EnterProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getCompleteProfileRequestLoadingStatus(state),
  companyName: getCompanyName(state),
  mailId: getEmailRegistered(state),
});

export default withRouter(
  connect(mapStateToProps, { completeProfile, userSessionCreatedAction })(
    withStyles(styles)(EnterProfile),
  ),
);
