import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';

export const AdminAnalyticsContainer = styled.div`
  background: ${ThemeV2.palette.gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-right: -15px;
  margin-left: -15px;
  padding-bottom: 108px;
`;

export const Breadcrumbs = styled.div`
  padding-top: 16px;
  padding-left: 24px;
  margin-bottom: 48px;
`;

export const InfoTypeContainer = styled.div`
  margin: 0 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: ${ThemeV2.palette.gray1};
  padding: 32px;
  margin-bottom: 24px;
  border-radius: 4px;
`;

export const InfoTypeHeader = styled(Heading)`
  margin: 0;
  margin-bottom: 16px;
`;

export const GraphContainer = styled.div`
  padding: 0 16px;
`;

export const GraphTitleContainer = styled.div`
  margin-bottom: 24px;
`;

export const BookCallButton = styled(Button)`
  width: fit-content;
  margin-top: 8px;
`;

export const BookCallModal = styled.div`
  min-width: 800px;
  min-height: 700px;
  & .meetings-iframe-container {
    padding: 0px 8px;
  }
`;

export const GraphDropdownContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
`;

export const MultiDropdownContainer = styled.div`
  & > div {
    margin-right: 12px;
  }
`;

export const BenchmarkContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
`;

export const BenchmarkContentHeading = styled(Heading)`
  margin-bottom: 0;
`;

export const BenchmarkContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const IconWrapper = styled.div<{ error?: boolean }>`
  margin-top: 6px;
  ${({ error }) =>
    error &&
    `
  background-color: ${ThemeV2.palette.gray3};
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  & path {
    color: ${ThemeV2.palette.gray6};
  }
  `};
`;

export const KeyTypeContainer = styled.div`
  margin-top: 24px;
  display: flex;
`;

export const TypeContainer = styled.div`
  width: 33.3%;
  display: flex;
  padding-right: 48px;
  & span {
    margin-right: 16px;
  }
`;
