import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { parse } from 'qs';

import useRequest from '../../hooks/useRequest/useRequest';
import RoutesList from '../../aV2/routes/routesList';
import { GET_BOT_OAUTH_CONFIG } from '../../constants/endpoints';
import { APP_URL } from '../../config';

const useFetchBotConfig = (sso: string) => {
  const REDIRECT_URI = `${APP_URL}${RoutesList.BOT_AUTHORIZE_FN(sso)}`;

  const [isConnectingBot, setIsConnectingBot] = useState(false);
  const [isAutoLogin, setIsAutoLogin] = useState(false);

  const { search } = useLocation();

  const redirectToIM = (authorizeUri: string) => {
    window.location.href = authorizeUri;
  };

  const onGetSSOConfigSuccess = useCallback(
    (data) => {
      if (isAutoLogin) {
        setIsConnectingBot(true);
        const authorizeUri = _.get(data, ['data', 'authorizeUri'], '');
        redirectToIM(authorizeUri);
      }
    },
    [isAutoLogin],
  );

  const handleError = useCallback(() => {
    // dispatch(showErrorMessage(message));
    //  TODO: Handle errors
  }, []);

  const { fireRequest } = useRequest(GET_BOT_OAUTH_CONFIG, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    onSuccess: onGetSSOConfigSuccess,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    onError: handleError,
  });
  useEffect(() => {
    fireRequest({
      params: { redirectUri: REDIRECT_URI },
      substitutions: { sso },
    });
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const { auto, code } = parsedParams;
    if (auto === 'true' && !code) {
      setIsConnectingBot(true);
      setIsAutoLogin(true);
    }
  }, [search, sso, REDIRECT_URI, fireRequest]);

  return {
    models: {
      isConnectingBot,
    },
  };
};

export default useFetchBotConfig;
