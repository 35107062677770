import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';

export const ToolTipText = styled.span<{ toolTipSize?: string }>`
  width: ${({ toolTipSize }) => (toolTipSize ? '196px' : 'max-content')};
  background-color: ${ThemeV2.palette.gray9};
  padding: 4px 8px;
  border-radius: 4px;
  margin: 4px;
  z-index: 99;
`;

export const ToolTipRoot = styled.div`
  position: relative;
  display: inline-flex;
`;
