// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { oneOf, string } from 'prop-types';
import ProgressBar from '../../atoms/ProgressBar';
import {
  STRENGTH_WEAK,
  STRENGTH_NORMAL,
  STRENGTH_STRONG,
  STRENGTH_NONE,
} from '../../../constants/progressValidator';

const useStyles = makeStyles({
  root: {
    padding: 4,
    display: 'flex',
    flexDirection: 'row',
    marginRight: '-4px',
    justifyContent: 'space-between',
  },
});

const ProgressValidator = ({
  strength,
  className: parentClassName,
  ...rest
}) => {
  const classes = useStyles();
  let validation;
  if (strength === STRENGTH_WEAK) {
    validation = [
      {
        color: 'danger',
        value: 100,
      },
      {
        color: 'none',
        value: 0,
      },
      {
        color: 'none',
        value: 0,
      },
    ];
  } else if (strength === STRENGTH_NORMAL) {
    validation = [
      {
        color: 'warning',
        value: 100,
      },
      {
        color: 'warning',
        value: 100,
      },
      {
        color: 'none',
        value: 0,
      },
    ];
  } else if (strength === STRENGTH_STRONG) {
    validation = [
      {
        color: 'success',
        value: 100,
      },
      {
        color: 'success',
        value: 100,
      },
      {
        color: 'success',
        value: 100,
      },
    ];
  } else if (strength === STRENGTH_NONE) {
    validation = [
      {
        color: 'none',
        value: 0,
      },
      {
        color: 'none',
        value: 0,
      },
      {
        color: 'none',
        value: 0,
      },
    ];
  }
  const className = `${classes.root} ${parentClassName}`;
  return (
    <div className={className} data-testid="progressValidator" {...rest}>
      {validation.map((strengthItem, index) => (
        <ProgressBar
          color={strengthItem.color}
          value={strengthItem.value}
          // FIXME: Come up with better approach for passing key
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        />
      ))}
    </div>
  );
};

ProgressValidator.propTypes = {
  strength: oneOf([
    STRENGTH_NONE,
    STRENGTH_NORMAL,
    STRENGTH_STRONG,
    STRENGTH_WEAK,
  ]),
  className: string,
};

ProgressValidator.defaultProps = {
  strength: STRENGTH_NONE,
  className: '',
};

export default ProgressValidator;
