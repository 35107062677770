// @ts-nocheck TODO: type issues need to be fixed in this file
import { createSelector } from 'reselect';
import _ from 'lodash';
import { SWITCH_TIER, ADD_PAYMENT, GET_CURRENT_PLAN } from '.';
import { PLAN_IDS } from '../../../../config';
import {
  getFeatureDeatilsForPlan,
  FEATURE_LIST,
  FEATURE_STATUS,
} from '../../../../Utils/admin/account';

export const serializePlan = (plan) => {
  const currency = plan.features.find(
    (feature) => feature.category === 'CURRENCY',
  );
  const uniqueId = plan.unique_id;
  return { ...plan, currency, uniqueId };
};
export const getActivePlanRequestLoadingStatus = (state) =>
  _.get(
    state,
    ['v2AdminAccount', 'mutations', GET_CURRENT_PLAN, 'pending'],
    0,
  ) > 0;

const availablePlansSelector = (state) =>
  _.get(state, ['v2AdminAccount', 'data', 'availablePlans'], []);

export const getCurentPlanSelector = (state) =>
  _.get(state, ['v2AdminAccount', 'data', 'activePlan'], null);

export const getCurentPlan = createSelector(getCurentPlanSelector, (plan) => {
  return plan ? serializePlan(plan) : plan;
});

export const getAccountDetails = (state) =>
  _.get(state, ['v2AdminAccount', 'data', 'accountDetails'], null);

export const hasActiveCreditCard = createSelector(
  getAccountDetails,
  (accountDetails) => {
    const creditCards = _.get(accountDetails, ['creditCards'], []);
    const creditCard = creditCards.find((card) => card.isActive);
    if (creditCard) {
      return true;
    }
    return false;
  },
);

export const checkPaidAllowanceAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.PAID_ALLOWANCE,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkInviteAndApprovalAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ALLOW_TO_CHANGE_USER_INVITE_AND_REQUEST_PERMISSION,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkChangeCustomLogoAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ALLOW_TO_CHANGE_ASSEMBLY_LOGO,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkChangeCustomCurrencyAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ALLOW_TO_CHANGE_ASSEMBLY_CURRENCY,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkCustomAllowanceAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.CUSTOM_ALLOWANCE,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkBirthdayBotAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.BIRTHDAY_BOT,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkAnniversaryBotAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ANNIVERSARY_BOT,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkSwitchPlanAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const switchPlanDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.SWITCH_PLAN,
    );
    if (switchPlanDetails) {
      return switchPlanDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkCustomCurrencyAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.CUSTOM_CURRENCY,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkPrivateMessageAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.PRIVATE_MESSAGE,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkIMUserGroupAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ACCOUNT_MANAGEMENT_SELECTION,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkFeedSearchAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.FEED_SEARCH,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkAdminAnalyticsAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ADMIN_ANALYTICS,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return true;
  },
);

export const checkADPIdentityManagerAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ALLOW_ADP_IDENTITY_MANAGER,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const checkMergeHRISAccess = createSelector(
  getCurentPlan,
  (currentPlan) => {
    const featureDetails = getFeatureDeatilsForPlan(
      currentPlan,
      FEATURE_LIST.ALLOW_MERGE_IDENTITY_MANAGER,
    );
    if (featureDetails) {
      return featureDetails.status === FEATURE_STATUS.ENABLED;
    }
    return false;
  },
);

export const getAllPlans = createSelector(availablePlansSelector, (plans) => {
  if (plans.length === 0) {
    return null;
  }
  const currentPlans = Object.keys(PLAN_IDS);
  const plansDict = {};
  currentPlans.forEach((planId) => {
    plansDict[planId] = serializePlan(
      plans.find((plan) => plan.unique_id === PLAN_IDS[planId]),
    );
  });
  return plansDict;
});

export const getSwitchAccountRequestLoadingStatus = (state) =>
  state.v2AdminAccount.mutations[SWITCH_TIER].pending > 0;

export const getAddPaymentRequestLoadingStatus = (state) =>
  state.v2AdminAccount.mutations[ADD_PAYMENT].pending > 0;
