import React from 'react';
import { MenuItemProps } from '../Dropdown_V2/interfaces';

export interface SelectedItemProps {
  id: any;
  label: string;
  avatar?: string;
  clickable?: boolean;
}

export enum TextFieldType {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Number = 'number',
  Date = 'date',
}
export enum TextFieldSizes {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
export interface TextFieldProps {
  id?: string;
  name?: string;
  type?: TextFieldType;
  value: string;
  placeholder?: string;
  label?: string | React.ReactNode;
  helperText?: string;
  hasError?: boolean;
  disabled?: boolean;
  icon?: string;
  size?: TextFieldSizes;
  onChange: (value: string, id?: string | number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClearClick?: () => void;
  disableClearButton?: boolean;
  suffix?: string;
  prefix?: string;
  selectedItems?: SelectedItemProps[];
  enableChip?: boolean;
  dropdownItems?: MenuItemProps[];
  limitTags?: number;
  isEditable?: boolean;
  inputIconColor?: string;
  className?: string;
  dataTestId?: string;
  onSelectItemChange?: (value: SelectedItemProps[]) => void;
  hasDropdownFilter?: boolean;
}

export interface StyledComponentProps {
  isFocused?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  isActivated?: boolean;
  label?: string;
  icon?: string;
  hasSuffix?: boolean;
  hasPrefix?: boolean;
  ref?: any;
  inputSize?: TextFieldSizes;
  dropdownItems?: MenuItemProps[];
  readonly?: boolean;
  inputIconColor?: boolean;
}
