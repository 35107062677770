import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import ThemeV2 from '../../../componentsV2/theme';

interface StyleProps {
  root: BaseCSSProperties;
}

type PropsClasses = Record<keyof StyleProps, string>;
interface ClickableProps {
  classes?: PropsClasses;
  className?: string;
}

const useStyles = makeStyles({
  root: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0px',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '14px',
    lineHeight: '22px',
  },
});

const Clickable = (
  props: React.HTMLProps<HTMLButtonElement> & ClickableProps,
): React.ReactElement => {
  const { children, className: parentClassName, ...rest } = props;
  const classes = useStyles(props);
  const styles = `${classes.root} ${parentClassName}`;
  return (
    <button {...rest} type="button" className={styles}>
      {children}
    </button>
  );
};

export default Clickable;
