import { useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import useRequest from '../../hooks/useRequest/useRequest';
import { GET_PREFERRED_SSO } from '../../constants/endpoints';

const preferredSSOSelector = (state: any) =>
  get(state, ['API', GET_PREFERRED_SSO, 'response', 'data', 'sso'], null);

const useIntegrationLayer = () => {
  const { fireRequest } = useRequest(GET_PREFERRED_SSO);
  const preferredSSO = useSelector(preferredSSOSelector);
  const actions = useMemo(() => {
    return {
      fireGetPreferredSSORequest: fireRequest,
    };
  }, [fireRequest]);
  const data = useMemo(() => {
    return {
      preferredSSO,
    };
  }, [preferredSSO]);
  return {
    actions,
    data,
  };
};

export default useIntegrationLayer;
