// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import reactStringReplace from 'react-string-replace';
import { Emoji } from 'emoji-mart';
import _ from 'lodash';
import titleize from 'titleize';

export const CASHOUT_TITLE_TEXT_MAX_LENGTH = 35;
export const MENTION_REGEX = /@__(.*?)__@/;
export const MENTION_REGEX_GLOBAL = /@__(.*?)__@/g;
export const URL_REGEX = /(https?:\/\/?\S+|www\.\S+)/gi;
export const DOMAIN_REGEX =
  /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

export const getProfileFullName = (user) => {
  if (user && user.profile) {
    return `${user.profile.firstName} ${user.profile.lastName}`;
  }
  return '';
};

export const isValidDomain = (value) => {
  if (value.match(DOMAIN_REGEX)) {
    return true;
  }
  return false;
};

export const capitalizeFirstLetter = (name) => {
  if (!name) {
    return '';
  }
  return titleize(name);
};

export const httpRegExp = new RegExp(`((http(s?))\\://)`, 'gm');

export const capitalizeFirstLetterInText = (text) =>
  text.replace(/./, (c) => c.toUpperCase());

export const getTimezoneAbbreviation = (d) => {
  const date = d.toString();
  let timezone = date.match(/\(.+\)/g)[0];
  timezone = timezone.replace('(', '').replace(')', '');
  let abbreviation = '';
  timezone.split(' ').forEach((word) => {
    // We insert the first letter of every word in the abbreviation variable
    abbreviation += word.split('')[0];
  });

  // And finally we return the abbreviation
  return abbreviation;
};

export const singularizeWord = (value) => {
  const word = value.split('');
  return word.slice(0, -1).join('');
};

export const getTaggedUsersFromMessage = (
  message,
  taggedUsers,
  isNotification = false,
) => {
  let replacedText = message;
  if (taggedUsers?.length > 0) {
    replacedText = reactStringReplace(replacedText, MENTION_REGEX, (match) => {
      const filteredEmployeeArr = taggedUsers
        .filter((employee) => employee._id === match)
        .map((employee) => {
          if (isNotification) {
            return employee.isDeleted
              ? 'Deactivated User'
              : getProfileFullName(employee);
          }
          return getProfileFullName(employee);
        });
      return filteredEmployeeArr;
    });
  }
  if (typeof replacedText === 'object') {
    return replacedText.join('');
  }
  return replacedText;
};

export const getPostFormattedMessage = (text, taggedUsers, onUserClicked) => {
  let replacedText = text;
  replacedText = reactStringReplace(replacedText, /#(\w+)/g, (match, i) => (
    <span key={match + i} className="feed-card-hashtag">
      #{match}
    </span>
  ));
  replacedText = reactStringReplace(
    replacedText,
    /:([^\s]*?):/g,
    (match, i) => <Emoji key={match + i} emoji={`:${match}:`} size={16} />,
  );

  if (taggedUsers.length > 0) {
    replacedText = reactStringReplace(
      replacedText,
      MENTION_REGEX,
      (match, i) => {
        const filteredEmployeeArr = taggedUsers
          .filter((employee) => employee._id === match)
          .map((employee) => {
            if (onUserClicked) {
              return (
                <span
                  key={match + i}
                  className={
                    employee.isDeleted
                      ? 'noEvent deactivated_user'
                      : 'feed-card-mention'
                  }
                  onClick={
                    !employee.isDeleted
                      ? () => {
                          onUserClicked(employee);
                        }
                      : null
                  }
                >
                  {employee.isDeleted
                    ? 'Deactivated User'
                    : getProfileFullName(employee)}
                </span>
              );
            }
            return (
              <span key={match + i}>
                <b>
                  {employee.isDeleted
                    ? 'Deactivated User'
                    : getProfileFullName(employee)}
                </b>
              </span>
            );
          });
        return filteredEmployeeArr;
      },
    );
  }
  return replacedText;
};

export const getFormattedMessage = (text, employeesArr) => {
  let replacedText = text;

  replacedText = reactStringReplace(replacedText, /#(\w+)/g, (match, i) => (
    <span key={match + i} className="feed-card-hashtag">
      #{match}
    </span>
  ));
  replacedText = reactStringReplace(replacedText, /:(.*?):/g, (match, i) => (
    <Emoji key={match + i} emoji={`:${match}:`} size={16} />
  ));

  if (employeesArr) {
    replacedText = reactStringReplace(
      replacedText,
      MENTION_REGEX,
      (match, i) => {
        const filteredEmployeeArr = employeesArr.filter((employee) => {
          return employee.id === match;
        });
        return (
          <span
            key={match + i}
            className="feed-card-mention"
            style={{ cursor: 'default' }}
          >
            {filteredEmployeeArr[0].name}
          </span>
        );
      },
    );
  }

  return replacedText;
};

export const getFirstLetterOfName = (user) => {
  if (user && user.profile && user.profile.firstName) {
    return `${user.profile.firstName.slice(0, 1).toUpperCase()}`;
  }
  return '';
};

export const getFirstLastName = (fullNameStr) => {
  const fullName = fullNameStr.trim().split(' ');
  let firstName = '';
  let lastName = '';
  for (const index in fullName) {
    if (Number(index) === 0) {
      firstName = fullName[index];
    } else {
      lastName = `${lastName} ${fullName[index]}`;
    }
  }
  lastName = lastName.trim();

  return [firstName, lastName];
};

export const getFullName = (user) => {
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
};
export const getUsersNamesCombined = (users) => {
  let names = '';
  for (let i = 0; i < users.length; i += 1) {
    names += users[i].profile.firstName;
    if (Number(users.length) === 1) {
    } else if (Number(i) === users.length - 2) {
      names += ' & ';
    } else if (Number(i) !== users.length - 1) {
      names += ', ';
    }
  }
  return names;
};
export const replaceAll = function (text, search, replacement) {
  return text.replace(new RegExp(search, 'g'), replacement);
};

export const trimText = (text, trimToLength) => {
  let newText = text;
  if (text.length > trimToLength) {
    newText = text.substring(0, trimToLength);
    newText = `${newText}...`;
  }
  return newText;
};

export const sortUsingName = (users) =>
  users.sort((a, b) => {
    let aName = '';
    let bName = '';
    if (a.profile) {
      aName = a.profile.firstName.toLowerCase();
    } else if (a.firstName) {
      aName = a.firstName;
    }
    if (b.profile) {
      bName = b.profile.firstName.toLowerCase();
    } else if (b.firstName) {
      bName = b.firstName;
    }
    return aName < bName ? -1 : aName > bName ? 1 : 0;
  });

export function truncate(n, useWordBoundary) {
  if (this.length <= n) {
    return this;
  }
  const subString = this.substr(0, n - 1);
  return `${
    useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString
  }...`;
}

export const joinArrayWithCommasAnd = (arr) => {
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return `${firsts.join(', ')} and ${last}`;
};

export const convertUsersForSearch = (arr) => {
  const keyMap = {
    name: 'displayText',
    username: 'secondaryText',
  };
  return arr.map((obj) => {
    return _.mapKeys(obj, (value, key) => {
      return keyMap[key];
    });
  });
};

export const getCoworkersOptions = (coworkers) =>
  coworkers.map((coworker) => {
    const { _id: id, profile, email } = coworker;
    const { firstName, lastName } = profile;
    return {
      id,
      label: `${firstName} ${lastName} (${email})`,
      tagLabel: `${firstName} ${lastName}`,
    };
  });

export const getIds = (array) => array.map(({ id }) => id);

export const getCoworkersFromIds = (ids, coworkers) => {
  if (ids.length === 0 || coworkers.length === 0) {
    return [];
  }
  return coworkers.reduce((acc, coworker) => {
    const { _id: id } = coworker;
    if (ids.indexOf(id) !== -1) {
      acc.push(coworker);
    }
    return acc;
  }, []);
};

export const filterExceptCurrentUser = (array, currentUserId) =>
  array.filter(({ id }) => id !== currentUserId);

export const trimSpaces = (string) => {
  return string
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<');
};
