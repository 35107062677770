import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

export default withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F5F5F5',
    },
    '&$selected': {
      backgroundColor: 'rgb(235,137,33, .20)',
    },
    // selected: {
    //   backgroundColor: 'rgb(235,137,33, .20)',
    // },
    '&$hover:hover': {
      backgroundColor: 'rgb(235,137,33, .20)',
    },
  },
  selected: {
    backgroundColor: 'rgb(235,137,33, .20)',
  },
}))(TableRow);
