import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import * as Analytics from '../../Utils/analytics';
import { PAGE_NAMES } from '../../Utils/analytics/constants';
import { signoutAsyncAction } from '../../modules/Session';

const useSignout = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const signoutUser = useCallback(() => {
    queryClient.removeQueries();
    Analytics.trackPageView(PAGE_NAMES.SIGN_OUT);
    Analytics.signoutEvent();
    dispatch(signoutAsyncAction());
  }, [dispatch, queryClient]);

  return {
    signoutUser,
  };
};

export default useSignout;
