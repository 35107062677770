import { MenuItemProps } from '../atomic/molecules/Dropdown_V2/interfaces';
import { AssemblyCurrency, CurrencyLevel } from '../interfaces/assembly';

export const getMenuItems = (
  assemblyCurrency: AssemblyCurrency,
): MenuItemProps[] => [
  {
    id: 'add-trophies-menu-items',
    items: [
      {
        value: `No ${assemblyCurrency.pluralName}`,
        id: -2,
        emoji: '🚫',
      },
      {
        value: `Add 10 ${assemblyCurrency.pluralName}`,
        id: 10,
        emoji: '🙂',
      },
      {
        value: `Add 20 ${assemblyCurrency.pluralName}`,
        id: 20,
        emoji: '😀',
      },
      {
        value: `Add 30 ${assemblyCurrency.pluralName}`,
        id: 30,
        emoji: '👏',
      },
      {
        value: `Add 40 ${assemblyCurrency.pluralName}`,
        id: 40,
        emoji: '💪',
      },
      {
        value: `Add 50 ${assemblyCurrency.pluralName}`,
        id: 50,
        emoji: '🚀',
      },
      {
        value: '+ Custom amount',
        id: -1,
      },
    ],
  },
];

const currencyLevelEmojiMap: { [Key: string]: string } = {
  [CurrencyLevel.SMALL]: '🙂',
  [CurrencyLevel.MEDIUM]: '😀',
  [CurrencyLevel.LARGE]: '👏',
  [CurrencyLevel.HUGE]: '💪',
  [CurrencyLevel.ABOVE]: '🚀',
};

export const getCurrencyMenuItemsFromPercentage = (
  assemblyCurrency: AssemblyCurrency,
  levels: { percentage: number; name: CurrencyLevel }[],
  points: number,
): MenuItemProps[] => {
  const result: MenuItemProps[] = [
    {
      id: 'add-trophies-menu-items',
      items: [
        {
          value: `No ${assemblyCurrency.pluralName}`,
          id: -2,
          emoji: '🚫',
        },
      ],
    },
  ];
  levels.forEach((level) => {
    const pointValue = Math.floor((points * level.percentage) / 100);
    const emoji = currencyLevelEmojiMap[level.name];
    const name = `Add ${pointValue} ${assemblyCurrency.pluralName}`;
    result[0].items.push({
      value: name,
      id: pointValue,
      emoji,
    });
  });
  result[0].items.push({
    value: '+ Custom amount',
    id: -1,
  });
  return result;
};
