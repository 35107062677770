// @ts-nocheck TODO: type issues need to be fixed in this file
import * as EmailValidator from 'email-validator';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const FULL_NAME_REQ_ERROR = 'Name must be more than 3 characters long';
const FULL_NAME_NO_FIRST_LAST_ERROR =
  'Sorry, both a first and last name is required';
const PASSWORD_REQ_ERROR = 'Password must be 6 characters long';
const DISPLAY_NAME_ERROR = 'Display name has errors';
const PHONE_NUMBER_INVALID_ERROR = 'Phone number is invalid';

const isValidFullNameWithSpaces = (fullname) => {
  if (fullname) {
    const trimmedName = fullname.trim();
    if (trimmedName.match(/^.*\s.*$/)) {
      return true;
    }
  }
  return false;
};
const validateNumbers = (value) => !value || value.match(/^\d{1,}$/);

const validateFullName = (name) => {
  if (!name) {
    return FULL_NAME_REQ_ERROR;
  }
  const trimName = name.trim();
  if (trimName.length < 3) {
    return FULL_NAME_REQ_ERROR;
  }
  if (!isValidFullNameWithSpaces(name)) {
    return FULL_NAME_NO_FIRST_LAST_ERROR;
  }
  return null;
};

const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber || !parsePhoneNumber(phoneNumber)) {
    return null;
  }

  return !isValidPhoneNumber(phoneNumber) ? PHONE_NUMBER_INVALID_ERROR : null;
};

const validatePassword = (password) => {
  if (!password) {
    return PASSWORD_REQ_ERROR;
  }
  const trimPassword = password.trim();
  if (trimPassword.length < 6) {
    return PASSWORD_REQ_ERROR;
  }
  return null;
};

const isValidEmail = (email) => EmailValidator.validate(email);

const validateDisplayName = (displayName) => {
  if (displayName && !displayName.match(/^[a-zA-Z0-9.-]*$/)) {
    return DISPLAY_NAME_ERROR;
  }

  return null;
};

export {
  validatePassword,
  validateFullName,
  isValidEmail,
  validateDisplayName,
  isValidFullNameWithSpaces,
  validateNumbers,
  validatePhoneNumber,
};
