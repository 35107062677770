import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';
import accountManagementTypes from '../../identity/accountManagementTypes';
import Link from '../../../atomic/atoms/Link';
import routesList from '../../../aV2/routes/routesList';
import {
  accountManagementTypeLanguageMap,
  identityManagerSteps,
} from './common';

export const stepsWizardTitle = 'Set up Office365 as your identity provider';
export const steps = [
  {
    id: identityManagerSteps.WHAT_IS_THIS,
    title: 'Connect',
  },
  {
    id: identityManagerSteps.SELECT_PEOPLE,
    title: 'Select People',
  },
  {
    id: identityManagerSteps.CONFIGURE_INVITES,
    title: 'Configure Invites',
  },
  {
    id: identityManagerSteps.CREATE_ACCOUNTS,
    title: 'Create Accounts',
  },
];

export const introInfoItems = [
  {
    icon: 'api',
    badgeColor: ThemeV2.palette.cyan7,
    info: 'Connecting Office365 is a simple way of importing people from your business into Assembly.',
  },
  {
    icon: 'team',
    badgeColor: ThemeV2.palette.geekBlue6,
    info: 'Choose whether to import everyone or select specific people.',
  },
  {
    icon: 'sync',
    badgeColor: ThemeV2.palette.yellow2,
    info: "When you add or remove people from Office365, we'll automatically add or remove them from Assembly.",
  },
  {
    icon: 'idcard',
    badgeColor: ThemeV2.palette.brown,
    info: "Update people's profile info in Office365 and we'll sync these changes on Assembly too.",
  },
];

export const introHeading = 'How does the Office365 connection work?';
export const introSecondHeading = 'Recommended permission requirements';
export const recommendation =
  'We recommend that a Office 365 admin to connect Assembly with your Office 365 directory';
export const progressHeading =
  'Assembly is gathering your team members, please give us a moment';

export const userSelectHeading = 'Who do you want to add from Office365? ';
export const userSelectSubHeading =
  "We'll keep your details in sync if you choose an automated option";
export const userSelectRadioGroupOptions = [
  {
    value: accountManagementTypes.ALL_USERS,
    label: 'Everyone that joins or leaves the Office 365 workspace',
  },
  {
    value: accountManagementTypes.GROUPS,
    label: `Anyone that joins or leaves a specific Office365 ${
      accountManagementTypeLanguageMap[accountManagementTypes.GROUPS]
    }`,
  },
  {
    value: accountManagementTypes.SELECTED_USERS,
    label: 'I want to manage all members manually on Assembly',
  },
];

export const invitesHeading = 'Ready to send your invitations?';
export const invitesSubHeading = `Once accounts are created, you'll need to send invitations so
people can access Assembly. Decide whether to send these invitations now or later`;
export const invitesRadioGroupOptions = [
  {
    value: true,
    labelHeader: 'Send Invitations Now',
    label: ` – Send them now, and whenever someone is added to your Office365, they'll automatically
    be sent an invitation too.`,
  },
  {
    value: false,
    labelHeader: `I'll send them later`,
    label: ` – If you choose to send them later, we'll create the accounts and put the invitations
    in your Invites page, so you can send them when you're ready.`,
  },
];

export const approvalHeading =
  'Auto approve all new members or require invite and request approvals?';
export const approvalSubHeading = `When new members join your Office365 groups, we can either
send you and admins an approval email or we can immediately send the member an invite`;
export const approvalRadioGroupOptions = [
  {
    value: true,
    labelHeader: 'Auto approve new members',
    label: ` – When we see a new Office365 member that fits the correct criteria, we’ll send them an
    invite and they’ll have access when they accept`,
  },
  {
    value: false,
    labelHeader: `Admin approval only`,
    label: ` – When we see a new Office365 member that fits the correct criteria, we’ll send you and
    admins a request to explicitly approve first`,
  },
];

export const createAccountsHeading = 'Creating your Assembly accounts';
export const createAccountsSubHeading = `This may take a while. You can leave this page and continue with other tasks.
 Invites will be sent once we’re done creating everyone’s account`;
export const createAccountsSubHeadingQueued = (
  <>
    This may take a while. You can leave this page and continue with other
    tasks. Go <Link to={routesList.ADMIN_USER_INVITE}>here</Link> to send out
    invitations for people to activate their new accounts when ready
  </>
);

export const setupConfigurePopupHeading = 'Add Assembly to your channel tab';
export const setupConfigurePopupText =
  'Get ready to celebrate your co-workers! Use Assembly in Microsoft Teams to give recognition and redeem rewards.';
