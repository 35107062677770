import styled from 'styled-components';
import Modal from '../../atoms/Modal';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import LinkItem from '../../atoms/LinkItem';
import ThemeV2 from '../../../componentsV2/theme';

export const BenchMarkInfoModalRoot = styled(Modal)`
  width: 100%;
  max-width: 564px;
`;
BenchMarkInfoModalRoot.displayName = 'BenchMarkInfoModalRoot';

export const BenchMarkInfoModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
`;
BenchMarkInfoModalHeader.displayName = 'BenchMarkInfoModalHeader';

export const BenchMarkInfoModalBody = styled.div`
  padding: 32px;
  overflow-y: auto;
  max-height: 480px;
`;
BenchMarkInfoModalBody.displayName = 'BenchMarkInfoModalBody';

export const BenchMarkInfoModalTitle = styled(Heading)`
  color: ${ThemeV2.palette.gray9};
  margin: 0px auto;
`;
BenchMarkInfoModalTitle.displayName = 'BenchMarkInfoModalTitle';

export const BenchMarkInfoModalBodyHeading = styled(Body)`
  color: ${ThemeV2.palette.gray10};
  margin-top: 24px;
`;
BenchMarkInfoModalBodyHeading.displayName = 'BenchMarkInfoModalBodyHeading';

export const BenchMarkInfoModalBodyText = styled(Body)`
  color: ${ThemeV2.palette.gray8};
`;
BenchMarkInfoModalBodyText.displayName = 'BenchMarkInfoModalBodyText';

export const BenchMarkInfoModalBodyLink = styled(LinkItem)`
  color: ${ThemeV2.palette.geekBlue6};
  margin-top: 12px;
  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
  &:focus {
    color: ${ThemeV2.palette.geekBlue7};
  }
`;
BenchMarkInfoModalBodyLink.displayName = 'BenchMarkInfoModalBodyLink';

export const BenchMarkInfoModalFooter = styled.div`
  padding: 16px;
  border-top: 1px solid ${ThemeV2.palette.gray4};
`;
BenchMarkInfoModalFooter.displayName = 'BenchMarkInfoModalFooter';
