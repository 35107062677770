import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button';
import IconButton from '../../atoms/IconButton_V2';
import { BenchMarkInfoModalProps } from './interface';
import {
  BenchMarkInfoModalRoot,
  BenchMarkInfoModalHeader,
  BenchMarkInfoModalBody,
  BenchMarkInfoModalTitle,
  BenchMarkInfoModalBodyHeading,
  BenchMarkInfoModalBodyText,
  BenchMarkInfoModalBodyLink,
  BenchMarkInfoModalFooter,
} from './styles';
import {
  BENCHMARK_INFO_MODAL_BODY_TITLE,
  BENCHMARK_INFO_MODAL_LIST_1_HEADING,
  BENCHMARK_INFO_MODAL_LIST_1_LINK,
  BENCHMARK_INFO_MODAL_LIST_1_TEXT,
  BENCHMARK_INFO_MODAL_LIST_2_HEADING,
  BENCHMARK_INFO_MODAL_LIST_2_LINK,
  BENCHMARK_INFO_MODAL_LIST_2_TEXT,
  BENCHMARK_INFO_MODAL_LIST_3_HEADING,
  BENCHMARK_INFO_MODAL_LIST_3_LINK,
  BENCHMARK_INFO_MODAL_LIST_3_TEXT,
  BENCHMARK_INFO_MODAL_LIST_4_HEADING,
  BENCHMARK_INFO_MODAL_LIST_4_LINK,
  BENCHMARK_INFO_MODAL_LIST_4_TEXT,
  BENCHMARK_INFO_MODAL_TITLE,
  BENCHMARK_INFO_MODAL_FOOTER_BUTTON_TEXT,
} from '../../../Utils/data/analytics/common';
import routesList from '../../../aV2/routes/routesList';

const BenchMarkInfoModal = (props: BenchMarkInfoModalProps) => {
  const { isOpen, onModalClose, onBookACallClick } = props;
  const { push } = useHistory();
  const handleGiveRecognitionClick = useCallback(() => {
    push(routesList.HOME);
    window.scrollTo(0, 0);
  }, [push]);
  const handleViewBlogPostClick = useCallback(() => {
    window.open(
      'https://joinassembly.com/blog/2020/10/what-makes-recognition-meaningful',
      'blank',
    );
  }, []);
  const handleBookACallClick = useCallback(() => {
    if (onBookACallClick) {
      onBookACallClick();
    }
  }, [onBookACallClick]);
  return (
    <BenchMarkInfoModalRoot isOpen={isOpen} handleClose={onModalClose}>
      <BenchMarkInfoModalHeader>
        <BenchMarkInfoModalTitle variant="h5">
          {BENCHMARK_INFO_MODAL_TITLE}
        </BenchMarkInfoModalTitle>
        <IconButton
          icon="round-close"
          title="modal close"
          onClick={onModalClose}
        />
      </BenchMarkInfoModalHeader>
      <BenchMarkInfoModalBody>
        <BenchMarkInfoModalTitle variant="h5">
          {BENCHMARK_INFO_MODAL_BODY_TITLE}
        </BenchMarkInfoModalTitle>
        <div>
          <BenchMarkInfoModalBodyHeading variant="body2Medium">
            {BENCHMARK_INFO_MODAL_LIST_1_HEADING}
          </BenchMarkInfoModalBodyHeading>
          <BenchMarkInfoModalBodyText variant="body2">
            {BENCHMARK_INFO_MODAL_LIST_1_TEXT}
          </BenchMarkInfoModalBodyText>
          <BenchMarkInfoModalBodyLink onClick={handleGiveRecognitionClick}>
            {BENCHMARK_INFO_MODAL_LIST_1_LINK}
          </BenchMarkInfoModalBodyLink>
        </div>
        <div>
          <BenchMarkInfoModalBodyHeading variant="body2Medium">
            {BENCHMARK_INFO_MODAL_LIST_2_HEADING}
          </BenchMarkInfoModalBodyHeading>
          <BenchMarkInfoModalBodyText variant="body2">
            {BENCHMARK_INFO_MODAL_LIST_2_TEXT}
          </BenchMarkInfoModalBodyText>
          <BenchMarkInfoModalBodyLink onClick={handleViewBlogPostClick}>
            {BENCHMARK_INFO_MODAL_LIST_2_LINK}
          </BenchMarkInfoModalBodyLink>
        </div>
        <div>
          <BenchMarkInfoModalBodyHeading variant="body2Medium">
            {BENCHMARK_INFO_MODAL_LIST_3_HEADING}
          </BenchMarkInfoModalBodyHeading>
          <BenchMarkInfoModalBodyText variant="body2">
            {BENCHMARK_INFO_MODAL_LIST_3_TEXT}
          </BenchMarkInfoModalBodyText>
          <BenchMarkInfoModalBodyLink onClick={handleGiveRecognitionClick}>
            {BENCHMARK_INFO_MODAL_LIST_3_LINK}
          </BenchMarkInfoModalBodyLink>
        </div>
        <div>
          <BenchMarkInfoModalBodyHeading variant="body2Medium">
            {BENCHMARK_INFO_MODAL_LIST_4_HEADING}
          </BenchMarkInfoModalBodyHeading>
          <BenchMarkInfoModalBodyText variant="body2">
            {BENCHMARK_INFO_MODAL_LIST_4_TEXT}
          </BenchMarkInfoModalBodyText>
          <BenchMarkInfoModalBodyLink onClick={handleBookACallClick}>
            {BENCHMARK_INFO_MODAL_LIST_4_LINK}
          </BenchMarkInfoModalBodyLink>
        </div>
      </BenchMarkInfoModalBody>
      <BenchMarkInfoModalFooter>
        <Button color="secondary" onClick={onModalClose} isFullWidth>
          {BENCHMARK_INFO_MODAL_FOOTER_BUTTON_TEXT}
        </Button>
      </BenchMarkInfoModalFooter>
    </BenchMarkInfoModalRoot>
  );
};

export default BenchMarkInfoModal;
