import { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';
import get from 'lodash/get';
import RoutesList from '../../aV2/routes/routesList';
import useRequest from '../../hooks/useRequest/useRequest';
import { AUTHORIZE_BOT } from '../../constants/endpoints';
import { APP_URL } from '../../config';
import {
  BotTypeOptions,
  BotTypeManager,
  BotManager,
} from '../../Utils/data/botManager/common';

const useVerifyBotCode = (sso: BotTypeOptions) => {
  const { search } = useLocation();
  const { push } = useHistory();
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [verifyError, setVerifyError] = useState<string | null>(null);

  const authorizeSuccess = useCallback(() => {
    setIsVerifyingCode(false);
    push(
      `${RoutesList.ADMIN_WORKSPACE_INTEGRATIONS}/${
        BotManager[BotTypeManager[sso]].uri
      }`,
      // BotManager[BotTypeManager[sso]].redirectURI
      // }`,
    );
  }, [push, sso]);

  const handleError = useCallback(
    (message = 'SERVER_ERROR') => {
      setIsVerifyingCode(false);
      setVerifyError(message);
      const errorMessage = get(message, ['response', 'data', 'message']);
      if (errorMessage) {
        push(
          `${RoutesList.ADMIN_WORKSPACE_INTEGRATIONS}?error=${errorMessage}&bot=${sso}`,
        );
      }
    },
    [sso, push],
  );

  const { fireRequest } = useRequest(AUTHORIZE_BOT, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    onSuccess: authorizeSuccess,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: Convert useFetch to Typescript
    onError: handleError,
  });

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const REDIRECT_URI = `${APP_URL}${RoutesList.BOT_AUTHORIZE_FN(sso)}`;
    const isCodePresent = parsedParams && parsedParams.code;
    const hasError = parsedParams && parsedParams.error;
    if (isCodePresent) {
      const { code } = parsedParams;
      setIsVerifyingCode(true);
      fireRequest({
        payload: { code, redirectUri: REDIRECT_URI },
        substitutions: { sso },
      });
    }
    if (hasError) {
      push(
        `${RoutesList.ADMIN_WORKSPACE_INTEGRATIONS}?error=${parsedParams.error}&bot=${sso}`,
      );
    }
  }, [sso, search, fireRequest, push]);

  return {
    isVerifyingCode,
    verifyError,
  };
};
export default useVerifyBotCode;
