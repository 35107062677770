// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Button from '../../../../componentsV2/core/Button';
import RoutesList from '../../../routes/routesList';
import Layout from '../../../Layout/onboarding';
import VerticallyCenteredLayout from '../../../Layout/verticallyCentered';
import Link from '../../../../componentsV2/core/Link';
import Typography from '../../../../componentsV2/core/Typography';
import { getVerfiyTokenRequestLoadingStatus } from '../../../modules/login/selectors';
import { forgotPasswordVerifyToken } from '../../../modules/login';
import themeV2 from '../../../../componentsV2/theme';

const styles = (theme) => ({
  buttonStyle: {
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
    marginTop: 30,
    '&:hover': {
      color: `${themeV2.palette.white} !important`,
    },
    '&:visited': {
      color: `${themeV2.palette.white} !important`,
    },
  },
});

const ForgotPasswordLink = React.forwardRef((props, ref) => (
  <Link to={RoutesList.FORGOT_PASSWORD} {...props} ref={ref} />
));

class VerifyToken extends Component {
  constructor(props) {
    super(props);
    const { verifyToken } = props.match.params;
    this.state = {
      verifyFailed: !verifyToken,
    };
  }

  componentDidMount() {
    const { verifyToken } = this.props.match.params;
    if (verifyToken) {
      this.props
        .forgotPasswordVerifyToken({ token: verifyToken })
        .then((successAction) => {
          const data = _.get(successAction, ['data', 'data']);
          const { userId } = data;
          this.props.history.push(`${RoutesList.RESET_PASSWORD}/${userId}`);
        })
        .catch(() => {
          this.setState({ verifyFailed: true });
        });
    }
  }

  render() {
    const { classes, loading } = this.props;
    const { verifyFailed } = this.state;
    let mainText = 'Verifying your reset token...';
    if (verifyFailed) {
      mainText = 'Verification failed';
    } else if (loading) {
      mainText = 'Verifying your reset token...';
    } else {
      mainText = 'Verification Successfully';
    }

    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              {mainText}
            </Typography>
          </div>
          {verifyFailed && (
            <React.Fragment>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Your token is invalid. Please try again.
                </Typography>
              </div>
              <div>
                <Button
                  component={ForgotPasswordLink}
                  className={classes.buttonStyle}
                  variant="contained"
                  color="secondary"
                >
                  Forgot Password
                </Button>
              </div>
            </React.Fragment>
          )}
        </VerticallyCenteredLayout>
      </Layout>
    );
  }
}

VerifyToken.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getVerfiyTokenRequestLoadingStatus(state),
});

export default connect(mapStateToProps, { forgotPasswordVerifyToken })(
  withStyles(styles)(VerifyToken),
);
