import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import InfoAlert from '../../molecules/InfoAlert';
import ThemeV2 from '../../../componentsV2/theme';
import { CARD_OPTIONS } from './styles';
import Input from '../../molecules/Input';
import {
  addPaymentSubHeading,
  addPaymentSubHeadingHeader,
  addPaymentHeading,
  addPaymentSecondaryButtonText,
  addPaymentPrimaryButtonText,
  upgradeReviewInputPlaceholders,
} from '../../../Utils/data/planUpgradeModal';

const useStyles = makeStyles({
  heading: {
    marginBottom: '16px',
  },
  subHeading: {
    marginBottom: '24px',
  },
  errorInfoAlert: {
    width: 'auto',
    marginBottom: '16px',
  },
  expirationFieldRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  promoCodeField: {
    display: 'flex',
    width: '100%',
    marginBottom: '-16px !important',
    '& >span': {
      width: '100%',
    },
    '& Button': {
      marginLeft: '16px',
    },
  },
  input: {
    fontFamily: 'Roboto, Menlo, monospace',
    fontWeight: 'normal',
    fontSize: '16px',
    border: `1px solid ${ThemeV2.palette.gray5}`,
    color: ThemeV2.palette.darkText,
    borderRadius: '3px',
    marginBottom: '16px',
    width: 'auto',
    '& ::placeholder': {
      fontFamily: 'Roboto, Menlo, monospace',
      fontWeight: 'normal',
      fontSize: '16px',
      color: ThemeV2.palette.gray6,
      opacity: '1',
    },
  },
  cardInput: {
    padding: '11px',
    '&.StripeElement--focus': {
      boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
      borderColor: ThemeV2.palette.geekBlue5,
    },
    '&.StripeElement--invalid': {
      borderColor: ThemeV2.palette.red6,
    },
  },
  cardExpiryInput: {
    width: '50%',
    marginRight: '16px',
  },
  cardCVCInput: {
    width: '50%',
  },
});

export interface PlanUpgradeAddPaymentModalProps {
  heading: string;
  onNextClick(): void;
  onNevermindClick(): void;
  onModalClose(): void;
  error: string | null;
  setError(error: string | null): void;
  name?: string;
  setName(name: string): void;
  promoCode?: string;
  setPromoCode(promoCode: string): void;
  promoCodeError: boolean;
  promoCodeHelperText?: string;
  onApplyClick(): void;
  isButtonsLoading: boolean;
}

const PlanUpgradeAddPaymentModal = (props: PlanUpgradeAddPaymentModalProps) => {
  const classes = useStyles(props);
  const {
    heading: templateHeading,
    onNextClick,
    onNevermindClick,
    onModalClose,
    error,
    setError,
    name,
    setName,
    promoCode,
    setPromoCode,
    promoCodeError,
    promoCodeHelperText,
    onApplyClick,
    isButtonsLoading,
  } = props;

  const primaryButton = {
    text: addPaymentPrimaryButtonText,
    isLoading: isButtonsLoading,
    onClick: onNextClick,
  };
  const secondaryButton = {
    text: addPaymentSecondaryButtonText,
    disabled: isButtonsLoading,
    onClick: onNevermindClick,
  };
  return (
    <BasicModalTemplate
      heading={templateHeading}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      onModalClose={onModalClose}
    >
      <Heading variant="h5" className={classes.heading}>
        {addPaymentHeading}
      </Heading>
      <div className={classes.subHeading}>
        <Body variant="body2Bold" inline>
          {addPaymentSubHeadingHeader}
        </Body>
        <Body variant="body2" inline>
          {addPaymentSubHeading}
        </Body>
      </div>
      {error && (
        <InfoAlert
          className={classes.errorInfoAlert}
          id={+new Date()}
          icon="warning"
          alertType="error"
          text={error}
          isDismissible={{
            closeIconColor: ThemeV2.palette.dustRed6,
            onCloseClick: () => setError(null),
            onCloseKeyPress: () => setError(null),
          }}
        />
      )}
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
      }
      <Input
        type="text"
        name="name"
        placeholder={upgradeReviewInputPlaceholders.name}
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setName(event.target.value)
        }
        className={classes.input}
      />
      <CardNumberElement
        options={{
          ...CARD_OPTIONS,
          placeholder: upgradeReviewInputPlaceholders.cardNumber,
        }}
        className={`${classes.input} ${classes.cardInput}`}
      />
      <div className={classes.expirationFieldRow}>
        <CardExpiryElement
          options={{
            ...CARD_OPTIONS,
            placeholder: upgradeReviewInputPlaceholders.cardExpiration,
          }}
          className={`${classes.input} ${classes.cardInput} ${classes.cardExpiryInput}`}
        />
        <CardCvcElement
          options={{
            ...CARD_OPTIONS,
            placeholder: upgradeReviewInputPlaceholders.cardCVC,
          }}
          className={`${classes.input} ${classes.cardInput} ${classes.cardCVCInput}`}
        />
      </div>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
      }
      <div className={classes.promoCodeField}>
        <span>
          <Input
            type="text"
            name="promoCode"
            placeholder={upgradeReviewInputPlaceholders.promoCode}
            value={promoCode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPromoCode(event.target.value)
            }
            error={promoCodeError}
            helperText={promoCodeHelperText}
            className={classes.input}
          />
        </span>
        <Button onClick={onApplyClick} color="primary" variant="outlined">
          Apply
        </Button>
      </div>
    </BasicModalTemplate>
  );
};

export default PlanUpgradeAddPaymentModal;
