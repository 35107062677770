import React from 'react';
import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import { HeadingProps } from './interface';

const fontSizes = {
  h1: {
    fontSize: '46px',
    lineHeight: '54px',
  },
  h2: {
    fontSize: '30px',
    lineHeight: '38px',
  },
  h3: {
    fontSize: '38px',
    lineHeight: '46px',
  },
  h4: {
    fontSize: '30px',
    lineHeight: '38px',
  },
  h5: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  h6: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  h7: {
    fontSize: '16px',
    lineHeight: '24px',
  },
};

const fontStyles = {
  regular: {
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  medium: {
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontStyle: 'normal',
  },
  bold: { fontWeight: ThemeV2.typography.fontWeightBold, fontStyle: 'normal' },
};

const getTag = (variant: string | undefined) => {
  switch (variant) {
    case 'h1':
      return 1;
    case 'h2':
      return 2;
    case 'h3':
      return 3;
    case 'h4':
      return 4;
    case 'h5':
      return 5;
    case 'h6':
      return 6;
    case 'h7':
      return 6;
    default:
      return 1;
  }
};

const Heading = styled(
  ({ variant, dataTestId, children, className = '' }: HeadingProps) => {
    return React.createElement(
      `h${getTag(variant)}`,
      { 'data-testid': dataTestId, className },
      children,
    );
  },
)`
  font-family: ${({ fontFamily }) =>
    fontFamily || ThemeV2.typography.adminFontFamily};
  font-size: ${({ variant }) => variant && fontSizes[variant].fontSize};
  font-weight: ${({ weight }) =>
    weight ? fontStyles[weight].fontWeight : fontStyles.bold.fontWeight};
  font-style: ${({ weight }) =>
    weight ? fontStyles[weight].fontStyle : fontStyles.bold.fontStyle};
  line-height: ${({ variant }) => variant && fontSizes[variant].lineHeight};
  color: ${({ color }) => color || ThemeV2.palette.gray9};
  text-align: ${({ textAlign }) => textAlign};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
`;

export default Heading;
Heading.displayName = 'Heading';
