// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import { API_URL_WITH_V2 } from '../../../config';
import { AuthHeaders } from '../../shared/HeaderToken';
import { adminProcessError } from '../../Admin';
import Logger from '../../../Utils/Logger';
import {
  showSuccessMessage,
  showInfoMessage,
} from '../../../Utils/flashHandler';
import { actions as usersManageActions } from '../users/Manage';

const apiEndpoints = {
  CUSTOM_ALLOWANCE: `${API_URL_WITH_V2}/employer/employer_info/custom`,
};

export const CUSTOM_ALLOWANCE_REQUEST_STATUSES = {
  BLANK: 'blank',
  UPDATING: 'updating',
};

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_CUSTOM_ALLOWANCE_SUCCESS =
  'UPDATE_CUSTOM_ALLOWANCE_SUCCESS';
export const CUSTOM_ALLOWANCE_REQUEST_FAILED =
  'CUSTOM_ALLOWANCE_REQUEST_FAILED';
export const CUSTOM_ALLOWANCE_REQUEST_STATUS =
  'CUSTOM_ALLOWANCE_REQUEST_STATUS';

// ------------------------------------
// Actions
// ------------------------------------
const updateCustomAllowanceSuccess = () => ({
  type: UPDATE_CUSTOM_ALLOWANCE_SUCCESS,
});

const updateRequestStatus = (status) => ({
  type: CUSTOM_ALLOWANCE_REQUEST_STATUS,
  status,
});

const requestFailed = (errorMessage) => ({
  type: CUSTOM_ALLOWANCE_REQUEST_FAILED,
  error: errorMessage,
});

export const customAllowanceActions = {
  updateCustomAllowanceSuccess,
  updateRequestStatus,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------
export const updateCustomAllowance = (props) => async (dispatch) => {
  Logger('updateCustomAllowance');
  try {
    dispatch(showInfoMessage('Updating custom allowance...'));
    dispatch(
      customAllowanceActions.updateRequestStatus(
        CUSTOM_ALLOWANCE_REQUEST_STATUSES.UPDATING,
      ),
    );
    const res = await axios.put(
      apiEndpoints.CUSTOM_ALLOWANCE,
      props,
      AuthHeaders(),
    );
    const result = res.data;
    dispatch(
      customAllowanceActions.updateRequestStatus(
        CUSTOM_ALLOWANCE_REQUEST_STATUSES.BLANK,
      ),
    );
    Logger(result, true);
    if (result.success) {
      const { users } = result.data;
      dispatch(customAllowanceActions.updateCustomAllowanceSuccess());
      dispatch(usersManageActions.updateEmployeesSuccess(users));
      dispatch(showSuccessMessage('Custom allowance updated'));
    }
  } catch (error) {
    adminProcessError(error, dispatch, customAllowanceActions);
    dispatch(
      customAllowanceActions.updateRequestStatus(
        CUSTOM_ALLOWANCE_REQUEST_STATUSES.BLANK,
      ),
    );
  }
};

export default (
  state = { requestStatus: CUSTOM_ALLOWANCE_REQUEST_STATUSES.BLANK },
  action,
) => {
  switch (action.type) {
    case UPDATE_CUSTOM_ALLOWANCE_SUCCESS: {
      return { ...state };
    }
    case CUSTOM_ALLOWANCE_REQUEST_STATUS:
      return { ...state, requestStatus: action.status };
    default:
      return state;
  }
};
