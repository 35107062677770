// @ts-nocheck TODO: type issues need to be fixed in this file
import uuid from 'uuid';
import moment from 'moment';
import _ from 'lodash';
import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';
import getter from './objectUtils/getter';
import { isValidEmail } from './validation';

const PLACEHOLDER_EMAIL = [
  'tylersellers@joinassembly.com',
  'racheljones@joinassembly.com',
];

export const readXls = (data, employer) => {
  const XLSX = window.XLSX;
  if (!XLSX) {
    return;
  }
  const rABS = true;
  if (!rABS) data = new Uint8Array(data);
  const workbook = XLSX.read(data, {
    type: rABS ? 'binary' : 'array',
    cellDates: true,
  });
  const js = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
    header: 1,
  }); // raw: true,
  const users = [];
  let noElementCount = 0;
  for (let i = 0; i < js.length; i++) {
    const row = js[i];
    if (row.length > 6) {
      noElementCount = 0;
      if (Number(i) === 0 && row[0] === 'first_name') {
        continue;
      } else {
        const firstName = row[0];
        const lastName = row[1];
        const email = row[2];
        if (
          email === 'bruce@wayneenterprise.com' ||
          email === 'rachel@gothamda.com'
        ) {
          continue;
        }
        const department = row[3];
        const statusValue = row[4];
        let status = 'normal';
        if (statusValue.includes('Giver')) {
          status = 'giver';
        } else if (statusValue.includes('Observer')) {
          status = 'observer';
        } else if (statusValue.includes('Receiver')) {
          status = 'receiver';
        }
        const regularAllowance = getter(['regularAllowance'], employer) || 200;
        const allowanceTypeValue = row[5];
        let allowanceType = 'regular';
        let customAllowance = 0;
        if (allowanceTypeValue !== regularAllowance) {
          allowanceType = 'custom';
          customAllowance = allowanceTypeValue;
        }

        const birthDate = moment(row[6], [
          'MM-DD-YYYY',
          'MM-DD-YY',
          'MM/DD/YYYY',
          'MM/DD/YY',
        ]).isValid()
          ? moment(row[6], [
              'MM-DD-YYYY',
              'DD-MM',
              'DD-MM-YYYY',
              'DD/MM/YYYY',
              'MM/DD/YYYY',
              'DD/MM/YY',
              'MM/DD/YY',
            ]).format()
          : null;
        const hiredDate = moment(row[7], [
          'MM-DD-YYYY',
          'MM-DD-YY',
          'MM/DD/YYYY',
          'MM/DD/YY',
        ]).isValid()
          ? moment(row[7], [
              'MM-DD-YYYY',
              'DD-MM',
              'DD-MM-YYYY',
              'DD/MM/YYYY',
              'MM/DD/YYYY',
              'DD/MM/YY',
              'MM/DD/YY',
            ]).format()
          : null;

        // else if (allowanceTypeValue.includes("No")) {
        //   allowanceType = "no";
        // }
        const isManager = row[8] === 'Yes';
        const isAdmin = row[9] === 'Yes';
        const managerEmail = row[10] ? row[10] : '';
        const externalUniqueId = row[11] ? row[11] : '';
        const _id = uuid();
        users.push({
          _id,
          email,
          firstName,
          lastName,
          department,
          status,
          allowanceType,
          customAllowance,
          birthDate,
          hiredDate,
          managerEmail,
          externalUniqueId,
          isAdmin,
          isManager,
        });
      }
    } else if (noElementCount < 10) {
      noElementCount++;
    } else {
      break;
    }
  }
  return users;
};

export const writeToXLS = (data, sheetName, fileName) => {
  const XLSX = window.XLSX;
  if (!XLSX) {
    return;
  }
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  wb.SheetNames.push(sheetName);
  wb.Sheets[sheetName] = ws;
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary',
  });
  const url = window.URL.createObjectURL(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
  );
  download(url, fileName);
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};
const download = (url, name) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const importEmails = (file, successCallback) => {
  let emails = [];
  const fileType = file.name.slice(
    ((file.name.lastIndexOf('.') - 1) >>> 0) + 2,
  );

  switch (file.name.length > 0) {
    case fileType === 'csv':
      Papa.parse(file, {
        transform: (data) => {
          return data.trim();
        },
        complete: (results) => {
          const array = results.data.flat();
          emails = array.filter((email) => {
            if (PLACEHOLDER_EMAIL.includes(email)) {
              return false;
            }
            return isValidEmail(email);
          });
          emails = _.uniq(emails);
          successCallback(emails);
        },
      });
      break;
    case fileType === 'xlsx':
      readXlsxFile(file).then((mails) => {
        const emailsUploaded = [].concat.apply([], mails);
        emails = emailsUploaded.filter((email) => {
          if (PLACEHOLDER_EMAIL.includes(email)) {
            return false;
          }
          return isValidEmail(email);
        });
        emails = _.uniq(emails);
        successCallback(emails);
      });
      break;
    default:
      break;
  }
  return emails;
};
