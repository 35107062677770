import React from 'react';

import {
  StyledWrapper,
  StyledCheckbox,
  StyledAvatar,
  StyledBody,
} from './styles';
import { FlowCheckboxProps } from './interfaces';

import { CheckboxProps } from '../../atoms/Checkbox';
import { AvatarProps } from '../../atoms/Avatar/interfaces';
import { MemberState } from '../../../interfaces/user';

const FlowCheckbox = (props: FlowCheckboxProps) => {
  const {
    label,
    name,
    value,
    onChange,
    size,
    color,
    className,
    info,
    showAvatar = false,
    avatarName,
    memberState,
    disabled = false,
    icon,
    ...rest
  } = props;

  const checkboxProps: CheckboxProps = {
    label,
    name,
    value,
    onChange,
    icon,
    size,
    color,
    disabled,
  };

  const avatarProps: AvatarProps = {
    name: avatarName,
    ...rest,
  };
  const { img } = avatarProps;
  const hasAvatar = !!img || showAvatar;
  const hasInfo = !!info;
  const avatarIcon =
    memberState === MemberState.PENDING ? 'pending-person' : '';
  return (
    <StyledWrapper
      className={className}
      hasInfo={hasInfo}
      hasAvatar={hasAvatar}
    >
      <StyledCheckbox
        {...checkboxProps}
        hasAvatar={hasAvatar}
        hasInfo={hasInfo}
      />
      {hasAvatar && (
        <StyledAvatar {...avatarProps} icon={avatarIcon} size="24px" />
      )}
      {hasInfo && info && (
        <StyledBody variant="body3" color="gray7">
          {info}
        </StyledBody>
      )}
    </StyledWrapper>
  );
};

export default FlowCheckbox;
