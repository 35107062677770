// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';

// Material UI
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';

import Table from '../admin/shared/table/Table';
import TableCell from '../admin/shared/table/TableCell';
import TableRow from '../admin/shared/table/TableRow';
import profilePlaceholder from '../../img/profilePlaceholder.png';
import ImageUploader from '../../atomic/organism/ImageUploader';
import ChangeCurrencyModal from './ChangeCurrencyModal';
import SearchBar from './SearchBar';
import XLSScript from '../admin/shared/xlsScript';
import Link from '../../atomic/atoms/Link';

import {
  getAccountsAction,
  updateBillingStatusAction,
  updateAccountMediaAction,
  deleteAccountMediaAction,
} from '../../modules/admin/superadmin';
import {
  getAccountsSelector,
  getAccountCustomIconSelector,
} from '../../modules/admin/superadmin/selector';
import billingTransitions from '../../Utils/superadmin/billingTransitions';
import DEFAULT_CURRENCY from '../../Utils/superadmin/constants';
import { writeToXLS } from '../../Utils/XlsReader';
import routesList from '../../aV2/routes/routesList';

const IMAGE_LIMITS = {
  MIN_DIMENSIONS: {
    WIDTH: 150,
    HEIGHT: 34,
  },
};

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  headingDiv: {
    paddingTop: '40px',
  },
  headingText: {
    display: 'inline',
  },
  buttonStyle: {
    position: 'absolute',
    right: '40px',
  },
  iconStyle: {
    position: 'absolute',
    right: '0px',
  },
});

class AccountsDashboardV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeCurrencyModalOpen: false,
      selectedEmployerId: null,
      selectedEmployerObj: {},
      showDoneIcon: false,
      searchTextFieldValue: '',
    };
  }

  componentDidMount() {
    this.props
      .getAccountsAction({ limit: 50, page: 1 })
      .then((successAction) => {})
      .catch((errorOrAbortAction) => {
        window.alert('Error while loading accounts');
      });
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  updateBillingStatus = (event) => {
    const employerIdNewStatus = _.get(event, 'target.value', null);
    if (_.isEmpty(employerIdNewStatus)) {
      return;
    }

    const [type, employerId, status] = employerIdNewStatus.split('-');
    if (
      window.confirm(
        `Are you sure you want to change the accountStatus to ${status}`,
      ) === false
    ) {
      return;
    }
    this.props
      .updateBillingStatusAction({
        type: _.toLower(type),
        status,
        employerId,
      })
      .then((successAction) => {})
      .catch((errorOrAbortAction) => {
        window.alert('Error while updating billing status');
      });
  };

  billingStatus({ type, employer }) {
    let displayBillingStatus =
      employer.billingStatus[_.camelCase(`${type}_status`)];
    displayBillingStatus = _.startCase(_.toLower(displayBillingStatus));
    return <TableCell>{displayBillingStatus}</TableCell>;
  }

  billingAction({ type, employer }) {
    const billingStatus = _.get(
      employer,
      `billingStatus.${_.camelCase(`${type}_STATUS`)}`,
    );
    const availableTransistions = billingTransitions[type][billingStatus] || [];
    if (availableTransistions.length === 0) {
      return 'No action here';
    }
    return (
      <FormControl id={employer.employerId} className="form-control">
        <InputLabel>Change</InputLabel>
        <Select value="" onChange={this.updateBillingStatus}>
          {availableTransistions.map((transition) => (
            <MenuItem
              key={`${type}-${employer.employerId}-${transition}`}
              value={`${type}-${employer.employerId}-${transition}`}
            >
              <em>{transition}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  onImageUploadError = (msg) => {
    window.alert(msg);
  };

  onCropperDone = (mediaAdded) => {
    const { selectedEmployerId } = this.state;
    const uploadData = {
      file: mediaAdded,
      fileType: 'logo',
    };
    this.props
      .updateAccountMediaAction(uploadData, selectedEmployerId)
      .then((successAction) => {})
      .catch((errorOrAbortAction) => {
        window.alert('Error while updating company logo');
      });

    this.setState({ selectedEmployerId: null });
    if (this.fileInput) {
      this.fileInput.value = '';
    }
  };

  handleChangeCurrencyModalClose = () => {
    this.setState({
      changeCurrencyModalOpen: false,
    });
  };

  handleChangeCurrencyModalOpen = (employer) => () => {
    this.setState({
      changeCurrencyModalOpen: true,
      selectedEmployerId: employer.employerId,
      selectedEmployerObj: employer,
    });
  };

  onBannerEditClick = (employerId) => () => {
    this.setState({ selectedEmployerId: employerId });
  };

  onBannerDeleteClick = (employerId) => () => {
    this.props
      .deleteAccountMediaAction(employerId)
      .then((successAction) => {})
      .catch((errorOrAbortAction) => {
        window.alert('Error while deleting Logo');
      });
  };

  onCurrencyChange = (props) => {
    const { selectedEmployerId } = this.state;
    const uploadData = {
      customCurrency: props,
    };
    this.props
      .updateAccountMediaAction(uploadData, selectedEmployerId)
      .then((successAction) => {})
      .catch((errorOrAbortAction) => {
        window.alert('Error while updating currency');
      });
    this.handleChangeCurrencyModalClose();
  };

  onLoadUsersClick = () => {
    if (
      window.confirm(`Are you sure you want to load users into Heap?`) === false
    ) {
    }
  };

  handleSearchChange = (event) => {
    const searchText = event.target.value;
    this.setState({ searchTextFieldValue: searchText });
  };

  onSearchClick = () => {
    this.props.getAccountsAction({
      limit: 10,
      page: 1,
      search: this.state.searchTextFieldValue,
    });
  };

  onClearClick = () => {
    this.setState({ searchTextFieldValue: '' });
    this.props.getAccountsAction({ limit: 10, page: 1 });
  };

  onExportClick = () => {
    const { accounts } = this.props;
    const csvData = [['Created Date', 'Company Name', 'User Name', 'Email']];

    accounts.map((account) => {
      csvData.push([
        moment(account.joinedOn).format('MM-DD-YYYY'),
        account.name,
        account.ownerName,
        account.ownerEmail,
      ]);
      return null;
    });
    writeToXLS(
      csvData,
      'Company Accounts',
      `${moment().format('MM-DD-YYYY')}-Accounts.xlsx`,
    );
  };

  accountHasLogo = (account) => {
    const logo = _.get(account, ['logo', 'original', 'relativeUrl'], null);
    if (logo) {
      return true;
    }
    return false;
  };

  displayCustomIcon = (account) => {
    const icon = this.props.getCustomIcon(account);
    if (icon.type === 'emoji') {
      const emojiType = _.get(icon, ['value', 'type'], 'REGULAR');
      if (emojiType === 'REGULAR') {
        return String.fromCodePoint(parseInt(icon.value.hexCode, 16));
      }
      if (emojiType === 'CUSTOM') {
        return (
          <img
            src={icon.value.url}
            alt="icon"
            style={{
              width: '20px',
            }}
          />
        );
      }
    }
    return (
      <img
        src={icon.value}
        alt="icon"
        style={{
          width: '20px',
        }}
      />
    );
  };

  render() {
    const { accounts, classes } = this.props;
    return (
      <div className="container">
        <XLSScript />
        <ChangeCurrencyModal
          open={this.state.changeCurrencyModalOpen}
          close={this.handleChangeCurrencyModalClose}
          onUpdate={this.onCurrencyChange}
          selectedEmployer={this.state.selectedEmployerObj}
        />
        <div className={classes.headingDiv}>
          <Typography variant="h6" className={classes.headingText}>
            Accounts Dashboard
          </Typography>
          {this.state.showDoneIcon && (
            <DoneIcon
              color="secondary"
              fontSize="large"
              className={classes.iconStyle}
            />
          )}
        </div>
        <SearchBar
          handleSearchChange={this.handleSearchChange}
          onSearchClick={this.onSearchClick}
          onClearClick={this.onClearClick}
          onExportClick={this.onExportClick}
          searchTextFieldValue={this.state.searchTextFieldValue}
        />
        <div className="row row-container__div admin-fixed-width__div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Joined</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Active Employees</TableCell>
                <TableCell>Paid Reward Status</TableCell>
                <TableCell>Paid Reward Action</TableCell>
                <TableCell>Link to Analytics</TableCell>
                <TableCell>Account Status</TableCell>
                <TableCell>Account Action</TableCell>
                <TableCell>Logo</TableCell>
                <TableCell>Currency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map((account) => (
                <TableRow key={account.employerId} id={account.employerId}>
                  <TableCell component="th" scope="row">
                    {account.name}
                  </TableCell>
                  <TableCell>{account.joinedOn}</TableCell>
                  <TableCell>{account.ownerName}</TableCell>
                  <TableCell>{account.ownerEmail}</TableCell>
                  <TableCell>{account.employeeCount}</TableCell>
                  {this.billingStatus({
                    employer: account,
                    type: 'PAID_REWARD',
                  })}
                  <TableCell>
                    <div style={{ minWidth: '200px' }}>
                      {this.billingAction({
                        employer: account,
                        type: 'PAID_REWARD',
                      })}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`${routesList.SUPER_ADMIN_ANALYTICS}?assemblyId=${account.employerId}&assemblyName=${account.name}`}
                    >
                      Check Analytics
                    </Link>
                  </TableCell>
                  {this.billingStatus({ employer: account, type: 'ACCOUNT' })}
                  <TableCell>
                    <div style={{ minWidth: '200px' }}>
                      {this.billingAction({
                        employer: account,
                        type: 'ACCOUNT',
                      })}
                    </div>
                  </TableCell>
                  <TableCell>
                    <img
                      src={_.get(
                        account,
                        ['logo', 'original', 'relativeUrl'],
                        profilePlaceholder,
                      )}
                      alt="No logo"
                      width="150px"
                    />
                    <form>
                      <ImageUploader
                        onError={this.onImageUploadError}
                        onImageEditingDone={this.onCropperDone}
                        aspectRatio={1}
                        limits={IMAGE_LIMITS}
                      >
                        <IconButton
                          component="span"
                          aria-label="Add Image"
                          onClick={this.onBannerEditClick(account.employerId)}
                        >
                          <EditIcon />
                        </IconButton>
                      </ImageUploader>
                      {this.accountHasLogo(account) && (
                        <IconButton
                          aria-label="Edit"
                          onClick={this.onBannerDeleteClick(account.employerId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </form>
                  </TableCell>
                  <TableCell>
                    {account.customCurrency.isSet === true
                      ? account.customCurrency.currencyName
                      : DEFAULT_CURRENCY.CURRENCY_NAME}
                    <br />
                    {account.customCurrency.isSet === true
                      ? account.customCurrency.currencyNamePlural
                      : DEFAULT_CURRENCY.CURRENCY_NAME_PLURAL}
                    <br />
                    {this.displayCustomIcon(account)}
                    <IconButton
                      onClick={this.handleChangeCurrencyModalOpen(account)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accounts: getAccountsSelector(state),
  getCustomIcon: (account) => getAccountCustomIconSelector(state, account),
});

export default connect(mapStateToProps, {
  getAccountsAction,
  updateBillingStatusAction,
  updateAccountMediaAction,
  deleteAccountMediaAction,
})(withStyles(styles)(AccountsDashboardV2));
