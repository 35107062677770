// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';

import history from '../history';
import { API_URL_WITH_V2 } from '../config';
import processErrorResponse, {
  ErrorMessages,
} from '../containers/helpers/ErrorHelper';
import { AuthHeaders } from './shared/HeaderToken';
import { meActions } from './Me';
import Logger from '../Utils/Logger';
import { uiActions, EMPLOYEES_SETUP_STATUSES } from './UI-Reducers/UIReducers';
import { serializeUser } from '../Utils/user';
import * as Analytics from '../Utils/analytics';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  HOME_INFO: `${API_URL_WITH_V2}/user/home/info/`,
  HOME_FEED: `${API_URL_WITH_V2}/user/feed`,
  PROFILE_INFO: `${API_URL_WITH_V2}/user/user_profile/info/`,
  REDEEM_INFO: `${API_URL_WITH_V2}/rewards/checkredeem`,
};

export const homeProcessError = (error = {}, dispatch, homeActions) => {
  Logger('processError');
  Logger(error.response);
  Logger(error);
  const statusCode = error.response ? error.response.status : 0;
  const errorMessage = {};
  switch (statusCode) {
    case 400: // bad request
      dispatch(homeActions.requestFailed(ErrorMessages.serverError));
      break;
    case 401: // Not authorised
      history.push('/signout');
      // (Log out user)
      break;
    case 403: // Forbidden
      history.push('/404');
      //  redirect to home
      break;
    case 404: // Page not found
      dispatch(homeActions.requestFailed(ErrorMessages.serverError));
      break;
    //  mostly my error
    case 422:
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        dispatch(homeActions.requestFailed(msg));
        errorMessage.code = responseData.code;
        errorMessage.message = msg;
      }
      break;
    default:
      dispatch(homeActions.requestFailed(ErrorMessages.networkError));
      break;
  }
  return errorMessage;
};

// ------------------------------------
// Constants
// ------------------------------------
export const GET_HOME_INFO_SUCCESS = 'get_home_info_success';
export const USERPASSWORDSET = 'user_password_set';
export const GET_COWORKER_INFO_SUCCESS = 'get_coworker_info_success';
export const REQUEST_FAILED = 'home_request_failed';
export const IMPORT_FROM_XLS_SUCCESS = 'import_from_xlx_success';

// ------------------------------------
// Actions
// ------------------------------------

const getHomeInfoSuccess = (props) => ({
  type: GET_HOME_INFO_SUCCESS,
  coworkers: props.coworkers,
});

const getCoWorkerInfoSuccess = (coworker) => ({
  type: GET_COWORKER_INFO_SUCCESS,
  coworker,
});

const requestFailed = (errorMessage) => ({
  type: REQUEST_FAILED,
  error: errorMessage,
});

export const actions = {
  getHomeInfoSuccess,
  getCoWorkerInfoSuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------
export const getHomeDetailsAction = () => {
  //  IN this API RESPONSE I need
  //  My available allowance to give
  //  My balance carrots to redeem
  //  My co-workers info (To show in autofill)
  // {name, _id, image}
  Logger('getHomeDetailsAction', false);
  return async (dispatch) => {
    try {
      const res = await axios.get(apiEndpoints.HOME_INFO, AuthHeaders());
      const result = res.data;
      Logger('getHomeDetailsAction', true);
      Logger(result, true);
      if (result.success) {
        const { remainingPoint, pointsEarned } = result.data;
        dispatch(meActions.gotMyPointsToGive(remainingPoint));
        dispatch(meActions.gotMyPointsToRedeem(pointsEarned));
        Analytics.registerUserProps({
          'Allowance Points Available': remainingPoint,
        });
        Analytics.registerSuperProps({
          'Allowance Points Available': remainingPoint,
        });
        dispatch(
          actions.getHomeInfoSuccess({ coworkers: result.data.employees }),
        );
        if (result.data.employees && result.data.employees.length > 1) {
          dispatch(
            uiActions.updateEmployeesSetupStatus(
              EMPLOYEES_SETUP_STATUSES.HAS_EMPLOYEES,
            ),
          );
        } else {
          dispatch(
            uiActions.updateEmployeesSetupStatus(
              EMPLOYEES_SETUP_STATUSES.NO_EMPLOYEES,
            ),
          );
        }
      } else {
        //  @vijay
        //  dispatch the failure with msg as server error
        //  Check below - we will handle all this site wide.
      }
    } catch (error) {
      homeProcessError(error, dispatch, actions);
    }
  };
};

export const getCoWorkerInfoAction = (props) => {
  //  IN this API RESPONSE I need
  //  User Info
  //  Carrots Given
  //  Carrots received
  //  birthDate, hiredDate, department and image
  Logger('getCoWorkerInfoAction', false);
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${apiEndpoints.PROFILE_INFO}${props._id}`,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        dispatch(
          actions.getCoWorkerInfoSuccess({ ...result.data, _id: props._id }),
        );
        //  MUTHU change needed
      } else {
        // @vijay
        // dispatch the failure with msg as server error
        //  Check below - we will handle all this site wide.
      }
    } catch (error) {
      homeProcessError(error, dispatch, actions);
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_INFO_SUCCESS: {
      const coworkers = action.coworkers.map((worker) => serializeUser(worker));
      return { ...state, coworkers, error: null };
    }
    case GET_COWORKER_INFO_SUCCESS:
      return {
        ...state,
        error: null,
        coworkers: state.coworkers
          ? state.coworkers.map((coworker) => {
              if (action.coworker._id === coworker._id) {
                return serializeUser({
                  ...coworker,
                  profile: { ...coworker.profile, ...action.coworker },
                });
              }
              return coworker;
            })
          : [action.coworker],
      };
    case REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
