import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Input from 'react-phone-number-input/input';

import ThemeV2 from '../../../componentsV2/theme';

export const PhoneInput = styled(Input)`
  width: 100%;
  border-radius: 10px;
  margin-top: 18px;
  border: solid 1px ${ThemeV2.palette.lightGray1};
  background-color: ${ThemeV2.palette.white};
  color: ${ThemeV2.palette.darkText};
  font-family: ${ThemeV2.typography.fontFamily};
  font-size: 14px;
  box-sizing: border-box;
  padding: 13px 16px;
  transition: all;
  font-weight: ${ThemeV2.typography.fontWeightRegular};

  &:focus,
  &:active {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: currentColor;
    opacity: 0.42;
  }
`;
