import { useQuery } from 'react-query';
import { GOOGLE_ONE_TAP_AUTH } from '../../../constants/endpoints';
import { makeAPICallWithDataReturn } from '../../utils';
import {
  AuthSuccessResponse,
  AxiosAuthError,
} from '../../../interfaces/Auth/ResponseTypes';

export const useAuthenticateGoogleOneTapCode = (
  makeRequest = true,
  code = '',
) => {
  return useQuery<AuthSuccessResponse, AxiosAuthError>(
    GOOGLE_ONE_TAP_AUTH,
    () => makeAPICallWithDataReturn(GOOGLE_ONE_TAP_AUTH, { idToken: code }),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};
