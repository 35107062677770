// @ts-nocheck TODO: type issues need to be fixed in this file
const processErrorResponse = (response, renames) => {
  let error = '';
  if (response.code === 100) {
    error = {};
    const errorkeys = Object.keys(response.message);
    for (let key in errorkeys) {
      //eslint-disable-line
      let assignKey = errorkeys[key];
      // If you want to rename the field
      // example - first_name => firstName
      if (renames) {
        if (Object.keys(renames).indexOf(assignKey) !== -1) {
          assignKey = renames[assignKey]; //eslint-disable-line
        }
      }
      error[assignKey] = response.message[errorkeys[key]].msg;
    }
  } else if (response.code === 101) {
    // do something
    error = response.message.join(', ');
  } else if (response.code === 102) {
    // Its a string
    error = response.message;
  } else {
    error = 'Server Error';
  }
  return error;
};

export default processErrorResponse;

export const ErrorMessages = {
  networkError: 'Network Error.',
  serverError: 'Server down. Please try again later.',
};
