import moment from 'moment';
import routesList from '../../../aV2/routes/routesList';
import { BenchmarkStatus } from '../../../interfaces/analytics/benchmarking/interfaces';

export const DATA_IN_PROGRESS = 'Data in progress';
export const PEOPLE = 'People';
export const RECOGNITION = 'Recognition';
export const ANALYTICS_UPGRADE_PLAN_HEADING = 'Upgrade for Assembly Insights';
export const ANALYTICS_UPGRADE_PLAN_TEXT =
  'See insights of how people are using the :assemblyName Assembly';
export const ANALYTICS_UPGRADE_PLAN_BUTTON_TEXT = 'Upgrade for Insights';
export const ANALYTICS_UPGRADE_PLAN_LINK_TEXT = 'See example data below';

export const PREVIOUS_COMPARISON_STRING = 'vs previous 30 days';
export const CORE_VALUES = 'Core Values';
export const BADGES = 'Badges';
export const LAST_7_DAYS = 'Last 7 days';
export const LAST_14_DAYS = 'Last 14 days';
export const LAST_30_DAYS = 'Last 30 days';
export const THIS_MONTH = 'This month';
export const THIS_QUARTER = 'This quarter';
export const THIS_YEAR = 'This year';
export const WEEKLY_VIEW = 'Weekly View';
export const MONTHLY_VIEW = 'Monthly View';
export const LAST_WEEK = 'Last Week';
export const LAST_TWO_WEEKS = 'Last 2 Weeks';
export const LAST_FOUR_WEEKS = 'Last 4 Weeks';
export const LAST_THREE_MONTHS = 'Last 3 Months';
export const LAST_SIX_MONTHS = 'Last 6 Months';
export const LAST_TWELVE_MONTHS = 'Last 12 Months';
export const MONTH_TO_DATE = 'Month-to-date';
export const QUARTER_TO_DATE = 'Quarter-to-date';
export const YEAR_TO_DATE = 'Year-to-date';
export const ALL_TIME = 'All-time';

export const weeklyTimelineDropdownItems = [
  {
    id: 1,
    value: LAST_WEEK,
  },
  {
    id: 2,
    value: LAST_TWO_WEEKS,
  },
  {
    id: 3,
    value: LAST_FOUR_WEEKS,
  },
  {
    id: 4,
    value: LAST_THREE_MONTHS,
  },
  {
    id: 5,
    value: LAST_SIX_MONTHS,
  },
  {
    id: 6,
    value: LAST_TWELVE_MONTHS,
  },
  {
    id: 7,
    value: MONTH_TO_DATE,
  },
  {
    id: 8,
    value: QUARTER_TO_DATE,
  },
  {
    id: 9,
    value: YEAR_TO_DATE,
  },
  {
    id: 10,
    value: ALL_TIME,
  },
];

export const valueDropdownItems = [
  {
    id: 1,
    value: CORE_VALUES,
    name: 'coreValues',
  },
  {
    id: 2,
    value: BADGES,
    name: 'badges',
  },
];

export const viewDropdownItems = [
  {
    id: 1,
    value: WEEKLY_VIEW,
    name: 'weekly',
  },
  {
    id: 2,
    value: MONTHLY_VIEW,
    name: 'monthly',
  },
];

export const PEOPLE_SUMMARY_TEMPLATE = [
  {
    key: 'invited',
    headerText: 'Invited',
    subText: 'Invite more people',
    subTextLink: routesList.ADMIN_USER_INVITE,
    headerTooltip: `The total number of employees ever invited to your Assembly –
      this includes requests, queued, and invited members.`,
  },
  {
    key: 'active',
    headerText: 'Members',
    subText: 'of invites accepted',
    headerTooltip:
      'All members with a currently active account in your Assembly.',
  },
  {
    key: 'monthlyActive',
    headerText: 'Monthly Active',
    subText: 'of members',
    headerTooltip:
      'All the members who have used your Assembly in the last month.',
  },
  {
    key: 'powerUsers',
    headerText: 'Power users',
    subText: 'of monthly active',
    headerTooltip:
      'All members who have been active 4 or more times in the last month.',
  },
];
export const RECOGNITION_SUMMARY_TEMPLATE = [
  {
    key: 'given',
    headerText: 'Recognition Given',
    subText: 'gave',
    headerTooltip: `If a member recognizes another or a group, it's counted here uniquely`,
  },
  {
    key: 'received',
    headerText: 'Recognition Received',
    subText: 'received',
    headerTooltip: `If a member receives recognition, it's counted here in aggregate`,
  },
  {
    key: 'comments',
    headerText: 'Comments',
    subText: 'commented',
    headerTooltip: `If a member comments on a post, it's counted here in aggregate`,
  },
  {
    key: 'reactions',
    headerText: 'Reactions',
    subText: 'added reactions',
    headerTooltip: `If a member reacts on a post, it's counted here in aggregate`,
  },
];
export const PROFILE_INFORMATION_TEMPLATE = [
  {
    key: 'birthday',
    headerText: 'Birthday Adds',
  },
  {
    key: 'anniversary',
    headerText: 'Anniversary Adds',
  },
  {
    key: 'departments',
    headerText: 'Department Adds',
  },
  {
    key: 'profileImages',
    headerText: 'Profile Image Adds',
  },
];
export const PEOPLE_SUMMARY_INFO_HEADER =
  'How many people are in the :assemblyName Assembly?';
export const PEOPLE_SUMMARY_INFO_SUB_HEADER =
  'Keep track of how your team is using Assembly.';
export const PROFILE_INFO_HEADER = 'Profile information';
export const PROFILE_INFO_SUB_HEADER =
  'See how many people on your team have completed their profiles. The more you add, the more there is to celebrate!';
export const RECOGNITION_SUMMARY_INFO_HEADER =
  'What’s happening on the :assemblyName Assembly?';
export const RECOGNITION_SUMMARY_INFO_SUB_HEADER =
  'Showing your Assembly’s activity data for the last 30 days.';
export const PEOPLE_SUMMARY_GRAPH_HEADER = 'People over time on Assembly';
export const PEOPLE_SUMMARY_GRAPH_SUB_HEADER =
  'Track how your team is using Assembly over time. Filter by week, month, year, or a custom time period.';
export const RECOGNITION_SUMMARY_GRAPH_HEADER =
  'Recognition over time on :assemblyName Assembly';
export const RECOGNITION_SUMMARY_GRAPH_SUB_HEADER =
  'Track your team’s recognition and engagement over time. Filter by week, month, year, or a custom time period.';
export const CORE_VALUES_BADGES_GRAPH_HEADER =
  'Core values & badges over time on :assemblyName Assembly';
export const CORE_VALUES_BADGES_GRAPH_SUB_HEADER =
  'See which values and traits your team exemplifies in their work and recognition.';
export const ANALYTICS_GRAPH_ERROR_HEADER = 'Whoops, something went wrong.';
export const ANALYTICS_GRAPH_ERROR_SUBHEADER = 'Please reload the page.';
export const ANALYTICS_GRAPH_LOADING_SUBHEADER = 'Just a second...';
export const ANALYTICS_GRAPH_LOADING_TEXT = 'We’re fetching your data';
export const ANALYTICS_GRAPH_PLACEHOLDER_IMAGE_ALT_TEXT =
  'analytics-graph-placeholder';
export const ANALYTICS_GRAPH_LINES_DISABLED_HEADING =
  'You turned off all data points';
export const ANALYTICS_GRAPH_LINES_DISABLED_TEXT =
  'Turn back on data points above to see your data.';
export const ANALYTICS_GRAPH_NO_DATA_HEADING =
  'You don’t have enough data, yet';
export const ANALYTICS_GRAPH_NO_DATA_TEXT =
  'When there is enough data to show, you will see it here';

export const convertNumberToPercentage = (num: number, decimalPoint = 0) =>
  parseFloat((num * 100).toFixed(decimalPoint));

export const calculateValues = (
  {
    total,
    percentage,
    previous,
    engagementRank = BenchmarkStatus.NEED_A_BOOST,
  } = {
    total: 0,
    percentage: 0,
    previous: 0,
  },
  { headerText, subText, subTextLink, headerTooltip } = {
    headerText: '',
    subText: '',
    subTextLink: '',
    headerTooltip: '',
  },
  error = false,
  circularProgress = false,
) => {
  if (circularProgress) {
    return {
      headerText: `${total} ${headerText}`,
      dataNumber: convertNumberToPercentage(percentage, 0),
      percentChange: '--',
      comparisonString: '',
      subText: '',
      engagementRank,
    };
  }
  return {
    dataNumber: total,
    headerText,
    subTextLink: subTextLink || undefined,
    percentChange: error
      ? '--'
      : convertNumberToPercentage(previous, 1).toString(),
    comparisonString: PREVIOUS_COMPARISON_STRING,
    subText: subTextLink
      ? subText
      : `${
          error ? '--' : convertNumberToPercentage(percentage, 1)
        }% ${subText}`,
    headerTooltip,
    engagementRank,
  };
};

export const serializeGraphData = (
  graphData:
    | {
        data: { lines: { label: string; data: { x: number; y: number }[] }[] };
      }
    | undefined,
) => {
  if (!graphData?.data?.lines) {
    return null;
  }
  const {
    data: { lines },
  } = graphData;
  return lines.map(({ label, data }) => ({
    id: label,
    data: data.map(({ x, y }, index) => ({
      x: x.toString(),
      y,
      isLastTimeDurationData: data.length === index + 1,
    })),
  }));
};

export const getTimeStamps = (
  selectedTimelineId: number,
  selectedViewId: number,
  assemblyCreatedAt?: string,
): [number, number] => {
  let fromTime = moment(new Date()).utc();
  const toTime = moment(new Date()).utc();
  switch (selectedTimelineId) {
    case 1: {
      break;
    }
    case 2: {
      fromTime.date(fromTime.date() - 7);
      break;
    }
    case 3: {
      fromTime.date(fromTime.date() - 21);
      break;
    }
    case 4: {
      fromTime.date(1);
      fromTime.month(fromTime.month() - 2);
      break;
    }
    case 5: {
      fromTime.date(1);
      fromTime.month(fromTime.month() - 5);
      break;
    }
    case 6: {
      fromTime.date(1);
      fromTime.month(fromTime.month() - 11);
      break;
    }
    case 7: {
      fromTime.date(1);
      break;
    }
    case 8: {
      fromTime.month((fromTime.quarter() - 1) * 3);
      fromTime.date(1);
      break;
    }
    case 9: {
      fromTime.date(1);
      fromTime.month(0);
      break;
    }
    case 10: {
      if (assemblyCreatedAt) {
        fromTime = moment(assemblyCreatedAt);
      }
      break;
    }
    default: {
      break;
    }
  }
  if (selectedTimelineId !== 10 && selectedViewId === 1) {
    fromTime.day(0);
  }
  return [parseInt(fromTime.format('x'), 10), parseInt(toTime.format('x'), 10)];
};

export const BENCHMARK_INFO_MODAL_TITLE = 'Tips for engagement';
export const BENCHMARK_INFO_MODAL_BODY_TITLE =
  'How to boost engagement in Assembly:';
export const BENCHMARK_INFO_MODAL_FOOTER_BUTTON_TEXT = 'Got it!';

export const BENCHMARK_INFO_MODAL_LIST_1_HEADING = 'Lead by example';
export const BENCHMARK_INFO_MODAL_LIST_1_TEXT =
  'Make sure your admins and managers are giving timely, high quality recognition to a broad group of your employees.';
export const BENCHMARK_INFO_MODAL_LIST_1_LINK = 'Give Recognition';

export const BENCHMARK_INFO_MODAL_LIST_2_HEADING =
  'Promote and give meaningful recognition';
export const BENCHMARK_INFO_MODAL_LIST_2_TEXT =
  'We recommend following the RISE formula (Regular, Immediate, Specific, Encouraging). Learn more on our blog!';
export const BENCHMARK_INFO_MODAL_LIST_2_LINK = 'View blog post';

export const BENCHMARK_INFO_MODAL_LIST_3_HEADING =
  'If you allow peer-to-peer recognition: check in with your team';
export const BENCHMARK_INFO_MODAL_LIST_3_TEXT =
  // eslint-disable-next-line max-len
  'Encourage them to recognize each other. Prompt your team to think of something great a co-worker did this week, and have them post about it.';
export const BENCHMARK_INFO_MODAL_LIST_3_LINK = 'Give Recognition';

export const BENCHMARK_INFO_MODAL_LIST_4_HEADING =
  'Book a call with an Account Manager';
export const BENCHMARK_INFO_MODAL_LIST_4_TEXT =
  // eslint-disable-next-line max-len
  'Assembly has dedicated Account Managers, who are experts on making sure you build a robust culture of recognition. Chat about specifics, set up a workshop, and more.';
export const BENCHMARK_INFO_MODAL_LIST_4_LINK = 'Book a call';
