import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Token } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import useFetch from '../../hooks/useFetch';
import {
  SWITCH_ACCOUNT_PREVIEW,
  SWITCH_ACCOUNT,
} from '../../hooks/useFetch/endpoints';
import {
  ApiResponseSuccess,
  ApiResponseError,
} from '../../interfaces/identityManager/common';
import PlanUpgradeSelectTierModal from '../../atomic/organism/PlanUpgradeSelectTierModal';
import PlanUpgradeAddPaymentModalContainer from '../PlanUpgradeAddPaymentModalContainer';
import {
  templateHeading,
  tiersRadioOptions,
} from '../../Utils/data/planUpgradeModal';
import PlanUpgradeReviewModal from '../../atomic/organism/PlanUpgradeReviewModal';
import Modal from '../../atomic/atoms/Modal';
import LoadingPulse from '../../screens/shared/LoadingPulse';
import { getCurrentPlanRequest } from '../../modules/admin/billing/account';
import { getAllPlans } from '../../modules/admin/billing/account/selector';
import { showErrorMessage } from '../../Utils/flashHandler';

interface PlanUpgradeModalContainerProps {
  onModalClose(): void;
  showIntegrationSubText?: boolean;
}

interface PlanUpgradeModalCardDetails {
  selectedTier: string;
  token: Token;
  name: string;
  promoCode: string;
}

interface PreviewPlanUpgrade {
  newPlanName: string;
  activeEmployeeCount: number;
  renewalDate: string;
  price: string;
  teamMembersString: string;
}

const PlanUpgradeModalContainer = (props: PlanUpgradeModalContainerProps) => {
  const { onModalClose, showIntegrationSubText = false } = props;
  const dispatch = useDispatch();
  const availablePlans = useSelector(getAllPlans);
  const tiersRadioOptionsData = tiersRadioOptions(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    availablePlans.tier4,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    availablePlans.tier3,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    availablePlans.tier2,
  );
  const tierNames = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tier1: availablePlans.tier1.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tier2: availablePlans.tier2.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tier3: availablePlans.tier3.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tier4: availablePlans.tier4.name,
  };
  const defaultTier = tiersRadioOptionsData[0].value;
  const [selectedTier, setSelectedTier] = useState<string>(defaultTier);
  const [planUpgradeDetails, setPlanUpgradeDetails] =
    useState<Partial<PlanUpgradeModalCardDetails> | null>(null);
  const [previewPlanUpgrade, setPreviewPlanUpgrade] =
    useState<PreviewPlanUpgrade | null>(null);
  const [isReviewButtonsLoading, setIsReviewButtonsLoading] =
    useState<boolean>(false);

  const cardLast4Digits = planUpgradeDetails?.token?.card?.last4 || '';

  const handleError = useCallback(
    (error: ApiResponseError) => {
      const { message } = error;
      dispatch(showErrorMessage(message));
    },
    [dispatch],
  );

  const onGetSwitchAccountPreview = useCallback(
    (response: ApiResponseSuccess) => {
      const newPlanName = response?.data?.data?.preview?.plan?.name || '';
      const activeEmployeeCount =
        response?.data?.data?.preview?.activeEmployeeCount || 0;
      const renewalDate = moment().add(1, 'month').format('MMMM D, YYYY');
      const currency = '$';
      const price =
        currency +
        response?.data?.data?.preview?.stripePreview?.subscriptionCost;
      const teamMembersString = `${activeEmployeeCount} team member${
        activeEmployeeCount !== 1 ? 's' : ''
      }`;
      setPreviewPlanUpgrade({
        newPlanName,
        activeEmployeeCount,
        renewalDate,
        price,
        teamMembersString,
      });
    },
    [],
  );

  const { makeRequest: getSwitchAccountPreview } = useFetch({
    apiEndpoint: SWITCH_ACCOUNT_PREVIEW,
    onSuccess: onGetSwitchAccountPreview,
    onFailure: handleError,
  });

  useEffect(() => {
    getSwitchAccountPreview({ data: { planUniqueId: selectedTier } });
  }, [getSwitchAccountPreview, selectedTier]);

  const handleSelectTierNextClick = useCallback(() => {
    setPlanUpgradeDetails({ ...planUpgradeDetails, selectedTier });
  }, [planUpgradeDetails, selectedTier]);

  const handleAddPaymentNextClick = useCallback(
    ({ token, name, promoCode }) => {
      setPlanUpgradeDetails({
        ...planUpgradeDetails,
        selectedTier,
        token,
        name,
        promoCode,
      });
    },
    [planUpgradeDetails, selectedTier],
  );

  const handleAddPaymentNevermindClick = useCallback(() => {
    setPlanUpgradeDetails({ ...planUpgradeDetails, selectedTier: undefined });
  }, [planUpgradeDetails]);

  const onSwitchAccountSuccess = useCallback(() => {
    dispatch(getCurrentPlanRequest());
    onModalClose();
    setIsReviewButtonsLoading(false);
  }, [dispatch, onModalClose]);

  const onSwitchAccountFailure = useCallback(
    (error: ApiResponseError) => {
      setIsReviewButtonsLoading(false);
      handleError(error);
    },
    [handleError],
  );

  const { makeRequest: switchAccount } = useFetch({
    apiEndpoint: SWITCH_ACCOUNT,
    onSuccess: onSwitchAccountSuccess,
    onFailure: onSwitchAccountFailure,
  });

  const handleReviewNextClick = useCallback(() => {
    if (planUpgradeDetails) {
      const { promoCode, token } = planUpgradeDetails;
      setIsReviewButtonsLoading(true);
      switchAccount({
        data: {
          planUniqueId: selectedTier,
          coupon: promoCode,
          token,
        },
      });
    }
  }, [planUpgradeDetails, selectedTier, switchAccount]);

  const handleReviewNevermindClick = useCallback(() => {
    setPlanUpgradeDetails({
      selectedTier,
    });
  }, [selectedTier]);

  if (!planUpgradeDetails?.selectedTier) {
    return (
      <PlanUpgradeSelectTierModal
        heading={templateHeading}
        onNextClick={handleSelectTierNextClick}
        showIntegrationSubText={showIntegrationSubText}
        tier={selectedTier}
        tierNames={tierNames}
        radioOptionsData={tiersRadioOptionsData}
        onRadioChange={setSelectedTier}
        onModalClose={onModalClose}
        isButtonsLoading={false}
      />
    );
  }
  if (!planUpgradeDetails?.token) {
    return (
      <PlanUpgradeAddPaymentModalContainer
        heading={templateHeading}
        onNevermindClick={handleAddPaymentNevermindClick}
        onNextClick={handleAddPaymentNextClick}
        onModalClose={onModalClose}
      />
    );
  }
  if (previewPlanUpgrade) {
    return (
      <PlanUpgradeReviewModal
        heading={templateHeading}
        onNevermindClick={handleReviewNevermindClick}
        onNextClick={handleReviewNextClick}
        isButtonsLoading={isReviewButtonsLoading}
        onModalClose={isReviewButtonsLoading ? () => {} : onModalClose}
        newPlanName={previewPlanUpgrade.newPlanName}
        renewalDate={previewPlanUpgrade.renewalDate}
        price={previewPlanUpgrade.price}
        cardLast4Digits={cardLast4Digits}
        teamMembersString={previewPlanUpgrade.teamMembersString}
      />
    );
  }
  return (
    <Modal isOpen handleClose={() => {}}>
      <LoadingPulse />
    </Modal>
  );
};

export default PlanUpgradeModalContainer;
