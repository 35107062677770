// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import noRequireAuth from '../../containers/hoc/NoAuthentication';
import requireAuth from '../../containers/hoc/Authentication';
import SignInWithSAML from '../screens/Login/signInWithSAML';
import ForgotPassword from '../screens/Login/forgotPassword';
import ForgotPasswordSuccess from '../screens/Login/forgotPassword/EmailSent';
import ForgotPasswordVerify from '../screens/Login/forgotPassword/VerifyToken';
import ResetPassword from '../screens/Login/ResetPassword';
import ResetPasswordSuccess from '../screens/Login/resetPassword/ResetPasswordSuccess';

import EnterEmail from '../screens/Onboarding/EnterEmail';
import EnterCode from '../screens/Onboarding/EnterCode';
import EnterCompanyName from '../screens/Onboarding/EnterCompanyName';

import EmployeeEnterEmail from '../screens/EmployeeSignup/EnterEmail';
import EmployeeSetProfile, {
  SET_PROFILE_TYPES,
} from '../screens/EmployeeSignup/EnterProfile';
import EmployeeSetDetails from '../screens/EmployeeSignup/EnterDetails';
import VerifyMagicLink from '../screens/EmployeeSignup/VerifyMagicLink';
import SignInWithSlack from '../screens/SlackOnboarding/SignInWithSlack';
import RoutesList from './routesList';

import SignupSuccess from '../screens/SlackOnboarding/SignupSuccess';
import EmailSignIn from '../screens/SlackOnboarding/EmailSignin';

const SignInWithSAMLHOC = noRequireAuth(SignInWithSAML);
const EnterEmailHOC = noRequireAuth(EnterEmail);

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${RoutesList.SIGNUP_SUCCESS}/:userId`}
        component={SignupSuccess}
      />
      <Route
        exact
        path={RoutesList.EMAIL_SIGNIN}
        // component={EmailSignIn}
        render={(props) => <EmailSignIn {...props} />}
      />
      <Route
        exact
        path={RoutesList.EMAIL_SIGNUP}
        // component={EmailSignUp}
        render={(props) => <EmailSignIn {...props} signUp />}
      />
      <Route path={RoutesList.SIGN_IN} component={SignInWithSAMLHOC} />
      <Route
        path={RoutesList.FORGOT_PASSWORD_SUCCESS}
        exact
        component={noRequireAuth(ForgotPasswordSuccess)}
      />
      <Route
        path={RoutesList.FORGOT_PASSWORD_VERIFY}
        component={noRequireAuth(ForgotPasswordVerify)}
      />
      <Route
        path={RoutesList.FORGOT_PASSWORD}
        component={noRequireAuth(ForgotPassword)}
      />
      <Route
        exact
        path={RoutesList.RESET_PASSWORD_SUCCESS}
        component={noRequireAuth(ResetPasswordSuccess)}
      />
      <Route
        exact
        path={`${RoutesList.RESET_PASSWORD}/:userId`}
        component={noRequireAuth(ResetPassword)}
      />
      <Route
        path={`${RoutesList.EMPLOYEE_VERIFY_MAGIC_LINK}/:magicLink`}
        exact
        component={noRequireAuth(VerifyMagicLink)}
      />
      <Route
        path={`${RoutesList.EMPLOYEE_SET_PROFILE_FROM_INVITE}/:inviteToken`}
        exact
        render={(props) => (
          <EmployeeSetProfile
            {...props}
            source={SET_PROFILE_TYPES.FROM_INVITE_TOKEN}
          />
        )}
      />
      <Route
        path={`${RoutesList.EMPLOYEE_SET_PROFILE}/:userId`}
        exact
        render={(props) => (
          <EmployeeSetProfile
            {...props}
            source={SET_PROFILE_TYPES.FROM_USER_ID}
          />
        )}
      />
      <Route path={RoutesList.ENTER_EMAIL} component={EnterEmailHOC} />
      {/* <Route
        exact
        path={RoutesList.IM_AUTHORIZE}
        component={IdentitySSORedirectPageContainer}
      /> */}
      <Route
        exact
        path={RoutesList.SLACK_USER_AUTH}
        render={(props) => <SignInWithSlack {...props} userAuth />}
      />
      <Route
        exact
        path={RoutesList.SLACK_SIGN_IN_V2}
        render={(props) => <SignInWithSlack {...props} signInV2 />}
      />
      <Route
        path={`${RoutesList.ENTER_CODE}/:userId`}
        component={noRequireAuth(EnterCode)}
      />
      <Route
        path={`${RoutesList.ENTER_COMPANY_NAME}/:userId`}
        component={noRequireAuth(EnterCompanyName)}
      />
      <Route
        path={`${RoutesList.EMPLOYEE_ENTER_EMAIL}/:companyId`}
        component={EmployeeEnterEmail}
      />
      <Route
        path={`${RoutesList.EMPLOYEE_SET_DETAILS}`}
        component={requireAuth(EmployeeSetDetails)}
      />
    </Switch>
  );
};

export default Routes;
