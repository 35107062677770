// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, string, node } from 'prop-types';
import CircleIndicator from '../../atoms/CircleIndicator';
import Body from '../../atoms/Body';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  indicator: {
    position: 'absolute',
    top: '5px',
    left: '4px',
  },
  bodyText: {
    position: 'relative',
    paddingLeft: '24px',
  },
});

const ValidationText = ({
  checked,
  children,
  className: parentClassName,
  ...rest
}) => {
  const classes = useStyles();
  const className = `${classes.root} ${parentClassName}`;

  return (
    <div className={className} {...rest}>
      <CircleIndicator checked={checked} className={classes.indicator} />
      <Body variant="body2" className={classes.bodyText}>
        {children}
      </Body>
    </div>
  );
};

ValidationText.propTypes = {
  checked: bool,
  className: string,
  children: node.isRequired,
};

ValidationText.defaultProps = {
  checked: false,
  className: '',
};

export default ValidationText;
