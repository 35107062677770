// @ts-nocheck TODO: type issues need to be fixed in this file
import * as microsoftTeams from '@microsoft/teams-js';
import classNames from 'classnames';
import get from 'lodash/get';
import qs from 'qs';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BotManagerAuthorize from '../atomic/pages/BotManagerAuthorize/BotManagerAuthorize';
import IdentityManagerAuthorize from '../atomic/pages/IdentityManagerAuthorize/IdentityManagerAuthorize';
import OnboaringRoutes from '../aV2/routes';
import RoutesList from '../aV2/routes/routesList';
import {
  CREATE_PASSWORD,
  GOOGLE_ONE_TAP_AUTH,
  USER_ACCOUNT_SETUP,
} from '../constants/routes';
import requireAuth from '../containers/hoc/Authentication';
import Authorization from '../containers/hoc/Authorization';
import SuperAdminAuthorization from '../containers/hoc/SuperAdminAuthorization';
import { ADMIN_ROLES } from '../containers/Roles';
import Signout from '../containers/SignOut';
import CreatePasswordController from '../controllers/auth/CreatePasswordController';
import GoogleOneTapLoginController from '../controllers/auth/GoogleOneTapLoginController';
import SAMLAuthModalController from '../controllers/auth/SAMLAuthController/SAMLAuthModalController';
import MSTeamsTabConfig from '../controllers/MSTeams/MSTeamsTabConfig';
import UserSetupController from '../controllers/signup/UserSetupController';
import history from '../history';
import SSOAuthorize from '../pages/signin/SSOAuthorize/SSOAuthorize';
import SSOErrors from '../pages/signin/SSOErrors/SSOErrors';
import PageNotFound from '../screens/404';
import Navbar from '../screens/admin/shared/Navbar';
import Header from '../screens/Header';
import FlashBar from '../screens/header/FlashBar';
import AccountsDashboard from '../screens/superadmin/AccountsDashboard';
import AccountsDashboardV2 from '../screens/superadmin/AccountsDashboardV2';
import SuperAdminAnalytics from '../screens/superadmin/Analytics';
import Reports from '../screens/superadmin/Reports';
import { SSOTypes } from '../Utils/data/ssoManager/common';
/* ms-team imports */
import { isSourceMSTeams, MS_TEAMS, setAppSource } from '../Utils/msteams';
import { LoadableAdminComponent } from './chunks';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';

const AdminRole = Authorization(ADMIN_ROLES);
const SuperAdminRole = SuperAdminAuthorization();

const OnboardingRoutePaths = [
  RoutesList.SIGN_IN,
  RoutesList.FORGOT_PASSWORD,
  RoutesList.RESET_PASSWORD,
  RoutesList.ENTER_EMAIL,
  RoutesList.ENTER_CODE,
  RoutesList.ENTER_COMPANY_NAME,
  RoutesList.EMPLOYEE_ENTER_EMAIL,
  RoutesList.EMPLOYEE_VERIFY_MAGIC_LINK,
  RoutesList.EMPLOYEE_SET_PROFILE,
  RoutesList.EMPLOYEE_SET_PROFILE_FROM_INVITE,
  RoutesList.EMPLOYEE_SET_DETAILS,
  RoutesList.SLACK_ONBOARD,
  RoutesList.SIGNUP_SUCCESS,
  RoutesList.EMAIL_SIGNIN,
  RoutesList.EMAIL_SIGNUP,
  RoutesList.ADP_SIGNIN,
  RoutesList.SSO_SIGNIN_AUTHORIZE,
  RoutesList.SSO_SIGNUP_AUTHORIZE,
  RoutesList.SSO_ERRORS,
  USER_ACCOUNT_SETUP,
  GOOGLE_ONE_TAP_AUTH,
];

const RoutePathsWithoutNavbar = [
  RoutesList.MSTEAM_CONFIG,
  RoutesList.SSO_SIGNIN_AUTHORIZE_MODAL,
  RoutesList.SSO_SIGNUP_AUTHORIZE_MODAL,
  RoutesList.SAML_AUTHORIZE_MODAL,
  GOOGLE_ONE_TAP_AUTH,
];

class RoutesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menu: false };
  }

  componentDidMount() {
    const parsed = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const source = get(parsed, 'source');
    if (source === MS_TEAMS) {
      setAppSource(source);
    }
    if (isSourceMSTeams()) {
      microsoftTeams.initialize();
      /* To notify app loaded to hide loading indicator */
      microsoftTeams.appInitialization.notifyAppLoaded();
      microsoftTeams.appInitialization.notifySuccess();
    }
    console.log('>>>');
  }

  handleChangeValue = (value) => {
    this.setState({ menu: value });
  };

  render() {
    const pageContent = classNames({
      'readmin-page-content': true,
      // eslint-disable-next-line react/destructuring-assignment
      'menu-open': this.state.menu,
    });
    const pageContentAdmin = classNames({
      'readmin-page-content': true,
      'menu-open':
        this.props.history?.location?.pathname !== RoutesList.SIGN_IN,
    });
    const menu = true;
    return (
      <div>
        <FlashBar />
        {this.props.history?.location?.pathname !== RoutesList.SIGN_IN &&
        this.props.history?.location?.pathname !== '/404' ? (
          <div>
            <Switch>
              <Route
                path="/"
                render={() => <Navbar onChangeMenu={this.handleChangeValue} />}
              />

              {OnboardingRoutePaths.map((route) => (
                <Route key={route} path={route} render={() => null} />
              ))}
              {RoutePathsWithoutNavbar.map((route) => (
                <Route key={route} path={route} render={() => null} />
              ))}
              <Route path="/v2" render={() => null} />
              <Route path="/signout" render={() => null} />
              <Route
                render={() => <Header path={history.location.pathname} />}
              />
            </Switch>
          </div>
        ) : (
          <div>
            <Switch>
              <Route
                render={() => <Header path={history.location.pathname} />}
              />
            </Switch>
          </div>
        )}
        <div className={menu ? pageContentAdmin : pageContent}>
          <Switch>
            <Route
              exact
              path="/"
              component={requireAuth(AdminRole(LoadableAdminComponent))}
            />
            <Route
              exact
              path={RoutesList.SSO_SIGNIN_AUTHORIZE}
              render={() => <SSOAuthorize ssoType={SSOTypes.SIGN_IN} />}
            />
            <Route
              exact
              path={RoutesList.SSO_SIGNUP_AUTHORIZE}
              render={() => <SSOAuthorize ssoType={SSOTypes.SIGN_UP} />}
            />
            <Route
              exact
              path={RoutesList.SSO_SIGNIN_AUTHORIZE_MODAL}
              render={() => (
                <SSOAuthorize ssoType={SSOTypes.SIGN_IN} authInModal />
              )}
            />
            <Route
              exact
              path={RoutesList.SSO_SIGNUP_AUTHORIZE_MODAL}
              render={() => (
                <SSOAuthorize ssoType={SSOTypes.SIGN_UP} authInModal />
              )}
            />
            <Route
              exact
              path={RoutesList.SAML_AUTHORIZE_MODAL}
              component={SAMLAuthModalController}
            />
            <Route
              exact
              path={RoutesList.IM_AUTHORIZE}
              component={IdentityManagerAuthorize}
            />
            <Route exact path={RoutesList.SSO_ERRORS} component={SSOErrors} />
            <ProtectedRoute
              exact
              path={RoutesList.BOT_AUTHORIZE}
              adminCheck
              component={BotManagerAuthorize}
            />
            <Route path={RoutesList.SIGN_IN} component={OnboaringRoutes} />

            <Route
              path={RoutesList.FORGOT_PASSWORD}
              component={OnboaringRoutes}
            />
            <Route
              path={CREATE_PASSWORD}
              component={CreatePasswordController}
            />
            <Route
              path={RoutesList.RESET_PASSWORD}
              component={OnboaringRoutes}
            />

            <Route path={RoutesList.ENTER_EMAIL} component={OnboaringRoutes} />
            <Route
              path={RoutesList.SLACK_ONBOARD}
              component={OnboaringRoutes}
            />
            <Route path={RoutesList.ADP_SIGNIN} component={OnboaringRoutes} />
            <Route path={RoutesList.ENTER_CODE} component={OnboaringRoutes} />
            <Route
              path={RoutesList.ENTER_COMPANY_NAME}
              component={OnboaringRoutes}
            />
            <Route
              path={RoutesList.EMPLOYEE_ENTER_EMAIL}
              component={OnboaringRoutes}
            />
            <Route
              path={RoutesList.EMPLOYEE_VERIFY_MAGIC_LINK}
              component={OnboaringRoutes}
            />
            <Route
              path={RoutesList.EMPLOYEE_SET_PROFILE}
              component={OnboaringRoutes}
            />
            <Route
              path={RoutesList.EMPLOYEE_SET_PROFILE_FROM_INVITE}
              component={OnboaringRoutes}
            />
            <Route
              path={RoutesList.EMPLOYEE_SET_DETAILS}
              component={OnboaringRoutes}
            />
            <Route path={USER_ACCOUNT_SETUP} component={UserSetupController} />
            <Route
              path={GOOGLE_ONE_TAP_AUTH}
              component={GoogleOneTapLoginController}
            />
            <Route path="/signout" component={requireAuth(Signout)} />
            <Route
              path="/"
              component={requireAuth(AdminRole(LoadableAdminComponent))}
            />
            <Route
              path="/superadmin/reports"
              component={requireAuth(SuperAdminRole(Reports))}
            />
            <Route
              path="/superadmin/accounts/dashboard/v2"
              component={requireAuth(SuperAdminRole(AccountsDashboardV2))}
            />
            <Route
              path={RoutesList.SUPER_ADMIN_ANALYTICS}
              component={requireAuth(SuperAdminRole(SuperAdminAnalytics))}
            />
            <Route
              path="/superadmin/accounts/dashboard"
              component={requireAuth(SuperAdminRole(AccountsDashboard))}
            />
            <Route
              path={RoutesList.SIGNUP_SUCCESS}
              component={OnboaringRoutes}
            />
            <Route path={RoutesList.EMAIL_SIGNIN} component={OnboaringRoutes} />
            <Route path={RoutesList.EMAIL_SIGNUP} component={OnboaringRoutes} />
            <Route
              exact
              path={RoutesList.MSTEAM_CONFIG}
              component={MSTeamsTabConfig}
            />

            {/* <Route exact path="/404" component={PageNotFound} /> */}
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}
export default RoutesComponent;
