import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  CreateYourPasswordPayload,
  CreateYourPasswordProps,
} from '../../../atomic/organism/CreateYourPassword/interface';
import useCreateYourPasswordLogic from '../../../atomic/organism/CreateYourPassword/useCreateYourPasswordLogic';
import validationSchema from '../../../atomic/organism/CreateYourPassword/validationSchema';
import { SigninOptionsType } from '../../../atomic/organism/SigninOptions/interface';
import { DEFAULT_PATH } from '../../../constants/routes';

import {
  userSessionCreatedAction,
  handleUserSignin,
} from '../../../modules/Session';
import {
  useVerifyCreatePasswordTokenQuery,
  useGetLoginOptionsQuery,
  SAMLLoginOptionsType,
  useCreatePasswordQuery,
} from '../../../queries/Auth/createPassword';

type ParamTypes = {
  token: string;
};

const useCreatePassword = () => {
  const { token } = useParams<ParamTypes>();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [createPasswordType, setCreatePasswordType] = useState<
    string | undefined
  >(undefined);
  const [emailForToken, setEmailForToken] = useState<string | undefined>(
    undefined,
  );
  const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
  const [SAMLLoginOptions, setSAMLLoginOptions] = useState<
    SAMLLoginOptionsType | undefined
  >(undefined);

  if (!token) {
    push(DEFAULT_PATH);
  }

  const {
    data: verifyTokenData,
    error,
    isLoading,
  } = useVerifyCreatePasswordTokenQuery(!!token, token);

  const { data, isLoading: isLoginOptionsLoading } = useGetLoginOptionsQuery(
    !!emailForToken,
    emailForToken,
  );

  const { data: createPasswordData, isLoading: isCreatePasswordQueryLoading } =
    useCreatePasswordQuery(
      !!newPassword,
      verifyTokenData?.data.userId || '',
      newPassword,
    );

  useEffect(() => {
    if (verifyTokenData) {
      setEmailForToken(verifyTokenData.data.email);
    }
  }, [verifyTokenData]);

  useEffect(() => {
    if (data) {
      if (!data.password) {
        push(DEFAULT_PATH);
      } else if (data.saml) {
        setCreatePasswordType(SigninOptionsType.SAML_WITH_CREATE_PASSWORD);
        setSAMLLoginOptions(data.saml);
      } else {
        setCreatePasswordType(
          SigninOptionsType.CREATE_PASSWORD_WITH_NO_PREFERRED_SSO,
        );
      }
    }
  }, [data, push]);

  useEffect(() => {
    if (createPasswordData) {
      const { jwtToken, user } = createPasswordData.data;
      if (user && jwtToken) {
        dispatch(userSessionCreatedAction(user, jwtToken));
        handleUserSignin(user);
      }
    }
  }, [createPasswordData, dispatch, error]);

  const tokenInvalid = error?.response?.data.code === 102;

  const handleSaveButtonClick = useCallback(
    (val: CreateYourPasswordPayload) => {
      setNewPassword(val.password);
    },
    [],
  );
  const { models, operations } = useCreateYourPasswordLogic();
  const { validationTexts, strength } = models;
  const { validateFormikForm } = operations;

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    validate: validateFormikForm,
    onSubmit: handleSaveButtonClick,
  });

  const createYourPasswordProps: CreateYourPasswordProps = {
    formik,
    validationTexts,
    strength,
    isExpandOnFocusEnabled: true,
    isLoading: isCreatePasswordQueryLoading,
  };

  return {
    isLoading: isLoading || isLoginOptionsLoading,
    tokenInvalid,
    createPasswordType,
    SAMLLoginOptions,
    createYourPasswordProps,
  };
};

export default useCreatePassword;
