import styled from 'styled-components';

import OrDivider from '../../molecules/OrDivider';
import SigninSubText from '../../molecules/SigninSubText';
import Button from '../../atoms/Button';

export const StyledDivider = styled(OrDivider)`
  width: 100%;
  margin: 24px 0;
  > div:nth-child(odd) {
    width: unset;
    flex: 1;
  }
`;

export const StyledSigninSubText = styled(SigninSubText)`
  margin-top: 24px;
  justify-content: center;
`;

export const StyledSubmitButton = styled(Button)`
  margin-top: 12px;
`;
