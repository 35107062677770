import React, { Fragment, ReactNode, useRef } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import Body from '../../atoms/Body';
import Radio from '../../atoms/Radio/index';
import { StyledFlexContainer, StyledIconInfo, ExtraContent } from './styles';
import ThemeV2 from '../../../componentsV2/theme';

import UpgradeOptionComponent from '../UpgradeInfo';
import { IconInfoProps } from '../IconInfo';
import { FlowsOtherMultipleChoiceInput } from '../../atoms/FlowsOtherMultipleChoiceInput';

const useStyles = makeStyles({
  root: {
    color: ThemeV2.palette.gray8,
    fontSize: '14px',
    margin: 0,
    paddingBottom: '16px',
    alignItems: 'center',

    '& .MuiRadio-root': {
      padding: '0',
      marginRight: '8px',
      '& .MuiSvgIcon-root': {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        width: (props) => props.radioSize,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        height: (props) => props.radioSize,
      },
    },
  },
});

export interface RadioOptionProps {
  value: string;
  label: string;
  labelHeader?: string;
  disabled?: boolean;
  upgradeInfo?: {
    upgradeText: string;
    onUpgradeClick?: () => void;
    toolTipText: string;
  };
  iconInfo?: IconInfoProps;
  children?: ReactNode;
  inlineChildren?: ReactNode;
}

interface RadioGroupProps {
  value: string;
  className?: string;
  name: string;
  radioOptions: RadioOptionProps[];
  radioSize?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  dataTestId?: string;
  labelColor?: string;
  labelHeaderVariant?: string;
  labelVariant?: string;
  onOtherInputValueChange?: (newValue: string) => void;
  otherInputValue?: string;
}

const CustomRadioGroup = ({
  labelColor = 'gray8',
  labelHeaderVariant = 'body2Bold',
  labelVariant = 'body2',
  name,
  value,
  radioSize = '16px',
  radioOptions,
  onChange,
  onFocus,
  onOtherInputValueChange,
  otherInputValue,
  className,
}: RadioGroupProps) => {
  const otherInputRef = useRef<HTMLInputElement>(null);

  const { root } = useStyles({ radioSize });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string,
  ) => {
    if (newValue === 'other') {
      otherInputRef.current?.focus();
    }
    onChange(event);
  };

  return (
    <RadioGroup
      data-testid="radioGroup"
      className={className}
      aria-label={name}
      name={name}
      value={value}
      onChange={handleChange}
    >
      {radioOptions.map(
        ({
          disabled: optionDisabled,
          label: optionLabel,
          value: optionValue,
          labelHeader: optionLabelHeader,
          upgradeInfo,
          iconInfo,
          children,
          inlineChildren,
        }) => {
          const formLabel = (
            <Body
              variant={labelVariant}
              color={labelColor}
              disabled={optionDisabled}
            >
              {optionLabelHeader && (
                <Body variant={labelHeaderVariant} color={labelColor} inline>
                  {optionLabelHeader}
                </Body>
              )}
              {optionLabel}
              {upgradeInfo && (
                <UpgradeOptionComponent
                  onUpgradeClick={upgradeInfo.onUpgradeClick}
                  upgradeText={upgradeInfo.upgradeText}
                  toolTipText={upgradeInfo.toolTipText}
                />
              )}
              {iconInfo && value === optionValue && (
                <StyledIconInfo {...iconInfo} />
              )}
            </Body>
          );
          return (
            <Fragment key={optionValue}>
              <StyledFlexContainer
                alignItems="center"
                className="radio-option-container"
                key={optionValue}
              >
                <FormControlLabel
                  value={optionValue}
                  control={<Radio />}
                  data-testid={optionLabel}
                  label={formLabel}
                  disabled={optionDisabled}
                  classes={{ root }}
                />
                {optionValue === 'other' && (
                  <FlowsOtherMultipleChoiceInput
                    isBorderVisible={value === 'other'}
                    onChange={(event) =>
                      onOtherInputValueChange &&
                      onOtherInputValueChange(event.target.value)
                    }
                    onFocus={onFocus}
                    placeholder="Add your answer (optional)"
                    ref={otherInputRef}
                    value={otherInputValue}
                  />
                )}
                {inlineChildren}
              </StyledFlexContainer>
              {value === optionValue && children ? (
                <ExtraContent className="extra-content">
                  {children}
                </ExtraContent>
              ) : null}
            </Fragment>
          );
        },
      )}
    </RadioGroup>
  );
};

export default CustomRadioGroup;
