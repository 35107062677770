import React from 'react';
import SSOOnboardingTemplate from '../../../atomic/templates/SSOOnboardingTemplate/SSOOnboardingTemplate';
import SSOInfoCard from '../../../atomic/pages/SSOInfoCard';

interface SSOErrorsPresentationalProps {
  headerText: string;
  bodyText: string;
  primaryButton: {
    buttonText: string;
    icon?: string;
    onClick: () => void;
  };
  subText?: {
    text: string;
    linkText?: string;
    linkPath?: string;
  };
}

const SSOErrorsPresentational = ({
  headerText,
  bodyText,
  primaryButton,
  subText,
}: SSOErrorsPresentationalProps) => {
  return (
    <SSOOnboardingTemplate>
      <SSOInfoCard
        headingText={headerText}
        bodyText={bodyText}
        buttonOnClick={primaryButton.onClick}
        buttonText={primaryButton.buttonText}
        buttonIcon={primaryButton.icon}
        subText={subText}
      />
    </SSOOnboardingTemplate>
  );
};

export default SSOErrorsPresentational;
