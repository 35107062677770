// @ts-nocheck TODO: type issues need to be fixed in this file
import { requestsReducer, success } from 'redux-saga-requests';
import _ from 'lodash';
import {
  HeadersWithoutAuthV2,
  AuthHeadersV2,
} from '../../../modules/shared/HeaderToken';
import { API_URL_WITH_V2 } from '../../../config';
import { MEMBER_GROUPS } from '../../utils/Slack';

const apiEndpoints = {
  AUTHORIZE_SIGNIN_WITH_SLACK: `${API_URL_WITH_V2}/integrations/slack/onboard/SignInWithSlack`,
  AUTHORIZE_SIGNIN_WITH_SLACK_V2: `${API_URL_WITH_V2}/integrations/slack/onboard/SignInWithSlackV2`,
  AUTHORIZE_USER_AUTH_SLACK: `${API_URL_WITH_V2}/integrations/slack/onboard/UserAuthSlack`,
  AUTHORIZE_ADD_TO_SLACK: `${API_URL_WITH_V2}/integrations/slack/onboard/AddToSlack`,
  SLACK_APP_INFO: `${API_URL_WITH_V2}/integrations/slack/config/app`,
  SLACK_VERIFY_MESSAGE: `${API_URL_WITH_V2}/integrations/slack/employer/verify/message`,
  SLACK_CHANNELS: `${API_URL_WITH_V2}/integrations/slack/employer/channels`,
  SLACK_ACTIVE_CHANNELS: `${API_URL_WITH_V2}/integrations/slack/employer/channels/active`,
  DEFAULT_SLACK_CHANNEL: `${API_URL_WITH_V2}/integrations/slack/employer/channels/default`,
  SLACK_CUSTOM_EMOJIS: `${API_URL_WITH_V2}/integrations/slack/employer/customEmojis`,
  CREATE_DEFAULT_SLACK_CHANNEL: `${API_URL_WITH_V2}/integrations/slack/employer/channels/default/create`,
  SLACK_MEMBERS: `${API_URL_WITH_V2}/integrations/slack/employer/members/v2`,
  INVITE_SLACK_MEMBERS: `${API_URL_WITH_V2}/integrations/slack/employer/invite/members/v2`,
  ONBOARD_SLACK_MEMBERS: `${API_URL_WITH_V2}/integrations/slack/employer/onboard/members/v2`,
  SLACK_HRIS_SETTINGS: `${API_URL_WITH_V2}/integrations/slack/employer/settings/hris`,
  SLACK_MEMBERS_ALL: `${API_URL_WITH_V2}/integrations/slack/employer/InviteAllSlackMembers`,
  SLACK_MEMBERS_FULL: `${API_URL_WITH_V2}/integrations/slack/employer/InviteFullSlackMembers`,
  SLACK_MEMBERS_SPECIFIC: `${API_URL_WITH_V2}/integrations/slack/employer/InviteSelectedSlackMembers`,
  SLACK_START_MEMBER_ONBOARDING: `${API_URL_WITH_V2}/integrations/slack/employer/StartMembersOnboarding`,
  SLACK_ANNOUNCEMENT: `${API_URL_WITH_V2}/integrations/slack/employer/SendWelcomeMessage`,
  CUSTOM_CURRENCY: `${API_URL_WITH_V2}/employer/employer_info/setCustomCurrency`,
  SKIP_CUSTOM_CURRENCY: `${API_URL_WITH_V2}/employer/employer_info/skipCustomCurrency`,
  SLACK_MEMBER_ONBOARDING_STATUS: `${API_URL_WITH_V2}/integrations/slack/employer/MembersOnboardingStatus`,
};

const AUTHORIZE_SIGNIN_WITH_SLACK =
  '@slackOnboard/V2/AUTHORIZE_SIGNIN_WITH_SLACK';
const AUTHORIZE_SIGNIN_WITH_SLACK_V2 =
  '@slackOnboard/V2/AUTHORIZE_SIGNIN_WITH_SLACK_V2';
const AUTHORIZE_USER_AUTH_SLACK = '@slackOnboard/V2/AUTHORIZE_USER_AUTH_SLACK';
const AUTHORIZE_ADD_TO_SLACK = '@slackOnboard/V2/AUTHORIZE_ADD_TO_SLACK';
const SLACK_APP_INFO = '@slackOnboard/V2/SLACK_APP_INFO';
const SLACK_VERIFY_MESSAGE = '@slackOnboard/V2/SLACK_VERIFY_MESSAGE';
const SLACK_CHANNELS = '@slackOnboard/V2/SLACK_CHANNELS';
const GET_ACTIVE_SLACK_CHANNELS = '@slackOnboard/V2/GET_ACTIVE_SLACK_CHANNELS';
const GET_SLACK_CUSTOM_EMOJIS = '@slackOnboard/V2/GET_SLACK_CUSTOM_EMOJIS';
export const UPDATE_ACTIVE_SLACK_CHANNELS =
  '@slackOnboard/V2/UPDATE_ACTIVE_SLACK_CHANNELS';
const GET_DEFAULT_SLACK_CHANNEL = '@slackOnboard/V2/GET_DEFAULT_SLACK_CHANNEL';
export const UPDATE_DEFAULT_SLACK_CHANNEL =
  '@slackOnboard/V2/UPDATE_DEFAULT_SLACK_CHANNEL';
export const CREATE_DEFAULT_SLACK_CHANNEL =
  '@slackOnboard/V2/CREATE_DEFAULT_SLACK_CHANNEL';
export const GET_SLACK_MEMBERS = '@slackOnboard/V2/GET_SLACK_MEMBERS';
export const INVITE_SLACK_MEMBERS = '@slackOnboard/V2/INVITE_SLACK_MEMBERS';
export const UPDATE_SLACK_MEMBERS_IN_STORE =
  '@slackOnboard/V2/UPDATE_SLACK_MEMBERS_IN_STORE';
export const ONBOARD_SLACK_MEMBERS = '@slackOnboard/V2/ONBOARD_SLACK_MEMBERS';
export const GET_SLACK_HRIS_SETTINGS =
  '@slackOnboard/V2/GET_SLACK_HRIS_SETTINGS';
export const UPDATE_SLACK_HRIS_SETTINGS =
  '@slackOnboard/V2/UPDATE_SLACK_HRIS_SETTINGS';
export const UPDATE_SLACK_MEMBERS = '@slackOnboard/V2/UPDATE_SLACK_MEMBERS';
export const UPDATE_SPECIFIC_SLACK_MEMBERS =
  '@slackOnboard/V2/UPDATE_SPECIFIC_SLACK_MEMBERS';
export const SLACK_START_MEMBER_ONBOARDING =
  '@slackOnboard/V2/SLACK_START_MEMBER_ONBOARDING';
export const GET_SLACK_MEMBER_ONBOARDING_STATUS =
  '@slackOnboard/V2/GET_SLACK_MEMBER_ONBOARDING_STATUS';
const SLACK_ANNOUNCEMENT = '@slackOnboard/V2/SLACK_ANNOUNCEMENT';
const SET_COMPANY_CURRENCY_SLACK =
  '@slackOnboard/V2/SET_COMPANY_CURRENCY_SLACK';
const SKIP_COMPANY_CURRENCY_SLACK =
  '@slackOnboard/V2/SKIP_COMPANY_CURRENCY_SLACK';

const baseUrl = window.location.href;

export const authorizeSignInWithSlack = ({ code }) => ({
  type: AUTHORIZE_SIGNIN_WITH_SLACK,
  request: {
    url: apiEndpoints.AUTHORIZE_SIGNIN_WITH_SLACK,
    data: {
      code,
      baseUrl,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    code,
  },
});

export const authorizeSignInWithSlackV2 = ({ code }) => ({
  type: AUTHORIZE_SIGNIN_WITH_SLACK_V2,
  request: {
    url: apiEndpoints.AUTHORIZE_SIGNIN_WITH_SLACK_V2,
    data: {
      code,
      baseUrl,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    code,
  },
});

export const authorizeUserAuthSlack = ({ code }) => ({
  type: AUTHORIZE_USER_AUTH_SLACK,
  request: {
    url: apiEndpoints.AUTHORIZE_USER_AUTH_SLACK,
    data: {
      code,
      baseUrl,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    code,
  },
});

export const authorizeAddToSlack = ({ code }, userAuthenticated = false) => {
  const headers = userAuthenticated ? AuthHeadersV2() : HeadersWithoutAuthV2();
  return {
    type: AUTHORIZE_ADD_TO_SLACK,
    request: {
      url: apiEndpoints.AUTHORIZE_ADD_TO_SLACK,
      data: {
        code,
        baseUrl,
      },
      method: 'post',
      ...headers,
    },
    meta: {
      code,
    },
  };
};

export const slackVerifyMessage = ({ message }) => ({
  type: SLACK_VERIFY_MESSAGE,
  request: {
    url: apiEndpoints.SLACK_VERIFY_MESSAGE,
    data: {
      message,
      baseUrl,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    message,
  },
});

export const getSlackAppInfo = () => ({
  type: SLACK_APP_INFO,
  request: {
    url: apiEndpoints.SLACK_APP_INFO,
    method: 'get',
    ...HeadersWithoutAuthV2(),
  },
});

export const getSlackChannels = () => ({
  type: SLACK_CHANNELS,
  request: {
    url: apiEndpoints.SLACK_CHANNELS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const getSlackActiveChannels = () => ({
  type: GET_ACTIVE_SLACK_CHANNELS,
  request: {
    url: apiEndpoints.SLACK_ACTIVE_CHANNELS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const updateSlackActiveChannels = (channels) => ({
  type: UPDATE_ACTIVE_SLACK_CHANNELS,
  request: {
    url: apiEndpoints.SLACK_ACTIVE_CHANNELS,
    data: {
      channels,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const getDefaultSlackChannel = () => ({
  type: GET_DEFAULT_SLACK_CHANNEL,
  request: {
    url: apiEndpoints.DEFAULT_SLACK_CHANNEL,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const getSlackCustomEmojis = () => ({
  type: GET_SLACK_CUSTOM_EMOJIS,
  request: {
    url: apiEndpoints.SLACK_CUSTOM_EMOJIS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const setCompanyCurrency = (customCurrency) => ({
  type: SET_COMPANY_CURRENCY_SLACK,
  request: {
    url: apiEndpoints.CUSTOM_CURRENCY,
    data: {
      customCurrency,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const updateDefaultSlackChannel = (channel) => ({
  type: UPDATE_DEFAULT_SLACK_CHANNEL,
  request: {
    url: apiEndpoints.DEFAULT_SLACK_CHANNEL,
    data: {
      channel,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const createDefaultSlackChannel = (channelName) => ({
  type: CREATE_DEFAULT_SLACK_CHANNEL,
  request: {
    url: apiEndpoints.CREATE_DEFAULT_SLACK_CHANNEL,
    data: {
      channelName,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const getSlackMembers = () => ({
  type: GET_SLACK_MEMBERS,
  request: {
    url: apiEndpoints.SLACK_MEMBERS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const inviteSlackMembers = ({ members }) => ({
  type: INVITE_SLACK_MEMBERS,
  request: {
    url: apiEndpoints.INVITE_SLACK_MEMBERS,
    data: {
      members,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const updateSlackMembersInStore = ({ members }) => ({
  type: UPDATE_SLACK_MEMBERS_IN_STORE,
  data: {
    members,
  },
});

export const onboardSlackMembers = () => ({
  type: ONBOARD_SLACK_MEMBERS,
  request: {
    url: apiEndpoints.ONBOARD_SLACK_MEMBERS,
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const getSlackHRISSettings = () => ({
  type: GET_SLACK_HRIS_SETTINGS,
  request: {
    url: apiEndpoints.SLACK_HRIS_SETTINGS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const updateSlackHRISSettings = ({ hrisSettings }) => ({
  type: UPDATE_SLACK_HRIS_SETTINGS,
  request: {
    url: apiEndpoints.SLACK_HRIS_SETTINGS,
    method: 'post',
    data: {
      hrisSettings,
    },
    ...AuthHeadersV2(),
  },
});

export const updateSlackMembers = (selectionType) => ({
  type: UPDATE_SLACK_MEMBERS,
  request: {
    url:
      selectionType === MEMBER_GROUPS.ALL
        ? apiEndpoints.SLACK_MEMBERS_ALL
        : apiEndpoints.SLACK_MEMBERS_FULL,
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const updateSpecificSlackMembers = (members) => ({
  type: UPDATE_SPECIFIC_SLACK_MEMBERS,
  request: {
    url: apiEndpoints.SLACK_MEMBERS_SPECIFIC,
    data: {
      members,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const startSlackMembersOnboarding = () => ({
  type: SLACK_START_MEMBER_ONBOARDING,
  request: {
    url: apiEndpoints.SLACK_START_MEMBER_ONBOARDING,
    data: {},
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const getSlackMembersOnboardingStatus = () => ({
  type: GET_SLACK_MEMBER_ONBOARDING_STATUS,
  request: {
    url: apiEndpoints.SLACK_MEMBER_ONBOARDING_STATUS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const skipSlackOnboardSetCustomCurrency = () => ({
  type: SKIP_COMPANY_CURRENCY_SLACK,
  request: {
    url: apiEndpoints.SKIP_CUSTOM_CURRENCY,
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const postSlackAnnouncement = (sendMessage) => ({
  type: SLACK_ANNOUNCEMENT,
  request: {
    url: apiEndpoints.SLACK_ANNOUNCEMENT,
    method: 'post',
    data: {
      sendMessage,
    },
    ...AuthHeadersV2(),
  },
});

export default requestsReducer({
  actionType: AUTHORIZE_SIGNIN_WITH_SLACK,
  getData: (state, action, config) => {
    switch (action.type) {
      // case success(AUTHORIZE_SIGNIN_WITH_SLACK): {
      //   return { ...state, data: action.data };
      // }
      default:
        return state.data;
    }
  },
  onError: (state, action, config) => {
    return { ...state };
  },
  mutations: {
    [SLACK_APP_INFO]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(SLACK_APP_INFO): {
            const data = _.get(action, ['data', 'data'], null);
            return { ...state.data, ...data };
          }
          default:
            return state;
        }
      },
    },
    [SLACK_CHANNELS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(SLACK_CHANNELS): {
            const slackChannels = _.get(action, [
              'data',
              'data',
              'channels',
              'channels',
            ]);
            return { ...state.data, slackChannels };
          }
          default:
            return state;
        }
      },
    },
    [UPDATE_ACTIVE_SLACK_CHANNELS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(UPDATE_ACTIVE_SLACK_CHANNELS): {
            const activeChannels = _.get(action, ['data', 'data', 'channels']);
            return { ...state.data, activeChannels };
          }
          default:
            return state;
        }
      },
    },
    [GET_ACTIVE_SLACK_CHANNELS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(GET_ACTIVE_SLACK_CHANNELS): {
            const activeChannels = _.get(action, ['data', 'data', 'channels']);
            return { ...state.data, activeChannels };
          }
          default:
            return state;
        }
      },
    },
    [GET_DEFAULT_SLACK_CHANNEL]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(GET_DEFAULT_SLACK_CHANNEL): {
            const defaultChannel = _.get(action, ['data', 'data', 'channel']);
            return { ...state.data, defaultChannel };
          }
          default:
            return state;
        }
      },
    },
    [UPDATE_DEFAULT_SLACK_CHANNEL]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(UPDATE_DEFAULT_SLACK_CHANNEL): {
            const defaultChannel = _.get(action, ['data', 'data', 'channel']);
            return { ...state.data, defaultChannel };
          }
          default:
            return state;
        }
      },
    },
    [CREATE_DEFAULT_SLACK_CHANNEL]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(CREATE_DEFAULT_SLACK_CHANNEL): {
            const defaultChannel = _.get(action, ['data', 'data', 'channel']);
            return { ...state.data, defaultChannel };
          }
          default:
            return state;
        }
      },
    },
    [GET_SLACK_MEMBERS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(GET_SLACK_MEMBERS): {
            const slackMembers = _.get(action, ['data', 'data', 'members']);
            return { ...state.data, slackMembers };
          }
          default:
            return state;
        }
      },
    },
    [INVITE_SLACK_MEMBERS]: {
      updateData: false,
    },
    [ONBOARD_SLACK_MEMBERS]: {
      updateData: false,
    },
    [GET_SLACK_HRIS_SETTINGS]: {
      updateData: false,
    },
    [UPDATE_SLACK_HRIS_SETTINGS]: {
      updateData: false,
    },
    [GET_SLACK_CUSTOM_EMOJIS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(GET_SLACK_CUSTOM_EMOJIS): {
            let customEmojis = _.get(action, ['data', 'data', 'emojis'], []);
            customEmojis = customEmojis.map((emoji) => {
              return {
                name: emoji.emoji,
                short_names: [emoji.emoji],
                text: '',
                keywords: ['slack'],
                imageUrl: emoji.image,
                customCategory: 'Slack',
              };
            });
            return { ...state.data, customEmojis };
          }
          default:
            return state;
        }
      },
    },
    [UPDATE_SLACK_MEMBERS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(UPDATE_SLACK_MEMBERS): {
            const slackMembersAdded = _.get(
              action,
              ['data', 'data', 'userIds'],
              [],
            );
            return { ...state.data, slackMembersAdded };
          }
          default:
            return state;
        }
      },
    },
    [UPDATE_SPECIFIC_SLACK_MEMBERS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(UPDATE_SPECIFIC_SLACK_MEMBERS): {
            const slackMembersAdded = _.get(
              action,
              ['data', 'data', 'userIds'],
              [],
            );
            return { ...state.data, slackMembersAdded };
          }
          default:
            return state;
        }
      },
    },
    [UPDATE_SLACK_MEMBERS_IN_STORE]: {
      updateData: (state, action) => {
        switch (action.type) {
          case UPDATE_SLACK_MEMBERS_IN_STORE: {
            const members = _.get(action, ['data', 'members'], []);
            return { ...state.data, slackMembers: members };
          }
          default:
            return state;
        }
      },
      local: true,
    },
  },
});
