import React, { useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { parse } from 'qs';
import {
  SSOOptions,
  SSOContent,
  SSOTypes,
} from '../../../Utils/data/ssoManager/common';
import SSOAuthorizePresentational from './SSOAuthorize.presentational';

import useVerifySSOCode from '../useVerifySSOCode/useVerifySSOCode';
import useFetchSSOConfig from '../useFetchSSOConfig/useFetchSSOConfig';
import Body from '../../../atomic/atoms/Body';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';
import GSuiteSSOController, {
  GOOGLE_SSO_FLOW,
  GSuiteSSOChildrenProps,
} from '../../../controllers/GSuiteSSOController';
import routesList from '../../../aV2/routes/routesList';

const DisplayComponent = ({ isLoading, displayName }: any) => (
  <Grid container justify="center" style={{ marginTop: 90 }}>
    <Body variant="body2Bold">
      {isLoading ? `Connecting to ${displayName}` : `Verifying ${displayName}`}
    </Body>
    <LoadingPulse isLoading />
  </Grid>
);

const GSuiteComponent = ({ loaded, signIn, isLoading, displayName }: any) => {
  useEffect(() => {
    if (loaded) {
      signIn();
    }
  }, [loaded, signIn]);
  return <DisplayComponent isLoading={isLoading} displayName={displayName} />;
};

interface SSOAuthorizeProps {
  ssoType: SSOTypes;
  authInModal: boolean;
}
const SSOAuthorize = ({ ssoType, authInModal }: SSOAuthorizeProps) => {
  const { sso } = useParams<{ sso: SSOOptions }>();
  const { push } = useHistory();
  const { search } = useLocation();
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });
  const { auto } = parsedParams;
  const { models: fetchSSOModels } = useFetchSSOConfig(
    sso,
    ssoType,
    authInModal,
  );
  const { models } = useVerifySSOCode(sso, ssoType, authInModal);
  const verifyGoogleSSOCode = useCallback(
    (code) => {
      const basePath =
        ssoType === SSOTypes.SIGN_IN
          ? routesList.SSO_SIGNIN_AUTHORIZE_FN(SSOOptions.GOOGLE)
          : routesList.SSO_SIGNUP_AUTHORIZE_FN(SSOOptions.GOOGLE);
      push(`/${basePath}?code=${code}`);
    },
    [push, ssoType],
  );

  const handleGoogleSSOError = useCallback(
    (error) => {
      const { error: errorCode } = error;
      push(`/${routesList.SSO_ERRORS_FN(sso, ssoType, errorCode)}`);
    },
    [push, sso, ssoType],
  );

  if (sso === SSOOptions.GOOGLE && auto === 'true') {
    return (
      <GSuiteSSOController
        flow={GOOGLE_SSO_FLOW.SIGN_IN}
        onSuccess={verifyGoogleSSOCode}
        onFailure={handleGoogleSSOError}
      >
        {({ signIn, loaded }: GSuiteSSOChildrenProps) => (
          <GSuiteComponent
            loaded={loaded}
            signIn={signIn}
            isLoading={fetchSSOModels.isLoading}
            displayName={SSOContent[sso].displayName}
          />
        )}
      </GSuiteSSOController>
    );
  }

  if (models.isVerifyingCode || fetchSSOModels.isLoading) {
    return (
      <DisplayComponent
        isLoading={fetchSSOModels.isLoading}
        displayName={SSOContent[sso].displayName}
      />
    );
  }

  return <SSOAuthorizePresentational />;
};

SSOAuthorize.defaultProps = {
  authInModal: false,
};

export default SSOAuthorize;
