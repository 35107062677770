// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import React, { Component } from 'react';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { API_URL_WITH_V2 } from '../../config';
import { AuthHeaders } from '../../modules/shared/HeaderToken';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      file: null,
      status: 'IDLE',
    };
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  onDownloadClick = async () => {
    this.setState({
      status: 'LOADING',
    });
    try {
      let fileName = this.state.file;
      if (!fileName || fileName.length === 0) {
        fileName = 'export';
      }
      const url = `${API_URL_WITH_V2}/metrics/events/export?start=${this.state.start}&end=${this.state.end}&file=${fileName}`;
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob', // important
        ...AuthHeaders(),
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      this.setState({
        status: 'IDLE',
      });
    } catch (err) {
      console.error(err);
      this.setState({
        status: 'ERROR',
      });
    }
  };

  handleStartDateChange = (date) => {
    this.setState({
      start: moment(date).format('YYYY-MM-DD'),
    });
  };

  handleFileChange(event) {
    this.setState({ file: event.target.value });
  }

  handleEndDateChange = (date) => {
    this.setState({
      end: moment(date).format('YYYY-MM-DD'),
    });
  };

  render() {
    return (
      <div style={{ 'margin-top': '100px' }}>
        <div className="container">
          <div className="row">
            <div className="col-3" />
            <div className="col-6">
              <h3>Export Events</h3>
              <div className="pt-2 pb-2">
                <KeyboardDatePicker
                  clearable
                  fullWidth
                  label="Start Date"
                  format="YYYY-MM-DD"
                  placeholder="Start Date (YYYY-MM-DD)"
                  value={this.state.start}
                  onChange={this.handleStartDateChange}
                  animateYearScrolling={false}
                />
              </div>
              <div className="pt-2 pb-2">
                <KeyboardDatePicker
                  clearable
                  fullWidth
                  label="End Date"
                  format="YYYY-MM-DD"
                  placeholder="End date (YYYY-MM-DD)"
                  value={this.state.end}
                  onChange={this.handleEndDateChange}
                  animateYearScrolling={false}
                />
              </div>
              <div className="pt-2 pb-2">
                <input
                  className="form-control"
                  placeholder="export.csv"
                  value={this.state.file}
                  onChange={this.handleFileChange}
                />
              </div>

              <button
                onClick={this.onDownloadClick}
                className="btn btn-primary mt-3"
                disabled={this.state.status === 'LOADING'}
              >
                Download
              </button>
            </div>
            <div className="col-3" />
          </div>
        </div>
      </div>
    );
  }
}

export default Reports;
