// @ts-nocheck TODO: type issues need to be fixed in this file
import { requestsReducer, success } from 'redux-saga-requests';
import _ from 'lodash';
import { HeadersWithoutAuthV2 } from '../../../modules/shared/HeaderToken';
import { API_URL_WITH_V2, API_URL_WITH_V3 } from '../../../config';

const apiEndpoints = {
  LOGIN_USER: `${API_URL_WITH_V2}/auth/session/login`,
  USERS: `${API_URL_WITH_V3}/users/`,
  LOGIN_OPTIONS: `${API_URL_WITH_V3}/users/signInOptions`,
  FORGOT_PASSWORD: `${API_URL_WITH_V2}/auth/session/forgotPassword`,
  VERIFY_FORGOT_PASSWORD: `${API_URL_WITH_V2}/auth/session/forgotPassword/verify/`,
  RESET_PASSWORD: `${API_URL_WITH_V2}/auth/session/resetPassword`,
};

const LOGIN_USER = '@sessions/V2/LOGIN_USER';
export const LOGIN_OPTIONS = '@sessions/V2/LOGIN_OPTIONS';
export const LOGIN_WITH_PASSWORD = '@sessions/V2/LOGIN_WITH_PASSWORD';
export const FORGOT_PASSWORD = '@sessions/V2/FORGOT_PASSWORD';
export const TRIGGER_SET_PASSWORD = '@sessions/V2/TRIGGER_SET_PASSWORD';
export const FORGOT_PASSWORD_VERIFY_TOKEN =
  '@sessions/V2/FORGOT_PASSWORD_VERIFY_TOKEN';
export const RESET_PASSWORD = '@sessions/V2/RESET_PASSWORD';

export const loginUser = ({ email, password, rememberMe = true }) => ({
  type: LOGIN_USER,
  request: {
    url: apiEndpoints.LOGIN_USER,
    data: {
      email,
      password,
      rememberMe,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const loginOptions = ({ email }) => ({
  type: LOGIN_OPTIONS,
  request: {
    url: `${apiEndpoints.LOGIN_OPTIONS}?email=${email}`,
    method: 'get',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const loginWithPassword = ({ password, email }) => ({
  type: LOGIN_WITH_PASSWORD,
  request: {
    url: `${apiEndpoints.USERS}passwordSignin`,
    data: {
      password,
      userEmail: email,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const forgotPassword = ({ email }) => ({
  type: FORGOT_PASSWORD,
  request: {
    url: apiEndpoints.FORGOT_PASSWORD,
    data: {
      email,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const triggerSetPassword = ({ email }) => ({
  type: TRIGGER_SET_PASSWORD,
  request: {
    url: `${apiEndpoints.USERS}sendEmailToSetPassword`,
    data: {
      userEmail: email,
    },
    method: 'put',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const forgotPasswordVerifyToken = ({ token }) => ({
  type: FORGOT_PASSWORD_VERIFY_TOKEN,
  request: {
    url: `${apiEndpoints.VERIFY_FORGOT_PASSWORD}${token}`,
    method: 'get',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const resetPassword = ({ userId, password }) => ({
  type: RESET_PASSWORD,
  request: {
    url: `${apiEndpoints.RESET_PASSWORD}`,
    data: {
      userId,
      password,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
  meta: {
    asPromise: true,
  },
});

export default requestsReducer({
  actionType: LOGIN_USER,
  mutations: {
    [FORGOT_PASSWORD_VERIFY_TOKEN]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(FORGOT_PASSWORD_VERIFY_TOKEN): {
            const data = _.get(action, ['data', 'data'], null);
            return { ...state.data, data };
          }
          default:
            return { ...state.data };
        }
      },
    },
    [LOGIN_OPTIONS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(LOGIN_OPTIONS): {
            const data = _.get(action, ['data'], null);
            return { ...state.data, data };
          }
          default:
            return { ...state.data };
        }
      },
    },
    [FORGOT_PASSWORD]: { updateData: false },
    [TRIGGER_SET_PASSWORD]: { updateData: false },
    [LOGIN_WITH_PASSWORD]: { updateData: false },
    [RESET_PASSWORD]: { updateData: false },
  },
});
