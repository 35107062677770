// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import {
  SET_PROFILE_FOR_INVITE_TOKEN,
  SET_PROFILE_FOR_EMPLOYEE,
  REGISTER_EMPLOYEE_WITH_DOMAIN,
  SET_DETAILS_FOR_USER,
} from '.';

export const getSetProfileRequestLoadingStatus = (state) =>
  _.get(
    state,
    ['v2EmployeeSignup', 'mutations', SET_PROFILE_FOR_INVITE_TOKEN, 'pending'],
    0,
  ) > 0 ||
  _.get(
    state,
    ['v2EmployeeSignup', 'mutations', SET_PROFILE_FOR_EMPLOYEE, 'pending'],
    0,
  ) > 0;

export const getRegisterRequestLoadingStatus = (state) =>
  _.get(state, ['v2EmployeeSignup', 'pending'], 0) > 0 ||
  _.get(
    state,
    ['v2EmployeeSignup', 'mutations', REGISTER_EMPLOYEE_WITH_DOMAIN, 'pending'],
    0,
  ) > 0;

export const getSetDetailsRequestLoadingStatus = (state) =>
  state.v2EmployeeSignup.pending > 0 ||
  state.v2EmployeeSignup.mutations[SET_DETAILS_FOR_USER].pending > 0;

export const getInviteEmployeeUserData = (state) =>
  state.employeeSignup.inviteUser;
