// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_VERIFY_TOKEN,
  RESET_PASSWORD,
  LOGIN_OPTIONS,
  LOGIN_WITH_PASSWORD,
  TRIGGER_SET_PASSWORD,
} from './index';

export const getRequestLoadingStatus = (state) => state.v2Session.pending > 0;

export const getForgotPasswordRequestLoadingStatus = (state) =>
  _.get(state, ['v2Session', 'mutations', FORGOT_PASSWORD, 'pending'], 0) > 0;

export const getTriggerResetPasswordRequestLoadingStatus = (state) =>
  _.get(state, ['v2Session', 'mutations', TRIGGER_SET_PASSWORD, 'pending'], 0) >
  0;

export const getVerfiyTokenRequestLoadingStatus = (state) =>
  state.v2Session.mutations[FORGOT_PASSWORD_VERIFY_TOKEN].pending > 0;

export const getResetPasswordRequestLoadingStatus = (state) =>
  state.v2Session.mutations[RESET_PASSWORD].pending > 0;

export const getEmailIdForResetPassword = (state) =>
  _.get(state, ['v2Session', 'data', 'data', 'email'], null);

export const getSigninOptionsRequestLoadingStatus = (state) =>
  _.get(state, ['v2Session', 'mutations', LOGIN_OPTIONS, 'pending'], 0) > 0;

export const getSigninWithPasswordRequestLoadingStatus = (state) =>
  _.get(state, ['v2Session', 'mutations', LOGIN_WITH_PASSWORD, 'pending'], 0) >
  0;
