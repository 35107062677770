import React, { useState, useCallback, useRef } from 'react';

import SVGIcon from '../../atoms/SVGIcon';
import useToggle from '../../../hooks/useToggle';
import { SingleAccordionProps } from './interface';
import ThemeV2 from '../../../componentsV2/theme';
import {
  AccordionWrapper,
  AccordionSummary,
  AccordionDetails,
  AccordionContent,
  SvgIconWrapper,
} from './styles';

const SingleAccordion = (props: SingleAccordionProps) => {
  const { summaryContent, detailsContent } = props;
  const {
    models: { toggleValue: isAccordionOpen },
    operations: { setToggleValue: toggleAccordionOpen },
  } = useToggle();

  const [height, setHeight] = useState<string>('0px');
  const accordionContentRefElement = useRef<HTMLDivElement>(null);

  const handleToggleClick = useCallback(() => {
    const accordionContentHeight =
      accordionContentRefElement.current !== null
        ? `${accordionContentRefElement.current.scrollHeight}px`
        : '0px';
    toggleAccordionOpen();
    setHeight(isAccordionOpen ? '0px' : accordionContentHeight);
  }, [isAccordionOpen, toggleAccordionOpen]);

  return (
    <AccordionWrapper>
      <AccordionSummary
        onClick={handleToggleClick}
        data-testid="accordion-summary"
      >
        {summaryContent}
        <SvgIconWrapper>
          <SVGIcon
            color={ThemeV2.palette.gray6}
            icon="carat-down"
            rotate={isAccordionOpen ? 180 : 0}
          />
        </SvgIconWrapper>
      </AccordionSummary>
      <AccordionDetails
        ref={accordionContentRefElement}
        height={height}
        isAccordionOpen={isAccordionOpen}
        tabIndex={0}
      >
        <AccordionContent>{detailsContent}</AccordionContent>
      </AccordionDetails>
    </AccordionWrapper>
  );
};

export default SingleAccordion;
