// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '../../core/Menu/MenuItem';
import { useStyles, INPUT_HEIGHT } from '../../core/TextField';
import themeV2 from '../../theme';
import { getMonthsToShow, getDaysForMonth } from '../../../Utils/date';

export const useSelectStyles = makeStyles((theme) => ({
  dropIcon: {
    color: themeV2.palette.lightGray1,
    marginRight: 10,
  },
  selectMonth: {
    borderRadius: '10px 0px 0px 10px',
    '&:focus': {
      borderRadius: '10px 0px 0px 10px',
    },
  },
  selectDay: {
    borderRadius: '0px 10px 10px 0px',
    '&:focus': {
      borderRadius: '0px 10px 10px 0px',
    },
  },
}));

export const useDateSelectStyles = makeStyles((theme) => ({
  dropIcon: {
    color: themeV2.palette.lightGray1,
    marginRight: 10,
  },
  selectMonth: {
    borderRadius: '10px 0px 0px 10px',
    '&:focus': {
      borderRadius: '10px 0px 0px 10px',
    },
  },
  selectDay: {
    borderRadius: '0px 0px 0px 0px',
    '&:focus': {
      borderRadius: '0px 0px 0px 0px',
    },
  },
  selectYear: {
    borderRadius: '0px 10px 10px 0px',
    '&:focus': {
      borderRadius: '0px 10px 10px 0px',
    },
  },
}));

const getClassNameforField = (field, classes) => {
  switch (field) {
    case 'day':
      return classes.selectDay;
    case 'year':
      return classes.selectYear;
    case 'month':
      return classes.selectMonth;
    default:
      return classes.selectDay;
  }
};

function SelectInput(props) {
  const {
    id,
    value,
    options,
    onChange,
    inputClasses,
    selectClasses,
    // error,
    style,
  } = props;
  const { placeholder } = props;
  let placeHolderInList = placeholder;
  if (id === 'day') {
    if (props.monthValue === null) {
      placeHolderInList = 'Select month first';
    }
  }

  return (
    <Select
      id={id}
      displayEmpty={!!placeholder}
      value={value}
      onChange={onChange}
      classes={{
        icon: selectClasses.dropIcon,
        select: getClassNameforField(id, selectClasses),
      }}
      input={
        <InputBase
          classes={{
            root: inputClasses.root,
            input: inputClasses.input, // error ? inputClasses.errorInput :
            // error: inputClasses.error,
          }}
          style={{ ...style, width: '50%' }}
        />
      }
      renderValue={(selected) => {
        if (!selected) {
          return <div style={{ opacity: 0.3 }}>{placeholder}</div>;
        }
        const selectedOption = _.get(
          options.find((option) => option.value === selected),
          'label',
          selected,
        );
        return selectedOption;
      }}
    >
      <MenuItem value="">
        <em>{placeHolderInList}</em>
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label || option.value}
        </MenuItem>
      ))}
    </Select>
  );
}

export function BirthDayMonthPicker(props) {
  const {
    style,
    error,
    rootStyle,
    dayValue,
    monthValue,
    onMonthChange,
    onDayChange,
    className,
    id,
    label,
    helperText,
    ...rest
  } = props;

  const styleProps = {
    height: INPUT_HEIGHT,
    hasLabel: !!label || !!error,
  };

  const classes = useStyles(styleProps);
  const selectClasses = useSelectStyles();

  let dayOptions = [];
  if (monthValue) {
    dayOptions = getDaysForMonth(monthValue);
  }
  return (
    <FormControl
      className={classNames(classes.margin, className)}
      {...rest}
      error={!!error}
      style={rootStyle}
    >
      <InputLabel
        shrink
        error={!!error}
        htmlFor={id}
        classes={{
          root: classes.formLabel,
          error: classes.errorFormLabel,
          focused: classes.formLabelFocused,
        }}
      >
        {error || label}
      </InputLabel>
      <div style={{ width: '100%', display: 'flex', marginTop: 20 }}>
        <SelectInput
          id="month"
          key="month"
          value={monthValue}
          onChange={onMonthChange}
          options={getMonthsToShow()}
          selectClasses={selectClasses}
          inputClasses={classes}
          error={error}
          placeholder="Month"
        />
        <SelectInput
          id="day"
          key="day"
          value={dayValue}
          onChange={onDayChange}
          options={dayOptions}
          selectClasses={selectClasses}
          inputClasses={classes}
          error={error}
          placeholder="Day"
          monthValue={monthValue}
        />
      </div>

      {helperText && (
        <FormHelperText
          className={classes.helperFormLabel}
          id="component-helper-text"
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export function DayMonthYearPicker(props) {
  const {
    style,
    error,
    rootStyle,
    dayValue,
    monthValue,
    onMonthChange,
    onDayChange,
    yearValue,
    onYearChange,
    yearOptions,
    className,
    id,
    label,
    helperText,
    ...rest
  } = props;

  const styleProps = {
    height: INPUT_HEIGHT,
    hasLabel: !!label || !!error,
  };

  const classes = useStyles(styleProps);
  const selectClasses = useDateSelectStyles();

  let dayOptions = [];
  if (monthValue) {
    dayOptions = getDaysForMonth(monthValue);
  }
  return (
    <FormControl
      className={classNames(classes.margin, className)}
      {...rest}
      error={!!error}
      style={rootStyle}
    >
      <InputLabel
        shrink
        error={!!error}
        htmlFor={id}
        classes={{
          root: classes.formLabel,
          error: classes.errorFormLabel,
          focused: classes.formLabelFocused,
        }}
      >
        {error || label}
      </InputLabel>
      <div style={{ width: '100%', display: 'flex', marginTop: 20 }}>
        <SelectInput
          id="month"
          key="month"
          value={monthValue}
          onChange={onMonthChange}
          options={getMonthsToShow()}
          selectClasses={selectClasses}
          inputClasses={classes}
          error={error}
          placeholder="Month"
        />
        <SelectInput
          id="day"
          key="day"
          value={dayValue}
          onChange={onDayChange}
          options={dayOptions}
          selectClasses={selectClasses}
          inputClasses={classes}
          error={error}
          placeholder="Day"
          monthValue={monthValue}
        />
        <SelectInput
          id="year"
          key="year"
          value={yearValue}
          onChange={onYearChange}
          options={yearOptions}
          selectClasses={selectClasses}
          inputClasses={classes}
          error={error}
          placeholder="Year"
        />
      </div>

      {helperText && (
        <FormHelperText
          className={classes.helperFormLabel}
          id="component-helper-text"
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
