// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderWithMenu from './header/HeaderWithMenu';

class Header extends Component {
  render() {
    return (
      <div>
        <HeaderWithMenu
          shouldShowMenuToggle={false}
          toggleMenu={this.toggleMenu}
          path={this.props.path}
        />
      </div>
    );
  }
}
export default connect()(Header);
