// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import noRequireAuth from '../../../containers/hoc/NoAuthentication';
import Layout from '../../Layout/onboarding';
import VerticallyCenteredLayout from '../../Layout/verticallyCentered';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import TextField from '../../../componentsV2/core/TextField';
import Button from '../../../componentsV2/core/Button';
import Link, { AnchorLink } from '../../../componentsV2/core/Link';
import themeV2 from '../../../componentsV2/theme';
import TermsAndPrivacy from '../shared/TermsAndPrivacy';
import { openIntercomMessenger } from '../../../Utils/Intercom';
import { handleErrorAction } from '../../utils/ErrorHandler';

import {
  getEmployerName,
  registerEmployeeWithEmail,
  registerEmployeeWithDomain,
} from '../../modules/employeeSignup';

import { getRegisterRequestLoadingStatus } from '../../modules/employeeSignup/selectors';
import RoutesList from '../../routes/routesList';
import { LANDER_URL } from '../../../config';
import { isValidEmail } from '../../../Utils/validation';

const CreateAssemblyLink = React.forwardRef((props, ref) => (
  <Link to={RoutesList.ENTER_EMAIL} {...props} ref={ref} />
));

const LanderLink = React.forwardRef((props, ref) => (
  <AnchorLink to={LANDER_URL} {...props} ref={ref} />
));

export const SET_ASSEMBLY_TYPE = {
  FROM_DOMAIN: 'FROM_DOMAIN',
  FROM_INVITE: 'FROM_INVITE',
};

export const ENTER_EMAIL_COMP_STATUS = {
  ERROR: 'error',
  SUCCESS: 'success',
  DOMAIN_RESTRICTED_ERROR: 'domain-restricted',
  DOMAIN_UNAVAILABLE_ERROR: 'domain-unavailable',
};

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    height: 593,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
  footer: {},
  link: {
    fontSize: 12,
  },
  terms: {
    color: themeV2.palette.lightGray1,
  },
  buttonStyle: {
    color: `${themeV2.palette.white} !important`,
    textDecoration: 'none',
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
    marginTop: 30,
    '&:hover': {
      color: `${themeV2.palette.white} !important`,
    },
    '&:visited': {
      color: `${themeV2.palette.white} !important`,
    },
  },
});

class EnterEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailErrors: null,
      emailTouched: false,
      employerName: null,
      message: null,
      domain: null,
    };
  }

  componentDidMount = () => {
    const { source } = this.props;
    if (!source) {
      this.loadEmployerDetails();
    }
  };

  onSignupClick = (e) => {
    e.preventDefault();
    const { source } = this.props;
    const { companyId } = this.props.match.params;
    let { email } = this.state;
    email = email.trim();

    let goThrough = true;
    if (!email) {
      this.setState({ emailErrors: 'Email is required' });
      goThrough = false;
    }

    if (!isValidEmail(email)) {
      this.setState({ emailErrors: 'Enter a valid email' });
      goThrough = false;
    }

    if (goThrough) {
      if (source === SET_ASSEMBLY_TYPE.FROM_DOMAIN) {
        this.props
          .registerEmployeeWithDomain({ email })
          .then((successAction) => {
            const { history } = this.props;
            history.push(
              `${history.location.pathname}?status=${ENTER_EMAIL_COMP_STATUS.SUCCESS}`,
            );
          })
          .catch((errorOrAbortAction) => {
            const { history } = this.props;
            const error = handleErrorAction(errorOrAbortAction);
            if (error.code === 100) {
              const emailError = error.values.email;
              if (emailError) {
                this.setState({ emailErrors: emailError.msg });
              }
              const { msg } = emailError;
              if (msg === 'You will need an invite with this email') {
                history.push(
                  `${history.location.pathname}?status=${ENTER_EMAIL_COMP_STATUS.DOMAIN_RESTRICTED_ERROR}`,
                );
              } else if (msg.includes('No account exists')) {
                const { domain } = error.values;
                this.setState(
                  () => ({
                    domain,
                  }),
                  () => {
                    history.push(
                      `${history.location.pathname}?status=${ENTER_EMAIL_COMP_STATUS.DOMAIN_UNAVAILABLE_ERROR}`,
                    );
                  },
                );
              } else {
                this.setState({ emailErrors: msg });
              }
            } else if (error.code === 102) {
              const msg = error.message;
              this.setState({ emailErrors: msg });
            }
          });
      } else {
        this.props
          .registerEmployeeWithEmail({ email, employerId: companyId })
          .then((successAction) => {
            const { history } = this.props;
            history.push(
              `${history.location.pathname}?status=${ENTER_EMAIL_COMP_STATUS.SUCCESS}`,
            );
          })
          .catch((errorOrAbortAction) => {
            const error = handleErrorAction(errorOrAbortAction);
            if (error.code === 100) {
              const emailError = error.values.email;
              if (emailError) {
                this.setState({ emailErrors: emailError.msg });
              }
            } else if (error.code === 102) {
              this.setState({ emailErrors: error.message });
            }
          });
      }
    }
  };

  getPageStatus = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    return params.get('status');
  };

  chatClick = (e) => {
    e.preventDefault();
    openIntercomMessenger();
  };

  loadEmployerDetails = async () => {
    try {
      const { companyId } = this.props.match.params;
      const { data } = await this.props.getEmployerName({
        employerId: companyId,
      });
      const employerName = _.get(data, 'name', null);
      this.setState({
        employerName,
      });
    } catch (err) {
      this.setState(
        () => ({
          message: 'This does not look like a valid Assembly link',
        }),
        () => {
          const { history } = this.props;
          history.push(
            `${history.location.pathname}?status=${ENTER_EMAIL_COMP_STATUS.ERROR}`,
          );
        },
      );
    }
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      emailErrors: null,
      emailTouched: true,
    });
  };

  renderDomainErrors = () => {
    const status = this.getPageStatus();
    const { classes } = this.props;
    if (status === ENTER_EMAIL_COMP_STATUS.DOMAIN_RESTRICTED_ERROR) {
      return (
        <Layout>
          <VerticallyCenteredLayout offsetHeight={200}>
            <div>
              <Typography weight="bold" variant="h4" gutterBottom>
                Sorry, this account is invite only.
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ask another cowoker or somone that might be an admin about
                access
              </Typography>
            </div>
            <div style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="secondary"
                component={LanderLink}
                className={classes.buttonStyle}
              >
                Back to Assembly for Now
              </Button>
            </div>
            <div style={{ marginTop: 16 }}>
              <Typography variant="subtitle1" gutterBottom>
                Not sure about this,{' '}
                <Link to="" onClick={this.chatClick}>
                  chat with us now
                </Link>
              </Typography>
            </div>
          </VerticallyCenteredLayout>
        </Layout>
      );
    }
    const { domain } = this.state;
    let message = 'No Assembly with your domain, but you can create one!';
    if (domain) {
      message = `No Assembly with the domain ${domain}, but you can create one!`;
    }
    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              Hmm, we didn’t find an active Assembly.
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {message}
            </Typography>
          </div>
          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="secondary"
              component={CreateAssemblyLink}
              className={classes.buttonStyle}
            >
              Create a new Assembly
            </Button>
          </div>
          <div style={{ marginTop: 16 }}>
            <Typography variant="subtitle1" gutterBottom>
              Not sure about this,{' '}
              <Link to="" onClick={this.chatClick}>
                chat with us now
              </Link>
            </Typography>
          </div>
        </VerticallyCenteredLayout>
      </Layout>
    );
  };

  renderError = () => {
    const { message } = this.state;
    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              Oops! the link is not right
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {message}
            </Typography>
          </div>
          <div style={{ marginTop: 16 }}>
            <Typography variant="subtitle1" gutterBottom>
              Not sure about this,{' '}
              <Link to="" onClick={this.chatClick}>
                chat with us now
              </Link>
            </Typography>
          </div>
        </VerticallyCenteredLayout>
      </Layout>
    );
  };

  renderSuccess() {
    const { loading } = this.props;
    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              Almost done, please check your email.
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              Click on the link in your email to finish signing up -- Don't
              forget to check your spam/junk.
            </Typography>
          </div>
          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.onSignupClick}
              disabled={loading}
            >
              {loading ? 'Resending' : 'Resend Email'}
            </Button>
          </div>
        </VerticallyCenteredLayout>
      </Layout>
    );
  }

  renderScreen() {
    const { classes, loading } = this.props;
    const { email, emailErrors, emailTouched, employerName } = this.state;
    const isButtonDisabled = !emailTouched || !!emailErrors;
    const { source } = this.props;

    return (
      <Layout>
        <Card className={classes.card}>
          <div className={classes.wrapper}>
            <div className={classes.body}>
              <form onSubmit={this.onSignupClick}>
                <Typography variant="h5" weight="bold" gutterBottom>
                  {source ? 'Find your Assembly' : 'Welcome to Assembly'}
                </Typography>
                {!source ? (
                  <Typography variant="body2" component="span">
                    Join the rest of your{' '}
                    <Typography variant="body2" weight="bold" component="span">
                      {employerName || ''}
                    </Typography>{' '}
                    team by entering your work email address to confirm your
                    account.
                  </Typography>
                ) : (
                  <Typography variant="body2" component="span">
                    We'll send you an email to confirm your address and find an
                    existing Assembly you can join.
                  </Typography>
                )}
                <TextField
                  id="email"
                  placeholder="name@workemail.com"
                  value={email}
                  onChange={this.handleEmailChange}
                  rootStyle={{ width: '100%' }}
                  error={emailErrors}
                  autoFocus
                />
                {source === SET_ASSEMBLY_TYPE.FROM_DOMAIN ? (
                  <Button
                    style={{ width: '100%', marginTop: 20 }}
                    onClick={this.onSignupClick}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isButtonDisabled || loading}
                  >
                    {loading ? 'Confirming...' : 'Confirm'}
                  </Button>
                ) : (
                  <Button
                    style={{ width: '100%', marginTop: 20 }}
                    onClick={this.onSignupClick}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isButtonDisabled || loading}
                  >
                    {loading ? 'Signing up...' : 'Sign up'}
                  </Button>
                )}

                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ marginTop: 5 }}
                >
                  Already an Assembly member?{' '}
                  <Link to={RoutesList.SIGN_IN}>Sign in</Link>
                </Typography>
              </form>
            </div>
            <TermsAndPrivacy buttonText="Sign up" />
          </div>
        </Card>
      </Layout>
    );
  }

  render() {
    const { source } = this.props;
    if (source === SET_ASSEMBLY_TYPE.FROM_DOMAIN) {
      return <Redirect to={RoutesList.ENTER_EMAIL} />;
    }
    const status = this.getPageStatus();
    if (status === ENTER_EMAIL_COMP_STATUS.ERROR) {
      return this.renderError();
    }
    if (status === ENTER_EMAIL_COMP_STATUS.SUCCESS) {
      return this.renderSuccess();
    }
    if (
      status === ENTER_EMAIL_COMP_STATUS.DOMAIN_RESTRICTED_ERROR ||
      status === ENTER_EMAIL_COMP_STATUS.DOMAIN_UNAVAILABLE_ERROR
    ) {
      return this.renderDomainErrors();
    }
    return this.renderScreen();
  }
}

EnterEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getRegisterRequestLoadingStatus(state),
});

export default connect(mapStateToProps, {
  getEmployerName,
  registerEmployeeWithEmail,
  registerEmployeeWithDomain,
})(withStyles(styles)(noRequireAuth(EnterEmail)));
