// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { string, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../../atoms/LinkItem';
import Image from '../../atoms/Image';
import AssemblyLogo from '../../../img/svgs/assembly-logo.svg';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  root: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '64px',
    width: '100%',
    maxWidth: '100%',
    background: ThemeV2.palette.gray1,
    borderBottom: `1px solid ${ThemeV2.palette.gray5}`,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    padding: '0px 24px',
    outline: 'none',
  },
  headerLogoLink: {
    display: 'inline-flex',
    outline: 'none',
  },
});

const HeaderNavbar = (props) => {
  const classes = useStyles(props);
  const { customLogo, logoOnClick } = props;
  return (
    <div
      className={classes.root}
      role="toolbar"
      aria-label="app header"
      tabIndex="0"
    >
      <Link
        id="home-button"
        role="button"
        tabIndex="0"
        aria-label="Go to home logo"
        onClick={logoOnClick}
        classes={{
          root: classes.headerLogoLink,
        }}
      >
        <Image src={customLogo || AssemblyLogo} alt="brand logo" height="24" />
      </Link>
    </div>
  );
};

HeaderNavbar.propTypes = {
  customLogo: string,
  logoOnClick: func.isRequired,
};

HeaderNavbar.defaultProps = {
  customLogo: '',
};

export default HeaderNavbar;
