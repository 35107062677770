// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '../../../../componentsV2/core/Typography';
import Button from '../../../../componentsV2/core/Button';
import { USER_ACCOUNT_SETUP } from '../../../../constants/routes';

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
  bottomDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
  },
});

function SetupSuccess(props) {
  const submitForm = (e) => {
    e.preventDefault();
    const { history, firstLogin } = props;
    if (firstLogin) {
      history.push(USER_ACCOUNT_SETUP);
    } else {
      window.location.href = `${APP_URL}home`;
    }
  };

  const { classes, header, SubTextComponent, subText } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <form onSubmit={submitForm}>
          <Typography variant="h5" weight="bold" style={{ marginBottom: 16 }}>
            {header}
          </Typography>
          {SubTextComponent}
          {subText.length > 0 && subText}
          <div className={classes.bottomDiv}>
            <Button variant="contained" color="secondary" type="submit">
              Explore Assembly
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

SetupSuccess.defaultProps = {
  SubTextComponent: null,
  subText: '',
};

SetupSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  subText: PropTypes.string,
  SubTextComponent: PropTypes.object,
};

export default withRouter(withStyles(styles)(SetupSuccess));
