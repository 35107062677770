import styled from 'styled-components';
import { PopoverOrigin } from '@material-ui/core/Popover';
import Body from '../../atoms/Body';

export const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};
export const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const StyledButton = styled.div<{
  disabled: boolean;
}>`
  padding: 0px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
}`;

export const Header = styled(Body)`
  padding: 8px;
  width: 100%;
  text-align: center;
`;

export const Footer = styled.div`
  padding: 8px;
`;
