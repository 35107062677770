// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import TextField from '../../../componentsV2/core/TextField';
import Button from '../../../componentsV2/core/Button';
import themeV2 from '../../../componentsV2/theme';

import RoutesList from '../../routes/routesList';
import { resetPassword } from '../../modules/login';
import {
  getResetPasswordRequestLoadingStatus,
  getEmailIdForResetPassword,
} from '../../modules/login/selectors';

import ProgressValidator from '../../../atomic/molecules/ProgressValidator';
import ValidationText from '../../../atomic/molecules/ValidationText';
import {
  checkPasswordStrength,
  passwordValidators,
  checkPasswordMaxLength,
  checkForNameAndMailId,
  PASSWORD_LANGUAGE,
} from '../../../Utils/validators/password';
import {
  STRENGTH_NONE,
  STRENGTH_STRONG,
} from '../../../constants/progressValidator';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  marginTop24: {
    marginTop: '24px',
  },
  marginBottom12: {
    marginBottom: '12px',
  },
  button: {
    width: '100%',
    marginTop: '24px',
  },
  subtitleStyle: {
    fontSize: 14,
    fontWeight: `${themeV2.typography.fontWeightSemibold} !important`,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeV2.palette.darkText,
  },
  link: {
    fontSize: 12,
  },
  terms: {
    color: themeV2.palette.lightGray1,
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      passwordErrors: null,
      passwordTouched: false,
      confirmPasswordTouched: false,

      validationTexts: passwordValidators,
      strength: STRENGTH_NONE,
    };
  }

  onButtonClick = (e) => {
    e.preventDefault();
    const { match, mailId } = this.props;
    const { userId } = match.params;
    const { password, confirmPassword } = this.state;

    let goThrough = true;

    if (password && password.length < 6) {
      this.setState({
        passwordErrors: 'Password must be atleast 6 characters long',
      });
      goThrough = false;
    }

    const isPasswordReachedMaxLength = checkPasswordMaxLength(password);
    if (isPasswordReachedMaxLength) {
      this.setState({ passwordErrors: PASSWORD_LANGUAGE.charactersLimit });
      goThrough = false;
    }

    const hasMailId = checkForNameAndMailId(password, {
      mailId,
    });
    if (hasMailId.length > 0) {
      goThrough = false;
      this.setState({
        passwordErrors: PASSWORD_LANGUAGE.emailUsernameSimilar,
      });
    }

    if (password !== confirmPassword) {
      this.setState({ passwordErrors: 'Password’s do not match' });
      goThrough = false;
    }

    if (goThrough) {
      this.props
        .resetPassword({ userId, password })
        .then(() => {
          this.props.history.push(RoutesList.RESET_PASSWORD_SUCCESS);
        })
        .catch(() => {});
    }
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    const { newValidationTexts, newStrength } = checkPasswordStrength(password);

    this.setState({
      password: event.target.value,
      passwordErrors: null,
      passwordTouched: true,

      validationTexts: newValidationTexts,
      strength: newStrength,
    });
  };

  handleConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      passwordErrors: null,
      confirmPasswordTouched: true,
    });
  };

  render() {
    const { classes, loading, match } = this.props;
    const {
      password,
      confirmPassword,
      confirmPasswordTouched,
      passwordTouched,
      passwordErrors,
      validationTexts,
      strength,
    } = this.state;

    const isPasswordStrengthStrong = strength !== STRENGTH_STRONG;
    const isButtonDisabled =
      !passwordTouched || !confirmPasswordTouched || !!passwordErrors;

    const { userId } = match.params;
    if (!userId) {
      return <Redirect to={`${RoutesList.SIGN_IN}`} />;
    }
    return (
      <Layout>
        <Card className={classes.card}>
          <form onSubmit={this.onButtonClick}>
            <Typography variant="h5" weight="bold" gutterBottom>
              Reset password
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitleStyle}>
              Enter your new password below. You must create a new unique
              password and not reuse an old one.
            </Typography>
            <TextField
              id="password"
              label="Enter new password"
              type="password"
              placeholder="Create a strong password"
              value={password}
              onChange={this.handlePasswordChange}
              rootStyle={{ width: '100%' }}
              error={passwordErrors}
              className={classes.marginTop24}
            />
            <TextField
              id="confirmPassword"
              label="Confirm new password"
              type="password"
              placeholder="Enter password"
              value={confirmPassword}
              onChange={this.handleConfirmPasswordChange}
              rootStyle={{ width: '100%' }}
              error={passwordErrors}
              className={`${classes.marginTop24} ${classes.marginBottom12}`}
            />
            {strength && (
              <ProgressValidator
                strength={strength}
                className={classes.progressValidator}
              />
            )}
            {validationTexts && (
              <div className={classes.validationTexts}>
                {validationTexts.map((item) => (
                  <ValidationText key={item.text} checked={item.checked}>
                    {item.text}
                  </ValidationText>
                ))}
              </div>
            )}
            <Button
              disabled={isButtonDisabled || loading || isPasswordStrengthStrong}
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.button}
            >
              {loading ? 'Resetting password...' : 'Reset Password'}
            </Button>
          </form>
        </Card>
      </Layout>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getResetPasswordRequestLoadingStatus(state),
  mailId: getEmailIdForResetPassword(state),
});

export default withRouter(
  connect(mapStateToProps, { resetPassword })(
    withStyles(styles)(ResetPassword),
  ),
);
