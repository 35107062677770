import { useCallback, useMemo } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { MS_TEAMS } from '../../Utils/msteams';
import { APP_URL, MS_TEAMS as MS_TEAMS_CONFIG } from '../../config';

const useMSTeamsConfig = () => {
  const setupMSTeamsConfig = useCallback(() => {
    microsoftTeams.initialize();

    /* To notify app loaded to hide loading indicator */
    microsoftTeams.appInitialization.notifyAppLoaded();
    /* enabling the save button in configuration page */
    microsoftTeams.settings.setValidityState(true);

    /* Register event handler on save button */
    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      microsoftTeams.settings.setSettings({
        contentUrl: `${APP_URL}?source=${MS_TEAMS}`,
        entityId: MS_TEAMS_CONFIG.ENTITY_ID,
        suggestedDisplayName: MS_TEAMS_CONFIG.NAME,
      });
      saveEvent.notifySuccess();
    });
  }, []);

  const operations = useMemo(() => {
    return {
      setupMSTeamsConfig,
    };
  }, [setupMSTeamsConfig]);

  return {
    operations,
  };
};

export default useMSTeamsConfig;
