// @ts-nocheck TODO: type issues need to be fixed in this file
import { checkIfProduction } from './admin';

const appID = checkIfProduction() ? 'vnd9nsk2' : 'jo3ehwt7';

export const updateIntercom = (data = {}) => {
  window.Intercom('update', data);
};

export const bootIntercom = (user = {}) => {
  if (checkIfProduction()) {
    window.Intercom('boot', {
      app_id: appID,
      hide_default_launcher: true,
      ...user,
    });
  }
};

export const rebootIntercom = () => {
  window.Intercom('boot', {
    app_id: appID,
    hide_default_launcher: true,
  });
};

export const openIntercomMessenger = () => {
  if (checkIfProduction()) {
    window.Intercom('show');
  }
};
