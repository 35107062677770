import styled from 'styled-components';

import Heading from '../../atoms/Heading';
import IconInfo from '../../molecules/IconInfo';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledIconInfo = styled(IconInfo)`
  margin: 8px 0 20px;
`;

export const StyledWrapper = styled.div<{ isExpandOnFocusEnabled: boolean }>`
  width: 100%;
  background: ${ThemeV2.palette.gray1};
  ${({ isExpandOnFocusEnabled }) =>
    !isExpandOnFocusEnabled &&
    `
  padding: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  `}
`;

export const StyledHeading = styled(Heading)`
  text-align: center;
  margin: 0 auto 24px;
`;

export const StyledNewPasswordTextFieldWrapper = styled.div`
  margin-bottom: 16px;
`;

export const StyledReEnterPasswordTextFieldWrapper = styled.div`
  margin: 24px 0;
`;
