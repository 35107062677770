export const MEMBER_TYPES = {
  ALL: {
    id: 'all',
    label: 'All Members',
  },
  FULL_MEMBERS: {
    id: 'fullMembers',
    label: 'Full Members',
  },
  MULTI_CHANNEL_GUEST: {
    id: 'multiChannel',
    label: 'Multi Channel Guests',
  },
  SINGLE_CHANNEL_GUEST: {
    id: 'singleChannel',
    label: 'Single Channel Guests',
  },
  OUTSIDE_GUESTS: {
    id: 'outside',
    label: 'Outside Guests',
  },
  // INACTIVE: {
  //   id: 'inactive',
  //   label: 'Inactive Slack Members',
  // },
};

export const SLACK_MEMBER_STATUS = {
  CAN_BE_DESELECTED: 'CAN_BE_DESELECTED',
  OWNER_CANNOT_BE_DESELECTED: 'OWNER_CANNOT_BE_DESELECTED',
  SELF_CANNOT_BE_DESELECTED: 'SELF_CANNOT_BE_DESELECTED',
};
