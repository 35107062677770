import { useCallback, useEffect, useMemo, useState } from 'react';
import useToggle from '../../../../hooks/useToggle';
import {
  viewDropdownItems as viewDropdownArray,
  valueDropdownItems as valueDropdownArray,
  weeklyTimelineDropdownItems as weeklyTimelineDropdownArray,
  getTimeStamps,
} from '../../../../Utils/data/analytics/common';
import {
  DropdownItem,
  GraphDataType,
  MakeGraphDataRequest,
  MakeInfoDataRequest,
} from './interfaces';

interface ReceivedActions {
  makePeopleSummaryRequest: MakeInfoDataRequest;
  makeRecognitionSummaryRequest: MakeInfoDataRequest;
  makeProfileInfoRequest: MakeInfoDataRequest;
  makePeopleDataRequest: MakeGraphDataRequest;
  makeRecognitionDataRequest: MakeGraphDataRequest;
  makeCoreValuesDataRequest: MakeGraphDataRequest;
  makeBadgesDataRequest: MakeGraphDataRequest;
  makeOverallEngagementRequest: MakeInfoDataRequest;
}

const usePageLogic = (
  {
    makePeopleSummaryRequest,
    makeRecognitionSummaryRequest,
    makeProfileInfoRequest,
    makePeopleDataRequest,
    makeRecognitionDataRequest,
    makeCoreValuesDataRequest,
    makeBadgesDataRequest,
    makeOverallEngagementRequest,
  }: ReceivedActions,
  adminAnalyticsAccess: boolean,
  coreValuesGraphData: GraphDataType[] | null,
  badgesGraphData: GraphDataType[] | null,
  coreValuesDataError?: Error,
  badgesDataError?: Error,
  assemblyId?: string,
  isSuperAdmin?: boolean,
  assemblyCreatedAt?: string,
) => {
  const [
    weeklyTimelineItems,
    monthlyTimelineItems,
    valueDropdownItems,
    viewDropdownItems,
  ]: [DropdownItem[], DropdownItem[], DropdownItem[], DropdownItem[]] = useMemo(
    () =>
      adminAnalyticsAccess || isSuperAdmin
        ? [
            weeklyTimelineDropdownArray,
            weeklyTimelineDropdownArray.map((i) => ({
              ...i,
              disabled: i.id <= 3,
            })),
            valueDropdownArray,
            viewDropdownArray,
          ]
        : [
            weeklyTimelineDropdownArray.map((i) => ({ ...i, disabled: true })),
            weeklyTimelineDropdownArray.map((i) => ({ ...i, disabled: true })),
            valueDropdownArray.map((i) => ({ ...i, disabled: true })).reverse(),
            viewDropdownArray.map((i) => ({ ...i, disabled: true })),
          ],
    [adminAnalyticsAccess, isSuperAdmin],
  );

  const [selectedValue, setSelectedValue] = useState(valueDropdownItems[0]);
  const [selectedValueTimeline, setSelectedValueTimeline] = useState(
    weeklyTimelineItems[2],
  );
  const [selectedPeopleTimeline, setSelectedPeopleTimeline] = useState(
    weeklyTimelineItems[2],
  );
  const [selectedRecognitionTimeline, setSelectedRecognitionTimeline] =
    useState(weeklyTimelineItems[2]);
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [peopleView, setPeopleView] = useState(viewDropdownItems[0]);
  const [recognitionView, setRecognitionView] = useState(viewDropdownItems[0]);
  const [valueView, setValueView] = useState(viewDropdownItems[0]);
  const {
    models: { toggleValue: showBookCallModal },
    operations: { setToggleValue: toggleBookCallModal },
  } = useToggle();
  const {
    models: { toggleValue: showTipsModal },
    operations: { setToggleValue: toggleTipsModal },
  } = useToggle();

  const onViewDropdownChange = useCallback(
    (type: string, optionId: number) => {
      let currentlyActiveItem;
      let setValue;
      let currentTimeline;
      let setTimeline;
      if (type === 'people') {
        currentlyActiveItem = peopleView;
        setValue = setPeopleView;
        setTimeline = setSelectedPeopleTimeline;
        currentTimeline = selectedPeopleTimeline;
      } else if (type === 'recognition') {
        currentlyActiveItem = recognitionView;
        setValue = setRecognitionView;
        setTimeline = setSelectedRecognitionTimeline;
        currentTimeline = selectedRecognitionTimeline;
      } else {
        currentlyActiveItem = valueView;
        setValue = setValueView;
        setTimeline = setSelectedValueTimeline;
        currentTimeline = selectedValueTimeline;
      }
      if (currentlyActiveItem.id !== optionId) {
        const selectedItem = viewDropdownItems.find((i) => i.id === optionId);
        if (selectedItem) {
          setValue(selectedItem);
        }
        if (selectedItem?.id === 2 && currentTimeline.id <= 3) {
          setTimeline(monthlyTimelineItems[3]);
        }
      }
    },
    [
      monthlyTimelineItems,
      peopleView,
      recognitionView,
      selectedPeopleTimeline,
      selectedRecognitionTimeline,
      selectedValueTimeline,
      valueView,
      viewDropdownItems,
    ],
  );

  const onTimelineDropdownChange = useCallback(
    (type: string, optionId: number) => {
      let currentlyActiveItem;
      let setValue;
      if (type === 'people') {
        currentlyActiveItem = selectedPeopleTimeline;
        setValue = setSelectedPeopleTimeline;
      } else if (type === 'recognition') {
        currentlyActiveItem = selectedRecognitionTimeline;
        setValue = setSelectedRecognitionTimeline;
      } else {
        currentlyActiveItem = selectedValueTimeline;
        setValue = setSelectedValueTimeline;
      }
      if (currentlyActiveItem.id !== optionId) {
        const selectedItem = weeklyTimelineItems.find((i) => i.id === optionId);
        if (selectedItem) {
          setValue(selectedItem);
        }
      }
    },
    [
      selectedPeopleTimeline,
      selectedRecognitionTimeline,
      selectedValueTimeline,
      weeklyTimelineItems,
    ],
  );

  const toggleUpgradeModalOpen = useCallback(() => {
    setUpgradeModalOpen(!isUpgradeModalOpen);
  }, [isUpgradeModalOpen]);

  const onValueDropdownChange = useCallback(
    (optionId: number) => {
      if (selectedValue.id !== optionId) {
        const selectedItem = valueDropdownItems.find((i) => i.id === optionId);
        if (selectedItem) {
          setSelectedValue(selectedItem);
        }
      }
    },
    [selectedValue, valueDropdownItems],
  );
  const getBadgesGraphData = useCallback(() => {
    const { name: grouping } = valueView;
    const [from, to] = getTimeStamps(
      selectedValueTimeline.id,
      valueView.id,
      assemblyCreatedAt,
    );
    makeBadgesDataRequest({
      params: {
        to,
        from,
        grouping,
        ...(assemblyId ? { assemblyId } : {}),
      },
    });
  }, [
    assemblyCreatedAt,
    assemblyId,
    makeBadgesDataRequest,
    selectedValueTimeline,
    valueView,
  ]);
  const getCoreValuesGraphData = useCallback(() => {
    const { name: grouping } = valueView;
    const [from, to] = getTimeStamps(
      selectedValueTimeline.id,
      valueView.id,
      assemblyCreatedAt,
    );
    makeCoreValuesDataRequest({
      params: {
        to,
        from,
        grouping,
        ...(assemblyId ? { assemblyId } : {}),
      },
    });
  }, [
    assemblyCreatedAt,
    assemblyId,
    makeCoreValuesDataRequest,
    selectedValueTimeline,
    valueView,
  ]);
  const getPeopleGraphData = useCallback(() => {
    const { name: grouping } = peopleView;
    const [from, to] = getTimeStamps(
      selectedPeopleTimeline.id,
      peopleView.id,
      assemblyCreatedAt,
    );
    makePeopleDataRequest({
      params: {
        to,
        from,
        grouping,
        ...(assemblyId ? { assemblyId } : {}),
      },
    });
  }, [
    assemblyCreatedAt,
    assemblyId,
    makePeopleDataRequest,
    peopleView,
    selectedPeopleTimeline,
  ]);
  const getRecognitionGraphData = useCallback(() => {
    const { name: grouping } = recognitionView;
    const [from, to] = getTimeStamps(
      selectedRecognitionTimeline.id,
      recognitionView.id,
      assemblyCreatedAt,
    );
    makeRecognitionDataRequest({
      params: {
        to,
        from,
        grouping,
        ...(assemblyId ? { assemblyId } : {}),
      },
    });
  }, [
    assemblyCreatedAt,
    assemblyId,
    makeRecognitionDataRequest,
    recognitionView,
    selectedRecognitionTimeline,
  ]);

  const [selectedChartData, selectedChartError] = useMemo(
    () =>
      selectedValue.id === 1
        ? [coreValuesGraphData, coreValuesDataError]
        : [badgesGraphData, badgesDataError],
    [
      badgesDataError,
      badgesGraphData,
      coreValuesDataError,
      coreValuesGraphData,
      selectedValue,
    ],
  );

  useEffect(() => {
    getPeopleGraphData();
  }, [getPeopleGraphData]);

  useEffect(() => {
    getRecognitionGraphData();
  }, [getRecognitionGraphData]);

  useEffect(() => {
    if (assemblyId) {
      makePeopleSummaryRequest({ params: { assemblyId } });
      makeRecognitionSummaryRequest({ params: { assemblyId } });
      makeProfileInfoRequest({ params: { assemblyId } });
      makeOverallEngagementRequest({ params: { assemblyId } });
    } else {
      makePeopleSummaryRequest();
      makeRecognitionSummaryRequest();
      makeProfileInfoRequest();
      makeOverallEngagementRequest();
    }
  }, [
    assemblyId,
    makeOverallEngagementRequest,
    makePeopleSummaryRequest,
    makeProfileInfoRequest,
    makeRecognitionSummaryRequest,
  ]);
  useEffect(() => {
    if (selectedValue.id === valueDropdownArray[0].id) {
      getCoreValuesGraphData();
    } else {
      getBadgesGraphData();
    }
  }, [
    getCoreValuesGraphData,
    getBadgesGraphData,
    selectedValue,
    valueDropdownItems,
  ]);

  const returnValues = useMemo(
    () => ({
      operations: {
        onValueDropdownChange,
        toggleUpgradeModalOpen,
        onViewDropdownChange,
        onTimelineDropdownChange,
        toggleBookCallModal,
        toggleTipsModal,
      },
      models: {
        selectedValueId: selectedValue.id,
        selectedValueTimelineId: selectedValueTimeline.id,
        selectedPeopleTimelineId: selectedPeopleTimeline.id,
        selectedRecognitionTimelineId: selectedRecognitionTimeline.id,
        isUpgradeModalOpen,
        selectedChartData,
        selectedChartError,
        selectedPeopleViewId: peopleView.id,
        selectedRecognitionViewId: recognitionView.id,
        selectedValueViewId: valueView.id,
        weeklyTimelineItems,
        monthlyTimelineItems,
        viewDropdownItems,
        valueDropdownItems,
        showBookCallModal,
        showTipsModal,
      },
    }),
    [
      showBookCallModal,
      toggleBookCallModal,
      viewDropdownItems,
      valueDropdownItems,
      onValueDropdownChange,
      toggleUpgradeModalOpen,
      onViewDropdownChange,
      onTimelineDropdownChange,
      selectedValue,
      selectedValueTimeline,
      selectedPeopleTimeline,
      selectedRecognitionTimeline,
      isUpgradeModalOpen,
      selectedChartData,
      selectedChartError,
      peopleView,
      recognitionView,
      valueView,
      weeklyTimelineItems,
      monthlyTimelineItems,
      toggleTipsModal,
      showTipsModal,
    ],
  );
  return returnValues;
};

export default usePageLogic;
