import { useEffect, useCallback } from 'react';

type Event = (event: MouseEvent | TouchEvent) => void;
const useOnClickOutside = (ref: any, handler: Event) => {
  const listener = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    },
    [handler, ref],
  );
  useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, listener]);
};

export default useOnClickOutside;
