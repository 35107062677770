import { useEffect, useMemo } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

import useIntegrationLayer from './useInteractionLayer';
import { isSourceMSTeams } from '../../Utils/msteams';

const usePreferredSSO = () => {
  const { actions, data } = useIntegrationLayer();
  const isFromMSTeams = isSourceMSTeams();
  useEffect(() => {
    if (isFromMSTeams) {
      microsoftTeams.initialize();

      /* To notify app loaded to hide loading indicator */
      microsoftTeams.appInitialization.notifyAppLoaded();
      microsoftTeams.appInitialization.notifySuccess();
      microsoftTeams.getContext((context: microsoftTeams.Context) => {
        if (context.loginHint) {
          actions.fireGetPreferredSSORequest({
            params: { email: context.loginHint },
          });
        }
      });
    }
  }, [actions, isFromMSTeams]);

  const models = useMemo(() => {
    let { preferredSSO } = data;
    if (preferredSSO) {
      preferredSSO = preferredSSO.toLowerCase();
    }
    return {
      preferredSSO,
    };
  }, [data]);

  return {
    models,
  };
};

export default usePreferredSSO;
