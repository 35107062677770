// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import Typography from '../componentsV2/core/Typography';
import LoadingPulse from '../atomic/atoms/LoadingPulse';
import useSignout from '../hooks/useSignout';

const Signout = () => {
  const { signoutUser } = useSignout();
  useEffect(() => {
    signoutUser();
  }, [signoutUser]);

  return (
    <Grid container justify="center" style={{ marginTop: 90 }}>
      <Typography variant="body2" weight="bold" gutterBottom>
        Signing out
      </Typography>
      <LoadingPulse isLoading />
    </Grid>
  );
};

export default Signout;
