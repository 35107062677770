// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Navbar from '../../componentsV2/core/Navbar';

const MARGIN_TOP = 90;
const styles = {
  root: {
    backgroundColor: '#f6f6f6',
    paddingTop: MARGIN_TOP,
    height: 'calc(100vh)',
  },
};

function onboardingLayout(props) {
  const { children, classes, slackOnboardStep } = props;
  return (
    <div className={classes.root}>
      <Navbar slackOnboardStep={slackOnboardStep} />
      {children}
    </div>
  );
}

onboardingLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(onboardingLayout);
