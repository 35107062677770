import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import themeV2 from '../../../theme';

const StyledMenuItem = withStyles({
  root: {
    fontFamily: themeV2.typography.fontFamily,
    fontSize: 14,
    fontWeight: themeV2.typography.fontWeightMedium,
    color: themeV2.palette.darkText,
  },
  selected: {
    fontWeight: themeV2.typography.fontWeightSemibold,
  },
})(MenuItem);

export default StyledMenuItem;
