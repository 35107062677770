import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Navbar from '../../../componentsV2/core/Navbar';
import Card from '../../../atomic/atoms/Card';
import Heading from '../../../atomic/atoms/Heading';
import Body from '../../../atomic/atoms/Body';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';
import GifContainer from '../../../atomic/molecules/GifContainer';
import { FLOWS_ONBOARDING_JOB } from '../../../constants/endpoints';
import { showErrorMessage } from '../../../Utils/toast';
import {
  SETUP_FLOWS_ERROR,
  SETUP_FLOWS_LOADING_INFO,
  SETUP_FLOWS_HEADER,
} from '../../../languages/en/flows';
import useMultipleJobsPollingV2 from '../../../hooks/useMultipleJobsPolling';
import { APP_URL } from '../../../config';

const CardContainer = styled(Card)`
  padding: 32px;
  width: 500px;
`;

const StyledGifContainer = styled(GifContainer)`
  margin: 24px 0;
`;

const MainContainer = styled.div`
  margin-top: 94px;
  display: flex;
  justify-content: center;
`;

const UserSetupController = () => {
  const history = useHistory();
  const { error, isJobStatusSuccessful } = useMultipleJobsPollingV2<undefined>({
    preJobsAPI: {
      apiName: FLOWS_ONBOARDING_JOB,
    },
    enabled: true,
  });
  useEffect(() => {
    if (error) {
      showErrorMessage(SETUP_FLOWS_ERROR);
    }
  }, [error]);
  useEffect(() => {
    if (isJobStatusSuccessful || error) {
      window.location.href = `${APP_URL}home`;
    }
  }, [isJobStatusSuccessful, history, error]);
  return (
    <div>
      <Navbar />
      <MainContainer>
        <CardContainer>
          <Heading variant="h5">{SETUP_FLOWS_HEADER}</Heading>
          <Body variant="body2">{SETUP_FLOWS_LOADING_INFO}</Body>
          <StyledGifContainer src="https://media2.giphy.com/media/SWVzkIlHdEckF81gnA/200.gif" />
          <LoadingPulse size={32} />
        </CardContainer>
      </MainContainer>
    </div>
  );
};

export default UserSetupController;
