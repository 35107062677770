// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../../Layout/onboarding';
import VerticallyCenteredLayout from '../../Layout/verticallyCentered';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import TextField from '../../../componentsV2/core/TextField';
import Button from '../../../componentsV2/core/Button';
import Link from '../../../componentsV2/core/Link';
import themeV2 from '../../../componentsV2/theme';

import { createAccount } from '../../modules/onboarding';
import { getRequestLoadingStatus } from '../../modules/onboarding/selectors';
import { getSlackConfigInfo } from '../../modules/slackOnboard/selectors';
import { registerEmployeeWithDomain } from '../../modules/employeeSignup';
import { getRegisterRequestLoadingStatus } from '../../modules/employeeSignup/selectors';
import { authorizeAddToSlack } from '../../modules/slackOnboard';
import SSOComponent from '../../../pages/signin/SSOComponent';

import { APP_URL } from '../../../config';
import RoutesList from '../../routes/routesList';
import { openIntercomMessenger } from '../../../Utils/Intercom';
import { handleErrorAction } from '../../utils/ErrorHandler';
import TermsAndPrivacy from '../shared/TermsAndPrivacy';
import { isValidEmail } from '../../../Utils/validation';
import { EventType, trackEvent } from '../../../Utils/analytics/Metrics';
import TextDivider from '../../../componentsV2/custom/TextDivider';
import { SLACK_ERROR_MESSAGES, SLACK_ERROR_TYPES } from '../../utils/Slack';
import LoadingPulse from '../../../screens/shared/LoadingPulse';
import * as Analytics from '../../../Utils/analytics';
import { ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { RegistrationMethods } from '../../../Utils/analytics/interfaces';

const ERROR_STATES = {
  EMAIL: 'email',
  DOMAIN: 'domain',
};

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
  footer: {},
  link: {
    fontSize: 12,
  },
  terms: {
    color: themeV2.palette.lightGray1,
  },
  buttonStyle: {
    color: `${themeV2.palette.white} !important`,
    textDecoration: 'none',
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
    marginTop: 30,
    '&:hover': {
      color: `${themeV2.palette.white} !important`,
    },
    '&:visited': {
      color: `${themeV2.palette.white} !important`,
    },
  },
});

const AdapterCustomLink = (to) =>
  React.forwardRef((props, ref) => <Link to={to} {...props} ref={ref} />);

class EnterEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailErrors: null,
      emailTouched: false,
      domainEmployer: null,
      ssoLoading: false,
      ssoType: null,
      slackVerifying: false,
    };
  }

  onSigninClick = (e) => {
    e.preventDefault();
    let { email } = this.state;
    email = email.trim();

    let goThrough = true;
    if (!email) {
      this.setState({ emailErrors: 'Email is required' });
      goThrough = false;
    }

    const isEmailValid = isValidEmail(email);
    if (isEmailValid === false) {
      this.setState({ emailErrors: 'Enter a valid email' });
      goThrough = false;
    }

    if (goThrough) {
      this.props
        .createAccount({ email })
        .then((successAction) => {
          const { data } = successAction.data;
          const { history } = this.props;

          Analytics.trackEvent(
            ANALYTICS_EVENTS.ACCOUNT_SIGN_UP,
            {
              $distinct_id: data.userId,
              $email: email,
              'Registration Method': RegistrationMethods.Email,
              'Registration Date': new Date().toString(),
            },
            {
              'Registration Method': RegistrationMethods.Email,
            },
            {
              Email: email,
            },
          );
          trackEvent(EventType.REGISTRATION, { userId: data.userId });
          history.push(`${RoutesList.ENTER_CODE}/${data.userId}`);
        })
        .catch((errorOrAbortAction) => {
          const error = handleErrorAction(errorOrAbortAction);
          if (error.code === 100) {
            const emailError = error.values.email;
            if (emailError) {
              const { history } = this.props;
              if (emailError.msg === 'Email exists, sign in') {
                history.push(
                  `${RoutesList.ENTER_EMAIL}?error=${ERROR_STATES.EMAIL}`,
                );
              } else if (
                emailError.msg.includes('Account exists') ||
                emailError.msg.includes('invite email and sign up')
              ) {
                history.push(
                  `${RoutesList.ENTER_EMAIL}?error=${ERROR_STATES.DOMAIN}`,
                );
                this.setState({ emailErrors: 'Domain exists' });
              } else {
                this.setState({ emailErrors: emailError.msg });
              }
            }
          } else {
            this.setState({ emailErrors: error.message });
          }
        });
    }
  };

  handleSSOClick = (ssoOption) => {
    this.setState({ ssoLoading: true, ssoType: ssoOption });
  };

  getRedirectUrl = () => APP_URL;

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      emailErrors: null,
      emailTouched: true,
    });
  };

  chatClick = (e) => {
    e.preventDefault();
    openIntercomMessenger();
  };

  renderErrorLayout = (errorState) => {
    const { classes } = this.props;

    let headeText = 'Sorry, this domain is already in use!';
    let subText = 'You can create a new account using a different email';
    let buttonText = 'Use Different Email';
    let RedirectComponent = AdapterCustomLink(RoutesList.ENTER_EMAIL);
    if (errorState === ERROR_STATES.EMAIL) {
      headeText = 'Whoops, you already have an account!';
      subText = 'What would you like to do?';
      buttonText = 'Go Back to Sign In';
      RedirectComponent = AdapterCustomLink(RoutesList.SIGN_IN);
    }

    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              {headeText}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {subText}
            </Typography>
          </div>
          <div>
            <Button
              variant="contained"
              color="secondary"
              component={RedirectComponent}
              className={classes.buttonStyle}
            >
              {buttonText}
            </Button>
          </div>
          <div style={{ marginTop: 16 }}>
            <Typography variant="subtitle1" gutterBottom>
              Not sure about this,{' '}
              <Link to="" onClick={this.chatClick}>
                chat with us now
              </Link>
            </Typography>
          </div>
        </VerticallyCenteredLayout>
      </Layout>
    );
  };

  render() {
    const { location } = this.props;
    const { slackVerifying, ssoLoading, ssoType } = this.state;
    const params = new URLSearchParams(location.search);
    const errorState = params.get('error');
    let slackErrorMessage = null;
    if (
      errorState === ERROR_STATES.DOMAIN ||
      errorState === ERROR_STATES.EMAIL
    ) {
      return this.renderErrorLayout(errorState);
    }
    if (errorState === SLACK_ERROR_TYPES.ACCESS_DENIED) {
      slackErrorMessage = SLACK_ERROR_MESSAGES.ACCESS_DENIED;
    }

    const { classes, loading } = this.props;
    const { email, emailErrors, emailTouched } = this.state;
    const isButtonDisabled = !emailTouched || !!emailErrors;

    return (
      <Layout>
        {slackVerifying || ssoLoading ? (
          <Grid container justify="center" style={{ marginTop: 90 }}>
            <Typography variant="body2" weight="bold" gutterBottom>
              {ssoLoading ? `Connecting to ${ssoType}` : 'Verifying Slack'}
            </Typography>
            <LoadingPulse isLoading />
          </Grid>
        ) : (
          <>
            <Card className={classes.card}>
              <div className={classes.wrapper}>
                <div className={classes.body}>
                  <form onSubmit={this.onSigninClick}>
                    <Typography variant="h5" weight="bold" gutterBottom>
                      Create a new Assembly
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Please confirm your email below or simply choose your
                      preferred authenticator to get started with your Assembly.
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <SSOComponent
                        ssoType="signup"
                        onSSOClick={this.handleSSOClick}
                      />
                    </div>
                    {slackErrorMessage && (
                      <Typography
                        variant="body2"
                        style={{
                          color: '#FC5A5A',
                          fontSize: '14px',
                          textAlign: 'center',
                          marginTop: 20,
                        }}
                      >
                        {slackErrorMessage}
                      </Typography>
                    )}
                    <TextDivider text="OR" />
                    <TextField
                      id="email"
                      label="Your email address"
                      placeholder="name@workemail.com"
                      value={email}
                      onChange={this.handleEmailChange}
                      rootStyle={{ width: '100%' }}
                      error={emailErrors}
                    />
                    <Button
                      style={{ width: '100%', marginTop: 20 }}
                      disabled={isButtonDisabled || loading || ssoLoading}
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      {loading ? 'Confirming...' : 'Confirm'}
                    </Button>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ marginTop: 5 }}
                    >
                      Already an Assembly member?{' '}
                      <Link to={RoutesList.SIGN_IN}>Sign in</Link>
                    </Typography>
                  </form>
                </div>
                <TermsAndPrivacy buttonText="Confirm" />
              </div>
            </Card>
            {/* <Existing
              text="Already have an Assembly?"
              linkText="Find yours"
              to={RoutesList.JOIN_ASSEMBLY}
            /> */}
          </>
        )}
      </Layout>
    );
  }
}

EnterEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getRequestLoadingStatus(state),
  slackConfigInfo: getSlackConfigInfo(state),
  signupRequestLoading: getRegisterRequestLoadingStatus(state),
});

export default connect(mapStateToProps, {
  createAccount,
  registerEmployeeWithDomain,
  authorizeAddToSlack,
})(withStyles(styles)(EnterEmail));
