// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import Button from '../../../componentsV2/core/Button';
import SlackButton from '../../../componentsV2/custom/AddToSlackButton';
import {
  authorizeAddToSlack,
  authorizeSignInWithSlack,
  authorizeUserAuthSlack,
  authorizeSignInWithSlackV2,
  getSlackAppInfo,
} from '../../modules/slackOnboard';
import { handleErrorAction } from '../../utils/ErrorHandler';
import { getSlackConfigInfo } from '../../modules/slackOnboard/selectors';
import { APP_URL } from '../../../config';
import RoutesList from '../../routes/routesList';
import Existing from '../shared/ExistingAssemblyDiv';
import {
  errorCodes,
  parseURL,
  ONBOARDING_FLOW,
  SLACK_ERROR_TYPES,
  getPathToSlackComplete,
} from '../../utils/Slack';
import { userSessionCreatedAction } from '../../../modules/Session';
import { isUserAuthenticated } from '../../../modules/session/selectors';
import LoadingPulse from '../../../screens/shared/LoadingPulse';

import TextDivider from '../../../componentsV2/custom/TextDivider';
import TextField from '../../../componentsV2/core/TextField';

import { createAccount } from '../../modules/onboarding';
import { getRequestLoadingStatus } from '../../modules/onboarding/selectors';
import { isValidEmail } from '../../../Utils/validation';
import { trackEvent, EventType } from '../../../Utils/analytics/Metrics';

const ERROR_STATES = {
  EMAIL: 'email',
  DOMAIN: 'domain',
  JOIN_DOMAIN: 'join-domain',
};

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    padding: '40px 55px 25px 55px',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
  giveFirstButton: {
    padding: '0 15 0 15',
    width: '100%',
    height: '44px',
    marginTop: '19px',
    color: '#FFFFFF',
    borderRadius: '3px',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  emailLabel: {
    display: 'block',
    fontSize: '14px',
    paddingLeft: '15px',
    marginTop: '14px',
    marginBottom: '24px',
  },
  button: {
    display: 'block',
    width: '100%',
  },
});

class SignInWithSlack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slackConnecting: false,
      slackVerifying: false,
      email: '',
      emailErrors: null,
    };
  }

  componentDidMount() {
    const { location, slackConfigInfo } = this.props;
    this.props.getSlackAppInfo();
    const params = new URLSearchParams(location.search);
    const isAuto = params.get('auto');
    if (isAuto && isAuto === 'true' && slackConfigInfo) {
      this.handleAutoSignIn();
    } else {
      this.handleSlackIntegration(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const { slackConfigInfo } = this.props;
    if (!prevProps.slackConfigInfo && slackConfigInfo) {
      const { location } = this.props;
      const params = new URLSearchParams(location.search);
      const isAuto = params.get('auto');
      if (isAuto && isAuto === 'true') {
        this.handleAutoSignIn();
      }
    }
  }

  isAutoSignIn = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const isAuto = params.get('auto');
    if (isAuto && isAuto === 'true') {
      return true;
    }
    return false;
  };

  handleAutoSignIn = () => {
    const { addToSlack, userAuth, signInV2 } = this.props;
    const {
      slackAddAPIUrl,
      slackSigninAPIUrl,
      slackUserAuthUrl,
      slackSigninV2APIUrl,
    } = this.getSlackUrls();
    if (slackAddAPIUrl && slackSigninAPIUrl) {
      this.setState({ slackConnecting: true });
      if (addToSlack) {
        window.location.href = slackAddAPIUrl;
      } else if (userAuth) {
        window.location.href = slackUserAuthUrl;
      } else if (signInV2) {
        window.location.href = slackSigninV2APIUrl;
      } else {
        window.location.href = slackSigninAPIUrl;
      }
    }
  };

  onSlackClick = () => {
    this.setState({ slackConnecting: true });
  };

  onAuthorizeSuccess = (successAction) => {
    this.setState({ slackVerifying: false });
    const data = _.get(successAction, ['data', 'data']);
    const { user, token, onboardingFlow, employer } = data;
    this.props.userSessionCreatedAction(user, token);
    const { history } = this.props;
    if (onboardingFlow === ONBOARDING_FLOW.SIGN_IN_WITH_SLACK) {
      history.push(RoutesList.HOME);
    } else if (onboardingFlow === ONBOARDING_FLOW.ADD_TO_SLACK) {
      const onboardingStep = _.get(
        employer,
        ['integrations', 'slack_v2', 'onboardingStep'],
        null,
      );
      history.push(getPathToSlackComplete(onboardingStep));
    }
  };

  onAuthorizeFailure = (errorOrAbortAction) => {
    this.setState({ slackVerifying: false });
    const error = handleErrorAction(errorOrAbortAction);
    if (error) {
      const slack = _.get(error, ['values', 'slack'], null);
      let slackError;
      if (slack) {
        const { history } = this.props;
        slackError = slack.error;
        if (slackError) {
          if (slackError === errorCodes.NO_SLACK_TEAM_IN_ASSEMBLY) {
            history.push(`${RoutesList.SLACK_ADD_TO}?error=${slackError}`);
          } else {
            history.push(`${RoutesList.SLACK_SIGN_IN}?error=${slackError}`);
          }
        }
      }
    }
  };

  getRedirectUrl = () => APP_URL;

  handleSlackIntegration = (props) => {
    const { code } = parseURL(props.location.search);
    const { addToSlack, userAuth, signInV2 } = props;
    if (code) {
      const { location } = this.props;
      const params = new URLSearchParams(location.search);
      const errorState = params.get('error');
      if (errorState) {
        if (errorState === errorCodes.NO_SLACK_TEAM_IN_ASSEMBLY) {
          this.addToSlackSlackAPI(code);
        } else {
          this.signInWithSlackAPI(code);
        }
      } else if (addToSlack) {
        this.addToSlackSlackAPI(code);
      } else if (userAuth) {
        this.userAuthSlackAPI(code);
      } else if (signInV2) {
        this.signInWithSlackAPIV2(code);
      } else {
        this.signInWithSlackAPI(code);
      }
    }
  };

  userAuthSlackAPI = (code) => {
    this.setState({ slackVerifying: true });
    this.props
      .authorizeUserAuthSlack({ code })
      .then((successAction) => {
        this.onAuthorizeSuccess(successAction);
      })
      .catch((errorOrAbortAction) => {
        this.onAuthorizeFailure(errorOrAbortAction);
      });
  };

  signInWithSlackAPI = (code) => {
    this.setState({ slackVerifying: true });
    this.props
      .authorizeSignInWithSlack({ code })
      .then((successAction) => {
        this.onAuthorizeSuccess(successAction);
      })
      .catch((errorOrAbortAction) => {
        this.onAuthorizeFailure(errorOrAbortAction);
      });
  };

  signInWithSlackAPIV2 = (code) => {
    this.setState({ slackVerifying: true });
    this.props
      .authorizeSignInWithSlackV2({ code })
      .then((successAction) => {
        this.onAuthorizeSuccess(successAction);
      })
      .catch((errorOrAbortAction) => {
        this.onAuthorizeFailure(errorOrAbortAction);
      });
  };

  addToSlackSlackAPI = (code) => {
    this.setState({ slackVerifying: true });
    const { userAuthenticated } = this.props;
    this.props
      .authorizeAddToSlack({ code }, userAuthenticated)
      .then((successAction) => {
        this.onAuthorizeSuccess(successAction);
      })
      .catch((errorOrAbortAction) => {
        this.onAuthorizeFailure(errorOrAbortAction);
      });
  };

  addToSlackSlackAPIV2 = (code) => {
    this.setState({ slackVerifying: true });
    this.props
      .authorizeSignInWithSlackV2({ code })
      .then((successAction) => {
        this.onAuthorizeSuccess(successAction);
      })
      .catch((errorOrAbortAction) => {
        this.onAuthorizeFailure(errorOrAbortAction);
      });
  };

  getSlackUrls = () => {
    const { slackConfigInfo, addToSlack, userAuth, signInV2 } = this.props;
    let currentUrl = 'slack/onboard/signin';
    if (addToSlack) {
      currentUrl = 'slack/onboard/add';
    } else if (userAuth) {
      currentUrl = 'slack/onboard/userauth';
    } else if (signInV2) {
      currentUrl = 'slack/onboard/signin/v2';
    }
    const redirectUrl = `${this.getRedirectUrl()}${currentUrl}`;
    let slackSigninAPIUrl;
    let slackAddAPIUrl;
    let slackUserAuthUrl;
    let slackSigninV2APIUrl;
    if (slackConfigInfo) {
      const { CLIENT_ID, DEV_CLIENT_ID, SCOPES } = slackConfigInfo;
      slackSigninAPIUrl = `https://slack.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${redirectUrl}&scope=${SCOPES.SIGN_IN_WITH_SLACK.user_scope}`;
      slackAddAPIUrl = `https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&redirect_uri=${redirectUrl}&scope=${SCOPES.ADD_TO_SLACK.scope}&user_scope=${SCOPES.ADD_TO_SLACK.user_scope}`;
      slackUserAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&redirect_uri=${redirectUrl}&user_scope=${SCOPES.SLACK_AUTHORIZE_USER.user_scope}`;
      slackSigninV2APIUrl = `https://slack.com/oauth/authorize?client_id=${DEV_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=${SCOPES.SIGN_IN_WITH_SLACK_V2.user_scope}`;
    }
    return {
      slackSigninAPIUrl,
      slackAddAPIUrl,
      slackUserAuthUrl,
      slackSigninV2APIUrl,
    };
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      emailErrors: null,
      emailTouched: true,
    });
  };

  onSigninClick = (e) => {
    e.preventDefault();
    let { email } = this.state;
    email = email.trim();

    let goThrough = true;
    if (!email) {
      this.setState({ emailErrors: 'Email is required' });
      goThrough = false;
    }

    const isEmailValid = isValidEmail(email);
    if (isEmailValid === false) {
      this.setState({ emailErrors: 'Enter a valid email' });
      goThrough = false;
    }

    if (goThrough) {
      this.props
        .createAccount({ email })
        .then((successAction) => {
          const { data } = successAction.data;
          const { history } = this.props;
          trackEvent(EventType.REGISTRATION, { userId: data.userId });
          history.push(`${RoutesList.SIGNUP_SUCCESS}/${data.userId}`);
        })
        .catch((errorOrAbortAction) => {
          const error = handleErrorAction(errorOrAbortAction);
          if (error.code === 100) {
            const emailError = error.values.email;
            if (emailError) {
              const { history } = this.props;
              if (emailError.msg === 'Email exists, sign in') {
                history.push(
                  `${RoutesList.ENTER_EMAIL}?error=${ERROR_STATES.EMAIL}`,
                );
              } else if (
                emailError.msg.includes('Account exists') ||
                emailError.msg.includes('invite email and sign up')
              ) {
                const employer = _.get(error, ['values', 'employer'], null);
                const employeeSignupFrom = _.get(
                  error,
                  ['values', 'employer', 'employeeSignupFrom', 'value'],
                  null,
                );
                if (employer && employeeSignupFrom === 'DOMAIN') {
                  this.setState(
                    () => {
                      return {
                        emailErrors: 'Domain exists',
                        domainEmployer: employer,
                      };
                    },
                    () => {
                      history.push(
                        `${RoutesList.ENTER_EMAIL}?error=${ERROR_STATES.JOIN_DOMAIN}`,
                      );
                    },
                  );
                } else {
                  history.push(
                    `${RoutesList.ENTER_EMAIL}?error=${ERROR_STATES.DOMAIN}`,
                  );
                  this.setState({ emailErrors: 'Domain exists' });
                }
              } else {
                this.setState({ emailErrors: emailError.msg });
              }
            }
          } else {
            this.setState({ emailErrors: error.message });
          }
        });
    }
  };

  renderHeader = () => {
    const { location, addToSlack } = this.props;
    const params = new URLSearchParams(location.search);
    const errorState = params.get('error');
    if (errorState) {
      if (errorState === errorCodes.NO_SLACK_TEAM_IN_ASSEMBLY) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#FC5A5A',
              fontSize: '24px',
            }}
          >
            Sorry, it looks like Assmebly needs to be installed to this Slack
            first
          </Typography>
        );
      }
      if (errorState === errorCodes.SLACK_USER_NOT_IN_ASSEMBLY) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#FC5A5A',
              fontSize: '24px',
            }}
          >
            Sorry! This Assembly is invite only
          </Typography>
        );
      }
      if (errorState === errorCodes.NON_WORK_EMAIL_NOT_ALLOWED) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#FC5A5A',
              fontSize: '24px',
            }}
          >
            Sorry! We require a work email address to create a new Assembly
          </Typography>
        );
      }
      if (errorState === errorCodes.SLACK_TEAM_MISMATCH) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#FC5A5A',
              fontSize: '24px',
            }}
          >
            Sorry, this email is already associated with an existing Assembly
          </Typography>
        );
      }
      if (errorState === SLACK_ERROR_TYPES.ACCESS_DENIED) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#FC5A5A',
              fontSize: '24px',
            }}
          >
            Oh no! It looks like your Slack account isn’t permitted.
          </Typography>
        );
      }
      if (errorState === errorCodes.ONLY_ADMIN_OWNER_ALLOWED_TO_ADD_TO_SLACK) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#FC5A5A',
              fontSize: '24px',
            }}
          >
            Sorry! Your Slack only allows admins to approve new apps
          </Typography>
        );
      }
      if (errorState === errorCodes.SLACK_ONBOARDING_INCOMPLETE) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#2C2C2C',
              fontSize: '24px',
            }}
          >
            Sorry your Assembly is not setup completely.
          </Typography>
        );
      }
      if (errorState === errorCodes.ADMIN_APPROVAL_REQUESTED) {
        return (
          <Typography
            variant="body2"
            weight="bold"
            style={{
              color: '#FC5A5A',
              fontSize: '24px',
            }}
          >
            This Assembly is invite only – we have sent an invite request to the
            admins
          </Typography>
        );
      }
    }
    if (addToSlack) {
      return (
        <Typography
          variant="body2"
          weight="bold"
          style={{
            color: '#2C2C2C',
            fontSize: '24px',
          }}
        >
          {' '}
          Welcome! Add Slack to your Assembly.
        </Typography>
      );
    }
    return (
      <Typography
        variant="body2"
        weight="bold"
        style={{
          color: '#2C2C2C',
          fontSize: '24px',
        }}
      >
        {' '}
        Welcome! Sign in with Slack to access your Assembly.
      </Typography>
    );
  };

  renderBody = () => {
    const {
      classes,
      loading,
      location,
      slackConfigInfo,
      addToSlack,
      signInV2,
    } = this.props;
    const params = new URLSearchParams(location.search);
    const errorState = params.get('error');

    const { email, emailErrors, emailTouched, slackConnecting } = this.state;
    const isButtonDisabled = !emailTouched || !!emailErrors;

    const { slackAddAPIUrl, slackSigninAPIUrl, slackSigninV2APIUrl } =
      this.getSlackUrls();
    if (errorState) {
      if (errorState === errorCodes.NO_SLACK_TEAM_IN_ASSEMBLY) {
        return (
          <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
            Click the “Add to slack” to add to your Slack workspace
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <SlackButton
                addToSlack
                onClick={this.onSlackClick}
                disabled={!slackConfigInfo}
                href={slackAddAPIUrl}
              />
            </div>
          </Typography>
        );
      }
      if (errorState === errorCodes.SLACK_TEAM_MISMATCH) {
        return (
          <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
            Emails can only be associated with a sinlge Assembly account
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <SlackButton
                signupwithslack
                onClick={this.onSlackClick}
                disabled={!slackConfigInfo}
                href={slackAddAPIUrl}
              />
            </div>
          </Typography>
        );
      }
      if (errorState === errorCodes.SLACK_USER_NOT_IN_ASSEMBLY) {
        return (
          <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
            If you’d like to join, you’ll have to ask permission from your
            Assembly’s admin
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.giveFirstButton}
                href="https://app.slack.com"
                target="_"
              >
                Thanks, take me back to Slack &nbsp;
                <LaunchIcon style={{ color: '#FFF' }} />
              </Button>
            </div>
          </Typography>
        );
      }
      if (errorState === errorCodes.NON_WORK_EMAIL_NOT_ALLOWED) {
        return (
          <>
            <form onSubmit={this.onSigninClick}>
              <Typography
                variant="body2"
                style={{ marginTop: 20 }}
                gutterBottom
              >
                In order to setup your new Assembly, please try a Slack that has
                a work email and not a personal email – or you can also setup
                with an Assembly with a work email below
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <SlackButton
                    addToSlack
                    onClick={this.onSlackClick}
                    disabled={!slackConfigInfo}
                    href={slackAddAPIUrl}
                  />
                </div>
              </Typography>
              <TextDivider text="OR" />
              <TextField
                id="email"
                label="Enter your work email"
                placeholder="name@workemail.com"
                value={email}
                onChange={this.handleEmailChange}
                rootStyle={{ width: '100%' }}
                error={emailErrors}
                autoFocus
              />
              <Button
                style={{ width: '100%', marginTop: 20 }}
                disabled={isButtonDisabled || loading || slackConnecting}
                variant="contained"
                color="secondary"
                type="submit"
              >
                {loading ? 'Verifying...' : 'Next'}
              </Button>
            </form>
          </>
        );
      }
      if (errorState === errorCodes.ONLY_ADMIN_OWNER_ALLOWED_TO_ADD_TO_SLACK) {
        return (
          <form onSubmit={this.onSigninClick}>
            <Typography
              variant="body2"
              style={{ marginTop: 20, marginBottom: '24px' }}
              gutterBottom
            >
              An admin of your Slack workspace has “Sign in with Slack” disabled
              – The setting needs to be turned off in Slack or you can use your
              email and password to setup
            </Typography>
            <TextField
              id="email"
              label="Enter your work email"
              placeholder="name@workemail.com"
              value={email}
              onChange={this.handleEmailChange}
              rootStyle={{ width: '100%' }}
              error={emailErrors}
              autoFocus
            />
            <Button
              style={{ width: '100%', marginTop: 20 }}
              disabled={isButtonDisabled || loading || slackConnecting}
              variant="contained"
              color="secondary"
              type="submit"
            >
              {loading ? 'Verifying...' : 'Next'}
            </Button>
          </form>
        );
      }
      if (errorState === errorCodes.SLACK_ONBOARDING_INCOMPLETE) {
        return (
          <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
            Looks like your Assembly account is not configured completely.
            Please wait until it is setup and try again.
          </Typography>
        );
      }
      if (errorState === SLACK_ERROR_TYPES.ACCESS_DENIED) {
        return (
          <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
            Sorry, some users on this Slack workspace aren’t permitted to join
            Assembly at the moment. This is a setting set by your Assembly
            admin. Please talk to your Assembly admin and try again.
          </Typography>
        );
      }
      if (errorState === errorCodes.ADMIN_APPROVAL_REQUESTED) {
        return (
          <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
            If your Assembly admin approves your access, we will inform you
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.giveFirstButton}
                href="https://app.slack.com"
                target="_"
              >
                Thanks, take me back to Slack &nbsp;
                <LaunchIcon style={{ color: '#FFF' }} />
              </Button>
            </div>
          </Typography>
        );
      }
    }
    if (addToSlack) {
      return (
        <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
          Give recognition, redeem your earned points, peep the leaderboards,
          and more. Click the "Add to Slack" to add Assembly to your Slack
          workspace.
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <SlackButton
              addToSlack
              onClick={this.onSlackClick}
              disabled={!slackConfigInfo}
              href={slackAddAPIUrl}
            />
          </div>
        </Typography>
      );
    }
    if (signInV2) {
      return (
        <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
          Give recognition, redeem your earned points, peep the leaderboards,
          and more. Click the "Sign in with Slack" to sign in and or authorize
          Assembly.
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <SlackButton
              onClick={this.onSlackClick}
              disabled={!slackConfigInfo}
              href={slackSigninV2APIUrl}
            />
          </div>
        </Typography>
      );
    }
    return (
      <Typography variant="body2" style={{ marginTop: 20 }} gutterBottom>
        Give recognition, redeem your earned points, peep the leaderboards, and
        more. Click the "Sign in with Slack" to sign in and or authorize
        Assembly.
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <SlackButton
            onClick={this.onSlackClick}
            disabled={!slackConfigInfo}
            href={slackSigninAPIUrl}
          />
        </div>
      </Typography>
    );
  };

  renderSubtext = () => {
    const params = new URLSearchParams(this.props.location.search);
    const errorState = params.get('error');
    if (errorState === errorCodes.NO_SLACK_TEAM_IN_ASSEMBLY) {
      return (
        <Existing
          text="No Slack account?"
          linkText="Sign up with email"
          to={RoutesList.ENTER_EMAIL}
        />
      );
    }
    if (errorState === errorCodes.SLACK_TEAM_MISMATCH) {
      return (
        <Existing
          text="Create a new Assembly?"
          linkText="Get started with email"
          to={RoutesList.ENTER_EMAIL}
        />
      );
    }
    if (errorState === errorCodes.ONLY_ADMIN_OWNER_ALLOWED_TO_ADD_TO_SLACK) {
      return (
        <Existing
          text="Are you a Slack admin?"
          linkText="Go to Slack settings"
          link="https://slack.com/apps/manage/settings"
        />
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { slackVerifying, slackConnecting } = this.state;
    return (
      <Layout>
        {slackVerifying || slackConnecting || this.isAutoSignIn() ? (
          <Grid container justify="center" style={{ marginTop: 90 }}>
            <Typography variant="body2" weight="bold" gutterBottom>
              {slackConnecting
                ? 'Connecting to Slack'
                : 'Verifying Slack Sign in'}
            </Typography>
            <LoadingPulse isLoading />
          </Grid>
        ) : (
          <>
            <Card className={classes.card}>
              <div className={classes.wrapper}>
                <div className={classes.body}>
                  {this.renderHeader()}
                  {this.renderBody()}
                </div>
              </div>
            </Card>
            {this.renderSubtext()}
          </>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: getRequestLoadingStatus(state),
  slackConfigInfo: getSlackConfigInfo(state),
  userAuthenticated: isUserAuthenticated(state),
});

export default connect(mapStateToProps, {
  createAccount,
  authorizeAddToSlack,
  authorizeSignInWithSlack,
  authorizeUserAuthSlack,
  userSessionCreatedAction,
  authorizeSignInWithSlackV2,
  getSlackAppInfo,
})(withStyles(styles)(SignInWithSlack));
