import React from 'react';

import { StyledRouterLink, StyledAnchor } from './styles';

export interface LinkProps {
  children: string | React.ReactNode;
  to: string;
  className?: string;
  isAnchor?: boolean;
  openInNewTab?: boolean;
  onClick?: () => void;
  color?: string;
  underline?: boolean;
}

const Link = (props: LinkProps) => {
  const {
    children = null,
    className = '',
    to = '',
    isAnchor,
    openInNewTab,
    onClick,
    color = undefined,
    underline,
  } = props;
  if (isAnchor) {
    return (
      <StyledAnchor
        href={to}
        target={openInNewTab ? '_blank' : '_self'}
        className={className}
        onClick={onClick}
        color={color}
      >
        {children}
      </StyledAnchor>
    );
  }
  return (
    <StyledRouterLink
      className={className}
      to={to}
      onClick={onClick}
      color={color}
      underline={underline}
    >
      {children}
    </StyledRouterLink>
  );
};

const MemoizedLink = React.memo(Link);
MemoizedLink.displayName = 'Link';
export default MemoizedLink;
