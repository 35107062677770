import { Method } from 'axios';
import { API_URL_WITH_V2, API_URL_WITH_V3 } from '../config';
import { PAGINATED_INTEGRATION_DATA_ITEMS_LIMIT } from './im';

export const TEST = 'test';
export const TEST_WITH_SUB = 'testWithSub';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_ADMIN_INTEGRATION_STATUS = 'GET_ADMIN_INTEGRATION_STATUS';
export const GET_INTEGRATION_STATUS = 'GET_INTEGRATION_STATUS';
export const GET_BOT_OAUTH_CONFIG = 'GET_BOT_OAUTH_CONFIG';
export const AUTHORIZE_BOT = 'AUTHORIZE_BOT';
export const SLACK_BOT_INTEGRATION_DATA = 'SLACK_BOT_INTEGRATION_DATA';
export const MSTEAMS_BOT_INTEGRATION_DATA = 'MSTEAMS_BOT_INTEGRATION_DATA';
export const SLACK_SET_ACTIVE_CHANNEL = 'SLACK_SET_ACTIVE_CHANNEL';
export const SLACK_CREATE_ACTIVE_CHANNEL = 'SLACK_CREATE_ACTIVE_CHANNEL';
export const MSTEAMS_SET_ACTIVE_CHANNEL = 'MSTEAMS_SET_ACTIVE_CHANNEL';
export const MSTEAMS_CREATE_ACTIVE_CHANNEL = 'MSTEAMS_CREATE_ACTIVE_CHANNEL';
export const GET_CUSTOM_EMOJI = 'GET_CUSTOM_EMOJI';
export const SET_CUSTOM_CURRENCY = 'SET_CUSTOM_CURRENCY';
export const SKIP_CUSTOM_CURRENCY = 'SKIP_CUSTOM_CURRENCY';
export const SEND_WELCOME_MESSAGE = 'SEND_WELCOME_MESSAGE';
export const GET_IDENTITY_MANAGER_DATA = 'GET_IDENTITY_MANAGER_DATA';

export const GET_SSO_SIGNIN_CONFIG = 'GET_SSO_SIGNIN_CONFIG';
export const GET_SSO_SIGNUP_CONFIG = 'GET_SSO_SIGNUP_CONFIG';
export const GET_SSO_OAUTH_CONFIG = 'GET_SSO_OAUTH_CONFIG';
export const SSO_SIGNIN = 'SSO_SIGNIN';
export const SSO_SIGNUP = 'SSO_SIGNUP';
export const GET_JOB_STATUS = 'GET_JOB_STATUS';
export const GET_MULTIPLE_JOBS_STATUS = 'GET_MULTIPLE_JOBS_STATUS';
export const ANALYTICS_PEOPLE_SUMMARY = 'ANALYTICS_PEOPLE_SUMMARY';
export const ANALYTICS_PROFILE_INFORMATION = 'ANALYTICS_PROFILE_INFORMATION';
export const ANALYTICS_RECOGNITION_SUMMARY = 'ANALYTICS_RECOGNITION_SUMMARY';
export const ANALYTICS_PEOPLE_GRAPH_DATA = 'ANALYTICS_PEOPLE_GRAPH_DATA';
export const ANALYTICS_RECOGNITION_GRAPH_DATA =
  'ANALYTICS_RECOGNITION_GRAPH_DATA';
export const ANALYTICS_CORE_VALUES_GRAPH_DATA =
  'ANALYTICS_CORE_VALUES_GRAPH_DATA';
export const ANALYTICS_BADGES_GRAPH_DATA = 'ANALYTICS_BADGES_GRAPH_DATA';
export const ANALYTICS_OVERALL_ENGAGEMENT = 'ANALYTICS_OVERALL_ENGAGEMENT';

export const SEARCH_MEMBERS = 'SEARCH_MEMBERS';
export const SEARCH_POSTS = 'SEARCH_POSTS';

export const GET_PREFERRED_SSO = 'GET_PREFERRED_SSO';
export const GET_INTEGRATION_DATA = 'GET_INTEGRATION_DATA';
export const GET_PAGINATED_INTEGRATION_DATA = 'GET_PAGINATED_INTEGRATION_DATA';
export const GET_PAGINATED_INTEGRATION_DATA_MAIN =
  'GET_PAGINATED_INTEGRATION_DATA_MAIN';
export const GET_IM_ENTITY_COUNT = 'GET_IM_ENTITY_COUNT';
export const GET_ASSEMBLY_UNIQUE_SHORT_CODE = 'GET_ASSEMBLY_UNIQUE_SHORT_CODE';
export const V3_GET_FEED = 'V3_GET_FEED';
export const GET_MEMBER_RECOGNITION_COUNT = 'GET_MEMBER_RECOGNITION_COUNT';
export const GET_SINGLE_POST = 'GET_SINGLE_POST';
export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_COMMENT = 'CREATE_POST_COMMENT';
export const CREATE_FLOW_POST_COMMENT = 'CREATE_FLOW_POST_COMMENT';
export const GET_POST_COMMENTS = 'GET_POST_COMMENTS';
export const GET_FLOW_POST_COMMENTS = 'GET_FLOW_POST_COMMENTS';
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const USER_VERIFY_TOKEN = 'USER_VERIFY_TOKEN';
export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS =
  'UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS';
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';
export const GET_CURRENT_PLAN = 'GET_CURRENT_PLAN';
export const GET_REDEEM_INFO = 'GET_REDEEM_INFO';
export const V3_SEARCH_FEED = 'V3_SEARCH_FEED';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const QUICK_SETUP_GUIDE = 'QUICK_SETUP_GUIDE';

// Onboard
export const VERIFY_EMAIL_ONBOARDING = 'VERIFY_EMAIL_ONBOARDING';

// Invites
export const INVITE_USERS_CREATE = 'INVITE_USERS_CREATE';

// Leaderboard
export const GET_MEMBER_CELEBRATED_RANKING = 'GET_MEMBER_CELEBRATED_RANKING';
export const GET_MEMBER_CELEBRATOR_RANKING = 'GET_MEMBER_CELEBRATOR_RANKING';
export const GET_MEMBER_COMMENTER_RANKING = 'GET_MEMBER_COMMENTER_RANKING';
export const GET_TOP_CELEBRATED_LEADERBOARD = 'GET_TOP_CELEBRATED_LEADERBOARD';
export const GET_TOP_CELEBRATORS_LEADERBOARD =
  'GET_TOP_CELEBRATORS_LEADERBOARD';
export const GET_TOP_COMMENTERS_LEADERBOARD = 'GET_TOP_COMMENTERS_LEADERBOARD';
export const GET_MEMBERS = 'GET_MEMBERS';

export const GET_MEMBERS_DETAILS = 'GET_MEMBERS_DETAILS';
export const ADMIN_DELETE_MEDIA = 'ADMIN_DELETE_MEDIA';
export const ADMIN_DELETE_POST = 'ADMIN_DELETE_POST';
export const ADMIN_DELETE_COMMENT = 'ADMIN_DELETE_COMMENT';
export const GET_PAGINATED_MEMBERS = 'GET_PAGINATED_MEMBERS';

// Rewards
export const GET_CUSTOM_REWARD = 'GET_CUSTOM_REWARD';
export const GET_GIFT_CARDS = 'GET_GIFT_CARDS';
export const GET_DONATIONS = 'GET_DONATIONS';
export const GET_CHEAPEST_REWARD = 'GET_CHEAPEST_REWARD';
export const GET_MY_REWARDS = 'GET_MY_REWARDS';
export const GET_MY_REWARDS_HISTORY = 'GET_MY_REWARDS_HISTORY';
export const RESEND_EMAIL_FOR_MY_REWARDS = 'RESEND_EMAIL_FOR_MY_REWARDS';
export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES';
export const UPDATE_POST_REACTION = 'UPDATE_POST_REACTION';
export const UPDATE_COMMENT_REACTION = 'UPDATE_COMMENT_REACTION';
export const GET_IP = 'GET_IP';

export const GET_USER_INFO = 'GET_USER_INFO';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';

export const CULTURE_REWARD_CASHOUT = 'CULTURE_REWARD_CASHOUT';
export const REWARD_CASHOUT = 'REWARD_CASHOUT';

// REWARDS SWAGS
export const GET_AXOMO_INFO = 'GET_AXOMO_INFO';
export const ESTABLISH_AXOMO_CONNECTION = 'ESTABLISH_AXOMO_CONNECTION';
export const BREAK_AXOMO_CONNECTION = 'BREAK_AXOMO_CONNECTION';
export const FETCH_INVENTORY_SWAGS = 'FETCH_INVENTORY_SWAGS';
export const TOGGLE_REWARD_CATEGORY = 'TOGGLE_REWARD_CATEGORY';
export const TOGGLE_REWARD_ITEMS = 'TOGGLE_REWARD_ITEMS';
export const TOGGLE_REWARDS_VISIBILITY = 'TOGGLE_REWARDS_VISIBILITY';
export const EDIT_REWARD_ITEMS = 'EDIT_REWARD_ITEMS';
export const DELETE_REWARD_ITEMS = 'DELETE_REWARD_ITEMS';
export const FETCH_SINGLE_SWAG_ITEM_DETAILS = 'FETCH_SINGLE_SWAG_ITEM_DETAILS';
export const PLACE_REWARD_ITEM_ORDER = 'PLACE_REWARD_ITEM_ORDER';
export const AXOMO_REWARD_CATALOGUE = 'AXOMO_REWARD_CATALOGUE';
export const AXOMO_REWARD_CATEGORY = 'AXOMO_REWARD_CATEGORY';
export const GET_AXOMO_REWARD_ITEM = 'GET_AXOMO_REWARD_ITEM';
export const AXOMO_PLACE_ORDER = 'AXOMO_PLACE_ORDER';
export const UPDATE_REWARD_ITEM = 'UPDATE_REWARD_ITEM';
export const UPDATE_SHIPPING_COST = 'UPDATE_SHIPPING_COST';
export const GET_GENERAL_REWARDS_INFO = 'GET_GENERAL_REWARDS_INFO';

// SAML
export const GET_SAML_DETAILS = 'GET_SAML_DETAILS';
export const UPDATE_SAML_DETAILS = 'UPDATE_SAML_DETAILS';
export const ENABLE_SAML_DETAILS = 'ENABLE_SAML_DETAILS';
export const DISABLE_SAML_DETAILS = 'DISABLE_SAML_DETAILS';
export const UPDATE_SAML_QUICK_SETTINGS = 'UPDATE_SAML_QUICK_SETTINGS';
// Admin Rewards
export const ADMIN_CUSTOM_REWARD = 'ADMIN_CUSTOM_REWARD';
export const ADMIN_CUSTOM_REWARD_EDIT = 'ADMIN_CUSTOM_REWARD_EDIT';

//  SAML AUTH
export const AUTHENTICATE_SAML = 'AUTHENTICATE_SAML';
export const GET_SAML_AUTH_CONFIG = 'GET_SAML_AUTH_CONFIG';

//  GOOGLE ONE TAP
export const GOOGLE_ONE_TAP_AUTH = 'GOOGLE_ONE_TAP_AUTH';

// HOME PAGE FEEDS
export const GET_FLOW_FEED = 'GET_FLOW_FEED';
export const GET_MAIN_FEED = 'GET_MAIN_FEED';
export const GET_PROFILE_FEED = 'GET_PROFILE_FEED';
export const DELETE_FLOW_RESPONSE = 'DELETE_FLOW_RESPONSE';

// FLOWS
export const EDIT_FLOW = 'EDIT_FLOW';
export const FLOWS_ONBOARDING_JOB = 'FLOWS_ONBOARDING_JOB';
export const FLOWS_ACCOUNT_SETUP = 'FLOWS_ACCOUNT_SETUP';
export const WORKATO_QUICKSETUP = 'WORKATO_QUICKSETUP';
export const CREATE_FLOW = 'CREATE_FLOW';
export const GET_SHORTCUTS = 'GET_SHORTCUTS';
export const GET_TODO_FLOWS = 'GET_TODO_FLOWS';
export const GET_ACTIVE_FLOWS = 'GET_ACTIVE_FLOWS';
export const GET_FLOWS_ACTIVITY = 'GET_FLOWS_ACTIVITY';
export const GET_ARCHIVED_FLOWS = 'GET_ARCHIVED_FLOWS';
export const GET_PUBLISHED_FLOWS = 'GET_PUBLISHED_FLOWS';
export const UPDATE_FLOWS_ACCESS_POLICY = 'UPDATE_FLOWS_ACCESS_POLICY';

export const GET_FLOWS_INSTANCE = 'GET_FLOWS_INSTANCE';
export const GET_FLOW_BLOCK_PARTICIPANTS = 'GET_FLOW_BLOCK_PARTICIPANTS';
export const GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS =
  'GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS';
export const SUBMIT_FLOWS_INSTANCE = 'SUBMIT_FLOWS_INSTANCE';
export const SUBMIT_EXTERNAL_FLOWS_INSTANCE = 'SUBMIT_EXTERNAL_FLOWS_INSTANCE';

export const GET_FLOW_DETAILS = 'GET_FLOW_DETAILS';
export const GET_FLOW_POST_DOWNLOAD_URL = 'FLOW_POST_DOWNLOAD_URL';
export const UPDATE_FLOW_POST_REACTION = 'UPDATE_FLOW_POST_REACTION';
export const GET_FILE_UPLOAD_SIGNED_URL = 'GET_FILE_UPLOAD_SIGNED_URL';
export const GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL =
  'GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL';
export const GET_PROFILE_PICTURE_UPLOAD_SIGNED_URL =
  'GET_PROFILE_PICTURE_UPLOAD_SIGNED_URL';
export const GET_LOGO_UPLOAD_SIGNED_URL = 'GET_LOGO_UPLOAD_SIGNED_URL';
export const GET_FLOW_VIEWERS = 'GET_FLOW_VIEWERS';
export const GET_FLOW_RESPONSE = 'GET_FLOW_RESPONSE';
export const GET_MEMBER_NOTIFICATION_PREFERENCES =
  'GET_MEMBER_NOTIFICATION_PREFERENCES';
export const UPDATE_MEMBER_NOTIFICATION_PREFERENCES =
  'SET_MEMBER_NOTIFICATION_PREFERENCES';
export const DELETE_ATTACHMENT_FILE = 'DELETE_ATTACHMENT_FILE';
export const GENERATE_FLOW_DATA = 'GENERATE_FLOW_DATA';

// Flow templates
export const GET_FLOW_TEMPLATE = 'GET_FLOWS_TEMPLATE';
export const GET_ALL_FLOWS_TEMPLATES = 'GET_ALL_FLOWS_TEMPLATES';
export const GET_FLOWS_TEMPLATE_CATEGORIES = 'GET_FLOWS_TEMPLATE_CATEGORIES';

export const REQUEST_TEMPLATE = 'REQUEST_TEMPLATE';
export const REQUEST_TEMPLATE_FROM_SCRATCH = 'REQUEST_TEMPLATE_FROM_SCRATCH';

export const GET_FLOWS_TODO_COUNT = 'GET_FLOWS_TODO_COUNT';

// Flow Options
export const TRIGGER_FLOW = 'TRIGGER_FLOW';
export const END_OCCURRENCE = 'END_OCCURRENCE';
export const SEND_FLOW_REMINDER = 'SEND_FLOW_REMINDER';
export const GET_FLOW_PARTICIPANTS = 'GET_FLOW_PARTICIPANTS';
export const GET_FLOWS_FILTER_OPTIONS = 'GET_FLOWS_FILTER_OPTIONS';
export const GET_POSTED_BY_FILTER_OPTIONS = 'GET_POSTED_BY_FILTER_OPTIONS';
export const GET_DATE_FILTER_OPTIONS = 'GET_DATE_FILTER_OPTIONS';
export const HIDE_FLOW_ACTIVITY = 'HIDE_FLOW_ACTIVITY';
export const SHOW_FLOW_ACTIVITY = 'SHOW_FLOW_ACTIVITY';
export const TRIGGER_ARCHIVE_FLOW = 'TRIGGER_ARCHIVE_FLOW';
export const TRIGGER_UNARCHIVE_FLOW = 'TRIGGER_UNARCHIVE_FLOW';
export const GET_FLOWS_OCCURRENCE_DETAILS = 'GET_FLOWS_OCCURRENCE_DETAILS';
export const GET_FLOWS_VERSIONS_FILTER_OPTIONS =
  'GET_FLOWS_VERSIONS_FILTER_OPTIONS';
export const GET_FLOWS_BLOCKS_FILTER_OPTIONS =
  'GET_FLOWS_BLOCKS_FILTER_OPTIONS';
export const GET_FLOWS_MENTIONS_FILTER_OPTIONS =
  'GET_FLOWS_MENTIONS_FILTER_OPTIONS';
export const GET_EXTERNAL_FLOWS = 'GET_EXTERNAL_FLOWS';
export const VERIFY_FLOW_AUTHORIZATION = 'VERIFY_AUTHORIZATION_STATUS_FLOW';

// Flows Builder
export const GET_MEMBERS_FROM_CRITERIA = 'GET_MEMBERS_FROM_CRITERIA';
export const GET_ALL_DEPARTMENTS = 'GET_ALL_DEPARTMENTS';

// Quick Setup
export const GET_CALENDAR_LIST = 'GET_CALENDAR_LIST';
export const GET_QUICK_SETUP_STATUS = 'GET_QUICK_SETUP_STATUS';
export const CALENDAR_AUTHORIZATION = 'CALENDAR_AUTHORIZATION';
export const GET_CALENDAR_EMBED_URL = 'GET_CALENDAR_EMBED_URL';
export const GET_CALENDAR_EVENTS_LIST = 'GET_CALENDAR_EVENTS_LIST';
export const UPDATE_QUICK_SETUP_STATUS = 'UPDATE_QUICK_SETUP_STATUS';
export const GET_CALENDAR_EVENT_DETAILS = 'GET_CALENDAR_EVENT_DETAILS';

// IM
export const GET_IM_SETUP_JOB = 'GET_IM_SETUP_JOB';
export const GET_IM_CONFIG = 'GET_IM_CONFIG';
export const IM_AUTHORIZE = 'IM_AUTHORIZE';
export const GET_IM_INTEGRATION_DATA = 'GET_IM_INTEGRATION_DATA';

//  AUTH
export const VERIFY_CREATE_PASSWORD_TOKEN = 'VERIFY_CREATE_PASSWORD_TOKEN';
export const GET_LOGIN_OPTIONS = 'GET_LOGIN_OPTIONS';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';

//  ADMIN MERGE.DEV
export const GET_MERGE_LINK_TOKEN = 'GET_MERGE_LINK_TOKEN';
export const AUTHORIZE_MERGE_PUBLIC_TOKEN = 'AUTHORIZE_MERGE_PUBLIC_TOKEN';

// SISENSE
export const AUTHORIZE_SISENSE = 'AUTHORIZE_SISENSE';

// ADMIN_GENERAL_SETTINGS
export const UPDATE_GENERAL_SETTINGS = 'UPDATE_GENERAL_SETTINGS';

// NOTEBOOK
export const GET_NOTEBOOK_SECTION = 'GET_NOTEBOOK_SECTION';
export const POST_NOTEBOOK_TASKS = 'POST_NOTEBOOK_TASKS';
export const GET_NOTEBOOK_TODO_COUNT = 'GET_NOTEBOOK_TODO_COUNT';
export const GET_NOTEBOOK_RIGHT_DRAWER_TASK = 'GET_NOTEBOOK_RIGHT_DRAWER_TASK';
export const GET_NOTEBOOK_TASK_ASSIGNEE_LIST =
  'GET_NOTEBOOK_TASK_ASSIGNEE_LIST';
export const PUT_NOTEBOOK_MARK_TASKS_AS_READ =
  'PUT_NOTEBOOK_MARK_TASKS_AS_READ';
export const GET_NOTEBOOK_TASK_HISTORY = 'GET_NOTEBOOK_TASK_HISTORY';

// IntroJs
export const GET_TOURS = 'GET_TOURS';
export const UPDATE_TOURS = 'UPDATE_TOURS';

type EndpointDetails = Record<
  string,
  { requestType: Method; endpointURL: string }
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default <EndpointDetails>{
  [TEST]: {
    requestType: 'GET',
    endpointURL: '/test',
  },
  [TEST_WITH_SUB]: {
    requestType: 'GET',
    endpointURL: '/test/:sub',
  },
  [GET_NOTEBOOK_TASK_HISTORY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/notebook/task/details/:taskId/activity`,
  },
  [VERIFY_EMAIL_ONBOARDING]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/auth/onboard/register`,
  },
  [QUICK_SETUP_GUIDE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/guide`,
  },
  [GET_PROFILE_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/info`,
  },
  [UPDATE_PROFILE_INFO]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/info`,
  },
  [UPDATE_PROFILE_PICTURE]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/profile/updateAccount`,
  },
  [UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/flow`,
  },
  [MARK_NOTIFICATION_AS_READ]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/user/notification/markread`,
  },
  [V3_GET_FEED]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/posts`,
  },
  [GET_SINGLE_POST]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/posts/:postID`,
  },
  [EDIT_FLOW]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId`,
  },
  [CREATE_FLOW]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows`,
  },
  [GET_FLOW_RESPONSE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/response/:responseId`,
  },
  [DELETE_FLOW_RESPONSE]: {
    requestType: 'DELETE',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/response/:responseId`,
  },
  [UPDATE_POST_REACTION]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/posts/:postId/react/:action`,
  },
  [UPDATE_COMMENT_REACTION]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/comments/:commentId/react/:action`,
  },
  [V3_SEARCH_FEED]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/posts/searchv2`,
  },
  [GET_MEMBER_RECOGNITION_COUNT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/members/recognitionCount`,
  },
  [GET_CURRENT_PLAN]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/billing/planFeatures`,
  },
  [CREATE_POST]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/user/feed`,
  },
  [CREATE_POST_COMMENT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/posts/:postId/comment`,
  },
  [CREATE_FLOW_POST_COMMENT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/response/:responseId/comment`,
  },
  [GET_POST_COMMENTS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/posts/:postId/comments`,
  },
  [GET_FLOW_POST_COMMENTS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/response/:responseId/comment`,
  },
  [ANALYTICS_OVERALL_ENGAGEMENT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/overallEngagement`,
  },
  [ANALYTICS_CORE_VALUES_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/coreValues`,
  },
  [ANALYTICS_BADGES_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/badges`,
  },
  [ANALYTICS_PEOPLE_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/people`,
  },
  [ANALYTICS_RECOGNITION_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/recognition`,
  },
  [ANALYTICS_PROFILE_INFORMATION]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/profileInformation`,
  },
  [ANALYTICS_RECOGNITION_SUMMARY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/recognitionSummary`,
  },
  [ANALYTICS_PEOPLE_SUMMARY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/peopleSummary`,
  },
  [GET_JOB_STATUS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/monitoring/job/:jobId/status`,
  },
  [GET_MULTIPLE_JOBS_STATUS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/monitoring/job/status?ids=:jobIds`,
  },
  [SEND_WELCOME_MESSAGE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/sendWelcomeMessage`,
  },
  [GET_IDENTITY_MANAGER_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/identityManagerData`,
  },
  [SET_CUSTOM_CURRENCY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/setCustomCurrency`,
  },
  [SKIP_CUSTOM_CURRENCY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/skipCustomCurrency`,
  },
  [GET_CUSTOM_EMOJI]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/integrations/slack/employer/customEmojis`,
  },
  [MSTEAMS_BOT_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/msteam/integrationData`,
  },
  [MSTEAMS_SET_ACTIVE_CHANNEL]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/msteam/setActiveChannel`,
  },
  [MSTEAMS_CREATE_ACTIVE_CHANNEL]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/msteam/createActiveChannel`,
  },
  [SLACK_SET_ACTIVE_CHANNEL]: {
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/setActiveChannel`,
    requestType: 'POST',
  },
  [SLACK_CREATE_ACTIVE_CHANNEL]: {
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/createActiveChannel`,
    requestType: 'POST',
  },
  [GET_ADMIN_INTEGRATION_STATUS]: {
    endpointURL: `${API_URL_WITH_V2}/integrations/common/employer/connections/status`,
    requestType: 'GET',
  },
  [GET_INTEGRATION_STATUS]: {
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/botManagerData`,
    requestType: 'GET',
  },
  [GET_BOT_OAUTH_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/:sso/oauth2Config`,
  },
  [AUTHORIZE_BOT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/:sso/authorize`,
  },
  [SLACK_BOT_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/integrationData`,
  },
  [GET_SSO_SIGNIN_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/oauth2Config/signin`,
  },
  [GET_SSO_SIGNUP_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/oauth2Config/signup`,
  },
  [GET_SSO_OAUTH_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/oauth2Config/im`,
  },
  [SSO_SIGNIN]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/signin`,
  },
  [SSO_SIGNUP]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/signup`,
  },
  [SEARCH_MEMBERS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/autocomplete?keyword=:keyword`,
  },
  [SEARCH_POSTS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/posts/search`,
  },
  [GET_PREFERRED_SSO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/preferredSSO`,
  },
  [GET_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/:sso/integrationData`,
  },
  [GET_PAGINATED_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/:sso/pullData`,
  },
  [GET_PAGINATED_INTEGRATION_DATA_MAIN]: {
    // Todo: Suren, remove this once the new api updated
    requestType: 'GET',
    // eslint-disable-next-line max-len
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/merge/pullData?kind=Employee&limit=${PAGINATED_INTEGRATION_DATA_ITEMS_LIMIT}`,
  },
  [GET_IM_ENTITY_COUNT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/membersCountByAccountManagementType`,
  },
  [GET_ASSEMBLY_UNIQUE_SHORT_CODE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/generateUniqueShortCode`,
  },
  [GET_NOTIFICATIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/user/notification`,
  },
  [INVITE_USERS_CREATE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/invite/create`,
  },
  [GET_MEMBER_CELEBRATED_RANKING]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/memberCelebratedRanking`,
  },
  [GET_MEMBER_CELEBRATOR_RANKING]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/memberCelebratorRanking`,
  },
  [GET_MEMBER_COMMENTER_RANKING]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/memberCommenterRanking`,
  },
  [GET_TOP_CELEBRATED_LEADERBOARD]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/topCelebrated`,
  },
  [GET_TOP_CELEBRATORS_LEADERBOARD]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/topCelebrators`,
  },
  [GET_TOP_COMMENTERS_LEADERBOARD]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/topCommenters`,
  },
  [GET_MEMBERS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/members`,
  },
  [GET_MEMBERS_DETAILS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/membersDetails`,
  },
  [GET_REDEEM_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/billing/checkRedeem`,
  },
  [GET_CUSTOM_REWARD]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/rewards/getreward/custom`,
    // Todo: sort should be dynamic
  },
  [GET_GIFT_CARDS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/rewards/getreward/tango?category=gift%20card`,
  },
  [GET_CHEAPEST_REWARD]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/cheapestReward?country=:countryCode`,
  },
  [GET_AXOMO_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/axomo/info`,
  },
  [ESTABLISH_AXOMO_CONNECTION]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/axomo/connect`,
  },
  [BREAK_AXOMO_CONNECTION]: {
    requestType: 'DELETE',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/axomo/disconnect`,
  },
  [FETCH_INVENTORY_SWAGS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/rewardItems`,
  },
  [TOGGLE_REWARD_ITEMS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/rewards/toggle/:status`,
  },
  [TOGGLE_REWARDS_VISIBILITY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/visibility/:status`,
  },
  [AXOMO_REWARD_CATALOGUE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/rewardCatalogue`,
  },
  [AXOMO_REWARD_CATEGORY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/rewardCategory`,
  },
  [GET_AXOMO_REWARD_ITEM]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/:storeUID/:itemUID`,
  },
  [UPDATE_REWARD_ITEM]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/:storeUID/:rewardItemUID`,
  },
  [UPDATE_SHIPPING_COST]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/shippingCost`,
  },
  [GET_GENERAL_REWARDS_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/info`,
  },
  [AXOMO_PLACE_ORDER]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/placeOrder`,
  },
  [GET_DONATIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/rewards/getreward/tango?category=donation`,
  },
  [GET_EXCHANGE_RATES]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/rewards/exchangeRates`,
  },
  [GET_IP]: {
    requestType: 'GET',
    endpointURL: 'https://ipapi.co/json/',
  },
  [GET_MY_REWARDS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/user/settings/rewards`,
  },
  [GET_MY_REWARDS_HISTORY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/user/settings/history`,
  },
  [ADMIN_DELETE_MEDIA]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V2}/user/feed/admin/:postId/deleteMedia`,
  },
  [ADMIN_DELETE_POST]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/posts/archive`,
  },
  [ADMIN_DELETE_COMMENT]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/comments/archive`,
  },
  [GET_PAGINATED_MEMBERS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/a`,
  },
  [RESEND_EMAIL_FOR_MY_REWARDS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/user/settings/resendEmail`,
  },
  [GET_USER_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/auth/user/info`,
  },
  [USER_VERIFY_TOKEN]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/verifyToken`,
  },
  [UPDATE_USER_SETTINGS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/user/settings/updateaccount`,
  },
  [CULTURE_REWARD_CASHOUT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/rewards/cashout/custom`,
  },
  [REWARD_CASHOUT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/rewards/cashout`,
  },
  [GET_SAML_DETAILS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [UPDATE_SAML_DETAILS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [ENABLE_SAML_DETAILS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [DISABLE_SAML_DETAILS]: {
    requestType: 'DELETE',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [UPDATE_SAML_QUICK_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml/update`,
  },
  [AUTHENTICATE_SAML]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/saml/authentication`,
  },
  [GET_SAML_AUTH_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/saml/config`,
  },
  [GOOGLE_ONE_TAP_AUTH]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/identity/gsuite/oneTap`,
  },
  [ADMIN_CUSTOM_REWARD]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/employer/rewards/custom`,
  },
  [ADMIN_CUSTOM_REWARD_EDIT]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V2}/employer/rewards/custom/:rewardId`,
  },
  [GET_IM_SETUP_JOB]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/setup`,
  },
  [GET_IM_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/config`,
  },
  [IM_AUTHORIZE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/authorize`,
  },
  [GET_IM_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/integrationData`,
  },
  [GET_MAIN_FEED]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/main`,
  },
  [GET_PROFILE_FEED]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/profile/:profileId`,
  },
  [GET_FLOW_BLOCK_PARTICIPANTS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/block/:blockId/members/search`,
  },
  [GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/external/flows/:flowId/block/:blockId/members/search`,
  },
  [FLOWS_ONBOARDING_JOB]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/onboardingJob`,
  },
  [FLOWS_ACCOUNT_SETUP]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/jobs/accountSetup`,
  },
  [WORKATO_QUICKSETUP]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/workato/setup`,
  },
  [GET_SHORTCUTS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/list/shortcut`,
  },
  [GET_TODO_FLOWS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/list/todo`,
  },
  [GET_ARCHIVED_FLOWS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/list/archived`,
  },
  [GET_FLOWS_INSTANCE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/instance`,
  },
  [SUBMIT_FLOWS_INSTANCE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/instance`,
  },
  [GET_PUBLISHED_FLOWS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/list/published`,
  },
  [UPDATE_FLOWS_ACCESS_POLICY]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/accessPolicy`,
  },
  [GET_FLOW_DETAILS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/details`,
  },
  [GET_EXTERNAL_FLOWS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/external/flows/:flowId/details`,
  },
  [SUBMIT_EXTERNAL_FLOWS_INSTANCE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/external/flows/response/:flowId/submit?identifier=:identifier`,
  },
  [GET_FLOW_POST_DOWNLOAD_URL]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/response/:responseId/block/:blockId/file/:fileName`,
  },
  [DELETE_ATTACHMENT_FILE]: {
    requestType: 'DELETE',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/instance/:instanceId/block/:blockId/file/:fileName`,
  },
  [GET_FLOW_FEED]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flow/:flowId`,
  },
  [TRIGGER_FLOW]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/trigger`,
  },
  [TRIGGER_ARCHIVE_FLOW]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/archive`,
  },
  [TRIGGER_UNARCHIVE_FLOW]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/unarchive`,
  },
  [HIDE_FLOW_ACTIVITY]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/activity/hide`,
  },
  [SHOW_FLOW_ACTIVITY]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/activity/show`,
  },
  [END_OCCURRENCE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/endOccurrence`,
  },
  [SEND_FLOW_REMINDER]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/reminder`,
  },
  [GET_ACTIVE_FLOWS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/list/authorized`,
  },
  [GET_FLOWS_ACTIVITY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/activity`,
  },
  [UPDATE_FLOW_POST_REACTION]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/response/:responseId/react/:action`,
  },
  [GET_FLOWS_TODO_COUNT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/count/todo`,
  },
  [VERIFY_CREATE_PASSWORD_TOKEN]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/auth/session/forgotPassword/verify/:token`,
  },
  [GET_LOGIN_OPTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/signInOptions`,
  },
  [CREATE_PASSWORD]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/auth/session/resetPassword`,
  },
  [UPDATE_GENERAL_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/settings`,
  },
  [GET_FILE_UPLOAD_SIGNED_URL]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/instance/:instanceId/block/:blockId/file/:fileId`,
  },
  [GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/external/flows/:flowId/block/:blockId/file/:fileId`,
  },
  [GET_PROFILE_PICTURE_UPLOAD_SIGNED_URL]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/profile/uploadPicLink`,
  },
  [GET_LOGO_UPLOAD_SIGNED_URL]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/logoUploadLink`,
  },
  [GET_FLOWS_TEMPLATE_CATEGORIES]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/template/category`,
  },
  [GET_ALL_FLOWS_TEMPLATES]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/template`,
  },
  [REQUEST_TEMPLATE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/template/:templateId/request`,
  },
  [GET_FLOW_TEMPLATE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/template/:templateId`,
  },
  [REQUEST_TEMPLATE_FROM_SCRATCH]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/template/requestFromScratch`,
  },
  [GET_FLOW_PARTICIPANTS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/members/participants`,
  },
  [GET_FLOW_VIEWERS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/members/viewers`,
  },
  [GET_FLOWS_OCCURRENCE_DETAILS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/occurrence`,
  },
  [GET_MEMBER_NOTIFICATION_PREFERENCES]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/settings/notification`,
  },
  [UPDATE_MEMBER_NOTIFICATION_PREFERENCES]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/settings/notification`,
  },
  [GET_FLOWS_FILTER_OPTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/filter/flows`,
  },
  [GET_POSTED_BY_FILTER_OPTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/filter/postedBy`,
  },
  [GET_FLOWS_VERSIONS_FILTER_OPTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/filter/version`,
  },
  [GET_FLOWS_BLOCKS_FILTER_OPTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/filter/blocks`,
  },
  [GET_FLOWS_MENTIONS_FILTER_OPTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/filter/mentions`,
  },
  [GET_DATE_FILTER_OPTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/filter/date`,
  },
  [GENERATE_FLOW_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/:flowId/downloadData`,
  },
  [GET_MEMBERS_FROM_CRITERIA]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/membersFromCriteria`,
  },
  [GET_ALL_DEPARTMENTS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/departments`,
  },
  [GET_CALENDAR_EMBED_URL]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/calendar/embedUrl`,
  },
  [GET_CALENDAR_LIST]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/calendar`,
  },
  [GET_QUICK_SETUP_STATUS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/quickSetup`,
  },
  [CALENDAR_AUTHORIZATION]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/calendar/authorize`,
  },
  [GET_MERGE_LINK_TOKEN]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/merge/linkToken`,
  },
  [AUTHORIZE_MERGE_PUBLIC_TOKEN]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/merge/authorize`,
  },
  [AUTHORIZE_SISENSE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/sisense/login/sso`,
  },
  [GET_NOTEBOOK_SECTION]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/notebook/task/:tab/:section`,
  },
  [POST_NOTEBOOK_TASKS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/feed/notebook/task`,
  },
  [GET_NOTEBOOK_TODO_COUNT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/notebook/task/count`,
  },
  [GET_NOTEBOOK_TASK_ASSIGNEE_LIST]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/notebook/task/details/:taskId/assignees?keyword=:keyword`,
  },
  [PUT_NOTEBOOK_MARK_TASKS_AS_READ]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/notebook/task/read`,
  },
  [GET_CALENDAR_EVENTS_LIST]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/calendar/:calendarId/event`,
  },
  [UPDATE_QUICK_SETUP_STATUS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/quickSetup`,
  },
  [GET_CALENDAR_EVENT_DETAILS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/calendar/:calendarId/event/:eventId`,
  },
  [GET_NOTEBOOK_RIGHT_DRAWER_TASK]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/notebook/task/details/:taskId`,
  },
  [GET_TOURS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/tours`,
  },
  [UPDATE_TOURS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/users/tours/:tourName`,
  },
  [VERIFY_FLOW_AUTHORIZATION]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/external/flows/:flowId/validate`,
  },
};
