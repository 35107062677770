import { capitalizeFirstLetter } from '../../../Utils/text';

import { PostTypes, FeedTypes } from '../../../interfaces/Home';

export const ADMIN_SETTINGS = 'Admin Settings';

export const GIVEN_PRIVATELY = 'Given Privately';
export const YOU = 'You';
export const AND = 'and';
export const OTHERS = 'Others';
export const PEOPLE = 'People';

export const CHOOSE_A_CORE_VALUE = 'Choose a core value';
export const PREMIUM_FEATURE = 'Premium feature';
export const UPGRADE_TO_ACCESS = 'upgrade to access';

export const FEED_POST_REACTIONS_COMMENT_TEXT = 'Comment';
export const FEED_POST_REACTIONS_REACT_TEXT = 'React';
export const EMOTICON_PICKER_TEXT = 'Pick your emoji';
export const REACTION_PICKER_TOOLTIP_TEXT =
  'Reaction limit reached, looks like you have plenty to choose from!';

export const ADDED = 'added';

export const SOMETHING_WENT_WRONG = 'Something went wrong.';
export const TRY_AGAIN = 'Whoops! Please reload the page to try again.';
export const TRY_AGAIN_LATER = 'Whoops! Please try again later.';

export const getPostTypeString = (type: PostTypes) => {
  switch (type) {
    case PostTypes.CELEBRATED:
      return 'celebrated';

    case PostTypes.ANNIVERSARY:
      return 'anniversary';

    case PostTypes.BIRTHDAY:
      return 'birthday';

    default:
      return '';
  }
};

// Feed

const POST = 'post';
const COMMENT = 'comment';
const FEED = 'feed';

export const notificationDropdownHeading = 'Notifications';
export const notificationDropdownAllButton = 'All';
export const notificationDropdownUnreadButton = 'Unread';
export const notificationDropdownAllCaughtUpHeading = "You're all caught up";
export const notificationDropdownAllCaughtUpMessage =
  "You've seen all of your notifications";
export const notificationDropdownNoNotificationsHeading =
  'No notifications yet!';
export const notificationDropdownNoNotificationsMessage =
  'Celebrate a teammate to get started';

export const removeAttachmentModalInfo = {
  headerText: 'Are you sure?',
  contentHeading: 'Remove this attachment.',
  contentText:
    'When you remove this attachment, it will disappear from the post and from the feed at large.',
};

export const feedPostHeaderModalSubmitButtonText = 'Delete';
export const feedPostHeaderModalCancelButtonText = 'Cancel';

export const GIVE_TROPHIES_BACK_TO_SENDER =
  'Give trophies back to sender, remove from receiver balance';
export const ALLOW_THE_RECEIVER_TO_KEEP =
  'Allow the receiver to keep the trophies';

export const COPY_POST_LINK = 'Copy Post Link';
export const REMOVE_GIF = 'Remove GIF';
export const DELETE_POST = 'Delete Post';
export const EDIT_MY_ANSWERS = 'Edit my answers';
export const DELETE_POST_AND_ANSWER = 'Delete post and answers';

export const COPY_LINK = 'Copy Link';
export const DELETE_COMMENT = 'Delete Comment';

export const BOTTOM_OF_FEED_MESSAGE =
  'This is the end of the feed. You can only go up from here.';

const getFeedTypeString = (feedType: FeedTypes) => {
  return feedType === FeedTypes.COMMENT ? COMMENT : POST;
};

const getDisappearFromString = (feedType: FeedTypes) => {
  return feedType === FeedTypes.COMMENT ? POST : FEED;
};

export const getDeleteFeedModelInfo = (feedType: FeedTypes) => {
  const feedTypeString = getFeedTypeString(feedType);
  const disappearFromString = getDisappearFromString(feedType);
  return {
    headerText: 'Are you sure?',
    contentHeading: `Delete this ${feedTypeString}.`,
    contentText: `When you delete this ${feedTypeString}, it will disappear from the ${disappearFromString}.`,
  };
};

export const getDeleteFeedConfirmationModalInfo = (feedType: FeedTypes) => {
  const feedTypeString = getFeedTypeString(feedType);
  const capitalizedFeedTypeString = capitalizeFirstLetter(feedType);
  return {
    headerText: `Delete ${capitalizedFeedTypeString}`,
    contentHeading: `How do you want to handle the trophies given in this ${feedTypeString}?`,
  };
};

export const GET_DELETE_FLOW_MODEL_INFO = {
  headerText: 'Delete Post',
  contentText:
    // eslint-disable-next-line max-len
    'When you delete this post, it will disappear from the feed and all of your answers will be erased. You will be able to re-fill out this flow if the occurrence is still running or has a shortcut.',
};

export const GET_DELETE_FLOW_CONFIRMATION_MODAL_INFO = {
  headerText: 'Delete Post',
  contentHeading: 'How do you want to handle the trophies given in this post?',
};

// Header
export const FAILED_TO_LOAD_MEMBER_COUNT = 'Failed to load member count';
export const MEMBERS = 'Members';
export const GO_TO_MAIN_FEED = 'Go to Main Feed';

export const SHOW_ALL = 'Show All';
