import React, { memo } from 'react';
import assemblyLogo from '../../../img/svgs/assembly-logo-mono.svg';
import { AssemblyLanderBg, InnerWrapper, StyledImage } from './styles';
import LoadingPulse from '../../atoms/LoadingPulse';

const AssemblyLanderLoadingScreen = () => {
  return (
    <AssemblyLanderBg>
      <InnerWrapper>
        <StyledImage src={assemblyLogo} alt="assembly logo" />
        <LoadingPulse />
      </InnerWrapper>
    </AssemblyLanderBg>
  );
};

const MemoizedAssemblyLanderLoadingScreen = memo(AssemblyLanderLoadingScreen);
MemoizedAssemblyLanderLoadingScreen.displayName = 'AssemblyLanderLoadingScreen';
export default MemoizedAssemblyLanderLoadingScreen;
