import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routesList from '../../aV2/routes/routesList';
import AdminInsightsPageController from '../../controllers/admin/settings/insights/AdminInsightsPageController';

const useStyles = makeStyles({
  root: {
    marginTop: '24px',
  },
});

const SuperAdminAnalytics = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const [assemblyId, assemblyName] = useMemo(() => {
    const { searchParams } = new URL(window.location.href);
    return [searchParams.get('assemblyId'), searchParams.get('assemblyName')];
  }, []);
  if (!assemblyId) {
    push(routesList.SUPER_ADMIN_DASHBOARD_V2);
  }
  return (
    <div className={classes.root}>
      <AdminInsightsPageController
        isSuperAdmin
        assemblyId={assemblyId || undefined}
        assemblyName={assemblyName || undefined}
      />
    </div>
  );
};

export default SuperAdminAnalytics;
