// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { string, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  root: ({ color, isFullWidth, marginTop, marginBottom }) => ({
    height: '1px',
    width: isFullWidth ? '100%' : '150px',
    background: color,
    marginTop,
    marginBottom,
    boxSizing: 'border-box',
  }),
});

const Divider = ({
  color,
  isFullWidth,
  className: parentClassName,
  marginTop,
  marginBottom,
}) => {
  const { root } = useStyles({ color, isFullWidth, marginTop, marginBottom });
  const className = `${root} ${parentClassName}`;
  return <div className={className} />;
};

Divider.propTypes = {
  color: string,
  isFullWidth: bool,
  className: string,
  marginTop: string,
  marginBottom: string,
};

Divider.defaultProps = {
  color: ThemeV2.palette.gray6,
  isFullWidth: false,
  className: '',
  marginTop: '0',
  marginBottom: '0',
};

export default Divider;
