import { API_URL_WITH_V3, API_URL_WITH_V2 } from '../../config';

export const TEST = 'test';
export const TEST_WITH_SUB = 'testWithSub';
export const GET_SSO_OAUTH_CONFIG = 'getSSOOAuthConfig';
export const AUTHORIZE_SSO = 'authorizeSSO';
export const SIGN_IN_SSO = 'signInSSO';
export const GET_IDENTITY_MANAGER_DATA = 'getIdentityManagerData';
export const GET_INTEGRATION_DATA = 'getIntegrationData';
export const GET_JOB_STATUS = 'getJobStatus';
export const CONFIGURE_IDENTITY_MANAGER = 'configureIdentityManager';
export const GET_IDENTITY_MANAGER_SETTINGS_DATA = 'getSettingsData';
export const DISCONNECT_IDENTITY_MANAGER = 'disconnectIdentityManager';
export const SWITCH_ACCOUNT_PREVIEW = 'switchAccountPreview';
export const SWITCH_ACCOUNT = 'switchAccount';
export const GET_BOT_MANAGER_DATA = 'getBotManagerData';
export const DISCONNECT_BOT_MANAGER = 'disconnectBotManager';

export const GET_PENDING_REQUESTS = 'GET_PENDING_REQUESTS';
export const AUTO_APPROVE_MEMBERS = 'AUTO_APPROVE_MEMBERS';

const endpoints: Record<string, { method: string; url: string }> = {
  [GET_SSO_OAUTH_CONFIG]: {
    method: 'GET',
    url: `${API_URL_WITH_V3}/users/identity/:sso/oauth2Config/im`,
  },
  [AUTHORIZE_SSO]: {
    method: 'POST',
    url: `${API_URL_WITH_V3}/assembly/integration/:sso/authorize`,
  },
  [SIGN_IN_SSO]: {
    method: 'POST',
    url: `${API_URL_WITH_V3}/users/identity/:sso/signin`,
  },
  [GET_IDENTITY_MANAGER_DATA]: {
    method: 'GET',
    url: `${API_URL_WITH_V3}/assembly/integration/identityManagerData`,
  },
  [GET_INTEGRATION_DATA]: {
    method: 'GET',
    url: `${API_URL_WITH_V3}/assembly/integration/:sso/integrationData`,
  },
  [GET_JOB_STATUS]: {
    method: 'GET',
    url: `${API_URL_WITH_V3}/monitoring/job/:jobId/status`,
  },
  [CONFIGURE_IDENTITY_MANAGER]: {
    method: 'POST',
    url: `${API_URL_WITH_V3}/assembly/integration/:sso/configure`,
  },
  [GET_IDENTITY_MANAGER_SETTINGS_DATA]: {
    method: 'PUT',
    url: `${API_URL_WITH_V3}/assembly/integration/:sso/settings`,
  },
  [DISCONNECT_IDENTITY_MANAGER]: {
    method: 'PUT',
    url: `${API_URL_WITH_V3}/assembly/integration/:sso/disconnect`,
  },
  [SWITCH_ACCOUNT_PREVIEW]: {
    method: 'POST',
    url: `${API_URL_WITH_V2}/employer/billing/plans/switch/preview`,
  },
  [SWITCH_ACCOUNT]: {
    method: 'POST',
    url: `${API_URL_WITH_V2}/employer/billing/plans/switch`,
  },
  [GET_BOT_MANAGER_DATA]: {
    method: 'GET',
    url: `${API_URL_WITH_V3}/assembly/bot/botManagerData`,
  },
  [DISCONNECT_BOT_MANAGER]: {
    method: 'PUT',
    url: `${API_URL_WITH_V3}/assembly/bot/:sso/disconnect`,
  },
  [TEST]: {
    method: 'GET',
    url: '/test',
  },
  [TEST_WITH_SUB]: {
    method: 'GET',
    url: '/test/:sub',
  },
  [GET_PENDING_REQUESTS]: {
    method: 'GET',
    url: `${API_URL_WITH_V3}/assembly/members/invite`,
  },
  [AUTO_APPROVE_MEMBERS]: {
    method: 'POST',
    url: `${API_URL_WITH_V3}/assembly/members/invite/approve`,
  },
};

export default endpoints;
