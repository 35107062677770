import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FLOWS, V2_HOME } from '../../constants/routes';
import { APP_URL } from '../../config';

export const useMemberAuthenticated = () => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    (state) => state?.auth?.authenticated || false,
  );

  useEffect(() => {
    if (!isAuthenticated) {
      let path = pathname;
      if (search) {
        path = `${path}${search}`;
      }

      if (path.includes(FLOWS)) {
        push(`/e${path}`);
        return;
      }

      window.location.href = `${APP_URL}signin`;
    }
  }, [isAuthenticated, pathname, search, push]);
  return isAuthenticated;
};

export const useMemberNotAuthenticated = () => {
  const { push } = useHistory();
  const isAuthenticated = useSelector(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    (state) => state?.auth?.authenticated || false,
  );

  useEffect(() => {
    if (isAuthenticated) {
      push(V2_HOME);
    }
  }, [isAuthenticated, push]);
  return !isAuthenticated;
};
