import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import SVGIcon from '../../atomic/atoms/SVGIcon';

import ToastContent from '../../atomic/molecules/ToastContent';
import { WELCOME_TO_BUILDER_TOAST } from '../../languages/en/flows/builder';

export const defaultToastOptions: ToastOptions = {
  position: toast.POSITION.BOTTOM_LEFT,
};

export const showErrorMessage = (message: string) => {
  toast.error(
    <ToastContent icon="warning-circle" message={message} />,
    defaultToastOptions,
  );
};

export const showWarningMessage = (message: string) => {
  toast.warn(
    <ToastContent icon="warning-circle" message={message} />,
    defaultToastOptions,
  );
};

export const showInfoMessage = (message: string) => {
  toast.info(
    <ToastContent icon="info-filled" message={message} />,
    defaultToastOptions,
  );
};

export const showSuccessMessage = (message: string) => {
  toast.success(
    <ToastContent icon="checkmark-circle" message={message} />,
    defaultToastOptions,
  );
};

export const showWelcomeToBuilderToast = () => {
  toast(
    <ToastContent
      iconSize="88px"
      icon="accountSetup"
      title={WELCOME_TO_BUILDER_TOAST.title}
      message={WELCOME_TO_BUILDER_TOAST.subTitle}
    />,
    {
      autoClose: 5000,
      hideProgressBar: true,
      style: { width: '410px', paddingTop: '14px' },
      position: toast.POSITION.BOTTOM_LEFT,
      closeButton: <SVGIcon icon="close-rounded" size="16px" />,
    },
  );
};

export const dismissAllToasts = () => {
  toast.dismiss();
};
