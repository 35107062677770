export const lineHeightForFont = (fontSize: string) => {
  switch (fontSize) {
    case '12px':
      return '20px';
    case '20px':
      return '28px';
    case '14px':
      return '22px';
    case '16px':
      return '24px';
    case '18px':
      return '26px';
    default:
      return '28px';
  }
};
