// @ts-nocheck TODO: type issues need to be fixed in this file
import { useState, useCallback } from 'react';
import axios from 'axios';
import useAPIStateManagement from '../useAPIStateManagement';
import { AuthHeadersV2 } from '../../modules/shared/HeaderToken';
import endpointInfo from '../../constants/endpoints';

const makeAPICall = async (url, type = 'GET', payload, params, headers) =>
  axios.request({
    url,
    method: type,
    data: payload,
    params,
    headers,
  });

const useRequest = (
  apiName,
  {
    // optimisticUI = false,
    onSuccess = null,
    onError = null,
    onRequestStart = null,
    initialLoadingState = false,
    resetResponseOnRequest = false,
  } = {},
) => {
  const {
    requestErrorStateChange,
    requestStartStateChange,
    requestSuccessStateChange,
  } = useAPIStateManagement(apiName);

  /* const isOptimisticUI = Boolean(
    optimisticUI && requestObject.optimisticResponse,
  ); */
  const [isLoading, setLoading] = useState(initialLoadingState);
  const [error, setError] = useState();
  const [response, setResponse] = useState();
  // const [optimisticData, setOptimisticData] = useState();
  const fireRequest = useCallback(
    ({ payload = {}, params = {}, substitutions = {} } = {}) => {
      const { headers } = AuthHeadersV2();
      const requestDetails = endpointInfo[apiName];
      const { endpointURL, requestType } = requestDetails;
      let substitutedURL = endpointURL;
      Object.keys(substitutions).forEach((key) => {
        const substitution = substitutions[key];
        substitutedURL = substitutedURL.replace(`:${key}`, substitution);
      });
      setError();
      setLoading(true);
      if (resetResponseOnRequest) {
        setResponse();
      }
      requestStartStateChange({
        url: substitutedURL,
        method: requestType,
        params,
        data: payload,
        headers,
      });
      if (onRequestStart) {
        onRequestStart();
      }
      /* if (isOptimisticUI) {
      const optimisticData = requestObject.optimisticResponse(payload);
      onOptimisticUI && onOptimisticUI(optimisticData);
      setOptimisticData(optimisticData);
    } */
      return makeAPICall(substitutedURL, requestType, payload, params, headers)
        .then((responseData) => {
          if (onSuccess) {
            onSuccess(responseData);
          }
          setResponse(responseData);
          requestSuccessStateChange(responseData);
          return { response: responseData, error: null };
        })
        .catch((errorObject) => {
          if (onError) {
            onError(errorObject);
          }
          setError(errorObject);
          requestErrorStateChange(errorObject);
          return { response: null, error: errorObject };
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      apiName,
      resetResponseOnRequest,
      requestStartStateChange,
      onRequestStart,
      onSuccess,
      requestSuccessStateChange,
      onError,
      requestErrorStateChange,
    ],
  );

  const resetRequest = useCallback(() => {
    setLoading(false);
    setResponse(undefined);
    setError(undefined);
  }, []);

  return {
    response,
    isLoading,
    error,
    fireRequest,
    /* optimisticData, */
    resetRequest,
  };
};

export default useRequest;
