// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  string,
  oneOf,
  bool,
  func,
  oneOfType,
  number,
  shape,
  instanceOf,
} from 'prop-types';

import Clickable from '../Clickable';
import IconButton from '../IconButton';
import SVGIcon from '../SVGIcon';
import Body from '../Body';
import ThemeV2 from '../../../componentsV2/theme';

export const typeMap = {
  text: 'text',
  password: 'password',
  number: 'number',
};

const StyledOutlinedInput = styled(OutlinedInput)`
  height: 40px;
  display: flex;
  width: ${(props) => props.width};
  padding: 0px;
  color: ${ThemeV2.palette.gray8};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: ${(props) =>
    props.isvalidinput ? ThemeV2.palette.green7 : ThemeV2.palette.gray5};
  pointer-events: ${(props) => (props.isvalidinput ? 'none' : 'auto')};
  box-sizing: border-box;
  transition: all 0.3s;
  && .MuiInputAdornment-root {
    height: 40px;
    border-radius: 3px 0px 0px 3px;
    padding: 0px 12px;
    background-color: ${(props) =>
      props.disabled ? ThemeV2.palette.gray3 : 'inherit'};
  }
  &.MuiOutlinedInput-adornedStart,
  &.MuiOutlinedInput-adornedEnd {
    padding-left: 0px;
    padding-right: 0px;
  }
  &.MuiInputBase-adornedEnd {
    & .MuiInputAdornment-root {
      border-radius: 0px 3px 3px 0px;
    }
  }

  &:hover {
    &:not(.Mui-disabled) {
      border-color: ${ThemeV2.palette.geekBlue5};
    }
    & .MuiInputAdornment-root {
      background-color: ${(props) =>
        props.iconbackgroundcolor
          ? ThemeV2.palette.geekBlue6
          : ThemeV2.palette.gray1};
    }
    & .MuiInputAdornment-root svg path {
      fill: ${(props) =>
        props.iconbackgroundcolor
          ? ThemeV2.palette.gray1
          : ThemeV2.palette.gray6};
    }
  }
  &.Mui-focused {
    &:not(.Mui-disabled) {
      box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
      border-color: ${ThemeV2.palette.geekBlue5};
    }
    & .MuiInputAdornment-root {
      background-color: ${(props) =>
        props.iconbackgroundcolor || ThemeV2.palette.gray1};
    }
    & .MuiInputAdornment-root svg path {
      fill: ${(props) =>
        props.iconbackgroundcolor
          ? ThemeV2.palette.gray1
          : ThemeV2.palette.gray6};
      transition: all 0.3s;
    }
  }
  &.Mui-error {
    border-color: ${ThemeV2.palette.red6};
    & .MuiInputAdornment-root svg path {
      fill: ${(props) =>
        props.iconbackgroundcolor
          ? ThemeV2.palette.gray1
          : ThemeV2.palette.gray6};
    }
    &:hover {
      & .MuiInputAdornment-root {
        background-color: ${ThemeV2.palette.red6};
      }
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  & .MuiOutlinedInput-input {
    padding: 8px 12px;
    padding-left: ${(props) =>
      (props.icon && !props.iconbackgroundcolor) || props.domain
        ? '0px'
        : '12px'};
    font-size: 16px;
    line-height: 24px;
    height: unset;
    border-radius: 3px;
    &.Mui-disabled {
      border-color: ${(props) => props.disabled && ThemeV2.palette.gray5};
      background: ${(props) => props.disabled && ThemeV2.palette.gray3};
    }
    &::placeholder {
      color: ${ThemeV2.palette.gray6};
      opacity: 1;
    }
  }
  & .MuiInputAdornment-root {
    margin: 0;
    padding: 12px;
    padding-right: ${(props) =>
      props.icon && !props.iconbackgroundcolor ? '8px' : '12px'};
    max-height: 40px;
    background-color: ${(props) =>
      props.iconbackgroundcolor
        ? ThemeV2.palette[props.iconbackgroundcolor]
        : ThemeV2.palette.gray1};
    transition: all 0.3s;
    &.domainInput {
      width: 159px;
      padding-right: 4px;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    position: absolute;
    top: 50%;
    right: ${({ $endIcon }) => ($endIcon ? '40px' : '8px')};
    padding: 4px;
    transform: translateY(-50%);
  }
`;

const InputWrapper = styled.div`
  position: relative;
  & .MuiInputBase-root {
    height: auto;
    pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'auto')};
    border: ${({ $disabled }) => $disabled && 'none'};
  }
`;

const StyledClickable = styled(Clickable)`
  display: flex;
  background-color: ${({ $disabled }) =>
    $disabled ? `${ThemeV2.palette.gray3}` : 'inherit'};
`;

const TextField = ({
  type,
  onChange,
  onBlur,
  onFocus,
  value,
  placeholder,
  className: parentClassName,
  icon,
  iconSize,
  domain,
  error,
  autoFocus,
  isValid,
  name,
  fullWidth,
  width,
  iconBackgroundColor,
  disabled,
  innerRef,
  endIcon,
  onIconClick,
  onEndIconClick,
  clearTextEnabled,
  onClearClick,
  onClick,
}) => {
  let startAdornment = null;
  let endAdornment = null;
  if (icon) {
    startAdornment = (
      <InputAdornment position="start">
        <StyledClickable onClick={onIconClick} $disabled={disabled}>
          <SVGIcon icon={icon} size={iconSize} color={ThemeV2.palette.gray6} />
        </StyledClickable>
      </InputAdornment>
    );
  }
  if (endIcon) {
    endAdornment = (
      <InputAdornment position="start">
        <StyledClickable onClick={onEndIconClick} $disabled={disabled}>
          <SVGIcon
            icon={endIcon}
            size={iconSize}
            color={ThemeV2.palette.gray6}
          />
        </StyledClickable>
      </InputAdornment>
    );
  }
  if (domain) {
    startAdornment = (
      <InputAdornment className="domainInput" position="start">
        <Body variant="body1Italic">{domain}</Body>
      </InputAdornment>
    );
  }

  return (
    <InputWrapper $disabled={disabled}>
      <StyledOutlinedInput
        name={name}
        placeholder={placeholder}
        className={parentClassName}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        type={type}
        aria-describedby="outlined-weight-helper-text"
        labelWidth={0}
        notched
        error={error}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        disabled={disabled}
        ref={innerRef}
        onClick={onClick}
        iconbackgroundcolor={iconBackgroundColor}
        width={width}
        icon={icon}
        isvalidinput={(isValid && isValid.toString()) || null}
      />
      {clearTextEnabled && (
        <StyledIconButton
          color="primary"
          aria-label="caret icon"
          component="span"
          onClick={onClearClick}
          $endIcon={endIcon}
        >
          <SVGIcon
            icon="round-close"
            size={iconSize}
            color={ThemeV2.palette.gray8}
          />
        </StyledIconButton>
      )}
    </InputWrapper>
  );
};

TextField.propTypes = {
  type: oneOf(Object.keys(typeMap)),
  name: string,
  onChange: func,
  onBlur: func,
  onFocus: func,
  value: oneOfType([string, number]),
  placeholder: string.isRequired,
  icon: string,
  endIcon: string,
  onIconClick: func,
  onEndIconClick: func,
  iconSize: string,
  error: bool,
  autoFocus: bool,
  isValid: bool,
  domain: string,
  className: string,
  fullWidth: bool,
  iconBackgroundColor: oneOf(Object.keys(ThemeV2.palette)),
  width: string,
  disabled: bool,
  innerRef: oneOfType([
    // Either a function
    func,
    // Or the instance of a DOM native element (see the note about SSR)
    shape({ current: instanceOf(Element) }),
  ]),
  clearTextEnabled: bool,
  onClearClick: func,
  onClick: func,
};

TextField.defaultProps = {
  name: '',
  type: 'text',
  isValid: null,
  className: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  domain: null,
  icon: null,
  endIcon: null,
  onIconClick: () => {},
  onEndIconClick: () => {},
  iconSize: '18px',
  error: false,
  autoFocus: false,
  value: '',
  fullWidth: true,
  width: 'auto',
  iconBackgroundColor: null,
  disabled: false,
  innerRef: undefined,
  clearTextEnabled: false,
  onClearClick: () => {},
  onClick: () => {},
};

const MemoizedTextField = React.memo(TextField);
MemoizedTextField.displayName = 'TextField';

export default MemoizedTextField;
