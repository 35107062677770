import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SSOOptions, SSOTypes } from '../../../Utils/data/ssoManager/common';
import {
  SSOErrorCodes,
  getSSOErrorData,
} from '../../../Utils/data/ssoManager/error';
import RoutesList from '../../../aV2/routes/routesList';

const useSSOError = (
  sso: SSOOptions,
  ssoType: SSOTypes,
  errorCode: SSOErrorCodes,
) => {
  const { push } = useHistory();
  const { headerText, bodyText, buttonText, buttonIcon, urlToPush } =
    getSSOErrorData(errorCode, ssoType, sso);

  const handleButtonClick = useCallback(() => {
    push(urlToPush);
  }, [urlToPush, push]);

  let subText;
  switch (errorCode) {
    case SSOErrorCodes.SIGNIN_VIA_ADP:
    case SSOErrorCodes.SIGNIN_VIA_OFFICE365:
    case SSOErrorCodes.SIGNIN_VIA_SLACK:
    case SSOErrorCodes.SIGNIN_VIA_GOOGLE:
    case SSOErrorCodes.SIGNIN_VIA_SAML:
      subText = {
        text: 'Is this incorrect?',
        linkText: 'Return to sign in',
        linkPath: RoutesList.SIGN_IN,
      };
      break;
    default:
      break;
  }

  return {
    models: { headerText, bodyText, buttonText, buttonIcon, subText },
    operations: { onPrimaryButtonClick: handleButtonClick },
  };
};

export default useSSOError;
