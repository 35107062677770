import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/styles';
import Image from '../../atoms/Image';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import MSTeamConfigurePopupContentBG from '../../../img/MS-team-configure-popup-bg.svg';
import {
  setupConfigurePopupHeading,
  setupConfigurePopupText,
} from '../../../Utils/data/identityManager/ssoOffice365';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '400px',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-40px',
  },
  root: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    maxWidth: '476px',
    margin: 'auto',
  },
  image: {
    display: 'block',
    margin: 'auto',
  },
  title: {
    color: ThemeV2.palette.gray10,
    margin: '16px 0px 8px 0px',
  },
  text: {
    color: ThemeV2.palette.gray8,
  },
});

interface StyleProps {
  root: BaseCSSProperties;
  innerWrapper: BaseCSSProperties;
  image: BaseCSSProperties;
  title: BaseCSSProperties;
}

// type PropsClasses = Partial<Record<keyof StyleProps, string>>;

export interface MSTeamConfigurePopupContentProps {
  classes?: StyleProps;
}

const MSTeamConfigurePopupContent = (
  props: MSTeamConfigurePopupContentProps,
) => {
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.innerWrapper}>
          <Image
            className={classes.image}
            src={MSTeamConfigurePopupContentBG}
            alt="configure image"
          />
          <Heading variant="h5" className={classes.title}>
            {setupConfigurePopupHeading}
          </Heading>
          <Body className={classes.text}>{setupConfigurePopupText}</Body>
        </div>
      </div>
    </div>
  );
};

export default MSTeamConfigurePopupContent;
