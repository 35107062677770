import React from 'react';
import { Flex } from '../../../Utils/styles/display';
import { StyledButton } from './styles';
import styled from 'styled-components';

const Wrapper = styled(Flex)<{ disabled: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

interface Props {
  disabled: boolean;
  children: React.ReactNode;
}

const TriggerButton = ({
  disabled,
  children,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  return (
    <Wrapper disabled={disabled}>
      {React.Children.map(
        children as React.ReactElement[],
        (child: React.ReactElement) => React.cloneElement(child),
      )}
    </Wrapper>
  );
};

// eslint-disable-next-line react/display-name
const PrimaryTriggerButton = React.forwardRef<
  HTMLDivElement | null,
  {
    children: React.ReactNode;
    disabled: boolean;
    onPrimaryButtonClick: (e: React.MouseEvent) => void;
  }
>((props, ref) => {
  return (
    <StyledButton
      role="button"
      disabled={props?.disabled}
      ref={ref}
      onClick={props?.onPrimaryButtonClick}
    >
      {props?.children}
    </StyledButton>
  );
});

const SecondaryTriggerButton = ({
  children,
  onSecondaryButtonClick,
}: {
  children: React.ReactNode;
  onSecondaryButtonClick: (e: React.MouseEvent) => void;
}): React.ReactElement => (
  <div onClick={onSecondaryButtonClick}>{children}</div>
);

TriggerButton.PrimaryTriggerButton = PrimaryTriggerButton;
TriggerButton.SecondaryTriggerButton = SecondaryTriggerButton;

export default TriggerButton;
