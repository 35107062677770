import titleize from 'titleize';

export const ANALYTICS_EVENTS = {
  ACCOUNT_SIGN_UP: 'Account Setup - Sign Up',
  ACCOUNT_VERIFICATION: 'Account Setup - Verification',
  ACCOUNT_SETUP_COMPANY_NAME: 'Account Setup - Company Name',
  ACCOUNT_SETUP_PROFILE_NAME: 'Account Setup - Profile Name',
  ACCOUNT_SETUP_QUICK_INVITES: 'Account Setup - Quick Invites',
  ACCOUNT_SETUP_COMPLETE: 'Account Setup - Complete',

  PAGE_VIEW: 'page_view',
  CREATE_POST_START: 'feed.post.recognition.compose',
  CREATE_POST_GIVEN: 'feed.post.recognition.given',
  CREATE_POST_GIVE_ERROR: 'feed.post.recognition.give.error',
  RECOGNITION_PARTICIPATION_START: 'recognition.participation.start',
  RECOGNITION_PARTICIPATION_EXIT: 'recognition.participation.exit',
  RECOGNITION_PARTICIPATION_POST: 'recognition.participation.post',
  RECOGNITION_PARTICIPATION_POST_ERROR: 'recognition.participation.post.error',

  SAML_CONFIG_ENABLE_START: 'saml.enable.start',
  SAML_CONFIG_SAVE_TEST_ERROR: 'saml.save.test.error',
  SAML_CONFIG_SAVE_TEST_SUCCESS: 'saml.save.test.success',
};

export const FEED_ANALYTICS_EVENTS = {
  FEED_SORTED: 'feedSorted',
  FEED_SCROLLED: 'feedScrolled',
  VIEWERS_HOVERED: 'viewersHovered',
  VIEWERS_SEARCHED: 'viewersSearched',
  VIEWER_PROFILE_CLICKED: 'viewerProfileClicked',
  FLOW_FEED_MENU_OPENED: 'flowFeedMenuOpened',
  FLOW_ARCHIVED: 'flowArchived',
  FLOW_LINK_COPIED: 'flowLinkCopied',
  FLOW_RAN: 'flowRan',
  PARTICIPANTS_REMINDED: 'participantsReminded',
  OCCURRENCE_ENDED: 'occurrenceEnded',
  EDIT_NOTIFICATIONS_CLICKED: 'editNotificationSettingsClicked',
  HOME_CLICKED: 'homeClicked',
};

export const PAGE_NAMES = {
  HOME: 'Home',
  REDEEM: 'Redeem',
  SETTINGS: 'Settings',
  SIGN_OUT: 'Sign out',
};

export const ADMIN_PAGE_NAME = (path: string) => {
  return path
    .substring(1)
    .split('/')
    .map((sub) => titleize(sub))
    .join(' -> ');
};

export const LEFT_DRAWER_EVENT_NAME = 'left.drawer.events';
export const LEFT_DRAWER_ANALYTICS_EVENTS = {
  LEFT_DRAWER_EVENTS_LEFT_DRAWER_COLLAPSED: 'leftDrawerCollapsed',
  LEFT_DRAWER_EVENTS_LEFT_DRAWER_EXPANDED: 'leftDrawerExpanded',
  LEFT_DRAWER_EVENTS_REDEEM_CLICKED: 'redeemClicked',
  LEFT_DRAWER_EVENTS_MAIN_FEED_CLICKED: 'mainFeedClicked',
  LEFT_DRAWER_EVENTS_REWARDS_CLICKED: 'rewardsClicked',
  LEFT_DRAWER_EVENTS_FEEDS_LIST_EXPANDED: 'feedListExpanded',
  LEFT_DRAWER_EVENTS_FEEDS_LIST_COLLAPSED: 'feedListCollapsed',
  LEFT_DRAWER_EVENTS_FLOW_CLICKED: 'flowClicked',
  LEFT_DRAWER_EVENTS_FLOW_MENU_OPENED: 'flowMenuOpen',
  LEFT_DRAWER_EVENTS_FLOW_ARCHIVED: 'flowArchived',
  LEFT_DRAWER_EVENTS_FLOW_LINK_COPIED: 'flowLinkCopied',
  LEFT_DRAWER_EVENTS_FLOW_RAN: 'flowRan',
  LEFT_DRAWER_EVENTS_PARTICIPANTS_REMINDED: 'participantsReminded',
  LEFT_DRAWER_EVENTS_OCCURRENCE_ENDED: 'occurrenceEnded',
  LEFT_DRAWER_EVENTS_EDIT_NOTIFICATION_SETTINGS_CLICKED:
    'editNotificationSettingsClicked',
  LEFT_DRAWER_EVENTS_ARCHIVED_LIST_EXPANDED: 'archivedListExpanded',
  LEFT_DRAWER_EVENTS_ARCHIVED_LIST_COLLAPSED: 'archivedListCollapsed',
  LEFT_DRAWER_EVENTS_FLOW_UNARCHIVED: 'flowUnarchived',
  LEFT_DRAWER_EVENTS_FLOW_UNARCHIVE_CANCELLED: 'flowUnarchiveCancelled',
  LEFT_DRAWER_EVENTS_UNREAD_NOTIFICATIONS_VIEW_CLICKED:
    'unreadNotificationsViewClicked',
  LEFT_DRAWER_EVENTS_ALL_NOTIFICATIONS_VIEW_CLICKED:
    'allNotificationsViewClicked',
  LEFT_DRAWER_EVENTS_MARKED_ALL_READ_CLICKED: 'markedAllReadClicked',
  LEFT_DRAWER_EVENTS_NOTIFICATION_CLICKED: 'notificationClicked',
  LEFT_DRAWER_EVENTS_NOTIFICATION_SETTINGS_CLICKED:
    'notificationSettingsClicked',
  LEFT_DRAWER_EVENTS_SETTINGS_GENERAL_CLICKED: 'settingsGeneralClicked',
  LEFT_DRAWER_EVENTS_SETTINGS_NOTIFICATIONS_CLICKED:
    'settingsNotificationsClicked',
};

export const POST_ANALYTICS_EVENTS = {
  SEE_FULL_POST_CLICK: 'seeFullPostClicked',
  MENU_CLICKED: 'postMenuClicked',
  DELETED: 'postDeleted',
  LINK_COPIED: 'postLinkCopied',
  MEMBER_NAME_CLICKED: 'postMemberNameClicked',
  FLOW_CLICKED: 'flowclicked',
  MENTIONED_MEMBER_NAME_CLICKED: 'mentionedMemberNameClicked',
  FILE_DOWNLOADED: 'postFileDownloaded',
  COMMENT_STARTED: 'commentStarted',
  COMMENT_POSTED: 'commentPosted',
  COMMENT_EXITED: 'commentExited',
  COMMENT_ERRORED: 'commentErrored',
  COMMENT_DELETED: 'commentDeleted',
  COMMENT_DELETE_ERRORED: 'commentDeleteErrored',
  COMMENT_REACTION_ADDED: 'reactionAdded',
  COMMENT_REACTION_ADD_ERRORED: 'reactionAddErrored',
  COMMENT_REACTION_REMOVED: 'reactionRemoved',
};

export const FLOW_BUILDER_EVENTS = {
  // Action Events
  FLOW_CREATED: 'flowCreated',
  FLOW_PREVIEWED: 'flowPreviewed',
  FLOW_EDIT_SAVED: 'flowEditSaved',
  ACTION_SELECTED: 'actionSelected',
  FLOW_EMOJI_ADDED: 'flowEmojiAdded',
  FLOW_TITLE_ADDED: 'flowTitleAdded',
  TRIGGER_SELECTED: 'triggerSelected',
  VIEWERS_SELECTED: 'viewersSelected',
  FLOW_DRAFT_EXITED: 'flowDraftExited',
  FLOW_EDIT_CLICKED: 'flowEditClicked',
  FLOW_EDIT_REVERTED: 'flowEditReverted',
  FLOW_OWNER_CHANGED: 'flowOwnerChanged',
  CONTENT_BLOCK_ADDED: 'contentBlockAdded',
  CONTENT_BLOCK_DELETED: 'contentBlockDeleted',
  PARTICIPANTS_SELECTED: 'participantsSelected',
  FLOW_DESCRIPTION_ADDED: 'flowDescriptionAdded',
  SCHEDULE_START_DATE_SELECTED: 'scheduleStartDateSelected',
  SCHEDULE_START_TIME_SELECTED: 'scheduleStartTimeSelected',
  SCHEDULE_END_DATE_SELECTED: 'scheduleEndDateSelected',
  SCHEDULE_END_TIME_SELECTED: 'scheduleEndTimeSelected',
  RECURRENCE_SELECTED: 'recurrenceSelected',
  DUE_DATE: 'dueDate',
  SHORT_CUT: 'shortcut',

  // Show Events
  ACTION_SHOW: 'actionShow',
  BUILDER_SHOW: 'builderShow',
  TRIGGER_SHOW: 'triggerShow',
  CONTENT_SHOW: 'contentShow',
  VISIBILITY_SHOW: 'visibilityShow',
  CONFIRMATION_SHOW: 'confirmationShow',
  PARTICIPATION_SHOW: 'participationShow',

  // Error Events
  EMOJI_ERROR: 'emojiError',
  FLOW_TITLE_ERROR: 'flowTitleError',
  FLOW_OWNER_ERROR: 'flowOwnerError',
  CREATE_FLOW_ERROR: 'createFlowError',
  SELECT_VIEWERS_ERROR: 'selectViewersError',
  SELECT_CONTENT_ERROR: 'selectContentError',
  FLOW_DESCRIPTION_ERROR: 'flowDescriptionError',
  SCHEDULE_END_TIME_ERROR: 'scheduleEndTimeError',
  SCHEDULE_DUE_DATE_ERROR: 'scheduleDueDateError',
  SCHEDULE_END_DATE_ERROR: 'scheduleEndDateError',
  EDIT_SAVE_CHANGES_ERROR: 'editSaveChangesError',
  EDIT_REVERT_CHANGES_ERROR: 'editRevertChangesError',
  SCHEDULE_START_DATE_ERROR: 'scheduleStartDateError',
  SCHEDULE_START_TIME_ERROR: 'scheDuleStartTimeError',
  SELECT_PARTICIPANTS_ERROR: 'selectParticipantsError',
};

export const RIGHT_DRAWER_EVENT_NAME = 'right.drawer.events';
export const RIGHT_DRAWER_EVENTS = {
  RIGHT_DRAWER_EXPANDED: 'rightDrawerExpanded',
  RIGHT_DRAWER_COLLAPSED: 'rightDrawerCollapsed',
  TAB_CLICKED: 'tabClicked',
  FILTER_SECTION_EXPANDED: 'filterSectionExpanded',
  FILTER_SECTION_COLLAPSED: 'filterSectionCollapsed',
  FILTERS_APPLIED: 'filtersApplied',
  FILTERS_CLEARED: 'filtersCleared',
  MEMBER_SEARCHED: 'memberSearched',
  MEMBER_CLICKED: 'memberClicked',
  SHOW_MORE_CLICKED: 'showMoreClicked',
  SHOW_LESS_CLICKED: 'showLessClicked',
  CLICK_MEMBER_NAME: 'clickMemberName',
};

export const PARTICIPATION_ANALYTICS_EVENTS = {
  START: 'participationStarted',
  BLOCK_ANSWERED: 'blockAnswered',
  BLOCK_SKIPPED: 'blockSkipped',
  POSTED: 'participationPosted',
  EXIT_TRIGGERED: 'participationExited',
  EXIT_LEAVE_CLICKED: 'participationExitLeaveClicked',
  EXIT_CANCELED: 'participationExitCanceled',
  ERRORED: 'participationErrored',
  NAVIGATION_PREV_CLICKED: 'navigationUpClicked',
  NAVIGATION_NEXT_CLICKED: 'navigationDownClicked',
  PROGRESSBAR_CLICKED: 'progressBarClicked',
  VIEWERS_HOVERED: 'viewersHovered',
  VIEWERS_SEARCHED: 'viewersSearched',
  NEED_HELP_CLICKED: 'needHelpClicked',
};

export const ACCOUNT_ONBOARDING_EVENTS = {
  GO_BACK: 'goBack',
  NEXT_STEP: 'nextStep',
  SKIP_SETUP: 'skipSetup',
  LEAVE_SETUP: 'leaveSetup',
  SELECT_PATH: 'selectPath',
  FINISH_SETUP: 'finishSetup',
  INVITE_LATER: 'inviteLater',
  REQUEST_DEMO: 'requestDemo',
  USE_TEMPLATE: 'useTemplate',
  INVITE_CREATE: 'inviteCreate',
  SELECT_MEETING: 'selectMeeting',
  CALENDAR_CONNECT: 'calendarConnect',
  TEMPLATE_SELECTED: 'templateSelected',
  COMPLETE_ONBOARDING: 'completeOnboarding',
  EXPLORE_TEMPLATES: 'exploreTemplates',
};

export const INVITE_MEMBER_EVENTS = {
  INVITE_START: 'inviteStart',
  COPY_INVITE_LINK: 'copyInviteLink',
  INVITE_COMPLETE: 'inviteComplete',
};

export const MANAGE_USERS_EVENTS = {
  INVITE_START: 'inviteStart',
  COPY_INVITE_LINK: 'copyInviteLink',
  INVITE_COMPLETE: 'inviteComplete',
};

export const TEMPLATE_GALLERY_EVENTS = {
  APPLY_FILTER: 'applyFilter',
  PREVIEW_TEMPLATE: 'previewTemplate',
  USE_TEMPLATE: 'useTemplate',
  SEARCH: 'search',
  START_FROM_SCRATCH: 'startFromScratch',
  TEMPLATE_GALLERY_SHOW: 'templateGalleryShow',
};

export const ACTION_EVENTS = {
  SHOW: 'show',
  ERROR: 'error',
  ACTION: 'action',
};

export const MAIN_FEED_ACTION_BAR_EVENTS = {
  DROPDOWN_ACTION_BAR_OPENED: 'dropdownActionBarOpened',
  ACTION_BAR_DROPDOWN_CLOSED: 'actionBarDropdownClosed',
  ACTION_BAR_DROPDOWN_SEARCHED: 'actionBarDropdownSearched',
  ACTION_BAR_DROPDOWN_SEARCH_CLEAR_CLICKED:
    'actionBarDropdownSearchClearClicked',
  ADD_FLOW_CLICKED: 'addFlowClicked',
  NEED_HELP_CLICKED: 'needHelpClicked',
  PARTICIPATION_STARTED: 'participationStarted',
  VIEW_FEED_CLICKED: 'viewFeedClicked',
};

export const NOTEBOOK_EVENTS = {
  NOTEBOOK_TASK_CREATION: 'noteCreated',
  NOTEBOOK_SHOW: 'notebookShow',
  NOTEBOOK_ERROR: 'notebookError',
  NOTEBOOK_SECTION_COLLAPSED: 'sectionCollapse',
  NOTEBOOK_SECTION_EXPANDED: 'sectionExpand',
  NOTEBOOK_SECTION_CHANGED: 'noteSectionChange',
};

export const NOTEBOOK_TASK_EVENTS = {
  NOTEBOOK_TASK_CREATE: 'create',
  NOTEBOOK_TASK_ARCHIVE: 'archive',
  NOTEBOOK_TASK_DELETE: 'delete',
  NOTEBOOK_TASK_TITLE_UPDATE: 'updateTitle',
  NOTEBOOK_TASK_ASSIGNMENT_UPDATE: 'updateAssignment',
  NOTEBOOK_TASK_STATUS_UPDATE: 'updateStatus',
  NOTEBOOK_TASK_DUE_DATE_UPDATE: 'updateDueDate',
  NOTEBOOK_TASK_SHOW: 'notebookTaskShow',
  NOTEBOOK_TASK_ERROR: 'notebookTaskError',
};

export const SHARE_SHEET_EVENTS = {
  SHARE_SHEET_SHOW: 'shareSheetShow',
  SHARE_SHEET_STARTED: 'shareSheetStarted',
  COPY_LINK: 'copyLink',
  PARTICIPANTS_SELECTED: 'participantsSelected',
  VIEWERS_SELECTED: 'viewersSelected',
  SHARE_SHEET_COMPLETED: 'shareSheetCompleted',
  SHARE_SHEET_ERROR: 'shareSheetError',
  ANONYMOUS_TOGGLE_ON: 'anonymousToggleOn',
  ANONYMOUS_TOGGLE_OFF: 'anonymousToggleOff',
  ANONYMOUS_OPTIONAL_ON: 'anonymousOptionalOn',
  UPDATE_LINK: 'updateLink',
  NEW_LINK_SETTINGS: 'newLinkSettings',
};

export enum AnalyticsEvents {
  SHOW = 'show',
  ACTION = 'action',
  ERROR = 'error',
}

export const NOTEBOOK_EVENT = 'notebook.events';
export const NOTEBOOK_TASK_EVENT = 'task.events';
export const BUILDER_EVENTS = 'builder.events';
export const FLOW_INSIGHTS_EVENTS = 'insights.events';
export const ONBOARDING_EVENTS = 'account.onboarding.events';
export const INVITE_MEMBER_EVENT = 'invite.events';
export const TEMPLATE_GALLERY_EVENT = 'template.events';
export const FEED_EVENTS = 'feed.events';
export const SHARE_SHEET_EVENT = 'share.sheet.events';
