import React, { useCallback, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import {
  Container,
  ErrorContainer,
  PopoverContent,
  PopoverHeader,
  SVGContainer,
} from './styles';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import Tooltip from '../ToolTipV2';
import Popover from '../Popover';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import routesList from '../../../aV2/routes/routesList';
import {
  NEED_A_BOOST_BUTTON,
  NEED_A_BOOST_CONTENT,
  NEED_A_BOOST_HEADER,
  DOING_GREAT_CONTENT,
  DOING_GREAT_HEADER,
  GIVE_RECOGNITION_BUTTON,
  TOP_PERFORMER_CONTENT,
  TOP_PERFORMER_HEADER,
  ADD_INFO_BUTTON,
  DOING_GREAT_CONTENT_PROFILE_INFO,
  NEED_A_BOOST_CONTENT_PROFILE_INFO,
  TOP_PERFORMER_CONTENT_PROFILE_INFO,
  BENCHMARK_ERROR_TOOLTIP,
} from '../../../constants/analytics/benchmark/constants';
import { BenchmarkStatus } from '../../../interfaces/analytics/benchmarking/interfaces';

export interface BenchmarkStatusIconProps {
  status?: BenchmarkStatus;
  loading?: boolean;
  size?: number;
  error?: boolean;
  isProfileInfo?: boolean;
  onShowTipsClick?: () => void;
}

const BenchmarkStatusIcon = ({
  status,
  loading,
  size = 20,
  error,
  isProfileInfo,
  onShowTipsClick,
}: BenchmarkStatusIconProps) => {
  const { push } = useHistory();
  const onGiveRecognitionClick = useCallback(
    () => push(routesList.HOME),
    [push],
  );
  const onAddInfoClick = useCallback(
    () => push(routesList.ADMIN_USER_MANAGE),
    [push],
  );
  const onCheckTipsClick = useCallback(() => {
    if (onShowTipsClick) {
      onShowTipsClick();
    }
  }, [onShowTipsClick]);
  const [selectedIcon, headerContent, bodyContent, buttonText, onButtonClick] =
    useMemo(() => {
      switch (status) {
        case BenchmarkStatus.TOP_PERFORMER: {
          return [
            'benchmark-top-performer',
            TOP_PERFORMER_HEADER,
            isProfileInfo
              ? TOP_PERFORMER_CONTENT_PROFILE_INFO
              : TOP_PERFORMER_CONTENT,
            isProfileInfo ? false : GIVE_RECOGNITION_BUTTON,
            isProfileInfo ? onAddInfoClick : onGiveRecognitionClick,
          ];
        }
        case BenchmarkStatus.DOING_GREAT: {
          return [
            'benchmark-doing-great',
            DOING_GREAT_HEADER,
            isProfileInfo
              ? DOING_GREAT_CONTENT_PROFILE_INFO
              : DOING_GREAT_CONTENT,
            isProfileInfo ? ADD_INFO_BUTTON : GIVE_RECOGNITION_BUTTON,
            isProfileInfo ? onAddInfoClick : onGiveRecognitionClick,
          ];
        }
        case BenchmarkStatus.NEED_A_BOOST:
        default: {
          return [
            'benchmark-need-boost',
            NEED_A_BOOST_HEADER,
            isProfileInfo
              ? NEED_A_BOOST_CONTENT_PROFILE_INFO
              : NEED_A_BOOST_CONTENT,
            isProfileInfo ? ADD_INFO_BUTTON : NEED_A_BOOST_BUTTON,
            isProfileInfo ? onAddInfoClick : onCheckTipsClick,
          ];
        }
      }
    }, [
      isProfileInfo,
      onAddInfoClick,
      onCheckTipsClick,
      onGiveRecognitionClick,
      status,
    ]);
  const PopoverContainer = useMemo(
    () => (
      <Container>
        <PopoverHeader>
          <SVGIcon size="24px" icon={selectedIcon} />
          <Body inline variant="body2Medium">
            {headerContent}
          </Body>
        </PopoverHeader>
        <PopoverContent>
          <Body variant="body3">{bodyContent}</Body>
        </PopoverContent>
        {buttonText && (
          <Button
            color="secondary"
            isFullWidth
            size="small"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </Container>
    ),
    [bodyContent, buttonText, headerContent, onButtonClick, selectedIcon],
  );
  const Icon = useCallback(
    () => (
      <SVGContainer data-testid={selectedIcon}>
        <SVGIcon size={`${size}px`} icon={selectedIcon} />
      </SVGContainer>
    ),
    [size, selectedIcon],
  );
  if (error) {
    return (
      <ErrorContainer data-testid="error-status">
        <Tooltip
          position="bottom"
          icon="error-warning"
          iconColor={ThemeV2.palette.gray6}
          iconSize="12px"
        >
          {BENCHMARK_ERROR_TOOLTIP}
        </Tooltip>
      </ErrorContainer>
    );
  }
  if (loading) {
    return (
      <ContentLoader
        speed={2}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        data-testid="loader"
      >
        <circle cx={`${size / 2}`} cy={`${size / 2}`} r="10" />
      </ContentLoader>
    );
  }
  if (status) {
    return (
      <Popover
        popoverContent={PopoverContainer}
        referenceContent={<Icon />}
        popoverPosition={isProfileInfo ? 'top' : 'bottom'}
      />
    );
  }
  return null;
};

export default BenchmarkStatusIcon;
