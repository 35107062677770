// @ts-nocheck TODO: type issues need to be fixed in this file
import { get } from 'lodash';
import { VERIFY_CODE, COMPLETE_PROFILE, INVITE_EMAILS, RESEND_CODE } from '.';

export const getRequestLoadingStatus = (state) =>
  state.v2Onboarding.pending > 0;

export const getEmailRegistered = (state) => state.v2Onboarding.email;

export const getCompanyName = (state) =>
  get(state, ['v2Onboarding', 'data', 'companyName'], null);

export const getVerifyRequestLoadingStatus = (state) =>
  state.v2Onboarding.mutations[VERIFY_CODE].pending > 0;

export const getCompleteProfileRequestLoadingStatus = (state) =>
  state.v2Onboarding.mutations[COMPLETE_PROFILE].pending > 0;

export const getInviteEmailRequestLoadingStatus = (state) =>
  state.v2Onboarding.mutations[INVITE_EMAILS].pending > 0;

export const getResendCodeRequestLoadingStatus = (state) =>
  state.v2Onboarding.mutations[RESEND_CODE].pending > 0;
