// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import Loadable from 'react-loadable';

// Add center loader
export const LoadableAdminComponent = Loadable({
  loader: () => import('../screens/Admin'),
  loading() {
    return <div />;
  },
});

export const preloadAdmin = () => {
  LoadableAdminComponent.preload();
};
