// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import TextField from '../../../componentsV2/core/TextField';
import Button from '../../../componentsV2/core/Button';
import Link from '../../../componentsV2/core/Link';
import themeV2 from '../../../componentsV2/theme';

import RoutesList from '../../routes/routesList';
import { forgotPassword } from '../../modules/login';
import { getForgotPasswordRequestLoadingStatus } from '../../modules/login/selectors';
import { handleErrorAction } from '../../utils/ErrorHandler';
import { isValidEmail } from '../../../Utils/validation';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    height: 348,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  textFieldStyle: {
    marginTop: 24,
  },
  subtitleStyle: {
    fontSize: 14,
    fontWeight: `${themeV2.typography.fontWeightRegular} !important`,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeV2.palette.darkText,
  },
  link: {
    fontSize: 12,
  },
  terms: {
    color: themeV2.palette.lightGray1,
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailErrors: null,
      emailTouched: false,
    };
  }

  onButtonClick = (e) => {
    e.preventDefault();
    let { email } = this.state;
    email = email.trim();

    let goThrough = true;
    if (!email) {
      this.setState({ emailErrors: 'Email is required' });
      goThrough = false;
    }

    if (!isValidEmail(email)) {
      this.setState({ emailErrors: 'Enter a valid email' });
    }

    if (goThrough) {
      this.props
        .forgotPassword({ email })
        .then((successAction) => {
          this.props.history.push(RoutesList.FORGOT_PASSWORD_SUCCESS);
        })
        .catch((errorOrAbortAction) => {
          // handle error or aborted request
          const error = handleErrorAction(errorOrAbortAction);
          if (error) {
            this.setState({ emailErrors: error.message });
          }
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
      emailErrors: null,
      emailTouched: true,
    });
  };

  render() {
    const { classes, loading } = this.props;
    const { email, emailErrors, emailTouched } = this.state;

    const isButtonDisabled = !emailTouched || !!emailErrors;
    return (
      <Layout>
        <Card className={classes.card}>
          <form onSubmit={this.onButtonClick}>
            <Typography variant="h5" weight="bold" gutterBottom>
              Forgot your password?
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitleStyle}>
              No problem. Enter your email and we'll send you instructions for
              resetting your password!
            </Typography>
            <TextField
              id="email"
              label="Your email address"
              placeholder="bruce@wayneenterprises.com"
              value={email}
              onChange={this.handleChange}
              rootStyle={{ width: '100%' }}
              error={emailErrors}
              className={classes.textFieldStyle}
              autoFocus
            />
            <Button
              style={{ width: '100%', marginTop: 20 }}
              disabled={isButtonDisabled || loading}
              variant="contained"
              color="secondary"
              type="submit"
            >
              {loading ? 'Sending email...' : 'Send email'}
            </Button>
            <Typography variant="body2" gutterBottom style={{ marginTop: 5 }}>
              Remember your password?{' '}
              <Link to={RoutesList.SIGN_IN}>Sign In</Link>
            </Typography>
          </form>
        </Card>
      </Layout>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getForgotPasswordRequestLoadingStatus(state),
});

export default withRouter(
  connect(mapStateToProps, { forgotPassword })(
    withStyles(styles)(ForgotPassword),
  ),
);
