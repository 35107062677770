// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { string, oneOfType, arrayOf, node } from 'prop-types';

import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  root: () => ({
    fontSize: '14px',
    lineHeight: '22px',
    color: ThemeV2.palette.gray9,
    marginBottom: '4px',
    boxSizing: 'border-box',
  }),
});

const Label = ({ id, children, className: parentClassName }) => {
  const classes = useStyles({});
  const className = `${classes.root} ${parentClassName}`;
  return (
    <InputLabel htmlFor={id} className={className}>
      {children}
    </InputLabel>
  );
};

Label.propTypes = {
  id: string,
  className: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

Label.defaultProps = {
  id: null,
  className: '',
};

export default Label;
