// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { checkSuperAdmin } from '../../Utils/admin';

const SuperAdminAuthorization = () => (WrappedComponent) =>
  class WithSuperAdminAuthorization extends Component {
    componentDidMount() {
      const user = JSON.parse(localStorage.getItem('user'));
      const isSuperAdmin = checkSuperAdmin(user);
      if (isSuperAdmin === false) {
        this.props.history.push('/404');
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default SuperAdminAuthorization;
