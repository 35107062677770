// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AdminBreadCrumps = ({ thirdText, link, primaryText, secondaryText }) => {
  return (
    <div className="">
      {thirdText ? (
        <>
          <span className="admin-breadcrumps-primary__span">{primaryText}</span>
          {link ? (
            <span className="admin-breadcrumps-primary__span">
              {' '}
              &gt;&nbsp;
              <Link to={link} className="admin-breadcrumps-primary__link">
                {secondaryText}
              </Link>
            </span>
          ) : (
            <span className="admin-breadcrumps-primary__span">
              {' '}
              &gt; {secondaryText}
            </span>
          )}

          <span className="admin-breadcrumps-secondary__span">
            {' '}
            &gt; {thirdText}
          </span>
        </>
      ) : (
        <>
          <span className="admin-breadcrumps-primary__span">{primaryText}</span>
          <span className="admin-breadcrumps-secondary__span">
            {' '}
            &gt; {secondaryText}
          </span>
        </>
      )}
    </div>
  );
};

AdminBreadCrumps.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
  thirdText: PropTypes.string,
  link: PropTypes.string,
};

AdminBreadCrumps.defaultProps = {
  thirdText: null,
  link: null,
};

export default AdminBreadCrumps;
