// @ts-nocheck TODO: type issues need to be fixed in this file
import { requestsReducer, success } from 'redux-saga-requests';
import _ from 'lodash';
import { AuthHeadersV2 } from '../../../../../modules/shared/HeaderToken';
import { API_URL_WITH_V2 } from '../../../../../config';

const apiEndpoints = {
  INVOICES: `${API_URL_WITH_V2}/employer/invoices`,
};

export const GET_BILLING_INVOICES =
  '@admin/billings/paymentHistory/GET_BILLING_INVOICES';
export const GET_BILLING_INVOICE =
  '@admin/billings/paymentHistory/GET_BILLING_INVOICE_ID';
export const RESET_BILLING_INVOICES =
  '@admin/billings/paymentHistory/RESET_BILLING_INVOICES';

export const getBillingInvoices = (afterId = null, limit = 10) => ({
  type: GET_BILLING_INVOICES,
  request: {
    url: afterId
      ? `${apiEndpoints.INVOICES}?startingAfter=${afterId}&limit=${limit}`
      : `${apiEndpoints.INVOICES}?limit=${limit}`,
    method: 'get',
    ...AuthHeadersV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const getBillingInvoice = ({ invoiceId, startDate, endDate }) => ({
  type: GET_BILLING_INVOICE,
  request: {
    url: `${apiEndpoints.INVOICES}/${invoiceId}?startDate=${startDate}&endDate=${endDate}`,
    method: 'get',
    ...AuthHeadersV2(),
  },
  meta: {
    invoiceId,
  },
});

export const resetBillingInvoices = () => ({
  type: RESET_BILLING_INVOICES,
});

export default requestsReducer({
  actionType: GET_BILLING_INVOICES,
  getData: (state, action, config) => {
    switch (action.type) {
      case success(GET_BILLING_INVOICES): {
        const data = _.get(action, ['data', 'data'], {});
        const { invoices, ...rest } = data;
        const invoicesList = invoices.data;
        const existingInvoice = _.get(state, ['data', 'invoices'], []);
        return {
          ...state.data,
          ...rest,
          invoices: [...existingInvoice, ...invoicesList],
          invoicesInfo: invoices,
        };
      }
      default:
        return state;
    }
  },
  mutations: {
    [GET_BILLING_INVOICE]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(GET_BILLING_INVOICE): {
            const data = _.get(action, ['data', 'data'], null);
            const id = action.meta.invoiceId;
            return { ...state.data, [id]: data };
          }
          default:
            return { ...state.data };
        }
      },
    },
    [RESET_BILLING_INVOICES]: {
      updateData: (state, action) => {
        switch (action.type) {
          case RESET_BILLING_INVOICES: {
            return null;
          }
          default:
            return { ...state.data };
        }
      },
      local: true,
    },
  },
});
