import React from 'react';
import Body from '../../../atomic/atoms/Body';
import Link from '../../../atomic/atoms/Link_V2';

export type PlanTier = {
  uniqueId: string;
  name: string;
  currency: {
    currency: string;
    value: number;
  };
};

export const templateHeading = 'Upgrade Plan';
export const tiersRadioOptions = (
  tier4: PlanTier,
  tier3: PlanTier,
  tier2: PlanTier,
) => [
  {
    value: tier4.uniqueId,
    label: `Upgrade to ${tier4.name} ($${tier4.currency.value} per user per month)`,
  },
  {
    value: tier3.uniqueId,
    label: `Upgrade to ${tier3.name} ($${tier3.currency.value} per user per month)`,
  },
  {
    value: tier2.uniqueId,
    label: `Upgrade to ${tier2.name} ($${tier2.currency.value} per user per month)`,
  },
];

export const selectTierHeading = (
  tier1Name: string,
  tier2Name: string,
  tier3Name: string,
  tier4Name: string,
) =>
  `Upgrade from the ${tier1Name} plan to ${tier2Name}, ${tier3Name}, or ${tier4Name} plan`;
export const selectTierSubHeadingHeader = 'Tip: ';
export const selectTierSubHeading = (
  tier2Name: string,
  tier3Name: string,
  tier4Name: string,
) =>
  // eslint-disable-next-line max-len
  `${tier2Name} plan supports more features with limited access. ${tier3Name} plan supports most features with no access limits, and ${tier4Name} plan provides all features, no access limits, and dedicated support teams.`;

export const selectTierPrimaryButtonText = 'Next >';
export const selectTierSecondaryButtonText = 'Nevermind';

export const addPaymentPrimaryButtonText = 'Next >';
export const addPaymentSecondaryButtonText = '< Back';

export const addPaymentHeading = 'Setup your payment method ';
export const addPaymentSubHeadingHeader = 'Tip: ';
export const addPaymentSubHeading = `Our payment processor is PCI Compliant through Stripe`;

export const upgradeReviewHeading =
  'Review billing and confirm payment for first month';
export const upgradeReviewSubHeadingHeader = 'Tip: ';
export const getUpgradeReviewSubHeading = ({
  newPlanName,
  renewalDate,
  price,
}: {
  newPlanName: string;
  renewalDate: string;
  price: string;
}) => (
  <>
    You’re upgrading your account to our monthly {newPlanName} plan.{' '}
    <Body variant="body2Bold" inline>
      {renewalDate} is your next billing date
    </Body>{' '}
    and{' '}
    <Body variant="body2Bold" inline>
      renew for {price}
    </Body>{' '}
    (subject to change based on seats and rewards).
  </>
);
export const upgradeReviewPlanHeading = 'Your plan currently:';
export const getUpgradeReviewCardLast4DigitsDetail = (
  cardLast4Digits: string,
) => (
  <>
    Charges will be billed to the card ending in{' '}
    <Body variant="body2Bold" inline>
      {cardLast4Digits}
    </Body>
  </>
);
export const getUpgradeReviewTeamMembersDetail = (
  teamMembersString: string,
) => (
  <>
    Your Assembly has{' '}
    <Body variant="body2Bold" inline>
      {teamMembersString}
    </Body>
  </>
);

export const upgradePlanIntegrationInfo = (
  <>
    <Body variant="body2">
      Get access to all of our integrations with a paid plan. Want to make sure
      we have yours? See our list of integrations{' '}
      <Link
        to="https://www.joinassembly.com/integrations"
        isAnchor
        openInNewTab
      >
        here
      </Link>
      .
    </Body>
  </>
);

export const upgradeReviewPrimaryButtonText = 'Confirm >';
export const upgradeReviewSecondaryButtonText = '< Back';

export const upgradeReviewInputPlaceholders = {
  name: 'Name as seen on card',
  cardNumber: '5555 0000 0000 1234',
  cardExpiration: 'Expiration date',
  cardCVC: 'CVC',
  promoCode: 'Promo code?',
};
