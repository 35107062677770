import { CurrentPlanResponse, GetProfileInfoResponse } from '.';
import { AvatarProps } from '../../atomic/atoms/Avatar/interfaces';
import { AddTrophiesInputProps } from '../../atomic/molecules/AddTrophiesInput';
import {
  AssemblyCurrency,
  AssemblyCurrencyType,
  PrivateMessageAndImpactLevelValues,
} from '../../interfaces/assembly';
import { CurrentUser } from '../../interfaces/currentUser';
import { AllowanceType } from '../../interfaces/user';
import { isFeatureEnabled } from '../../Utils/admin/account';
import { checkAdmin, checkManager } from '../../Utils/admin';
import {
  getCurrencyMenuItemsFromPercentage,
  getMenuItems,
} from '../../Utils/trophySelector';
import {
  isUserAdmin,
  isUserOwner,
  canCurrentUserGiveRecognition,
} from '../../Utils/user';
import { GifRatingsProps } from '../../atomic/atoms/Giphy/interface';
import { ADMIN_LOWER } from '../../languages/en/singleWords';
import { DropdownColor } from '../../atomic/molecules/Dropdown_V2/interfaces';

const emptyFunction = () => {};

export const getCurrentUserAvatar = (
  profileData?: GetProfileInfoResponse,
): AvatarProps => {
  if (profileData) {
    const { member } = profileData;
    const { profile, memberId } = member;
    const { firstName, lastName, image: img } = profile;
    const result: AvatarProps = {
      userId: memberId,
      img,
      name: `${firstName} ${lastName}`,
    };
    return result;
  }
  return {
    img: '',
  };
};

export const getCanCurrentUserGiveRecognition = (
  profileData?: GetProfileInfoResponse,
): boolean => {
  if (profileData) {
    const { member } = profileData;
    return canCurrentUserGiveRecognition(member);
  }
  return false;
};

// From GiveCarrots.js
export const isAssemblySettingEnabledForCurrentUser = (
  settingValue: PrivateMessageAndImpactLevelValues,
  currentUser: CurrentUser,
) => {
  if (
    settingValue === PrivateMessageAndImpactLevelValues.EVERYONE ||
    checkAdmin(currentUser.role)
  ) {
    return true;
  }
  if (
    settingValue === PrivateMessageAndImpactLevelValues.MANAGER_ONLY &&
    checkManager(currentUser)
  ) {
    return true;
  }
  if (
    settingValue ===
    PrivateMessageAndImpactLevelValues.MANAGER_AND_CUSTOM_ALLOWANCES
  ) {
    if (checkManager(currentUser)) {
      return true;
    }
    const allowanceType = currentUser.allowance.type;
    if (allowanceType === AllowanceType.CUSTOM) {
      return true;
    }
    return false;
  }
  if (
    settingValue === PrivateMessageAndImpactLevelValues.ADMINS_ONLY &&
    checkAdmin(currentUser.role)
  ) {
    return true;
  }
  return false;
};

export const getCanUserPostPrivately = (
  profileData?: GetProfileInfoResponse,
): boolean => {
  if (profileData) {
    const { assembly, member } = profileData;
    const { settings } = assembly;
    const { privateMessage } = settings;
    const { enabled, value } = privateMessage;
    const canPostPrivately = enabled
      ? isAssemblySettingEnabledForCurrentUser(value, member)
      : enabled;
    return canPostPrivately;
  }
  return true;
};

// Sending number only if its enabled
export const getMinimumMessageCharacters = (
  profileData?: GetProfileInfoResponse,
): number | undefined => {
  if (profileData) {
    const { assembly } = profileData;
    const { settings } = assembly;
    const { minMessageChar } = settings;
    const { enabled, value } = minMessageChar;
    return enabled ? value : undefined;
  }
  return undefined;
};

export const getGifRatings = (
  profileData?: GetProfileInfoResponse,
): GifRatingsProps | undefined => {
  if (profileData) {
    const { assembly } = profileData;
    const { flow } = assembly;
    return flow?.gifAccessibility.enabled
      ? flow.gifAccessibility.value
      : undefined;
  }
  return undefined;
};

export const getCanCheckForBadWords = (
  profileData?: GetProfileInfoResponse,
): boolean => {
  if (profileData) {
    const { assembly } = profileData;
    const { settings } = assembly;
    const { blockWordsMessage } = settings;
    const { enabled } = blockWordsMessage;
    return enabled;
  }
  return false;
};

export const getCanDeletePosts = (
  profileData?: GetProfileInfoResponse,
): boolean => {
  if (profileData) {
    return (
      profileData.assembly.settings.canDeletePosts.enabled &&
      isUserAdmin(profileData.member)
    );
  }
  return false;
};

export const defaultCurrency: AssemblyCurrency = {
  name: 'Trophy',
  pluralName: 'Trophies',
  type: AssemblyCurrencyType.REGULAR,
  value: '1F3C6',
};

export const getAssemblyCurrency = (profileData?: GetProfileInfoResponse) => {
  if (profileData) {
    return profileData.assembly.currency;
  }
  return defaultCurrency;
};

export const getCanShowAddTrophies = (profileData?: GetProfileInfoResponse) => {
  if (profileData) {
    return !(
      profileData.member.allowance.type === AllowanceType.NO_ALLOWANCE &&
      profileData.member.allowance.points === 0
    );
  }
  return false;
};

export const getIsCurrentUserAdmin = (profileData?: GetProfileInfoResponse) => {
  if (!profileData) {
    return false;
  }
  return isUserAdmin(profileData.member);
};

export const getIsCurrentUserOwner = (profileData?: GetProfileInfoResponse) => {
  if (!profileData) {
    return false;
  }
  return isUserOwner(profileData.member);
};

export const isUserCurrentUser = (
  user: { memberID: string },
  profileData?: GetProfileInfoResponse,
) => {
  if (!profileData) {
    return false;
  }
  return user.memberID === profileData?.member.memberId;
};

export const getEmployerName = (profileData?: GetProfileInfoResponse) => {
  if (!profileData) {
    return ADMIN_LOWER;
  }
  return profileData.assembly.name;
};

export const getIsCoreValuesEnabled = (
  profileData?: GetProfileInfoResponse,
) => {
  if (profileData) {
    const { assembly } = profileData;
    const { settings } = assembly;
    const { coreValues } = settings;
    const { enabled, value } = coreValues;
    return value.length === 0 ? false : enabled;
  }
  return undefined;
};

export const isFeatureEnabledInCurrentPlan = (
  feature: string,
  currentPlan?: CurrentPlanResponse,
) => {
  if (!currentPlan) {
    return false;
  }
  return isFeatureEnabled(currentPlan.features, feature);
};

export const canGiveCustomAmountOfPoints = (
  profileData?: GetProfileInfoResponse,
): boolean => {
  if (!profileData) {
    return false;
  }

  const { member, assembly } = profileData;
  const { customType } = assembly.settings.postImpactLevel.value;
  return isAssemblySettingEnabledForCurrentUser(customType, member);
};

export const getTrophies = (
  trophiesInputValue: number,
  splitBy: number,
  onTrophiesInputChange: (value: number) => void,
  profileData?: GetProfileInfoResponse,
  buttonColor?: DropdownColor,
) => {
  const assemblyCurrency = getAssemblyCurrency(profileData);
  if (profileData) {
    const { member, assembly } = profileData;
    const { allowance, pointsLeftThisCycle } = member;
    const { points } = allowance;
    const { levels } = assembly.settings.postImpactLevel.value;
    const canAllowCustomAmount = canGiveCustomAmountOfPoints(profileData);
    const result: AddTrophiesInputProps = {
      onChange: onTrophiesInputChange,
      onItemClick: emptyFunction,
      buttonText: `Add ${assemblyCurrency.pluralName}`,
      assemblyCurrency,
      value: trophiesInputValue,
      maxValue: pointsLeftThisCycle,
      splitBy,
      menuItems: getCurrencyMenuItemsFromPercentage(
        assemblyCurrency,
        levels,
        points,
      ),
      canAllowCustomAmount,
      buttonColor,
    };
    return result;
  }
  const result: AddTrophiesInputProps = {
    onChange: emptyFunction,
    onItemClick: emptyFunction,
    buttonText: `Add Trophies`,
    assemblyCurrency,
    value: 0,
    maxValue: 39,
    splitBy,
    menuItems: getMenuItems(assemblyCurrency),
    canAllowCustomAmount: false,
    buttonColor,
  };
  return result;
};

export const setAssemblyFlowPermissionValue = (
  cacheValue: string,
  payloadValue: string | undefined,
) => {
  if (payloadValue) {
    if (cacheValue !== payloadValue) {
      return payloadValue;
    }
  }
  return cacheValue;
};
