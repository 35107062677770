import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Body from '../Body';
import ThemeV2 from '../../../componentsV2/theme';
import SVGIcon from '../SVGIcon';

const useStyles = makeStyles({
  root: {
    background: ThemeV2.palette.gray1,
    width: '163px',
    minHeight: '64px',
    padding: '10px 0 10px 0',
    borderRadius: '2px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: ThemeV2.palette.gray5,
    cursor: 'pointer',
    '&:not(:disabled):not(:active)': {
      '&:hover': {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
      },
    },
    '&:active': {
      background: ThemeV2.palette.gray4,
      outline: ThemeV2.palette.gray5,
      boxShadow: 'none',
    },
    '&:focus': {
      outline: ThemeV2.palette.gray5,
      boxShadow: 'none',
    },
    '&:disabled': {
      background: ThemeV2.palette.gray3,
      cursor: 'default',
      filter: 'grayscale(1)',
      '& img': {
        height: '16px',
        minHeight: '16px',
        width: 'auto',
        maxWidth: '94px',
      },
    },
  },
  logoHolder: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    height: '16px',
    maxWidth: '94px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  helperText: {
    color: ThemeV2.palette.gray6,
    margin: '0px',
  },
});

export interface IntegrationButtonBaseProps {
  children?: React.ReactNode;
  disabled?: boolean;
  showUpgradeInfo?: boolean;
  upgradeText?: string;
  text?: string;
  onClick?: () => void;
  linkText?: string;
}

const IntegrationButtonBase = (props: IntegrationButtonBaseProps) => {
  const {
    children,
    disabled = false,
    text,
    onClick,
    showUpgradeInfo = false,
    upgradeText = '',
    linkText,
  } = props;
  const classes = useStyles();
  const finalText = disabled ? 'Coming Soon' : text;
  return (
    <button
      type="button"
      className={classes.root}
      disabled={disabled}
      onClick={onClick}
    >
      {linkText ? (
        <Body variant="body3" color="geekBlue6">
          {linkText}
        </Body>
      ) : (
        <>
          <div className={classes.logoHolder}>{children}</div>
          {finalText && (
            <Body variant="body3" className={classes.helperText}>
              {finalText}
            </Body>
          )}
        </>
      )}

      {showUpgradeInfo && (
        <>
          <SVGIcon
            icon="premium-crown-icon"
            color={ThemeV2.palette.gold6}
            size="20px"
          />
          <Body
            variant="body3"
            color="gold6"
            inline
            data-testid="upgradeInfoText"
          >
            {upgradeText}
          </Body>
        </>
      )}
    </button>
  );
};

export default IntegrationButtonBase;
