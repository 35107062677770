// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';
import themeV2 from '../../theme';

export const INPUT_HEIGHT = 44;
const TEXTAREA_HEIGHT = 160;

export const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 'auto',
  },
  root: (props) => ({
    // height: 44,
    'label + &': {
      marginTop: theme.spacing(1) * (props.hasLabel ? 2.5 : 0),
    },
  }),
  input: (props) => ({
    borderRadius: props.borderRadius || 10,
    border: `solid 1px ${themeV2.palette.lightGray1}`,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    color: themeV2.palette.darkText,
    fontFamily: themeV2.typography.fontFamily,
    fontStretch: 'normal',
    // lineHeight: `${props.height}px !important`,
    letterSpacing: 'normal',
    fontSize: 14,
    height: `${props.height}px !important`,
    boxSizing: 'border-box',
    padding: '13px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontWeight: themeV2.typography.fontWeightRegular,
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.25)',
    },
  }),
  error: (props) => ({
    border: `solid 1px ${themeV2.palette.red2}`,
    borderRadius: props.borderRadius || 10,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    color: themeV2.palette.red2,
    fontFamily: themeV2.typography.fontFamily,
    fontStretch: 'normal',
    // lineHeight: 44,
    letterSpacing: 'normal',
    fontSize: 14,
    height: `${props.height}px !important`,
    boxSizing: 'border-box',
    padding: '13px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontWeight: themeV2.typography.fontWeightRegular,
    '&:focus': {
      borderColor: themeV2.palette.red2,
      boxShadow: `0 0 0 0.1rem ${themeV2.palette.red2}`,
    },
  }),
  errorInput: {
    height: '100% !important',
  },
  formLabel: {
    fontFamily: themeV2.typography.fontFamily,
    color: themeV2.palette.darkText,
    fontWeight: themeV2.typography.fontWeightBold,
    fontSize: '14px',
  },
  formLabelFocused: {
    color: `${themeV2.palette.darkText} !important`,
  },
  errorFormLabel: {
    fontFamily: themeV2.typography.fontFamily,
    color: `${themeV2.palette.red2} !important`,
    fontWeight: themeV2.typography.fontWeightBold,
  },
  helperFormLabel: {
    fontFamily: themeV2.typography.fontFamily,
    fontSize: 12,
    color: themeV2.palette.darkGray3,
    fontWeight: themeV2.typography.fontWeightMedium,
  },
}));

function CustomTextField(props) {
  const {
    label,
    placeholder,
    style,
    error,
    type,
    rootStyle,
    value,
    onChange,
    className,
    id,
    onKeyPress,
    helperText,
    multiline,
    autoFocus,
    autoComplete,
    name,
    inputProps,
    ...rest
  } = props;
  const styleProps = {
    height: multiline ? TEXTAREA_HEIGHT : INPUT_HEIGHT,
    hasLabel: !!label || !!error,
  };
  const classes = useStyles(styleProps);
  return (
    <FormControl
      className={classNames(classes.margin, className)}
      {...rest}
      error={!!error}
      style={rootStyle}
    >
      <InputLabel
        shrink
        error={!!error}
        htmlFor={id}
        classes={{
          root: classes.formLabel,
          error: classes.errorFormLabel,
          focused: classes.formLabelFocused,
        }}
      >
        {error || label}
      </InputLabel>
      <InputBase
        type={type}
        error={!!error}
        id={id}
        classes={{
          root: classes.root,
          input: error ? classes.errorInput : classes.input,
          error: classes.error,
        }}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        style={style}
        placeholder={placeholder}
        multiline={multiline}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        name={name}
        inputProps={inputProps}
      />
      {helperText && (
        <FormHelperText
          className={classes.helperFormLabel}
          id="component-helper-text"
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

CustomTextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rootStyle: PropTypes.PropTypes.shape({}),
  error: PropTypes.string,
};

CustomTextField.defaultProps = {
  placeholder: '',
  type: 'text',
  helperText: null,
  label: null,
  multiline: false,
  autoFocus: false,
  value: null,
  id: '',
  rootStyle: {},
  error: null,
};

export default CustomTextField;
