// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core';
import Typography from '../../../componentsV2/core/Typography';
import ButtonWithArrow from '../../../componentsV2/custom/ButtonWithArrow';
import HelperText from '../../../componentsV2/core/HelperText';
import BootstrapInput from '../../../componentsV2/core/BootstrapInput';
import Modal from '../../../componentsV2/core/Modal';
import Button from '../../../componentsV2/core/Button';
import CloseButton from '../../../componentsV2/core/CloseButton';
import CarrotIcon from '../../../sass/carrot/icons/CarrotIcon';
import ThemeV2 from '../../../componentsV2/theme';
import { getEmployerCustomIconSelector } from '../../../modules/admin/selector';

const styles = (theme) => ({
  dropdownButtonIcon: {
    border: `1px solid ${ThemeV2.palette.lightGray1}`,
    justifyContent: 'start',
    height: '44px',
    width: '59px',
    minWidth: 'auto',
    borderRadius: 3,
  },
  slackSettingsModal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 720,
    padding: '45px 55px 25px 55px',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
    background: '#FFFFFF',
    border: '1px solid #F6F6F6',
    boxSizing: 'border-box',
    borderRadius: 10,
    outline: 'none',
  },
  buttonHolder: {
    marginTop: 30,
    display: 'flex',
  },
  button: {
    display: 'block',
    borderRadius: 3,
    width: '50%',
  },
});

class ChangeCurrencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emojiOpen: false,
      selectedEmoji: '',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'center',
      },
      targetOrigin: {
        horizontal: 'left',
        vertical: 'center',
      },
      currencyName: '',
      currencyNamePlural: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedEmployer !== this.props.selectedEmployer) {
      const { customCurrency } = this.props.selectedEmployer;
      this.setState({
        currencyName: customCurrency.currencyName,
        currencyNamePlural: customCurrency.currencyNamePlural,
        selectedEmoji: {
          id: customCurrency.currencyEmoji.id,
          hexCode: customCurrency.currencyEmoji.hexCode,
        },
      });
    }
  }

  isWindows = () => {
    if (
      window.navigator.platform &&
      window.navigator.platform.includes('Win')
    ) {
      return true;
    }
    return false;
  };

  addEmoji = (emoji) => {
    this.setState({
      selectedEmoji: {
        id: emoji.id,
        hexCode: emoji.unified,
      },
      emojiOpen: false,
    });
  };

  onEmojiClick = (e) => {
    e.preventDefault();
    this.setState({
      emojiOpen: true,
      anchorEl: e.currentTarget,
    });
  };

  handleEmojiRequestClose = () => {
    this.setState({
      emojiOpen: false,
    });
  };

  handleCurrencyChange = (event) => {
    this.setState({
      currencyName: event.target.value,
    });
  };

  handleCurrencyPluralChange = (event) => {
    this.setState({
      currencyNamePlural: event.target.value,
    });
  };

  onUpdateClick = () => {
    const { currencyName, currencyNamePlural, selectedEmoji } = this.state;
    const customCurrency = {
      isSet: true,
      currencyName,
      currencyNamePlural,
      currencyEmoji: {
        id: selectedEmoji.id,
        hexCode: selectedEmoji.hexCode,
      },
      currencyIconURL: '',
    };
    this.props.onUpdate(customCurrency);
  };

  render() {
    const { classes, open, close, customCompanyIcon, loading, ...rest } =
      this.props;
    return (
      <React.Fragment>
        <Popover
          open={this.state.emojiOpen}
          anchorEl={this.state.anchorEl}
          onClose={this.handleEmojiRequestClose}
          anchorOrigin={this.state.anchorOrigin}
          transformOrigin={this.state.targetOrigin}
          style={styles.emojiPopover}
        >
          {this.isWindows() ? (
            <Picker
              onClick={this.addEmoji}
              native={false}
              sheetSize={16}
              set="google"
              title="Pick your emojiâ€¦"
              emoji="point_up"
            />
          ) : (
            <Picker
              onClick={this.addEmoji}
              set="apple"
              sheetSize={16}
              native
              title="Pick your emojiâ€¦"
              emoji="point_up"
            />
          )}
        </Popover>
        <Modal
          open={open}
          handleClose={close}
          style={{ padding: '10px 5px' }}
          customClass={classes.slackSettingsModal}
          {...rest}
        >
          <CloseButton onClick={close} />
          <div className="row w-100">
            <div className="col-md-7">
              <div className="d-flex flex-column">
                <Typography variant="body1" weight="semiBold" className="mb-1">
                  Currency Icon
                </Typography>
                <Typography variant="body2" weight="medium">
                  You can select from any emoji available to be your custom
                  currency icon.
                </Typography>
              </div>
            </div>
            <div className="col-md-5">
              <ButtonWithArrow
                aria-label="Add Emoji"
                onClick={this.onEmojiClick}
                children={
                  this.state.selectedEmoji.hexCode ? (
                    String.fromCodePoint(
                      parseInt(this.state.selectedEmoji.hexCode, 16),
                    )
                  ) : (
                    <CarrotIcon
                      style={{ fontSize: 24, marginRight: 5 }}
                      active
                      url={customCompanyIcon}
                    />
                  )
                }
                isOpen={this.state.emojiOpen}
                classes={{ root: classes.dropdownButtonIcon }}
              />
            </div>
          </div>
          <div className="row mt-5 w-100">
            <div className="col-md-7">
              <div className="d-flex flex-column">
                <Typography variant="body1" weight="semiBold" className="mb-1">
                  Singular Currency Name
                </Typography>
                <Typography variant="body2" weight="medium">
                  Enter the singular of your currency name (e.g. Trophy). This
                  will be displayed in posts, leaderboards, and more.
                </Typography>
              </div>
            </div>
            <div className="col-md-5">
              <BootstrapInput
                value={this.state.currencyName}
                onChange={this.handleCurrencyChange}
              />
              <HelperText>
                <span role="img" aria-label="icon">
                  👉
                </span>{' '}
                Less than 22 characters and singular
              </HelperText>
            </div>
          </div>
          <div className="row mt-5 w-100">
            <div className="col-md-7">
              <div className="d-flex flex-column">
                <Typography variant="body1" weight="semiBold" className="mb-1">
                  Plural Currency Name
                </Typography>
                <Typography variant="body2" weight="medium">
                  Enter the plural of your currency name (e.g. Trophies). This
                  will be displayed in posts, leaderboards, and more.
                </Typography>
              </div>
            </div>
            <div className="col-md-5">
              <BootstrapInput
                value={this.state.currencyNamePlural}
                onChange={this.handleCurrencyPluralChange}
              />
              <HelperText>
                <span role="img" aria-label="icon">
                  👉
                </span>{' '}
                Less than 22 characters and plural
              </HelperText>
            </div>
          </div>
          <div className={classes.buttonHolder}>
            <Button
              variant="contained"
              style={{
                boxShadow: 'none',
                marginRight: 16,
                border: `1px solid ${ThemeV2.palette.lightGray1}`,
                backgroundColor: '#F6F6F6',
                color: ThemeV2.palette.darkGray3,
              }}
              onClick={close}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.onUpdateClick}
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Update'}
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customCompanyIcon: getEmployerCustomIconSelector(state),
});

export default connect(mapStateToProps)(
  withStyles(styles)(ChangeCurrencyModal),
);
