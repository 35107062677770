// @ts-nocheck TODO: type issues need to be fixed in this file
import uuidv4 from 'uuid/v4';

const INITIAL_STATE = {
  name: '',
  email: '',
  department: [],
  status: '',
  allowanceType: '',
  isAdmin: '',
};

// ------------------------------------
// Constants
// ------------------------------------

const ADMIN_TABLE_INITIALIZE_FILTER = '@@ADMIN_TABLE/INITIALIZE_FILTER';
const ADMIN_TABLE_APPLY_FILTER = '@@ADMIN_TABLE/APPLY_FILTER';
const ADMIN_TABLE_REMOVE_FILTER = '@@ADMIN_TABLE/REMOVE_FILTER';
const ADMIN_TABLE_REMOVE_ALL_FILTERS =
  '@@ADMIN_TABLE/ADMIN_TABLE_REMOVE_ALL_FILTERS';
const ADMIN_TABLE_UPDATE_INPUT = '@@ADMIN_TABLE/UPDATE_INPUT';
const ADMIN_TABLE_RESET_INPUTS = '@@ADMIN_TABLE/RESET_INPUTS';

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case ADMIN_TABLE_INITIALIZE_FILTER: {
      const { tableName, defaultValues = {} } = action;
      return {
        ...state,
        currentTable: tableName,
        [tableName]: {
          filters: {},
          values: INITIAL_STATE,
          defaultValues,
        },
      };
    }
    case ADMIN_TABLE_RESET_INPUTS: {
      const { tableName } = action;
      return {
        ...state,
        [tableName]: { ...state[tableName], values: INITIAL_STATE },
      };
    }
    case ADMIN_TABLE_UPDATE_INPUT: {
      const { tableName, field, value } = action;
      const values = state[tableName] ? state[tableName].values : {};
      return {
        ...state,
        [tableName]: {
          ...state[tableName],
          values: { ...values, [field]: value },
        },
      };
    }
    case ADMIN_TABLE_APPLY_FILTER: {
      const { tableName, filter } = action;
      const filters = state[tableName] ? state[tableName].filters : {};
      const id = uuidv4();
      return {
        ...state,
        [tableName]: {
          ...state[tableName],
          filters: { ...filters, ...filter },
          id,
        },
      };
    }
    case ADMIN_TABLE_REMOVE_FILTER: {
      const { tableName, filter } = action;
      const filters = state[tableName] ? state[tableName].filters : {};
      const { [filter]: value, ...rest } = filters;
      const id = uuidv4();
      return {
        ...state,
        [tableName]: { ...state[tableName], filters: rest, id },
      };
    }
    case ADMIN_TABLE_REMOVE_ALL_FILTERS: {
      const { tableName } = action;
      const id = uuidv4();
      return {
        ...state,
        [tableName]: { ...state[tableName], filters: {}, id },
      };
    }
    default:
      return state;
  }
};

export const initializeNewFilter = (tableName, defaultValues = {}) => ({
  type: ADMIN_TABLE_INITIALIZE_FILTER,
  tableName,
  defaultValues,
});

export const resetFilterInputValues = (tableName) => ({
  type: ADMIN_TABLE_RESET_INPUTS,
  tableName,
});

export const applyFilter = (tableName, filter) => ({
  type: ADMIN_TABLE_APPLY_FILTER,
  tableName,
  filter,
});

export const removeFilter = (tableName, filter) => ({
  type: ADMIN_TABLE_REMOVE_FILTER,
  tableName,
  filter,
});

export const removeAllFilters = (tableName) => ({
  type: ADMIN_TABLE_REMOVE_ALL_FILTERS,
  tableName,
});

export const updateInput = (tableName, field, value) => ({
  type: ADMIN_TABLE_UPDATE_INPUT,
  tableName,
  field,
  value,
});
