import React, {
  useState,
  ReactNode,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

import {
  ClickHolder,
  Container,
  PopoverElement,
  ReferenceContentContainer,
} from './styles';
import useToggle from '../../../hooks/useToggle';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';

export interface PopoverProps {
  elementPadding?: string;
  containerPadding?: string;
  popoverPosition?: Placement;
  popoverContent: ReactNode;
  referenceContent: ReactNode;
  xAxisOffset?: number;
  yAxisOffset?: number;
  triggerAction?: TriggerActionType;
  onOutsideClick?: (event: MouseEvent | TouchEvent) => void;
  closeOnPopoverClick?: boolean;
  disabled?: boolean;
  className?: string;
}

type PopperElementState = HTMLElement | null;
export enum TriggerActionType {
  hover = 'HOVER',
  click = 'CLICK',
}

const Popover = ({
  disabled,
  elementPadding = '16px',
  containerPadding = '0px',
  popoverPosition = 'bottom',
  triggerAction = TriggerActionType.hover,
  popoverContent: PopoverContent,
  referenceContent: ReferenceContent,
  xAxisOffset = 0,
  yAxisOffset = 0,
  onOutsideClick,
  closeOnPopoverClick,
  className,
}: PopoverProps) => {
  const {
    models: { toggleValue: isPopoverOpen },
    operations: { setToggleValue: togglePopoverOpen },
  } = useToggle();
  const [referenceElement, setReferenceElement] =
    useState<PopperElementState>(null);
  const [popperElement, setPopperElement] = useState<PopperElementState>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const popperAttributes = useMemo(
    () => ({
      placement: popoverPosition,
      modifiers: [
        { name: 'offset', options: { offset: [yAxisOffset, xAxisOffset] } },
      ],
    }),
    [popoverPosition, xAxisOffset, yAxisOffset],
  );
  const { styles, update } = usePopper(
    referenceElement,
    popperElement,
    popperAttributes,
  );

  const checkTriggerAndToggle = useCallback(
    async (action: TriggerActionType, requiredAction: TriggerActionType) => {
      if (disabled) {
        return;
      }

      if (action === requiredAction) {
        // to update the position of the popover
        if (update) {
          update();
        }
        togglePopoverOpen();
      }
    },
    [disabled, togglePopoverOpen, update],
  );
  const onOutsideClickHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (isPopoverOpen) {
        checkTriggerAndToggle(triggerAction, TriggerActionType.click);
        if (onOutsideClick) {
          onOutsideClick(event);
        }
      }
    },
    [checkTriggerAndToggle, isPopoverOpen, triggerAction, onOutsideClick],
  );
  const handleHoverToggle = useCallback(
    () => checkTriggerAndToggle(triggerAction, TriggerActionType.hover),
    [checkTriggerAndToggle, triggerAction],
  );
  const handleContainerClick = useCallback(() => {
    if (!isPopoverOpen) {
      checkTriggerAndToggle(triggerAction, TriggerActionType.click);
    }
  }, [checkTriggerAndToggle, isPopoverOpen, triggerAction]);
  const onPopoverClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      if (closeOnPopoverClick) {
        togglePopoverOpen();
      }
    },
    [closeOnPopoverClick, togglePopoverOpen],
  );

  const onPopoverKeypress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        togglePopoverOpen();
      }
    },
    [togglePopoverOpen],
  );

  const handleMouseLeave = useCallback(() => {
    if (isPopoverOpen) {
      checkTriggerAndToggle(triggerAction, TriggerActionType.hover);
    }
  }, [checkTriggerAndToggle, isPopoverOpen, triggerAction]);

  useOnClickOutside(containerRef, onOutsideClickHandler);

  return (
    <Container
      onMouseEnter={handleHoverToggle}
      onMouseLeave={handleMouseLeave}
      aria-haspopup="true"
      aria-expanded={isPopoverOpen ? 'true' : 'false'}
      onClick={handleContainerClick}
      ref={containerRef}
      className={className}
    >
      <ClickHolder
        ref={setReferenceElement}
        onClick={onPopoverClick}
        onKeyPress={onPopoverKeypress}
        tabIndex={0}
        role="button"
      >
        {ReferenceContent}
      </ClickHolder>
      <ReferenceContentContainer
        padding={containerPadding}
        ref={setPopperElement}
        style={styles.popper}
      >
        {isPopoverOpen && (
          <PopoverElement
            data-testid="popover"
            onClick={onPopoverClick}
            padding={elementPadding}
          >
            {PopoverContent}
          </PopoverElement>
        )}
      </ReferenceContentContainer>
    </Container>
  );
};

export default Popover;
