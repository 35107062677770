// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../../Layout/onboarding';
import VerticallyCenteredLayout from '../../Layout/verticallyCentered';
import Typography from '../../../componentsV2/core/Typography';
import Button from '../../../componentsV2/core/Button';
import RoutesList from '../../routes/routesList';
import { handleErrorAction } from '../../utils/ErrorHandler';
import Link from '../../../componentsV2/core/Link';
import themeV2 from '../../../componentsV2/theme';
import { getMagicLinkVerified } from '../../modules/employeeSignup';

const styles = (theme) => ({
  emailStyle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonStyle: {
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
    marginTop: 30,
    '&:hover': {
      color: `${themeV2.palette.white} !important`,
    },
    '&:visited': {
      color: `${themeV2.palette.white} !important`,
    },
  },
});

const SignInLink = React.forwardRef((props, ref) => (
  <Link to={RoutesList.SIGN_IN} {...props} ref={ref} />
));

class VerifyMagicLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentDidMount = () => {
    this.checkAndVerifyLink();
  };

  checkAndVerifyLink = () => {
    const { match } = this.props;
    const { magicLink } = match.params;
    if (magicLink) {
      this.props
        .getMagicLinkVerified({ magicLink })
        .then((successAction) => {
          const userId = _.get(successAction, ['data', 'data', '_id'], null);
          if (userId) {
            const { history } = this.props;
            history.push(`${RoutesList.EMPLOYEE_SET_PROFILE}/${userId}`);
          } else {
            this.setState({ error: 'Invalid' });
          }
        })
        .catch((errorOrAbortAction) => {
          const error = handleErrorAction(errorOrAbortAction);
          if (error.code === 102) {
            this.setState({ error: 'Invalid' });
          }
        });
    }
  };

  renderError = () => {
    const { classes } = this.props;
    let headeText = 'Magic Link has expired';
    let subText = 'Please signup again.';
    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              {headeText}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {subText}
            </Typography>
          </div>
          <div>
            <Button
              variant="contained"
              color="secondary"
              component={SignInLink}
              className={classes.buttonStyle}
            >
              Go back to Sign In
            </Button>
          </div>
          <div style={{ marginTop: 16 }}>
            <Typography variant="subtitle1" gutterBottom>
              Not sure about this,{' '}
              <Link to="" onClick={this.chatClick}>
                chat with us now
              </Link>
            </Typography>
          </div>
        </VerticallyCenteredLayout>
      </Layout>
    );
  };

  render() {
    const { match } = this.props;
    const { error } = this.state;
    const { magicLink } = match.params;
    if (!magicLink || error) {
      return this.renderError();
    }
    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              Verifying Magic Link
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              Please wait till we verify the link. Thank you!
            </Typography>
          </div>
        </VerticallyCenteredLayout>
      </Layout>
    );
  }
}

VerifyMagicLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, { getMagicLinkVerified })(
  withStyles(styles)(VerifyMagicLink),
);
