import theme from '../../../componentsV2/theme';

export const ButtonContent = {
  SIGN_IN: 'Sign in with',
  SIGN_UP: 'Sign up with',
  COMING_SOON: 'Coming Soon',
};

export enum SSOOptions {
  SLACK = 'slack',
  OFFICE365 = 'office365',
  ADP = 'adp',
  GOOGLE = 'gSuite',
  LIFION = 'lifion',
}

export enum SSOTypes {
  SIGN_UP = 'signup',
  SIGN_IN = 'signin',
}

export const SSOContent = {
  [SSOOptions.GOOGLE]: {
    ssoParam: SSOOptions.GOOGLE,
    logo: 'google-light',
    displayName: 'Google',
  },
  [SSOOptions.OFFICE365]: {
    ssoParam: SSOOptions.OFFICE365,
    logo: 'office-365-mini-logo',
    displayName: 'Office 365',
    iconColor: theme.palette.office365Logo,
  },
  [SSOOptions.ADP]: {
    ssoParam: SSOOptions.ADP,
    logo: 'adp-logo',
    displayName: 'ADP',
  },
  [SSOOptions.SLACK]: {
    ssoParam: SSOOptions.SLACK,
    logo: 'slack-mini-logo',
    displayName: 'Slack',
  },
  [SSOOptions.LIFION]: {
    ssoParam: SSOOptions.LIFION,
    logo: '',
    displayName: 'Lifion',
  },
};
