import axios, { Method } from 'axios';
import { AuthHeadersV2 } from '../modules/shared/HeaderToken';
import endpointInfo from '../constants/endpoints';

axios.defaults.withCredentials = true;
type StringDictionary = Record<string, string | number | boolean>;

const makeAxiosRequest = async (
  url: string,
  type: Method,
  payload: Record<string, any>,
  params: StringDictionary,
  headers: StringDictionary,
) =>
  axios.request({
    url,
    method: type,
    data: payload,
    params,
    headers,
  });

export const makeAPICall = (
  apiName: string,
  payload: any = {},
  params: StringDictionary = {},
  substitutions: Record<string, string> = {},
) => {
  const requestDetails = endpointInfo[apiName];
  let { endpointURL } = requestDetails;
  Object.keys(substitutions).forEach((key) => {
    const substitution = substitutions[key];
    endpointURL = endpointURL.replace(`:${key}`, substitution);
  });
  return makeAxiosRequest(
    endpointURL,
    requestDetails.requestType,
    payload,
    params,
    AuthHeadersV2().headers,
  );
};

export const makeAPICallWithDataReturn = async (
  apiName: string,
  payload: any = {},
  params: StringDictionary = {},
  substitutions: Record<string, string> = {},
) => {
  const requestDetails = endpointInfo[apiName];
  let { endpointURL } = requestDetails;
  Object.keys(substitutions).forEach((key) => {
    const substitution = substitutions[key];
    endpointURL = endpointURL.replace(`:${key}`, substitution);
  });

  const { data } = await makeAxiosRequest(
    endpointURL,
    requestDetails.requestType,
    payload,
    params,
    AuthHeadersV2().headers,
  );
  return data;
};
