import styled from 'styled-components';
import Image from '../../atoms/Image';
import ThemeV2 from '../../../componentsV2/theme';

export const AssemblyLanderBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: ${ThemeV2.palette.gray2};
`;

export const StyledImage = styled(Image)`
  margin-bottom: 64px;
  max-width: 342px;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  margin-bottom: 20%;
`;
