// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import { API_URL_WITH_V2 } from '../../../config';
import { AuthHeaders } from '../../shared/HeaderToken';
import { adminProcessError } from '../../Admin';
import Logger from '../../../Utils/Logger';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  USERS_PARTICIPATION: `${API_URL_WITH_V2}/employer/employee/participations`,
  USERS_EARNING: `${API_URL_WITH_V2}/employer/employee/earnings`,
};

// ------------------------------------
// Constants
// ------------------------------------

export const GET_USERS_PARTICIPATION_SUCCESS =
  'get_users_participation_success';
export const GET_USERS_EARNING_SUCCESS = 'get_users_earning_success';
export const USER_REPORTS_REQUEST_FAILED = 'user_reports_request_failed';

// ------------------------------------
// Actions
// ------------------------------------

const getUsersParticipationSuccess = (report) => ({
  type: GET_USERS_PARTICIPATION_SUCCESS,
  report,
});
const getUsersEarningSuccess = (report) => ({
  type: GET_USERS_EARNING_SUCCESS,
  report,
});
const requestFailed = (errorMessage) => ({
  type: USER_REPORTS_REQUEST_FAILED,
  error: errorMessage,
});

export const actions = {
  getUsersParticipationSuccess,
  getUsersEarningSuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getUsersParticipationAction = (cb) => async (dispatch) => {
  try {
    const res = await axios.get(
      apiEndpoints.USERS_PARTICIPATION,
      AuthHeaders(),
    );
    const result = res.data;
    Logger(result);
    if (result.success) {
      cb();
      dispatch(actions.getUsersParticipationSuccess(result.data));
    } else {
      cb();
      // dispatch(actions.emailExists());
    }
  } catch (error) {
    adminProcessError(error, dispatch, actions);
  }
};

export const getUsersEarningsAction = (cb) => async (dispatch) => {
  try {
    const res = await axios.get(apiEndpoints.USERS_EARNING, AuthHeaders());
    const result = res.data;
    Logger(result);
    if (result.success) {
      cb();
      dispatch(actions.getUsersEarningSuccess(result.data));
    } else {
      cb();
      // dispatch(actions.emailExists());
    }
  } catch (error) {
    adminProcessError(error, dispatch, actions);
  }
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case GET_USERS_PARTICIPATION_SUCCESS:
      return { ...state, participation: action.report };
    case GET_USERS_EARNING_SUCCESS:
      return { ...state, earnings: action.report };
    case USER_REPORTS_REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
