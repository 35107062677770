import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { parse } from 'qs';
import * as microsoftTeams from '@microsoft/teams-js';
import {
  useAuthenticateSAMLQuery,
  useSAMLConfigFetchQuery,
} from '../../../queries/Auth/SAML';
import {
  userSessionCreatedAction,
  handleUserSignin,
} from '../../../modules/Session';
import { AUTH_SAML, SIGN_IN } from '../../../constants/routes';
import { SAMLAuthErrorCodes } from '../../../Utils/data/SAML/error';
import { LOADING } from '../../../languages/en/singleWords';
import { CONNECTING_TO, VERIFYING_SAML } from '../../../languages/en/signIn';
import { getAppSource, MS_TEAMS } from '../../../Utils/msteams';

const useSAMLAuthLogic = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [assemblyId, setAssemblyId] = useState<string | undefined>(undefined);
  const [samlName, setSamlName] = useState<string>('');
  const [errorCode, setErrorCode] = useState<SAMLAuthErrorCodes | undefined>(
    undefined,
  );

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const tokenFromURL = parsedParams && parsedParams.token;
    const from = parsedParams && parsedParams.from;
    const assemblyIdFromURL = parsedParams && parsedParams.assemblyId;
    const errorCodeFromURL = parsedParams && parsedParams.error;
    // const hasError = parsedParams && parsedParams.error;
    const isFromMSTeams = getAppSource() === MS_TEAMS;
    const fromTab = from === 'tab';
    if (isFromMSTeams && tokenFromURL && !fromTab) {
      microsoftTeams.initialize();
      /* To notify app loaded to hide loading indicator */
      microsoftTeams.appInitialization.notifyAppLoaded();
      microsoftTeams.appInitialization.notifySuccess();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      microsoftTeams.authentication.notifySuccess({
        code: `${tokenFromURL}`,
      });
    } else {
      if (tokenFromURL) {
        setToken(tokenFromURL as string);
      }
      if (assemblyIdFromURL) {
        setAssemblyId(assemblyIdFromURL as string);
      }
      if (errorCodeFromURL === SAMLAuthErrorCodes.SAML_NOT_ENABLED) {
        push(`${SIGN_IN}?error=${errorCodeFromURL}`);
      }
      if (errorCodeFromURL && !assemblyIdFromURL) {
        setErrorCode(errorCodeFromURL as SAMLAuthErrorCodes);
      }
    }
  }, [push, search]);

  let loadingText = '';

  const hasToken = !!token;
  const { data, error } = useAuthenticateSAMLQuery(hasToken, token);

  const hasAssemblyId = !!assemblyId;

  useEffect(() => {
    if (data) {
      const { jwtToken, user } = data;
      if (user && jwtToken) {
        dispatch(userSessionCreatedAction(user, jwtToken));
        handleUserSignin(user);
      }
    }
    if (error && !hasAssemblyId) {
      const errorCodeValue =
        error.response?.data.message || SAMLAuthErrorCodes.INVALID_PARAMETER;
      push(`${AUTH_SAML}?error=${errorCodeValue}`);
    }
  }, [data, dispatch, error, push, hasAssemblyId]);

  const { data: samlConfigData } = useSAMLConfigFetchQuery(
    hasAssemblyId,
    assemblyId,
  );
  useEffect(() => {
    if (samlConfigData) {
      const { name, uri } = samlConfigData;
      if (uri) {
        window.location.href = uri;
        setSamlName(name);
      }
    }
  }, [samlConfigData]);

  if (hasToken) {
    loadingText = VERIFYING_SAML;
  }
  if (hasAssemblyId) {
    if (samlName) {
      loadingText = `${CONNECTING_TO} ${samlName}`;
    } else {
      loadingText = LOADING;
    }
  }
  return {
    loadingText,
    errorCode,
  };
};

export default useSAMLAuthLogic;
