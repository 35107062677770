// @ts-nocheck TODO: type issues need to be fixed in this file
import { combineReducers } from 'redux';
import { reducer as flashReducer } from 'redux-flash';
import sessionReducer, { SIGN_OUT } from './Session';
import checkoutReducer from './Checkout';
import homeReducer from './Home';
import notificationsReducer from './Notification';
import employeeSignupReducer from './onboarding/EmployeeSignup';
import adminUsersInviteReducer from './admin/Users';
import meReducer from './Me';
import adminReducer from './Admin';
import adminManageUsersReducers from './admin/users/Manage';
import adminActivityReducers from './admin/users/Activity';
import adminUsersReportsReducers from './admin/users/Reports';
import adminRewardsGiftsReducers from './admin/rewards/GiftCatalog';
import adminRewardsPaymentReducer from './admin/Rewards';
import adminTablesReducers from './admin/shared/AdminTables';
import adminBillingReducer from './admin/Billing';
import adminIntegrationsReducer from './admin/Integrations';
import adminAnniversariesReducer from './admin/rewards/Anniversaries';
import adminCustomAllowanceReducer from './admin/settings/CustomAllowance';
import uiReducers from './UI-Reducers/UIReducers';

import headerStyleReducers from './style-switcher-reducers/headerStyleReducers';
import headerActiveStyleReducer from './style-switcher-reducers/headeractiveReducer';
import sidebarStyleReducers from './style-switcher-reducers/sidebarStyleReducers';
import sidebarActiveStyleReducer from './style-switcher-reducers/sidebarActiveReducer';
import headerBannerStyleReducers from './style-switcher-reducers/headerBannerStyleReducers';
import headerBannerActiveStyleReducer from './style-switcher-reducers/headerBannerActiveReducer';

import v2SessionReducers from '../aV2/modules/login';
import v2OnboardingReducers from '../aV2/modules/onboarding';
import v2EmployeeSignupReducers from '../aV2/modules/employeeSignup';
import v2SlackOnboardReducers from '../aV2/modules/slackOnboard';
import apiReducers from '../hooks/useFetch/redux';

import v2AdminAccountReducers from './admin/billing/account';
// import v2AdminSettingsIntegrationsReducers from '../aV2/modules/admin/settings/integrations/index';
// import v2AdminSettingsIntegrationsReducers from '../screens/admin/content/settings/Integrations/reducers';
import v2AdminBillingPaymentHistoryReducers from '../aV2/modules/admin/billings/paymentsHistory/index';
import adminSlackReducers from '../aV2/modules/admin/slack';
import superAdminReducers from './admin/superadmin';
import myRewardsReducers from '../aV2/modules/admin/settings/myRewards';
import APIResponseReducer from '../hooks/useAPIStateManagement/reducer';

const appReducer = combineReducers({
  auth: sessionReducer,
  checkout: checkoutReducer,
  me: meReducer,
  home: homeReducer,
  notifications: notificationsReducer,
  employeeSignup: employeeSignupReducer,
  admin: adminReducer,
  adminUsersInvite: adminUsersInviteReducer,
  adminManageUsers: adminManageUsersReducers,
  adminActivity: adminActivityReducers,
  adminUsersReports: adminUsersReportsReducers,
  adminRewardsGifts: adminRewardsGiftsReducers,
  adminRewardsPayment: adminRewardsPaymentReducer,
  adminBilling: adminBillingReducer,
  adminIntegrations: adminIntegrationsReducer,
  adminTables: adminTablesReducers,
  adminAnniversaries: adminAnniversariesReducer,
  adminCustomAllowance: adminCustomAllowanceReducer,
  flash: flashReducer,
  ui: uiReducers,
  sheaderStyle: headerStyleReducers,
  headerActiveStyle: headerActiveStyleReducer,
  headerBannerStyle: headerBannerStyleReducers,
  headerBAnnerActiveStyle: headerBannerActiveStyleReducer,
  sidebarStyle: sidebarStyleReducers,
  sidebarActiveStyle: sidebarActiveStyleReducer,

  v2Session: v2SessionReducers,
  v2Onboarding: v2OnboardingReducers,
  v2EmployeeSignup: v2EmployeeSignupReducers,
  v2AdminAccount: v2AdminAccountReducers,
  v2SlackOnboard: v2SlackOnboardReducers,
  api: apiReducers,
  adminSlack: adminSlackReducers,
  superAdmin: superAdminReducers,

  // v2AdminSettingsIntegrations: v2AdminSettingsIntegrationsReducers,
  v2AdminBillingsPaymentHistory: v2AdminBillingPaymentHistoryReducers,
  myRewards: myRewardsReducers,
  API: APIResponseReducer,
});

const allReducers = (state, action) => {
  let newState = {};
  if (action.type === SIGN_OUT) {
    newState = {
      auth: state.auth,
      sheaderStyle: state.sheaderStyle,
      headerActiveStyle: state.headerActiveStyle,
      headerBannerStyle: state.headerBannerStyle,
      headerBAnnerActiveStyle: state.headerBAnnerActiveStyle,
      sidebarStyle: state.sidebarStyle,
      sidebarActiveStyle: state.sidebarActiveStyle,
    };
  } else {
    newState = state;
  }
  return appReducer(newState, action);
};

export default allReducers;
