// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import Link from '../../../componentsV2/core/Link';
import OtpInput from '../../../componentsV2/core/OTPInput/BaseOtpInput';

import { handleErrorAction } from '../../utils/ErrorHandler';
import { verifyCode, resendCode } from '../../modules/onboarding';
import {
  getEmailRegistered,
  getVerifyRequestLoadingStatus,
  getResendCodeRequestLoadingStatus,
} from '../../modules/onboarding/selectors';
import RoutesList from '../../routes/routesList';
import themeV2 from '../../../componentsV2/theme';

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    height: 593,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  otpContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  body: {
    flex: 1,
  },
  link: {
    fontSize: 12,
  },
  secondaryDivStyle: {
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  progresStyle: {
    color: themeV2.palette.greenLight,
  },
  errorDivStyle: {
    color: themeV2.palette.red2,
  },
  successDivStyle: {
    color: themeV2.palette.greenLight,
  },
});

class EnterCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      codeErrors: null,
      codeVerified: false,
      resendCodeSuccess: false,
    };
  }

  handleOtpChange = (code) => {
    this.setState(
      () => ({ code }),
      () => {
        if (code.length === 6) {
          this.verifyCode();
        }
      },
    );
  };

  resendCode = (e) => {
    e.preventDefault();
    const { match } = this.props;
    const { userId } = match.params;

    this.props
      .resendCode({ userId })
      .then((successAction) => {
        this.setState({ resendCodeSuccess: true });
      })
      .catch((errorOrAbortAction) => {});
  };

  verifyCode = () => {
    let { code } = this.state;
    const { match } = this.props;
    const { userId } = match.params;
    code = code.trim();

    this.setState({ codeErrors: null });
    if (userId) {
      this.props
        .verifyCode({ userId, codeToVerify: code })
        .then((successAction) => {
          this.setState({ codeVerified: true });
          const { history } = this.props;
          const newUserId = _.get(successAction, ['data', 'data', '_id'], null);
          history.push(
            `${RoutesList.ENTER_COMPANY_NAME}/${newUserId}?code=${code}`,
          );
        })
        .catch((errorOrAbortAction) => {
          handleErrorAction(errorOrAbortAction);
          this.setState({
            codeErrors:
              'Whoops, this code doesn’t match what we sent, check your email and try again.',
          });
        });
    }
  };

  render() {
    const { classes, match, email, isRequestLoading, isResendRequestLoading } =
      this.props;
    const { code, codeErrors, codeVerified, resendCodeSuccess } = this.state;

    const { userId } = match.params;
    if (!userId) {
      return <Redirect to={`${RoutesList.ENTER_EMAIL}`} />;
    }

    return (
      <Layout>
        <Card className={classes.card}>
          <div className={classes.wrapper}>
            <div className={classes.body}>
              <Typography variant="h5" weight="bold" gutterBottom>
                Check your email
              </Typography>
              <Typography variant="body2" gutterBottom>
                You will receive a temporary 6 digit confirmation code at{' '}
                {email ? (
                  <span style={{ fontWeight: 600 }}>{email}</span>
                ) : (
                  'your email'
                )}
                , enter it quickly.
              </Typography>
              <div className={classes.otpContainerStyle}>
                <OtpInput
                  numInputs={6}
                  isDisabled={isRequestLoading}
                  hasErrored={!!codeErrors}
                  errorStyle="error"
                  onChange={this.handleOtpChange}
                  isInputNu
                  shouldAutoFocus
                  value={code}
                />
              </div>
              {codeVerified && (
                <div className={classes.secondaryDivStyle}>
                  <CheckIcon className={classes.successDivStyle} />
                  &nbsp;&nbsp;
                  <span className={classes.successDivStyle}>
                    {' '}
                    Code verified.
                  </span>
                </div>
              )}
              {isRequestLoading && (
                <div className={classes.secondaryDivStyle}>
                  <CircularProgress
                    size={24}
                    className={classes.progresStyle}
                  />
                  &nbsp;&nbsp;
                  <span className={classes.successDivStyle}>
                    {' '}
                    Verifying code.
                  </span>
                </div>
              )}
              {codeErrors && !isRequestLoading && (
                <div className={classes.secondaryDivStyle}>
                  <div className={classes.errorDivStyle}>{codeErrors}</div>
                </div>
              )}
              <Typography variant="body2" gutterBottom style={{ marginTop: 5 }}>
                Keep this window open while checking your email. If you can’t
                find your code, <b>check your spam or junk folder</b> or{' '}
                <Link to="" onClick={this.resendCode}>
                  resend code
                </Link>
                .
              </Typography>
              {isResendRequestLoading && (
                <div className={classes.secondaryDivStyle}>
                  <CircularProgress
                    size={24}
                    className={classes.progresStyle}
                  />
                  &nbsp;&nbsp;
                  <span className={classes.successDivStyle}>
                    {' '}
                    Resending code.
                  </span>
                </div>
              )}
              {resendCodeSuccess && !isResendRequestLoading && (
                <div className={classes.secondaryDivStyle}>
                  <CheckIcon className={classes.successDivStyle} />
                  &nbsp;&nbsp;
                  <span className={classes.successDivStyle}> Code resent.</span>
                </div>
              )}
            </div>
          </div>
        </Card>
      </Layout>
    );
  }
}

EnterCode.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  email: getEmailRegistered(state),
  isRequestLoading: getVerifyRequestLoadingStatus(state),
  isResendRequestLoading: getResendCodeRequestLoadingStatus(state),
});

export default connect(mapStateToProps, { verifyCode, resendCode })(
  withStyles(styles)(EnterCode),
);
