// @ts-nocheck TODO: type issues need to be fixed in this file
import * as Yup from 'yup';
import Fuse from 'fuse.js';
import {
  STRENGTH_WEAK,
  STRENGTH_NORMAL,
  STRENGTH_STRONG,
  STRENGTH_NONE,
} from '../../constants/progressValidator';

export const PASSWORD_LANGUAGE = {
  requiredPassword: 'Password is Required',
  minLength: 'Must be more than 8 characters',
  upperCase: 'At least one upper and one lowercase letter',
  numberAndSymbol: 'Must have one number and symbol',
  charactersLimit: 'Password should not be more than 128 characters long',
  emailUsernameSimilar: 'Do not use your username or email id as password',
};

export const passwordValidators = [
  {
    text: PASSWORD_LANGUAGE.upperCase,
    validation: Yup.string().matches(/^(?=.*[A-Z])(?=.*[a-z])/),
  },
  {
    text: PASSWORD_LANGUAGE.minLength,
    validation: Yup.string().min(8),
  },
  {
    text: PASSWORD_LANGUAGE.numberAndSymbol,
    validation: Yup.string().matches(
      /^(?=.*[0-9])(?=.*[!#$%^&*[\]{}:;"'().<,>?@_/|\\=+~\-`])/,
    ),
  },
];

/**
 * Pass the password and a list of validators to check the password valid
 * Returns isValid boolean and list of errors strings
 *
 * @param {string} password Pass in the password string to validate
 * @param {*} validators List of Validators which the password is validated
 */
export const validatePassword = (password, validators) => {
  let isValid = true;
  const errors = [];
  if (password) {
    validators.forEach(({ validation, text }) => {
      try {
        validation.validateSync(password);
      } catch (e) {
        errors.push(text);
        isValid = false;
      }
    });
    return { isValid, errors };
  }
  return { isValid: false, errors };
};

/**
 * Returns the validation texts based on the password, validatePassword's
 * errors and validators
 *
 * @param {string} password Password string to be validated
 * @param {*} errors Output from validatePassword for the same password
 * @param {*} validators List of Validators which the password is validated
 */
export const calculateValidationTexts = (password, errors, validators) => {
  const validationTexts = [];
  if (password) {
    for (let i = 0; i < validators.length; i += 1) {
      const { text } = validators[i];
      validationTexts.push({ text, checked: true });
    }
    for (let i = 0; i < errors.length; i += 1) {
      for (let j = 0; j < validationTexts.length; j += 1) {
        const error = errors[i];
        const validationText = validationTexts[j];
        if (error === validationText.text) {
          validationText.checked = false;
        }
      }
    }
  } else {
    for (let i = 0; i < validators.length; i += 1) {
      const { text } = validators[i];
      validationTexts.push({ text, checked: false });
    }
  }
  return validationTexts;
};

/**
 * Returns the password strength based on the password and calculateValidationText's
 * validationTexts
 *
 * @param {string} password Password string to be validated
 * @param {*} validationTexts Output from calculateValidationTexts for the same password
 */
export const calculateStrength = (password, validationTexts) => {
  let strength = STRENGTH_NONE;
  if (password) {
    let validCount = 0;
    validationTexts.forEach(({ checked }) => {
      if (checked) {
        validCount += 1;
      }
    });
    if (validCount !== 0 && validCount === 1) {
      strength = STRENGTH_WEAK;
    } else if (validCount !== 0 && validCount === 2) {
      strength = STRENGTH_NORMAL;
    } else if (validCount !== 0 && validCount === validationTexts.length) {
      strength = STRENGTH_STRONG;
    }
  }
  return strength;
};

export const checkPasswordMaxLength = (password, maxLen = 128) => {
  return password.length > maxLen;
};

export const checkForNameAndMailId = (password, keys) => {
  const fuseSearchOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['mailId', 'fullName'],
  };

  let invalidPasswordFound = [];
  const passwordChecker = [];
  passwordChecker.push(keys);

  const fuse = new Fuse(passwordChecker, fuseSearchOptions);
  invalidPasswordFound = fuse.search(password);
  return invalidPasswordFound;
};

export const checkPasswordStrength = (password?) => {
  const { errors } = validatePassword(password, passwordValidators);
  const newValidationTexts = calculateValidationTexts(
    password,
    errors,
    passwordValidators,
  );

  const newStrength = calculateStrength(password, newValidationTexts);

  return { newValidationTexts, newStrength };
};

export const checkPasswordsEquality = (password1, password2) => {
  let reEnterPasswordError = true;
  let saveButtonDisabled = true;
  if (password1) {
    if (
      password1.length >= 8 &&
      password2.length >= 8 &&
      password1 === password2
    ) {
      reEnterPasswordError = false;
      saveButtonDisabled = false;
    }
  }
  return { reEnterPasswordError, saveButtonDisabled };
};
