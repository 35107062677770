import React from 'react';

import classNames from 'classnames';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { INPUT_HEIGHT, useStyles } from '../../../componentsV2/core/TextField';

import { PhoneInput } from './styles';

export interface PhoneFieldProps {
  id: string;
  name: string;
  label: string;
  value: string;
  error: string;
  className?: string;
  helperText: string;
  placeholder: string;
  autoComplete: string;
  onInput: (phone: string) => void;
  onChange: (phone: string) => void;
  rootStyle: Record<string, unknown>;
}

const PhoneField = ({
  id,
  name,
  label,
  value,
  error,
  onInput,
  onChange,
  rootStyle,
  className,
  helperText,
  placeholder,
  autoComplete,
}: PhoneFieldProps) => {
  const styleProps = {
    height: INPUT_HEIGHT,
    hasLabel: !!label,
  };
  const classes = useStyles(styleProps);
  return (
    <FormControl
      className={classNames(classes.margin, className)}
      style={rootStyle}
      error={!!error}
    >
      <InputLabel
        shrink
        htmlFor={id}
        classes={{
          root: classes.formLabel,
          error: classes.errorFormLabel,
          focused: classes.formLabelFocused,
        }}
      >
        {error || label}
      </InputLabel>
      <PhoneInput
        id={id}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        onInput={(event: React.BaseSyntheticEvent) =>
          onInput(event.target.value)
        }
        onBlur={(event: React.BaseSyntheticEvent) =>
          onInput(event.target.value)
        }
        classes={{
          root: classes.root,
          input: error ? classes.errorInput : classes.input,
          error: classes.error,
        }}
      />
      {helperText && (
        <FormHelperText
          id="phone-number-helper-text"
          className={classes.helperFormLabel}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const MemoizedPhoneField = React.memo(PhoneField);
MemoizedPhoneField.displayName = 'PhoneField';

export default MemoizedPhoneField;
