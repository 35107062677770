import axios from 'axios';
import { createRequestInstance, watchRequests } from 'redux-saga-requests';
import { createDriver } from 'redux-saga-requests-axios';
import { all } from 'redux-saga/effects';
import { watchAuthenticateUserSaga } from '../Session'; // SIGN_OUT
import { watchAccountPlanChangeSagas } from '../admin/billing/account/index';

export default function* rootSaga() {
  yield all([
    watchAuthenticateUserSaga(),
    watchAccountPlanChangeSagas(),
    createRequestInstance({
      driver: createDriver(axios),
    }),
    watchRequests({
      // abortOn: SIGN_OUT,
    }),
  ]);
}
