import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bg404 from '../img/error-404.png';
import { APP_URL, HELP_URL } from '../config';

class PageNotFound extends Component {
  render() {
    return (
      <div className="nopage-bg__div">
        <div className="vertical-center">
          <div className="container">
            <div className="row  justify-content-center">
              <img className="bg-404__div" alt="404" src={bg404} />
            </div>
            <div className="row  justify-content-center top-buffer-row">
              <div className="col-8">
                <div className="row justify-content-center">
                  <div className="nopage-subheader__div">
                    Darn, we can’t seem to find that page!
                  </div>
                </div>
                <div className="row justify-content-center top-buffer-row">
                  <div className="nopage-body__div">
                    Here are some helpful links insted:
                  </div>
                </div>
                <div className="row justify-content-between top-buffer-row">
                  <div className="col-4" style={{ textAlign: 'center' }}>
                    <div
                      onClick={() => {
                        window.location.href = `${APP_URL}home`;
                      }}
                      className="nopage-redirect__Link "
                    >
                      Home
                    </div>
                  </div>
                  <div className="col-4" style={{ textAlign: 'center' }}>
                    <Link to="/redeem" className="nopage-redirect__Link ">
                      Cash out
                    </Link>
                  </div>
                  <div className="col-4" style={{ textAlign: 'center' }}>
                    <a href={HELP_URL} className="nopage-redirect__Link ">
                      FAQs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
