// @ts-nocheck TODO: type issues need to be fixed in this file
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  apiCallAction,
  apiCallSuccessAction,
  apiCallErrorAction,
} from './stateManagementConstants';

const useStateManagement = (reduxKey: string) => {
  const dispatch = useDispatch();
  const requestStartStateChange = useCallback(
    (requestDetails) =>
      dispatch(apiCallAction({ reduxKey, ...requestDetails })),
    [dispatch, reduxKey],
  );
  const requestSuccessStateChange = useCallback(
    (response) => dispatch(apiCallSuccessAction({ reduxKey, response })),
    [dispatch, reduxKey],
  );
  const requestErrorStateChange = useCallback(
    (error) => dispatch(apiCallErrorAction({ reduxKey, error })),
    [dispatch, reduxKey],
  );
  return {
    requestStartStateChange,
    requestSuccessStateChange,
    requestErrorStateChange,
  };
};

export default useStateManagement;
