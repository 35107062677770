import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import LinearGraph, { DataFormat } from '../../molecules/LinearGraph';
import LoadingPulse from '../../atoms/LoadingPulse';
import ChartToggleGroup from '../../organism/ChartToggleGroup';
import { colors } from '../../../Utils/analytics/common';
import {
  ANALYTICS_GRAPH_LOADING_SUBHEADER,
  ANALYTICS_GRAPH_LOADING_TEXT,
} from '../../../Utils/data/analytics/common';
import Body from '../../atoms/Body';

export interface InputChartDataFormat {
  id: string;
  data: DataFormat[];
}

export interface LinearGraphTemplateProps {
  chartData: InputChartDataFormat[] | undefined | null;
  chartHeight: string;
  chartWidth?: string;
  chartError?: boolean;
  chartName?: string;
  timeDuration?: string;
}

const useStyles = makeStyles({
  loadingContainer: (props: { chartHeight: string; chartWidth?: string }) => ({
    height: props.chartHeight,
    width: props.chartWidth || 'initial',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }),
});

const LinearGraphTemplate = (props: LinearGraphTemplateProps) => {
  const classes = useStyles(props);
  const { chartData, chartHeight, chartWidth, chartError, timeDuration } =
    props;
  const initializeLocalState = useCallback(
    (dataFromProps: InputChartDataFormat[] | undefined | null) =>
      dataFromProps
        ? new Map(
            dataFromProps.map((item, index) => [
              item.id,
              {
                isActive: true,
                backgroundColor: colors[index % colors.length].backgroundColor,
                color: colors[index % colors.length].color,
              },
            ]),
          )
        : undefined,
    [],
  );
  const [itemLocalState, setItemLocalState] = useState(
    initializeLocalState(chartData),
  );
  useEffect(() => {
    setItemLocalState(initializeLocalState(chartData));
  }, [chartData, initializeLocalState]);
  const toggleData = useMemo(
    () =>
      itemLocalState && chartData
        ? chartData.map(({ id }) => ({
            id,
            color: itemLocalState.get(id)?.color || '',
            backgroundColor: itemLocalState.get(id)?.backgroundColor || '',
            isActive: Boolean(itemLocalState.get(id)?.isActive),
          }))
        : null,
    [chartData, itemLocalState],
  );
  const graphData = useMemo(
    () =>
      itemLocalState && chartData
        ? chartData.reduce(
            (
              acc: { id: string; color: string; data: DataFormat[] }[],
              { id, data },
            ) => {
              const localItem = itemLocalState?.get(id);
              if (localItem?.isActive) {
                acc.push({ id, data, color: localItem.color });
              }
              return acc;
            },
            [],
          )
        : null,
    [chartData, itemLocalState],
  );
  const isLinesDisabled = useMemo(
    () => graphData?.length === 0 && chartData?.length !== 0,
    [chartData, graphData],
  );
  const toggleOnClick = useCallback(
    (id) => {
      if (itemLocalState) {
        const {
          color = '',
          backgroundColor = '',
          isActive = true,
        } = itemLocalState.get(id) || {};
        setItemLocalState(
          new Map(
            itemLocalState.set(id, {
              color,
              backgroundColor,
              isActive: !isActive,
            }),
          ),
        );
      }
    },
    [itemLocalState],
  );

  if (!chartError && !(chartData && toggleData && graphData)) {
    return (
      <div className={classes.loadingContainer}>
        <LoadingPulse isLoading />
        <Body variant="body1Bold">{ANALYTICS_GRAPH_LOADING_SUBHEADER}</Body>
        <Body variant="body2">{ANALYTICS_GRAPH_LOADING_TEXT}</Body>
      </div>
    );
  }

  return (
    <div>
      {toggleData && (
        <ChartToggleGroup
          toggleOnClick={toggleOnClick}
          toggleItems={toggleData}
        />
      )}
      <LinearGraph
        chartData={graphData}
        linesDisabled={isLinesDisabled}
        height={chartHeight}
        width={chartWidth || '100%'}
        error={chartError}
        timeDuration={timeDuration}
      />
    </div>
  );
};

export default LinearGraphTemplate;
