import React from 'react';
import { useParams } from 'react-router-dom';
import { SSOOptions, SSOTypes } from '../../../Utils/data/ssoManager/common';
import SSOErrorsPresentational from './SSOErrors.presentational';

import useSSOError from './useSSOError';
import { SSOErrorCodes } from '../../../Utils/data/ssoManager/error';

const SSOErrors = () => {
  const { sso, ssoType, errorCode } = useParams<{
    sso: SSOOptions;
    ssoType: SSOTypes;
    errorCode: SSOErrorCodes;
  }>();
  const { models, operations } = useSSOError(sso, ssoType, errorCode);

  return (
    <SSOErrorsPresentational
      headerText={models.headerText}
      bodyText={models.bodyText}
      primaryButton={{
        buttonText: models.buttonText,
        onClick: operations.onPrimaryButtonClick,
        icon: models.buttonIcon || undefined,
      }}
      subText={models.subText}
    />
  );
};

export default SSOErrors;
