import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

export const Container = styled.div`
  width: 250px;
`;

export const SVGContainer = styled.div`
  padding: 4px 0;
`;

export const ErrorContainer = styled.div`
  background-color: ${ThemeV2.palette.gray3};
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopoverHeader = styled.div`
  display: flex;
  align-items: center;
  & > span:last-child {
    margin-left: 8px;
  }
`;

export const PopoverContent = styled.div`
  margin: 4px 0 16px 32px;
`;
