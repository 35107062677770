// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AnchorLink } from '../../../componentsV2/core/Link';
import Typography from '../../../componentsV2/core/Typography';
import themeV2 from '../../../componentsV2/theme';
import { TERMS_URL, PRIVACY_URL } from '../../../config';

const styles = (theme) => ({
  terms: {
    color: themeV2.palette.lightGray3,
  },
  link: {
    color: themeV2.palette.darkGray3,
    fontSize: 12,
    '&:hover': {
      color: `${themeV2.palette.darkGray3} !important`,
    },
    '&:visited': {
      color: `${themeV2.palette.darkGray3} !important`,
    },
  },
});

function TermsAndPrivacy(props) {
  const { classes, buttonText } = props;
  return (
    <div>
      <Typography variant="caption" className={classes.terms} gutterBottom>
        By clicking the &quot;{buttonText}&quot; button, you’re agreeing to our{' '}
        <AnchorLink className={classes.link} to={TERMS_URL}>
          Terms of Service
        </AnchorLink>{' '}
        and{' '}
        <AnchorLink className={classes.link} to={PRIVACY_URL}>
          Privacy Policy
        </AnchorLink>
        .
      </Typography>
    </div>
  );
}

TermsAndPrivacy.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsAndPrivacy);
