// @ts-nocheck TODO: type issues need to be fixed in this file
import titleize from 'titleize';

const APP_CURRENCY_NAME = 'gold';
const APP_RECOGNITION = 'recognition';
const APP_RECOGNITION_PAST = 'recognized';
const GIVE_RECOGNITION_HEADER = 'Give Recognition';
const GIVE_RECOGNITION = 'give recognition';
const GIVE_RECOGNITION_CAPS = titleize(GIVE_RECOGNITION);
const SLACK_COMMAND = 'giverecognition';

const APP_NAME = 'Assembly';

const FLOW_POST_MAX_HEIGHT = 800;

const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const APP_CURRENCY = {
  NAME: APP_CURRENCY_NAME,
  NAME_TITLEIZED: titleize(APP_CURRENCY_NAME),
  NAME_ALL_CAPS: APP_CURRENCY_NAME.toUpperCase(),
  APP_CURRENCY_WITH_SPACES: ` ${APP_CURRENCY_NAME} `,
};

const GET_APP_CURRENCY = (currency = {}) => {
  const APP_CURRENCY_STR = currency.singular || APP_CURRENCY_NAME;
  const APP_CURRENCY_PLURAL_STR = currency.plural || APP_CURRENCY_NAME;
  return {
    NAME: APP_CURRENCY_STR,
    NAME_PLURAL: APP_CURRENCY_PLURAL_STR,
    NAME_TITLEIZED: APP_CURRENCY_STR,
    NAME_TITLEIZED_PLURAL: APP_CURRENCY_PLURAL_STR,
    NAME_ALL_CAPS: APP_CURRENCY_STR.toUpperCase(),
    NAME_ALL_CAPS_PLURAL: APP_CURRENCY_PLURAL_STR.toUpperCase(),
    APP_CURRENCY_WITH_SPACES: ` ${APP_CURRENCY_STR} `,
    APP_CURRENCY_WITH_SPACES_PLURAL: ` ${APP_CURRENCY_PLURAL_STR} `,
  };
};

const APP_VERBS = {
  RECOGNITION: APP_RECOGNITION,
  RECOGNITION_PAST: APP_RECOGNITION_PAST,
  RECOGNITION_TITLEIZED: titleize(APP_RECOGNITION),
  GAVE_RECOGNITION: 'recognized',
  MENTIONED: 'mentioned',
};

const FLASHBAR_AUTOHIDE_DURATION = 3000;

const FLASHBAR_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'error',
  INFO: 'info',
};

const FEED_TYPES = {
  RECOGNITION: 'recognition',
  BIRTHDAY: 'birthday',
  ANNIVERSARY: 'anniversary',
};

export { FLASHBAR_AUTOHIDE_DURATION, FLASHBAR_TYPES };

const AVATAR = {
  MIN_DIMENSIONS: {
    WIDTH: 220,
    HEIGHT: 220,
  },
  FILE_SIZE_LIMIT: 4 * 1000 * 1000,
};

const CULTURE_REWARDS = {
  MIN_DIMENSIONS: {
    WIDTH: 150,
    HEIGHT: 150,
  },
  FILE_SIZE_LIMIT: 15 * 1000 * 1000,
};

const CURRENCY = {
  SYMBOL: '$',
};

const ALLOWANCE_TYPES = {
  REGULAR: 'regular',
  CUSTOM: 'custom',
  NO_ALLOWANCE: 'no',
};

const REWARD_TYPES = {
  GIFT_CARD: 'gift card',
  CASH_EQUIVALENT: 'cash equivalent',
  DONATION: 'donation',
};

const DEFAULT_COUNTRY = 'US';

const IMPACT_DATA = {
  small: {
    displayName: 'Small Impact',
    emoji: '🙂',
  },
  medium: {
    displayName: 'Medium Impact',
    emoji: '😀',
  },
  large: {
    displayName: 'Large Impact',
    emoji: '👏',
  },
  huge: {
    displayName: 'Huge Impact',
    emoji: '💪',
  },
  above: {
    displayName: 'Above & Beyond',
    emoji: '🚀',
  },
};

const IMPACT_CUSTOM_OPTIONS_VALUES = {
  MANAGER_ONLY: 'MANAGER_ONLY',
  ADMINS_ONLY: 'ADMINS_ONLY',
  MANAGER_AND_CUSTOM_ALLOWANCES: 'MANAGER_AND_CUSTOM_ALLOWANCES_ONLY',
  EVERYONE: 'EVERYONE',
};

const PRIVATE_MESSAGE_OPTIONS_VALUES = {
  MANAGER_ONLY: 'MANAGER_ONLY',
  ADMINS_ONLY: 'ADMINS_ONLY',
  MANAGER_AND_CUSTOM_ALLOWANCES: 'MANAGER_AND_CUSTOM_ALLOWANCES_ONLY',
  EVERYONE: 'EVERYONE',
};

const IMPACT_CUSTOM_OPTIONS = [
  {
    label: 'Admins Only',
    value: IMPACT_CUSTOM_OPTIONS_VALUES.ADMINS_ONLY,
  },
  {
    label: 'Managers Only',
    value: IMPACT_CUSTOM_OPTIONS_VALUES.MANAGER_ONLY,
  },
  {
    label: 'Managers & Custom Allowances Only',
    value: IMPACT_CUSTOM_OPTIONS_VALUES.MANAGER_AND_CUSTOM_ALLOWANCES,
  },
];

const PRIVATE_MESSAGE_OPTIONS = [
  {
    label: 'Admins Only',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.ADMINS_ONLY,
  },
  {
    label: 'Managers Only',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.MANAGER_ONLY,
  },
  {
    label: 'Managers & Custom Allowances Only',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.MANAGER_AND_CUSTOM_ALLOWANCES,
  },
  {
    label: 'Entire organization',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.EVERYONE,
  },
];

const COOKIE_CONSTANTS = {
  FROM_IDENTITY_MANAGER_AUTHORIZE: 'FROM_IDENTITY_MANAGER_AUTHORIZE',
  SEEING_FIRST_TIME: 'SEEING-RECOGNITION-TIPS-FIRST-TIME',
  ASSEMBLY_TOKEN: 'assembly-token',
  ASSEMBLY_USER: 'assembly-user-data',
};

export {
  AVATAR,
  CULTURE_REWARDS,
  CURRENCY,
  FEED_TYPES,
  REWARD_TYPES,
  DEFAULT_COUNTRY,
  GIVE_RECOGNITION_HEADER,
  GIVE_RECOGNITION,
  GIVE_RECOGNITION_CAPS,
  APP_CURRENCY,
  GET_APP_CURRENCY,
  APP_VERBS,
  APP_NAME,
  SLACK_COMMAND,
  IMPACT_DATA,
  IMPACT_CUSTOM_OPTIONS,
  IMPACT_CUSTOM_OPTIONS_VALUES,
  PRIVATE_MESSAGE_OPTIONS_VALUES,
  ALLOWANCE_TYPES,
  PRIVATE_MESSAGE_OPTIONS,
  COOKIE_CONSTANTS,
  FLOW_POST_MAX_HEIGHT,
  DEFAULT_TIMEZONE,
};

export const ASSEMBLY_EMAILS = {
  SUPPORT: {
    LINK: 'mailto:support@joinassembly.com',
    TEXT: 'support@joinassembly.com',
  },
};
