// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/styles';
import themeV2 from '../../theme';

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
    height: 1,
    width: '100%',
    fontSize: 18,
    fontFamily: themeV2.typography.adminFontFamily,
    fontWeight: themeV2.typography.fontWeightSemibold,
    '& span': {
      position: 'absolute',
      left: 'auto',
      right: 'auto',
      top: -13,
      background: '#fff',
      padding: '0 20px',
      fontSize: 'inherit',
      display: 'block',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
    },
    '&.small': {
      fontSize: '12px',
      fontWeight: 'normal',
      '& span': {
        top: -8,
        padding: '0 8px',
      },
    },
  },
};

class TextDivider extends Component {
  renderRootColor = () => {
    if (this.props.color) {
      return { background: this.props.color };
    } else {
      return {
        background: themeV2.palette.lightGray1,
      };
    }
  };

  renderTextColor = () => {
    if (this.props.color) {
      return { color: this.props.color };
    } else {
      return {
        color: themeV2.palette.lightGray1,
      };
    }
  };

  render() {
    const { classes, text, small } = this.props;
    return (
      <div
        className={classnames(classes.root, small && 'small')}
        style={this.renderRootColor()}
      >
        <span style={this.renderTextColor()}>{text}</span>
      </div>
    );
  }
}

export default withStyles(styles)(TextDivider);
