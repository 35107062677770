import ThemeV2 from '../../../componentsV2/theme';

export const CARD_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Roboto, Menlo, monospace',
      fontWeight: 'normal',
      fontSize: '16px',
      border: `1px solid ${ThemeV2.palette.gray5}`,
      color: ThemeV2.palette.darkText,
      borderRadius: '3px',
      marginBottom: '16px',
      '::placeholder': {
        color: ThemeV2.palette.gray6,
      },
    },
    invalid: {
      color: ThemeV2.palette.darkText,
    },
  },
  hideIcon: true,
};
