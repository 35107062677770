import React, { useCallback } from 'react';
import { EmojiData, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { EmoticonPickerProps } from './interface';
import { EMOTICON_PICKER_TEXT } from '../../../languages/en/home';
import { isWindows } from '../../../Utils/window';

const EmoticonPicker = (props: EmoticonPickerProps) => {
  const { onEmoticonSelect } = props;

  const handleOnClick = useCallback(
    (emoji: EmojiData) => {
      onEmoticonSelect(emoji);
    },
    [onEmoticonSelect],
  );
  return (
    <Picker
      onClick={handleOnClick}
      set={isWindows() ? 'google' : 'apple'}
      sheetSize={16}
      native={!isWindows()}
      title={EMOTICON_PICKER_TEXT}
      emoji="point_up"
    />
  );
};

const MemoizedEmoticonPicker = React.memo(EmoticonPicker);
MemoizedEmoticonPicker.displayName = 'EmoticonPicker';
export default MemoizedEmoticonPicker;
