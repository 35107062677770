import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ThemeV2 from './componentsV2/theme';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    min-height: 46px;
    padding: 8px 16px;
  }

  .Toastify__toast--error {
    background: ${ThemeV2.palette.red7};
  }
  .Toastify__toast--info {
    background: ${ThemeV2.palette.geekBlue7};
  }
  .Toastify__toast--success {
    background: ${ThemeV2.palette.polarGreen7};
  }

  .Toastify__toast-body {
    align-self: center;
  }

  .Toastify__close-button {
    align-self: center;
    margin-right: 6px;
  }
`;

export default StyledToastContainer;
