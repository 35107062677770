import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';

import ButtonSSO, { LoginType } from '../../atomic/atoms/ButtonSSO';
import useFetchSSOConfig from './useFetchSSOConfig/useFetchSSOConfig';
import {
  SSOOptions,
  SSOContent,
  SSOTypes,
} from '../../Utils/data/ssoManager/common';
import { SSOComponentProps } from './interfaces';
import RoutesList from '../../aV2/routes/routesList';
import usePreferredSSO from '../../controllers/MSTeams/usePreferredSSO';
import { isSourceMSTeams } from '../../Utils/msteams';
import GSuiteSSOController, {
  GOOGLE_SSO_FLOW,
  GSuiteSSOChildrenProps,
} from '../../controllers/GSuiteSSOController';
import {
  getSSOErrorData,
  SSOErrorCodes,
} from '../../Utils/data/ssoManager/error';
import InfoAlert from '../../atomic/molecules/InfoAlert';
import ThemeV2 from '../../componentsV2/theme';
import Button from '../../atomic/atoms/Button';
import { signInWithText } from '../../languages/en/signIn';

const useStyles = makeStyles({
  errorInfoAlert: {
    margin: '16px 0',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: 'calc(100% + 16px)',
    margin: '12px -12px -8px -12px',
  },
  buttonWrapper: {
    padding: '8px',
    flexGrow: 0,
    maxWidth: '50%',
    flexBasis: '50%',
    '@media(max-width:640px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  buttonWrapperFull: {
    padding: '8px',
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
    '@media(max-width:640px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  buttonRoot: {
    width: '100%',
  },
});

const SSOComponent = ({
  onSSOClick,
  ssoType,
  showPreferred,
  showAsPrimary,
}: SSOComponentProps) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { models: slackModels, operations: slackOperations } =
    useFetchSSOConfig(SSOOptions.SLACK, ssoType, false);
  const { models: officeModels, operations: officeOperations } =
    useFetchSSOConfig(SSOOptions.OFFICE365, ssoType, false);
  const { models: adpModels, operations: adpOperations } = useFetchSSOConfig(
    SSOOptions.ADP,
    ssoType,
    false,
  );
  const { models } = usePreferredSSO();
  const isFromMSTeams = isSourceMSTeams();
  const classes = useStyles();

  useEffect(() => {
    // Handling Sign in error flow for ADP, since we are using same URL
    // for both Sign In and IM for ADP. During Sign In flow, the user will
    // not be in session, so it will be redirected to /signin route.
    // We capture error and sso from query param to handle error.
    if (parsedParams.error && parsedParams.sso) {
      push(
        `/${RoutesList.SSO_ERRORS_FN(
          parsedParams.sso,
          ssoType,
          parsedParams.error,
        )}`,
      );
    }
  }, [parsedParams.error, parsedParams.sso, push, ssoType]);

  const redirectToURL = useCallback((authorizeUri: string) => {
    window.location.href = authorizeUri;
  }, []);

  const handleSlackClick = useCallback(() => {
    onSSOClick(SSOContent[SSOOptions.SLACK].displayName);
    if (isFromMSTeams) {
      slackOperations.msTeamsOAuthStart();
    } else {
      redirectToURL(slackModels.ssoAuthorizeURI);
    }
  }, [
    onSSOClick,
    isFromMSTeams,
    slackOperations,
    redirectToURL,
    slackModels.ssoAuthorizeURI,
  ]);

  const handleOfficeClick = useCallback(() => {
    onSSOClick(SSOContent[SSOOptions.OFFICE365].displayName);
    if (isFromMSTeams) {
      officeOperations.msTeamsOAuthStart();
    } else {
      redirectToURL(officeModels.ssoAuthorizeURI);
    }
  }, [
    isFromMSTeams,
    officeModels.ssoAuthorizeURI,
    officeOperations,
    onSSOClick,
    redirectToURL,
  ]);

  const verifyGoogleSSOCode = useCallback(
    (code) => {
      const basePath =
        ssoType === SSOTypes.SIGN_IN
          ? RoutesList.SSO_SIGNIN_AUTHORIZE_FN(SSOOptions.GOOGLE)
          : RoutesList.SSO_SIGNUP_AUTHORIZE_FN(SSOOptions.GOOGLE);
      push(`/${basePath}?code=${code}`);
    },
    [push, ssoType],
  );

  const handleGoogleSSOError = useCallback(
    (error) => {
      const { error: errorCode } = error;
      if (errorCode === SSOErrorCodes.COOKIES_NOT_ALLOWED) {
        const message = getSSOErrorData(errorCode, ssoType, SSOOptions.GOOGLE);
        setErrorMessage(message.bodyText);
      } else {
        push(
          `/${RoutesList.SSO_ERRORS_FN(SSOOptions.GOOGLE, ssoType, errorCode)}`,
        );
      }
    },
    [push, ssoType],
  );
  const loginType =
    ssoType === SSOTypes.SIGN_IN ? LoginType.SignIn : LoginType.SignUp;

  const handleADPClick = useCallback(() => {
    if (loginType === LoginType.SignUp) {
      window.location.href = 'https://apps.adp.com/apps/316525';
    } else {
      onSSOClick(SSOContent[SSOOptions.ADP].displayName);
      if (isFromMSTeams) {
        adpOperations.msTeamsOAuthStart();
      } else {
        redirectToURL(adpModels.ssoAuthorizeURI);
      }
    }
  }, [
    adpModels.ssoAuthorizeURI,
    adpOperations,
    isFromMSTeams,
    onSSOClick,
    redirectToURL,
    loginType,
  ]);

  const gSuiteSSOFlow =
    ssoType === SSOTypes.SIGN_UP
      ? GOOGLE_SSO_FLOW.SIGN_UP
      : GOOGLE_SSO_FLOW.SIGN_IN;

  const SlackButton = useMemo(() => {
    if (showPreferred === SSOOptions.SLACK) {
      return (
        <Button
          onClick={handleSlackClick}
          disabled={slackModels.isLoading}
          color={showAsPrimary ? 'primary' : 'secondary'}
          icon="signin-slack"
          isFullWidth
        >
          {signInWithText.SLACK}
        </Button>
      );
    }
    return (
      <ButtonSSO
        loginType={loginType}
        sso={SSOContent[SSOOptions.SLACK]}
        onClick={handleSlackClick}
        disabled={slackModels.isLoading}
        classes={{ root: classes.buttonRoot }}
      />
    );
  }, [
    classes.buttonRoot,
    handleSlackClick,
    loginType,
    showPreferred,
    showAsPrimary,
    slackModels.isLoading,
  ]);

  const OfficeButton = useMemo(() => {
    if (showPreferred === SSOOptions.OFFICE365) {
      return (
        <Button
          onClick={handleOfficeClick}
          disabled={officeModels.isLoading}
          color={showAsPrimary ? 'primary' : 'secondary'}
          icon="office-365-mini-logo"
          isFullWidth
        >
          {signInWithText.OFFICE365}
        </Button>
      );
    }
    return (
      <ButtonSSO
        loginType={loginType}
        sso={SSOContent[SSOOptions.OFFICE365]}
        onClick={handleOfficeClick}
        disabled={officeModels.isLoading}
        classes={{ root: classes.buttonRoot }}
      />
    );
  }, [
    classes.buttonRoot,
    handleOfficeClick,
    loginType,
    officeModels.isLoading,
    showPreferred,
    showAsPrimary,
  ]);

  const ADPButton = useMemo(() => {
    if (showPreferred === SSOOptions.ADP) {
      return (
        <Button
          onClick={handleADPClick}
          disabled={adpModels.isLoading}
          color={showAsPrimary ? 'primary' : 'secondary'}
          icon="adp-logo"
          isFullWidth
        >
          {signInWithText.ADP}
        </Button>
      );
    }
    return (
      <ButtonSSO
        loginType={loginType}
        sso={SSOContent[SSOOptions.ADP]}
        classes={{ root: classes.buttonRoot }}
        onClick={handleADPClick}
        disabled={loginType === LoginType.SignIn && adpModels.isLoading}
      />
    );
  }, [
    adpModels.isLoading,
    classes.buttonRoot,
    handleADPClick,
    loginType,
    showPreferred,
    showAsPrimary,
  ]);

  const LifionButton = useMemo(() => {
    return (
      <Button onClick={onSSOClick} disabled={false} color="primary" isFullWidth>
        {signInWithText.LIFION}
      </Button>
    );
  }, [onSSOClick]);

  const GSuiteButton = useMemo(() => {
    return (
      <GSuiteSSOController
        flow={gSuiteSSOFlow}
        onSuccess={verifyGoogleSSOCode}
        onFailure={handleGoogleSSOError}
      >
        {({ signIn, loaded, initError }: GSuiteSSOChildrenProps) => {
          const handleClick = () => {
            if (loaded) {
              if (initError) {
                const errorCode = initError as SSOErrorCodes;
                const message = getSSOErrorData(
                  errorCode,
                  ssoType,
                  SSOOptions.GOOGLE,
                );
                setErrorMessage(message.bodyText);
              } else {
                signIn();
              }
            }
          };
          if (showPreferred === SSOOptions.GOOGLE) {
            return (
              <Button
                onClick={handleClick}
                disabled={!loaded}
                color={showAsPrimary ? 'primary' : 'secondary'}
                icon="google-light"
                isFullWidth
              >
                {signInWithText.GOOGLE}
              </Button>
            );
          }
          return (
            <ButtonSSO
              loginType={loginType}
              sso={SSOContent[SSOOptions.GOOGLE]}
              onClick={handleClick}
              disabled={!loaded}
              classes={{ root: classes.buttonRoot }}
            />
          );
        }}
      </GSuiteSSOController>
    );
  }, [
    classes.buttonRoot,
    gSuiteSSOFlow,
    handleGoogleSSOError,
    loginType,
    ssoType,
    verifyGoogleSSOCode,
    showPreferred,
    showAsPrimary,
  ]);

  const doNotShowErrorMessage = useCallback(() => {
    setErrorMessage('');
  }, []);

  const infoAlertDismissible = useMemo(
    () => ({
      closeIconColor: ThemeV2.palette.dustRed6,
      onCloseClick: doNotShowErrorMessage,
      onCloseKeyPress: doNotShowErrorMessage,
    }),
    [doNotShowErrorMessage],
  );

  const showSingleSignInButton = isFromMSTeams || showPreferred;

  if (showSingleSignInButton) {
    const preferredSSO = models.preferredSSO || showPreferred;
    switch (preferredSSO) {
      case SSOOptions.SLACK:
        return <div className={classes.buttonWrapperFull}>{SlackButton}</div>;
      case SSOOptions.OFFICE365:
        return <div className={classes.buttonWrapperFull}>{OfficeButton}</div>;
      case SSOOptions.ADP:
        return <div className={classes.buttonWrapperFull}>{ADPButton}</div>;
      case SSOOptions.GOOGLE:
        return <div className={classes.buttonWrapperFull}>{GSuiteButton}</div>;
      case SSOOptions.LIFION:
        return <div className={classes.buttonWrapperFull}>{LifionButton}</div>;
      default:
        return <div className={classes.buttonWrapperFull}>{OfficeButton}</div>;
    }
  }

  return (
    <div>
      {errorMessage !== '' && (
        <InfoAlert
          className={classes.errorInfoAlert}
          id={+new Date()}
          alertType="error"
          text={errorMessage}
          isDismissible={infoAlertDismissible}
        />
      )}
      <div className={classes.buttons}>
        <div className={classes.buttonWrapper}>{SlackButton}</div>
        <div className={classes.buttonWrapper}>{OfficeButton}</div>
        <div className={classes.buttonWrapper}>{ADPButton}</div>
        <div className={classes.buttonWrapper}>{GSuiteButton}</div>
      </div>
    </div>
  );
};

export default SSOComponent;
