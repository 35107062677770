// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import _ from 'lodash';
import { API_URL_WITH_V2 } from '../../../config';
import { AuthHeaders } from '../../shared/HeaderToken';
import { adminProcessError, adminActions } from '../../Admin';
import Logger from '../../../Utils/Logger';
import {
  showSuccessMessage,
  showInfoMessage,
  showErrorMessage,
} from '../../../Utils/flashHandler';
import {
  getCountryAndCurrenyCodes,
  filterDenomsAgainstCardItems,
} from '../Utils';
// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  CUSTOM_REWARD: `${API_URL_WITH_V2}/employer/rewards/custom`,
  GET_GIFTS_CATALOG: `${API_URL_WITH_V2}/employer/rewards/catalog`,
  SET_DENOMINATION_GIFT: `${API_URL_WITH_V2}/employer/rewards/catalog/denomination/set`,
  UNSET_DENOMINATION_GIFT: `${API_URL_WITH_V2}/employer/rewards/catalog/denomination/unset`,
  SET_ALL_DENOMINATION_GIFT: `${API_URL_WITH_V2}/employer/rewards/catalog/denomination/setall`,
  UNSET_ALL_DENOMINATION_GIFT: `${API_URL_WITH_V2}/employer/rewards/catalog/denomination/unsetall`,
  SET_ALL_CATALOG_GIFT: `${API_URL_WITH_V2}/employer/rewards/catalog/setAllCatalog`,
  UNSET_ALL_CATALOG_GIFT: `${API_URL_WITH_V2}/employer/rewards/catalog/unsetAllCatalog`,
  TOGGLE_GIFT_CARD: `${API_URL_WITH_V2}/employer/rewards/catalog/activate`,
};

export const CATALOG_REQUEST_STATUS = {
  BLANK: 'BLANK',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

// ------------------------------------
// Constants
// ------------------------------------
const GET_CULTURE_REWARDS_SUCCESS = 'GET_CULTURE_REWARDS_SUCCESS';
export const CREATE_CUSTOM_REWARD_SUCCESS = 'create_custom_reward_success';
export const EDIT_CUSTOM_REWARD_SUCCESS = 'edit_custom_reward_success';
export const DELETE_CUSTOM_REWARD_SUCCESS = 'delete_custom_reward_success';
export const GET_GIFT_CATALOG_SUCCESS = 'get_gift_catalog_success';
const GET_GIFT_CARDS_COUNTRIES_SUCCESS = 'GET_GIFT_CARDS_COUNTRIES_SUCCESS';
const GET_DONATIONS_COUNTRIES_SUCCESS = 'GET_DONATIONS_COUNTRIES_SUCCESS';
const GET_GIFT_CARD_CURRENCY_CODE_SUCCESS =
  'GET_GIFT_CARD_CURRENCY_CODE_SUCCESS';
export const SET_GIFT_DENOMINATION_SUCCESS = 'set_gift_denomination_success';
export const TOGGLE_GIFT_CARD_SUCCRSS = 'toggle_gift_card_success';
export const REWARDS_MANAGE_REQUEST_FAILED = 'rewards_manage_request_failed';
export const UPDATE_CATATLOG_REQUEST_STATUS = 'UPDATE_CATATLOG_REQUEST_STATUS';

// ------------------------------------
// Actions
// ------------------------------------

const getCultureRewardsSuccess = (rewards) => ({
  type: GET_CULTURE_REWARDS_SUCCESS,
  rewards,
});

const createCustomRewardSuccess = (newReward) => ({
  type: CREATE_CUSTOM_REWARD_SUCCESS,
  newReward,
});
const editCustomRewardSuccess = (reward) => ({
  type: EDIT_CUSTOM_REWARD_SUCCESS,
  reward,
});

const deleteCustomRewardSuccess = (reward) => ({
  type: DELETE_CUSTOM_REWARD_SUCCESS,
  reward,
});

const updateCatalogRequestStatus = (status) => ({
  type: UPDATE_CATATLOG_REQUEST_STATUS,
  status,
});

const getGiftsCatalogSuccess = (payload) => ({
  type: GET_GIFT_CATALOG_SUCCESS,
  payload,
});
const gotGiftCardCountries = (countries) => ({
  type: GET_GIFT_CARDS_COUNTRIES_SUCCESS,
  countries,
});

const gotGiftCardCurrencyCodes = (currencyCodes) => ({
  type: GET_GIFT_CARD_CURRENCY_CODE_SUCCESS,
  currencyCodes,
});

const gotDonationCountries = (countries) => ({
  type: GET_DONATIONS_COUNTRIES_SUCCESS,
  countries,
});
const setGiftDenominationSuccess = (employerRewards) => ({
  type: SET_GIFT_DENOMINATION_SUCCESS,
  updatedEmployerRewards: employerRewards,
});

const toggleGiftCardSuccess = (brandKey, isActive, denominations) => ({
  type: TOGGLE_GIFT_CARD_SUCCRSS,
  brandKey,
  isActive,
  denominations,
});
const requestFailed = (errorMessage) => ({
  type: REWARDS_MANAGE_REQUEST_FAILED,
  error: errorMessage,
});

export const actions = {
  getCultureRewardsSuccess,
  getGiftsCatalogSuccess,
  updateCatalogRequestStatus,
  gotGiftCardCountries,
  gotDonationCountries,
  gotGiftCardCurrencyCodes,
  editCustomRewardSuccess,
  deleteCustomRewardSuccess,
  createCustomRewardSuccess,
  setGiftDenominationSuccess,
  toggleGiftCardSuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getCultureRewards = () => async (dispatch) => {
  try {
    const res = await axios.get(apiEndpoints.CUSTOM_REWARD, AuthHeaders());
    const result = res.data;
    Logger(result, true);
    if (result.success) {
      dispatch(actions.getCultureRewardsSuccess(result.data));
    }
  } catch (error) {
    adminProcessError(error, dispatch, actions);
  }
};

export const createCustomRewardsAction = (props) => async (dispatch) => {
  try {
    dispatch(showInfoMessage('Creating culture reward...'));
    dispatch(
      actions.updateCatalogRequestStatus(CATALOG_REQUEST_STATUS.PENDING),
    );
    const res = await axios.post(
      apiEndpoints.CUSTOM_REWARD,
      props,
      AuthHeaders(),
    );
    const result = res.data;
    Logger(result, true);
    if (result.success) {
      dispatch(showSuccessMessage('Culture reward created'));
      dispatch(
        actions.updateCatalogRequestStatus(CATALOG_REQUEST_STATUS.SUCCESS),
      );
      dispatch(actions.createCustomRewardSuccess(result.data));
    }
  } catch (error) {
    adminProcessError(error, dispatch, actions);
    dispatch(
      showErrorMessage(
        'Sorry, please upload a 150x150 minimum image or greater',
      ),
    );
    dispatch(actions.updateCatalogRequestStatus(CATALOG_REQUEST_STATUS.BLANK));
  }
};
export const editCustomRewardsAction = (props) => {
  Logger(props);
  return async (dispatch) => {
    try {
      dispatch(
        actions.updateCatalogRequestStatus(CATALOG_REQUEST_STATUS.PENDING),
      );
      dispatch(showInfoMessage('Editing reward...'));
      const res = await axios.put(
        `${apiEndpoints.CUSTOM_REWARD}/${props._id}`,
        props,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        dispatch(showSuccessMessage('Successfully edited the reward.'));
        dispatch(
          actions.updateCatalogRequestStatus(CATALOG_REQUEST_STATUS.SUCCESS),
        );
        dispatch(actions.editCustomRewardSuccess(result.data));
      } else {
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};
export const toggelCustomRewardsAction = (isActive, cultureRewardId) => {
  Logger(isActive);
  return async (dispatch) => {
    try {
      if (isActive) {
        dispatch(showInfoMessage('Turning on reward...'));
      } else {
        dispatch(showInfoMessage('Turning off reward...'));
      }

      const res = await axios.post(
        `${apiEndpoints.CUSTOM_REWARD}/${cultureRewardId}/activate`,
        {
          isActive,
        },
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        if (isActive) {
          dispatch(showSuccessMessage('Successfully turned on reward.'));
        } else {
          dispatch(showSuccessMessage('Successfully turned off reward.'));
        }
        dispatch(actions.editCustomRewardSuccess(result.data));
      } else {
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};
export const deleteCustomRewardsAction = (props) => {
  const { _id } = props;
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Deleting reward...'));
      const res = await axios.delete(
        `${apiEndpoints.CUSTOM_REWARD}/${_id}`,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        dispatch(showSuccessMessage('Successfully deleted the reward.'));
        dispatch(actions.deleteCustomRewardSuccess(props));
      } else {
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const getGitfsCatalogAction = () => async (dispatch) => {
  try {
    const res = await axios.get(apiEndpoints.GET_GIFTS_CATALOG, AuthHeaders());
    const result = res.data;
    Logger(result, true);
    if (result.success) {
      const { employer, donations } = result.data;
      let { giftCards } = result.data;
      giftCards = giftCards.filter((giftCard) => giftCard.items.length > 0);
      const denominations = filterDenomsAgainstCardItems(
        giftCards,
        result.data.employerRewards || [],
      );
      const giftCardDetails = getCountryAndCurrenyCodes(giftCards);
      const { countries, currencyCodes } = giftCardDetails;
      const donationCardDetails = getCountryAndCurrenyCodes(donations);
      const doantionCountries = donationCardDetails.countries;

      dispatch(actions.gotGiftCardCurrencyCodes(currencyCodes));
      dispatch(actions.gotGiftCardCountries(countries));
      dispatch(actions.gotDonationCountries(doantionCountries));

      dispatch(adminActions.gotMyEmployer(employer));
      dispatch(
        actions.getGiftsCatalogSuccess({ giftCards, donations, denominations }),
      );
    } else {
    }
  } catch (error) {
    adminProcessError(error, dispatch, actions);
  }
};
export const setUnsetGitfDenominationAction = (props, set) => {
  const {
    utid,
    brandKey,
    faceValue,
    currencyCode,
    rewardType,
    isActive,
    countries,
  } = props;
  return async (dispatch) => {
    const data = {
      utid,
      brandKey,
      faceValue,
      currencyCode,
      rewardType,
      isActive,
      countries,
    };
    try {
      const res = await axios.post(
        set
          ? apiEndpoints.SET_DENOMINATION_GIFT
          : apiEndpoints.UNSET_DENOMINATION_GIFT,
        data,
        AuthHeaders(),
      );
      const result = res.data;

      Logger(result, true);
      if (result.success) {
        dispatch(actions.setGiftDenominationSuccess(result.data));
      } else {
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};
export const setUnsetAllGitfDenominationAction = (props, set) => {
  const { denominations, brandKey, isActive, countries, rewardType } = props;
  return async (dispatch) => {
    let data = {};
    if (set) {
      data = { denominations, brandKey, isActive, countries, rewardType };
    } else {
      data = { brandKey, isActive, countries, rewardType };
    }
    try {
      const res = await axios.post(
        set
          ? apiEndpoints.SET_ALL_DENOMINATION_GIFT
          : apiEndpoints.UNSET_ALL_DENOMINATION_GIFT,
        data,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        dispatch(actions.setGiftDenominationSuccess(result.data));
      } else {
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};
export const setUnsetAllCatalogDenominationAction =
  (props, set, rewardType = 'gift card') =>
  async (dispatch) => {
    const data = props;
    try {
      if (set) {
        if (rewardType === 'donations') {
          dispatch(showInfoMessage('Turning all donations on...'));
        } else {
          dispatch(showInfoMessage('Turning all cards on...'));
        }
      } else if (!set) {
        if (rewardType === 'donations') {
          dispatch(showInfoMessage('Turning all donations off...'));
        } else {
          dispatch(showInfoMessage('Turning all cards off...'));
        }
      }
      const res = await axios.post(
        apiEndpoints.SET_ALL_CATALOG_GIFT,
        { data },
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        const employer = result.data;
        dispatch(adminActions.gotMyEmployer(employer));
        if (set) {
          if (rewardType === 'donations') {
            dispatch(showSuccessMessage('All donations turned on'));
          } else {
            dispatch(showSuccessMessage('All cards turned on'));
          }
        } else if (!set) {
          if (rewardType === 'donations') {
            dispatch(showSuccessMessage('All donations turned off'));
          } else {
            dispatch(showSuccessMessage('All cards turned off'));
          }
        }

        dispatch(actions.setGiftDenominationSuccess(employer.rewards.tango));
      } else {
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
export const toggleGitfCardAction = (props) => {
  const { brandKey, isActive, denominations, rewardType, countries } = props;
  return async (dispatch) => {
    const data = {
      brandKey,
      isActive,
      denominations,
      rewardType,
      countries,
    };
    try {
      const res = await axios.post(
        apiEndpoints.TOGGLE_GIFT_CARD,
        data,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        dispatch(
          actions.toggleGiftCardSuccess(brandKey, isActive, denominations),
        );
      } else {
      }
    } catch (error) {
      const message = adminProcessError(error, dispatch, actions);
      if (message) {
        dispatch(showErrorMessage(message));
      }
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CATATLOG_REQUEST_STATUS: {
      return { ...state, catalogRequestStatus: action.status };
    }
    case GET_GIFT_CATALOG_SUCCESS: {
      const giftCardsArray = action.payload.giftCards;
      const donationsArray = action.payload.donations;
      const denominationArray = action.payload.denominations;
      // let { denominations, giftCards, donations } = action.payload;
      const giftCards = {};
      const donations = {};
      const denominations = {};
      giftCardsArray.forEach((card) => {
        if (!giftCards[card.brandKey]) {
          giftCards[card.brandKey] = card;
        }
      });
      donationsArray.forEach((card) => {
        if (!donations[card.brandKey]) {
          donations[card.brandKey] = card;
        }
      });
      denominationArray.forEach((denom) => {
        if (!denominations[denom.brandKey]) {
          denominations[denom.brandKey] = denom;
        }
      });
      return {
        ...state,
        giftCards: { ...state.giftCards, ...giftCards },
        donations: { ...state.donations, ...donations },
        denominations: { ...state.denominations, ...denominations },
      };
    }
    case GET_GIFT_CARDS_COUNTRIES_SUCCESS: {
      return { ...state, giftCardCountries: action.countries };
    }
    case GET_GIFT_CARD_CURRENCY_CODE_SUCCESS: {
      const exsistingCurrecyCodes = state.giftCardCurrencyCodes || [];
      action.currencyCodes.forEach((currencyCode) => {
        if (exsistingCurrecyCodes.indexOf(currencyCode) === -1) {
          exsistingCurrecyCodes.push(currencyCode);
        }
      });
      return { ...state, giftCardCurrencyCodes: [...exsistingCurrecyCodes] };
    }
    case GET_DONATIONS_COUNTRIES_SUCCESS: {
      return { ...state, donationCountries: action.countries };
    }
    case SET_GIFT_DENOMINATION_SUCCESS: {
      const { updatedEmployerRewards } = action;
      const denominations = {};
      updatedEmployerRewards.forEach((denom) => {
        if (!denominations[denom.brandKey]) {
          denominations[denom.brandKey] = denom;
        }
      });
      return {
        ...state,
        denominations: { ...state.denominations, ...denominations },
      };
    }
    case TOGGLE_GIFT_CARD_SUCCRSS: {
      let denomination = state.denominations[action.brandKey];
      denomination = {
        ...denomination,
        isActive: action.isActive,
        denominations: action.denominations,
      };
      return {
        ...state,
        denominations: {
          ...state.denominations,
          [action.brandKey]: denomination,
        },
      };
    }
    case GET_CULTURE_REWARDS_SUCCESS: {
      const rewardsArray = action.rewards || [];
      const cultureRewards = {};
      rewardsArray.forEach((card) => {
        if (!cultureRewards[card._id]) {
          cultureRewards[card._id] = card;
        }
      });
      return {
        ...state,
        cultureRewards: { ...state.cultureRewards, ...cultureRewards },
      };
    }
    case CREATE_CUSTOM_REWARD_SUCCESS: {
      const { cultureRewards } = state;
      const { newReward } = action;
      if (!cultureRewards[newReward._id]) {
        cultureRewards[newReward._id] = newReward;
      }
      return { ...state, cultureRewards: { ...cultureRewards } };
    }
    case EDIT_CUSTOM_REWARD_SUCCESS: {
      const { cultureRewards } = state;
      const { reward } = action;
      if (!cultureRewards[reward._id]) {
        cultureRewards[reward._id] = reward;
      } else {
        cultureRewards[reward._id] = {
          ...cultureRewards[reward._id],
          ...reward,
        };
      }
      return { ...state, cultureRewards: { ...cultureRewards } };
    }
    case DELETE_CUSTOM_REWARD_SUCCESS: {
      const { cultureRewards } = state;
      return {
        ...state,
        cultureRewards: _.omit(cultureRewards, [action.reward._id]),
      };
    }
    case REWARDS_MANAGE_REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
