import React, { useEffect } from 'react';

import MSTeamConfigurePopupContent from '../../atomic/molecules/MSTeamConfigurePopupContent';
import useMSTeamsConfig from './useMSTeamsConfig';
import usePreferredSSO from './usePreferredSSO';
import { setMSTeamsAsSource } from '../../Utils/msteams';

const MSTeamsTabConfig = () => {
  setMSTeamsAsSource();
  const { operations } = useMSTeamsConfig();
  usePreferredSSO();

  useEffect(() => {
    operations.setupMSTeamsConfig();
  }, [operations]);
  return <MSTeamConfigurePopupContent />;
};

export default MSTeamsTabConfig;
