// @ts-nocheck TODO: type issues need to be fixed in this file
import cookie from 'react-cookies';
import qs from 'qs';
import Client from '../../vendors/assembly_metrics';
import { checkIfProduction } from '../admin';

export const EventType = {
  REGISTRATION: 'REGISTRATION',
  PROFILE_COMPLETE: 'PROFILE_COMPLETE',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export function trackEvent(eventType, props) {
  const { userId } = props;

  const lander = cookie.load('lander') || null;
  const referrer = cookie.load('referrer') || null;
  const userIdStored = cookie.load('userId');

  if (userId && userId !== userIdStored) {
    cookie.save('userId', userId, { path: '/' });
  }

  let type;
  switch (eventType) {
    case EventType.REGISTRATION:
      type = Client.eventTypes.REGISTRATION;
      break;
    case EventType.PROFILE_COMPLETE:
      type = Client.eventTypes.PROFILE_COMPLETE;
      break;
    case EventType.SUBSCRIPTION:
      type = Client.eventTypes.SUBSCRIPTION;
      break;
    default:
      type = eventType;
  }

  Client.event({
    environment: checkIfProduction() ? 'production' : 'development',
    type,
    lander,
    referrer,
    ...props,
  });
}

export function saveTrackingDetails() {
  const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { lander, referrer } = parsed;

  if (
    !cookie.load('lander') &&
    Object.prototype.hasOwnProperty.call(parsed, 'lander')
  ) {
    cookie.save('lander', lander || '-', { path: '/' });
  }
  if (
    !cookie.load('referrer') &&
    Object.prototype.hasOwnProperty.call(parsed, 'referrer')
  ) {
    cookie.save('referrer', referrer || '-', { path: '/' });
  }
}
