import { useCallback, useMemo, useRef, useState } from 'react';

import { usePopper } from 'react-popper';

import useOnClickOutside from '../useOnOutsideClick';
import { PositionStrategies, UsePopoverProps } from './interface';

const usePopover = (props: UsePopoverProps) => {
  const {
    xAxisOffset,
    yAxisOffset,
    popoverPosition = 'bottom',
    children,
    strategy = PositionStrategies.Fixed,
  } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const containerRef = useRef(null);

  const popperAttributes = useMemo(
    () => ({
      placement: popoverPosition,
      strategy,
      modifiers: [
        { name: 'offset', options: { offset: [yAxisOffset, xAxisOffset] } },
      ],
    }),
    [popoverPosition, strategy, xAxisOffset, yAxisOffset],
  );
  const { styles, update } = usePopper(
    referenceElement.current,
    popperElement.current,
    popperAttributes,
  );
  const togglePopover = useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
    if (update) {
      update();
    }
  }, [isPopoverOpen, update]);

  const onOutsideClickHandler = useCallback(() => {
    if (isPopoverOpen) {
      setIsPopoverOpen(false);
    }
  }, [isPopoverOpen]);

  useOnClickOutside(containerRef, onOutsideClickHandler);

  const childrenWithPopoverProps = useMemo(
    () => children({ isPopoverOpen, togglePopover }),
    [children, isPopoverOpen, togglePopover],
  );

  return {
    models: {
      childrenWithPopoverProps,
      containerRef,
      referenceElement,
      popperElement,
      isPopoverOpen,
      styles,
    },
    operations: {
      togglePopover,
    },
  };
};

export default usePopover;
