import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import RadioGroup, { RadioOptionProps } from '../../molecules/RadioGroup';
import {
  selectTierHeading,
  selectTierSubHeadingHeader,
  selectTierSubHeading,
  selectTierPrimaryButtonText,
  upgradePlanIntegrationInfo,
} from '../../../Utils/data/planUpgradeModal';

const useStyles = makeStyles({
  heading: {
    marginBottom: '16px',
  },
  subHeading: {
    marginBottom: '24px',
  },
  integrationInfo: {
    marginBottom: '16px',
  },
});

export interface PlanUpgradeSelectTierModalProps {
  heading: string;
  onNextClick(): void;
  radioOptionsData: RadioOptionProps[];
  tier: string;
  tierNames: {
    tier1: string;
    tier2: string;
    tier3: string;
    tier4: string;
  };
  onRadioChange(value: string): void;
  onModalClose(): void;
  isButtonsLoading: boolean;
  showIntegrationSubText?: boolean;
}

const PlanUpgradeSelectTierModal = (props: PlanUpgradeSelectTierModalProps) => {
  const classes = useStyles(props);
  const {
    heading: templateHeading,
    onNextClick,
    radioOptionsData,
    tier,
    onRadioChange,
    onModalClose,
    isButtonsLoading,
    tierNames,
    showIntegrationSubText = false,
  } = props;
  const handleRadioChange = useCallback(
    (e) => {
      const { value } = e.target;
      onRadioChange(value);
    },
    [onRadioChange],
  );
  const primaryButton = {
    text: selectTierPrimaryButtonText,
    isLoading: isButtonsLoading,
    onClick: onNextClick,
  };
  return (
    <BasicModalTemplate
      heading={templateHeading}
      primaryButton={primaryButton}
      onModalClose={onModalClose}
    >
      <Heading variant="h5" className={classes.heading}>
        {selectTierHeading(
          tierNames.tier1,
          tierNames.tier2,
          tierNames.tier3,
          tierNames.tier4,
        )}
      </Heading>
      {showIntegrationSubText && (
        <div className={classes.integrationInfo}>
          {upgradePlanIntegrationInfo}
        </div>
      )}
      <div className={classes.subHeading}>
        <Body variant="body2Bold" inline>
          {selectTierSubHeadingHeader}
        </Body>
        <Body variant="body2" inline>
          {selectTierSubHeading(
            tierNames.tier2,
            tierNames.tier3,
            tierNames.tier4,
          )}
        </Body>
      </div>
      <RadioGroup
        radioOptions={radioOptionsData}
        value={tier}
        name="tier"
        onChange={handleRadioChange}
      />
    </BasicModalTemplate>
  );
};

export default PlanUpgradeSelectTierModal;
