// @ts-nocheck TODO: type issues need to be fixed in this file
export const ADMIN_ROLES = ['Owner', 'Admin'];
export const OWNER_ROLES = ['Owner'];
export const USER_ROLES = ['Employee'];

export const checkIfAdmin = (user) => {
  const { role } = user;
  const hasRole = role.find((rol) => {
    if (ADMIN_ROLES.includes(rol)) {
      return true;
    }
    return false;
  });
  return !!hasRole;
};
