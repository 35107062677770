import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';

export const AccordionWrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  margin: 0 24px 24px 24px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
`;

export const AccordionSummary = styled.div`
  position: relative;
  max-width: 100%;
  padding: 32px 56px 32px 32px;
  cursor: pointer;
`;

export const AccordionDetails = styled.div<{
  height: string;
  isAccordionOpen: boolean;
}>`
  max-width: 100%;
  max-height: ${({ height }) => height};
  overflow: auto;
  transition: max-height 0.4s ease-out;
`;

export const AccordionContent = styled.div`
  padding: 32px 32px;
  border-top: 1px solid ${ThemeV2.palette.gray4};
`;

export const SvgIconWrapper = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
`;
