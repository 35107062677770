export const NEED_A_BOOST_CONTENT = `We’ve found that Assembly works best when more of your team is
participating regularly. Encourage more team members to give regularly to reach the next benchmark!`;
export const TOP_PERFORMER_CONTENT = `Congratulations on your engagement with Assembly. There’s a great
amount of recognition going on! With this level of engagement, you’ll start to reap the benefits of a 
culture of recognition. Keep it going!`;
export const DOING_GREAT_CONTENT = `You’re on your way to building a great Assembly. There’s a good 
amount of activity happening! Encourage more people to engage, and your Assembly will be a top performer. 
Give recognition to keep the ball rolling.`;
export const TOP_PERFORMER_CONTENT_PROFILE_INFO = `A lot of your team has their profile information entered, 
which means everyone can celebrate each other on birthdays and anniversaries when their big days come. Keep it up!`;
export const DOING_GREAT_CONTENT_PROFILE_INFO = `A good amount of your team has their profile information entered, 
which means they’ll be celebrated on their birthdays and anniversaries. Get everyone in on the fun, and 
add some more information!`;
export const NEED_A_BOOST_CONTENT_PROFILE_INFO = `Celebrating birthdays and anniversaries is a great way to boost team 
morale. Keep adding profile information so everyone can join in on the fun! Need help?`;
export const NEED_A_BOOST_HEADER = 'Need a boost?';
export const DOING_GREAT_HEADER = 'Doing great';
export const TOP_PERFORMER_HEADER = 'Top performer!';
export const NEED_A_BOOST_BUTTON = 'Check out some tips';
export const GIVE_RECOGNITION_BUTTON = 'Give Recognition';
export const ADD_INFO_BUTTON = 'Add more information';

export const ACCORDING_SUMMARY_HEADER = 'Overall engagement rating';
export const ACCORDING_SUMMARY_SUBHEADER =
  'Want to know more about our benchmarking system? Expand to learn more.';
export const BENCHMARK_TOP_PERFORMER_COMMENT = `Congratulations! Your team is taking full advantage of Assembly and 
engagement is in top shape. Keep it up.`;
export const BENCHMARK_DOING_GREAT_COMMENT = `Great job! Take a look at all of your engagement metrics below, and 
we’ll give you some tips for taking your Assembly’s engagement from great to top performer.`;
export const BENCHMARK_NEED_A_BOOST_COMMENT = `Developing and nurturing a culture of recognition can take time. 
Find ways to help your team engage with Assembly to reach the next benchmark. Need some ideas?`;
export const BENCHMARK_NEED_A_BOOST_BUTTON =
  'Book a call with a Customer Success Manager';
export const BENCHMARK_ERROR_HEADER =
  'Error pulling overall engagement ranking';
export const BENCHMARK_ERROR_COMMENT = 'Please refresh this page to try again.';
export const BENCHMARK_ERROR_TOOLTIP = 'Error finding this data';
export const BENCHMARK_KEY_HEADER = 'Assembly’s Benchmarking Key';
export const BENCHMARK_KEY_CONTENT = `Insights benchmarking helps you understand all of the activity that’s going on 
within your Assembly. Based on different threshholds of company size and engagement, categories like Power Users, 
Birthday Adds, and more will be shown a level based on the percentile of activity. We aggregate these into an overall 
engagement rating for your Assembly. What does each level mean?`;
export const BENCHMARK_KEY_DOING_GREAT_CONTENT = `You’re doing a good job of making sure your employees are engaged 
with Assembly. With that extra push, you’ll be a top performer.`;
export const BENCHMARK_KEY_NEED_BOOST_CONTENT = `Looks like you haven’t gotten in your groove, yet. Find ways to 
help your team engage with Assembly to reach the next benchmark.`;
export const BENCHMARK_TALK_WITH_MANAGER =
  'Talk with a Customer Success Manager';

export const BENCHMARK_KEYS = [
  {
    icon: 'benchmark-top-performer',
    header: TOP_PERFORMER_HEADER,
    content: BENCHMARK_TOP_PERFORMER_COMMENT,
  },
  {
    icon: 'benchmark-doing-great',
    header: DOING_GREAT_HEADER,
    content: BENCHMARK_KEY_DOING_GREAT_CONTENT,
  },
  {
    icon: 'benchmark-need-boost',
    header: NEED_A_BOOST_HEADER,
    content: BENCHMARK_KEY_NEED_BOOST_CONTENT,
  },
];
