export default function () {
  return [
    { color: '#258df2', value: 'Primary' },
    { color: '#B71C1C', value: 'Red' },
    { color: '#D81B60', value: 'Pink' },
    { color: '#4A148C', value: 'Purple' },
    { color: '#3F51B5', value: 'Indigo' },
    { color: '#1565C0', value: 'Blue' },
    { color: '#00BCD4', value: 'Cyan' },
    { color: '#009688', value: 'Teal' },
    { color: '#2E7D32', value: 'Green' },
    { color: '#827717', value: 'Lime' },
    { color: '#FF4400', value: 'Orange' },
    { color: '#3E2723', value: 'Brown' },
    { color: '#424242', value: 'Gray' },
    { color: '#4527A0', value: 'Deep purple' },
    { color: '#1b202a', value: 'Dark' },
    { color: '#2E313A', value: 'Dark Deep' },
    { color: '#1c2236', value: 'Dark purple' },
    { color: '#202432', value: 'Dark' },
    { color: '#212a42', value: 'Dark' },
    { color: '#141a2d', value: 'Dark' },
    { color: '#101524', value: 'Dark' },
  ];
}
