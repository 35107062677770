import styled, { css } from 'styled-components';

import Checkbox from '../../atoms/Checkbox';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import { ellipsisStyle } from '../../../Utils/styles/css';

import ThemeV2 from '../../../componentsV2/theme';

export const StyledWrapper = styled.div<{
  hasInfo?: boolean;
  hasAvatar?: boolean;
}>`
  position: relative;
  display: ${({ hasInfo }) => hasInfo && 'flex'};
  align-items: ${({ hasInfo }) => hasInfo && 'center'};
  justify-content: ${({ hasInfo }) => hasInfo && 'space-between'};
  margin-bottom: ${({ hasAvatar }) => (hasAvatar ? '12px' : '8px')};
  min-height: ${({ hasAvatar }) => hasAvatar && '24px'};
`;

const InfoStyle = css`
  overflow: hidden;
  padding-left: 1px;
  flex: 1;
`;

export const StyledCheckbox = styled(Checkbox)<{
  value?: boolean;
  hasInfo?: boolean;
  hasAvatar: boolean;
}>`
  ${({ hasInfo }) => hasInfo && InfoStyle};
  div:last-child {
    position: relative;
    color: ${ThemeV2.palette.gray8};
    font-size: 16px;
    padding-left: ${({ hasAvatar }) => hasAvatar && '36px'};
    z-index: 1;
    font-weight: ${({ value }) => value && 500};
    width: ${({ hasAvatar }) =>
      hasAvatar ? 'calc(100% - 36px)' : 'calc(100% - 24px)'};
    ${ellipsisStyle}
    user-select: none;
  }
`;

export const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 0;
  left: 24px;
`;

export const StyledBody = styled(Body)`
  white-space: nowrap;
`;
