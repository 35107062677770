// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '../../atoms/Divider';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import { OR } from '../../../languages/en/singleWords';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20px',
    width: '29px',
    textAlign: 'center',
  },
  orText: ({ color }) => ({
    color,
  }),
});

const OrDivider = ({ color, className: parentClassName }) => {
  const { textBox, orText, root } = useStyles({ color });
  const className = `${root} ${parentClassName}`;
  return (
    <div className={className}>
      <Divider color={color} />
      <div className={textBox}>
        <Body variant="body3" className={orText}>
          {OR}
        </Body>
      </div>
      <Divider color={color} />
    </div>
  );
};

OrDivider.propTypes = {
  color: string,
  className: string,
};

OrDivider.defaultProps = {
  color: ThemeV2.palette.gray6,
  className: '',
};

export default OrDivider;
