import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import {
  VERIFY_CREATE_PASSWORD_TOKEN,
  GET_LOGIN_OPTIONS,
  CREATE_PASSWORD,
} from '../../../constants/endpoints';
import { makeAPICallWithDataReturn } from '../../utils';

export interface VerifyCreatePasswordTokenResponse {
  data: {
    userId: string;
    email: string;
  };
}

export type SAMLLoginOptionsType = {
  uri: string;
  name: string;
};

export type GetLoginOptionsResponse = {
  password?: 'ENABLED' | 'DISABLED';
  memberId: string;
  sso?: 'SLACK' | 'GSUITE' | 'OFFICE365' | 'ADP' | 'NO_PREFERRED_SSO_FOUND';
  saml?: SAMLLoginOptionsType;
};

export type GetLoginOptionsError = {
  message: string;
};

export interface VerifyCreatePasswordTokenError {
  message: string;
  code: number;
}

export interface CreatePasswordResponse {
  data: {
    user: any;
    jwtToken: string;
  };
}

export type CreatePasswordError = {
  message: string;
};

export const useVerifyCreatePasswordTokenQuery = (
  makeRequest = true,
  token = '',
) => {
  return useQuery<
    VerifyCreatePasswordTokenResponse,
    AxiosError<VerifyCreatePasswordTokenError>
  >(
    VERIFY_CREATE_PASSWORD_TOKEN,
    () =>
      makeAPICallWithDataReturn(VERIFY_CREATE_PASSWORD_TOKEN, null, undefined, {
        token,
      }),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};

export const useGetLoginOptionsQuery = (makeRequest = true, email = '') => {
  return useQuery<GetLoginOptionsResponse, AxiosError<GetLoginOptionsError>>(
    GET_LOGIN_OPTIONS,
    () =>
      makeAPICallWithDataReturn(GET_LOGIN_OPTIONS, null, {
        email,
      }),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};

export const useCreatePasswordQuery = (
  makeRequest = true,
  userId = '',
  password = '',
) => {
  return useQuery<CreatePasswordResponse, AxiosError<CreatePasswordError>>(
    CREATE_PASSWORD,
    () =>
      makeAPICallWithDataReturn(CREATE_PASSWORD, {
        userId,
        password,
      }),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};
