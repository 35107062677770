import React from 'react';
import styled from 'styled-components';

import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../atoms/Body';

const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin-left: 16px;
`;

interface ToastContentProps {
  icon: string;
  message?: string;
  iconSize?: string;
  title?: string;
}

const ToastContent = ({
  icon,
  message,
  iconSize,
  title,
}: ToastContentProps) => {
  return (
    <Wrapper>
      <StyledSVGIcon
        color={ThemeV2.palette.gray1}
        icon={icon}
        size={iconSize ? iconSize : '16px'}
      />
      {title ? (
        <ContentWrapper>
          <Body variant="body1Bold">{title}</Body>
          <Body variant="body3">{message}</Body>
        </ContentWrapper>
      ) : (
        <span>{message}</span>
      )}
    </Wrapper>
  );
};

export default ToastContent;
