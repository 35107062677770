import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import {
  upgradeReviewPrimaryButtonText,
  upgradeReviewSecondaryButtonText,
  upgradeReviewHeading,
  upgradeReviewSubHeadingHeader,
  getUpgradeReviewSubHeading,
  upgradeReviewPlanHeading,
  getUpgradeReviewCardLast4DigitsDetail,
  getUpgradeReviewTeamMembersDetail,
} from '../../../Utils/data/planUpgradeModal';

const useStyles = makeStyles({
  heading: {
    marginBottom: '16px',
  },
  subHeading: {
    marginBottom: '24px',
  },
  listTitle: {
    color: ThemeV2.palette.darkText,
  },
  ul: {
    paddingLeft: '16px',
  },
  li: {
    marginBottom: '0px',
    '& .div': {
      color: ThemeV2.palette.darkText,
    },
  },
});

export interface PlanUpgradeReviewModalProps {
  heading: string;
  onNextClick(): void;
  onNevermindClick(): void;
  onModalClose(): void;
  isButtonsLoading: boolean;
  newPlanName: string;
  renewalDate: string;
  price: string;
  cardLast4Digits: string;
  teamMembersString: string;
}

const PlanUpgradeReviewModal = (props: PlanUpgradeReviewModalProps) => {
  const classes = useStyles(props);
  const {
    heading: templateHeading,
    onNextClick,
    onNevermindClick,
    onModalClose,
    isButtonsLoading,
    newPlanName,
    renewalDate,
    price,
    cardLast4Digits,
    teamMembersString,
  } = props;
  const primaryButton = {
    text: upgradeReviewPrimaryButtonText,
    isLoading: isButtonsLoading,
    onClick: onNextClick,
  };
  const secondaryButton = {
    text: upgradeReviewSecondaryButtonText,
    disabled: isButtonsLoading,
    onClick: onNevermindClick,
  };
  return (
    <BasicModalTemplate
      heading={templateHeading}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      onModalClose={onModalClose}
    >
      <Heading variant="h5" className={classes.heading}>
        {upgradeReviewHeading}
      </Heading>
      <div className={classes.subHeading}>
        <Body variant="body2Bold" inline>
          {upgradeReviewSubHeadingHeader}
        </Body>
        <Body variant="body2" inline>
          {getUpgradeReviewSubHeading({
            newPlanName,
            renewalDate,
            price,
          })}
        </Body>
      </div>
      <Body variant="body2Bold" className={classes.listTitle}>
        {upgradeReviewPlanHeading}
      </Body>
      <ul className={classes.ul}>
        <li className={classes.li}>
          <Body variant="body2" className={classes.listTitle}>
            {getUpgradeReviewCardLast4DigitsDetail(cardLast4Digits)}
          </Body>
        </li>
        <li className={classes.li}>
          <Body variant="body2" className={classes.listTitle}>
            {getUpgradeReviewTeamMembersDetail(teamMembersString)}
          </Body>
        </li>
      </ul>
    </BasicModalTemplate>
  );
};

export default PlanUpgradeReviewModal;
