import React, { useState, useCallback } from 'react';
import { usePopper } from 'react-popper';

import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

import { ToolTipRoot, ToolTipText } from './styles';
import { ToolTipProps } from './interface';

const ToolTip = (props: ToolTipProps) => {
  const {
    icon,
    iconColor,
    iconHoverColor = '',
    toolTipComponent,
    children,
    showTooltipByDefault,
    position,
    positionStrategy = 'absolute',
    className,
    iconSize = '16px',
    disabled,
    toolTipSize,
  } = props;

  const [isTooltipOpen, setTooltipOpen] =
    useState<boolean>(showTooltipByDefault);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLSpanElement | null>(
    null,
  );
  const { styles } = usePopper(referenceElement, popperElement, {
    placement: position,
    strategy: positionStrategy,
  });

  const handleMouseEnter = useCallback(() => {
    setTooltipOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const renderToolTipComponent = useCallback(() => {
    if (toolTipComponent) {
      return toolTipComponent;
    }
    return (
      <SVGIcon
        icon={icon}
        color={iconColor}
        size={iconSize}
        hoverColor={iconHoverColor}
      />
    );
  }, [icon, iconColor, iconSize, toolTipComponent, iconHoverColor]);

  return (
    <ToolTipRoot
      className={className}
      ref={setReferenceElement}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="ToolTipWrapper"
    >
      {renderToolTipComponent()}
      {isTooltipOpen && !disabled && (
        <ToolTipText
          style={styles.popper}
          ref={setPopperElement}
          toolTipSize={toolTipSize}
        >
          <Body variant="body3" color="gray1">
            {children}
          </Body>
        </ToolTipText>
      )}
    </ToolTipRoot>
  );
};

ToolTip.defaultProps = {
  icon: 'icon-circle-outlined',
  iconColor: ThemeV2.palette.gray6,
  showTooltipByDefault: false,
  position: 'bottom',
  disabled: false,
};

export default ToolTip;
