// @ts-nocheck TODO: type issues need to be fixed in this file
import { requestsReducer, success } from 'redux-saga-requests';
import _ from 'lodash';
import { AuthHeadersV2 } from '../../../../../modules/shared/HeaderToken';
import { API_URL_WITH_V2 } from '../../../../../config';

const apiEndpoints = {
  REWARDS: `${API_URL_WITH_V2}/user/settings/rewards`,
  RESEND_EMAIL: `${API_URL_WITH_V2}/user/settings/resendEmail`,
};

export const GET_REWARD_LISTS = '@settings/myrewards/GET_REWARD_LISTS';
export const RESEND_EMAIL = '@settings/myrewards/RESEND_EMAIL';

export const getMyRewardsList = () => ({
  type: GET_REWARD_LISTS,
  request: {
    url: apiEndpoints.REWARDS,
    method: 'get',
    ...AuthHeadersV2(),
  },
  meta: {
    asPromise: true,
  },
});

export const resendEmail = (orderId) => ({
  type: RESEND_EMAIL,
  request: {
    url: apiEndpoints.RESEND_EMAIL,
    data: {
      orderId,
    },
    method: 'post',
    ...AuthHeadersV2(),
  },
  meta: {
    asPromise: true,
    rewardId: orderId,
  },
});

export default requestsReducer({
  actionType: GET_REWARD_LISTS,
  getData: (state, action) => {
    switch (action.type) {
      case success(GET_REWARD_LISTS): {
        const rewards = {};
        const incomingRewards = _.get(action, ['data', 'data', 'orders'], []);
        incomingRewards.forEach((element) => {
          rewards[element._id] = { ...element };
        });
        return { ...state.data, rewards };
      }
      default:
        return { ...state.data };
    }
  },
  mutations: {
    [RESEND_EMAIL]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(RESEND_EMAIL): {
            const rewards = _.get(state, ['data', 'rewards'], {});
            const udpatedReward = _.get(action, ['data', 'data', 'order'], {});

            const rewardId = udpatedReward._id;
            const rewardToUpdate = rewards[rewardId] || {};
            const reward = { ...rewardToUpdate, ...udpatedReward };
            return {
              ...state.data,
              rewards: { ...rewards, [rewardId]: reward },
            };
          }
          default:
            return { ...state.data };
        }
      },
    },
  },
});
