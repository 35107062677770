import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ComputedDatum,
  DatumValue,
  Point,
  ResponsiveLine,
  Datum,
} from '@nivo/line';
import Image from '../../atoms/Image';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import AnalyticsNoDataImage from '../../../img/analytics/analytics-graph-no-data-image.svg';
import AnalyticsGraphAllToggleOff from '../../../img/analytics/analytics-graph-all-toggle-off-image.svg';
import AnalyticsGraphErrorImage from '../../../img/error.svg';
import {
  ANALYTICS_GRAPH_LINES_DISABLED_HEADING,
  ANALYTICS_GRAPH_LINES_DISABLED_TEXT,
  ANALYTICS_GRAPH_NO_DATA_HEADING,
  ANALYTICS_GRAPH_NO_DATA_TEXT,
  ANALYTICS_GRAPH_PLACEHOLDER_IMAGE_ALT_TEXT,
  ANALYTICS_GRAPH_ERROR_HEADER,
  ANALYTICS_GRAPH_ERROR_SUBHEADER,
  DATA_IN_PROGRESS,
} from '../../../Utils/data/analytics/common';
import {
  generateAxisLeftValues,
  getDateFormattedString,
} from '../../../Utils/adminAnalytics/methods';
import IconInfo from '../IconInfo';

const styleById = {
  dashed: {
    strokeDasharray: '6, 3',
    strokeWidth: 3,
    opacity: 0.4,
  },
  default: {
    strokeWidth: 3,
  },
};

interface Series {
  id: string | number;
  color?: string;
  data: ComputedDatum[];
}

const DashedLine = ({
  series,
  lineGenerator,
  xScale,
  yScale,
}: {
  series: Series[];
  lineGenerator: (data: Datum[]) => string;
  xScale: (value: string | number | Date) => number;
  yScale: (value: string | number | Date) => number;
}) => {
  return series.map(({ id, data, color }) => (
    <g key={id}>
      <path
        key={`${id}_dup`}
        d={lineGenerator(
          data.slice(data.length - 2, data.length).map((d) => ({
            x: xScale(d.data.x || ''),
            y: yScale(d.data.y || ''),
          })),
        )}
        fill="none"
        stroke={color}
        style={styleById.dashed}
      />
      <path
        key={id}
        d={lineGenerator(
          data.slice(0, data.length - 1).map((d) => ({
            x: xScale(d.data.x || ''),
            y: yScale(d.data.y || ''),
          })),
        )}
        fill="none"
        stroke={color}
        style={styleById.default}
      />
    </g>
  ));
};

const useTooltipStyles = makeStyles({
  tooltip: {
    background: ThemeV2.palette.gray1,
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    padding: '8px 12px',
    width: 'auto',
    maxWidth: '212px',
  },
  date: {
    fontSize: '12px',
    lineHeight: '20px',
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
    color: ThemeV2.palette.gray7,
  },
  chartItems: {
    paddingLeft: '0px',
    listStyle: 'none',
    margin: '4px 0px 0px 0px',
  },
  chartItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0px',
    borderBottom: `1px solid ${ThemeV2.palette.gray4}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  chartItemText: {
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '12px',
    lineHeight: '20px',
    fontStyle: 'normal',
    color: ThemeV2.palette.gray8,
    margin: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chartItemNumberIndicator: {
    margin: '0px 0px 0px auto',
    paddingLeft: '8px',
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightBold,
    fontSize: '12px',
    lineHeight: '20px',
    fontStyle: 'normal',
  },
});

const useStyles = makeStyles({
  root: (props: { height: string; width: string }) => ({
    position: 'relative',
    height: props.height,
    width: props.width,
    zIndex: 0,
  }),
  graphPlaceholder: {
    position: 'absolute',
    top: '0px',
    left: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 24px)',
    width: '100%',
    background: ThemeV2.palette.gray1,
    zIndex: 1,
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  placeholderImage: {
    marginBottom: '32px',
  },
  placeholderText: {
    color: ThemeV2.palette.gray10,
    '&:last-child': {
      marginTop: '4px',
    },
  },
});

export interface DataFormat {
  x: string;
  y: number;
  isLastTimeDurationData?: boolean;
}

export interface LinearGraphProps {
  chartData: { id: string; color: string; data: DataFormat[] }[] | null;
  linesDisabled?: boolean;
  width: string;
  height: string;
  className?: string;
  error?: boolean;
  timeDuration?: string;
}

const chartStyling = {
  margin: { top: 12, right: 48, bottom: 80, left: 48 },
  theme: {
    axis: {
      ticks: {
        text: {
          fill: ThemeV2.palette.gray9,
          fontSize: '12px',
          lineHeight: '20px',
          fontFamily: ThemeV2.typography.adminFontFamily,
        },
      },
    },
    grid: {
      line: {
        stroke: ThemeV2.palette.gray4,
      },
    },
  },
};
export interface ChartTooltipProps {
  slice: {
    id: DatumValue;
    height: number;
    width: number;
    x0: number;
    x: number;
    y0: number;
    y: number;
    points: Point[];
  };
  timeDuration?: string;
}

export const ChartTooltip = ({ slice, timeDuration }: ChartTooltipProps) => {
  const classes = useTooltipStyles();
  const formattedString = useMemo(
    () => getDateFormattedString(slice.points[0].data.x, timeDuration),
    [slice, timeDuration],
  );
  return (
    <div className={classes.tooltip}>
      {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {slice.points[0].data?.isLastTimeDurationData && (
        <IconInfo backgroundColor={ThemeV2.palette.gold2} icon="warning-shield">
          {DATA_IN_PROGRESS}
        </IconInfo>
      )}
      <span className={classes.date}>{formattedString}</span>
      <ul className={classes.chartItems}>
        {slice.points
          .sort(
            (a, b) =>
              parseInt(b.data.y.toString(), 10) -
              parseInt(a.data.y.toString(), 10),
          )
          .map((point) => (
            <li className={classes.chartItem} key={point.serieId}>
              <span className={classes.chartItemText}>{point.serieId}</span>
              <span
                className={classes.chartItemNumberIndicator}
                style={{ color: point.color }}
              >
                {point.data.y}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

const LinearGraph = (props: LinearGraphProps) => {
  const classes = useStyles(props);
  const {
    chartData,
    linesDisabled,
    className: parentClassName,
    error,
    timeDuration,
  } = props;
  const styles = `${classes.root} ${parentClassName}`;

  const dataLength = useMemo(
    () => (chartData && chartData[0] && chartData[0]?.data?.length) || 0,
    [chartData],
  );
  const SliceTooltip = useCallback(
    ({ slice }) => <ChartTooltip slice={slice} timeDuration={timeDuration} />,
    [timeDuration],
  );
  const noChartData = dataLength === 0;
  const isPlaceholderActive = error || linesDisabled || noChartData;

  const renderPlaceholderContent = useCallback(() => {
    let imageSrc;
    let placeholderHeader;
    let placeholderSubHeader;
    if (error) {
      imageSrc = AnalyticsGraphErrorImage;
      placeholderHeader = ANALYTICS_GRAPH_ERROR_HEADER;
      placeholderSubHeader = ANALYTICS_GRAPH_ERROR_SUBHEADER;
    } else if (linesDisabled) {
      imageSrc = AnalyticsGraphAllToggleOff;
      placeholderHeader = ANALYTICS_GRAPH_LINES_DISABLED_HEADING;
      placeholderSubHeader = ANALYTICS_GRAPH_LINES_DISABLED_TEXT;
    } else {
      imageSrc = AnalyticsNoDataImage;
      placeholderHeader = ANALYTICS_GRAPH_NO_DATA_HEADING;
      placeholderSubHeader = ANALYTICS_GRAPH_NO_DATA_TEXT;
    }
    return (
      <>
        <Image
          className={classes.placeholderImage}
          src={imageSrc}
          alt={ANALYTICS_GRAPH_PLACEHOLDER_IMAGE_ALT_TEXT}
        />
        <Body className={classes.placeholderText} variant="body1Bold">
          {placeholderHeader}
        </Body>
        <Body className={classes.placeholderText} variant="body2">
          {placeholderSubHeader}
        </Body>
      </>
    );
  }, [classes, error, linesDisabled]);

  const axisLeftValues = useMemo(
    () => generateAxisLeftValues(chartData),
    [chartData],
  );

  const xAxisTickValues = useMemo(() => {
    if (!chartData || dataLength <= 0) {
      return [];
    }

    const xAxisData = chartData && chartData[0].data.map((i) => i.x);
    if (dataLength < 10) {
      return xAxisData;
    }
    const showOneTickForValues = Math.ceil(dataLength / 12);
    return xAxisData.filter((val, index) => index % showOneTickForValues === 0);
  }, [chartData, dataLength]);

  const xAxisFormat = useCallback(
    (d: DatumValue) => getDateFormattedString(d, timeDuration),
    [timeDuration],
  );

  return (
    <div className={styles}>
      {isPlaceholderActive ? (
        <div className={classes.graphPlaceholder}>
          <div className={classes.innerWrapper}>
            {renderPlaceholderContent()}
          </div>
        </div>
      ) : (
        <ResponsiveLine
          data={chartData || []}
          theme={chartStyling.theme}
          margin={chartStyling.margin}
          curve="monotoneX"
          colors={(chartColor) => chartColor.color}
          lineWidth={4}
          enablePoints={Boolean(
            chartData && chartData[0].data && chartData[0].data.length === 1,
          )}
          axisBottom={{
            tickSize: 0,
            tickPadding: 12,
            format: xAxisFormat,
            tickValues: xAxisTickValues,
          }}
          yScale={{
            type: 'linear',
            min: axisLeftValues[0],
            max: axisLeftValues[axisLeftValues.length - 1],
            stacked: false,
            reverse: false,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 16,
            tickValues: axisLeftValues,
          }}
          enableGridY={false}
          enableSlices="x"
          enableCrosshair
          isInteractive
          sliceTooltip={SliceTooltip}
          layers={['axes', 'grid', DashedLine, 'slices', 'crosshair', 'points']}
        />
      )}
    </div>
  );
};

export default LinearGraph;
