// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, string } from 'prop-types';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  root: (props) => ({
    background: props.checked
      ? ThemeV2.palette.polarGreen6
      : ThemeV2.palette.gray3,
    width: 12,
    height: 12,
    borderRadius: '100%',
    transition: '0.2s ease background',
  }),
});

const CircleIndicator = ({ checked, className: parentClassName, ...rest }) => {
  const classes = useStyles({ checked });
  const className = `${classes.root} ${parentClassName}`;
  return <div className={className} {...rest} />;
};

CircleIndicator.propTypes = {
  checked: bool,
  className: string,
};

CircleIndicator.defaultProps = {
  className: '',
  checked: false,
};

export default CircleIndicator;
