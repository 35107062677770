import { AssemblyCurrency } from '../../../interfaces/assembly';

export interface MenuItemIndividualItem {
  value: string;
  id: string | number;
  isSelected?: boolean;
  prefixIcon?: string;
  prefixImg?: string;
  disabled?: boolean;
  deleteItem?: boolean;
  assemblyCurrency?: AssemblyCurrency;
  emoji?: string;
  helperText?: string;
}
export interface MenuItemProps {
  id: string | number | null | undefined;
  value?: string;
  prefixIcon?: string | undefined;
  prefixImg?: string | undefined;
  disabled?: boolean | undefined;
  deleteItem?: boolean | undefined;
  category?: string;
  items: MenuItemIndividualItem[];
}

export enum DropdownPlacement {
  BottomEnd = 'bottom-end',
  BottomStart = 'bottom-start',
  Bottom = 'bottom',
  LeftEnd = 'left-end',
  LeftStart = 'left-start',
  Left = 'left',
  RightEnd = 'right-end',
  RightStart = 'right-start',
  Right = 'right',
  TopEnd = 'top-end',
  TopStart = 'top-start',
  Top = 'top',
}

export enum DropdownColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Secondary2 = 'secondary2',
}
export enum DropdownSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
export enum DropdownVariants {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
}
export interface ButtonDropdownProps {
  category?: string | number | null | undefined;
  value?: string;
  color?: DropdownColor;
  size?: DropdownSizes;
  variant?: DropdownVariants;
  icon?: string;
  className?: string;
  dropdownPlacement?: DropdownPlacement;
  isDropdownPositionFixed?: boolean;
  caretIconSize?: string;
  hasBoxShadow?: boolean;
}

export interface StyledComponentProps {
  ref?: any;
  isFullWidth?: boolean;
}
