// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import { API_URL_WITH_V2, API_URL_WITH_V3 } from '../../config';
import { AuthHeaders } from '../shared/HeaderToken';
import { getHomeDetailsAction } from '../Home';
import Logger from '../../Utils/Logger';
import { SIGN_OUT } from '../Session';
import {
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/flashHandler';
import filterHash from '../../Utils/objectUtils/filterHash';
import { orderSortFilterMenuItems } from './data';
import { defaultValues } from '../../atomic/organism/SearchDropdown';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  FEED: `${API_URL_WITH_V2}/user/feed`,
  FEED_ADMIN: `${API_URL_WITH_V2}/user/feed/admin`,
  COMMENT_ADMIN: (feedId, commentId) =>
    `${API_URL_WITH_V2}/user/feed/${feedId}/comment/admin/${commentId}`,
  FEED_SEARCH: `${API_URL_WITH_V3}/feed/posts/search`,
};

const INITIAL_DATA = {
  feed: {},
  newFeed: {},
  isEndOfFeed: false,
  lastPostId: null,
  orderDropdownId: orderSortFilterMenuItems[0].menuItems[0].id,
  sortDropdownId: orderSortFilterMenuItems[1].menuItems[0].id,
  search: { ...defaultValues },
};

// ------------------------------------
// Constants
// ------------------------------------
export const POST_DELETED_BY_ADMIN = 'POST_DELETED_BY_ADMIN';
export const REMOVE_FEED_POST_SUCCESS = 'REMOVE_FEED_POST_SUCCESS';
export const FEED_REQUEST_FAILED = 'feed_request_failed';

// ------------------------------------
// Actions
// ------------------------------------

const removePostSuccess = (feedId) => ({
  type: REMOVE_FEED_POST_SUCCESS,
  feedId,
});

const requestFailed = (errorMessage) => ({
  type: FEED_REQUEST_FAILED,
  error: errorMessage,
});

export const feedActions = {
  removePostSuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const removePost = (props) => {
  const { feedId, keepCarrots } = props;
  return async (dispatch) => {
    try {
      const urlPath = keepCarrots ? 'deleteFeed' : 'deleteFeedWithCarrots';
      dispatch(showInfoMessage('Deleting Post.'));
      const res = await axios.put(
        `${apiEndpoints.FEED_ADMIN}/${feedId}/${urlPath}`,
        {
          feedID: feedId,
        },
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        dispatch(getHomeDetailsAction());
        dispatch(feedActions.removePostSuccess(feedId));
        dispatch(showSuccessMessage('Post has been deleted'));
      } else {
        // @vijay
        // dispatch the failure with msg as server error
        // Check below - we will handle all this site wide.
      }
    } catch (error) {
      // homeProcessError(error, dispatch, feedActions);
      dispatch(showErrorMessage('Deleting post failed, please try again.'));
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case REMOVE_FEED_POST_SUCCESS: {
      const feedId = action.feedId || null;
      const feed = filterHash(state.feed || {}, (post) => post._id !== feedId);
      return {
        ...state,
        feed,
        error: null,
      };
    }
    case POST_DELETED_BY_ADMIN: {
      const { postId } = action;
      const deletedPosts = state.deletedPosts || [];
      return {
        ...state,
        deletedPosts: [...deletedPosts, postId],
        error: null,
      };
    }
    case FEED_REQUEST_FAILED:
      return {
        ...state,
        error: action.errorMessage,
      };
    case SIGN_OUT:
      return INITIAL_DATA;
    default:
      return state;
  }
};
