import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  '@keyframes loader-animation': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  '@keyframes loader-inner': {
    '0%': { transform: 'rotate(0deg)' },
    '100% ': { transform: 'rotate(220deg)' },
  },
  '@keyframes loader-inner-2': {
    '0%': { transform: 'rotate(-140deg)' },
    '50% ': { transform: 'rotate(-160deg)' },
    '100%': { transform: 'rotate(140deg) ' },
  },
  loader: {
    display: 'block',
    height: (props: { size: number }) =>
      props.size ? `${props.size}px` : '32px',
    width: (props: { size: number }) =>
      props.size ? `${props.size}px` : '32px',
    animation: '$loader-animation 4.8s linear infinite',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    '& span': {
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      margin: 'auto',
      height: (props: { size: number }) =>
        props.size ? `${props.size}px` : '32px',
      width: (props: { size: number }) =>
        props.size ? `${props.size}px` : '32px',
      clip: (props: { size: number }) =>
        props.size
          ? `rect(0, ${props.size}px, ${props.size}px, ${props.size / 2}px)`
          : 'rect(0, 32px, 32px, 16px)',
      animation: '$loader-inner 1.2s linear infinite',
      '&:after': {
        content: "''",
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: ' 0',
        margin: 'auto',
        height: (props: { size: number }) =>
          props.size ? `${props.size}px` : '32px',
        width: (props: { size: number }) =>
          props.size ? `${props.size}px` : '32px',
        clip: (props: { size: number }) =>
          props.size
            ? `rect(0, ${props.size}px, ${props.size}px, ${props.size / 2}px)`
            : 'rect(0, 32px, 32px, 16px)',
        border: (props: { color: string; stroke: number }) =>
          props.color
            ? `${props.stroke ? `${props.stroke}px` : '3px'} solid ${
                props.color
              }`
            : `${props.stroke ? `${props.stroke}px` : '3px'} solid ${
                ThemeV2.palette.gray1
              }`,
        borderRadius: ' 50%',
        boxSizing: 'border-box',
        animation: '$loader-inner-2 1.2s ease-in infinite',
      },
    },
  },
});

export interface LoadingCircleProps {
  color?: string;
  size: number;
  stroke?: number;
}

const LoadingCircle = (props: LoadingCircleProps) => {
  const { size = 32 } = props;
  const classes = useStyles({ size });
  return (
    <div className={classes.loader} data-testid="loading-circle">
      <span />
    </div>
  );
};

export default LoadingCircle;
