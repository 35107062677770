import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';
import Body from '../../../atomic/atoms/Body';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';
import { MS_TEAMS, setAppSource } from '../../../Utils/msteams';

const SAMLAuthModalController = () => {
  const { search } = useLocation();

  useEffect(() => {
    setAppSource(MS_TEAMS);
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    if (parsedParams) {
      const samlURL = decodeURIComponent(parsedParams.samlURL as string);
      window.location.href = samlURL;
    }
  }, [search]);
  return (
    <Grid container justify="center" style={{ marginTop: 90 }}>
      <Body variant="body2Bold">Connecting</Body>
      <LoadingPulse isLoading />
    </Grid>
  );
};

export default SAMLAuthModalController;
