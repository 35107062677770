import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';

import { useDispatch } from 'react-redux';
import AssemblyLanderLoadingScreen from '../../../atomic/molecules/AssemblyLanderLoadingScreen';
import { useMemberNotAuthenticated } from '../../../hooks/useAuth';
import { useAuthenticateGoogleOneTapCode } from '../../../queries/Auth/GoogleOneTap';
import RoutesList from '../../../aV2/routes/routesList';
import {
  userSessionCreatedAction,
  handleUserSignin,
} from '../../../modules/Session';
import { SSOOptions, SSOTypes } from '../../../Utils/data/ssoManager/common';

const GoogleOneTapLoginController = () => {
  const [code, setCode] = useState<string | undefined>(undefined);
  const { search } = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  useMemberNotAuthenticated();

  const { data, error } = useAuthenticateGoogleOneTapCode(!!code, code);
  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isCodePresent = parsedParams && parsedParams.code;
    if (isCodePresent) {
      setCode(parsedParams.code as string);
    } else {
      push('/');
    }
  }, [push, search]);

  useEffect(() => {
    if (error) {
      push(
        `/${RoutesList.SSO_ERRORS_FN(
          SSOOptions.GOOGLE,
          SSOTypes.SIGN_IN,
          error.response?.data.message,
        )}`,
      );
    }
  }, [error, push]);

  useEffect(() => {
    if (data) {
      const { jwtToken, user } = data;
      if (user && jwtToken) {
        dispatch(userSessionCreatedAction(user, jwtToken));
        handleUserSignin(user);
      }
    }
  }, [data, dispatch]);

  return <AssemblyLanderLoadingScreen />;
};

export default GoogleOneTapLoginController;
