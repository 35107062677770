import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../componentsV2/theme';

import PlaceHolderImage from '../../../img/email-verification.svg';
import {
  IS_THIS_CORRECT_TEXT,
  RETURN_TO_SIGN_IN_TEXT,
  VALIDATE_YOUR_EMAIL,
} from '../../../languages/en/signIn';
import SigninSubText from '../../molecules/SigninSubText';
import { DEFAULT_PATH } from '../../../constants/routes';

const StyledWrapper = styled.div`
  width: 100%;
  padding: 24px;
  background: ${ThemeV2.palette.gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin: 0 auto 4px;
`;

const StyledBody = styled(Body)`
  text-align: center;
  margin-bottom: 24px;
`;

const StyledImage = styled.img`
  display: block;
  margin: 0 auto;
`;

export const StyledSigninSubText = styled(SigninSubText)`
  margin-top: 24px;
  justify-content: center;
`;

const ValidateYourEmail = () => {
  const subTextProps = {
    text: IS_THIS_CORRECT_TEXT,
    linkOneText: RETURN_TO_SIGN_IN_TEXT,
    linkOnePath: DEFAULT_PATH,
  };
  return (
    <StyledWrapper>
      <StyledHeading variant="h6">{VALIDATE_YOUR_EMAIL.HEADING}</StyledHeading>
      <StyledBody data-testid="body-text">
        {VALIDATE_YOUR_EMAIL.BODY}
      </StyledBody>
      <StyledImage src={PlaceHolderImage} alt={VALIDATE_YOUR_EMAIL.HEADING} />
      <StyledSigninSubText {...subTextProps} />
    </StyledWrapper>
  );
};

export default ValidateYourEmail;
