import * as Yup from 'yup';

import { PASSWORD_LANGUAGE } from '../../../Utils/validators/password';
import {
  PASSWORD_DO_NOT_MATCH_ERROR,
  REQUIRED_CONFIRM_PASSWORD,
} from '../../../languages/en/signIn';

const validationSchema = Yup.object({
  password: Yup.string().required(PASSWORD_LANGUAGE.requiredPassword),
  confirmPassword: Yup.string()
    .required(REQUIRED_CONFIRM_PASSWORD)
    .when('password', {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        PASSWORD_DO_NOT_MATCH_ERROR,
      ),
    }),
});

export default validationSchema;
