// @ts-nocheck TODO: type issues need to be fixed in this file
import { flashMessage, flashErrorMessage } from 'redux-flash';

const showSuccessMessage = (message) => (dispatch) => {
  dispatch(flashMessage(message, { props: { type: 'success' } }));
};

const showErrorMessage = (message) => (dispatch) => {
  dispatch(flashErrorMessage(message));
};

const showInfoMessage = (message) => (dispatch) => {
  dispatch(flashMessage(message, { props: { type: 'info' } }));
};

export { showSuccessMessage, showErrorMessage, showInfoMessage };
