// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Layout from '../../Layout/onboarding';
import Card from '../../../componentsV2/core/Card';
import Typography from '../../../componentsV2/core/Typography';
import Button from '../../../componentsV2/core/Button';
import TextField from '../../../componentsV2/core/TextField';
import TermsAndPrivacy from '../shared/TermsAndPrivacy';
import VerticallyCenteredLayout from '../../Layout/verticallyCentered';

import {
  setProfileForEmployee,
  setProfileForInviteToken,
  getInviteTokenVerified,
} from '../../modules/employeeSignup';
import { getSetProfileRequestLoadingStatus } from '../../modules/employeeSignup/selectors';
import { actions as employeeSignupActions } from '../../../modules/onboarding/EmployeeSignup';
import {
  handleUserSignin,
  userSessionCreatedAction,
} from '../../../modules/Session';
import RoutesList from '../../routes/routesList';
import { handleErrorAction } from '../../utils/ErrorHandler';
import { validatePassword, validateFullName } from '../../../Utils/validation';
import noRequireAuth from '../../../containers/hoc/NoAuthentication';

import ProgressValidator from '../../../atomic/molecules/ProgressValidator';
import ValidationText from '../../../atomic/molecules/ValidationText';
import {
  passwordValidators,
  checkPasswordMaxLength,
  checkForNameAndMailId,
  checkPasswordStrength,
  PASSWORD_LANGUAGE,
} from '../../../Utils/validators/password';
import {
  STRENGTH_NONE,
  STRENGTH_STRONG,
} from '../../../constants/progressValidator';

export const SET_PROFILE_TYPES = {
  FROM_USER_ID: 'FROM_USER_ID',
  FROM_INVITE_TOKEN: 'FROM_INVITE_TOKEN',
};

const styles = (theme) => ({
  card: {
    margin: 'auto',
    width: 520,
    height: 593,
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: '20px 22px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
  },
  marginBottom24: {
    marginBottom: 24,
  },
  marginBottom12: {
    marginBottom: '12px',
  },
  bottomDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

class EnterProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      fullNameErrors: null,
      fullNameTouched: false,
      password: '',
      passwordErrors: null,
      passwordTouched: false,
      invalidInviteToken: false,

      validationTexts: passwordValidators,
      strength: STRENGTH_NONE,
    };
  }

  componentDidMount() {
    const { match, source } = this.props;
    const { inviteToken } = match.params;
    if (source === SET_PROFILE_TYPES.FROM_INVITE_TOKEN) {
      this.props
        .getInviteTokenVerified({ inviteToken })
        .then((successAction) => {
          const user = _.get(successAction, ['data', 'data'], null);
          if (user) {
            this.props.updateInviteUserData(user);
            this.setState({
              fullName: `${user.firstName} ${user.lastName}`,
              fullNameTouched: true,
            });
          }
        })
        .catch((errorOrAbortAction) => {
          this.setState({ invalidInviteToken: true });
        });
    }
  }

  onNextClick = (e) => {
    e.preventDefault();
    let { fullName, password } = this.state;
    const { match, source } = this.props;
    const { userId, inviteToken } = match.params;
    fullName = fullName.trim();
    password = password.trim();

    let goThrough = true;
    const fullNameErrors = validateFullName(fullName);
    if (fullNameErrors) {
      this.setState({ fullNameErrors });
      goThrough = false;
    }

    const passwordErrors = validatePassword(password);
    if (passwordErrors) {
      this.setState({ passwordErrors });
      goThrough = false;
    }

    const isPasswordReachedMaxLength = checkPasswordMaxLength(password);
    if (isPasswordReachedMaxLength) {
      this.setState({ passwordErrors: PASSWORD_LANGUAGE.charactersLimit });
      goThrough = false;
    }

    const hasName = checkForNameAndMailId(password, {
      fullName,
    });
    if (hasName.length > 0) {
      goThrough = false;
      this.setState({
        passwordErrors: PASSWORD_LANGUAGE.emailUsernameSimilar,
      });
    }

    if (goThrough) {
      if (source === SET_PROFILE_TYPES.FROM_USER_ID) {
        this.props
          .setProfileForEmployee({ userId, fullName, password })
          .then((successAction) => {
            const data = _.get(successAction, ['data', 'data']);
            this.loginAndRedirectUser(data);
          })
          .catch((errorOrAbortAction) => {
            handleErrorAction(errorOrAbortAction);
          });
      } else {
        this.props
          .setProfileForInviteToken({ inviteToken, fullName, password })
          .then((successAction) => {
            const data = _.get(successAction, ['data', 'data']);
            this.loginAndRedirectUser(data);
          })
          .catch((errorOrAbortAction) => {
            handleErrorAction(errorOrAbortAction);
          });
      }
    }
  };

  loginAndRedirectUser = (data) => {
    const { user, token } = data;
    this.props.userSessionCreatedAction(user, token);
    handleUserSignin(user);
  };

  handleNameChange = (event) => {
    this.setState({
      fullName: event.target.value,
      fullNameErrors: null,
      fullNameTouched: true,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    const { newValidationTexts, newStrength } = checkPasswordStrength(password);

    this.setState({
      password: event.target.value,
      passwordErrors: null,
      passwordTouched: true,

      validationTexts: newValidationTexts,
      strength: newStrength,
    });
  };

  redirectToSignup = () => {
    return <Redirect to={`${RoutesList.EMPLOYEE_ENTER_EMAIL}`} />;
  };

  renderInvalidToken = () => {
    return (
      <Layout>
        <VerticallyCenteredLayout offsetHeight={200}>
          <div>
            <Typography weight="bold" variant="h4" gutterBottom>
              Your invite is invalid
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              Invite token is invalid, try contacting your Admin.
            </Typography>
          </div>
          <div style={{ marginTop: 16 }}>
            <Typography variant="subtitle1" gutterBottom>
              Not sure about this,{' '}
              <Link to="" onClick={this.chatClick}>
                chat with us now
              </Link>
            </Typography>
          </div>
        </VerticallyCenteredLayout>
      </Layout>
    );
  };

  render() {
    const { classes, loading, match, source } = this.props;
    const {
      password,
      passwordErrors,
      passwordTouched,
      fullName,
      fullNameTouched,
      fullNameErrors,
      invalidInviteToken,
      validationTexts,
      strength,
    } = this.state;

    const isPasswordStrengthStrong = strength !== STRENGTH_STRONG;
    const isButtonDisabled =
      !passwordTouched ||
      !!passwordErrors ||
      !fullNameTouched ||
      !!fullNameErrors;

    const { userId, inviteToken } = match.params;
    if (!source) {
      return this.redirectToSignup();
    }
    if (source === SET_PROFILE_TYPES.FROM_USER_ID && !userId) {
      return this.redirectToSignup();
    }
    if (source === SET_PROFILE_TYPES.FROM_INVITE_TOKEN && !inviteToken) {
      return this.redirectToSignup();
    }

    if (invalidInviteToken) {
      return this.renderInvalidToken();
    }

    return (
      <Layout>
        <Card className={classes.card}>
          <div className={classes.wrapper}>
            <div className={classes.body}>
              <form onSubmit={this.onNextClick}>
                <Typography variant="h5" weight="bold" gutterBottom>
                  Set your name and password
                </Typography>
                <TextField
                  id="full-name"
                  name="name"
                  label="Your full name"
                  placeholder="Ex. Martha Wayne"
                  value={fullName}
                  onChange={this.handleNameChange}
                  rootStyle={{ width: '100%' }}
                  error={fullNameErrors}
                  helperText="Your name will appear on all posts and messages you create"
                  className={classes.marginBottom24}
                  autoComplete="off"
                />
                <TextField
                  id="new-password"
                  type="password"
                  label="Your password"
                  name="pass"
                  placeholder="Create a strong password"
                  value={password}
                  onChange={this.handlePasswordChange}
                  rootStyle={{ width: '100%' }}
                  error={passwordErrors}
                  className={classes.marginBottom12}
                  autoComplete="new-password"
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                />
                {strength && (
                  <ProgressValidator
                    strength={strength}
                    className={classes.progressValidator}
                  />
                )}
                {validationTexts && (
                  <div
                    className={`${classes.validationTexts} ${classes.marginBottom24}`}
                  >
                    {validationTexts.map((item) => (
                      <ValidationText key={item.text} checked={item.checked}>
                        {item.text}
                      </ValidationText>
                    ))}
                  </div>
                )}
                <div className={classes.bottomDiv}>
                  <Typography variant="subtitle2" weight="semiBold">
                    Step 1 of 2
                  </Typography>
                  <Button
                    style={{ width: 120 }}
                    disabled={
                      isButtonDisabled || loading || isPasswordStrengthStrong
                    }
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {loading ? 'Saving...' : 'Next'}
                  </Button>
                </div>
              </form>
            </div>
            <TermsAndPrivacy buttonText="Next" />
          </div>
        </Card>
      </Layout>
    );
  }
}

EnterProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getSetProfileRequestLoadingStatus(state),
});

export default connect(mapStateToProps, {
  setProfileForEmployee,
  setProfileForInviteToken,
  getInviteTokenVerified,
  userSessionCreatedAction,
  updateInviteUserData: employeeSignupActions.updateInviteUserData,
})(withStyles(styles)(noRequireAuth(EnterProfile)));
