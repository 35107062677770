import React from 'react';
import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';
import Button from '../../atoms/Button';
import { ButtonProps } from '../../atoms/Button/interfaces';
import SVGIcon from '../../atoms/SVGIcon';

const EndButton = styled(Button)`
  margin-left: auto;
`;

const Wrapper = styled.div<Partial<IconInfoProps>>`
  display: flex;
  padding: ${(props) => `${props.paddingTopBottom} ${props.paddingLeftRight}`};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
`;

const IconWrapper = styled.span<Partial<IconInfoProps>>`
  display: flex;
  margin-right: ${(props) => props.iconTextGap};
`;

export interface IconInfoProps {
  children: React.ReactNode;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  backgroundColor?: string;
  paddingTopBottom?: string;
  paddingLeftRight?: string;
  className?: string;
  borderRadius?: string;
  iconTextGap?: string;
  endButton?: ButtonProps;
}

const IconInfo = ({
  children,
  icon = 'info-circle',
  iconColor = ThemeV2.palette.geekBlue6,
  backgroundColor = ThemeV2.palette.geekBlue1,
  paddingTopBottom = '8px',
  paddingLeftRight = '16px',
  iconSize = '24px',
  className: parentClassName,
  borderRadius = '2px',
  iconTextGap = '8px',
  endButton,
}: IconInfoProps) => {
  return (
    <Wrapper
      paddingTopBottom={paddingTopBottom}
      paddingLeftRight={paddingLeftRight}
      backgroundColor={backgroundColor}
      className={parentClassName}
      borderRadius={borderRadius}
    >
      {/* Note: Applying className to SVGIcon does not render the color */}
      <IconWrapper iconTextGap={iconTextGap}>
        <SVGIcon icon={icon} color={iconColor} size={iconSize} />
      </IconWrapper>
      {children}
      {endButton && <EndButton {...endButton} variant="text" size="small" />}
    </Wrapper>
  );
};

export default IconInfo;
