// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { oneOf, number, string } from 'prop-types';
import {
  StyledProgressBarWrapper,
  StyledProgressBar,
  colorMap,
} from './styles';

const ProgressBar = (props) => {
  const { color, value = 0, background, height, className, dataTestId } = props;

  return (
    <StyledProgressBarWrapper
      background={background}
      height={height}
      className={className}
      data-testid={dataTestId}
    >
      <StyledProgressBar color={color} value={value} />
    </StyledProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  color: oneOf(Object.keys(colorMap)),
  value: number,
  background: string,
  height: string,
  className: string,
  dataTestId: string,
};

ProgressBar.defaultProps = {
  color: 'none',
  value: 0,
  background: '',
  height: '',
  className: '',
  dataTestId: 'progress-bar',
};

export default ProgressBar;
