import { useState, useCallback } from 'react';

const useToggle = (initialValue = false) => {
  const [toggleValue, setToggleValue] = useState(initialValue);

  const setToggle = useCallback(() => {
    setToggleValue(!toggleValue);
  }, [toggleValue]);
  const setToggleValueSynchronous = useCallback(() => {
    setToggleValue((prevToggleValue) => !prevToggleValue);
  }, []);

  const setToggleToFalse = useCallback(() => setToggleValue(false), []);
  const setToggleToTrue = useCallback(() => setToggleValue(true), []);

  return {
    models: { toggleValue },
    operations: {
      setToggleValue: setToggle,
      setToggleToFalse,
      setToggleToTrue,
      setToggleValueSynchronous,
    },
  };
};

export default useToggle;
