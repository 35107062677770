// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  root: {
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontStyle: 'normal',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '14px',
    lineHeight: '22px',
    color: ThemeV2.palette.geekBlue6,
    transition: '0.2s ease color',
    '&:hover': {
      color: ThemeV2.palette.geekBlue5,
    },
    '&:focus': {
      color: ThemeV2.palette.geekBlue7,
    },
    '&:active': {
      color: ThemeV2.palette.geekBlue7,
    },
  },
});

const Link = (props) => {
  const classes = useStyles(props);
  const { children, to, className, ...rest } = props;
  return (
    <RouterLink to={to} className={`${classes.root} ${className}`} {...rest}>
      {children}
    </RouterLink>
  );
};

Link.propTypes = {
  children: string,
  to: string,
  className: string,
};

Link.defaultProps = {
  children: null,
  to: '',
  className: '',
};

export default Link;
