const getFilteredItems = (menuItems: any[], value: string) => {
  if (value) {
    return menuItems.map((element) => {
      return {
        ...element,
        items: element.items?.filter((item: { value: string }) =>
          item.value.toLowerCase().includes(value.toLowerCase()),
        ),
      };
    });
  }

  return menuItems;
};

const getItemsToAdd = (menuItems: any[], addedItemIds?: any[]) => {
  if (addedItemIds && addedItemIds?.length > 0) {
    return menuItems.map((element) => {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-types
        ...(element as {}),
        items: element.items?.filter(
          (item: { id: any }) => !addedItemIds.includes(item.id),
        ),
      };
    });
  }

  return menuItems;
};

export { getFilteredItems, getItemsToAdd };
