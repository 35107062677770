// @ts-nocheck TODO: type issues need to be fixed in this file
import './vendors/polyfills';
import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { API_URL_WITH_V2, DATA_DOG } from './config';
import rootSaga from './modules/sagas';
import { authenticateUser, clearLocalStorage } from './modules/Session';
import store from './store';
import { checkIfProduction } from './Utils/admin';
import { initAnalytics } from './Utils/analytics';
import { bootIntercom } from './Utils/Intercom';
import { AuthHeaders } from './modules/shared/HeaderToken';

loadProgressBar({ showSpinner: false }, axios);

datadogRum.init({
  ...DATA_DOG,
  version: process.env.REACT_APP_VERSION,
  sampleRate: 100,
  trackInteractions: true,
  replaySampleRate: 1,
  defaultPrivacyLevel: checkIfProduction() ? 'mask-user-input' : 'allow',
});
datadogRum.startSessionReplayRecording();

store.runSaga(rootSaga);

initAnalytics();

axios
  .get(`${API_URL_WITH_V2}/auth/user/info`, AuthHeaders())
  .then((res) => {
    authenticateUser(res.data.data.user, store.dispatch);
  })
  .catch(() => {
    clearLocalStorage();
    bootIntercom();
  })
  .finally(() => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root'),
    );
  });
