export enum ButtonSize {
  Large = 'large',
  Normal = 'normal',
  Small = 'small',
}
export type IconButtonColors =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'textWithBackground'
  | 'iconWithoutBackground';
export interface IconButtonProps {
  disabled?: boolean;
  size?: ButtonSize;
  children?: React.ReactNode;
  icon?: string;
  onClick?: (event: any) => void;
  rounded?: boolean;
  title?: string;
  className?: string;
  isSelected?: boolean;
  iconBgColor?: string;
  iconColor?: string;
  dataTestId?: string;
  buttonRef?: any;
  color?: IconButtonColors;
  rotate?: number;
}

export interface StylesComponentsProps {
  size?: ButtonSize;
  rounded?: boolean;
  isSelected?: boolean;
  iconBgColor?: string;
  color: IconButtonColors;
  icon?: string;
}
