// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import ThemeV2 from '../../theme';

const Styles = {
  root: {
    width: '100%',
    '&.disabled': {
      opacity: '0.24',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: `1px solid ${ThemeV2.palette.lightGray1}`,
    width: '100%',
    padding: '10px 12px',
    textAlign: 'inherit',
    fontFamily: ThemeV2.typography.fontFamily,
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontSize: '14px',
    '&:focus': {
      borderColor: ThemeV2.palette.primary.main,
    },
  },
};

class BootstrapInput extends Component {
  render() {
    const { classes, disabled, ...rest } = this.props;
    return (
      <InputBase
        classes={{
          root: classnames(classes.root, disabled ? 'disabled' : ''),
          input: classes.input,
        }}
        disabled={disabled}
        {...rest}
      />
    );
  }
}

export default withStyles(Styles)(BootstrapInput);
