// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import Logger from '../../Utils/Logger';

/*
  API Error Object
  {
    status: HTTP error codes
    code: ERROR_CODES see below
    values: Based on Error codes (string or object)
    message: string
  }
*/

const ERROR_CODES = {
  CODE_100: 100, //  Map based error details
  CODE_101: 101, //  Array in error messages (Good to show bullet points)
  CODE_102: 102, // String error in message
};

export function handleErrorAction(errorAction) {
  const error = {};
  if (errorAction.error) {
    const errorData = _.get(errorAction, ['error', 'response', 'data'], null);
    const errorStatus = _.get(errorAction, ['error', 'response', 'status'], 0);
    error.status = errorStatus;
    Logger(errorStatus);
    if (errorData) {
      error.code = errorData.code;
      Logger(errorData);
      switch (errorData.code) {
        case ERROR_CODES.CODE_100:
          error.message = 'Map based error';
          error.values = errorData.message;
          break;
        case ERROR_CODES.CODE_101:
          error.message = errorData.message;
          error.values = errorData.message.join(', ');
          break;
        case ERROR_CODES.CODE_102:
          error.message = errorData.message;
          error.values = errorData.message;
          break;
        default:
          break;
      }
    }
  }
  return error;
}

export function nothing() {}
