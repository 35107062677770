// @ts-nocheck TODO: type issues need to be fixed in this file
import { checkIfProduction } from './admin';

const PRINT_ON = !checkIfProduction();

export default (text, shouldShow = true) => {
  if (PRINT_ON && shouldShow) {
    console.log(text);
  }
};
