import {
  InputAdornment,
  InputBaseComponentProps,
  InputLabelProps,
  OutlinedInputProps,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useMemo, KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import SVGIcon from '../../atoms/SVGIcon';

const muiTextFieldHeight = (height: number, padding: string) => {
  const offset = (53 - height) / 2;

  return css`
    input.MuiInputBase-input.MuiOutlinedInput-input {
      padding-top: ${() => (padding ? padding : `calc(18.5px - ${offset}px)`)};
      padding-bottom: ${() =>
        padding ? padding : `calc(18.5px - ${offset}px)`};
    }

    label {
      top: -${offset}px;
    }

    label[data-shrink='true'] {
      top: 0;
    }
  `;
};

export interface TextfieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSuffixAdornmentClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  label?: React.ReactNode;
  name?: string;
  helperText?: string | React.ReactNode;
  placeholder?: string;
  hasError?: boolean;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  disabled?: boolean;
  icon?: string;
  suffix?: string;
  prefix?: React.ReactNode;
  type?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onBlur?: () => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onInputFocus?: () => void;
  inputProps?: InputBaseComponentProps;
  InputLabelPropsObj?: Partial<InputLabelProps>;
  InputProps?: Partial<OutlinedInputProps>;
  autoFocus?: boolean;
  removeElevation?: boolean;
  background?: string;
  autoComplete?: string;
  spellCheck?: boolean;
  fullWidth?: boolean;
  padding?: string;
}

const StyledTextfield = styled(TextField)<{
  height: number;
  activated: number;
  error?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  background?: string;
  $removeElevation?: boolean;
  padding?: string;
}>`
  width: 100%;
  font-family: ${ThemeV2.typography.adminFontFamily};
  ${({ height, padding }) => muiTextFieldHeight(height || 40, padding || '')}
  .MuiFormLabel-root.MuiInputLabel-root {
    font-size: 14px;
    font-weight: ${ThemeV2.typography.fontWeightRegular};
    > span {
      line-height: 0;
    }
    &.MuiInputLabel-shrink {
      transform: translate(14px, 0px) scale(0.75);
      top: -4px;
      color: ${(props) => {
        return props?.error ? ThemeV2.palette.dustRed6 : 'inherit';
      }};
    }
    color: ${({ disabled, activated, error }) => {
      if (disabled) {
        return ThemeV2.palette.gray6;
      }
      if (activated) {
        return ThemeV2.palette.gray9;
      }
      if (error) {
        return ThemeV2.palette.dustRed6;
      }
      return ThemeV2.palette.gray7;
    }};
    &.Mui-focused {
      color: ${(props) => {
        return props?.error
          ? ThemeV2.palette.dustRed6
          : ThemeV2.palette.geekBlue6;
      }};
    }
  }
  .MuiInputBase-root {
    input {
      margin-left: 0px;
      font-size: 14px;
      line-height: 22px;
      font-weight: ${ThemeV2.typography.fontWeightRegular};
      height: 16px;
    }
    background-color: ${({ disabled, background }) => {
      if (background) return background;
      if (disabled) return ThemeV2.palette.gray3;
      return 'transparent';
    }};
    fieldset {
      border-color: ${({ activated, disabled }) => {
        if (disabled) {
          return ThemeV2.palette.gray3;
        }
        return activated ? ThemeV2.palette.gray7 : ThemeV2.palette.gray5;
      }};
      ${({ label }) =>
        label &&
        `span {
          font-size: 10.5px;
          line-height: 20px;
          padding: 0 4px;
        }`}
    }
    &:hover {
      box-shadow: ${({ disabled, $removeElevation }) =>
        disabled || $removeElevation
          ? 'none'
          : `0px 2px 4px rgba(0, 0, 0, 0.04),
        0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12)`};

      fieldset {
        border-color: ${({ activated, disabled, error }) => {
          if (disabled) {
            return ThemeV2.palette.gray3;
          }
          if (error) {
            return ThemeV2.palette.dustRed6;
          }
          return activated ? ThemeV2.palette.gray7 : ThemeV2.palette.gray5;
        }};
      }
    }
  }
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: ${ThemeV2.palette.gray3};
  }
  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${ThemeV2.palette.geekBlue6};
    }
    .MuiInputAdornment-root {
      svg > path:last-of-type {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
  }
  .MuiOutlinedInput-root.Mui-error {
    .MuiInputAdornment-root {
      svg > path:last-of-type {
        fill: ${ThemeV2.palette.dustRed6};
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: ${ThemeV2.palette.dustRed6};
    }
  }
  .MuiInputAdornment-root {
    p {
      font-size: 14px;
      line-height: 22px;
      font-weight: ${ThemeV2.typography.fontWeightRegular};
    }
    svg > path:last-of-type {
      fill: ${({ activated }) =>
        activated ? ThemeV2.palette.gray9 : ThemeV2.palette.gray6};
    }
  }
  p {
    color: ${({ disabled }) =>
      disabled ? ThemeV2.palette.gray6 : ThemeV2.palette.gray7};
  }
  .MuiAutocomplete-endAdornment {
    top: unset;
  }
`;

const NewTextfield = ({
  label,
  name,
  helperText,
  placeholder,
  size = 'medium',
  value,
  onChange,
  onKeyPress,
  hasError,
  disabled,
  multiline,
  maxRows,
  minRows,
  icon,
  suffix,
  prefix,
  type = 'text',
  className,
  onBlur,
  onInputFocus,
  inputProps,
  InputLabelPropsObj,
  onSuffixAdornmentClick,
  InputProps: propInputProps,
  autoFocus = false,
  removeElevation = false,
  background,
  autoComplete,
  spellCheck = true,
  fullWidth,
  padding = '',
}: TextfieldProps) => {
  const calculateHeight = () => {
    if (size === 'small') {
      return 32;
    }
    if (size === 'medium') {
      return 40;
    }
    return 48;
  };
  const InputProps = useMemo(
    () => ({
      ...(propInputProps && {
        ...propInputProps,
      }),
      ...(suffix && {
        endAdornment: (
          <InputAdornment position="end" onClick={onSuffixAdornmentClick}>
            {suffix}
          </InputAdornment>
        ),
      }),
      ...(icon && {
        startAdornment: (
          <InputAdornment position="start">
            <SVGIcon icon={icon} size="16px" />
          </InputAdornment>
        ),
      }),
      ...(prefix && {
        startAdornment: (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
      }),
    }),
    [icon, prefix, propInputProps, suffix, onSuffixAdornmentClick],
  );

  const newInputProps = useMemo(() => {
    const defaultValue = { 'aria-label': name, spellCheck };
    if (inputProps) {
      return { ...inputProps, ...defaultValue };
    }
    return defaultValue;
  }, [inputProps, name, spellCheck]);

  return (
    <StyledTextfield
      data-testid="text-field"
      name={name}
      id={name}
      label={label}
      type={type}
      variant="outlined"
      helperText={helperText}
      placeholder={placeholder}
      height={calculateHeight()}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      activated={value && value.length > 0 ? 1 : 0}
      error={hasError}
      disabled={disabled}
      InputProps={InputProps}
      onBlur={onBlur}
      onFocus={onInputFocus}
      multiline={multiline}
      maxRows={maxRows}
      minRows={minRows}
      background={background}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={newInputProps}
      InputLabelProps={InputLabelPropsObj}
      className={className}
      autoFocus={autoFocus}
      $removeElevation={removeElevation}
      autoComplete={autoComplete}
      fullWidth={fullWidth}
      padding={padding}
    />
  );
};

export default NewTextfield;
