import React from 'react';
import { Grow } from '@material-ui/core';
import { DropdownCardBackground, StyledPopper } from './styles';
import { DropdownCardProps } from './interface';
import { DropdownPlacement } from '../../molecules/Dropdown_V2/interfaces';

const DropdownCard = (props: DropdownCardProps) => {
  const {
    id,
    isOpen,
    anchorEl,
    dropdownPlacement = DropdownPlacement.Bottom,
    children,
    isFullWidth,
    isDropdownPositionFixed = false,
    className,
  } = props;
  const popperWidth =
    anchorEl && isFullWidth ? `${anchorEl.offsetWidth}px` : 'auto';
  return (
    <StyledPopper
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      placement={dropdownPlacement}
      width={popperWidth}
      disablePortal
      modifiers={{
        hide: { enabled: false },
        preventOverflow: {
          enabled: false,
          boundariesElement: 'viewport',
        },
      }}
      popperOptions={{
        positionFixed: isDropdownPositionFixed,
      }}
      transition
      className={className}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <DropdownCardBackground isFullWidth={isFullWidth}>
            {children}
          </DropdownCardBackground>
        </Grow>
      )}
    </StyledPopper>
  );
};

export default DropdownCard;
