// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

import { removeMessage, getLatestMessage } from 'redux-flash';

import {
  FLASHBAR_AUTOHIDE_DURATION,
  FLASHBAR_TYPES,
} from '../../Utils/constants';
import getter from '../../Utils/objectUtils/getter';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: '#d32f2f  ',
  },
  info: {
    backgroundColor: '#1976d2',
  },
  warning: {
    backgroundColor: amber[700],
  },
  close: {
    '&:focus': {
      outline: 'none',
    },
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  messageStyle: {
    marginRight: 34,
  },
  action: {
    position: 'absolute',
    right: 20,
  },
});
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      classes={{
        root: classNames(classes[variant]),
        message: classes.messageStyle,
        action: classes.action,
      }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};
MySnackbarContent.defaultProps = {
  className: '',
  message: '',
  onClose: null,
};
const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = (theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class FlashBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      messageInfo: {},
    };
    this.queue = [];
  }

  componentDidUpdate(prevProps) {
    const prevMessageId = getter(['flashMessage', 'id'], prevProps) || '';
    const currentMessageId = getter(['flashMessage', 'id'], this.props) || '';
    if (currentMessageId !== prevMessageId) {
      if (this.props.flashMessage) {
        this.queue.push({
          message: this.props.flashMessage,
        });
        this.props.removeMessage(this.props.flashMessage.id);
        if (this.state.open) {
          // immediately begin dismissing current message
          // to start showing new one
          this.setState({ open: false });
        } else {
          this.processQueue();
        }
      }
    }
  }

  getMessageVariant = (message) => {
    if (!message) {
      return FLASHBAR_TYPES.INFO;
    }
    if (message.isError) {
      return FLASHBAR_TYPES.FAILURE;
    } else if (message.props) {
      if (message.props.type === 'success') {
        return FLASHBAR_TYPES.SUCCESS;
      }
    }
    return FLASHBAR_TYPES.INFO;
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleExited = () => {
    this.processQueue();
  };

  removeMessage = (flashId) => () => {
    this.props.removeMessage(flashId);
  };

  processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        messageInfo: this.queue.shift(),
        open: true,
      });
    }
  };

  render() {
    const { message, id } = this.state.messageInfo;
    return (
      <Snackbar
        key={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.open}
        autoHideDuration={FLASHBAR_AUTOHIDE_DURATION}
        onClose={this.handleClose}
        onExited={this.handleExited}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant={this.getMessageVariant(message)}
          message={message && message.message}
        />
      </Snackbar>
    );
  }
}

FlashBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  flashMessage: getLatestMessage(state),
});
const mapDispatchToProps = (dispatch) => ({
  removeMessage: (flashId) => dispatch(removeMessage(flashId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles2)(FlashBar));
