// @ts-nocheck TODO: type issues need to be fixed in this file
import { requestsReducer, success } from 'redux-saga-requests';
import { put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import { API_URL_WITH_V2 } from '../../../../config';
import { AuthHeadersV2 } from '../../../shared/HeaderToken';
import { resetBillingInvoices } from '../../../../aV2/modules/admin/billings/paymentsHistory/index';

const apiEndpoints = {
  TIERS: `${API_URL_WITH_V2}/employer/billing/plans`,
  CURRENT_PLAN: `${API_URL_WITH_V2}/employer/billing/plans/active`,
  SWITCH_TIER_PREVIEW: `${API_URL_WITH_V2}/employer/billing/plans/switch/preview`,
  SWITCH_TIER: `${API_URL_WITH_V2}/employer/billing/plans/switch`,
  ADD_PAYMENT: `${API_URL_WITH_V2}/employer/billing/cards/add`,
};

const GET_ACCOUNT_TIERS = '@admin/v2/GET_ACCOUNT_TIERS';
export const GET_CURRENT_PLAN = '@admin/v2/GET_CURRENT_PLAN';
const SWITCH_TIER_PREVIEW = '@admin/v2/SWITCH_TIER_PREVIEW';
export const SWITCH_TIER = '@admin/v2/SWITCH_TIER';
export const ADD_PAYMENT = '@admin/v2/ADD_PAYMENT';

function* resetBillingInvoicesSaga() {
  yield put(resetBillingInvoices());
}
export function* watchAccountPlanChangeSagas() {
  yield takeEvery(success(SWITCH_TIER), resetBillingInvoicesSaga);
  yield takeEvery(success(ADD_PAYMENT), resetBillingInvoicesSaga);
}

export const getAccountTiers = () => ({
  type: GET_ACCOUNT_TIERS,
  request: {
    url: apiEndpoints.TIERS,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const getCurrentPlanRequest = () => ({
  type: GET_CURRENT_PLAN,
  request: {
    url: apiEndpoints.CURRENT_PLAN,
    method: 'get',
    ...AuthHeadersV2(),
  },
});

export const switchAccountPreview = (data) => ({
  type: SWITCH_TIER_PREVIEW,
  request: {
    url: apiEndpoints.SWITCH_TIER_PREVIEW,
    data,
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const switchAccountTiers = (data) => ({
  type: SWITCH_TIER,
  request: {
    url: apiEndpoints.SWITCH_TIER,
    data,
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export const addPaymentMethod = (data) => ({
  type: ADD_PAYMENT,
  request: {
    url: apiEndpoints.ADD_PAYMENT,
    data,
    method: 'post',
    ...AuthHeadersV2(),
  },
});

export default requestsReducer({
  actionType: GET_ACCOUNT_TIERS,
  getData: (state, action, config) => {
    switch (action.type) {
      case success(GET_ACCOUNT_TIERS): {
        const { activePlan, availablePlans } = _.get(
          action,
          ['data', 'data'],
          {},
        );
        return { ...state.data, activePlan, availablePlans };
      }
      default:
        return state;
    }
  },
  mutations: {
    [GET_CURRENT_PLAN]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(GET_CURRENT_PLAN): {
            const details = _.get(action, ['data', 'data', 'details'], null);
            const { plan, ...rest } = details;
            return {
              ...state.data,
              activePlan: plan,
              accountDetails: { ...rest },
            };
          }
          default:
            return state;
        }
      },
    },
    [SWITCH_TIER]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(SWITCH_TIER): {
            const { activePlan, details } = _.get(
              action,
              ['data', 'data'],
              null,
            );
            return {
              ...state.data,
              activePlan,
              accountDetails: { ...details },
            };
          }
          default:
            return state;
        }
      },
    },
    [ADD_PAYMENT]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(ADD_PAYMENT): {
            const { cards } = _.get(action, ['data', 'data'], null);
            if (cards) {
              return {
                ...state.data,
                accountDetails: {
                  ...state.data.accountDetails,
                  creditCards: cards,
                },
              };
            }
            return state;
          }
          default:
            return state;
        }
      },
    },
    [SWITCH_TIER_PREVIEW]: { updateData: false },
  },
});
