// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import SignOutIcon from '@material-ui/icons/PowerSettingsNew';
import { getFullName } from '../../Utils/text';
import { checkAdmin } from '../../Utils/admin';
import { ProfilePic } from '../../Utils/profilePic';
import DropdownMenu from '../../componentsV2/custom/DropdownMenu';

const styles = (theme) => ({
  typography: {
    margin: theme.spacing.unit * 2,
  },
  menuItem: {},
  menuPopoverStyle: {
    minWidth: 168,
  },
});

// eslint-disable-next-line react/display-name
const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

class AvatarDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    event.preventDefault();
    this.setState({
      anchorEl: event.currentTarget,
      // open : true,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      // open : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const { role, profile } = this.props.me;
    let fullName = '';
    if (profile) {
      fullName = getFullName(profile);
    } else {
      fullName = getFullName(this.props.me);
    }
    const isAdmin = checkAdmin(role);
    return (
      <div>
        <Button
          component="div"
          onClick={this.handleClick}
          className="user-drop-down__div"
        >
          <ProfilePic size={35} user={this.props.me} />
          <span className="username-drop-down__span">{`${fullName}`}</span>
          <DownArrowIcon />
        </Button>
        <DropdownMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
        >
          <MenuList>
            {isAdmin && (
              <MenuItem
                className={classes.menuItem}
                component={AdapterLink}
                to="/admin"
              >
                <ListItemIcon className={classes.icon}>
                  <AccountCircleIcon />
                </ListItemIcon>
                <Typography variant="inherit">Admin</Typography>
              </MenuItem>
            )}
            <MenuItem
              className={classes.menuItem}
              component={AdapterLink}
              to="/settings"
            >
              <ListItemIcon className={classes.icon}>
                <SettingsIcon />
              </ListItemIcon>
              <Typography variant="inherit">Settings</Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              component={AdapterLink}
              to="/signout"
            >
              <ListItemIcon className={classes.icon}>
                <SignOutIcon />
              </ListItemIcon>
              <Typography variant="inherit">Sign Out</Typography>
            </MenuItem>
          </MenuList>
        </DropdownMenu>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  me: state.me.me,
});

export default connect(mapStateToProps)(withStyles(styles)(AvatarDropdown));
