import React from 'react';
import { IconButtonProps, ButtonSize } from './interface';
import SVGIcon from '../SVGIcon';
import { IconButtonRoot } from './styles';

const IconButton = (props: IconButtonProps) => {
  const {
    disabled,
    size,
    icon,
    rounded,
    title,
    className,
    children,
    onClick,
    isSelected = false,
    iconBgColor,
    iconColor,
    dataTestId = '',
    buttonRef,
    color = 'text',
    rotate = 0,
  } = props;
  return (
    <IconButtonRoot
      className={className}
      disabled={disabled}
      size={size}
      rounded={rounded}
      onClick={onClick}
      isSelected={isSelected}
      iconBgColor={iconBgColor}
      aria-label={title}
      data-testid={dataTestId}
      color={color}
      ref={buttonRef}
      icon={icon}
    >
      {icon && (
        <SVGIcon
          icon={icon}
          size={size === ButtonSize.Small ? '14px' : '20px'}
          data-testid="SVGIcon"
          color={iconColor}
          rotate={rotate}
        />
      )}
      {children}
    </IconButtonRoot>
  );
};

export default IconButton;
