// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import { API_URL_WITH_V2 } from '../../../config';
import { AuthHeaders } from '../../shared/HeaderToken';
import { adminProcessError } from '../../Admin';
import Logger from '../../../Utils/Logger';
import { REMOVE_FEED_POST_SUCCESS } from '../../home/Feed';
import { deserializePost } from '../../utils/serialize';
// import { showSuccessMessage, showInfoMessage } from '../../../Utils/flashHandler';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  ACTIVITY: `${API_URL_WITH_V2}/employer/employee/activity`,
};

// ------------------------------------
// Constants
// ------------------------------------

export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const ACTIVITY_REQUEST_FAILED = 'ACTIVITY_REQUEST_FAILED';

// ------------------------------------
// Actions
// ------------------------------------

const getUsersActivitySuccess = (posts) => ({
  type: GET_ACTIVITY_SUCCESS,
  posts,
});

const requestFailed = (errorMessage) => ({
  type: ACTIVITY_REQUEST_FAILED,
  error: errorMessage,
});

export const actions = {
  getUsersActivitySuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getActivityAction = (cb) => {
  Logger('getActivityAction', false);
  return async (dispatch) => {
    try {
      const res = await axios.get(apiEndpoints.ACTIVITY, AuthHeaders());
      const result = res.data;
      Logger(result);
      if (result.success) {
        cb();
        dispatch(actions.getUsersActivitySuccess(result.data));
      } else {
        cb();
        // dispatch(actions.emailExists());
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ACTIVITY_SUCCESS: {
      const posts = state.posts || {};
      const incomingPosts = action.posts;
      incomingPosts.forEach((element) => {
        posts[element._id] = deserializePost(element);
      });
      return { ...state, posts: { ...posts }, error: null };
    }
    case REMOVE_FEED_POST_SUCCESS: {
      const posts = state.posts || {};
      let deletedPost = posts[action.feedId];
      if (deletedPost) {
        deletedPost = deserializePost({ ...deletedPost, isDeleted: true });
        return { ...state, posts: { ...posts, [action.feedId]: deletedPost } };
      }
      return state;
    }
    default:
      return state;
  }
};
