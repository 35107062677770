import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import Image from '../../atoms/Image';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import Body from '../../atoms/Body';
import BannerImage from '../../../img/analytics/analytics-graph-no-data-image.svg';
import {
  ANALYTICS_UPGRADE_PLAN_BUTTON_TEXT,
  ANALYTICS_UPGRADE_PLAN_HEADING,
  ANALYTICS_UPGRADE_PLAN_LINK_TEXT,
  ANALYTICS_UPGRADE_PLAN_TEXT,
} from '../../../Utils/data/analytics/common';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
  },
  innerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& img': {
      height: '300px',
      width: '400px',
      margin: '0 auto',
    },
    marginBottom: '64px',
  },
  heading: {
    marginTop: '0px',
    marginBottom: '8px',
  },
  upgradeText: { margin: '0px auto 24px auto' },
  upgradeButton: {
    margin: '0 auto 8px auto',
  },
});

interface StyleProps {
  root: BaseCSSProperties;
  innerWrapper: BaseCSSProperties;
  bannerImage: BaseCSSProperties;
  heading: BaseCSSProperties;
  upgradeText: BaseCSSProperties;
  upgradeButton: BaseCSSProperties;
}

export type PropsClasses = Record<keyof StyleProps, string>;
export interface AnalyticsUpgradeFreePlanProps {
  isFreePlan?: boolean;
  onUpgradeClick: () => void;
  classes?: StyleProps;
  assemblyName: string;
}

const AnalyticsUpgradeFreePlan = (props: AnalyticsUpgradeFreePlanProps) => {
  const { isFreePlan, onUpgradeClick, assemblyName } = props;
  const classes = useStyles(props);
  const UPGRADE_TEXT = useMemo(
    () =>
      ANALYTICS_UPGRADE_PLAN_TEXT.replace(':assemblyName', assemblyName || ''),
    [assemblyName],
  );
  if (isFreePlan) {
    return (
      <div className={classes.root}>
        <div className={classes.innerWrapper}>
          <Image src={BannerImage} alt="banner-free-plan-upgrade" />
          <Heading className={classes.heading} variant="h2">
            {ANALYTICS_UPGRADE_PLAN_HEADING}
          </Heading>
          <Heading
            className={classes.upgradeText}
            variant="h5"
            weight="regular"
          >
            {UPGRADE_TEXT}
          </Heading>
          <Button
            className={classes.upgradeButton}
            icon="premium-crown-icon"
            status="info"
            onClick={onUpgradeClick}
          >
            {ANALYTICS_UPGRADE_PLAN_BUTTON_TEXT}
          </Button>
          <Body variant="body2">{ANALYTICS_UPGRADE_PLAN_LINK_TEXT}</Body>
        </div>
      </div>
    );
  }
  return null;
};

export default AnalyticsUpgradeFreePlan;
