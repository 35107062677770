import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SVGIcon from '../SVGIcon/index';
import theme from '../../../componentsV2/theme';
import Body from '../Body';
import { ButtonContent } from '../../../Utils/data/ssoManager/common';
import { PropsClasses } from '../../../interfaces/ClassesProps';

const useStyles = makeStyles({
  root: {
    padding: '12px 6px',
    width: '184px',
    height: '44px',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.lightGray2}`,
    opacity: 1,
    textTransform: 'unset',

    transition: '0.3s ease all',
    '&:hover': {
      backgroundColor: theme.palette.gray1,
      boxShadow: '0px 2px 6px rgba(44, 44, 44, 0.24)',
    },
    '&:active': {
      backgroundColor: theme.palette.gray3,
    },
    '&:disabled': {
      filter: 'grayscale(1)',
      background: theme.palette.gray3,
      border: `1px solid ${theme.palette.lightGray2}`,
    },
    '& .MuiButton-startIcon': {
      margin: '0px',
      marginRight: '8px',
    },
    '& .MuiButton-label': {
      justifyContent: 'center',
      '& b': {
        marginLeft: '4px',
      },
      '& svg': {
        maxWidth: '18px',
        maxHeight: '18px',
      },
    },
  },
  bodyText: {
    letterSpacing: 'normal',
  },
});
export interface SSOProps {
  ssoParam: string;
  logo: string;
  displayName: string;
  iconColor?: string;
}

export enum LoginType {
  SignIn = 'signin',
  SignUp = 'signup',
  ComingSoon = 'comingSoon',
}
interface ButtonSSOProps {
  sso: SSOProps;
  disabled?: boolean;
  loginType: LoginType;
  onClick?(): void;
  classes?: PropsClasses;
}

const ButtonSSO = (props: ButtonSSOProps) => {
  const { onClick, sso, loginType, disabled } = props;
  const classes = useStyles(props);

  const RenderSvg = () => (
    <SVGIcon icon={sso.logo} color={sso.iconColor} size="18px" />
  );

  let ButtonText: string | null = null;
  if (loginType === LoginType.SignIn) {
    ButtonText = ButtonContent.SIGN_IN;
  } else if (loginType === LoginType.SignUp) {
    ButtonText = ButtonContent.SIGN_UP;
  } else {
    ButtonText = ButtonContent.COMING_SOON;
  }

  return (
    <Button
      type="button"
      disabled={disabled || loginType === LoginType.ComingSoon}
      onClick={onClick}
      startIcon={RenderSvg()}
      disableRipple
      classes={{ root: classes.root }}
    >
      <Body
        variant="body2Medium"
        color="gray8"
        className={classes.bodyText}
        data-testid="signin"
      >
        {ButtonText} {loginType !== LoginType.ComingSoon && sso.displayName}
      </Body>
    </Button>
  );
};

export default ButtonSSO;
