// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import themeV2 from '../../theme';

const styles = (theme) => ({
  label: {
    color: themeV2.palette.skyblue,
    '&:hover': {
      color: '#0092A3 !important',
    },
    '&:focus': {
      color: `${themeV2.palette.skyblue} !important`,
    },
    fontSize: themeV2.typography.fontSize,
    fontFamily: themeV2.typography.fontFamily,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textDecoration: 'underline',
  },
  adminLabel: {
    // fontSize: themeV2.typography.fontSize,
    fontFamily: themeV2.typography.adminFontFamily,
    fontWeight: themeV2.typography.fontWeightSemibold,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textDecoration: 'none',
    color: themeV2.palette.skyblue,
    '&:hover': {
      color: '#0092A3 !important',
    },
    '&:focus': {
      color: `${themeV2.palette.skyblue} !important`,
    },
  },
});

function CustomLink(props) {
  const { classes, children, className, component, ...rest } = props;
  return (
    <Link
      component={component || RouterLink}
      {...rest}
      className={classNames(classes.label, className)}
    >
      {children}
    </Link>
  );
}

function AdminLinkComponent(props) {
  const { classes, children, className, component, ...rest } = props;
  return (
    <Link
      component={component || RouterLink}
      {...rest}
      className={classNames(classes.adminLabel, className)}
    >
      {children}
    </Link>
  );
}

export const AdminLink = withStyles(styles)(AdminLinkComponent);

function AnchorLinkFunc(props) {
  const {
    classes,
    children,
    className,
    component,
    useAdminStyle,
    to,
    ...rest
  } = props;
  const linkStyleName = useAdminStyle ? classes.adminLabel : classes.label;
  return (
    <a href={to} {...rest} className={classNames(linkStyleName, className)}>
      {children}
    </a>
  );
}

export const AnchorLink = withStyles(styles)(AnchorLinkFunc);

CustomLink.propTypes = {
  classes: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
};

export default withStyles(styles)(CustomLink);
