// @ts-nocheck TODO: type issues need to be fixed in this file
import isNil from 'lodash.isnil';
import Bowser from 'bowser';
import axios from 'axios';

class Client {
  static ping() {
    console.log('Client ready');
  }

  static apiEndpoint(environment) {
    environment = environment || 'production';
    if (environment === 'development') {
      return 'https://api-dev.joinassembly.com';
    } else if (environment === 'LOCAL') {
      return 'http://localhost:8080';
    }
    return 'https://api-prod.joinassembly.com';
  }

  static clientInformation() {
    const clientInformationObject = {
      platform: 'WEB',
    };
    if (!isNil(window)) {
      clientInformationObject.viewport = {
        width: window.outerWidth,
        height: window.outerHeight,
      };
      const { browser, os } = Bowser.parse(window.navigator.userAgent);
      clientInformationObject.browser = browser;
      clientInformationObject.os = os;
    }

    return clientInformationObject;
  }

  static campaign({ lander, referrer }) {
    const campaignLander = lander || window.location.href;
    let campaignReferrer = referrer || window.document.referrer;
    if (!isNil(campaignReferrer)) {
      const match = campaignReferrer.match(/joinassembly\.com|carrothr\.com/);
      if (!isNil(match)) {
        campaignReferrer = null;
      }
    }
    return {
      lander: campaignLander,
      referrer: campaignReferrer,
    };
  }

  static async event({
    environment,
    type,
    employerId,
    userId,
    payload,
    lander,
    referrer,
  }) {
    if (isNil(type)) {
      throw new Error('Please provide an event type');
    }

    const eventObject = {
      type,
      employerId,
      userId,
      campaign: Client.campaign({ lander, referrer }),
      payload,
      client: Client.clientInformation(),
    };
    const apiEndpoint = Client.apiEndpoint(environment);
    const url = `${apiEndpoint}/api/v2/metrics/events/record`;
    await axios.post(url, { event: eventObject });
  }
}
Client.eventTypes = {
  REGISTRATION: 'REGISTRATION',
  PROFILE_COMPLETE: 'PROFILE_COMPLETE',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export default Client;
