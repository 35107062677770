import styled from 'styled-components';

import Body from '../Body';
import SVGIcon from '../SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledWrapper = styled.div`
  width: auto;
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  margin: 0;
  padding: 0;
`;

export const StyledLabel = styled.label<{
  checkboxSize: string;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  position: relative;
  margin: 0;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  & span {
    width: ${({ checkboxSize }) => checkboxSize || '14px'};
  }

  & div {
    color: ${({ disabled }) => disabled && `${ThemeV2.palette.darkGray7}`};
  }
`;

export const StyledCheckboxSpan = styled.span`
  display: flex;
  align-items: center;
`;

// https://styled-components.com/docs/api#transient-props
interface LabelTransientProps {
  $isFocused?: boolean;
}

export const StyledSVGIcon = styled(SVGIcon)<LabelTransientProps>`
  box-shadow: ${({ $isFocused }) =>
    $isFocused && `0 0 1px 1px ${ThemeV2.palette.geekBlue6}`};
  border-radius: 2px;
`;

export const StyledBody = styled(Body)<{ $margin?: string; $padding?: string }>`
  margin: ${({ $margin }) => $margin || '0px 0px 0px 8px'};
  padding: ${({ $padding }) => $padding || undefined};
`;
